//
// return the active tree

import { re } from "mathjs";

//
//async function getActiveTree(tree) {
const getActiveTree = async (tree) => {
  // If the tree is empty, return 0
  if (tree.length === 0) {
    return tree;
  }

  let activeTree = [];
  let activePath = -1;

  // loop over the tree and find the active path
  for (let i = 0; i < tree.length; i++) {
    const item = tree[i];
    // if item is the first item in array then set active path to item.path
    if (i === 0) {
      // update active path value
      activePath = item.path;

      // add item to active tree
      activeTree.push(item);
    } else {
      // check if active path value exists in item.choice array
      if (item.choice.includes(activePath)) {
        // update active path value
        activePath = item.path;

        // add item to active tree
        activeTree.push(item);
      }
    }
  }

  return activeTree;

}

//
// switch a node to a new path
//
function switchTreeNode(incoming) {
  // Destructure the incoming object to get the parameters
  const { tree, itemid, newPath } = incoming;

  // Find the item in the tree by id
  const item = tree.find((e) => e.id === itemid);

  // If the item exists, change the path
  if (item) {
    item.path = newPath;
  }

  return tree;
}

//
// extract a single node by it id. USed by tests to check if changes have been made
//
async function extractTreeNodeById(incoming) {
  // Destructure the incoming object to get the parameters
  const { tree, itemid } = incoming;

  // Find the item in the tree by id
  const item = tree.find((e) => e.id === itemid);

  // Return the found item
  return item;
}

// extract the id number only frok a tree as an array
async function extractTreeIds(incoming) {
  // Destructure the incoming object to get the parameters
  const { tree } = incoming;

  // Extract the id numbers
  const ids = tree.map((e) => e.id);

  // Return the array of id numbers
  return ids;
}

// what is teh next numbere to use for path?
function findNextTreePathId(tree) {
  // Check if the array is empty
  if (tree.length === 0) {
    return 1;
  }

  // Find the max path value
  let maxPath = Math.max(...tree.map((e) => e.path));

  // Return the next path number
  return maxPath + 1;
}

// returm the live tree and only the role & content parts for sending to chtgpt
async function extractActiveTreeContent(tree) {
  // Use getActiveTree function to get the active tree
  const activeTree = await getActiveTree(tree);

  // Return the 'id' and 'content' of each item in the active tree
  return activeTree.map(({ role, content }) => ({ role, content }));
}

// get the current tree value continue building the ntree
async function getCurrentIdForPath(tree) {
  // Get the active tree
  const activeTree = await getActiveTree(tree);

  // If the active tree is empty, return 0
  if (activeTree.length === 0) {
    return 0;
  }

  // Return the maximum id from the items in the active tree
  //const max = Math.max(...activeTree.map(item => item.path));
  const max = Math.max(...tree.map((item) => item.path));

  return max;
}

// get the next sequential id number of te tree
function treeGetNextId(tree) {
  // If the tree is empty, return 1
  if (tree.length === 0) {
    return 0;
  }

  // Find the current maximum id in the tree
  const maxId = Math.max(...tree.map((item) => item.id));

  // Return the next id
  return maxId + 1;
}

//
// CHange a question in the tree at id number. Creates a deviated branch
//
async function changeQuestionInTree(incoming) {
  try {
    let { tree, itemid, messages } = incoming;

    const message = messages[0]; // Extract message

    // Find the item to be changed in the tree by itemid
    const item = tree.find((e) => e.id === itemid);
    if (!item) {
      return { error: "Item not found in the tree" };
    }
    if (!item.role === "user") {
      return { error: "You cannot chnage an assistant message only user messages" };
    }

    // Create a new path
    const newPath = findNextTreePathId(tree);

    // Update the item
    item.path = newPath;
    item.choice.push(newPath);
    item.question.push(message.content);
    item.content = message.content;
    item.role = message.role;

    // Return the modified tree
    return {
      data: tree,
    };
  } catch (error) {
    return {
      error: error,
    };
  }
}

export {
  getActiveTree,
  switchTreeNode,
  extractTreeNodeById,
  extractTreeIds,
  findNextTreePathId,
  extractActiveTreeContent,
  getCurrentIdForPath,
  treeGetNextId,
  changeQuestionInTree,
};
