import React, { useState, useEffect, useContext } from 'react'
// import PropTypes from 'prop-types';
import _ from 'lodash';
// import { useAuthState } from 'react-firebase-hooks/auth';

import {
  getFirestore,
  collection,
  // doc,
  query,
  where,
  // orderBy
} from 'firebase/firestore';
import {
  useCollection
  // useDocument
} from 'react-firebase-hooks/firestore';
import { firebase } from '../code/firebase';
import { buildGridData } from '../code/googleMapsApi';


import {
  // Button,
  Box,
  Grid,
  useTheme,
  styled
} from '@mui/material';

import { CustomerAuthContext } from '../helpers/CustomerAuthProvider';

import Container from '@mui/material/Container';
import SettingsDrawer from './SettingsDrawer'
import SettingsPaneLoading from './SettingsPaneLoading'
import SettingsGoogleMapPane from './SettingsGoogleMapPane'

// import MainCard from './MainCard';

import googleMapGrids from '../json/googleMapGrids.json'
let defaultGrid = {}
let defaultGridType = ''
let defaultZoom = ''
let defaultDistance = 0
let defaultDistanceUnit = ''
if (googleMapGrids) {
  defaultDistance = googleMapGrids['distance']
  defaultDistanceUnit = googleMapGrids['distanceUnit']
  defaultZoom = googleMapGrids['zoom']
  // defaultGrid = googleMapGrids['g11']
  // defaultGridType = '121'
  defaultGrid = googleMapGrids['g7']
  defaultGridType = '49'
}

const drawerWidth = 600;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.shorter
  }),
  marginRight: `-${drawerWidth}px`,
  [theme.breakpoints.down('lg')]: {
    paddingRight: 0,
    marginRight: 0
  },
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.shorter
    }),
    marginRight: 0
  })
}));


const SettingsPane = (props) => {
  const theme = useTheme();

  // const keywordsLoaded = props.keywordsLoaded

  let userData = {}
  if (props.userData) {
    userData = props.userData
  }

  let gridTemplateId = ''
  if (props.gridTemplateId) {
    gridTemplateId = props.gridTemplateId
  }


  let keywordId = ''
  if (props.keywordId) {
    keywordId = props.keywordId
  }

  let keywordsData = []
  if (props.keywordsData) {
    keywordsData = props.keywordsData
  }

  let keywordsDataParams = {}
  if (props.keywordsDataParams) {
    keywordsDataParams = props.keywordsDataParams
  }

  let keywordsSchedule = {}
  if (props.keywordsSchedule) {
    keywordsSchedule = props.keywordsSchedule
  }

  let keywordsDocData = {}
  if (props.keywordsDocData) {
    keywordsDocData = props.keywordsDocData
  }

  let defaultGridTemplateData = {
    distance: defaultDistance,
    distanceUnit: defaultDistanceUnit,
    eclocationid: userData.locationId,
    grid: [],
    gridTemplate: defaultGrid, // extra
    gridType: defaultGridType, // extra
    id: '',
    keywordid: '',
    // lat: null,
    // lng: null,
    lat: userData.lat,
    lng: userData.lng,
    userid: userData.userId,
    website: userData.website,
    type: 'default', // extra
    zoom: defaultZoom,
    selectedGridTemplate: '' // extra
  }


  let gridTemplateDataObj = {
    // exists: false,
    // grid: [],
    // id: '',
    // keywordid: '',
    // zoom: '',
    // distance: null,
    // lat: null,
    // lng: null
  }

  const gridTypes = {
    '9': 'g3',
    '25': 'g5',
    '49': 'g7',
    '81': 'g9',
    '121': 'g11',
    '169': 'g13'
  }
  const [openSettingsDrawer, setOpenSettingsDrawer] = useState(true);
  const handleDrawerOpen = () => {
    setOpenSettingsDrawer((prevState) => !prevState);
  };
  const [moveGridPosition, setMoveGridPosition] = useState(false);

  const [recenterMap, setRecenterMap] = useState(Math.floor(Date.now() / 1000));

  const handleRecenterMap = () => {
    const timestamp = Math.floor(Date.now() / 1000)
    setRecenterMap(timestamp)
  }

  const handleRecenterMapReset = () => {
    setRecenterMap(0)
  }


  const { gridTemplatesDocs, setGridTemplatesDocs } = useContext(CustomerAuthContext);
  const { gridTemplatesDocsInit, setGridTemplatesDocsInit } = useContext(CustomerAuthContext);
  const { getGridTemplateDocs } = useContext(CustomerAuthContext);
  const [init, setInit] = useState(true);

  const [gridTemplateData, setGridTemplateData] = useState({});
  const [gridTemplateLoaded, setGridTemplateLoaded] = useState(false);


  // fetches aisettings Docs on init
  useEffect(() => {

    // declare the data fetching function
    const fetchGridTemplateDocs = async () => {
      const returnData = await getGridTemplateDocs(userData);
      if (returnData && returnData.data) {
        // set in getAiAgentsDocs function
        // setAiAgentsData(returnData.data)
        // if aisettings exist, set the first one as selected
        if (returnData.data.length > 0) {
          // setAiAgentsSelected(returnData.data[0])
        }

        setInit(false)
      }
      else {
        setInit(false)
      }
    }

    if (init && gridTemplateId !== '') {
      // call the function
      fetchGridTemplateDocs()
        // make sure to catch any error
        .catch(console.error);
    }
  }, [gridTemplateId])


  useEffect(() => {
    if (!_.isEmpty(gridTemplateDataObj) && gridTemplateLoaded === false) {

      if (gridTemplateDataObj.type === 'user') {
        // gridType
        const gridLength = _.size(gridTemplateDataObj.grid)
        const gridType = gridLength.toString()
        gridTemplateDataObj.gridType = gridType

        // gridTemplate
        const newGridTemplateId = gridTypes[gridType]
        gridTemplateDataObj.gridTemplate = googleMapGrids[newGridTemplateId]
      }
      else if (gridTemplateDataObj.type === 'default') {
        // BT: need lat, lng
      }

      // BT: rebuild gridData
      const newGridData = buildGridData(gridTemplateDataObj)
      gridTemplateDataObj.grid = newGridData



      // initial firestore fetch
      setGridTemplateData(gridTemplateDataObj)
      setGridTemplateLoaded(true)
    }
    else if (!_.isEqual(gridTemplateData.gridTemplates, gridTemplateDataObj.gridTemplates) && gridTemplateLoaded === true) {
      //   // firestore updated and there are new datatemplates

      gridTemplateData.gridTemplates = gridTemplateDataObj.gridTemplates;
      setGridTemplateData({ ...gridTemplateData });

      // setGridTemplateData(gridTemplateDataObj)
    }



    // else if (!_.isEqual(keywordsData, keywordsDataObj.keywords) && keywordsLoaded === true) {
    //   // firestore updated
    //   setKeywordsData(keywordsDataObj.keywords)
    // }
    //setUser({ pending: true, value: undefined })
    // fetchUser().then((fetchedUser) => {
    //  setUser({ pending: true, value: fetchedUser })
    //})
  }, [gridTemplateDataObj])


  const updateGridTemplateData = (newData) => {
    let dataChanged = false
    let dataUpdated = []
    // clone gridTemplateDataObj
    let gridTemplateDataClone = _.cloneDeep(gridTemplateData)

    // loop over newData and add to gridTemplateDataClone
    for (const item in newData) {
      // const value = newData[item]

      if (gridTemplateData[item] !== newData[item]) {
        // if item is "schedule" related skip as it's not needed for gridtemplate
        const isSchedule = item.includes('schedule')
        if (!isSchedule) {
          dataChanged = true
          dataUpdated.push(item)

          gridTemplateDataClone[item] = newData[item]
          // if gridType changed then add gridTemplate
          if (item === 'gridType') {
            const newGridTemplateId = gridTypes[newData[item]]
            gridTemplateDataClone.gridTemplate = googleMapGrids[newGridTemplateId]
          }
        }
      }
    }




    if (dataChanged) {
      // BT: if any map data params change rebuild gridData,
      // might need to move up and have it's own if for all params that affect the grid build

      // check if website is the only value changed, if so true don't rebuild grid data
      let updateGridData = true
      if (dataUpdated.length === 1 && dataUpdated[0] === 'website') {
        updateGridData = false
      }

      if (updateGridData) {
        const newGridData = buildGridData(gridTemplateDataClone)
        gridTemplateDataClone.grid = newGridData
      }

      setGridTemplateData(gridTemplateDataClone)
      if (clog()) console.log('ZZZ gridTemplateDataClone', gridTemplateDataClone);
      // reset clone
      gridTemplateDataClone = {}
    }

    // setOpenSettingsDrawer((prevState) => !prevState);
  };

  const handleMoveGridPosition = () => {
    setMoveGridPosition(!moveGridPosition)
    handleRecenterMap()

  }

  const updateGridLatLng = (newData) => {

    // clone gridTemplateDataObj
    let gridTemplateDataClone = _.cloneDeep(gridTemplateData)

    gridTemplateDataClone.lat = newData.lat
    gridTemplateDataClone.lng = newData.lng

    const newGridData = buildGridData(gridTemplateDataClone)
    gridTemplateDataClone.grid = newGridData

    setGridTemplateData(gridTemplateDataClone)
    // reset clone
    gridTemplateDataClone = {}

  };



  if (gridTemplateId !== '') {

    // const [value, loading, error] = useDocument(
    //   doc(getFirestore(firebase), 'gridtemplates', gridTemplateId),
    //   {
    //     snapshotListenOptions: { includeMetadataChanges: true },
    //   }
    // );

    // const gridtemplatesRef = collection(getFirestore(firebase), 'gridtemplates')
    // const q = query(
    //   gridtemplatesRef,
    //   where('userid', '==', userData.userId),
    //   where('eclocationid', '==', userData.locationId),
    // );
    // const [value, loading, error] = useCollection(
    //   q,
    //   {
    //     snapshotListenOptions: { includeMetadataChanges: true },
    //   }
    // );


    // if (error) {
    if (gridTemplatesDocs.error) {
      return (
        <Container maxWidth={false}>
          <strong>Error: {JSON.stringify(gridTemplatesDocs.error)}</strong>
        </Container>
      )
    }

    // if (loading) {
    if (gridTemplatesDocs.loading) {
      // return (
      //   <Container maxWidth={false}>
      //   </Container>
      // )

      return (
        <SettingsPaneLoading
          loading={'Loading Settings'}
          error={''}
          loadingProgress={75}
        />
      )
    }

    // if (value) {
    if (gridTemplatesDocs.data) {

      const gridtemplateDocs = gridTemplatesDocs.data

      // find gridTemplateId in array
      const gridtemplateIdx = _.findIndex(gridtemplateDocs, { id: gridTemplateId })

      let docData = {}
      if (gridtemplateIdx > -1) {
        // docData = gridtemplateDocs[gridtemplateIdx].data()
        docData = gridtemplateDocs[gridtemplateIdx]
      }

      let gridTemplates = []
      gridtemplateDocs.forEach((doc, index) => {
        // const docsData = doc.data()
        const docsData = doc
        if (docsData) {
          gridTemplates.push(docsData)
        }
      })
      // const docData = value.data()
      let dataExists = true

      // const gridDataLength = _.size(gridData)
      if (!docData.grid || _.size(docData.grid) === 0) {
        dataExists = false
      }
      if (!docData.keywordid || docData.keywordid === '') {
        dataExists = false
      }

      if (!docData.id || docData.id === '') {
        dataExists = false
      }

      if (!docData.zoom || docData.zoom === '') {
        dataExists = false
      }

      if (!docData.distance) {
        dataExists = false
      }

      if (!docData.distanceunit) {
        dataExists = false
      }

      if (!docData.lat) {
        dataExists = false
      }

      if (!docData.lng) {
        dataExists = false
      }


      if (dataExists) {

        // trigger useEffect
        gridTemplateDataObj = {

          /// distance: 1609
          /// distanceunit: "miles"
          // eclocationid: "NG8AEc8nheLEMNYfo3dn"
          // grid: {0: {…}, 1: {…}, 2: {…}, 3: {…}, 4: {…}, 5: {…}, 6: {…}, 7: {…}, 8: {…}, 9: {…}, 10: {…}, 11: {…}, 12: {…}, 13: {…}, 14: {…}, 15: {…}, 16: {…}, 17: {…}, 18: {…}, 19: {…}, 20: {…}, 21: {…}, 22: {…}, 23: {…}, 24: {…}, 25: {…}, 26: {…}, 27: {…}, 28: {…}, 29: {…}, 30: {…}, 31: {…}, 32: {…}, 33: {…}, 34: {…}, 35: {…}, 36: {…}, 37: {…}, 38: {…}, 39: {…}, 40: {…}, 41: {…}, 42: {…}, 43: {…}, 44: {…}, 45: {…}, 46: {…}, 47: {…}, 48: {…}, 49: {…}, 50: {…}, 51: {…}, 52: {…}, 53: {…}, 54: {…}, 55: {…}, 56: {…}, 57: {…}, 58: {…}, 59: {…}, 60: {…}, 61: {…}, 62: {…}, 63: {…}, 64: {…}, 65: {…}, 66: {…}, 67: {…}, 68: {…}, 69: {…}, 70: {…}, 71: {…}, 72: {…}, 73: {…}, 74: {…}, 75: {…}, 76: {…}, 77: {…}, 78: {…}, 79: {…}, 80: {…}, 81: {…}, 82: {…}, 83: {…}, 84: {…}, 85: {…}, 86: {…}, 87: {…}, 88: {…}, 89: {…}, 90: {…}, 91: {…}, 92: {…}, 93: {…}, 94: {…}, 95: {…}, 96: {…}, 97: {…}, 98: {…}, 99: {…}, …}
          // id: "zZdsStb8x13VtLLGM9IS"
          // keywordid: " HwxnNn22f6hjIk7h2vbQ"
          // lat: 51.291727
          // lng: -0.3233870000000252
          // userid: "DzLUva5FNJWJ2iFJJjwoUknksu73"
          // website: "surreyhouseclearanceandremovals.co.uk"
          // zoom: "12z"

          distance: docData.distance,
          distanceUnit: docData.distanceunit,
          eclocationid: userData.locationId,
          grid: docData.grid,
          gridTemplate: {}, // extra
          gridType: '', // extra
          id: docData.id,
          keywordid: docData.keywordid,
          // lat: docData.lat,
          // lng: docData.lng,
          lat: userData.lat,
          lng: userData.lng,

          userid: userData.userId,
          website: userData.website,
          type: 'user', // extra
          zoom: docData.zoom,
          gridTemplates: gridTemplates, // extra
          selectedGridTemplate: docData.id // extra
        }

      }
      else {
        gridTemplateDataObj = defaultGridTemplateData
      }

    }


  }
  else {
    gridTemplateDataObj = defaultGridTemplateData
  }


  if (gridTemplateLoaded) {

    // if (clog()) console.log('gridTemplateData:', gridTemplateData)
    return (
      <Box sx={{ display: 'flex' }}>
        <Main theme={theme} open={openSettingsDrawer}>
          <Grid container>

            {
              //  <Button variant="outlined" size="small" onClick={handleDrawerOpen}>
              //    Toggle Drawer
              //  </Button>
            }

            <SettingsGoogleMapPane
              idx={0}
              userData={userData}
              gridTemplateData={gridTemplateData}
              updateGridLatLng={updateGridLatLng}
              moveGridPosition={moveGridPosition}
              recenterMap={recenterMap}
              handleRecenterMapReset={handleRecenterMapReset}
            />


          </Grid>
        </Main>
        <SettingsDrawer
          userData={userData}
          keywordsData={keywordsData}
          keywordId={keywordId}
          keywordsDataParams={keywordsDataParams}
          keywordsSchedule={keywordsSchedule}
          keywordsDocData={keywordsDocData}
          gridTemplateData={gridTemplateData}
          gridTemplateId={gridTemplateId}
          openSettingsDrawer={openSettingsDrawer}
          handleDrawerOpen={handleDrawerOpen}
          drawerWidth={drawerWidth}
          updateGridTemplateData={updateGridTemplateData}
          moveGridPosition={moveGridPosition}
          handleMoveGridPosition={handleMoveGridPosition}
          handleRecenterMap={handleRecenterMap}
        // setUser={setUser}
        />
      </Box>
    )
  }
  else {
    return (
      <SettingsPaneLoading
        loading={'Grid Template Loading'}
        loadingProgress={100}
        error={''}
      />
    )
  }




};


export default React.memo(SettingsPane)