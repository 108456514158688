import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
import _ from 'lodash';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Button,
  FormControlLabel,
  InputLabel,
  Drawer,
  Grid,
  Stack,
  useMediaQuery,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  CircularProgress,
  Box
} from '@mui/material';

import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import SaveIcon from '@mui/icons-material/Save';

// third-party
import { useFormik } from 'formik';
import * as yup from 'yup';

import SettingsKeywordsPane from './SettingsKeywordsPane'

// project imports
// import UserAvatar from './UserAvatar';
// import UserList from './UserList';
import MainCard from '../components/MainCard';
// import useAuth from 'hooks/useAuth';
// import IconButton from 'components/@extended/IconButton';
// import SimpleBar from 'components/third-party/SimpleBar';

// import AnimateButton from '../components/@extended/AnimateButton';

import {
  checkKeywordGridData,
  processKeywords,
  processDataTemplates,
  checkGridTemplates,
  generateGridTemplateId,
  saveKeywordsDataTemplate,
  processDefaultFormikScheduleValues,
  processFormikScheduleValues
} from '../code/settings';


import scheduleMenuData from '../json/schedule.json'

import gridSizes from '../json/gridSizes.json'
const milesDistanceData = gridSizes.milesDistanceData
const metersDistanceData = gridSizes.metersDistanceData
const gridSizeData = gridSizes.gridSizeData

/**
 * 'Enter distance'
 * yup.number Expected 0 arguments, but got 1 */
const validationSchema = yup.object({
  distance: yup.string().required('Distance is required.'),
  distanceUnit: yup.string().required('Measurement is required'),
  gridType: yup.string().required('Grid Size is required'),
});


/*
const milesDistanceData = [
  { id: 161, value: '0.1 Miles' },
  { id: 805, value: '0.5 Miles' },
  { id: 1609, value: '1 Mile' },
  { id: 3219, value: '2 Miles' },
  { id: 4828, value: '3 Miles' },
  { id: 6437, value: '4 Miles' },
  { id: 8047, value: '5 Miles' },
  { id: 9656, value: '6 Miles' },
  { id: 11265, value: '7 Miles' },
  { id: 12875, value: '8 Miles' },
  { id: 14484, value: '9 Miles' },
  { id: 16093, value: '10 Miles' }
];

const metersDistanceData = [
  { id: 100, value: '0.1 Kilometers' },
  { id: 200, value: '0.2 Kilometers' },
  { id: 500, value: '0.5 Kilometers' },
  { id: 1000, value: '1 Kilometer' },
  { id: 2500, value: '2.5 Kilometers' },
  { id: 5000, value: '5 Kilometers' },
  { id: 10000, value: '10 Kilometers' },
  { id: 25000, value: '25 Kilometers' }
];

const gridSizeData = [
  { id: '9', value: '3x3' },
  { id: '25', value: '5x5' },
  { id: '49', value: '7x7' },
  { id: '81', value: '9x9' },
  { id: '121', value: '11x11' },
  // { id: '169', value: '13x13' }
];

*/

const defaultKeywordsSchedule = {
  active: false,
  interval: 'daily',
  hour: '0',
}



/*
schedule: {
  "interval": "hourly",
  "day": "0",     //null
  "hour": "0",    //null
  "minute": "10"
},
// run at 10am each day
schedule: {
  "interval": "daily",
  "day": "0",     //null
  "hour": "10",
  "minute": "0"   //null
},
// run every monday at 2pm
schedule: {
  "interval": "weekly",
  "day": "1",     // Mon is index 1, Sun is index 7
  "hour": "14",
  "minute": "0"   //null
}
// run 21st of each month at 1pm
schedule: {
  "interval": "monthly",
  "day": "21",
  "hour": "13",
  "minute": "0"   //null
}
*/


function SettingsDrawer(props) {
  const theme = useTheme();

  const handleDrawerOpen = props.handleDrawerOpen
  const openSettingsDrawer = props.openSettingsDrawer
  const drawerWidth = props.drawerWidth
  const updateGridTemplateData = props.updateGridTemplateData
  const moveGridPosition = props.moveGridPosition
  const handleMoveGridPosition = props.handleMoveGridPosition

  let userData = {}
  if (props.userData) {
    userData = props.userData
  }

  let websiteStr = ''
  if (userData.website && userData.website !== '') {
    websiteStr = userData.website
  }

  let gridTemplateData = ''
  if (props.gridTemplateData) {
    gridTemplateData = props.gridTemplateData
  }

  let keywordsData = []
  if (props.keywordsData) {
    keywordsData = props.keywordsData
  }

  let keywordId = ''
  if (props.keywordId) {
    keywordId = props.keywordId
  }

  let distanceUnit = ''
  if (gridTemplateData.distanceUnit) {
    distanceUnit = gridTemplateData.distanceUnit
  }

  // let keywordsDataParams = {}
  // if (props.keywordsDataParams) {
  //   keywordsDataParams = props.keywordsDataParams
  // }

  let keywordsScheduleData = defaultKeywordsSchedule
  if (props.keywordsSchedule && !_.isEmpty(props.keywordsSchedule)) {
    keywordsScheduleData = props.keywordsSchedule
  }

  let distance = ''
  if (gridTemplateData.distance) {
    distance = gridTemplateData.distance
  }

  let gridType = ''
  if (gridTemplateData.gridType) {
    gridType = gridTemplateData.gridType
  }

  let gridTemplatesData = []

  if (gridTemplateData.gridTemplates) {
    gridTemplatesData = gridTemplateData.gridTemplates
  }
  else {

  }

  let gridTemplateIdStr = ''
  if (props.gridTemplateId) {
    gridTemplateIdStr = props.gridTemplateId
  }

  let keywordsDocData = {}
  if (props.keywordsDocData) {
    keywordsDocData = props.keywordsDocData
  }

  /// BT
  let gridtemplatesDocData = {}
  // look for gridtemplate
  const gridtemplatesDocDataIdx = _.findIndex(gridTemplatesData, { id: gridTemplateIdStr })
  if (gridtemplatesDocDataIdx > -1) {
    gridtemplatesDocData = gridTemplatesData[gridtemplatesDocDataIdx]


  }
  const [savedGridtemplatesDocData, setSavedGridtemplatesDocData] = useState(gridtemplatesDocData);

  let handleRecenterMap = props.handleRecenterMap


  // BT: this might have to be called after a save
  const createGridTemplateId = generateGridTemplateId()
  // eslint-disable-next-line no-unused-vars
  const [newGridTemplateId, setNewGridTemplateId] = useState(createGridTemplateId);

  const [websiteUser, setWebsiteUser] = useState(websiteStr);
  // use for websiteUser comparison
  // eslint-disable-next-line no-unused-vars
  const [website, setWebsite] = useState(websiteStr);

  // eslint-disable-next-line no-unused-vars
  const [gridTemplates, setGridTemplates] = useState(gridTemplatesData);
  const [gridTemplateId, setGridTemplateId] = useState(gridTemplateIdStr);

  const [keywordsSchedule, setKeywordsSchedule] = useState(keywordsScheduleData);
  const formikKeywordsScheduleData = processDefaultFormikScheduleValues(keywordsScheduleData)
  const [formikKeywordsSchedule, setFormikKeywordsSchedule] = useState(formikKeywordsScheduleData);

  // These 2 are needed for save
  // gridTemplateData
  const [userKeywordData, setUserKeywordData] = useState(keywordsData);
  const [settingsEnabled, setSettingsEnabled] = useState(false);

  const [saveGridTemplates, setSaveGridTemplates] = useState({});
  const [saveKeywords, setSaveKeywords] = useState({});

  // const [keywordsMatch, setKeywordsMatch] = useState(true);
  // const [gridtemplatesMatch, setGridtemplatesMatch] = useState(true);


  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const timer = React.useRef();

  const saveButtonSx = {
    ...(success && {
      bgcolor: theme.palette.success.main,
      '&:hover': {
        // bgcolor: theme.palette.success.dark,
        bgcolor: theme.palette.success.main,
      },
    }),
    // ml: 1,
    height: '40px',
    width: '100%',
    fontWeight: 700
  };

  const [recenterSuccess, setRecenterSuccess] = React.useState(false);

  const recenterButtonSx = {
    ...(recenterSuccess && {
      bgcolor: theme.palette.success.main,
      '&:hover': {
        // bgcolor: theme.palette.success.dark,
        bgcolor: theme.palette.success.main,
      },
    }),
    // ml: 1,
    height: '40px',
    // width: '100%',
    fontWeight: 700,
    mt: '28px',
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);


  useEffect(() => {
    // updating gridTempates data <-
    const match = _.isEqual(gridTemplates, gridTemplatesData)
    if (!match) {
      // BT: need to find to do a match and update states
      // triggers
      setGridTemplates(gridTemplatesData)
      // useEffect: saveGridTemplates <- final data object
      const createNewGridTemplateId = generateGridTemplateId()
      setNewGridTemplateId(createNewGridTemplateId);
    }
  }, [gridTemplatesData]);


  useEffect(() => {
    // check if data matches
    if (!_.isEmpty(saveGridTemplates) && gridTemplates.length > 0) {
      const matchData = checkGridTemplates(saveGridTemplates, gridTemplates, newGridTemplateId)

      if (matchData.match === true) {
        formik.setFieldValue('selectedGridTemplate', matchData.id);
      }
      else {
        formik.setFieldValue('selectedGridTemplate', '0');
      }

      // if (matchData.id !== gridTemplateId) {
      //   setGridTemplateId(matchData.id)
      //   // update select menu
      //   if (matchData.match === true) {
      //     formik.setFieldValue('selectedGridTemplate', matchData.id);
      //   }
      //   else {
      //     formik.setFieldValue('selectedGridTemplate', '0');
      //   }
      // }
    }
  }, [gridTemplates]);


  // const { user } = useAuth();

  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  const drawerBG = theme.palette.mode === 'dark' ? 'dark.main' : 'white';
  // if (clog()) console.log('formikKeywordsSchedule:', formikKeywordsSchedule)

  const formik = useFormik({
    initialValues: {
      distanceUnit: distanceUnit,
      distance: distance,
      gridType: gridType,
      selectedGridTemplate: gridTemplateId,
      website: websiteUser,
      // scheduleInterval: keywordsSchedule.interval ? keywordsSchedule.interval : 'hourly',

      // scheduleDay: keywordsSchedule.day ? keywordsSchedule.day : '1',
      // scheduleHour: keywordsSchedule.hour ? keywordsSchedule.hour : '0',
      // scheduleMinute: keywordsSchedule.minute ? keywordsSchedule.minute : '0',

      scheduleActive: formikKeywordsSchedule.scheduleActive,
      scheduleInterval: formikKeywordsSchedule.scheduleInterval,
      scheduleHourlyMinute: formikKeywordsSchedule.scheduleHourlyMinute,
      scheduleDailyHour: formikKeywordsSchedule.scheduleDailyHour,
      scheduleWeeklyDay: formikKeywordsSchedule.scheduleWeeklyDay,
      scheduleWeeklyHour: formikKeywordsSchedule.scheduleWeeklyHour,
      scheduleMonthlyDay: formikKeywordsSchedule.scheduleMonthlyDay,
      scheduleMonthlyHour: formikKeywordsSchedule.scheduleMonthlyHour
    },
    validationSchema,
    handleChange: (value) => {
      if (clog()) console.log('XXX value ', value);
    },
    onSubmit: async (values) => {
      setSuccess(false);
      setLoading(true);

      // eslint-disable-next-line no-unused-vars
      const result = await saveKeywordsDataTemplate(saveKeywords, saveGridTemplates)

      setSuccess(true);
      setLoading(false);
      /// BT:
      // set saved Gridtemplate Doc for checking save button disabled state etc
      if (result.status === 200) {
        setSavedGridtemplatesDocData(saveGridTemplates)
      }

      timer.current = window.setTimeout(() => {

        setSuccess(false);

        /// BT: look for gridtemplate
        // const gridTemplatesDocDataIdx = _.findIndex(gridTemplatesData, { id: gridTemplateId })

        // if (gridTemplatesDocDataIdx > -1) {
        //   gridtemplatesDocData = gridTemplatesData[gridTemplatesDocDataIdx]
        // }
      }, 3000);


      // showSpinner

      // if (clog()) console.log('select form submit - ', values);
      // dispatch(
      //   openSnackbar({
      //     open: true,
      //     message: 'Select - Submit Success',
      //     variant: 'alert',
      //     alert: {
      //       color: 'success'
      //     },
      //     close: false
      //   })
      // );
    }
  });


  // const [moveGridPosition, setMoveGridPosition] = useState(false);

  useEffect(() => {
    const fDistanceUnit = formik.values.distanceUnit
    const fDistance = formik.values.distance

    if (fDistanceUnit === 'miles') {
      // look for fDistance in milesDistanceData
      const mileIdx = _.findIndex(milesDistanceData, { id: fDistance });
      if (mileIdx === -1) {
        // set distance to 1 mile = 1609
        formik.setFieldValue('distance', 1609);
        updateGridTemplateData(formik.values)

      }
    }

    if (fDistanceUnit === 'meters') {
      // look for fDistance in metersDistanceData
      const meterIdx = _.findIndex(metersDistanceData, { id: fDistance });
      if (meterIdx === -1) {
        // set distance to 1km = 1000
        formik.setFieldValue('distance', 1000);
        updateGridTemplateData(formik.values)
      }
    }
  }, [formik.values.distanceUnit])


  useEffect(() => {
    const result = checkKeywordGridData(userKeywordData, gridTemplateData)
    if (result.passed !== settingsEnabled) {
      setSettingsEnabled(result.passed)
    }
  }, [
    // formik.values,
    userKeywordData,
    gridTemplateData
  ])

  useEffect(() => {
    // build Keywords save data
    const saveKeywordsData = processKeywords(userKeywordData, keywordsSchedule, userData, keywordId, gridTemplateId, gridTemplateData, websiteUser)
    if (!_.isEqual(saveKeywordsData, saveKeywords)) {
      setSaveKeywords(saveKeywordsData)
    }
  }, [
    userKeywordData,
    keywordsSchedule,
    gridTemplateId,
    gridTemplateData,
    websiteUser
  ])

  useEffect(() => {
    // build Gridtemplate save data
    const saveGridTemplatesData = processDataTemplates(userData, keywordId, gridTemplateId, gridTemplateData, websiteUser)

    if (!_.isEqual(saveGridTemplatesData, saveGridTemplates)) {
      setSaveGridTemplates(saveGridTemplatesData)
    }
  }, [
    gridTemplateData,
    gridTemplateId,
    websiteUser
  ])

  useEffect(() => {
    if (gridTemplateData.gridType !== formik.values.gridType) {
      // update parent
      updateGridTemplateData(formik.values)
    }
    // if (clog()) console.log('formik.values:', formik.values)
  }, [formik.values.gridType])


  useEffect(() => {
    if (gridTemplateData.distance !== formik.values.distance) {
      // update parent
      updateGridTemplateData(formik.values)
    }
    // if (clog()) console.log('formik.values:', formik.values)
  }, [formik.values.distance])


  useEffect(() => {
    if (gridTemplateData.website !== formik.values.website) {
      setWebsiteUser(formik.values.website)
      // update parent
      // BT: does this need to update??
      // updateGridTemplateData(formik.values)
    }
    // if (clog()) console.log('formik.values:', formik.values)
  }, [formik.values.website])

  useEffect(() => {

    // gridTemplateId is different to formik val
    if (gridTemplateId !== formik.values.selectedGridTemplate) {

      if (gridTemplateId !== newGridTemplateId && formik.values.selectedGridTemplate === '0') {
        // empty value set = do nothing
      }
      else if (gridTemplateId === newGridTemplateId && formik.values.selectedGridTemplate === '0') {
        // saveGridTemplates useEffect updated formik.values.selectedGridTemplate val
      }
      else if (gridTemplateId !== formik.values.selectedGridTemplate && formik.values.selectedGridTemplate !== '') {
        // selectmenu option selected
        const selectedVal = formik.values.selectedGridTemplate
        let selectedGridTemplateItem = {}
        if (gridTemplates.length > 0) {
          const selectedIdx = _.findIndex(gridTemplates, { id: selectedVal })
          if (selectedIdx > -1) {
            selectedGridTemplateItem = gridTemplates[selectedIdx]
          }
        }

        if (!_.isEmpty(selectedGridTemplateItem)) {

          setGridTemplateId(formik.values.selectedGridTemplate)
          //// formik values
          // distanceUnit: distanceUnit,
          formik.setFieldValue('distanceUnit', selectedGridTemplateItem.distanceunit);
          // distance: distance,
          formik.setFieldValue('distance', selectedGridTemplateItem.distance);
          // gridType: gridType,

          const gridLength = _.size(selectedGridTemplateItem.grid)
          const gridType = gridLength.toString()

          formik.setFieldValue('gridType', gridType);
          // selectedGridTemplate: gridTemplateId
          // formik.setFieldValue('distanceUnit', '');
        }
      }
    }

    // if (clog()) console.log('formik.values:', formik.values)
  }, [formik.values.selectedGridTemplate])

  useEffect(() => {
    // check if data matches
    if (!_.isEmpty(saveGridTemplates) && gridTemplates.length > 0) {
      const matchData = checkGridTemplates(saveGridTemplates, gridTemplates, newGridTemplateId)

      if (matchData.id !== gridTemplateId) {
        setGridTemplateId(matchData.id)
        // update select menu
        if (matchData.match === true) {
          formik.setFieldValue('selectedGridTemplate', matchData.id);
        }
        else {
          formik.setFieldValue('selectedGridTemplate', '0');
        }
      }
    }
    else if (gridTemplateId === '') {
      setGridTemplateId(newGridTemplateId)
      // update select menu
      formik.setFieldValue('selectedGridTemplate', '');
    }

    // if (!_.isEmpty(saveGridTemplates)) {
    //   const isEqual = _.isEqual(saveGridTemplates, gridtemplatesDocData)
    //   // if (clog()) console.log('saveGridTemplates:', saveGridTemplates)
    //   // if (clog()) console.log('gridtemplatesDocData:', gridtemplatesDocData)
    //   // if (clog()) console.log('======================')
    //   if (isEqual) {
    //     setGridtemplatesMatch(true)
    //   }
    //   else {
    //     setGridtemplatesMatch(false)
    //   }
    // }

  }, [saveGridTemplates])

//  useEffect(() => {
//    if (!_.isEmpty(saveKeywords)) {
//      const isEqual = _.isEqual(saveKeywords, keywordsDocData)
//      if (isEqual) {
//        setKeywordsMatch(true)
//      }
//      else {
//        setKeywordsMatch(false)
//      }
//    }
//
//  }, [saveKeywords, keywordsDocData])

  useEffect(() => {

    const scheduleKeys = [
      'scheduleActive',
      'scheduleInterval',
      'scheduleHourlyMinute',
      'scheduleDailyHour',
      'scheduleWeeklyDay',
      'scheduleWeeklyHour',
      'scheduleMonthlyDay',
      'scheduleMonthlyHour',
    ]

    let formikData = {}
    let dataChanged = false
    // iterate through array and check if value has changed
    for (var i = 0; i < scheduleKeys.length; i++) {
      const item = scheduleKeys[i]
      formikData[item] = formik.values[item]
      // check
      if (formikKeywordsSchedule[item] !== formik.values[item]) {
        dataChanged = true
      }
    }

    if (dataChanged) {
      // update formikKeywordsSchedule (used to compare formik changes)
      setFormikKeywordsSchedule(formikData)
      // update keywordsSchedule
      const scheduleData = processFormikScheduleValues(formikData)
      // if (clog()) console.log('schedule:', scheduleData)
      setKeywordsSchedule(scheduleData)
    }

  }, [
    formik.values.scheduleActive,
    formik.values.scheduleInterval,
    formik.values.scheduleHourlyMinute,
    formik.values.scheduleDailyHour,
    formik.values.scheduleWeeklyDay,
    formik.values.scheduleWeeklyHour,
    formik.values.scheduleMonthlyDay,
    formik.values.scheduleMonthlyHour,
  ])


  // show menu to set current user status
  //  const [anchorEl, setAnchorEl] = useState();
  //  const handleClickRightMenu = (event) => {
  //    setAnchorEl(event?.currentTarget);
  //  };

  //  const handleCloseRightMenu = () => {
  //    setAnchorEl(null);
  //  };

  // set user status on status menu click
  //  const [status, setStatus] = useState('available');
  //  const handleRightMenuItemClick = (userStatus) => () => {
  //    setStatus(userStatus);
  //    handleCloseRightMenu();
  //  };

  //  const [search, setSearch] = useState('');
  //  const handleSearch = async (event) => {
  //    const newString = event?.target.value;
  //    setSearch(newString);
  //  };


  const saveDisabled = () => {
    // BT: Needs more work
    const isKeywordsEqual = _.isEqual(saveKeywords, keywordsDocData)
    const isGridtemplatesEqual = _.isEqual(saveGridTemplates, savedGridtemplatesDocData)
    // if (clog()) console.log('saveKeywords:', saveKeywords)
    // if (clog()) console.log('keywordsDocData:', keywordsDocData)
    // if (clog()) console.log('saveGridTemplates:', saveGridTemplates)
    // if (clog()) console.log('savedGridtemplatesDocData:', savedGridtemplatesDocData)
    // if (clog()) console.log('gridtemplatesMatch:', gridtemplatesMatch)
    // if (clog()) console.log('keywordsMatch:', keywordsMatch)
    // if (clog()) console.log('--------------------------------------------')


    // if (gridtemplatesMatch && keywordsMatch && isKeywordsEqual) {
    //   return true
    // }
    if (isKeywordsEqual && isGridtemplatesEqual) {
      return true
    }
    else if (settingsEnabled) {
      if (loading) {
        return true
      }
      else {
        return false
      }
    }
    else {
      return true
    }
    /*
    if (disable === true) {
      return true
    }
    else {
      if (settingsEnabled) {
        if (loading) {
          return true
        }
        else {
          return false
        }
      }
      else {
        return true
      }
    }
    */

  }


  const handleScheduleActiveChange = () => {

    // check current formik value and do opposite
    const toggle = !formik.values.scheduleActive

    // update formik value
    formik.setFieldValue('scheduleActive', toggle);

  }


  const recenterMapOnClick = () => {
    setRecenterSuccess(true);
    handleRecenterMap()
    timer.current = window.setTimeout(() => {
      setRecenterSuccess(false);
    }, 1000);
  }


  if (clog()) console.log('saveKeywords:');
  if (clog()) console.log('saveKeywords:', saveKeywords);
  if (clog()) console.log('saveGridTemplates:', saveGridTemplates);

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        zIndex: { xs: 1100, lg: 0 },
        minHeight: `calc(100vh - 53px)`,
        height: `calc(100vh - 53px)`, // <- added to make scrollable
        overflow: 'scroll', // <- added to make scrollable
        '& .MuiDrawer-paper': {
          height: matchDownLG ? '100%' : 'auto',
          width: drawerWidth,
          boxSizing: 'border-box',
          position: 'relative',
          border: 'none'
        }
      }}
      // variant={matchDownLG ? 'temporary' : 'persistent'}
      variant={'persistent'}
      anchor="right"
      open={openSettingsDrawer}
      ModalProps={{ keepMounted: true }}
      onClose={handleDrawerOpen}
    >
      {openSettingsDrawer && (
        <MainCard
          sx={{
            bgcolor: matchDownLG ? 'transparent' : drawerBG,
            borderRadius: '0px 0 0 0px',
            // borderRight: 'none'
            borderTop: 'none',
            borderBottom: 'none',
            minHeight: `calc(100vh - 53px)`,
            p: 2
          }}
          border={!matchDownLG}
          content={false}
        >

          <form onSubmit={formik.handleSubmit}>


            <SettingsKeywordsPane
              userData={userData}
              keywordsData={keywordsData}

              userKeywordData={userKeywordData}
              setUserKeywordData={setUserKeywordData}

              saveKeywords={saveKeywords}
              saveGridTemplates={saveGridTemplates}
            />

            <Grid container
              spacing={3}
              pt={2}
            // sx={{ pt: theme.spacing(4) }}
            >
              <Grid item xs={12} sm={6}>
                <Stack spacing={1}>
                  <InputLabel sx={{ fontWeight: 700 }} htmlFor="website">Website</InputLabel>
                  <TextField
                    fullWidth
                    id="website"
                    name="website"
                    placeholder="Enter website address"
                    value={formik.values.website}
                    onChange={formik.handleChange}
                    error={formik.touched.website && Boolean(formik.errors.website)}
                    helperText={formik.touched.website && formik.errors.website}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Stack spacing={1}>
                  <Button
                    variant="contained"
                    startIcon={<CenterFocusStrongIcon />}
                    sx={recenterButtonSx}
                    // sx={{
                    //   // my: 3,
                    //   // ml: 1,
                    //   mt: '28px',
                    //   height: '40px',
                    //   fontWeight: 700
                    // }}
                    // onClick={handleRecenterMap}
                    onClick={recenterMapOnClick}
                  >
                    Re-center Map
                  </Button>
                </Stack>
              </Grid>
            </Grid>



            <Grid container
              spacing={3}
              pt={2}
            >
              <Grid item xs={12} sm={6}>
                <Stack spacing={0.5}>
                  <InputLabel sx={{ fontWeight: 700 }}>Measurement Unit</InputLabel>
                  <FormControl>
                    <RadioGroup row aria-label="distanceUnit"
                      value={formik.values.distanceUnit}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="distanceUnit"
                      id="distanceUnit"
                      required
                    >
                      <FormControlLabel value="meters" control={<Radio />} label="Kilometers" />
                      <FormControlLabel value="miles" control={<Radio />} label="Miles" />
                    </RadioGroup>
                  </FormControl>

                  {formik.touched.distanceUnit ?
                    <FormHelperText error id="helper-distanceUnit">
                      {''}
                      {formik.errors.distanceUnit}{''}
                    </FormHelperText>
                    :
                    <FormHelperText error id="helper-distanceUnit">
                      {''}
                    </FormHelperText>
                  }

                </Stack>
              </Grid>

              <Grid item xs={12} sm={6}
              >
                <Stack spacing={0.5}>
                  <InputLabel sx={{ fontWeight: 700 }}></InputLabel>
                  {
                    <FormControlLabel
                      sx={{ color: 'text.primary', mt: '22px !important' }}
                      control={<Switch checked={moveGridPosition} onChange={handleMoveGridPosition} />}
                      label="Move Grid Center Position"
                      labelPlacement="start"
                    />
                  }

                </Stack>
              </Grid>

            </Grid>

            <Grid container
              spacing={3}
              pt={1}
            >
              <Grid item xs={12} sm={6}>
                <Stack spacing={0.5}>
                  <InputLabel sx={{ fontWeight: 700 }}>Distance between Grid points</InputLabel>
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <Select
                      id="distance"
                      name="distance"
                      value={formik.values.distance}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      required
                    >
                      {
                        //  <MenuItem value="">
                        //    <em>Select distance</em>
                        //  </MenuItem>
                      }

                      {formik.values.distanceUnit && (milesDistanceData.map(m => (
                        <MenuItem key={"" + m.id} value={m.id}
                          sx={{
                            ...(formik.values.distanceUnit === 'meters' && {
                              display: 'none'
                            })
                          }}
                        >
                          {m.value}
                        </MenuItem>
                      )))}

                      {formik.values.distanceUnit && (metersDistanceData.map(m => (
                        <MenuItem key={"" + m.id} value={m.id}
                          sx={{
                            ...(formik.values.distanceUnit === 'miles' && {
                              display: 'none'
                            })
                          }}
                        >
                          {m.value}
                        </MenuItem>
                      )))}

                    </Select>
                    {formik.touched.distance ?
                      <FormHelperText error id="helper-distance">
                        {''}
                        {formik.errors.distance}{''}
                      </FormHelperText>
                      :
                      <FormHelperText error id="helper-distance">
                        {''}
                      </FormHelperText>
                    }
                  </FormControl>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack spacing={0.5}>
                  <InputLabel sx={{ fontWeight: 700 }}>Grid Size</InputLabel>
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <Select
                      id="gridType"
                      name="gridType"
                      value={formik.values.gridType}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      required
                    >
                      {formik.values.gridType && (gridSizeData.map(m => (
                        <MenuItem key={m.id} value={m.id}>
                          {m.value}
                        </MenuItem>
                      )))}
                    </Select>
                    {formik.touched.gridType ?
                      <FormHelperText error id="helper-gridType">
                        {' '}
                        {formik.errors.gridType}{' '}
                      </FormHelperText>
                      :
                      <FormHelperText error id="helper-gridType">
                        {' '}
                        {formik.errors.gridType}{' '}
                      </FormHelperText>
                    }
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>

            <Grid container
              spacing={3}
              pt={2}
            >
              <Grid item xs={12} sm={12}>
                <Stack spacing={0.5}>
                  <InputLabel sx={{ fontWeight: 700 }}>Grid Template</InputLabel>
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <Select
                      id="selectedGridTemplate"
                      name="selectedGridTemplate"
                      value={formik.values.selectedGridTemplate}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    // required
                    >
                      <MenuItem key={0} value={'0'}>
                        {<em>Select A Template...</em>}
                      </MenuItem>
                      {formik.values.selectedGridTemplate && (gridTemplates.map(gt => {
                        let gridType = ''
                        const gtLength = _.size(gt.grid)
                        const gtLengthStr = '' + gtLength
                        // find id in gridSizeData
                        const gtIdx = _.findIndex(gridSizeData, { id: gtLengthStr })
                        if (gtIdx > -1) {
                          const item = gridSizeData[gtIdx]
                          gridType = ' ' + item.value + ' Grid'
                        }

                        // milesDistanceData
                        // metersDistanceData
                        let gtDistanceIdx = -1
                        let distanceStr = ''
                        const gDistance = gt.distance
                        if ('miles' === gt.distanceunit) {
                          // find distance in milesDistanceData
                          gtDistanceIdx = _.findIndex(milesDistanceData, { id: gDistance })
                          if (gtDistanceIdx > -1) {
                            const mileValue = milesDistanceData[gtDistanceIdx].value
                            distanceStr = ' (' + mileValue + ' Distance Between Grid Points)'
                          }
                        }

                        if ('meters' === gt.distanceunit) {
                          // find distance in milesDistanceData
                          gtDistanceIdx = _.findIndex(metersDistanceData, { id: gDistance })
                          if (gtDistanceIdx > -1) {
                            const meterValue = metersDistanceData[gtDistanceIdx].value
                            // distanceStr = ' (' + meterValue + ' Spacing)'
                            distanceStr = ' (' + meterValue + ' Distance Between Grid Points)'

                          }
                        }

                        return (
                          <MenuItem key={gt.id} value={gt.id}>
                            {gridType + distanceStr}
                          </MenuItem>
                        )
                      }
                      ))}
                    </Select>
                    {/*formik.touched.selectedGridTemplate && (
                      <FormHelperText error id="helper-selectedGridTemplate">
                        {''}
                        {formik.errors.selectedGridTemplate}{''}
                      </FormHelperText>
                    )*/}
                    {formik.touched.selectedGridTemplate ?
                      <FormHelperText error id="helper-selectedGridTemplate">
                        {''}
                        {formik.errors.selectedGridTemplate}{''}
                      </FormHelperText>
                      :
                      <FormHelperText error id="helper-selectedGridTemplate">
                        {''}
                      </FormHelperText>
                    }
                  </FormControl>
                </Stack>
              </Grid>


              {
                // <Grid item xs={12} sm={6}></Grid>
              }


            </Grid>


            <Grid container id='schedule'
              spacing={3}
              pt={2}
            >
              <Grid item xs={12} sm={6}>
                <Stack spacing={0.5}>
                  <InputLabel sx={{ fontWeight: 700 }}>When to run the report?</InputLabel>
                  <FormControl sx={{
                    m: 1,
                    // minWidth: 120
                  }}>
                    <Select
                      id="scheduleInterval"
                      name="scheduleInterval"
                      value={formik.values.scheduleInterval}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={!formik.values.scheduleActive}
                      required
                    >
                      {formik.values.scheduleInterval && (scheduleMenuData.scheduleIntervalMenu.map(m => (
                        <MenuItem key={m.id} value={m.id}>
                          {m.value}
                        </MenuItem>
                      )))}

                    </Select>
                    {formik.touched.scheduleInterval ?
                      <FormHelperText error id="helper-scheduleInterval">
                        {''}
                        {formik.errors.scheduleInterval}{''}
                      </FormHelperText>
                      :
                      <FormHelperText error id="helper-scheduleInterval">
                        {''}
                      </FormHelperText>
                    }
                  </FormControl>
                </Stack>
              </Grid>


              <Grid item xs={12} sm={6}
              >
                <Stack spacing={0.5}>
                  <InputLabel sx={{ fontWeight: 700 }}></InputLabel>
                  {
                    <FormControlLabel
                      sx={{ color: 'text.primary', mt: '29px !important' }}
                      control={<Switch
                        checked={formik.values.scheduleActive}
                        // onChange={handleMoveGridPosition}
                        // value={formik.values.selectedGridTemplate}
                        // onChange={formik.handleChange}
                        onChange={handleScheduleActiveChange}
                        onBlur={formik.handleBlur}
                      />}
                      label={formik.values.scheduleActive ? 'Activate Reporting: ON' : 'Activate Reporting: OFF'}
                      labelPlacement="start"
                    />
                  }

                </Stack>
              </Grid>
            </Grid>


            <Grid container id='scheduleHourly'
              sx={{
                display: formik.values.scheduleInterval === 'hourly' ? 'flex' : 'none'
              }}
              spacing={3}
              pt={2}
            >
              <Grid item xs={6} sm={6}>
                <Stack spacing={0.5}>
                  <InputLabel sx={{ fontWeight: 700 }}>What minute of each hour?</InputLabel>
                  <FormControl sx={{
                    m: 1,
                    // minWidth: 120
                  }}>
                    <Select
                      id="scheduleHourlyMinute"
                      name="scheduleHourlyMinute"
                      value={formik.values.scheduleHourlyMinute}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={!formik.values.scheduleActive}
                      required
                    >
                      {formik.values.scheduleHourlyMinute && (scheduleMenuData.scheduleMinuteMenu.map(m => {
                        return (
                          <MenuItem key={m.id} value={m.id}>
                            {m.value}
                          </MenuItem>
                        )
                      }
                      ))}

                    </Select>
                    {formik.touched.scheduleHourlyMinute ?
                      <FormHelperText error id="helper-scheduleHourlyMinute">
                        {''}
                        {formik.errors.scheduleHourlyMinute}{''}
                      </FormHelperText>
                      :
                      <FormHelperText error id="helper-scheduleHourlyMinute">
                        {''}
                      </FormHelperText>
                    }
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>

            <Grid container id='scheduleDaily'
              sx={{
                display: formik.values.scheduleInterval === 'daily' ? 'flex' : 'none'
              }}
              spacing={3}
              pt={2}
            >
              <Grid item xs={6} sm={6}>
                <Stack spacing={0.5}>
                  <InputLabel sx={{ fontWeight: 700 }}>What hour of the day?</InputLabel>
                  <FormControl sx={{
                    m: 1,
                    // minWidth: 120
                  }}>
                    <Select
                      id="scheduleDailyHour"
                      name="scheduleDailyHour"
                      value={formik.values.scheduleDailyHour}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={!formik.values.scheduleActive}
                      required
                    >
                      {formik.values.scheduleDailyHour && (scheduleMenuData.scheduleHourMenu.map(m => {
                        return (
                          <MenuItem key={m.id} value={m.id}>
                            {m.value}
                          </MenuItem>
                        )
                      }
                      ))}

                    </Select>
                    {formik.touched.scheduleDailyHour ?
                      <FormHelperText error id="helper-scheduleDailyHour">
                        {''}
                        {formik.errors.scheduleDailyHour}{''}
                      </FormHelperText>
                      :
                      <FormHelperText error id="helper-scheduleDailyHour">
                        {''}
                        {formik.errors.scheduleDailyHour}{''}
                      </FormHelperText>
                    }
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>

            <Grid container id='scheduleWeekly'
              sx={{
                display: formik.values.scheduleInterval === 'weekly' ? 'flex' : 'none'
              }}
              spacing={3}
              pt={2}
            >
              <Grid item xs={6} sm={6}>
                <Stack spacing={0.5}>
                  <InputLabel sx={{ fontWeight: 700 }}>What day of the week?</InputLabel>
                  <FormControl sx={{
                    m: 1,
                    // minWidth: 120
                  }}>
                    <Select
                      id="scheduleWeeklyDay"
                      name="scheduleWeeklyDay"
                      value={formik.values.scheduleWeeklyDay}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={!formik.values.scheduleActive}
                      required
                    >
                      {formik.values.scheduleWeeklyDay && (scheduleMenuData.scheduleWeekDayMenu.map(m => {
                        return (
                          <MenuItem key={m.id} value={m.id}>
                            {m.value}
                          </MenuItem>
                        )
                      }
                      ))}

                    </Select>
                    {formik.touched.scheduleWeeklyDay ?
                      <FormHelperText error id="helper-scheduleWeeklyDay">
                        {''}
                        {formik.errors.scheduleWeeklyDay}{''}
                      </FormHelperText>
                      :
                      <FormHelperText error id="helper-scheduleWeeklyDay">
                        {''}
                      </FormHelperText>
                    }
                  </FormControl>
                </Stack>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Stack spacing={0.5}>
                  <InputLabel sx={{ fontWeight: 700 }}>What hour of the day?</InputLabel>
                  <FormControl sx={{
                    m: 1,
                    // minWidth: 120
                  }}>
                    <Select
                      id="scheduleWeeklyHour"
                      name="scheduleWeeklyHour"
                      value={formik.values.scheduleWeeklyHour}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={!formik.values.scheduleActive}
                      required
                    >
                      {formik.values.scheduleWeeklyHour && (scheduleMenuData.scheduleHourMenu.map(m => {
                        return (
                          <MenuItem key={m.id} value={m.id}>
                            {m.value}
                          </MenuItem>
                        )
                      }
                      ))}

                    </Select>
                    {formik.touched.scheduleWeeklyHour ?
                      <FormHelperText error id="helper-scheduleWeeklyHour">
                        {''}
                        {formik.errors.scheduleWeeklyHour}{''}
                      </FormHelperText>
                      :
                      <FormHelperText error id="helper-scheduleWeeklyHour">
                        {''}
                      </FormHelperText>
                    }
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>

            <Grid container id='scheduleMonthly'
              sx={{
                display: formik.values.scheduleInterval === 'monthly' ? 'flex' : 'none'
              }}
              spacing={3}
              pt={2}
            >
              <Grid item xs={6} sm={6}>
                <Stack spacing={0.5}>
                  <InputLabel sx={{ fontWeight: 700 }}>What day of the month?</InputLabel>
                  <FormControl sx={{
                    m: 1,
                    // minWidth: 120
                  }}>
                    <Select
                      id="scheduleMonthlyDay"
                      name="scheduleMonthlyDay"
                      value={formik.values.scheduleMonthlyDay}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={!formik.values.scheduleActive}
                      required
                    >
                      {formik.values.scheduleMonthlyDay && (scheduleMenuData.scheduleMonthDayMenu.map(m => {
                        return (
                          <MenuItem key={m.id} value={m.id}>
                            {m.value}
                          </MenuItem>
                        )
                      }
                      ))}

                    </Select>
                    {formik.touched.scheduleMonthlyDay ?
                      <FormHelperText error id="helper-scheduleMonthlyDay">
                        {''}
                        {formik.errors.scheduleMonthlyDay}{''}
                      </FormHelperText>
                      :
                      <FormHelperText error id="helper-scheduleMonthlyDay">
                        {''}
                      </FormHelperText>
                    }
                  </FormControl>
                </Stack>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Stack spacing={0.5}>
                  <InputLabel sx={{ fontWeight: 700 }}>What hour of the day?</InputLabel>
                  <FormControl sx={{
                    m: 1,
                    // minWidth: 120
                  }}>
                    <Select
                      id="scheduleMonthlyHour"
                      name="scheduleMonthlyHour"
                      value={formik.values.scheduleMonthlyHour}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={!formik.values.scheduleActive}
                      required
                    >
                      {formik.values.scheduleMonthlyHour && (scheduleMenuData.scheduleHourMenu.map(m => {
                        return (
                          <MenuItem key={m.id} value={m.id}>
                            {m.value}
                          </MenuItem>
                        )
                      }
                      ))}

                    </Select>
                    {formik.touched.scheduleMonthlyHour ?
                      <FormHelperText error id="helper-scheduleMonthlyHour">
                        {''}
                        {formik.errors.scheduleMonthlyHour}{''}
                      </FormHelperText>
                      :
                      <FormHelperText error id="helper-scheduleMonthlyHour">
                        {''}
                      </FormHelperText>
                    }
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>




            <Grid container
              spacing={3}
              pt={2}
            >
              <Grid item xs={12}>
                <Stack direction="row" justifyContent="flex-end">


                  {/*
                  <Button
                    variant="contained"
                    sx={{
                      // my: 3,
                      ml: 1
                    }}
                    type="submit"
                    onClick={() => setShowSpinner(true)}
                    disabled={!settingsEnabled}
                  >
                    Save
                  </Button>
                    */}


                  <Box id='saveButtonBox' sx={{
                    position: 'relative',
                    width: '100%'
                  }}>
                    <Button
                      variant="contained"
                      type="submit"
                      sx={saveButtonSx}
                      disabled={saveDisabled()}
                      // disabled={loading}
                      // onClick={handleSaveButtonClick}
                      startIcon={<SaveIcon />}
                    >
                      Save Keyword Report Settings
                    </Button>
                    {loading && (
                      <CircularProgress
                        size={24}
                        sx={{
                          color: theme.palette.success.main,
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          marginTop: '-12px',
                          marginLeft: '-12px',
                        }}
                      />
                    )}
                  </Box>



                </Stack>
              </Grid>

            </Grid>

          </form>

        </MainCard>
      )}
    </Drawer>
  );
}

SettingsDrawer.propTypes = {
  handleDrawerOpen: PropTypes.func,
  openSettingsDrawer: PropTypes.bool,
  drawerWidth: PropTypes.number
  // setUser: PropTypes.func
};

export default SettingsDrawer;
