import React, {
  useState,
  useEffect,
  // useCallback
  useContext,
} from "react";
import PropTypes from "prop-types";

import {} from "firebase/auth";
import {setd, debug, setc, clog} from "../helpers/HelperFunctions";
import { AdminAuthContext } from "../helpers/AdminAuthProvider";

import {
  getFirestore,
  collection,
  query,
  where,
  // orderBy
} from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { firebase } from "../code/firebase";
import { processKeywordsUserData } from "../code/ecomacy";

import _ from "lodash";

import { useTheme, styled } from "@mui/material/styles";

import {
  Box,
  Button,
  CircularProgress,
  Typography,
  TextField,
  InputLabel,
  Input,
  FormControl,
  FormLabel,
  FormHelperText,
  // Grid
} from "@mui/material";

import Container from "@mui/material/Container";

// import GoogleMapPane from './GoogleMapPane'
import LoadingPane from "./LoadingPane";

const AdminApiSetupPane = (props) => {
  const theme = useTheme();

  const [loading, setLoading] = useState(true);
  const [adminApiKey, setAdminApiKey] = useState("");

  const [adminApiKeySaving, setAdminApiKeySaving] = useState(false);



  const { adminUserData, setAdminUserData } = useContext(AdminAuthContext);
  const { customersData, setCustomersData } = useContext(AdminAuthContext);

  const handleOnChangeAiPresetName = (event) => {
    const val = event.target.value;
    if (clog()) console.log("event:", event.target.value);
    setAdminApiKey(val);
  };

  const handleSaveApiKey = async () => {
    setAdminApiKeySaving(true);
  };

  return (
    <Container
      component="main"
      maxWidth="lg"
      sx={{
        height: "calc(100vh - 70px)",
        pb: 3,
        // backgroundColor: '#cc0000'
      }}
    >
      <Box
        sx={{
          marginTop: 2,
          marginBottom: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#fff",
          border: `1px solid ${theme.palette.divider}`,
          paddingTop: 1,
          paddingBottom: 4,
          paddingLeft: 4,
          paddingRight: 4,
          height: "100%",
        }}
      >
        <Typography component="h1" variant="h3">
          Add your Ecomacy API Key
        </Typography>

        <TextField
          // required
          fullWidth
          label="API Key"
          name="API Key"
          autoComplete="apikey"
          placeholder="Enter your API Key"
          size="large"
          // autoFocus
          sx={{
            mt: 2,
            maxWidth: "500px",
            // minWidth: "300px",
            input: {
              // py: "7px",
              // px: "8px",
              backgroundColor: "white",
            },
          }}
          value={adminApiKey}
          onChange={(e) => handleOnChangeAiPresetName(e)}
        />



        {adminApiKeySaving ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // width: "30px",
              height: "37px",
              flexDirection: "row",
              mt: 3,
            }}
          >
            <CircularProgress
              size={25}
              sx={{
                p: 0,
                m: 0,
              }}
              // variant="determinate"
            />
          </Box>
        ) : (
          <Button
            variant="contained"
            color="primary"
            // fullWidth={!matchDownSM}
            // startIcon={<img src={Twitter} alt="Twitter" />}
            onClick={handleSaveApiKey}
            disabled={adminApiKey === ""}
            sx={{
              mt: 3,
            }}
          >
            Save API Key
          </Button>
        )}
      </Box>
    </Container>
  );
};

export default React.memo(AdminApiSetupPane);
