import React, { useEffect, useRef, useContext, useState } from "react";
// import * as React from 'react';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import {setd, debug, setc, clog} from "../helpers/HelperFunctions";
import { AdminAuthContext } from "../helpers/AdminAuthProvider";
import { RemoteConfigAuthContext } from "../helpers/RemoteConfigAuthProvider";

import {
  Box,
  Typography,
  Link,
  // Button,
} from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircleOutlined";
import ErrorIcon from "@mui/icons-material/ErrorOutlined";
import CircleIcon from "@mui/icons-material/CircleOutlined";

export default function AdminSettingsDialog() {


  const { rc } = useContext(RemoteConfigAuthContext);

  const { adminUserData, setAdminUserData } = useContext(AdminAuthContext);
  const { getAdminUserData } = useContext(AdminAuthContext);
  const { adminStatus, setAdminStatus } = useContext(AdminAuthContext);

  const { showSettingsDialog, setShowSettingsDialog } = useContext(AdminAuthContext);
  const { saveAdminApiKeys } = useContext(AdminAuthContext);

  const { adminApiKeyStatus, setAdminApiKeyStatus } = useContext(AdminAuthContext);

  const [adminApiKeysSaving, setAdminApiKeysSaving] = useState(false);
  const [eapiKey, setEapiKey] = React.useState("");
  const eapiKeyRef = useRef("");

  const [openaiKey, setOpenaiKey] = React.useState("");
  const openaiKeyRef = useRef("");

  const [anthropicKey, setAnthropicKey] = React.useState("");
  const anthropicKeyRef = useRef("");

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (showSettingsDialog === true) {
      let eapiKeyValue = "";
      if (adminUserData && adminUserData.userDoc && adminUserData.userDoc.eapi && adminUserData.userDoc.eapi !== "") {
        eapiKeyValue = adminUserData.userDoc.eapi;
      }
      setEapiKey(eapiKeyValue);
      eapiKeyRef.current = eapiKeyValue;

      let openaiKeyValue = "";
      if (adminUserData && adminUserData.userDoc && adminUserData.userDoc.openai_key && adminUserData.userDoc.openai_key !== "") {
        openaiKeyValue = adminUserData.userDoc.openai_key;
      }
      setOpenaiKey(openaiKeyValue);
      openaiKeyRef.current = openaiKeyValue;

      let anthropicKeyValue = "";
      if (adminUserData && adminUserData.userDoc && adminUserData.userDoc.eapi && adminUserData.userDoc.eapi !== "") {
        anthropicKeyValue = adminUserData.userDoc.anthropic_key;
      }
      setAnthropicKey(anthropicKeyValue);
      anthropicKeyRef.current = anthropicKeyValue;

      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [showSettingsDialog]);

  // const handleClickOpen = () => {
  //   setShowSettingsDialog(true);
  // };

  const handleClose = () => {
    setShowSettingsDialog(false);
  };

  const handleChange = (event, type) => {
    const val = event.target.value;
    if (type === "eapiKey") {
      setEapiKey(val);
    } else if (type === "openaiKey") {
      setOpenaiKey(val);
    } else if (type === "anthropicKey") {
      setAnthropicKey(val);
    }
  };

  const handleOnClickSave = async (event) => {
    let result;
    if (clog()) console.log("handleOnClickSave");

    setAdminApiKeysSaving(true);

    const apiKeys = {
      eapiKey: eapiKey,
      openaiKey: openaiKey,
      anthropicKey: anthropicKey,
    };
    result = await saveAdminApiKeys(adminUserData, apiKeys);
    if (result && result.data) {
      const { data } = result;

      // trigger useEffect in AiSettingsPanel to load new settings
      // setAiSettingsDocsRefresh(val)
      setAdminApiKeysSaving(false);
      setShowSettingsDialog(false);
      // reload admin
      // getAdminUserData();
      getAdminUserData(adminStatus);
    }
  };

  const isSaveDisabled = () => {
    let disabled = false;

    if (eapiKey === "") {
      disabled = true;
    }

    if (eapiKey !== "" && eapiKey === eapiKeyRef.current) {
      disabled = true;
    }

    return disabled;
  };

  const showApiStatusIcon = (type) => {
    const apiKeyStatus = adminApiKeyStatus[type];

    if (apiKeyStatus === "valid") {
      return <CheckCircleIcon color="success" />;
    } else if (apiKeyStatus === "invalid") {
      return <ErrorIcon color="error" />;
    } else {
      return <CircleIcon />;
    }
  };

  return (
    <div>
      <Dialog
        fullWidth
        sx={{
          minWidth: "500px",
          minHeight: "300px",
        }}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Settings</DialogTitle>
        <DialogContent
          dividers={true}
        >
          <DialogContentText>Add your Agency API Key and various AI API Keys.</DialogContentText>

          <Box
            name="agencyApiKeyContainer"
            sx={{
              mt: 3,
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontWeight: "bold",
                }}
              >
                Agency API Key
              </Typography>
            </Box>

            <Box
              sx={{
                mt: 1,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                }}
              >
                <TextField
                  autoFocus
                  // margin="dense"
                  id="agencyApiKey"
                  // label="Agency API Key"
                  type="agencyApiKey"
                  fullWidth
                  variant="outlined"
                  value={eapiKey}
                  // variant="standard"
                  onChange={(event) => handleChange(event, "eapiKey")}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // backgroundColor: "#cc0000",
                  pl: 1,
                }}
              >
                {showApiStatusIcon("eapiKey")}
              </Box>
            </Box>

            <Box
              sx={{
                mt: 1,
              }}
            >
              <Typography
                sx={
                  {
                    // fontWeight: "bold",
                  }
                }
              >
                To find your Agency API Key{" "}
                <Link target="_blank" rel="noopener" href="https://app.gohighlevel.com/settings/api_key">
                  click here
                </Link>
                .
              </Typography>
            </Box>
          </Box>

          {rc.ai_api_keys && (
            <Box
              name="openaiApiKeyContainer"
              sx={{
                mt: 3,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  ChatGPT API Key
                </Typography>
              </Box>
              <Box
                sx={{
                  mt: 1,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                  }}
                >
                  <TextField
                    // autoFocus
                    // margin="dense"
                    id="openaiApiKey"
                    // label="ChatGPT API Key"
                    type="openaiApiKey"
                    fullWidth
                    variant="outlined"
                    // placeholder="Optional"
                    value={openaiKey}
                    // variant="standard"
                    onChange={(event) => handleChange(event, "openaiApiKey")}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // backgroundColor: "#cc0000",
                    pl: 1,
                  }}
                >
                  {showApiStatusIcon("openaiKey")}
                </Box>
              </Box>

              <Box
                sx={{
                  mt: 1,
                }}
              >
                <Typography
                  sx={
                    {
                      // fontWeight: "bold",
                    }
                  }
                >
                  To find your ChatGPT API Key{" "}
                  <Link target="_blank" rel="noopener" href="https://platform.openai.com/account/api-keys">
                    click here
                  </Link>
                  .
                </Typography>
              </Box>
            </Box>
          )}

          {rc.ai_api_keys && (
            <Box
              name="anthropicApiKeyContainer"
              sx={{
                mt: 3,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Claude API Key
                </Typography>
              </Box>

              <Box
                sx={{
                  mt: 1,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                  }}
                >
                  <TextField
                    // autoFocus
                    // margin="dense"
                    id="anthropicApiKey"
                    // label="Claude API Key"
                    type="anthropicApiKey"
                    fullWidth
                    variant="outlined"
                    value={anthropicKey}
                    // variant="standard"
                    onChange={(event) => handleChange(event, "anthropicApiKey")}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // backgroundColor: "#cc0000",
                    pl: 1,
                  }}
                >
                  {showApiStatusIcon("anthropicApiKey")}
                </Box>
              </Box>

              <Box
                sx={{
                  mt: 1,
                }}
              >
                <Typography
                  sx={
                    {
                      // fontWeight: "bold",
                    }
                  }
                >
                  To find your Claude API Key{" "}
                  <Link target="_blank" rel="noopener" href="https://console.anthropic.com/account/keys">
                    click here
                  </Link>
                  .
                </Typography>
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={handleOnClickSave} disabled={isSaveDisabled()}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
