import React, { useState, useEffect, useContext, useCallback } from "react";
// import PropTypes from 'prop-types';
// import { useAuthState } from 'react-firebase-hooks/auth';

import {
  useTheme,
  // styled
} from "@mui/material/styles";

import { Box, Container } from "@mui/material";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

// import MainCard from './MainCard';
// import './GridKeywordsPane.css'

import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebase } from "../code/firebase";

import { setd, debug, setc, clog } from "../helpers/HelperFunctions";
import { AdminAuthContext } from "../helpers/AdminAuthProvider";

import { getUrlParams } from "../code/ecomacy";

// import Container from '@mui/material/Container';
import GridKeywordsPane from "./GridKeywordsPane";
import LoadingPane from "./LoadingPane";
import NoApiKeyPane from "./NoApiKeyPane";

import AdminApiSetupPane from "./AdminApiSetupPane";
import AdminPane from "./AdminPane";

// const auth = getAuth(firebaseApp);
const auth = getAuth(firebase);

const AdminContainer = (routerData) => {
  const theme = useTheme();


  const { testVal, setTestVal } = useContext(AdminAuthContext);
  const { logout } = useContext(AdminAuthContext);
  const { getAdminUserData } = useContext(AdminAuthContext);
  const { getAdminPlanData } = useContext(AdminAuthContext);

  const { adminUserData, setAdminUserData } = useContext(AdminAuthContext);
  const { adminStatus, setAdminStatus } = useContext(AdminAuthContext);

  const { adminUserDocRef } = useContext(AdminAuthContext);
  const { customersData, setCustomersData } = useContext(AdminAuthContext);
  const { customersEcData, setCustomersEcData } = useContext(AdminAuthContext);
  const { customersDataSynced, setCustomersDataSynced } = useContext(AdminAuthContext);
  const { adminPlanData, setAdminPlanData } = useContext(AdminAuthContext);

  const { adminApiKeyStatus, setAdminApiKeyStatus } = useContext(AdminAuthContext);

  const { compareAiSoftwareConfigSettings } = useContext(AdminAuthContext);

  const handleLogout = async () => {
    await logout();
  };

  const [user, loadingUser, errorUser] = useAuthState(auth);

  useEffect(() => {
    fetchAdminUserData()
      // make sure to catch any error
      .catch(console.error);
  }, []);

  const fetchAdminUserData = useCallback(async () => {
    // need to check if user is superAdmin or admin
    const test = adminStatus;
    getAdminUserData(adminStatus);
  }, [user]);

  // check if aisettings have changed when adminUserData is updated
  useEffect(() => {
    if (adminUserDocRef.current) {
      compareAiSoftwareConfigSettings();
    }
  }, [adminUserData]);

  useEffect(() => {
    fetchAdminPlanData()
      // make sure to catch any error
      .catch(console.error);
  }, [adminUserData, customersData]);

  const fetchAdminPlanData = useCallback(async () => {
    if (adminUserData.isAdmin === true && adminUserData.userDoc && customersData.loaded === true) {
      getAdminPlanData();
    }
  }, [adminUserData, customersData]);

  // no admin doc found
  if (adminUserData.loaded === true && adminUserData.isAdmin === false) {
    return (
      <Container component="main" maxWidth="lg">
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#fff",
            border: `1px solid ${theme.palette.divider}`,
            padding: 4,
          }}
        >
          <LoadingPane
            text={"No Admin Data Found"}
            showSpinner={false}
          // progress={100}
          />
        </Box>
      </Container>
    );
  }

  // invalid eapikey set
  if (adminApiKeyStatus && adminApiKeyStatus.eapiKey === "invalid") {
    return (
      <Container component="main" maxWidth="lg">
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#fff",
            border: `1px solid ${theme.palette.divider}`,
            padding: 4,
          }}
        >
          <NoApiKeyPane text={"Agency API key is invalid, please update."} buttonText={'UPDATE API KEY'} showSpinner={false} />
        </Box>
      </Container>
    );
  }

  // missing api key
  // if (adminUserData.loaded === true && adminUserData.isAdmin === true && adminUserData.userDoc) {
  //   if (adminUserData.userDoc.apikey === undefined || adminUserData.userDoc.apikey === "") {
  //     return <AdminApiSetupPane />;
  //   }
  // }

  // customerData is synced and snapshot loaded
  if (customersDataSynced && customersData.data && customersData.loaded && adminPlanData.loaded === true) {
    // const test = customersData.data;
    return <AdminPane />;

    // return <button onClick={handleLogout}>Log out</button>
  }

  if (customersDataSynced && customersData.data && customersData.loaded && adminPlanData.loaded === false) {
    if (clog()) console.log("Loading Plans... adminUserData: ", adminUserData);
    return (
      <Container component="main" maxWidth="lg">
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#fff",
            border: `1px solid ${theme.palette.divider}`,
            padding: 4,
          }}
        >
          <LoadingPane text={"Loading Plans..."} showSpinner={true} progress={100} />
        </Box>
      </Container>
    );
  }

  if (customersDataSynced == false && customersData.loaded) {
    return (
      <Container component="main" maxWidth="lg">
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#fff",
            border: `1px solid ${theme.palette.divider}`,
            padding: 4,
          }}
        >
          <LoadingPane text={"Syncing Customer Data..."} showSpinner={true} progress={75} />
        </Box>
      </Container>
    );
  }

  if (customersData.loaded === false && customersEcData.data && customersEcData.loaded) {
    return (
      <Container component="main" maxWidth="lg">
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#fff",
            border: `1px solid ${theme.palette.divider}`,
            padding: 4,
          }}
        >
          <LoadingPane text={"Loading Customers..."} showSpinner={true} progress={50} />
        </Box>
      </Container>
    );
  }


  // no eapikey set
  if (customersEcData.error && customersEcData.error === "noEapiKey" && customersEcData.loaded) {
    return (
      <Container component="main" maxWidth="lg">
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#fff",
            border: `1px solid ${theme.palette.divider}`,
            padding: 4,
          }}
        >
          <NoApiKeyPane text={"No Agency API key set"} buttonText={'ADD API KEY'} showSpinner={false} />
        </Box>
      </Container>
    );
  }

  if (customersEcData.error && customersEcData.loaded) {
    return (
      <Container component="main" maxWidth="lg">
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#fff",
            border: `1px solid ${theme.palette.divider}`,
            padding: 4,
          }}
        >
          <LoadingPane text={"Error loading Ecomacy Data"} showSpinner={false} />
        </Box>
      </Container>
    );
  }

  if (customersData.loaded === false && customersEcData.loaded === false) {
    return (
      <Container component="main" maxWidth="lg">
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#fff",
            border: `1px solid ${theme.palette.divider}`,
            padding: 4,
          }}
        >
          <LoadingPane text={"Loading Ecomacy Data..."} showSpinner={true} progress={25} />
        </Box>
      </Container>
    );
  }

  /*
  if (errorUser) {
    return (
      <LoadingPane
        text={'Error User: ' + JSON.stringify(errorUser)}
        data={errorUser}
        showSpinner={false}
      />
    )
  }








  if (loadingUser) {
    return (
      <div>
        <p>Initialising User...</p>
      </div>
    );
  }


  if (errorUser) {
    return (
      <div>
        <p>Error: {errorUser}</p>
      </div>
    );
  }


  if (user) {
    return (
      <div>
        <p>testVal: {testVal}</p>
        <p>Current User: {user.email}</p>
        <button onClick={handleLogout}>Log out</button>
      </div>
    );
  }
*/
  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#fff",
          border: `1px solid ${theme.palette.divider}`,
          padding: 4,
        }}
      >
        <LoadingPane text={"Loading User..."} showSpinner={true} progress={10} />
      </Box>
    </Container>
  );

  /*
  let loaded = false
  let userDataObj = {
    userId: '',
    locationId: '',
    email: '',
    // lat: null,
    // lng: null,
    // website: '',
    // postcode: '',
  }
  const [userData, setUserData] = useState({
    userId: '',
    locationId: '',
    email: '',
    // lat: null,
    // lng: null,
    // website: '',
    // postcode: '',
  });

  const [userLoaded, setUserLoaded] = useState(false);


  useEffect(() => {
    if (
      userDataObj.userId !== ''
      && userDataObj.locationId !== ''
      // && userDataObj.email !== ''
      && userLoaded === false
    ) {
      setUserData(userDataObj)
      setUserLoaded(true)
    }
    else if (loaded === true && userLoaded === false) {
      setUserLoaded(true)
    }

    //setUser({ pending: true, value: undefined })
    // fetchUser().then((fetchedUser) => {
    //  setUser({ pending: true, value: fetchedUser })
    //})
  }, [userDataObj])


  if (errorUser) {
    return (
      <LoadingPane
        text={'Error User: ' + JSON.stringify(errorUser)}
        data={errorUser}
        showSpinner={false}
      />
    )
  }

  if (loadingUser) {
    return (
      <LoadingPane
        text={'Loading User...'}
        showSpinner={true}
      />
    )
  }

  if (user) {

    // get locationId from url
    const urlDataParams = getUrlParams()
    // userDataObj = {
    //   userId: userId,
    //   locationId: locationId,
    //   lat: lat,
    //   lng: lng,
    //   website: website,
    //   postcode: postcode,
    // }

    // trigger state change
    userDataObj = {
      userId: user.uid,
      email: urlDataParams.email,
      locationId: urlDataParams.locationId,
    }

    loaded = true



    if (userData.userId !== '' && userData.locationId !== '' && userLoaded === true) {
      return (
        <GridKeywordsPane
          userData={userData}
        />
      )
    }
    else if (userData.userId === '' && userData.locationId === '' && userLoaded === true) {

      if (!userData.userId || userData.userId === '') {
        console.warn('Missing User ID')
      }

      if (!userData.locationId || userData.locationId === '') {
        console.warn('Missing Location ID')
      }

      return (
        <LoadingPane
          text={'Missing user data, please contact support.'}
          showSpinner={false}
        />
      )
    }

  }
  else {
    // not able to log in contact support
    return (
      <LoadingPane
        text={'Not able to log in, please contact support'}
        showSpinner={false}
      />
    )

  }

*/
};

export default React.memo(AdminContainer);
