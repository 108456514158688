import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";

import {
  getFirestore,
  collection,
  query,
  where,
  // orderBy
} from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { firebase } from "../code/firebase";
import { processKeywordsUserData } from "../code/ecomacy";

import { RemoteConfigAuthContext } from "../helpers/RemoteConfigAuthProvider";
import { CustomerAuthContext } from "../helpers/CustomerAuthProvider";
import { AiAuthContext } from "../helpers/AiAuthProvider";
import {setd, debug, setc, clog} from "../helpers/HelperFunctions";

import { getActiveTree } from "../helpers/AiEngineerTrees";

import _ from "lodash";

import { useTheme, styled } from "@mui/material/styles";

import {
  Box,
  Tab,
  Tabs,
  Typography,
  Button,
  IconButton,
  Grid,
  Paper,
  Menu,
  MenuItem,
  ListSubheader,
  InputAdornment,
  Divider,
  // Tooltip,
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
} from "@mui/material";

import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import TextField from "@mui/material/TextField";

import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import EditIcon from "@mui/icons-material/EditOutlined";
import SendIcon from "@mui/icons-material/Send";
import RefreshIcon from "@mui/icons-material/Refresh";
import SyncIcon from "@mui/icons-material/Sync";
import SettingsIcon from "@mui/icons-material/Settings";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CircleIcon from "@mui/icons-material/Circle";
import ArrowBackIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardIos";
// import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';

import Container from "@mui/material/Container";
import GridKeywordTimelinePane from "./GridKeywordTimelinePane";
import LoadingSubPane from "./LoadingSubPane";
import { re } from "mathjs";
import { set } from "date-fns";

const BorderTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    // boxShadow: theme.shadows[1],
  },
  [`& .${tooltipClasses.arrow}::before`]: {
    color: theme.palette.common.white,
    // boxShadow: theme.shadows[1],

    border: `1px solid ${theme.palette.primary.main}`,
    boxSizing: "border-box",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    // boxShadow: theme.shadows[1],
    fontSize: "14px",
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

const AiEngineerPanel = (props) => {
  const { userData } = props;
  const theme = useTheme();

  const { rc } = useContext(RemoteConfigAuthContext);


  const { customerUserData, setCustomerUserData } = useContext(CustomerAuthContext);

  const { testVal, setTestVal } = useContext(AiAuthContext);

  const { aiQuestionsEngineerPanelRef } = useContext(AiAuthContext);

  const { saveAiConversationLocalStorage } = useContext(AiAuthContext);

  const { aiSettingsData, setAiSettingsData } = useContext(AiAuthContext);
  const { aiSettingsDataUpdated, setAiSettingsDataUpdated } = useContext(AiAuthContext);
  const { aiSettingsDataRef } = useContext(AiAuthContext);

  const { aiStrategySelectedRefresh, setAiStrategySelectedRefresh } = useContext(AiAuthContext);
  const { aiModelSelectedRefresh, setAiModelSelectedRefresh } = useContext(AiAuthContext);

  const { aiEngineersPanelShow, setAiEngineersPanelShow } = useContext(AiAuthContext);
  const { aiEngineersPanelHeightResizing, setAiEngineersPanelHeightResizing } = useContext(AiAuthContext);
  const aiEngineersPanelHeightResizingRef = useRef(false)
  const { aiChatInput, setAiChatInput } = useContext(AiAuthContext);
  const { aiEngineerChatInput, setAiEngineerChatInput } = useContext(AiAuthContext);
  const [aiEngineerQuestionSaving, setAiEngineerQuestionSaving] = useState(false);

  const { postAiEngineerMessage } = useContext(AiAuthContext);
  const { postAiMessage } = useContext(AiAuthContext);
  const { resetAiMessages } = useContext(AiAuthContext);
  const { aiConversationsIsSaving, setAiConversationsIsSaving } = useContext(AiAuthContext);

  const { aiSettingsSelected, setAiSettingsSelected } = useContext(AiAuthContext);
  const { aiConversationsSelected, setAiConversationsSelected } = useContext(AiAuthContext);
  const { aiConversation, setAiConversation } = useContext(AiAuthContext);
  const [aiConversationChanged, setAiConversationChanged] = useState(false);

  const { aiConversationMessageCount } = useContext(AiAuthContext);

  const { aiPromptVersionSelected, setAiPromptVersionSelected } = useContext(AiAuthContext);

  const { getAiEngineersDocs } = useContext(AiAuthContext);
  const { aiEngineersDocs, setAiEngineersDocs } = useContext(AiAuthContext);
  const { aiEngineersSelected, setAiEngineersSelected } = useContext(AiAuthContext);

  const { aiEngineerConversation, setAiEngineerConversation } = useContext(AiAuthContext);
  const { aiEngineerConversationUpdated, setAiEngineerConversationUpdated } = useContext(AiAuthContext);
  const { getAiEngineerConversation } = useContext(AiAuthContext);
  const [aiEngineerConversationFetched, setAiEngineerConversationFetched] = useState({
    aiconversationid: "",
    aiengineerid: "",
    aiengineerconversationid: "",
  });
  const [aiEngineerConversationLoading, setAiEngineerConversationLoading] = useState(true);
  const [aiEngineerMessages, setAiEngineerMessages] = useState([]);
  const { aiEngineerConversationSelectDifferentQuestion } = useContext(AiAuthContext);
  const { updateAiEngineerConversationMessages } = useContext(AiAuthContext);

  const [showNewQuestionInput, setShowNewQuestionInput] = useState(-1);
  const aiEngineerNewQuestionInputOriginal = useRef(null);
  const [aiEngineerNewQuestionInput, setAiEngineerNewQuestionInput] = useState(null);
  const [aiEngineerNewQuestionSaving, setAiEngineerNewQuestionSaving] = useState(false);

  const { refreshAiConversationSettingsData, setRefreshAiConversationSettingsData } = useContext(AiAuthContext);
  const { duplicateAiConversation } = useContext(AiAuthContext);
  const { aiConversationDuplicatedRefresh, setAiConversationDuplicatedRefresh } = useContext(AiAuthContext);

  const { aiAgentsIsEditing, setAiAgentsIsEditing } = useContext(AiAuthContext);
  const { aiSettingsIsEditing, setAiSettingsIsEditing } = useContext(AiAuthContext);
  const { aiConversationsIsEditing, setAiConversationsIsEditing } = useContext(AiAuthContext);
  const { aiConversationFetchData, setAiConversationFetchData } = useContext(AiAuthContext);

  const { aiStrategySelected, setAiStrategySelected } = useContext(AiAuthContext);

  const { showAiCustomerSettingsDialog, setShowAiCustomerSettingsDialog } = useContext(AiAuthContext);
  const { checkCustomerApiKeys } = useContext(AiAuthContext);

  const [init, setInit] = useState(true);

  const scrollRef = useRef();

  // START: Resize Drawer
  let defaultDrawerHeight = 500;
  let minDrawerHeight = 70;
  let maxDrawerHeight = 600;

  const [drawerHeight, setDrawerHeight] = useState(defaultDrawerHeight);

  const handleMouseDown = (e) => {
    document.addEventListener("mouseup", handleMouseUp, true);
    document.addEventListener("mousemove", handleMouseMove, true);
    aiEngineersPanelHeightResizingRef.current = true;
    setAiEngineersPanelHeightResizing(true);
  };

  const handleMouseUp = () => {
    document.removeEventListener("mouseup", handleMouseUp, true);
    document.removeEventListener("mousemove", handleMouseMove, true);
    setAiEngineersPanelHeightResizing(false);
    // scroll to bottom of panel
    // scrollRefIntoViewX();
  };

  const handleMouseMove = useCallback((e) => {
    if (aiQuestionsEngineerPanelRef.current) {
      // if (clog()) console.log("====================");
      // if (clog()) console.log("aiQuestionsEngineerPanelRef:", aiQuestionsEngineerPanelRef.current.offsetHeight);
      // if (clog()) console.log("e.clientY", e.clientY);
      // if (clog()) console.log("minDrawerHeight", minDrawerHeight);
      // if (clog()) console.log("maxDrawerHeight", maxDrawerHeight);

      // maxDrawerHeight = e.clientY - 100

      const newHeight = aiQuestionsEngineerPanelRef.current.offsetHeight + 46 - e.clientY;

      if (newHeight <= minDrawerHeight) {
        setAiEngineersPanelShow(false);
      }

      if (newHeight > minDrawerHeight) {
        setAiEngineersPanelShow(true);
      }

      if (newHeight > minDrawerHeight && newHeight < maxDrawerHeight) {
        // if (drawerHeight > minDrawerHeight && drawerHeight < maxDrawerHeight) {
        // if (clog()) console.log("newHeight", newHeight);
        // if (clog()) console.log("drawerHeight", drawerHeight);
        setDrawerHeight(newHeight);
      }

      let minHeightReached = false;
      let maxHeightReached = false;
      if (newHeight <= minDrawerHeight) {
        // if (clog()) console.log('minHeightReached')
        minHeightReached = true;
      }

      if (newHeight >= maxDrawerHeight) {
        // if (clog()) console.log('maxHeightReached:', maxDrawerHeight)
        maxHeightReached = true;
      }
    }
  }, []);
  // END: Resize Drawer

  const messageCount = useRef(0);
  // if (clog()) console.log('AiChatPanel aiSettingsData', aiSettingsData);

  // useEffect(() => {
  //   const getUserStatus = async () => {
  //     const idTokenResult = await getSetAdminStatus();
  //   };

  //   if (user) {
  //     getUserStatus()
  //     .catch(console.error);
  //   }
  // }, [user]);

  // initial load - only needs to happen once
  useEffect(() => {
    const fetchAiEngineers = async () => {
      const result = await getAiEngineersDocs(userData);
    };
    if (init) {
      fetchAiEngineers()
        // make sure to catch any error
        .catch(console.error);
      setInit(false);
    }
  }, [init]);

  // when aiengineer docs are updated
  // useEffect(() => {
  //   // const fetchAiEngineers = async () => {
  //   //   const result = await getAiEngineersDocs(userData);
  //   // };
  //   if (!init && aiEngineersDocs) {
  //     // check if aiEngineersSelected is set
  //     if (
  //       aiEngineersSelected &&
  //       aiEngineersSelected.aiengineerid &&
  //       aiEngineersSelected.aiengineerid !== "" &&
  //       aiEngineersSelected.aiengineerconversationid === ""
  //       ) {

  //       debugger
  //       //
  //     }
  //     // fetchAiEngineers()
  //     //   // make sure to catch any error
  //     //   .catch(console.error);
  //     //setInit(false);
  //   }
  // }, [aiEngineersDocs]);

  // aiConversation is changed - should only run if aiEngineerConversationFetched.aiconversationid does not match
  useEffect(() => {
    if (
      // !init &&
      // aiConversationsSelected &&
      aiConversation &&
      aiConversation.aiconversationid &&
      aiEngineerConversationFetched.aiconversationid !== aiConversation.aiconversationid &&
      aiEngineersDocs
    ) {
      let sameAiEngineer = false;
      ;
      let aiengineerid = "";
      if (aiConversation.localstorage && aiConversation.localstorage.aiengineerid && aiConversation.localstorage.aiengineerid !== "") {
        aiengineerid = aiConversation.localstorage.aiengineerid;
      }

      var foundIdx = _.findIndex(aiEngineersDocs, { aiengineerid: aiengineerid });
      if (foundIdx > -1) {
        const found = aiEngineersDocs[foundIdx];
        if (aiEngineerConversationFetched.aiconversationid !== "" && found.aiengineerid === aiEngineerConversationFetched.aiengineerid) {
          sameAiEngineer = true;
        }

        setAiEngineersSelected(found);
      } else {
        if (aiEngineerConversationFetched.aiconversationid !== "" && "0" === aiEngineerConversationFetched.aiengineerid) {
          sameAiEngineer = true;
        }
        setAiEngineersSelected({
          aiengineerid: "0",
        });
      }

      // if aiconversationid is not empty then conversation has changed but has the same engineer
      if (sameAiEngineer) {
        setAiConversationChanged(true);
      }
    }
  }, [aiConversation]);

  const cleanAiEngineerStateData = () => {
    // if (clog()) console.log('----------------------------------------------------------------------------------------------------------------------------------')
    // if (clog()) console.log('aiConversation:', aiConversation)
    // if (clog()) console.log('aiEngineerMessages:', aiEngineerMessages)
    // if (clog()) console.log('aiEngineersSelected:', aiEngineersSelected)
    // if (clog()) console.log('aiEngineerConversation:', aiEngineerConversation)
    // if (clog()) console.log('aiEngineerConversationFetched:', aiEngineerConversationFetched)
    // if (clog()) console.log('aiEngineerConversationLoading:', aiEngineerConversationLoading)
    // clean and set aiEngineerConversationFetched
    let aiconversationid = "";
    if (aiConversation && aiConversation.aiconversationid && aiConversation.aiconversationid !== "") {
      aiconversationid = aiConversation.aiconversationid;
    }

    let aiengineerid = "";
    if (aiEngineersSelected && aiEngineersSelected.aiengineerid && aiEngineersSelected.aiengineerid !== "") {
      aiengineerid = aiEngineersSelected.aiengineerid;
    }

    const engineerConversationFetched = { ...aiEngineerConversationFetched };

    if (aiconversationid !== engineerConversationFetched.aiconversationid) {
      engineerConversationFetched.aiconversationid = aiconversationid;
    }

    if (aiengineerid !== engineerConversationFetched.aiengineerid) {
      engineerConversationFetched.aiengineerid = aiengineerid;
    }

    engineerConversationFetched.aiengineerconversationid = "";

    setAiEngineerConversationFetched(engineerConversationFetched);

    // clean messages
    if (aiEngineerMessages.length > 0) {
      // clean Engineer Message data
      setAiEngineerMessages([]);
    }

    // set loading
    if (aiEngineerConversationLoading) {
      setAiEngineerConversationLoading(false);
    }

    return;
  };
  // aiEngineersSelected is changed
  useEffect(() => {
    if (aiEngineersSelected && aiEngineersSelected.aiengineerid !== "" && aiEngineersSelected.aiengineerid === "0") {
      cleanAiEngineerStateData();
    } else if (aiEngineersSelected && aiEngineersSelected.aiengineerid !== "") {
      fetchAiEngineerConversationData()
        // make sure to catch any error
        .catch(console.error);
    }
  }, [aiEngineersSelected]);

  // conversation has changed but selected aiEngineer is the same
  useEffect(() => {
    if (aiConversationChanged) {
      ;
      if (aiEngineersSelected && aiEngineersSelected.aiengineerid !== "" && aiEngineersSelected.aiengineerid === "0") {
        cleanAiEngineerStateData();
      } else if (aiEngineersSelected && aiEngineersSelected.aiengineerid !== "") {
        fetchAiEngineerConversationData()
          // make sure to catch any error
          .catch(console.error);
      }
      setAiConversationChanged(false);
    }
  }, [aiConversationChanged]);

  const fetchAiEngineerConversationData = useCallback(async () => {
    // if (clog()) console.log('==================================================================================================================================')
    // if (clog()) console.log('aiEngineerMessages:', aiEngineerMessages)
    // if (clog()) console.log('aiEngineersSelected:', aiEngineersSelected)
    // if (clog()) console.log('aiEngineerConversation:', aiEngineerConversation)
    // if (clog()) console.log('aiEngineerConversationFetched:', aiEngineerConversationFetched)
    // if (clog()) console.log('aiEngineerConversationLoading:', aiEngineerConversationLoading)

    // if (aiEngineerConversation === null) {
    //   if (aiEngineerConversationLoading) {
    //     setAiEngineerConversationLoading(false);
    //   }
    //   return;
    // }

    // setAiEngineerConversationFetch(false);
    setAiEngineerConversationLoading(true);
    let fetchedData = {
      aiconversationid: "",
      aiengineerid: "",
      aiengineerconversationid: "",
    };
    if (aiConversation && aiConversation.aiconversationid) {
      fetchedData.aiconversationid = aiConversation.aiconversationid;
    }

    if (aiConversation && aiEngineersSelected && aiEngineersSelected.aiengineerid !== "0") {
      if (aiEngineersSelected && aiEngineersSelected.aiengineerid) {
        fetchedData.aiengineerid = aiEngineersSelected.aiengineerid;
      }

      const result = await getAiEngineerConversation(userData, aiConversation, aiEngineersSelected);
      // trigger useEffect in AiHistoryPanel
      // setRefreshAiConversationSettingsDataX(true);

      if (result && result.data) {
        if (result.data.aiengineerconversationid) {
          fetchedData.aiengineerconversationid = result.data.aiengineerconversationid;
        }

        setAiEngineerConversationUpdated(result.data);
      } else {
        setAiEngineerConversationUpdated(null);
        // clean Engineer Message data
        setAiEngineerMessages([]);

        // cancel load
        setAiEngineerConversationLoading(false);
      }

      if (
        fetchedData.aiconversationid !== aiEngineerConversationFetched.aiconversationid ||
        fetchedData.aiengineerid !== aiEngineerConversationFetched.aiengineerid ||
        fetchedData.aiengineerconversationid !== aiEngineerConversationFetched.aiengineerconversationid
      ) {
        setAiEngineerConversationFetched(fetchedData);
      }
    } else {
      // clean Engineer Conversation data (will trigger useEffect [aiEngineerConversation])
      if (aiEngineerConversation) {
        setAiEngineerConversation(null);
        // cancel loading in useEffect [aiEngineerConversation]
        // setAiEngineerConversationLoading(false);
      } else {
        // just cancel loading
        setAiEngineerConversationLoading(false);
      }

      if (
        fetchedData.aiconversationid !== aiEngineerConversationFetched.aiconversationid ||
        fetchedData.aiengineerid !== aiEngineerConversationFetched.aiengineerid ||
        fetchedData.aiengineerconversationid !== aiEngineerConversationFetched.aiengineerconversationid
      ) {
        setAiEngineerConversationFetched(fetchedData);
      }
    }

    //
  }, [getAiEngineerConversation]);

  useEffect(() => {
    // sets aiEngineerConversationUpdated to value of aiEngineerConversation
    const updateEngineerConversationAndMessages = async () => {
      setAiEngineerConversationUpdated(aiEngineerConversation);

      // check if aiEngineerConversationFetched needs updating
      // triggers when new aiEngineerConversation is added after first message
      if (
        aiEngineerConversation &&
        aiEngineerConversationFetched &&
        aiEngineerConversationFetched.aiengineerconversationid === "" &&
        // aiconversationid matches
        aiEngineerConversation.aiconversationid === aiEngineerConversationFetched.aiconversationid &&
        // aiengineerid matches
        aiEngineerConversation.aiengineerid === aiEngineerConversationFetched.aiengineerid
      ) {
        // everything matches apart from empty aiengineerconversationid in aiEngineerConversationFetched
        setAiEngineerConversationFetched({
          ...aiEngineerConversationFetched,
          aiengineerconversationid: aiEngineerConversation.aiengineerconversationid,
        });
      }

      if (aiEngineerConversation.messages && aiEngineerConversation.messages.length > 0) {
        const activeMessageTree = await getActiveTree(aiEngineerConversation.messages);
        // const activeMessageTree = aiEngineerConversation.messages;
        setAiEngineerMessages(activeMessageTree);
        // // setAiChatMessagesData(result.data.messages)
        // messageCount.current = activeMessageTree.length;
      } else {
        setAiEngineerMessages([]);
        // messageCount.current = 0;
      }
      // cancel loading
      if (aiEngineerConversationLoading) setAiEngineerConversationLoading(false);
    };

    if (!init && aiEngineerConversation) {
      ;
      updateEngineerConversationAndMessages();
    } else if (aiEngineersSelected && aiEngineersSelected.aiengineerid === "0") {
      if (aiEngineerConversationLoading) {
        setAiEngineerMessages([]);
        setAiEngineerConversationLoading(false);
      }
    }

    // else {
    //   if (aiEngineerConversationLoading) {
    //     debugger;
    //     setAiEngineerConversationLoading(false);
    //   }
    // }
  }, [aiEngineerConversation]);

  // const scrollToBottom = () => {
  //   if (aiEngineersPanelShow) {
  //     if (aiEngineerMessages.length !== 0) {
  //       var element = document.getElementById("engineerMessagesScrollWrapper");
  //       // element.scrollHeight - lastmessage
  //       element.scrollTop = element.scrollHeight;
  //     }
  //   }
  // };

  // run after every render
  useEffect(() => {
    if (
      !aiEngineersPanelHeightResizing &&
      aiEngineersPanelHeightResizingRef.current === false
    ) {
      // if (clog()) console.log('useEffect EveryRender XXX')
      scrollRefIntoView()
    }
    else if (!aiEngineersPanelHeightResizing && aiEngineersPanelHeightResizingRef.current) {
      // resize has just finished so reset
      aiEngineersPanelHeightResizingRef.current = false
    }
  });

  const scrollRefIntoView = () => {
    // if element exists then scroll into view
    if (scrollRef.current) {
      // param: defaultVal
      //  ...options
      // behavior:"auto",
      //   auto | smooth
      // block: "start",
      //   start | center | end | nearest
      // inline:"nearest"
      //   start | center | end | nearest
      const opts = {
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      }
      scrollRef.current.scrollIntoView(opts)
    }
  };

  const isScollRef = (type, index = -1) => {
    let isSelected = false

    // aiMessagesUserSending <- human sent message, waiting for it to appear
    // isChatWaitingForAiResponse <- human message appeared, waiting for AI response
    const isWaiting = isChatWaitingForAiResponse()
    // if (isWaiting === false && aiMessagesUserSending === false) {
    if (isWaiting === false) {
      if ("message" === type) {
        if (index === aiEngineerMessages.length - 1) {
          isSelected = true
        }
      }
    }

    return isSelected

  }
  //// END: Scroll Into View

  useEffect(() => {
    const updateEngineerMessages = () => {
      // New Question
      // if aiEngineerNewQuestionSaving is true
      if (aiEngineerNewQuestionSaving === true) {
        // needs more logic
        // const lastMessage = aiEngineerConversation.messages[aiEngineerConversation.messages.length - 1];
        const lastMessage = aiEngineerMessages[aiEngineerMessages.length - 1];

        // if last item in messages has role 'assistant'
        if (lastMessage.role === "assistant") {
          // if so then set aiEngineerNewQuestionSaving to false
          setAiEngineerNewQuestionSaving(false);
          // reset new question input
          setShowNewQuestionInput(-1);
          setAiEngineerNewQuestionInput(null);
          aiEngineerNewQuestionInputOriginal.current = null;

          setAiSettingsIsEditing({
            active: false,
            type: "",
          });
        }
      }

      // Normal Question
      // if aiEngineerQuestionSaving is true
      if (aiEngineerQuestionSaving === true) {
        // needs more logic
        // const lastMessage = aiEngineerConversation.messages[aiEngineerConversation.messages.length - 1];
        const lastMessage = aiEngineerMessages[aiEngineerMessages.length - 1];
        // if last item in messages has role 'assistant'
        if (lastMessage.role === "assistant") {
          // if so then set aiEngineerQuestionSaving to false
          setAiEngineerQuestionSaving(false);

          setAiSettingsIsEditing({
            active: false,
            type: "",
          });
        }
      }
      // update message count ref
      // messageCount.current = aiEngineerMessages.length;

      if (aiEngineerMessages && aiEngineerMessages.length !== messageCount) {
        // update message count ref
        messageCount.current = aiEngineerMessages.length;
        // scroll to bottom
        // if (clog()) console.log('useEffect updateEngineerMessages XXX')
        scrollRefIntoView();
      }
    };

    updateEngineerMessages();
  }, [aiEngineerMessages]);

  // show engineer panel is turned on
  useEffect(() => {
    if (aiEngineersPanelShow) {
      // scroll to bottom
      // if (clog()) console.log('useEffect aiEngineersPanelShow XXX')
      scrollRefIntoView();
    }
  }, [aiEngineersPanelShow]);
  // useEffect(() => {
  //   const scrollToBottom = () => {
  //     var element = document.getElementById("engineerMessagesScrollWrapper");
  //     element.scrollTop = element.scrollHeight;
  //   };
  //   if (aiEngineerConversationUpdated && aiEngineerConversationUpdated.messages && aiEngineerConversationUpdated.messages.length !== messageCount) {
  //     // update message count ref
  //     messageCount.current = aiEngineerConversationUpdated.messages.length;
  //     // scroll to bottom
  //     scrollToBottom();
  //   }
  // }, [
  //   aiEngineerConversationUpdated,
  //   // scrollToBottom
  // ]);

  const getSelectedEngineerData = (engineerId) => {
    let selected = null;
    if (engineerId && engineerId !== "") {
      var foundIdx = _.findIndex(aiEngineersDocs, { aiengineerid: engineerId });
      if (foundIdx > -1) {
        selected = aiEngineersDocs[foundIdx];
      }

      return selected;
    }

    return selected;
  };

  const getSelectedEngineerId = () => {
    let selected = "0";
    // if (aiEngineersSelected && aiEngineersSelected.aiengineerid && aiEngineersSelected.aiengineerid !== "") {
    if (aiEngineersSelected && aiEngineersSelected.aiengineerid && aiEngineersSelected.aiengineerid !== "0") {
      selected = aiEngineersSelected.aiengineerid;
    }

    return selected;
  };

  const handleOnChangeEngineers = async (event) => {
    const val = event.target.value;
    let saveData = null;

    // check that val is not the same as current aiEngineersSelected
    if (val === aiEngineersSelected.aiengineerid) {
      return;
    }

    if (val === "0" || val === "autoengineer") {
      saveData = {
        aiengineerid: val,
      };
    } else {
      var foundIdx = _.findIndex(aiEngineersDocs, { aiengineerid: val });
      if (foundIdx > -1) {
        saveData = aiEngineersDocs[foundIdx];
      } else {
        saveData = {
          aiengineerid: "0",
        };
      }
    }
    // save to aiconversations.localstorage
    const result = await saveAiConversationLocalStorage(userData, aiConversationsSelected, "aiengineerid", saveData);
    ;
    if (result && result.data) {
      if (saveData.aiengineerid === "") {
        // clean all related states as no doc fetch will take place

        setAiEngineerConversation(null);
      }
      setAiEngineersSelected(saveData);
    }
  };

  const isAiEngineersSelectReadOnly = () => {
    let readonly = false;

    if (aiSettingsIsEditing.active && aiSettingsIsEditing.type === "promptVersionChange") {
      readonly = true;
    }

    return readonly;
  };

  const isAiEngineersSelectDisabled = () => {
    let disabled = false;

    // question is being edited
    if (showNewQuestionInput > -1) {
      disabled = true;
    }
    // if (aiEngineerChatInput && aiEngineerChatInput !== "") {
    //   disabled = false;
    // }

    if (aiAgentsIsEditing.active || aiConversationsIsEditing.active) {
      disabled = true;
    }

    if (
      aiSettingsIsEditing.active &&
      aiSettingsIsEditing.type !== "engineerNewQuestionX" &&
      aiSettingsIsEditing.active &&
      aiSettingsIsEditing.type !== "promptVersionChange"
    ) {
      disabled = true;
    }

    if (!aiEngineersPanelShow) {
      disabled = true;
    }

    // if (aiEngineerConversationUpdated && aiEngineerConversationUpdated.improveprocessing) {
    //   disabled = true;
    // }

    return disabled;
  };

  const handleOnChangeChatInput = (e) => {
    // if (clog()) console.log('handleOnChangeChatInput:', e.target.value);
    setAiEngineerChatInput(e.target.value);
  };

  const handleOnSubmitSend = async () => {

    const checkApiKeys = checkCustomerApiKeys('engineer', customerUserData, aiSettingsDataUpdated)
    if (checkApiKeys.show) {
      // open settings dialog
      setShowAiCustomerSettingsDialog({
        show: checkApiKeys.show,
        type: checkApiKeys.type,
        message: "engineer",
      })
      return
    }

    // call api
    setAiEngineerChatInput("");

    const userMessageData = [
      {
        role: "user",
        content: aiEngineerChatInput,
      },
    ];

    // aiEngineersSelected

    const aiEngineerId = aiEngineersSelected.aiengineerid;
    const messageId = -1;

    // SF
    const { data, error } = await postAiEngineerMessage(userData, aiEngineerId, aiConversationsSelected, userMessageData, messageId);

    if (data) {
      // set aiEngineerQuestionSaving
      setAiEngineerQuestionSaving(true);
    }
    // set freeze state
    // setAiChatInputFreeze(false)

    // SF: messages stuff
    if (clog()) console.log("handleOnSubmitSend:", userMessageData);
  };

  // const handleOnClickReset = async () => {
  //   setAiConversationsIsSaving(true);
  //   if (clog()) console.log("handleOnClickReset");
  //   // const result = await duplicateAiSettings()
  //   const resetMessageData = {
  //     role: "aireset",
  //     content: "",
  //   };

  //   const result = await resetAiMessages(userData, aiConversationsSelected, resetMessageData);

  //   if (result && result.data && result.data.aiconversationid && result.data.aiconversationid !== "") {
  //     // const val = result.data.aiconversationid;
  //   }
  //   setAiConversationsIsSaving(false);
  // };

  const isChatInputDisabled = () => {
    let disabled = false;

    if (aiAgentsIsEditing.active || aiConversationsIsEditing.active || aiConversationsIsSaving) {
      disabled = true;
    }

    if (aiSettingsIsEditing.active && aiSettingsIsEditing.type !== "improvePromptProcessing") {
      disabled = true;
    }

    if (aiEngineerConversationUpdated && aiEngineerConversationUpdated.improveprocessing) {
      disabled = true;
    }

    if (aiEngineerMessages && aiEngineerMessages.length > 0 && aiEngineerMessages[aiEngineerMessages.length - 1].role === "user") {
      disabled = true;
    }

    return disabled;
  };

  const isSendDisabled = () => {
    let disabled = true;
    if (aiEngineerChatInput && aiEngineerChatInput !== "") {
      disabled = false;
    }

    if (aiAgentsIsEditing.active || aiConversationsIsEditing.active || aiConversationsIsSaving) {
      disabled = true;
    }

    if (aiSettingsIsEditing.active && aiSettingsIsEditing.type !== "improvePromptProcessing") {
      disabled = true;
    }

    if (aiEngineerConversationUpdated && aiEngineerConversationUpdated.improveprocessing) {
      disabled = true;
    }

    return disabled;
  };

  const isResetDisabled = () => {
    let disabled = false;

    if (aiAgentsIsEditing.active || aiSettingsIsEditing.active || aiConversationsIsEditing.active || aiConversationsIsSaving) {
      disabled = true;
    }

    return disabled;
  };

  const isAskDifferentQuestionDisabled = () => {
    let disabled = false;

    if (aiAgentsIsEditing.active || aiConversationsIsEditing.active || aiConversationsIsSaving) {
      disabled = true;
    }

    if (aiSettingsIsEditing.active && aiSettingsIsEditing.type !== "improvePromptProcessing") {
      disabled = true;
    }

    if (aiEngineerConversationUpdated && aiEngineerConversationUpdated.improveprocessing) {
      disabled = true;
    }

    return disabled;
  };

  const handleAskDifferentQuestion = (message) => {
    if (clog()) console.log("handleAskDifferentQuestion:", message);

    // find path value in choice array and get index
    let foundIdx = -1;
    if (message.choice && message.choice.length > 0 && message.path !== undefined && message.path > -1) {
      foundIdx = message.choice.indexOf(message.path);
    }

    if (foundIdx > -1) {
      setAiSettingsIsEditing({
        active: true,
        type: "engineerNewQuestion",
      });

      // add current question value
      setAiEngineerNewQuestionInput(message.question[foundIdx]);
      // se original message value to compare against
      aiEngineerNewQuestionInputOriginal.current = message.question[foundIdx];
      setShowNewQuestionInput(message.id);
    } else {
      setAiSettingsIsEditing({
        active: false,
        type: "",
      });

      setAiEngineerNewQuestionInput(null);
      aiEngineerNewQuestionInputOriginal.current = null;
      setShowNewQuestionInput(-1);
    }

    // call api
    // setAiEngineerChatInput("");

    // const userMessageData = [
    //   {
    //     role: "user",
    //     content: aiEngineerChatInput,
    //   },
    // ];

    // // SF
    // const { data, error } = await postAiEngineerMessage(userData, aiSettingsSelected, aiConversationsSelected, userMessageData);

    // set freeze state
    // setAiChatInputFreeze(false)

    // SF: messages stuff
    // if (clog()) console.log("handleAskDifferentQuestion:", userMessageData);
  };

  const handleSelectDifferentQuestion = async (type, engineerUpdated, message) => {
    if (clog()) console.log("handleSelectDifferentQuestion:", type);

    try {
      // update question path
      const updatedMessages = aiEngineerConversationSelectDifferentQuestion(type, engineerUpdated, message);
      // update aiengineerconversation in db
      const result = await updateAiEngineerConversationMessages(userData, engineerUpdated, updatedMessages);
      if (result && result.data) {
        // const val = result.data.aiconversationid;
      }
    } catch (err) {
      if (clog()) console.log("handleSelectDifferentQuestion error:", err);
      return;
    }
  };

  const isAskDifferentQuestionInputDisabled = () => {
    let disabled = false;

    if (aiEngineerNewQuestionSaving === true) {
      disabled = true;
    }
    // question is being edited

    // if (aiEngineerChatInput && aiEngineerChatInput !== "") {
    //   disabled = false;
    // }

    // if (aiAgentsIsEditing.active || aiConversationsIsEditing.active || aiConversationsIsSaving) {
    //   disabled = true;
    // }

    // if (aiSettingsIsEditing.active && aiSettingsIsEditing.type !== "improvePromptProcessing") {
    //   disabled = true;
    // }

    // if (aiEngineerConversationUpdated && aiEngineerConversationUpdated.improveprocessing) {
    //   disabled = true;
    // }

    return disabled;
  };

  const isSettingsMenuDisabled = () => {
    let disabled = false;

    if (aiAgentsIsEditing.active || aiSettingsIsEditing.active || aiConversationsIsEditing.active) {
      disabled = true;
    }

    return disabled;
  };

  const branchSelector = (message) => {
    let showSelector = false;

    let choice = [];
    if (message && message.choice) {
      choice = message.choice;
    }
    // temp
    // choice = [0, 6, 20];

    // number of choices
    const choiceLength = choice.length;

    if (choiceLength > 1) {
      showSelector = true;
    }

    if (showSelector) {
      // const messageId = message.id;
      let path = -1;
      if (message && message.path !== undefined) {
        path = message.path;
      }

      const pathIndex = choice.indexOf(path);

      //
      let pathIsFirstChoice = false;
      if (path === choice[0]) {
        pathIsFirstChoice = true;
      }

      let pathIsLastChoice = false;
      if (path === choice[choice.length - 1]) {
        pathIsLastChoice = true;
      }

      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            // width: "100%",
          }}
        >
          {pathIsFirstChoice ? (
            <ArrowBackIcon
              sx={{
                color: "grey.400",
                fontSize: "11px",
              }}
            />
          ) : (
            <ArrowBackIcon
              sx={{
                color: "grey.800",
                cursor: "pointer",
                fontSize: "11px",
              }}
              onClick={async () => handleSelectDifferentQuestion("previous", aiEngineerConversationUpdated, message)}
            />
          )}

          <Typography
            sx={{
              lineHeight: "1",
            }}
          >
            {pathIndex + 1}
          </Typography>
          <Typography
            sx={{
              lineHeight: "1",
            }}
          >
            /
          </Typography>
          <Typography
            sx={{
              lineHeight: "1",
            }}
          >
            {choiceLength}
          </Typography>

          {pathIsLastChoice ? (
            <ArrowForwardIcon
              sx={{
                color: "grey.400",
                fontSize: "11px",
              }}
            />
          ) : (
            <ArrowForwardIcon
              sx={{
                color: "grey.800",
                cursor: "pointer",
                fontSize: "11px",
              }}
              onClick={async () => handleSelectDifferentQuestion("next", aiEngineerConversationUpdated, message)}
            />
          )}
        </Box>
      );
    } else {
      return <></>;
    }
  };

  const handleOnClickAiEngineerEdit = () => {
    //
    if (aiSettingsIsEditing.active === false) {
    } else {
      // activate edit mode
      setAiSettingsIsEditing({
        active: false,
        type: "",
      });

      // reset new question input
      setShowNewQuestionInput(-1);
      setAiEngineerNewQuestionInput(null);
      aiEngineerNewQuestionInputOriginal.current = null;
    }
  };

  const handleOnClickAiEngineerNewQuestionSave = async (message) => {
    setAiEngineerNewQuestionSaving(true);

    const userMessageData = [
      {
        role: "user",
        content: aiEngineerNewQuestionInput,
      },
    ];

    const aiEngineerId = aiEngineersSelected.aiengineerid;
    const messageId = message.id;
    // SF
    const { data, error } = await postAiEngineerMessage(userData, aiEngineerId, aiConversationsSelected, userMessageData, messageId);
    // const data = 1;
    if (data) {
      // NOTE: now handled in useEffect aiEngineerMessages
      // if so then set aiEngineerNewQuestionSaving to false
      // setAiEngineerNewQuestionSaving(false);
      // // reset new question input
      // setShowNewQuestionInput(-1);
      // setAiEngineerNewQuestionInput(null);
      // aiEngineerNewQuestionInputOriginal.current = null;
      // setAiSettingsIsEditing({
      //   active: false,
      //   type: "",
      // });
    } else {
      setAiEngineerNewQuestionSaving(false);
    }

    if (clog()) console.log("handleOnSubmitSend:", userMessageData);
  };

  const isAiEngineerNewQuestionSaveDisabled = () => {
    let disabled = false;

    if (aiEngineerNewQuestionInput === "") {
      disabled = true;
    }

    if (aiEngineerNewQuestionInput === aiEngineerNewQuestionInputOriginal.current) {
      disabled = true;
    }

    // if (aiAgentsName !== "" && inUse) {
    //   disabled = true;
    // }

    // if (aiAgentsName !== "" && illegalChars) {
    //   disabled = true;
    // }

    // if (aiAgentsIsEditing.type === "add" && aiAgentsAutomationLink === "") {
    //   disabled = true;
    // }

    // if (aiAgentsSelected !== null && aiAgentsName === aiAgentsSelected.name) {
    //   disabled = true;
    // }

    // if (aiAgentsIsEditing.type === "add" && aiPresetSelected === "") {
    //   disabled = true;
    // }

    return disabled;
  };

  const isAiEngineerNewQuestionCancelDisabled = () => {
    let disabled = false;

    if (aiEngineerNewQuestionSaving) {
      disabled = true;
    }

    // if (aiAgentsName !== "" && inUse) {
    //   disabled = true;
    // }

    // if (aiAgentsName !== "" && illegalChars) {
    //   disabled = true;
    // }

    // if (aiAgentsIsEditing.type === "add" && aiAgentsAutomationLink === "") {
    //   disabled = true;
    // }

    // if (aiAgentsSelected !== null && aiAgentsName === aiAgentsSelected.name) {
    //   disabled = true;
    // }

    // if (aiAgentsIsEditing.type === "add" && aiPresetSelected === "") {
    //   disabled = true;
    // }

    return disabled;
  };

  const handleOnClickAiEngineerVersion = (message) => () => {
    // if (clog()) console.log("handleOnClickAiEngineerVersion:", message);
    let key = "prompt";

    let prompt = "";
    if (message && message.prompt !== undefined) {
      prompt = message.prompt;
    }

    if (prompt !== "") {
      // add prompt to aiSettingsUpdated
      // copied from AiQuestionsPanel > handleQuestionChange
      let dataOriginal = {};
      if (aiSettingsData && aiSettingsData[aiStrategySelected.strategy]) {
        dataOriginal = aiSettingsData[aiStrategySelected.strategy];
      }

      let data = {};
      if (aiSettingsDataUpdated && aiSettingsDataUpdated[aiStrategySelected.strategy]) {
        data = _.cloneDeep(aiSettingsDataUpdated[aiStrategySelected.strategy]);
      }
      data[key] = prompt;

      // compare data to dataOriginal
      const isEqual = _.isEqual(data, dataOriginal);
      if (isEqual === false) {
        // if different, set aiSettingsIsEditing
        setAiSettingsIsEditing({
          active: true,
          type: "model",
        });
      } else {
        // if same, set aiSettingsIsEditing
        setAiSettingsIsEditing({
          active: false,
          type: "",
        });
      }

      // if (clog()) console.log("handleQuestionChange val:", val);

      setAiSettingsDataUpdated({
        ...aiSettingsDataUpdated,
        [aiStrategySelected.strategy]: data,
      });
    }
  };

  const promptVersionSelector = (message) => {
    let showSelector = false;
    const test = message;

    let promptId = "";
    if (message && message.id !== undefined) {
      promptId = message.id;
      showSelector = true;
    }

    let prompt = "";
    if (message && message.prompt !== undefined) {
      prompt = message.prompt;
    }

    if (showSelector) {
      // const messageId = message.id;

      if (prompt === "") {
        return (
          <Box>
            <IconButton
              aria-label="Send"
              color="primary"
              variant="light"
              // onClick={handleOnClickAiEngineerVersion(message)}
              disabled={true}
              sx={{
                mb: "19px",
                "&.MuiIconButton-sizeSmall": {
                  fontSize: "14px",
                  // fontWeight: "700",
                },
              }}
              size="small"
            >
              {promptId}
            </IconButton>
          </Box>
        );
      } else {
        return (
          <Box>
            <BorderTooltip title={prompt} placement="top" arrow leaveDelay={200}>
              <IconButton
                aria-label="Send"
                color="primary"
                variant="light"
                onClick={handleOnClickAiEngineerVersion(message)}
                // disabled={isSendDisabled()}
                sx={{
                  mb: "19px",
                  "&.MuiIconButton-sizeSmall": {
                    fontSize: "14px",
                    // fontWeight: "700",
                  },
                }}
                size="small"
              >
                {promptId}
              </IconButton>
            </BorderTooltip>
          </Box>
        );
      }

      // needs to be fixed
      //if (promptId === aiPromptVersionSelected.promptversion) {
      //   return (
      //     <Box
      //       sx={{
      //         width: "30px",
      //         height: "30px",
      //         backgroundColor: "primary.main",
      //         color: "white",
      //         // cursor: "pointer",
      //         display: "flex",
      //         flexDirection: "row",
      //         alignItems: "center",
      //         justifyContent: "center",
      //         borderRadius: "4px",
      //         // fontWeight: "700",
      //       }}
      //     >
      //       <Typography>{promptVersion}</Typography>
      //     </Box>
      //   );
      // } else {

      //}
      // return (
      //   <Box
      //     sx={{
      //       display: "flex",
      //       flexDirection: "row",
      //       alignItems: "center",
      //       justifyContent: "center",
      //       // width: "100%",
      //     }}
      //   >
      //     {pathIsFirstChoice ? (
      //       <ArrowBackIcon
      //         sx={{
      //           color: "grey.400",
      //           fontSize: "11px",
      //         }}
      //       />
      //     ) : (
      //       <ArrowBackIcon
      //         sx={{
      //           color: "grey.800",
      //           cursor: "pointer",
      //           fontSize: "11px",
      //         }}
      //         onClick={async () => handleSelectDifferentQuestion("previous", aiEngineerConversationUpdated, message)}
      //       />
      //     )}

      //     <Typography
      //       sx={{
      //         lineHeight: "1",
      //       }}
      //     >
      //       {pathIndex + 1}
      //     </Typography>
      //     <Typography
      //       sx={{
      //         lineHeight: "1",
      //       }}
      //     >
      //       /
      //     </Typography>
      //     <Typography
      //       sx={{
      //         lineHeight: "1",
      //       }}
      //     >
      //       {choiceLength}
      //     </Typography>

      //     {pathIsLastChoice ? (
      //       <ArrowForwardIcon
      //         sx={{
      //           color: "grey.400",
      //           fontSize: "11px",
      //         }}
      //       />
      //     ) : (
      //       <ArrowForwardIcon
      //         sx={{
      //           color: "grey.800",
      //           cursor: "pointer",
      //           fontSize: "11px",
      //         }}
      //         onClick={async () => handleSelectDifferentQuestion("next", aiEngineerConversationUpdated, message)}
      //       />
      //     )}
      //   </Box>
      // );
    } else {
      return <></>;
    }
  };

  const handleOnChangeNextQuestionInput = (event) => {
    setAiEngineerNewQuestionInput(event.target.value);
  };

  const nextQuestionInput = (message) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
        // ref={scrollRef}
      >
        <TextField
          variant="standard"
          multiline
          sx={{
            // fontSize: "22px",
            borderWidth: "0px",
            mt: "-4px",
            "& .MuiInput-input": {
              fontSize: "15px",
              lineHeight: 1.57,
            },
            // "& .MuiOutlinedInput-input": {
            //   p: 0,
            // },
            "& fieldset": {
              border: "none",
              outline: "none",
            },
          }}
          fullWidth
          autoFocus
          name="nextQuestionInput"
          value={aiEngineerNewQuestionInput}
          // onChange={handleOnChangeChatInput}
          onChange={handleOnChangeNextQuestionInput}
          InputProps={{
            disableUnderline: true,
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter" && (event.ctrlKey || event.metaKey)) {
              if (aiEngineerNewQuestionInput !== "") {
                // if (clog()) console.log('Enter Pressed')
                handleOnClickAiEngineerNewQuestionSave(message);
              }
            }
          }}
          disabled={isAskDifferentQuestionInputDisabled()}
        ></TextField>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            mt: 2,
          }}
        >
          <Button
            aria-label="Cancel"
            color="primary"
            variant="outlined"
            // size="small"
            // variant="light"
            sx={{
              mr: 1,
            }}
            onClick={handleOnClickAiEngineerEdit}
            disabled={isAiEngineerNewQuestionCancelDisabled()}
          >
            Cancel
          </Button>

          {aiEngineerNewQuestionSaving ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "64px",
                height: "36.5px",
              }}
            >
              <CircularProgress
                size={12}
                sx={{
                  p: 0,
                  m: 0,
                }}
              // variant="determinate"
              />
            </Box>
          ) : (
            <Button
              aria-label="Save"
              color="primary"
              variant="contained"
              // size="small"
              // variant="light"
              onClick={() => handleOnClickAiEngineerNewQuestionSave(message)}
              disabled={isAiEngineerNewQuestionSaveDisabled()}
            >
              Save
            </Button>
          )}
        </Box>
      </Box>
    );
  };
  // if (clog()) console.log('AiChatPanel aiChatMessagesData', aiChatMessagesData);

  const isStartConversationDisabled = () => {
    let disabled = false;

    if (aiEngineersSelected === null) {
      disabled = true;
    }

    return disabled;
  };
  const handleOnClickStartConversation = async () => {
    if (clog()) console.log("handleOnClickStartConversation");
  };

  const handleOnClickAiEngineersPanelShow = () => {
    setAiEngineersPanelShow(!aiEngineersPanelShow);
  };

  const showFirstEngineerQuestion = () => {
    const m = aiEngineersSelected;

    // if (aiEngineerMessages && aiEngineerMessages.length > 0) {
    //   return <></>;
    // }

    if (aiEngineerMessages && aiEngineerMessages.length === 0) {
      return (
        <Box
          sx={{
            px: 2,
            py: 1,
          }}
        >
          <ReactMarkdown
            components={{
              // Map `h1` (`# heading`) to use `h2`s.
              h1: "h2",
              // Rewrite `em`s (`*like so*`) to `i` with a red foreground color.
              // em: ({node, ...props}) => <i style={{color: 'red'}} {...props} />
            }}
            children={m.question}
          />
        </Box>
      );
    }
  };

  const isChatWaitingForAiResponse = () => {

    let waiting = false;
    // last message is human so waiting for ai response
    if (aiEngineerMessages && aiEngineerMessages.length > 0 && aiEngineerMessages[aiEngineerMessages.length - 1].role === "user") {
      waiting = true
    }

    return waiting
  }

  const showEngineerWaitingResponse = () => {
    // const m = aiEngineersSelected;

    // if (aiEngineerMessages && aiEngineerMessages.length > 0) {
    //   return <></>;
    // }

    const isWaiting = isChatWaitingForAiResponse()

    // if last message is user then waiting for a response
    if (isWaiting) {
      return (
        <Grid
          item
          xs={12}
          key={'engineerawaitingresponse'}
          name={'engineerawaitingresponse'}
          sx={{
            px: 2,
            py: 1,
          }}
          ref={scrollRef}
        >
          <Box
            sx={{
              // backgroundColor: m.role === "assistant" ? "#efefef" : "#f9f9f9",
              backgroundColor: theme.palette.background.paper,
              borderRadius: "5px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: theme.palette.border.normal,
              boxShadow: theme.palette.boxshadow.normal,
              display: "flex",
              flexDirection: "row",
              minHeight: "56px",
              // backgroundColor: m.role === 'assistant' ? '#efefef' : '#fff',
            }}
          >
            <Box>
              <Box
                name={"roleIcon"}
                sx={{
                  width: "56px",
                  minWidth: "56px",
                  pt: 2,
                  // backgroundColor: '#cc0000',
                  display: "flex",
                  // alignItems: 'center',
                  justifyContent: "center",
                }}
              >
                <SupportAgentIcon
                  sx={{
                    color: "grey.500",
                  }}
                // color='primary'
                />
              </Box>
            </Box>

            <Box
              sx={{
                // backgroundColor: m.role === "assistant" ? "#efefef" : "#f9f9f9",
                flexGrow: 1,
                // px: 2,
                px: 0,
                py: 2,
                borderRadius: "5px",
              }}
            >
              <Box>
                <div className="dot-holder">
                  <div
                    className="dot-pulse"
                    style={{
                      "--ca-agentmessageloading": theme.palette.primary.main,
                    }}
                  ></div>
                </div>
              </Box>
            </Box>
            <Box
              sx={{
                width: "46px",
                minWidth: "46px",
                pt: "10px",
                px: "5px",
                display: "flex",
                // alignItems: 'center',
                justifyContent: "center",
              }}
            ></Box>
          </Box>
        </Grid>
      );
    }
  };

  const showEngineerMessages = () => {
    if (
      aiConversation &&
      aiConversation.aiconversationid &&
      aiConversation.aiconversationid !== "" &&
      aiEngineersSelected &&
      aiEngineersSelected.aiengineerid !== "0" &&
      // aiConversation &&
      // aiConversation.aiconversationid !== "" &&
      aiEngineerConversationFetched &&
      aiEngineerConversationFetched.aiconversationid &&
      aiEngineerConversationFetched.aiconversationid !== "" &&
      aiEngineerConversationFetched &&
      aiEngineerConversationFetched.aiengineerid &&
      aiEngineerConversationFetched.aiengineerid !== "" &&
      aiConversation.aiconversationid === aiEngineerConversationFetched.aiconversationid &&
      aiEngineersSelected.aiengineerid === aiEngineerConversationFetched.aiengineerid &&
      aiEngineerConversationLoading === false
    ) {
      // if (clog()) console.log(
      //   "----------------------------------------------------------------------------------------------------------------------------------"
      // );
      // if (clog()) console.log("aiConversation:", aiConversation);
      // if (clog()) console.log("aiEngineerMessages:", aiEngineerMessages);
      // if (clog()) console.log("aiEngineersSelected:", aiEngineersSelected);
      // if (clog()) console.log("aiEngineerConversation:", aiEngineerConversation);
      // if (clog()) console.log("aiEngineerConversationFetched:", aiEngineerConversationFetched);
      // if (clog()) console.log("aiEngineerConversationLoading:", aiEngineerConversationLoading);

      return (
        <Grid
          container
          sx={{
            my: 1,
          }}
        >
          {showFirstEngineerQuestion()}

          {aiEngineerMessages &&
            // aiEngineerConversationUpdated &&
            //aiEngineerConversationUpdated.messages &&
            // aiEngineerConversationUpdated.messages.map((m, i) => {
            aiEngineerMessages.map((m, i) => {
              // const test = m
              // check to see if next message exists
              // let settingsMessage = null;

              // const prevMessageIndex = i - 1;
              // if (prevMessageIndex > -1) {
              //   const prevMessage = aiEngineerConversationUpdated.messages[prevMessageIndex];
              //   // has the role of 'aisettings'
              //   if (prevMessage.role === "aisettings") {
              //     settingsMessage = prevMessage;
              //   }
              // }

              // let isLastMessage = false;
              // if (i === aiEngineerMessages.length - 1) {
              //   isLastMessage = true;
              // }

              if (m.role === "assistant" || m.role === "user") {
                // if (m.role === "assistant") {
                //   debugger
                // }

                return (
                  <Grid
                    item
                    xs={12}
                    key={m.id}
                    name={m.role}
                    sx={{
                      px: 2,
                      py: 1,
                    }}
                    ref={
                      isScollRef('message', i) || (m.id === showNewQuestionInput) ? scrollRef : null
                    }
                  >
                    <Box
                      sx={{
                        // backgroundColor: m.role === "assistant" ? "#efefef" : "#f9f9f9",
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: "5px",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        borderColor: theme.palette.border.normal,
                        boxShadow: theme.palette.boxshadow.normal,
                        display: "flex",
                        flexDirection: "row",
                        minHeight: "56px",
                        // backgroundColor: m.role === 'assistant' ? '#efefef' : '#fff',
                      }}
                    >
                      <Box>
                        <Box
                          name={"roleIcon"}
                          sx={{
                            width: "56px",
                            minWidth: "56px",
                            pt: 2,
                            // backgroundColor: '#cc0000',
                            display: "flex",
                            // alignItems: 'center',
                            justifyContent: "center",
                          }}
                        >
                          {m.role === "assistant" && (
                            <SupportAgentIcon
                              sx={{
                                color: "grey.500",
                              }}
                            // color='primary'
                            />
                          )}

                          {m.role === "user" && (
                            <AccountCircleIcon
                              sx={{
                                color: "grey.500",
                              }}
                            // color='primary'
                            />
                          )}
                        </Box>

                        {m.role === "user" && (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              // justifyContent: 'center',
                            }}
                          >
                            {branchSelector(m)}
                          </Box>
                        )}
                      </Box>

                      <Box
                        sx={{
                          // backgroundColor: m.role === "assistant" ? "#efefef" : "#f9f9f9",
                          flexGrow: 1,
                          // px: 2,
                          mt: 1,
                          px: 0,
                          py: 2,
                          borderRadius: "5px",
                        }}
                        // ref={m.id === showNewQuestionInput ? scrollRef : null}
                      >
                        {m.id === showNewQuestionInput ? (
                          nextQuestionInput(m)
                        ) : (
                          <Box>
                            {
                              // <Box>
                              //   <Typography sx={{ fontSize: "15px" }} value={i}>
                              //     choices: [{m.choice[0]}
                              //     {m.choice[1] && ", " + m.choice[1]}
                              //     {m.choice[2] && ", " + m.choice[2]}
                              //     {m.choice[3] && ", " + m.choice[3]}
                              //     {m.choice[4] && ", " + m.choice[4]}]
                              //   </Typography>
                              //   <Typography sx={{ fontSize: "15px" }} value={i}>
                              //     PATH: {m.path}
                              //   </Typography>
                              //   <Typography sx={{ fontSize: "15px" }} value={i}>
                              //     ID: {m.id}
                              //   </Typography>
                              //   <Typography sx={{ fontSize: "15px" }} value={i}>
                              //     ROLE: {m.role}
                              //   </Typography>
                              // </Box>
                            }

                            {m.role === "user" && (
                              <Typography
                                sx={{
                                  fontSize: "15px",
                                  // whiteSpace: "pre-line",
                                  whiteSpace: "pre-wrap",
                                }}
                              // value={i}
                              >
                                {m.content}
                              </Typography>
                            )}

                            {m.role === "assistant" && (
                              <Typography
                                sx={{
                                  fontSize: "15px",
                                  whiteSpace: "pre-wrap",
                                }}
                              // value={i}
                              >
                                {m.agentresponse}
                              </Typography>
                            )}
                          </Box>
                        )}
                      </Box>
                      <Box
                        sx={{
                          width: "46px",
                          minWidth: "46px",
                          pt: "10px",
                          px: "5px",
                          display: "flex",
                          // alignItems: 'center',
                          justifyContent: "center",
                        }}
                      >
                        {m.role === "user" && (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              // justifyContent: 'center',
                            }}
                          >
                            <IconButton
                              aria-label="Ask Different Question"
                              color="primary"
                              variant="light"
                              onClick={() => handleAskDifferentQuestion(m)}
                              disabled={isAskDifferentQuestionDisabled()}
                              sx={{
                                mb: "19px",
                                "&.MuiIconButton-sizeSmall": {
                                  fontSize: "16px",
                                },
                              }}
                              size="small"
                            >
                              <EditIcon fontSize="inherit" />
                            </IconButton>
                          </Box>
                        )}

                        {m.role === "assistant" && (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              // justifyContent: 'center',
                            }}
                          >
                            {promptVersionSelector(m)}
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                );
              }
            })}

          {showEngineerWaitingResponse()}
        </Grid>
      );
    }

    if (aiEngineersSelected && aiEngineersSelected.aiengineerid === "0" && aiEngineerConversationLoading === false) {
      // if (clog()) console.log('----------------------------------------------------------------------------------------------------------------------------------')
      // if (clog()) console.log('aiEngineerMessages:', aiEngineerMessages)
      // if (clog()) console.log('aiEngineersSelected:', aiEngineersSelected)
      // if (clog()) console.log('aiEngineerConversation:', aiEngineerConversation)
      // if (clog()) console.log('aiEngineerConversationFetched:', aiEngineerConversationFetched)
      // if (clog()) console.log('aiEngineerConversationLoading:', aiEngineerConversationLoading)

      return (
        <Box
          name="selectAnEngineer"
          sx={{
            // mt: 2,
            display: "flex",
            flexDirection: "column",
            width: "100%",
            // height: "100%",
            alignItems: 'center',
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Box
            name={"roleIcon"}
            sx={{
              mt: -4,
              width: "100px",
              pt: 2,
              // backgroundColor: '#cc0000',
              display: "flex",
              // alignItems: 'center',
              justifyContent: "center",
            }}
          >
            <SupportAgentIcon
              sx={{
                fontSize: "100px",
                color: "grey.300",
              }}
            // color='primary'
            />
          </Box>
          <Typography
            align="center"
            sx={{
              fontSize: "18px",
              color: "grey.600",
            }}
          >
            Select an Engineer
          </Typography>
        </Box>
      );
    }

    if (aiEngineerConversationLoading === true) {
      return <LoadingSubPane spin={true} />;
    }
  };

  const showEngineerChatInput = () => {
    // normal chat input with onchange and disabled check
    if (aiEngineersSelected && aiEngineersSelected.aiengineerid !== "0" && aiEngineerConversationLoading === false) {
      return (
        <Box
          name={"chatInput"}
          sx={{
            display: "flex",
            flexDirection: "row",
            py: 2,
            pl: 2,
            pr: 2,
            // pr: "5px",
            justifyContent: "center",
            alignItems: "flex-end",
            borderTopWidth: "1px",
            borderTopStyle: "solid",
            borderTopColor: theme.palette.border.normal,
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              // display: 'flex',
              // flexDirection: 'column',
              // p: 4
              // pr: 2,
            }}
          >
            <TextField
              fullWidth
              name="chatinput"
              value={aiEngineerChatInput}
              onChange={handleOnChangeChatInput}
              onKeyDown={(event) => {
                if (event.key === "Enter" && (event.ctrlKey || event.metaKey)) {
                  if (aiEngineerChatInput !== "") {
                    // if (clog()) console.log('Enter Pressed')
                    handleOnSubmitSend();
                  }
                }
              }}
              disabled={isChatInputDisabled()}
              multiline
              maxRows={25}
              autoFocus
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {aiEngineerQuestionSaving ? (
                      <Box
                        sx={{
                          // backgroundColor: "#cc0000",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          mb: "19px",
                          mr: "10px",

                          // height: '100%',
                          // width: "64px",
                          // height: "36.5px",
                        }}
                      >
                        <CircularProgress
                          size={12}
                          sx={{
                            p: 0,
                            m: 0,
                          }}
                        // variant="determinate"
                        />
                      </Box>
                    ) : (
                      <IconButton
                        aria-label="Send"
                        color="primary"
                        variant="light"
                        onClick={handleOnSubmitSend}
                        disabled={isSendDisabled()}
                        sx={{
                          mb: "19px",
                          "&.MuiIconButton-sizeSmall": {
                            fontSize: "16px",
                          },
                        }}
                        size="small"
                      >
                        <SendIcon fontSize="inherit" />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
                sx: {
                  alignItems: "flex-end",
                  "&.MuiOutlinedInput-root": {
                    backgroundColor: theme.palette.background.paper,
                  },
                },
                //     <Box
                //     sx={{
                //       display: "flex",
                //       flexDirection: "column",
                //       alignItems: "flex-end",
                //       backgroundColor: "red",
                //     }}
                //   >
              }}
            />
          </Box>

          {
            //   <Box
            //   sx={{
            //     ml: "5px",
            //     pb: "8px",
            //     // flex: 1,
            //   }}
            // >
            //   <IconButton aria-label="Reset" color="primary" variant="light" onClick={handleOnClickReset} disabled={isResetDisabled()}>
            //     <SyncIcon fontSize="10px" />
            //   </IconButton>
            // </Box>
          }
        </Box>
      );
    }

    // disabled chat input with no functionality
    if (
      (aiEngineersSelected && aiEngineersSelected.aiengineerid === "0" && aiEngineerConversationLoading === false) ||
      aiEngineerConversationLoading === true
    ) {
      return (
        <Box
          name={"chatInput"}
          sx={{
            display: "flex",
            flexDirection: "row",
            py: 2,
            pl: 2,
            pr: 2,
            // pr: "5px",
            justifyContent: "center",
            alignItems: "flex-end",
            borderTopWidth: "1px",
            borderTopStyle: "solid",
            borderTopColor: theme.palette.border.normal,
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
            }}
          >
            <TextField
              fullWidth
              name="chatinput"
              disabled={true}
              multiline
              maxRows={25}
              autoFocus
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Send"
                      color="primary"
                      variant="light"
                      disabled={true}
                      sx={{
                        mb: "19px",
                        "&.MuiIconButton-sizeSmall": {
                          fontSize: "16px",
                        },
                      }}
                      size="small"
                    >
                      <SendIcon fontSize="inherit" />
                    </IconButton>
                  </InputAdornment>
                ),
                sx: {
                  alignItems: "flex-end",
                  "&.MuiOutlinedInput-root": {
                    backgroundColor: theme.palette.background.paper,
                  },
                },
              }}
            />
          </Box>
        </Box>
      );
    }
  };

  if (
    // aiEngineerConversationUpdated
    // aiEngineerConversationFetched
    aiEngineersDocs
  ) {
    // if (clog()) console.log('AiChatPanel aiEngineerConversationUpdated', aiEngineerConversationUpdated);

    return (
      <Box
        name={"engineerPanel"}
        // height={"80%"}
        // bgcolor="#cc0000"
        sx={{
          // mt: 1,
          display: "flex",
          flexDirection: "column",
          // flexGrow: 1,
          overflow: "hidden",
          height: aiEngineersPanelShow ? drawerHeight : "59px",
        }}
      >
        <Box
          name={"engineerPanelResize"}
          sx={{
            cursor: "row-resize",
            // backgroundColor: "#cc0000",
            minHeight: "16px",
            height: "16px",
          }}
          onMouseDown={(e) => handleMouseDown(e)}
        ></Box>

        <Box
          name={"engineerMenuPanel"}
          sx={{
            // mt: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              mr: 1,
            }}
          >
            <IconButton
              aria-label="Edit"
              color="primary"
              size="small"
              // variant="light"
              onClick={handleOnClickAiEngineersPanelShow}
              // disabled={isAiAgentsAddDisabled()}
              sx={{
                // ml: 0.5,
                "&.MuiIconButton-sizeSmall": {
                  fontSize: "24px",
                },
              }}
            >
              {aiEngineersPanelShow ? <ArrowDropDownIcon fontSize="inherit" /> : <ArrowRightIcon fontSize="inherit" />}
            </IconButton>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
            }}
          >
            <FormControl
              sx={{
                width: "100%",
              }}
            >
              <InputLabel>Engineers</InputLabel>
              <Select
                label="Engineers"
                value={getSelectedEngineerId()}
                disabled={isAiEngineersSelectDisabled()}
                readOnly={isAiEngineersSelectReadOnly()}
                onChange={(event) => handleOnChangeEngineers(event)}
                // autoFocus={aiAgentsSelected !== null && aiSettingsSelected === null ? true : false}
                sx={{
                  backgroundColor: theme.palette.background.paper,
                }}
              >
                <MenuItem value="0">Select An Engineer</MenuItem>

                {aiEngineersDocs &&
                  aiEngineersDocs.map((e, index) => {
                    return (
                      <MenuItem key={e.aiengineerid} value={e.aiengineerid}>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            // flexDirection: 'row',
                            alignItems: "center",
                            justifyContent: "space-between !important",
                          }}
                        >
                          {e.name}
                        </Box>
                      </MenuItem>
                    );
                  })}

                {rc.engineer_autoengineer && (
                  <Divider
                    key={"divider"}
                    variant="middle"
                    sx={{
                      "&.MuiDivider-root": {
                        // my: 0,
                      },
                    }}
                  />
                )}

                {rc.engineer_autoengineer && (
                  <MenuItem key={"autoengineer"} value={"autoengineer"}>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        // flexDirection: 'row',
                        alignItems: "center",
                        justifyContent: "space-between !important",
                      }}
                    >
                      Automatic Engineer
                    </Box>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
        </Box>

        <Box
          name={"engineerChatPanel"}
          // height={"80%"}
          // bgcolor="#cc0000"
          sx={{
            // backgroundColor: theme.palette.background.paper,
            backgroundColor: theme.palette.background.bg,
            mt: 2,
            // display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: theme.palette.border.normal,
            borderRadius: "4px",
            // backgroundColor: '#efefef',
            overflow: "hidden",
            display: aiEngineersPanelShow ? "flex" : "none",
            // height: aiEngineersPanelShow ? "500px" : "50px",
          }}
        >
          <Box
            name={"engineerMessages"}
            sx={{
              overflow: "hidden",
              flexGrow: 1,
              height: "100%",
              backgroundColor: aiEngineerMessages && aiEngineerMessages.length > 0 ? "transparent" : theme.palette.background.paper,
            }}
          >
            <Box
              id={"engineerMessagesScrollWrapper"}
              sx={{
                maxHeight: "100%",
                height: aiEngineersSelected && aiEngineersSelected.aiengineerid !== "0" ? "auto" : "100%",
                // flexGrow: 1,
                overflow: "auto",
              }}
            >
              {showEngineerMessages()}
            </Box>
          </Box>
          {showEngineerChatInput()}
        </Box>
      </Box>
    );
  }

  if (init) {
    return <LoadingSubPane spin={true} />;
  }
};

export default React.memo(AiEngineerPanel);
