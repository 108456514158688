import React, { useContext } from "react";

import { useNavigate } from "react-router-dom";
// import { useDispatch } from 'react-redux';

// material-ui
import { Button, FormHelperText, Grid, InputLabel, OutlinedInput, Stack, Typography } from "@mui/material";

// third party
import * as Yup from "yup";
import { Formik } from "formik";

import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebase } from "../../../code/firebase";

import {setd, debug, setc, clog} from "../../../helpers/HelperFunctions";
import { AdminAuthContext } from "../../../helpers/AdminAuthProvider";

// project import
// import useAuth from '../../../hooks/useAuth';
import useScriptRef from "../../../hooks/useScriptRef";
import AnimateButton from "../../../components/@extended/AnimateButton";
// import { openSnackbar } from 'store/reducers/snackbar';

// ============================|| FIREBASE - FORGOT PASSWORD ||============================ //
const auth = getAuth(firebase);

const AuthForgotPassword = () => {
  const scriptedRef = useScriptRef();
  // const dispatch = useDispatch();
  const navigate = useNavigate();



  // const { isLoggedIn, resetPassword } = useAuth();
  const [user, loadingUser, errorUser] = useAuthState(auth);

  const { resetPassword } = useContext(AdminAuthContext);
  const { adminShowForgotPassword, setAdminShowForgotPassword } = useContext(AdminAuthContext);
  const { adminShowCheckEmail, setAdminShowCheckEmail } = useContext(AdminAuthContext);

  // todo:

  const handleBackToLogin = () => {
    setAdminShowForgotPassword(false);
    setAdminShowCheckEmail(false);
  };

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email("Must be a valid email").max(255).required("Email is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            const result = await resetPassword(values.email);

            if (result.data) {
              setStatus({ success: true });
              setSubmitting(false);
              setAdminShowCheckEmail(true);
            } else {
              setStatus({ success: false });

              // check errors
              let message = "";
              if (result.error.val.code === "auth/user-not-found") {
                message = "User not found. Please check email address.";
              }
              setErrors({ submit: message });
              setSubmitting(false);
            }

            // await resetPassword(values.email).then(
            //   (result) => {
            //     setStatus({ success: true });
            //     setSubmitting(false);
            //     debugger

            //     if (result) {

            //     }
            //     setAdminShowCheckEmail(true);
            //     // dispatch(
            //     //   openSnackbar({
            //     //     open: true,
            //     //     message: 'Check mail for reset password link',
            //     //     variant: 'alert',
            //     //     alert: {
            //     //       color: 'success'
            //     //     },
            //     //     close: false
            //     //   })
            //     // );
            //     // setTimeout(() => {
            //     //   // navigate(isLoggedIn ? '/auth/check-mail' : '/check-mail', { replace: true });
            //     //   // navigate(user ? '/checkmail' : '/checkmail', { replace: true });
            //     //   handleBackToLogin()
            //     // }, 1500);

            //     // WARNING: do not set any formik state here as formik might be already destroyed here. You may get following error by doing so.
            //     // Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application.
            //     // To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
            //     // github issue: https://github.com/formium/formik/issues/2430
            //   },
            //   (err) => {
            //     debugger
            //     setStatus({ success: false });
            //     setErrors({ submit: err.message });
            //     setSubmitting(false);
            //   }
            // );
          } catch (err) {
            console.error(err);
            if (scriptedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="email-forgot">Email Address</InputLabel>
                  <OutlinedInput
                    fullWidth
                    error={Boolean(touched.email && errors.email)}
                    id="email-forgot"
                    type="email"
                    value={values.email}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Enter email address"
                    inputProps={{}}
                  />
                  {touched.email && errors.email && (
                    <FormHelperText error id="helper-text-email-forgot">
                      {errors.email}
                    </FormHelperText>
                  )}

                  {errors.submit && (
                    <Grid item xs={12}>
                      <FormHelperText error>{errors.submit}</FormHelperText>
                    </Grid>
                  )}
                </Stack>
              </Grid>
              {
                // errors.submit && (
                //   <Grid item xs={12}>
                //     <FormHelperText error>{errors.submit}</FormHelperText>
                //   </Grid>
                // )
              }
              <Grid item xs={12} sx={{ mb: -2 }}>
                <Typography variant="caption">Do not forgot to check SPAM box.</Typography>
              </Grid>
              <Grid item xs={12}>
                <AnimateButton>
                  <Button disableElevation disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained" color="primary">
                    Send Password Reset Email
                  </Button>
                </AnimateButton>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

export default AuthForgotPassword;
