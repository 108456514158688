import React, { useState, useEffect } from 'react'
// import PropTypes from 'prop-types';

import {
  getFirestore,
  collection,
  query,
  where,
  orderBy,
} from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import { firebase } from '../code/firebase';
import _ from 'lodash';
import moment from 'moment'

import { Chrono } from "react-chrono";

import { useTheme } from '@mui/material/styles';
import {
  Box,
  Typography,
  Container,
  Select,
  MenuItem,
} from '@mui/material';
import GridIcon from '@mui/icons-material/Apps';
import DistanceIcon from '@mui/icons-material/ZoomOutMap';

import GoogleMapPane from './GoogleMapPane'
import LoadingSubPane from './LoadingSubPane'

// import OverallDataPane from './OverallDataPane'


import '../assets/extra.css'

// const gridSizes = require('../json/gridSizes.json')
import gridSizes from '../json/gridSizes.json'

const milesDistanceData = gridSizes.milesDistanceData
const metersDistanceData = gridSizes.metersDistanceData
const gridSizeData = gridSizes.gridSizeData

// import MainCard from './MainCard';


// const auth = getAuth(firebase)

const GridKeywordTimelinePane = (props) => {
  const theme = useTheme();

  let userData = {}
  if (props.userData) {
    userData = props.userData
  }

  let keywordId = ''
  if (props.keywordId) {
    keywordId = props.keywordId
  }

  let title = ''
  if (props.title) {
    title = props.title
  }

  let keywordsDocData = {}
  if (props.keywordsDocData) {
    keywordsDocData = props.keywordsDocData
  }



  const timelineRange = props.timelineRange
  const setTimelineRange = props.setTimelineRange
  const timelineRangeTimestamp = props.timelineRangeTimestamp

  const [selectedKeywordScan, setSelectedKeywordScan] = useState({});
  const [selectedTimestamp, setSelectedTimestamp] = useState(-1);

  // const [timelineRange, setTimelineRange] = useState('alltime');

  const [scansData, setScansData] = useState([]);
  const [scansLoaded, setScansLoaded] = useState(false);
  const [scansMenuData, setScansMenuData] = useState([]);

  let scansDataArr = []

  useEffect(() => {
    if (scansDataArr.length > 0 && scansLoaded === false) {
      // initial firestore fetch
      setScansData(scansDataArr)
      setScansLoaded(true)

      // use newest scan as selected
      const latest = scansDataArr[scansDataArr.length - 1]
      setSelectedKeywordScan(latest)
      setSelectedTimestamp(latest.timestamp)

      // const oldest = scansDataArr[0]
      // setSelectedKeywordScan(oldest)
      // setSelectedTimestamp(oldest.timestamp)
      // const selectedScanIdx = _.findIndex(scansDataArr, {data:{timestamp: '1234567890'}});
    }
    else if (!_.isEqual(scansData, scansDataArr) && scansLoaded === true) {
      // firestore updated
      setScansData(scansDataArr)

      if (scansDataArr.length > 0) {
        const latest = scansDataArr[scansDataArr.length - 1]
        setSelectedKeywordScan(latest)
        setSelectedTimestamp(latest.timestamp)
      }
      else {
        setSelectedKeywordScan({})
        setSelectedTimestamp(-1)

      }


      // // check selected scan data
      // const selectedScanIdx = _.findIndex(scansDataArr, { data: { timestamp: selectedTimestamp } });
      // // compare with selected state
      // if (!_.isEqual(scansDataArr[selectedScanIdx], selectedKeywordScan)) {
      //   // update state if different
      //   setSelectedKeywordScan(scansDataArr[selectedScanIdx])
      // }

    }
    //setUser({ pending: true, value: undefined })
    // fetchUser().then((fetchedUser) => {
    //  setUser({ pending: true, value: fetchedUser })
    //})
  }, [scansDataArr])


  useEffect(() => {
    if (scansData.length > 0) {
      let sortedScansMenuData = []
      for (var i = 0; i < scansData.length; i++) {
        const docData = scansData[i]
        // create Menu data
        let menuItem = {}
        // const dateStr = moment.unix(docData.timestamp).format('MMM Do YYYY, h:mm:ss a');
        // const dateStr = moment.unix(docData.timestamp).format('h:mm A MMM Do YYYY');
        const dateStr = moment.unix(docData.timestamp).format('Do MMM YYYY');
        menuItem.title = dateStr
        // added itemId to use in chronos as id overwritten in item callback for chronos
        menuItem.itemId = docData.id
        //        const date = new Date(docData.timestamp *1000)
        //        const dateStr = date.getDate() + ' ' +
        //        if (clog()) console.log("Date: "+date.getDate()+
        //          "/"+(date.getMonth()+1)+
        //          "/"+date.getFullYear()+
        //          " "+date.getHours()+
        //          ":"+date.getMinutes()+
        //          ":"+date.getSeconds());

        sortedScansMenuData.push(menuItem)
      }

      setScansMenuData(sortedScansMenuData)

    }
    else {
      setScansMenuData([])
    }
  }, [scansData])

  const handleTimelineOnSelected = (item) => {
    if (item.itemId !== selectedKeywordScan.id) {
      let idx = -1
      // find item in
      const foundIdx = _.findIndex(scansData, { id: item.itemId })
      if (foundIdx > -1) {
        idx = foundIdx
      }

      if (idx > -1) {
        const itemFound = scansData[idx]

        // update selectedKeywordScan
        setSelectedKeywordScan(itemFound)

        // update selectedTimestamp
        setSelectedTimestamp(itemFound.timestamp)
        // if (clog()) console.log('itemId:', itemId)
      }
    }
  }

  const handleTimelineRangeOnChange = (event) => {
    setTimelineRange(event.target.value);
  };

  const displayScanDate = () => {
    let dateStr = ''
    if (selectedTimestamp > 0) {
      dateStr = moment.unix(selectedTimestamp).format('Do MMM YYYY');
    }
    return (dateStr)
  }

  const displayGridType = () => {
    let html = ''
    if (selectedKeywordScan && selectedKeywordScan.grid) {
      const grid = selectedKeywordScan.grid
      const gridLength = _.size(grid)
      if (gridLength > 0) {
        const gridLengthStr = '' + gridLength

        let gridType = ''
        const gtIdx = _.findIndex(gridSizeData, { id: gridLengthStr })
        if (gtIdx > -1) {
          const item = gridSizeData[gtIdx]
          gridType = item.value
          // const sqrRoot = Math.sqrt(gridLength);
          html =
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <GridIcon
                sx={{
                  mr: '5px'
                }}
              />
              Grid Size: {gridType} (Grid Points: {gridLengthStr})
            </Box>
        }
      }
    }

    return (html)
  }

  const displayDistance = () => {
    let html = ''
    if (selectedKeywordScan && selectedKeywordScan.distance) {
      let distanceUnit = ''
      const distance = selectedKeywordScan.distance

      // look in milesDistanceData
      const milesIdx = _.findIndex(milesDistanceData, { id: distance })
      if (milesIdx !== -1) {
        const item = milesDistanceData[milesIdx]
        distanceUnit = item.value
      }

      if (distanceUnit === '') {
        // look in metersDistanceData
        const metersIdx = _.findIndex(metersDistanceData, { id: distance })
        if (metersIdx !== -1) {
          const item = metersDistanceData[metersIdx]
          distanceUnit = item.value
        }
      }

      if (distanceUnit !== '') {
        html =
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              ml: 1
            }}
          >
            <DistanceIcon
              sx={{
                mr: '5px',
                fontSize: 20
              }}
            />
            Spacing: {distanceUnit}
          </Box>
      }

    }

    return (html)
  }


  /*
    const handleTimelineOnclick = (itemId) => {

      // find itemId in scansData
      let idx = -1
      const foundIdx = _.findIndex(scansData, { id: itemId })
      if (foundIdx > -1) {
        idx = foundIdx
      }

      if (idx > -1) {
        const item = scansData[idx]

        // update selectedKeywordScan
        setSelectedKeywordScan(item)

        // update selectedTimestamp
        setSelectedTimestamp(item.timestamp)
        // if (clog()) console.log('itemId:', itemId)
      }
    }
  */
  //  const handleSelectKeywordScan = (newData) => {
  //    setSelectedKeywordScan(newData);
  //  };

  const scansRef = collection(getFirestore(firebase), 'scans')
  const q = query(
    scansRef,
    where('userid', '==', userData.userId),
    where('eclocationid', '==', userData.locationId),
    where('keyworditemid', '==', keywordId),
    where('timestamp', '>=', timelineRangeTimestamp),
    orderBy('timestamp', 'asc')

    // whereEqualTo('data.timestamp', '==', '1234567890'),
    // orderBy('data.timestamp', 'desc')
  );

  const [value, loading, error] = useCollection(
    q,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (error) {
    if (clog()) console.log(error)
    return (
      <LoadingSubPane
        text={<strong>Scans Error: {JSON.stringify(error)}</strong>}
        showSpinner={false}
      />
    )
  }

  if (loading) {
    return (
      <LoadingSubPane
        text={'Loading Report...'}
        showSpinner={true}
        progress={100}
      />
    )
  }

  if (value) {
    // START: build data
    let sortedScansData = []
    // let sortedScansMenuData = []
    value.docs.forEach((doc, index) => {
      const docData = doc.data()
      if (docData) {
        sortedScansData.push(docData)
      }
    })

    // trigger useEffect
    if (sortedScansData.length === 0) {

    }
    scansDataArr = sortedScansData
    // END: build data

    // if (clog()) console.log('selectedKeywordScan:', JSON.stringify(selectedKeywordScan))


    // <Box sx={{ borderBottom: 1, borderColor: 'divider' }}></Box>

    return (
      <Container
        className={scansMenuData.length > 0 ? 'timelineContainer' : 'timelineContainerNoItems'}
        maxWidth="lg"

        sx={{
          background: theme.palette.background.paper,
          borderLeft: `1px solid ${theme.palette.divider}`,
          borderRight: `1px solid ${theme.palette.divider}`,
          borderBottom: `1px solid ${theme.palette.divider}`,
          pt: 2,
          pb: 3,
          mb: 4,
          minHeight: '700px'
        }}
      >

        <Box
          sx={{
            position: 'relative',
            zIndex: 2,
            display: 'flex',
            flexDirection: 'row',
            // mb: 2,
            width: `calc(100% - 144px)`
          }}
        >

          <Box
            sx={{
              flexGrow: 1
            }}
          >
            <Typography
              variant='h3'
            >
              Results: {title !== '' ? '"' + title + '"' : ''}
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                pr: 1
              }}
            >
              <Typography>
                Timeline Range:
              </Typography>
            </Box>
            <Box>
              <Select
                value={timelineRange}
                onChange={handleTimelineRangeOnChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Timeline Range' }}
                sx={{
                  minWidth: '120px'
                }}
              >
                <MenuItem value={'alltime'}>All Time</MenuItem>
                <MenuItem value={'12months'}>12 Months</MenuItem>
                <MenuItem value={'6months'}>6 Months</MenuItem>
                <MenuItem value={'1month'}>1 Month</MenuItem>
                <MenuItem value={'now'}>Now</MenuItem>
              </Select>
            </Box>
          </Box>

        </Box>
        {/* scansData.map(function (s, i) {
          // build Tab Panel
          const keyword = s.keyword
          const timestamp = s.timestamp
          // const when = data.when
          // {JSON.stringify(scans)} <br />
          // GridId - {s.gridid}

          let selected = 'false'
          if (selectedKeywordScan.id === s.id) {
            selected = 'true'
          }
          return (
            <Box
              key={i}
              onClick={handleTimelineOnclick.bind(this, s.id)}
            >
              Timestamp - {timestamp} <br />
              ID - {s.id} <br />
              Selected - {selected}<br /><br />

            </Box>
          )
        })*/}

        {scansMenuData.length > 0 && //}
          <Chrono
            theme={{
              // primary: 'red',
              // secondary: 'blue',
              // cardBgColor: 'yellow',
              // cardForeColor: 'violet',
              // titleColor: 'black',
              // titleColorActive: 'red',
              primary: theme.palette.grey[500],
              secondary: theme.palette.primary.main,
              titleColor: 'black',
              titleColorActive: 'red',
              timelineCircleDimension: 40
            }}
            fontSizes={{
              title: '0.8rem',
            }}
            items={scansMenuData}
            // items={items}
            mode="HORIZONTAL"
            scrollable={{ scrollbar: true }}
            cardLess={true}
            cardPositionHorizontal={'TOP'}
            activeItemIndex={scansData.length - 1}
            onItemSelected={handleTimelineOnSelected.bind(this)}
            // onItemSelected={selected => handleTimelineOnSelected(selected)}
            allowDynamicUpdate={true}

          />
        }


        {scansMenuData.length > 0 &&
          <Box
            sx={{
              position: 'relative',
              zIndex: 2,
              display: 'flex',
              flexDirection: 'row',
              // backgroundColor: 'red',
              mt: '-24px'
            }}
          >
            <Box
              sx={{
                flexGrow: 1
              }}
            >

              <Typography
                variant='h4'
              >
                {displayScanDate()}
              </Typography>


            </Box>
            <Box
              sx={{
                mb: 2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {displayGridType()}
              {displayDistance()}
            </Box>
          </Box>
        }

        {
          // <OverallDataPane />
          // <Grid name={'spacer'} sx={{pb: 3}}></Grid>
        }

        {scansMenuData.length > 0 &&

          <GoogleMapPane
            // idx={i}
            idx={1}
            userData={userData}
            selectedKeywordScan={selectedKeywordScan}
            title={title}
            keywordsDocData={keywordsDocData}
          />
        }

        {scansMenuData.length === 0 &&
          <Typography
            variant={'h4'}
            sx={{
              mt: 1
            }}
          >
            No Reports for selected Timeline Range.
          </Typography>

        }


      </Container>
    );
  }







};


export default React.memo(GridKeywordTimelinePane)