// ==============================|| OVERRIDES - DIALOG CONTENT TEXT ||============================== //

export default function Select(theme) {
  return {
    MuiSelect: {
      styleOverrides: {
        root: {
          // "& .MuiSelect-outlined.MuiOutlinedInput-input"
          // "& .MuiOutlinedInput-notchedOutline": {
          //   borderColor: "#cc0000",
          // }
        }
      }
    }
  };
}
