import {
  collection,
  // getFirestore,
  doc,
  setDoc
  // query,
  //where
} from 'firebase/firestore';

import {
  // firebase,
  // generateKeywordUuid,
  db
} from '../code/firebase';
import _ from 'lodash';


const checkKeywordGridData = (keywordData, gridData) => {

  let returnData = {
    data: {},
    passed: true,
    errors: {
      keywordData: {},
      gridData: {}
    }
  }
  if (gridData) {
    if (gridData.distanceUnit === '') {
      returnData.passed = false
    }

    if (gridData.distance === '') {
      returnData.passed = false
    }

    if (gridData.gridType === '') {
      returnData.passed = false
    }

    // does grid exist?
    if (_.isEmpty(gridData)) {
      returnData.passed = false
    }
  }
  else {
    returnData.passed = false
  }

  if (keywordData) {

    // do keywords exist?
    if (keywordData.length > 0) {
      // does primary exist?
      const primaryExists = _.findIndex(keywordData, { primary: 1 })
      if (primaryExists === -1) {
        // primary does not exist
        returnData.passed = false
      }
    }
    else {
      // no keywords
      returnData.passed = false
    }
  }
  else {
    returnData.passed = false
  }

  return returnData
}



const processUserKeywordData = (action, newData, currentData) => {

  let id = ''
  let t = ''
  let primary = 0
  let primaryIdx = -1
  let newItem = {}
  let isPrimary = false

  const assignPrimary = false

  // newData = selectedRow
  // currentData = userKeywordData
  let returnData = {
    processed: false,
    data: {}
  }
  let processedData = []

  if ('add' === action) {

    if (newData.id && newData.id !== '') {
      id = newData.id
    }

    if (newData.t && newData.t !== '') {
      t = newData.t
    }

    if (id !== '' && t !== '') {
      // clone userKeywordData
      processedData = _.cloneDeep(currentData)

      if (assignPrimary) {
        // is primary category already allocated?
        primaryIdx = _.findIndex(processedData, { primary: 1 })
        if (primaryIdx === -1) {
          // primary is not set
          primary = 1
        }
      }

      // generate Uuid
      const keywordRef = doc(collection(db, "keywords"));
      const uuid = keywordRef.id
      // const uuid = db.collection("keywords").doc().id;
      // const uuid = 'a' + Math.floor(Date.now() / 1000)

      // generate google uuid
      newItem = {
        active: 1,
        categoryid: id, // "estate_appraiser"
        keyword: t, // "Estate Appraiser"
        keyworditemid: uuid, // "CXRXzNlgALU87dHkBnwB"
        primary: primary
      }


      // add newItem
      processedData.push(newItem)

      // sort data
      const sortedAddData = _.orderBy(processedData, ['primary', 'keyword'], ['desc', 'asc']);

      returnData.processed = true
      returnData.data = sortedAddData
    }

    return returnData

  }


  if ('remove' === action) {

    if (newData.id && newData.id !== '') {
      id = newData.id
    }

    if (id !== '') {
      // clone userKeywordData
      processedData = _.cloneDeep(currentData)

      // find item index
      const idx = _.findIndex(processedData, { categoryid: id })
      const removeItem = processedData[idx]

      // check if is primary
      if (removeItem.primary === 1) {
        isPrimary = true
      }

      // remove removeItem
      processedData.splice(idx, 1)

      // add primary to first active item that is not this

      if (assignPrimary) {
        if (isPrimary) {
          for (var i = 0; i < processedData.length; i++) {
            const item = processedData[i]
            if (item.active === 1) {
              item.primary = 1
              break
            }
          }
        }
      }

      // sort data
      const sortedRemoveData = _.orderBy(processedData, ['primary', 'keyword'], ['desc', 'asc']);

      returnData.processed = true
      returnData.data = sortedRemoveData

    }

    return returnData

  }



  if ('makePrimary' === action) {
    if (newData.id && newData.id !== '') {
      id = newData.id
    }

    if (newData.t && newData.t !== '') {
      t = newData.t
    }

    if (id !== '' && t !== '') {
      // clone data
      processedData = _.cloneDeep(currentData)

      // find current primary and reset
      primaryIdx = _.findIndex(processedData, { primary: 1 })

      if (primaryIdx > -1) {
        // remove current primary
        processedData[primaryIdx].primary = 0
      }

      // add primary to new
      const newPrimaryIdx = _.findIndex(processedData, { categoryid: id })
      if (newPrimaryIdx > -1) {
        // remove current primary
        processedData[newPrimaryIdx].primary = 1
      }

      // sort data
      const sortedPrimaryData = _.orderBy(processedData, ['primary', 'keyword'], ['desc', 'asc']);
      returnData.processed = true
      returnData.data = sortedPrimaryData

    }

    return returnData

  }


  if ('enableDisable' === action) {

    let subAction = ''
    if (newData.id && newData.id !== '') {
      id = newData.id
    }

    if (id !== '') {
      // clone userKeywordData
      processedData = _.cloneDeep(currentData)

      // find item index
      const idx = _.findIndex(processedData, { categoryid: id })
      const enableDisableItem = processedData[idx]

      const active = enableDisableItem.active
      if (active === 1) {
        subAction = 'disable'
        enableDisableItem.active = 0
      }
      else {
        subAction = 'enable'
        enableDisableItem.active = 1
      }

      // check if is primary
      if (enableDisableItem.primary === 1) {
        isPrimary = true
        // disable primary
        enableDisableItem.primary = 0
      }

      if (assignPrimary) {
        // add primary to first active item that is not this
        if (isPrimary && subAction === 'disable') {
          for (var j = 0; j < processedData.length; j++) {
            const item = processedData[j]
            if (j !== idx && item.active === 1) {
              item.primary = 1
              break
            }
          }
          //processedData[0].primary = 1
        }

        // add primary to this item if no other is primary
        if (!isPrimary && subAction === 'enable') {
          const primaryExists = _.findIndex(processedData, { primary: 1 })
          if (primaryExists === -1) {
            enableDisableItem.primary = 1
          }
        }
      }
      // sort data
      const sortedEnableDisableData = _.orderBy(processedData, ['primary', 'keyword'], ['desc', 'asc']);

      returnData.processed = true
      returnData.data = sortedEnableDisableData
    }

    return returnData

  }

}


const processKeywords = (userKeywordData, keywordsSchedule, userData, keywordId, gridTemplateId, gridTemplateData, website) => {
  // const test1 = userKeywordData
  // const test2 = keywordsSchedule
  // const test3 = userData
  // const test4 = keywordId
  // const test5 = gridTemplateData
  /*
    DATA SHAPE

    data: {
      lat: "51.291727",
      lng: "-0.3233870000000252",
      website: "surreyhouseclearanceandremovals.co.uk",
      zoom: "12z"
    },
    keywords: [],
    schedule: {},
    eclocationid: "NG8AEc8nheLEMNYfo3dn",
    gridtemplateid: "zZdsStb8x13VtLLGM9IS",
    id: "HwxnNn22f6hjIk7h2vbQ",
    userid: "DzLUva5FNJWJ2iFJJjwoUknksu73",
  */

  let returnData = {
    data: {
      lat: null,
      lng: null,
      website: '',
      zoom: '',
      address: '',
      businessname: '',
      city: '',
      country: '',
      googleplaceid: '',
      postalcode: '',
      state: '',
      timezone: '',

      // address: "17 St Mary’s Road",
      // businessname: "Surrey House Clearance & Removals",
      // city: "Leatherhead",
      // country: "GB",
      // googleplaceid: "ChIJbS0JBd_hdUgRXOyoPQzL4f8",
      // postalcode: "KT22 8HB",
      // state: "Surrey",
      // timezone: "Europe/London",

    },
    eclocationid: '',
    gridtemplateid: '',
    id: '',
    keywords: [],
    schedule: {},
    userid: ''
  }

  if (userData) {
    // add eclocationid
    if (userData.locationId && userData.locationId !== '') {
      returnData.eclocationid = userData.locationId
    }

    // add userid
    if (userData.userId && userData.userId !== '') {
      returnData.userid = userData.userId
    }

    // add address
    if (userData.address && userData.address !== '') {
      returnData.data.address = userData.address
    }

    // NEEDED: add businessname
    if (userData.businessname && userData.businessname !== '') {
      returnData.data.businessname = userData.businessname
    }

    // add city
    if (userData.city && userData.city !== '') {
      returnData.data.city = userData.city
    }

    // add country
    if (userData.country && userData.country !== '') {
      returnData.data.country = userData.country
    }

    // add googleplaceid
    if (userData.googleplaceid && userData.googleplaceid !== '') {
      returnData.data.googleplaceid = userData.googleplaceid
    }

    // add googleplaceid
    if (userData.postalCode && userData.postalCode !== '') {
      returnData.data.postalcode = userData.postalCode
    }

    // add state
    if (userData.state && userData.state !== '') {
      returnData.data.state = userData.state
    }

    // add timezone
    if (userData.timezone && userData.timezone !== '') {
      returnData.data.timezone = userData.timezone
    }

  }

  // add schedule
  if (keywordsSchedule && !_.isEmpty(keywordsSchedule)) {
    returnData.schedule = keywordsSchedule
  }

  // add keywords
  if (userKeywordData && userKeywordData.length > 0) {
    returnData.keywords = userKeywordData
  }

  if (gridTemplateData) {
    // add lat
    if (gridTemplateData.lat && gridTemplateData.lat !== '') {
      returnData.data.lat = gridTemplateData.lat
    }

    // add lng
    if (gridTemplateData.lng && gridTemplateData.lng !== '') {
      returnData.data.lng = gridTemplateData.lng
    }



    // add zoom
    if (gridTemplateData.zoom && gridTemplateData.zoom !== '') {
      returnData.data.zoom = gridTemplateData.zoom
    }

  }

  // add id
  returnData.id = keywordId

  // add gridtemplateid
  returnData.gridtemplateid = gridTemplateId

  // add website
  returnData.data.website = website

  // if (clog()) console.log('saveKeywords:', returnData)


  return returnData

}


const processDataTemplates = (userData, keywordId, gridTemplateId, gridTemplateData, website) => {
  // const test1 = userKeywordData
  // const test2 = keywordsSchedule
  // const test3 = userData
  // const test4 = keywordId
  // const test5 = gridTemplateData

  /*
    DATA SHAPE

    distance: 1609,
    distanceunit: 'miles',
    eclocationid: 'NG8AEc8nheLEMNYfo3dn',
    grid: {},
    id: 'zZdsStb8x13VtLLGM9IS',
    keywordid: 'HwxnNn22f6hjIk7h2vbQ',
    lat: 51.291727,
    lng: -0.3233870000000252,
    userid: 'DzLUva5FNJWJ2iFJJjwoUknksu73',
    website: 'surreyhouseclearanceandremovals.co.uk',
    zoom: '12z'

  */

  let returnData = {
    distance: -1,
    distanceunit: '',
    eclocationid: '',
    grid: {},
    id: '',
    keywordid: '',
    lat: null,
    lng: null,
    userid: '',
    website: '',
    zoom: ''
  }

  if (userData) {
    // add eclocationid
    if (userData.locationId && userData.locationId !== '') {
      returnData.eclocationid = userData.locationId
    }

    // add userid
    if (userData.userId && userData.userId !== '') {
      returnData.userid = userData.userId
    }
  }

  if (gridTemplateData) {

    // add distance
    if (gridTemplateData.distance && gridTemplateData.distance !== '') {
      returnData.distance = gridTemplateData.distance
    }

    // add distanceUnit
    if (gridTemplateData.distanceUnit && gridTemplateData.distanceUnit !== '') {
      returnData.distanceunit = gridTemplateData.distanceUnit
    }

    // add grid
    if (gridTemplateData.grid && !_.isEmpty(gridTemplateData.grid)) {
      returnData.grid = gridTemplateData.grid
    }

    // add lat
    if (gridTemplateData.lat && gridTemplateData.lat !== '') {
      returnData.lat = gridTemplateData.lat
    }

    // add lng
    if (gridTemplateData.lng && gridTemplateData.lng !== '') {
      returnData.lng = gridTemplateData.lng
    }

    // add zoom
    if (gridTemplateData.zoom && gridTemplateData.zoom !== '') {
      returnData.zoom = gridTemplateData.zoom
    }
  }

  // add gridtemplateid
  returnData.id = gridTemplateId

  // add keywordid
  returnData.keywordid = keywordId

  // add website
  returnData.website = website


  // if (clog()) console.log('saveGridTemplates:', returnData)
  return returnData
}


/*
const checkGridTemplatesOLD = (saveGridTemplates, gridTemplates, newGridTemplateId) => {

  let returnData = {
    id: newGridTemplateId,
    match: false
  }


  const saveGridTemplatesClone = _.cloneDeep(saveGridTemplates)
  // remove id
  saveGridTemplatesClone.id = ''

  const gridTemplatesClone = _.cloneDeep(gridTemplates)

  // loop through gridTemplates
  for (var i = 0; i < gridTemplatesClone.length; i++) {
    let item = gridTemplatesClone[i]
    const id = item.id
    // remove id
    item.id = ''

    // check if data matches
    const isEqual = _.isEqual(item, saveGridTemplatesClone)
    if (isEqual) {
      returnData = {
        // id: item.id,
        id: id,
        match: true
      }
      break
    }
  }

  return returnData
}
*/
const checkGridTemplates = (saveGridTemplates, gridTemplates, newGridTemplateId) => {

  let returnData = {
    id: newGridTemplateId,
    match: false
  }

  // distance: 1609
  // distanceunit: "miles"
  // eclocationid: "NG8AEc8nheLEMNYfo3dn"
  // grid: {0: {…}, 1: {…}, 2: {…}, 3: {…}, 4: {…}, 5: {…}, 6: {…}, 7: {…}, 8: {…}, 9: {…}, 10: {…}, 11: {…}, 12: {…}, 13: {…}, 14: {…}, 15: {…}, 16: {…}, 17: {…}, 18: {…}, 19: {…}, 20: {…}, 21: {…}, 22: {…}, 23: {…}, 24: {…}, 25: {…}, 26: {…}, 27: {…}, 28: {…}, 29: {…}, 30: {…}, 31: {…}, 32: {…}, 33: {…}, 34: {…}, 35: {…}, 36: {…}, 37: {…}, 38: {…}, 39: {…}, 40: {…}, 41: {…}, 42: {…}, 43: {…}, 44: {…}, 45: {…}, 46: {…}, 47: {…}, 48: {…}, 49: {…}, 50: {…}, 51: {…}, 52: {…}, 53: {…}, 54: {…}, 55: {…}, 56: {…}, 57: {…}, 58: {…}, 59: {…}, 60: {…}, 61: {…}, 62: {…}, 63: {…}, 64: {…}, 65: {…}, 66: {…}, 67: {…}, 68: {…}, 69: {…}, 70: {…}, 71: {…}, 72: {…}, 73: {…}, 74: {…}, 75: {…}, 76: {…}, 77: {…}, 78: {…}, 79: {…}, 80: {…}, 81: {…}, 82: {…}, 83: {…}, 84: {…}, 85: {…}, 86: {…}, 87: {…}, 88: {…}, 89: {…}, 90: {…}, 91: {…}, 92: {…}, 93: {…}, 94: {…}, 95: {…}, 96: {…}, 97: {…}, 98: {…}, 99: {…}, …}
  // id: ""
  // keywordid: "HwxnNn22f6hjIk7h2vbQ"
  // lat: 51.291727
  // lng: -0.3233870000000252
  // userid: "DzLUva5FNJWJ2iFJJjwoUknksu73"
  // website: "surreyhouseclearanceandremovals.co.uk"
  // zoom: "12z"

  const skipValues = [
    'eclocationid',
    'id',
    // 'grid',
    'keywordid',
    'lat',
    'lng',
    'userid',
    'website',

  ]

  // loop through gridTemplates
  for (var i = 0; i < gridTemplates.length; i++) {
    let item = gridTemplates[i]
    const id = item.id

    let match = true
    for (const key in item) {
      const value = item[key]
      // check if item is in skipValues
      // const skipIdx = _.find(skipValues, key)
      let skipIdx = _.findIndex(skipValues, (e) => {
        return e === key;
      }, 0);

      if (skipIdx === -1) {
        if ('grid' === key) {
          // if grid then do a count instead
          const templatesItemCount = _.size(item.grid)
          const saveTemplateCount = _.size(saveGridTemplates.grid)
          if (templatesItemCount !== saveTemplateCount) {
            match = false
          }
        }
        else {
          if (value !== saveGridTemplates[key]) {
            match = false
          }
        }
        // if (clog()) console.log('key:', key)
      }
      // if (clog()) console.log(`${property}: ${object[key]}`);
    }

    if (match === true) {
      returnData = {
        // id: item.id,
        id: id,
        match: true
      }
      break
    }
  }

  return returnData
}

const generateGridTemplateId = () => {

  const gridtemplateRef = doc(collection(db, "gridtemplates"));
  const uuid = gridtemplateRef.id
  // if (clog()) console.log('uuid:', uuid)
  return uuid
}


const saveDataTemplate = async (gridtemplateData) => {

  let returnData = {
    status: 200
  }

  try {

    // get gridtemplate id
    let gId = ''

    if (gridtemplateData && gridtemplateData.id && gridtemplateData.id !== '') {
      gId = gridtemplateData.id

      // update gridtemplate
      const gridtemplateRef = doc(db, 'gridtemplates', gId);
      await setDoc(gridtemplateRef, gridtemplateData, {
        // merge: true
      });
    }

    return returnData

  } catch (err) {
    console.error(err);

    returnData.status = 400
    returnData.error = err

  }
}

const saveKeywords = async (keywordsData) => {

  let returnData = {
    status: 200
  }

  try {

    // get keywords id
    let kId = ''

    if (keywordsData && keywordsData.id && keywordsData.id !== '') {
      kId = keywordsData.id

      // update keywords
      const keywordsRef = doc(db, 'keywords', kId);
      await setDoc(keywordsRef, keywordsData, {
        // merge: true
      });
    }

    return returnData

  } catch (err) {
    console.error(err);

    returnData.status = 400
    returnData.error = err
  }
}

const saveKeywordsDataTemplate = async (keywordsData, gridtemplateData) => {

  let returnData = {
    status: 200,
    gridtemplates: {
    },
    keywords: {
    }
  }

  try {

    const gResult = await saveDataTemplate(gridtemplateData)
    returnData.gridtemplates = gResult


    const kResult = await saveKeywords(keywordsData)
    returnData.keywords = kResult

    return returnData

  } catch (err) {
    console.error(err);
    returnData.status = 400
    returnData.both = err
    return returnData
  }
}

const processDefaultFormikScheduleValues = (keywordsScheduleData) => {

  /*
  schedule: {
    "interval": "hourly",
    "day": "0",     //null
    "hour": "0",    //null
    "minute": "10"
  },
  // run at 10am each day
  schedule: {
    "interval": "daily",
    "day": "0",     //null
    "hour": "10",
    "minute": "0"   //null
  },
  // run every monday at 2pm
  schedule: {
    "interval": "weekly",
    "day": "1",     // Mon is index 1, Sun is index 7
    "hour": "14",
    "minute": "0"   //null
  }
  // run 21st of each month at 1pm
  schedule: {
    "interval": "monthly",
    "day": "21",
    "hour": "13",
    "minute": "0"   //null
  }
  */

  let returnData = {
    scheduleActive: true,
    scheduleInterval: 'hourly',
    scheduleHourlyMinute: '0',
    scheduleDailyHour: '0',
    scheduleWeeklyDay: '1',
    scheduleWeeklyHour: '0',
    scheduleMonthlyDay: '1',
    scheduleMonthlyHour: '0'
  }


  //  let returnData = {
  //    scheduleActive: true,
  //    scheduleInterval: 'hourly',
  //    scheduleHourlyMinute: 0,
  //    scheduleDailyHour: 0,
  //    scheduleWeeklyDay: 1,
  //    scheduleWeeklyHour: 0,
  //    scheduleMonthlyDay: 1,
  //    scheduleMonthlyHour: 0
  //  }

  if (keywordsScheduleData && keywordsScheduleData.interval && keywordsScheduleData.interval !== '') {
    const interval = keywordsScheduleData.interval
    // set interval
    returnData.scheduleInterval = keywordsScheduleData.interval

    // set active
    if (keywordsScheduleData.active === true || keywordsScheduleData.active === false) {
      returnData.scheduleActive = keywordsScheduleData.active
    }

    if ('hourly' === interval) {
      // update minute
      if (keywordsScheduleData.minute) {
        returnData.scheduleHourlyMinute = keywordsScheduleData.minute
      }
    }

    if ('daily' === interval) {
      // update hour
      if (keywordsScheduleData.hour) {
        returnData.scheduleDailyHour = keywordsScheduleData.hour
      }
    }

    if ('weekly' === interval) {
      // update day
      if (keywordsScheduleData.day) {
        returnData.scheduleWeeklyDay = keywordsScheduleData.day
      }
      // update hour
      if (keywordsScheduleData.hour) {
        returnData.scheduleWeeklyHour = keywordsScheduleData.hour
      }
    }

    if ('monthly' === interval) {
      // update day
      if (keywordsScheduleData.day) {
        returnData.scheduleMonthlyDay = keywordsScheduleData.day
      }
      // update hour
      if (keywordsScheduleData.hour) {
        returnData.scheduleMonthlyHour = keywordsScheduleData.hour
      }
    }
  }

  return returnData

}

const processFormikScheduleValues = (formikData) => {

  let returnData = {}

  if (formikData && formikData.scheduleInterval && formikData.scheduleInterval !== '') {

    const interval = formikData.scheduleInterval
    returnData.interval = interval

    if ('hourly' === interval) {
      // update minute
      if (formikData.scheduleHourlyMinute) {
        returnData.minute = formikData.scheduleHourlyMinute
      }
    }

    if ('daily' === interval) {
      // update hour
      if (formikData.scheduleDailyHour) {
        returnData.hour = formikData.scheduleDailyHour
      }
    }

    if ('weekly' === interval) {
      // update day
      if (formikData.scheduleWeeklyDay) {
        returnData.day = formikData.scheduleWeeklyDay
      }
      // update hour
      if (formikData.scheduleWeeklyHour) {
        returnData.hour = formikData.scheduleWeeklyHour
      }
    }

    if ('monthly' === interval) {
      // update day
      if (formikData.scheduleMonthlyDay) {
        returnData.day = formikData.scheduleMonthlyDay
      }
      // update hour
      if (formikData.scheduleMonthlyHour) {
        returnData.hour = formikData.scheduleMonthlyHour
      }
    }


    if (formikData.scheduleActive === true || formikData.scheduleActive === false) {
      returnData.active = formikData.scheduleActive
    }
  }

  return returnData
}

const buildKeywordsData = (userData, ecomacyUserDataData, selectedLocation) => {

  let keywordsDataObj = {
    data: {
      businessname: '',
      googleplaceid: '',
      address: '',
      city: '',
      state: '',
      postalcode: '',
      country: '',
      website: '',
      timezone: '',
      lat: null,
      lng: null,
      zoom: "21z"
    },
    eclocationid: userData.locationId,
    gridtemplateid: '',
    // id: "HwxnNn22f6hjIk7h2vbQ",
    keywords: [],
    schedule: {
      active: true,
      day: 1,
      hour: 10,
      interval: 'weekly'
    },
    userid: userData.userId
  }

  // userData Params
  let userId = ''
  if (userData.userId) {
    userId = userData.userId
  }
  keywordsDataObj.userid = userId

  let locationId = ''
  if (userData.locationId) {
    locationId = userData.locationId
  }
  keywordsDataObj.eclocationid = locationId

  // ecomacyUserData Params
  let businessName = ''
  if (ecomacyUserDataData.name && ecomacyUserDataData.name !== '') {
    businessName = ecomacyUserDataData.name
  }
  keywordsDataObj.data.businessname = businessName

  let address = ''
  if (ecomacyUserDataData.address && ecomacyUserDataData.address !== '') {
    address = ecomacyUserDataData.address
  }
  keywordsDataObj.data.address = address


  let city = ''
  if (ecomacyUserDataData.city && ecomacyUserDataData.city !== '') {
    city = ecomacyUserDataData.city
  }
  keywordsDataObj.data.city = city

  let state = ''
  if (ecomacyUserDataData.state && ecomacyUserDataData.state !== '') {
    state = ecomacyUserDataData.state
  }
  keywordsDataObj.data.state = state

  let postalCode = ''
  if (ecomacyUserDataData.postalCode && ecomacyUserDataData.postalCode !== '') {
    postalCode = ecomacyUserDataData.postalCode
  }
  keywordsDataObj.data.postalcode = postalCode

  let country = ''
  if (ecomacyUserDataData.country && ecomacyUserDataData.country !== '') {
    // if (ecomacyUserDataData.country === 'GB') {
    //     country = 'UK'
    // }
    // else {
    country = ecomacyUserDataData.country
    // }
    // addressArr.push(data.country)
  }
  keywordsDataObj.data.country = country


  let website = ''
  if (ecomacyUserDataData.website && ecomacyUserDataData.website !== '') {
    website = ecomacyUserDataData.website
  }
  keywordsDataObj.data.website = website

  let timezone = ''
  if (ecomacyUserDataData.timezone && ecomacyUserDataData.timezone !== '') {
    timezone = ecomacyUserDataData.timezone
  }
  keywordsDataObj.data.timezone = timezone


  let googlePlaceId = ''

  if (ecomacyUserDataData.social && ecomacyUserDataData.social.googlePlaceId && ecomacyUserDataData.social.googlePlaceId !== '') {
    googlePlaceId = ecomacyUserDataData.social.googlePlaceId
  }
  keywordsDataObj.data.googleplaceid = googlePlaceId

  // location Params
  let lat = null
  if (selectedLocation.lat) {
    lat = selectedLocation.lat
  }
  keywordsDataObj.data.lat = lat

  let lng = null
  if (selectedLocation.lng) {
    lng = selectedLocation.lng
  }
  keywordsDataObj.data.lng = lng

  return keywordsDataObj
}



const saveSetupKeywordsData = async (keywordsData) => {

  let returnData = {
    docId: '',
    status: 200
  }

  try {

    // generate keywords id
    const keywordsRef = doc(collection(db, 'keywords'));
    const uuid = keywordsRef.id

    keywordsData.id = uuid

    // update keywords
    await setDoc(keywordsRef, keywordsData, {
      // merge: true
    });


    return returnData

  } catch (err) {
    console.error(err);

    returnData.status = 400
    returnData.error = err
  }
}





export {
  checkKeywordGridData,
  processUserKeywordData,
  processKeywords,
  processDataTemplates,
  checkGridTemplates,
  generateGridTemplateId,
  saveKeywordsDataTemplate,
  processDefaultFormikScheduleValues,
  processFormikScheduleValues,
  buildKeywordsData,
  saveSetupKeywordsData
}
