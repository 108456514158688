import _ from 'lodash';
import { getUserToken } from './firebase'

import axios from 'axios';


const getEcomacyLocationData = async () => {
    let returnData = {
        status: 200,
        data: {},
        error: {}
    }
    try {
        const userData = getUrlParams()

        let locationId = ''
        if (userData.locationId !== '') {
            locationId = userData.locationId
        }
        // let email = ''
        // if (userData.email !== '') {
        //     email = userData.email
        // }

        if (locationId !== '') {
            // call ecomacy API to get location data

            const userToken = await getUserToken()
            const jwt = 'Bearer ' + userToken

            const data = {
                jwt: jwt,
                payload: {
                    locationid: locationId
                }
            }

            let result = await requestLocationFromApi(data, 5)
            return (result)
        }
        else {
            returnData.status = 500
            returnData.error = {
                val: 'locationId or email not set'
            }
            return (returnData)
        }

    } catch (err) {
        console.error(err);
        returnData.status = 501
        returnData.error = {
            val: 'Ecomacy loaction data fetch failed'
        }
    }
}


const requestLocationFromApi = async (data) => {

    let returnData = {
        status: 0,
        data: {},
        error: {}
    }
    try {
        let URL = import.meta.env.VITE_REACT_APP_AI_API_URL
        /*
        let URL = 'https://api.ecomacy.com/'
        if (import.meta.env.VITE_REACT_APP_ENV === 'dev') {
            URL = 'http://localhost:8080/'
        }
        */

        if (clog()) console.log('requestLocationFromApi URL', URL)
        if (clog()) console.log('jwt token', data.jwt)
        if (clog()) console.log('URL', URL + 'location')
        const jwt = data.jwt
        const payload = data.payload
        const response = await axios.post(
            URL + 'location',
            payload,
            {
                headers: {
                    "Authorization": jwt
                }
            }
        )
        // cLog(response)
        // cLog(response.data)
        if (response.status === 200) {
            const responseData = response.data

            if (responseData.status === 200) {
                returnData.status = responseData.status
                returnData.data = responseData.data

            }
            else {
                returnData.status = responseData.status
                returnData.error = responseData.error
            }
        }
        else {
            returnData.status = response.status
            returnData.error = response.error
        }

        return returnData


    } catch (err) {
        console.error(err);
        returnData.status = 491
        returnData.error = err
    }
}


const getDfsLocationData = async (userData) => {
    let returnData = {
        status: 200,
        data: {},
        error: {}
    }
    try {
        // const userData = getUrlParams()

        let googleplaceid = ''
        if (userData.googleplaceid !== '') {
            googleplaceid = userData.googleplaceid
        }
        // let email = ''
        // if (userData.email !== '') {
        //     email = userData.email
        // }

        if (googleplaceid !== '') {
            // call ecomacy API to get location data

            const userToken = await getUserToken()
            const jwt = 'Bearer ' + userToken

            const data = {
                jwt: jwt,
                payload: {
                    googleplaceid: googleplaceid
                }
            }

            let result = await requestDfsLocationFromApi(data, 5)
            return (result)
        }
        else {
            returnData.status = 500
            returnData.error = {
                val: 'googleplaceid not set'
            }
            return (returnData)
        }

    } catch (err) {
        console.error(err);
        returnData.status = 501
        returnData.error = {
            val: 'Ecomacy loaction data fetch failed'
        }
    }
}



const requestDfsLocationFromApi = async (data) => {

    let returnData = {
        status: 0,
        data: {},
        error: {}
    }
    try {

        let URL = 'https://api.ecomacy.com/'
        if (import.meta.env.VITE_REACT_APP_ENV === 'devXXXXX') {
            // if (clog()) console.log('using dev url NOT main server')
            // URL = 'http://localhost:8080/'
        }
        const jwt = data.jwt
        const payload = data.payload

        // if (clog()) console.log('requestDfsLocationFromApi URL', URL)
        const response = await axios.post(
            URL + 'dfslocation',
            payload,
            {
                headers: {
                    "Authorization": jwt
                }
            }
        )

        // cLog(response)
        // cLog(response.data)
        if (response.status === 200) {
            const responseData = response.data

            if (responseData.status === 200) {
                returnData.status = responseData.status
                returnData.data = responseData.data
            }
            else {
                returnData.status = responseData.status
                returnData.error = responseData.error
            }
        }
        else {
            returnData.status = response.status
            returnData.error = response.error
        }

        return returnData


    } catch (err) {
        console.error(err);
        returnData.status = 491
        returnData.error = err
    }
}



const getUrlParamsOld = () => {

    let returnData = {
        locationId: '',
        email: ''
    }
    // START: read url
    const url = window.location.href

    let cleanedUrl = url.replace(/^http?:\/\//, '')
    cleanedUrl = url.replace(/^https?:\/\//, '')

    const urlParamsArr = cleanedUrl.split('/')
    let locationId = ''

    if (urlParamsArr[1] && urlParamsArr[1] !== '') {
        locationId = urlParamsArr[1]
    }
    let email = ''
    if (urlParamsArr[2] && urlParamsArr[2] !== '') {
        email = urlParamsArr[2]
    }

    // if (clog()) console.log('url locationId:', locationId)
    // if (clog()) console.log('url email:', email)

    returnData.locationId = locationId
    returnData.email = email

    return returnData

}

const getUrlParams = (data = null, ) => {

    let returnData = {
        url: '',
        type: '',
        locationId: '',
        email: ''
    }

    // START: read url
    // const url = window.location.href
    let w = null
    if (typeof window !== 'undefined') {
        w = window
    }

    let url = ''
    // test if window object exists
    if (w && w.location && w.location.href) {
        url = w.location.href
    }

    // test if data object exists then override url - vitest
    if (data && data.href) {
        url = data.href
    }

    let isHttps = false
    if (url.indexOf('https://') !== -1) {
        isHttps = true
    }

    let cleanedUrl = url.replace(/^http?:\/\//, '')
    cleanedUrl = url.replace(/^https?:\/\//, '')

    const urlParamsArr = cleanedUrl.split('/')

    let url_base = 'http://' + urlParamsArr[0]
    if (isHttps) {
        url_base = 'https://' + urlParamsArr[0]
    }
    let locationId = ''
    let email = ''
    let type = ''

    // test for ai, grid or admin as first param in url
    if (urlParamsArr[1] && urlParamsArr[1] !== '') {
        const param = urlParamsArr[1]
        if (param === 'ai') {
            type = 'ai'
        }
        else if (param === 'grid') {
            type = 'grid'
        }
        else if (param === 'admin') {
            type = 'admin'
        }
    }

    if (type === '') {
        // if type is not set so use first param as locationId
        if (urlParamsArr[1] && urlParamsArr[1] !== '') {
            // locationId = urlParamsArr[1]
        }
        if (urlParamsArr[2] && urlParamsArr[2] !== '') {
            // email = urlParamsArr[2]
        }
    }
    else {
        // type is set so use second param as locationId
        if (urlParamsArr[2] && urlParamsArr[2] !== '') {
            locationId = urlParamsArr[2]
        }
        if (urlParamsArr[3] && urlParamsArr[3] !== '') {
            email = urlParamsArr[3]
        }
    }
    // if (clog()) console.log('url locationId:', locationId)
    // if (clog()) console.log('url email:', email)
    returnData.url = url_base
    returnData.type = type
    returnData.locationId = locationId
    returnData.email = email

    return returnData

}

const processKeywordsUserData = (userData, keywordsDocDataData) => {
    let returnData = {
        userId: userData.userId,
        locationId: userData.locationId,
        email: userData.email,
        website: '',
        address: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
        lat: null,
        lng: null,
        zoom: '',
        googleplaceid: '',
        businessname: '',
        timezone: ''
    }

    if (keywordsDocDataData.website && keywordsDocDataData.website !== '') {
        returnData.website = keywordsDocDataData.website
    }

    if (keywordsDocDataData.address && keywordsDocDataData.address !== '') {
        returnData.address = keywordsDocDataData.address
    }

    if (keywordsDocDataData.city && keywordsDocDataData.city !== '') {
        returnData.city = keywordsDocDataData.city
    }

    if (keywordsDocDataData.state && keywordsDocDataData.state !== '') {
        returnData.state = keywordsDocDataData.state
    }

    if (keywordsDocDataData.country && keywordsDocDataData.country !== '') {
        returnData.country = keywordsDocDataData.country
    }

    if (keywordsDocDataData.postalcode && keywordsDocDataData.postalcode !== '') {
        returnData.postalCode = keywordsDocDataData.postalcode
    }

    if (keywordsDocDataData.lat && keywordsDocDataData.lat !== null) {
        returnData.lat = keywordsDocDataData.lat
    }

    if (keywordsDocDataData.lng && keywordsDocDataData.lng !== null) {
        returnData.lng = keywordsDocDataData.lng
    }

    if (keywordsDocDataData.zoom && keywordsDocDataData.zoom !== '') {
        returnData.zoom = keywordsDocDataData.zoom
    }

    if (keywordsDocDataData.businessname && keywordsDocDataData.businessname !== '') {
        returnData.businessname = keywordsDocDataData.businessname
    }

    if (keywordsDocDataData.timezone && keywordsDocDataData.timezone !== '') {
        returnData.timezone = keywordsDocDataData.timezone
    }

    if (keywordsDocDataData.googleplaceid && keywordsDocDataData.googleplaceid !== '') {
        returnData.googleplaceid = keywordsDocDataData.googleplaceid
    }

    return returnData

}

const compareKeywordsEcomacyData = (ecomacyUserData, keywordsDocData) => {
    // let match = true
    let returnData = {
        data: {},
        match: true
    }

    let ecomacyData = {}
    if (ecomacyUserData && ecomacyUserData.data && !_.isEmpty(ecomacyUserData.data)) {
        ecomacyData = ecomacyUserData.data
    }
    else {
        returnData.data['ecomacyUserData'] = 'all'
        returnData.match = false
    }

    let keywordsData = {}
    if (keywordsDocData && keywordsDocData.data && !_.isEmpty(keywordsDocData.data)) {
        keywordsData = keywordsDocData.data
    }
    else {
        returnData.data['keywordsDocData'] = 'all'
        returnData.match = false
    }


    if (returnData.match === true) {
        // businessname: '',
        if (ecomacyData.name !== keywordsData.businessname) {
            returnData.data['businessname'] = {
                e: ecomacyData.name,
                k: keywordsData.businessname
            }
            returnData.match = false
        }

        // googleplaceid: '',
        if (ecomacyData.social.googlePlaceId !== keywordsData.googleplaceid) {
            returnData.data['googleplaceid'] = {
                e: ecomacyData.social.googlePlaceId,
                k: keywordsData.googleplaceid
            }
            returnData.match = false
        }

        // address: '',
        if (ecomacyData.address !== keywordsData.address) {
            returnData.data['address'] = {
                e: ecomacyData.address,
                k: keywordsData.address
            }
            returnData.match = false
        }

        // city: '',
        if (ecomacyData.city !== keywordsData.city) {
            returnData.data['city'] = {
                e: ecomacyData.city,
                k: keywordsData.city
            }
            returnData.match = false
        }

        // state: '',
        if (ecomacyData.state !== keywordsData.state) {
            returnData.data['state'] = {
                e: ecomacyData.state,
                k: keywordsData.state
            }
            returnData.match = false
        }

        // postalcode: '',
        if (ecomacyData.postalCode !== keywordsData.postalcode) {
            returnData.data['postalcode'] = {
                e: ecomacyData.postalCode,
                k: keywordsData.postalcode
            }
            returnData.match = false
        }
        // country: '',
        if (ecomacyData.country !== keywordsData.country) {
            returnData.data['country'] = {
                e: ecomacyData.country,
                k: keywordsData.country
            }
            returnData.match = false
        }

        // website: '',
        let cleanedEcomacyWebsite = ecomacyData.website.replace(/^https?:\/\//, '')
        let cleanedKeywordsWebsite = keywordsData.website.replace(/^https?:\/\//, '')

        if (cleanedEcomacyWebsite !== cleanedKeywordsWebsite) {
            returnData.data['website'] = {
                e: ecomacyData.website,
                k: keywordsData.website
            }
            returnData.match = false
        }

        // timezone: '',
        if (ecomacyData.timezone !== keywordsData.timezone) {
            returnData.data['timezone'] = {
                e: ecomacyData.timezone,
                k: keywordsData.timezone
            }
            returnData.match = false
        }

        // lat: null,

        // lng: null,
    }

    return returnData
}


export {
    getUrlParams,
    getEcomacyLocationData,
    processKeywordsUserData,
    getDfsLocationData,
    compareKeywordsEcomacyData
}

