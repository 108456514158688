// import './App.css';
// import './GoogleMapPane.css';

import React, {
  useState,
  useEffect
} from 'react'
import _ from 'lodash';


// import {
//   getFirestore,
//   collection,
//   query,
//   where,
//   // orderBy
// } from 'firebase/firestore';
// import { useCollection } from 'react-firebase-hooks/firestore';
// import { firebase } from '../code/firebase';

import {
  GoogleMap,
  useJsApiLoader,
  // MarkerF,
  Marker,
  InfoWindow,
  // InfoBox,
  // Circle
  // OverlayView
} from '@react-google-maps/api';

import {
  Container, Typography,
  // Box
} from '@mui/material';
// import { useTheme } from '@mui/material/styles';

import markerGrey from '../assets/grey.svg';
import markerGreyHover from '../assets/greyHover.svg';
import markerGreen from '../assets/green.svg';
import markerGreenHover from '../assets/greenHover.svg';
import markerOrange from '../assets/orange.svg';
import markerOrangeHover from '../assets/orangeHover.svg';

import GoogleMapPaneDialog from './GoogleMapPaneDialog'

import GridItemInfo from './GridItemInfo'
import {
  getLocationData
} from '../code/googleMapsGrid';

import '../assets/extra.css'


const mapsApiKey = import.meta.env.VITE_REACT_APP_MAPS_API
// const libraries = ["places"];


const GoogleMapPane = (props) => {

  let userData = {}
  if (props.userData) {
    userData = props.userData
  }

  let title = ''
  if (props.title) {
    title = props.title
  }

  // BT: Use lat/lng from user instead of gridtemplate??
  let lat = null
  if (userData.lat) {
    lat = userData.lat
  }

  let lng = null
  if (userData.lng) {
    lng = userData.lng
  }

  //  let googleplaceid = ''
  //  if (userData.googleplaceid) {
  //    googleplaceid = userData.googleplaceid
  //  }

  const keywordsDocData = props.keywordsDocData

  let centerObj = {
    lat: null,
    lng: null
  };

  if (lat && lng) {
    centerObj = {
      lat: lat,
      lng: lng
    };

  }

  // let markerMouseOver = true

  // eslint-disable-next-line
  const [center, setCenter] = useState(centerObj)

  const [gridItemsData, setGridItemsData] = useState({})
  const [gridItemScanData, setGridItemScanData] = useState([])
  const [gridItemParams, setGridItemParams] = useState({})

  const [openDialog, setOpenDialog] = useState(false)

  const [activeMarker, setActiveMarker] = useState(null);
  // const timer = React.useRef();
  // clearTimeout(timer.current);
  // timer.current = window.setTimeout(() => {
  //   setSuccess(false);
  // }, 200);


  const handleDialogClose = () => {
    setGridItemScanData([])
    setGridItemParams({})
    setOpenDialog(false)
  };

  const handleMapMouseOut = () => {
    // if (clog()) console.log('handleMapMouseOut')
    // if (activeMarker !== null) {
    //   // clearTimeout(timer.current);
    //   setActiveMarker(null)
    // }
  }
  const handleMapMouseOver = () => {
    // if (clog()) console.log('handleMapMouseOver')
    if (activeMarker !== null) {
      // clearTimeout(timer.current);
      setActiveMarker(null)
    }
  }

  // const handleMapMouseMove = () => {
  //   if (clog()) console.log('handleMapMouseMove')
  //   // if (activeMarker !== null ) {
  //   //   setActiveMarker(null)
  //   // }
  // }

  const handleMapBoundsChanged = () => {
    // if (clog()) console.log('handleMapMouseMove')
    // if (activeMarker !== null ) {
    //   setActiveMarker(null)
    // }
    // const currZoom = map.getZoom()
    // map.setZoom(currZoom - 1);
  }




  const handleMarkerMouseOver = async (marker, markerId, scanId) => {
    // if (clog()) console.log('MARKER MOUSEOVER')
    if (markerId === activeMarker) {
      // if (clog()) console.log('ACTIVE MARKER:', markerId)
      return;
    }
    else {
      setActiveMarker(markerId);
    }

    // check if scanId is in gridItemsData
    if (!gridItemsData[scanId]) {
      // if (clog()) console.log('item missing:', scanId)
      // item dos not exist so fetch from locations
      const itemData = await getLocationData(userData, scanId)
      if (itemData.status === 200) {
        // update gridItemsData state
        gridItemsData[scanId] = itemData.data;
        setGridItemsData({ ...gridItemsData });
      }
    }

    // setActiveMarker(markerId);


  };

  const handleMarkerMouseOut = (marker, markerId, scanId) => {
    // if (clog()) console.log('MARKER MOUSEOUT:', markerId)
    // clearTimeout(timer.current)
    // markerMouseOver = false
    setActiveMarker(null);
    // const test = marker.InfoWindow
  }

  const handleMarkerOnClick = async (id, scanId, pos, title) => {

    if (gridItemsData && gridItemsData[scanId]) {
      const gridItemData = gridItemsData[scanId]
      if (
        gridItemData
        && gridItemData.data
        && gridItemData.data.result
        && gridItemData.data.result[0]
      ) {
        const result = gridItemData.data.result[0]
        if (result.items && result.items.length > 0) {
          const items = result.items

          setGridItemScanData(items)
          setOpenDialog(true)

          const params = {
            pos: pos,
            title: title,
            distance: selectedKeywordScan.distance,
            distanceunit: selectedKeywordScan.distanceunit
          }

          setGridItemParams(params)

          // if (clog()) console.log('DATA', JSON.stringify(items))
        }
      }

    }
    else {
      // scanId is not in gridItemsData
      // if (clog()) console.log('click item missing:', scanId)
      // item dos not exist so fetch from locations
      const itemData = await getLocationData(userData, scanId)
      if (itemData.status === 200) {
        // update gridItemsData state
        gridItemsData[scanId] = itemData.data;
        setGridItemsData({ ...gridItemsData });

        const gridItemData = itemData.data
        if (
          gridItemData
          && gridItemData.data
          && gridItemData.data.result
          && gridItemData.data.result[0]
        ) {
          const result = gridItemData.data.result[0]
          if (result.items && result.items.length > 0) {
            const items = result.items
            // if (clog()) console.log('DATA', JSON.stringify(items))
            setGridItemScanData(items)
            setOpenDialog(true)
            const params = {
              pos: pos,
              title: title
            }
            setGridItemParams(params)
          }
        }

      }
    }
  }

  const handleInfoWindowOnContentChanged = () => {

  }

  /*
  const handleMarkerMouseOverOLD = async (marker, scanId) => {
    if (marker === activeMarker) {
      if (clog()) console.log('ACTIVE MARKER')
      return;
    }

    // check if scanId is in gridItemsData
    if (!gridItemsData[scanId]) {
      if (clog()) console.log('item missing:', scanId)
      // item dos not exist so fetch from locations
      const itemData = await getLocationData(userData, scanId)
      if (itemData.status === 200) {
        // update gridItemsData state
        gridItemsData[scanId] = itemData.data;
        setGridItemsData({ ...gridItemsData });
      }

    }
    setActiveMarker(marker);

    //    timer.current = window.setTimeout( async () => {
    //      // check if scanId is in gridItemsData
    //      if (!gridItemsData[scanId]) {
    //        if (clog()) console.log('item missing:', scanId)
    //        // item dos not exist so fetch from locations
    //        const itemData = await getLocationData(userData, scanId)
    //        if (itemData.status === 200) {
    //          // update gridItemsData state
    //          gridItemsData[scanId] = itemData.data;
    //          setGridItemsData({ ...gridItemsData });
    //        }
    //
    //      }
    //      setActiveMarker(marker);
    //    }, 200);
  };
  */



  let selectedKeywordScan = {}

  // let gridData = []
  // let gridDataArr = []
  let gridDataObj = {}
  if (!_.isEmpty(props.selectedKeywordScan)) {
    selectedKeywordScan = props.selectedKeywordScan
    if (selectedKeywordScan.grid && _.size(selectedKeywordScan.grid) > 0) {
      gridDataObj = selectedKeywordScan.grid
      //const bounds = new window.google.maps.LatLngBounds();
      //gridData.forEach((item, index) => {
      //  const position = {
      //    lat: item.lat,
      //    lng: item.lng,
      //  }
      //  bounds.extend(position)
      //})

      //map.fitBounds(bounds);
      //setMap(map)

    }
  }

  const [gridData, setGridData] = useState(gridDataObj);

  useEffect(() => {
    const gridDataObjLength = _.size(gridDataObj)
    if (gridDataObjLength > 0) {
      setGridData(gridDataObj)
    }
  }, [gridDataObj])

  useEffect(() => {
    if (map) {
      updateBounds(map)
      // handleRecenterMapReset()
    }
  }, [selectedKeywordScan])

  //  useEffect(() => {
  // const gridDataLengthCheck = _.size(gridData)

  //    if (gridDataLengthCheck > 0) {
  //      updateBounds(map)
  //    }
  //  }, [gridData])

  // Add event listener
  const handleWindowResize = () => {
    //if (clog()) console.log('window resize')
    if (map) {
      updateBounds(map)
    }
  }
  window.addEventListener("resize", handleWindowResize);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: mapsApiKey,
    // libraries
  })

  // START: is this needed?
  const [map, setMap] = React.useState(null)


  const onLoad = React.useCallback(
    function callback(gData, map) {

      const gDataLength = _.size(gData)
      if (gDataLength > 0) {
        updateBounds(map, gData)
        // const bounds = new window.google.maps.LatLngBounds(center);
        // map.fitBounds(bounds);
        setMap(map)
      }
      else {
        setMap(map)
      }

      // if (googleplaceid !== '') {
      //   let service = new window.google.maps.places.PlacesService(map);
      //   // let service = new google.maps.places.PlaceDetailsRequest(googleplaceid);
      //   service.getDetails({
      //     placeId: googleplaceid
      //   }, function (place, status) {
      //     if (status === window.google.maps.places.PlacesServiceStatus.OK) {
      //       if (clog()) console.log(place.reviews);
      //       // Intended behavior is to set this.setState({places.place.reviews})
      //     }
      //   })
      // }


    }, []
  )

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  // END: is this needed?



  const updateBounds = (mapObj, gData = null) => {
    let gridDataObj = gridData
    if (gData) {
      gridDataObj = gData
    }

    const gridDataObjLength = _.size(gridDataObj)
    if (gridDataObjLength > 0 && mapObj) {
      // const bounds = new window.google.maps.LatLngBounds(center);
      const bounds = new window.google.maps.LatLngBounds();
      for (var i = 0; i < gridDataObjLength; i++) {
        // if (markers[i].getVisible()) {
        const item = gridDataObj[i]
        const pos = {
          lat: item.lat,
          lng: item.lng
        }
        bounds.extend(pos);
        // }
      }
      mapObj.fitBounds(bounds);

      // const currZoom = mapObj.getZoom()
      // mapObj.setZoom(currZoom - 1);

      setMap(mapObj)
    }
  }


  const mapOptions = {
    // disableDefaultUI: true
    mapTypeControl: false,
    scaleControl: true,
    zoomControl: true,
    fullscreenControl: true,
    streetViewControl: false,
    keyboardShortcutsControl: false,
    showCopyright: false
  }


  // const gridItemInfoStyle = {
  //   width: 400,
  //   height: 170
  // };

/*
  const getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height + 24),
    // x: -(gridItemInfoStyle.width / 2),
    // y: -(gridItemInfoStyle.height + 33),
  })


  const loadCallback = React.useCallback((e) => {
    // if (clog()) console.log('OverlayView onLoad: ', e)
  }, [])

  const unmountCallback = React.useCallback((e) => {
    // if (clog()) console.log('OverlayView onUnmount', e)
  }, [])

  */



  const gridDataLength = _.size(gridData)
  if (gridDataLength > 0) {

    let height = '700px'

    // 3x3
    if (9 === gridDataLength) {
      height = '600px'
    }

    // 5x5
    if (25 === gridDataLength) {
      height = '700px'
    }

    // 7x7
    if (49 === gridDataLength) {
      height = '700px'
    }

    // 9x9
    if (81 === gridDataLength) {
      height = '1000px'
    }

    // 11x11
    if (121 === gridDataLength) {
      height = '1000px'
    }



    const containerStyle = {
      // maxWidth: '1200px',
      maxWidth: '100%',
      // height: '1200px'
      height: height,
      // overflow: 'visible !important'
    };


    // if (clog()) console.log('props.selectedKeywordScan:', props.selectedKeywordScan)

    // gridDataArr = value.something??
    return isLoaded ? (
      <Container
        maxWidth="lg"
        disableGutters
        sx={{
          '& > div': {
            // position: 'relative',
            // overflow: 'visible !important',
            // width: '900px'
          }
        }}
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={12}
          // onLoad={onLoad}
          onLoad={onLoad.bind(this, gridData)}
          onMouseOut={() => handleMapMouseOut()}
          onMouseOver={() => handleMapMouseOver()}
          // onMouseMove={() => handleMapMouseMove()}
          onBoundsChanged={() => handleMapBoundsChanged()}
          onUnmount={onUnmount}
          clickableIcons={false}
          options={mapOptions}
          mapTypeIds={'ROADMAP'}

        >

          {Object.keys(gridData).map((itemId, index) => {
            const item = gridData[itemId]
            const id = item.id

            const scanId = item.scanid

            let pos = {
              lat: item.lat,
              lng: item.lng
            }

            let ratingPos = -1
            if (item && item.position) {
              let itemPos = item.position
              const posType = typeof item.position
              if ('string' === posType) {
                itemPos = parseInt(item.position)
              }
              // ratingPos = item.position + 1
              if (itemPos !== -1) {
                ratingPos = itemPos + 1
              }

            }

            // process label
            let labelText = ' '
            if (ratingPos < 101 && ratingPos !== -1) {
              labelText = ratingPos.toString()
            }


            // grey 8c8c8c
            // let fillColor = 'rgba(140, 140, 140, 0.7)'
            // // green 2FA626
            // if (ratingPos >= 1 && ratingPos <= 3) {
            //   fillColor = 'rgba(47, 166, 38, 0.7)'
            // }
            // // orange FF8C00
            // if (ratingPos >= 4 && ratingPos <= 10) {
            //   fillColor = 'rgba(255, 140, 0, 0.7)'
            // }

            // grey 8c8c8c
            let icon = markerGrey
            let iconHover = markerGreyHover

            // green 2FA626
            if (ratingPos >= 1 && ratingPos <= 3) {
              icon = markerGreen
              iconHover = markerGreenHover
            }
            // orange FF8C00
            if (ratingPos >= 4 && ratingPos <= 10) {
              icon = markerOrange
              iconHover = markerOrangeHover
            }


            const labelParams = {
              text: labelText,
              color: 'white',
              fontSize: '18px',
              fontWeight: "bold"
            }

            return (
              <Marker
                key={id}
                id={'m' + id}
                position={pos}
                label={labelParams}
                onMouseOver={(marker) => handleMarkerMouseOver(marker, id, scanId)}
                onMouseOut={(marker) => handleMarkerMouseOut(marker, id, scanId)}
                onClick={() => handleMarkerOnClick(id, scanId, pos, title)}
                // icon={image}
                icon={{
                  url: id === activeMarker ? iconHover : icon,
                  anchor: new window.google.maps.Point(35, 35),
                  scaledSize: new window.google.maps.Size(70, 70),
                  scale: 1,
                  strokeWeight: 0,
                  // zIndex: 99999999
                }}
                // icon={{
                //   // path: "M10, 35 a 25, 25 0 1,0 50,0 a 25,25 0 1,0 -50,0",
                //   path: "M-20,0 a 20,20 0 1,0 40,0 a 20,20 0 1,0 -40,0",
                //   fillColor: fillColor,
                //   // fillColor: '#cc0000',
                //   fillOpacity: 1,
                //   scale: 1,
                //   strokeWeight: 0,
                //   zIndex: 99999999
                // }}
                onLoad={marker => {

                  // window.google.maps.event.addListener(marker, 'mouseover', function () {
                  //   marker.setIcon(markerRedHover);
                  // });
                  // window.google.maps.event.addListener(marker, 'mouseout', function () {
                  //   marker.setIcon(markerRed);
                  // });

                  //                  const customIcon = (opts) => Object.assign({
                  //                    path: 'M12.75 0l-2.25 2.25 2.25 2.25-5.25 6h-5.25l4.125 4.125-6.375 8.452v0.923h0.923l8.452-6.375 4.125 4.125v-5.25l6-5.25 2.25 2.25 2.25-2.25-11.25-11.25zM10.5 12.75l-1.5-1.5 5.25-5.25 1.5 1.5-5.25 5.25z',
                  //                    fillColor: '#34495e',
                  //                    fillOpacity: 1,
                  //                    strokeColor: '#000',
                  //                    strokeWeight: 1,
                  //                    scale: 1,
                  //                  }, opts);
                  //
                  //                  marker.setIcon(customIcon({
                  //                    fillColor: 'green',
                  //                    strokeColor: 'white'
                  //                  }));
                  //                  return markerLoadHandler(marker, place)
                }}
              >



                {activeMarker === id ? (

                  <InfoWindow
                    // onLoad={onLoad}
                    position={pos}
                    options={{
                      // disableAutoPan: true,
                      // shouldFocus: false
                    }}
                    onContentChanged={() => handleInfoWindowOnContentChanged()}
                  >
                    <GridItemInfo
                      id={id}
                      activeMarker={activeMarker}
                      scanId={scanId}
                      userData={userData}
                      gridItemsData={gridItemsData}
                      keywordsDocData={keywordsDocData}
                      // gridItemInfoStyle={gridItemInfoStyle}
                    />
                  </InfoWindow>
                )
                  : null
                }
              </Marker>
            )
          })}
        </GoogleMap>


        {gridItemScanData.length > 0 && (
          <GoogleMapPaneDialog
            openDialog={openDialog}
            handleDialogClose={handleDialogClose}
            gridItemScanData={gridItemScanData}
            gridItemParams={gridItemParams}
            userData={userData}
            keywordsDocData={keywordsDocData}
          // setOpenDialog={setOpenDialog}
          />
        )}

      </Container>
    ) : <Container maxWidth="lg"></Container>



  }
  else {
    return (
      <Container
        maxWidth="lg"
        disableGutters
      >
        <Typography
          variant={'h4'}
        >
          No data for selected Keyword Grid.
        </Typography>

      </Container>
    )
  }





}

export default React.memo(GoogleMapPane)