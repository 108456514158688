import React from 'react'

import { Outlet } from "react-router-dom";
// import { useTheme } from '@mui/material/styles';

import {
  // AppBar,
  // useMediaQuery,
  Container,
  // Link,
  // Toolbar
} from '@mui/material';

import HeaderAdmin from './Header/HeaderAdmin'


export default function MainAdminLayout(props) {

  let showHeader = true
  if (props && props.hideHeader && props.hideHeader === true) {
    showHeader = false
  }
  // const theme = useTheme();

  return (

    <Container
      id='MainAdminLayout'
      name='MainAdminLayout'
      maxWidth={false}
      disableGutters
      sx={{
        paddingTop: '53px',
        // backgroundColor: '#fe6b02',
        // paddingLeft: '0 !important',
        // paddingRight: '0 !important',
        //bgcolor: 'background.paper',
        // boxShadow: 1,
        // borderRadius: 2,
        // p: 0,
        // minWidth: 300,
      }}
    >

      {showHeader ? <HeaderAdmin /> : <></>}



      <Outlet />

      {
        // Footer
      }
    </Container>

  )

};