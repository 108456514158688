import { Link } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Box, Button, Grid, Divider, Typography, useMediaQuery } from "@mui/material";

import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebase } from "../code/firebase";

import {setd, debug, setc, clog} from "../helpers/HelperFunctions";
import { AdminAuthContext } from "../helpers/AdminAuthProvider";

// project import
// import useAuth from 'hooks/useAuth';
import AnimateButton from "../components/@extended/AnimateButton";
import AuthWrapper from "../sections/auth/AuthWrapper";
// import FirebaseSocial from "../sections/auth/auth-forms/FirebaseSocial";

// ================================|| CHECK MAIL ||================================ //
const auth = getAuth(firebase);

const AdminLoginCheckMailContainer = () => {
  const theme = useTheme();


  const matchDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  // const { isLoggedIn } = useAuth();
  const [user, loadingUser, errorUser] = useAuthState(auth);

  return (
    <AuthWrapper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
            <Typography variant="h3">Hi, Check Your Mail</Typography>
            <Typography color="secondary" sx={{ mb: 0.5, mt: 1.25 }}>
              We have sent password recovery instructions to your email.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <AnimateButton>
            <Button
              component={Link}
              // to={isLoggedIn ? '/auth/login' : '/login'}
              to={user ? "/admin" : "/admin"}
              disableElevation
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              color="primary"
            >
              Sign in
            </Button>
          </AnimateButton>
        </Grid>
        <Grid item xs={12}>
          <Divider>
            <Typography variant={matchDownSM ? "subtitle1" : "h5"}>Sign up with</Typography>
          </Divider>
        </Grid>

        {
          // <Grid item xs={12}>
          //   <FirebaseSocial />
          // </Grid>
        }
      </Grid>
    </AuthWrapper>
  );
};

export default AdminLoginCheckMailContainer;
