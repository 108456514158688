import React, { createContext, useState, useRef, useContext } from "react";
// import auth from '@react-native-firebase/auth';
import { getUserToken } from "../code/firebase";

import { getAuth } from "firebase/auth";

import {
  // collection,
  doc,
  setDoc,
  onSnapshot,
  getDoc,
  getDocs,
  getFirestore,
  collection,
  query,
  where,
  orderBy,
  limit,
  addDoc,
  updateDoc,
  deleteDoc,
  writeBatch,
  serverTimestamp,
  Timestamp,
  deleteField
} from "firebase/firestore";

import _ from "lodash";
import { diff, addedDiff, deletedDiff, updatedDiff, detailedDiff } from "deep-object-diff";

import { getFunctions, httpsCallable } from "firebase/functions";
import { useAuthState } from "react-firebase-hooks/auth";

import { getUrlParams } from "../code/ecomacy";
import { firebase } from "../code/firebase";
import { clone, e, re, sort } from "mathjs";
import { ar, de, tr } from "date-fns/locale";
import { settings } from "firebase/analytics";
// import { Timestamp } from '@google-cloud/firestore';

import { RemoteConfigAuthContext } from "./RemoteConfigAuthProvider";
import { setd, debug, setc, clog } from "./HelperFunctions";

// const axios = require("axios");
import axios from 'axios';
// import { as } from "vitest/dist/reporters-cb94c88b";

// const userData = getUrlParamsX();
// let locationId = "";
// if (userData.locationId !== "") {
//   locationId = userData.locationId;
// }
// let email = "";
// if (userData.email !== "") {
//   email = userData.email;
// }

let globaldeleteme = null;
// globaldeleteme = 2;

// let globaldeleteme = null;
// globaldeleteme = 1;
// example
// // add deleteme
// if (globaldeleteme !== null) {
//   newAgentData.deleteme = globaldeleteme;
// }

const auth = getAuth(firebase);
const functions = getFunctions(firebase, "europe-west1");
const db = getFirestore(firebase);

/**
 * This provider is created
 * to access user in admin area
 */

const initialState = {
  // login: () => {},
  // logout: () => {},
  // authenticate: () => {},
  // getUserToken: () => {},
};

export const AiAuthContext = createContext(initialState);
// export const AuthContext = createContext({} as ContextState);
// const Context = createContext({} as ContextState);

export const AiAuthProvider = ({ children }) => {



  let props = {}
  if (children && children.props) {
    props = { ...children.props };
  }

  let userData = {};
  if (props.userData) {
    userData = props.urlDataParams;
  }
  let locationId = "";
  if (userData.locationId !== "") {
    locationId = userData.locationId;
  }
  let email = "";
  if (userData.email !== "") {
    email = userData.email;
  }

  const urlDataParams = props.urlDataParams;


  // const [user, setUser] = useState(null);
  const { rc } = useContext(RemoteConfigAuthContext);

  const [testVal, setTestVal] = useState("");
  const [showAiCustomerSettingsDialog, setShowAiCustomerSettingsDialog] = useState({
    show: false,
    type: [],
    message: "",
  })
  const [aiCustomerApiKeyStatus, setAiCustomerApiKeyStatus] = useState({
    // eapiKey: "",
    openaiKey: "",
    anthropicKey: "",
  });

  const aiQuestionsEngineerPanelRef = useRef(null);
  const [aiAgentsDocs, setAiAgentsDocs] = useState(null);

  const [aiAgentsSelected, setAiAgentsSelected] = useState(null);
  const aiAgentsSelectedRef = useRef(null);

  const [aiAgentsIsEditing, setAiAgentsIsEditing] = useState({
    active: false,
    type: "",
  });
  const [aiAgentsIsEditingType, setAiAgentsIsEditingType] = useState("");

  const [aiAgentsIsRemoving, setAiAgentsIsRemoving] = useState(false);

  const [showAiPresetAgentsDialog, setShowAiPresetAgentsDialog] = useState(false);
  const [aiAgentsIsSavingPresetAgent, setAiAgentsIsSavingPresetAgent] = useState(false);

  const aiAgentsAutomationLinkItems = [
    { value: "link1", name: "link1" },
    { value: "link2", name: "link2" },
    { value: "link3", name: "link3" },
    { value: "link4", name: "link4" },
    { value: "link5", name: "link5" },
    { value: "link6", name: "link6" },
    { value: "link7", name: "link7" },
    { value: "link8", name: "link8" },
    { value: "link9", name: "link9" },
    { value: "link10", name: "link10" },
    // { value: "link11", name: "link11" },
    // { value: "link12", name: "link12" },
    // { value: "link13", name: "link13" },
    // { value: "link14", name: "link14" },
    // { value: "link15", name: "link15" },
    // { value: "link16", name: "link16" },
    // { value: "link17", name: "link17" },
    // { value: "link18", name: "link18" },
  ]

  const [aiCategoriesDoc, setAiCategoriesDoc] = useState(null);

  const [aiSettingsDocs, setAiSettingsDocs] = useState(null);

  const [aiSettingsLiveData, setAiSettingsLiveData] = useState(null);

  // const [aiLinkSettingsData, setAiLinkSettingsData] = useState(null);
  // const [aiLinkSettingsDataRefresh, setAiLinkSettingsDataRefresh] = useState(false);

  const [aiSettingsSelected, setAiSettingsSelected] = useState(null);
  const aiSettingsSelectedRefresh = useRef(true);
  const [aiSettingsData, setAiSettingsData] = useState(null);
  const aiSettingsDataRef = useRef(null);

  const aiSettingsDataConversationIdRef = useRef("");

  const [aiSettingsDataUpdated, setAiSettingsDataUpdated] = useState(null);
  const [aiSettingsDataDiff, setAiSettingsDataDiff] = useState(null);

  const [aiSettingsSaving, setAiSettingsSaving] = useState(false);


  const [aiSettingsIndexIdSelected, setAiSettingsIndexIdSelected] = useState({
    index: -1,
    aisettingsindexid: "",
    name: "",
    aiconversationid: "",
  });
  const [aiSettingsIndexIdSelectedRefresh, setAiSettingsIndexIdSelectedRefresh] = useState(false);

  const [aiSettingsItemsSwitchPromptsSelected, setAiSettingsItemsSwitchPromptsSelected] = useState({});


  const [aiSettingsIsEditing, setAiSettingsIsEditing] = useState({
    active: false,
    type: "",
  });
  const [aiSettingsIsEditingType, setAiSettingsIsEditingType] = useState("");

  // const [aiChatMessagesData, setAiChatMessagesData] = useState([]);
  const [aiStrategySelected, setAiStrategySelected] = useState(null);
  const [aiStrategySelectedRefresh, setAiStrategySelectedRefresh] = useState(false);

  const [aiModelSelected, setAiModelSelected] = useState(null);
  const [aiModelSelectedRefresh, setAiModelSelectedRefresh] = useState(false);
  // const [aiModel, setAiModel] = useState({})
  const [aiModelReset, setAiModelReset] = useState(false);


  const [aiAgentShowLiveConversations, setAiAgentShowLiveConversations] = useState({
    active: false,
  });
  const [aiLiveLinkSettingsDocs, setAiLiveLinkSettingsDocs] = useState({
    // fetched: false,
    aiagentid: "",
    // ailinksettingid: "all",
    data: [],
  });
  const [aiLiveLinkSettingsSelected, setAiLiveLinkSettingsSelected] = useState({
    //index: -1,
    name: "",
    aiagentid: "",
    ailinksettingid: "all",
  });


  const [aiLiveConversationsDocs, setAiLiveConversationsDocs] = useState({
    aigentid: "",
    ailinksettingid: "",
    data: [],
  });
  const [aiLiveConversationsSelected, setAiLiveConversationsSelected] = useState(null);
  const [aiLiveConversationFetchData, setAiLiveConversationFetchData] = useState(true);

  const [aiLiveConversationsDocsRefresh, setAiLiveConversationsDocsRefresh] = useState(false);

  const [aiLiveConversation, setAiLiveConversation] = useState(null);
  const [refreshAiLiveConversationSettingsData, setRefreshAiLiveConversationSettingsData] = useState(false);
  const [aiLiveMessages, setAiLiveMessages] = useState(null);

  const [aiLiveSettingsData, setAiLiveSettingsData] = useState(null);
  // const aiSettingsDataRef = useRef(null);
  const [aiLiveSettingsDataUpdated, setAiLiveSettingsDataUpdated] = useState(null);

  const [aiConversationsDocs, setAiConversationsDocs] = useState(null);
  const [aiConversationsDocsRefresh, setAiConversationsDocsRefresh] = useState(false);

  const [aiConversationsIsEditing, setAiConversationsIsEditing] = useState({
    active: false,
    type: "",
  });

  const [aiConversationsIsSaving, setAiConversationsIsSaving] = useState(false);
  const [aiConversationsIsRemoving, setAiConversationsIsRemoving] = useState(false);

  const [aiConversationsSelected, setAiConversationsSelected] = useState(null);
  const [aiConversationFetchData, setAiConversationFetchData] = useState(true);

  const [aiConversation, setAiConversation] = useState(null);
  const [refreshAiConversationSettingsData, setRefreshAiConversationSettingsData] = useState(false);

  const [aiMessagesDoc, setAiMessagesDoc] = useState(null);
  const [aiMessages, setAiMessages] = useState(null);
  const [aiMessagesIndexId, setAiMessagesIndexId] = useState({
    index: -1,
    name: "",
    aimessagesid: "",
    aiconversationid: "",
    exists: false,
  });

  const aiConversationMessageCount = useRef(0);
  const [aiChatInput, setAiChatInput] = useState("");

  const [aiConversationDuplicatedRefresh, setAiConversationDuplicatedRefresh] = useState(false);

  const [aiPromptVersionsDocs, setAiPromptVersionsDocs] = useState([]);

  const [aiPromptVersionSelected, setAiPromptVersionSelected] = React.useState({
    index: 0,
    name: "",
    promptversion: -1,
    aipromptversionid: "",
    aiconversationid: "",
  });
  const [aiPromptVersionReset, setAiPromptVersionReset] = useState(false);

  const [aiEngineersPanelShow, setAiEngineersPanelShow] = useState(false);
  const [aiEngineersPanelHeightResizing, setAiEngineersPanelHeightResizing] = useState(false);

  const [aiEngineersDocs, setAiEngineersDocs] = useState(null);
  const [aiEngineersSelected, setAiEngineersSelected] = useState(null);

  const [aiEngineerConversation, setAiEngineerConversation] = useState(null);
  const [aiEngineerConversationUpdated, setAiEngineerConversationUpdated] = useState(null);
  // const [aiEngineerConversationFetched, setAiEngineerConversationFetched] = useState(false);

  const [aiEngineerChatInput, setAiEngineerChatInput] = useState("");

  const [aiShowDescriptionPanel, setAiShowDescriptionPanel] = useState(false);

  const [user, loadingUser, errorUser] = useAuthState(auth);

  const [showSavePresetAlert, setShowSavePresetAlert] = useState(false);
  const [showSavePresetAgentAlert, setShowSavePresetAgentAlert] = useState(false);

  const [showAiAgentVariablesDialog, setShowAiAgentVariablesDialog] = useState(false);
  // const [aiAgentInputs, setAiAgentInputs] = useState([]);

  const [showAiWebCodeDialog, setShowAiWebCodeDialog] = useState(false);
  const [aiWebCodeDomain, setAiWebCodeDomain] = useState("");
  const [aiWebCodeCopyHeader, setAiWebCodeCopyHeader] = useState(null);
  const [aiWebCodeCopyPage, setAiWebCodeCopyPage] = useState(null);

  const aiModelListRef = useRef({
    "gpt-3-5-turbo": { type: "chatgpt" },
    "gpt-3-5-turbo-0301": { type: "chatgpt" },
    "gpt-3-5-turbo-16k": { type: "chatgpt" },
    "gpt-3-5-turbo-0613": { type: "chatgpt" },
    "gpt-3-5-turbo-16k-0613": { type: "chatgpt" },
    "gpt-4": { type: "chatgpt" },
    "gpt-4-0613": { type: "chatgpt" },
    "gpt-4-1106-preview": { type: "chatgpt" },
    "gpt-4-32k": { type: "chatgpt" },
    "gpt-4-32k-0613": { type: "chatgpt" },
    "claude": { type: "claude" },
    "claude-2": { type: "claude" }
  });
  /*
  const [customerUserData, setAiUserData] = useState({
    data: null,
    error: null,
    loaded: false,
  });

  const [customersData, setAisData] = useState({
    data: null,
    // dataOrig: null,
    error: null,
    loaded: false,
    // synced: false
  });

  const [user, loadingUser, errorUser] = useAuthState(auth);

  const [rawData, setRawData] = useState({
    customerData: null,
    accountStartTs: null,
    billingStartTs: null,
    tokensIncluded: null,
    tokensExtra: null,
    monthly: null,
    multiple: null,
    tokenPrice: null,
    runningTotal: null,
    tokensLeftThisMonth: null,
    allCustomerData: false,

    saveKeywords: null,
    keywordsCount: null,
    schedule: null,
    scheduleDay: null,
    scheduleHour: null,
    scheduleInterval: null,
    allKeywordsData: false,

    // saveGridTemplates: null,
    gridCount: null,
    allGridData: false
  })

  const rawDataRef = useRef({});

  const [tokenData, setTokenData] = useState({
    iterationsMonthly: null,
    tokensLeftThisMonth: null,
    tokensNeededSingle: null,
    totalSingle: null,
    tokensNeededMonthly: null,
    totalMonthly: null,
    tokensLeftAfterDeduction: null,
  })
*/

  const getAiUserDoc = async (uid) => {
    try {
      let customerDoc = null;
      let customerLogSearchDoc = null;

      const qc = query(collection(db, "customers"), where("locationid", "==", uid));

      const snapshotCustomer = await getDocs(qc);

      if (snapshotCustomer.empty) {
        const err = {
          val: "no User Doc found",
        };
        return {
          error: err,
        };
      }

      snapshotCustomer.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        // if (clog()) console.log(doc.id, " => ", doc.data());
        customerDoc = doc.data();
      });

      if (customerDoc) {
        if (!customerDoc.data) {
          customerDoc.data = {};
        }
        const qls = query(collection(db, "logsearches"), where("eclocationid", "==", uid), orderBy("created", "desc"), limit(1));

        const snapshotLogSearch = await getDocs(qls);

        // if (snapshotLogSearch.empty) {
        // }

        snapshotLogSearch.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // if (clog()) console.log(doc.id, " => ", doc.data());
          customerLogSearchDoc = doc.data();
        });
      }

      if (customerLogSearchDoc && customerLogSearchDoc.runningtotal) {
        customerDoc.data.runningtotal = customerLogSearchDoc.runningtotal;
      }

      return customerDoc;
    } catch (err) {
      console.error(err);
      return {
        error: err,
      };
    }
  };

  const getAiConversation = async (userData, conversationid) => {
    return new Promise((resolve, reject) => {
      try {
        let aiconversationid = "";
        if (conversationid && conversationid !== "") {
          aiconversationid = conversationid;
        }
        if (aiconversationid !== "") {
          const unsub = onSnapshot(doc(db, "aiconversations", aiconversationid), (doc) => {
            // if (clog()) console.log("Current data: ", doc.data());
            // const docData = doc.data()
            // set aiConversation state that listens to changes
            const data = doc.data();
            if (data) {
              setAiConversation(doc.data());
            } else {
              setAiConversation(null);
            }

            // if (doc.messages && doc.messages.length > 0) {
            //   aiConversationMessageCount.current = doc.messages.length;
            // }
            resolve({
              data: doc.data(),
            });
          });
        }
      } catch (err) {
        console.error(err);
        reject({
          error: err,
        });
      }
    });
  };

  const removeVariablesFromPrompt = (data) => {
    try {
      const newData = data
      let paramsObj
      let content = ''
      if (newData.data && newData.data.content) {
        content = newData.data.content
      }
      let additional_kwargs = ''
      if (newData.data && newData.data.additional_kwargs) {
        additional_kwargs = newData.data.additional_kwargs
      }

      const regex = /\[\[\{'([^']+)'\s*:\s*'([^']+)'\}\]\]/g;
      let variables = {};
      let cleanedString = content;

      let match;
      while ((match = regex.exec(content)) !== null) {
        // variables.push({ [match[1]]: match[2] });
        variables[match[1]] = match[2]

        // add to paramsObj
        // paramsObj[match[1]] = match[2]

        cleanedString = cleanedString.replace(match[0], '').trimStart();
      }

      // add params to newData
      // newData.xvariablesx = variables
      //return cleaned string
      newData.data.content = cleanedString

      return newData
    } catch (err) {
      console.error(err);
      return data
    }
  }

  const getAiMessagesDoc = async (userData, conversationid) => {
    return new Promise((resolve, reject) => {
      try {
        let aiconversationid = "";
        if (conversationid && conversationid !== "") {
          aiconversationid = conversationid;
        }

        if (aiconversationid !== "") {

          // const postRef = db
          //   .collection("aiconversations")
          //   .doc(aiconversationid)
          //   .collection("messages");

          // const unsub1 = postRef
          //   .orderBy("createdon", "desc")
          //   // .limit(1)
          //   .onSnapshot((docSnap) => {
          //     if (clog()) console.log(docSnap.data());
          //    //  messages.push(docSnap.data());

          //   });
          const q = query(
            collection(db, "aimessages"),
            where("id", "==", aiconversationid),
          );

          const unsub = onSnapshot(q, (querySnapshot) => {
            // store for any variables found

            let docData = null;
            querySnapshot.forEach((doc) => {
              docData = doc.data();
            });
            // BT debugger
            if (docData) {
              setAiMessagesDoc(docData);
            } else {
              // setAiMessagesDoc(null);
              setAiMessagesDoc({
                id: aiconversationid,
                noDocYet: true,
              });
            }

            resolve({
              data: docData,
            });
          });
        }

      } catch (err) {
        console.error(err);
        setAiMessagesDoc(null);
        reject({
          error: err,
        });
      }
    });
  };

  const getAiMessages = async (userData, conversationid) => {
    return new Promise((resolve, reject) => {
      try {
        let aiconversationid = "";
        if (conversationid && conversationid !== "") {
          aiconversationid = conversationid;
        }

        if (aiconversationid !== "") {

          // const postRef = db
          //   .collection("aiconversations")
          //   .doc(aiconversationid)
          //   .collection("messages");

          // const unsub1 = postRef
          //   .orderBy("createdon", "desc")
          //   // .limit(1)
          //   .onSnapshot((docSnap) => {
          //     if (clog()) console.log(docSnap.data());
          //    //  messages.push(docSnap.data());

          //   });
          const q = query(
            collection(db, `aimessages/${aiconversationid}/messages`),
            // .doc(aiconversationid)
            // .collection("messages"),
            // where("locationid", "==", locationid),
            // where("archive", "==", false)
            orderBy("createdAt", "asc")
          );

          const unsub = onSnapshot(q, (querySnapshot) => {
            // store for any variables found
            // let aiconversationsMessagesVariablesObj = {};

            const aiconversationsMessagesDocsArr = [];
            querySnapshot.forEach((doc) => {
              const docData = doc.data();
              const newData = _.cloneDeep(docData)
              const cleanedDocData = removeVariablesFromPrompt(newData)

              // add params to aiconversationsMessagesParamsObj
              // aiconversationsMessagesVariablesObj = {
              //   ...aiconversationsMessagesVariablesObj,
              //   ...cleanedDocData.xvariablesx
              // }

              aiconversationsMessagesDocsArr.push(cleanedDocData)
            });

            setAiMessages(aiconversationsMessagesDocsArr);
            // setAiMessagesVariables(aiconversationsMessagesVariablesObj);

            resolve({
              data: aiconversationsMessagesDocsArr,
            });
          });
        }

      } catch (err) {
        console.error(err);
        setAiMessages(null);
        // setAiMessagesVariables(null);
        reject({
          error: err,
        });
      }
    });
  };


  const getAiLiveConversation = async (userData, liveconversationid) => {
    return new Promise((resolve, reject) => {
      try {
        let ailiveconversationid = "";
        if (liveconversationid && liveconversationid !== "") {
          ailiveconversationid = liveconversationid;
        }
        if (ailiveconversationid !== "") {
          const unsub = onSnapshot(doc(db, "ailiveconversations", ailiveconversationid), (doc) => {
            // if (clog()) console.log("Current data: ", doc.data());
            // const docData = doc.data()
            // set aiConversation state that listens to changes
            const data = doc.data();
            if (data) {
              setAiLiveConversation(doc.data());
            } else {
              setAiLiveConversation(null);
            }

            // if (doc.messages && doc.messages.length > 0) {
            //   aiConversationMessageCount.current = doc.messages.length;
            // }
            resolve({
              data: doc.data(),
            });
          });
        }
      } catch (err) {
        console.error(err);
        reject({
          error: err,
        });
      }
    });
  };


  const getAiLiveMessages = async (userData, liveconversationid) => {
    return new Promise((resolve, reject) => {
      try {
        let ailiveconversationid = "";
        if (liveconversationid && liveconversationid !== "") {
          ailiveconversationid = liveconversationid;
        }

        if (ailiveconversationid !== "") {

          // const postRef = db
          //   .collection("aiconversations")
          //   .doc(aiconversationid)
          //   .collection("messages");

          // const unsub1 = postRef
          //   .orderBy("createdon", "desc")
          //   // .limit(1)
          //   .onSnapshot((docSnap) => {
          //     if (clog()) console.log(docSnap.data());
          //    //  messages.push(docSnap.data());

          //   });
          const q = query(
            collection(db, `aimessages/${ailiveconversationid}/messages`),
            // .doc(aiconversationid)
            // .collection("messages"),
            // where("locationid", "==", locationid),
            // where("archive", "==", false)
            orderBy("createdAt", "asc")
          );

          const unsub = onSnapshot(q, (querySnapshot) => {
            const aiconversationsMessagesDocsArr = [];
            querySnapshot.forEach((doc) => {
              const docData = doc.data();
              aiconversationsMessagesDocsArr.push(docData)
            });

            setAiLiveMessages(aiconversationsMessagesDocsArr);

            resolve({
              data: aiconversationsMessagesDocsArr,
            });
          });
        }

      } catch (err) {
        console.error(err);
        setAiMessages(null);
        // setAiMessagesVariables(null);
        reject({
          error: err,
        });
      }
    });
  };

  const getAiWebCodeTokenFromApi = async (payload) => {
    let returnData = {
      status: 0,
      // data: {},
      // error: {},
    };
    try {
      let URL = import.meta.env.VITE_REACT_APP_AI_API_URL;
      /*
      let URL = 'https://api.ecomacy.com'
      if (import.meta.env.VITE_REACT_APP_ENV === 'dev') {
        URL = 'http://localhost:8080'
      }
      */
      //STUAUTH
      if (URL) {
        const userToken = await getUserToken();
        const jwt = "Bearer " + userToken;
        const apiUrlPath = URL.concat("/gettoken");
        // const apiUrlPath = URL.concat("/gettoken/", payload.locationid, "/", payload.link, "/", payload.url);
        // const apiUrlPath = URL.concat("/gettoken/", jsonData);

        if (clog()) console.log("apiUrlPath", apiUrlPath);
        const response = await axios.get(apiUrlPath, {
          // const response = await axios.post(apiUrlPath, payload, {
          headers: {
            "Content-Type": "application/json",
            Authorization: jwt,
          },
          params: payload,
        });
        // cLog(response.data)

        if (response.status === 200) {
          returnData.status = response.status;
          returnData.data = response.data;
        } else {
          returnData.status = response.status;
          returnData.error = response.error;
        }
        // if (clog()) console.log('getAiWebCodeTokenFromApi returnData:', returnData);
        return returnData;
      } else {
        returnData.status = 490;
        returnData.error = "REACT_APP_API_URL url missing from env";
        return returnData;
      }
    } catch (err) {
      console.error("response error", err);
      returnData.status = 491;
      returnData.error = err;
      return returnData;
    }
  };

  const getAiWebCodeToken = async (userData, automationlink) => {
    let returnData = {
      status: 200,
      data: {},
      error: {},
    };
    try {
      let locationid = "";
      if (userData && userData.locationId) {
        locationid = userData.locationId;
      }

      // let domains = [];
      // if (agentDomains && agentDomains.length > 0) {
      //   domains = agentDomains;
      // }

      if (locationid !== "" && automationlink !== "") {
        const data = {
          locationid: locationid,
          link: automationlink,
          // url: url,
          // domains: domains,
        };

        let result = await getAiWebCodeTokenFromApi(data);
        returnData.status = result.status;

        if (result.status === 200 && result.data) {
          returnData.status = result.status;
          returnData.data = result.data;
        } else {
          returnData.status = result.status;
          returnData.error = result.error.code;
        }
        // let result = {status: 'success'};
        return returnData;
      }
    } catch (err) {
      console.error(err);
      returnData.status = 501;
      returnData.error = {
        val: "Web Code Token data fetch failed",
      };
      // return returnData;
    }
  };

  const postEcomacyAiMessageFromApi = async (payload) => {
    let returnData = {
      status: 0,
      data: {},
      error: {},
    };
    try {
      let URL = import.meta.env.VITE_REACT_APP_AI_API_URL;
      /*
      let URL = 'https://api.ecomacy.com'
      if (import.meta.env.VITE_REACT_APP_ENV === 'dev') {
        URL = 'http://localhost:8080'
      }
      */

      //STUAUTH
      if (URL) {
        const userToken = await getUserToken();
        const jwt = "Bearer " + userToken;

        // const apiUrlPath = URL.concat("/message");
        const apiUrlPath = URL.concat("/messagev2"); //changed to new endpoint. Old one always remains
        const response = await axios.post(apiUrlPath, payload, {
          headers: {
            "Content-Type": "application/json",
            Authorization: jwt,
          },
        });

        // cLog(response.data)
        if (response.status === 200) {
          returnData.status = response.status;
          returnData.data = response.data;
        } else {
          returnData.status = response.status;
          returnData.error = response.error;
        }
        // if (clog()) console.log('6 requestMfaFromApi URL', URL);

        return returnData;
      } else {
        returnData.status = 490;
        returnData.error = "REACT_APP_API_URL url missing from env";
        return returnData;
      }
    } catch (err) {
      console.error("response error", err);
      returnData.status = 491;
      returnData.error = err;
      return returnData;
    }
  };

  const postEcomacyAiEngineerMessageFromApi = async (payload) => {
    let returnData = {
      status: 0,
      data: {},
      error: {},
    };
    try {
      let URL = import.meta.env.VITE_REACT_APP_AI_API_URL;
      /*
      let URL = 'https://api.ecomacy.com'
      if (import.meta.env.VITE_REACT_APP_ENV === 'dev') {
        URL = 'http://localhost:8080'
      }
      */

      //STUAUTH
      if (URL) {
        const userToken = await getUserToken();
        const jwt = "Bearer " + userToken;

        const apiUrlPath = URL.concat("/engineermessage");
        const response = await axios.post(apiUrlPath, payload, {
          headers: {
            "Content-Type": "application/json",
            Authorization: jwt,
          },
        });

        // cLog(response.data)
        if (response.status === 200) {
          returnData.status = response.status;
          returnData.data = response.data;
        } else {
          returnData.status = response.status;
          returnData.error = response.error;
        }
        // if (clog()) console.log('6 requestMfaFromApi URL', URL);

        return returnData;
      } else {
        returnData.status = 490;
        returnData.error = "REACT_APP_API_URL url missing from env";
        return returnData;
      }
    } catch (err) {
      console.error("response error", err);
      returnData.status = 491;
      returnData.error = err;
      return returnData;
    }
  };
  const requestEcomacyAiSettingsFromApi = async (data) => {
    let returnData = {
      status: 0,
      data: {},
      error: {},
    };
    try {
      //const jwt = data.jwt;
      let payload = data.payload;

      // temp val
      // payload.locationid = 'yEz3dNwupKApQykDTKPU'

      let URL = "https://api.ecomacy.com";
      // if (clog()) console.log('import.meta.env.VITE_REACT_APP_ENV:', import.meta.env.VITE_REACT_APP_ENV)
      if (import.meta.env.VITE_REACT_APP_ENV === "dev") {
        URL = "http://localhost:8080";
      }

      // SF
      // URL = 'https://ai.ecomacy.com/settings/yEz3dNwupKApQykDTKPU'
      // URL = 'https://ai.ecomacy.com'

      if (URL) {
        // const apiUrlPath = URL.concat('/acl/', payload.eapi);
        const apiUrlPath = URL.concat("/settings/", payload.locationid);
        // const response = await axios.get(URL + '/apguser', payload, {
        const response = await axios.get(apiUrlPath, {
          headers: {
            //  Authorization: jwt,
          },
        });

        // if (clog()) console.log('response:', response);
        // cLog(response.data)
        if (response.status === 200) {
          returnData.status = response.status;
          returnData.data = response.data;
        } else {
          returnData.status = response.status;
          returnData.error = response.error;
        }
        // if (clog()) console.log('6 requestMfaFromApi URL', URL);

        return returnData;
      } else {
        returnData.status = 490;
        returnData.error = "REACT_APP_API_URL url missing from env";
        return returnData;
      }
    } catch (err) {
      console.error("response error", err);
      returnData.status = 491;
      returnData.error = err;
      return returnData;
    }
  };

  // clean selected preset ready using in interface
  const processAiPromptVersionDataForSettings = (newData) => {
    let data = { ...newData };
    // let dataFound = false;

    // remove aiconversationid
    delete data.aiconversationid;

    // remove aipromptversionid
    delete data.aipromptversionid;

    // remove createdon
    delete data.createdon;

    // remove updatedon
    delete data.updatedon;

    // remove name
    delete data.name;

    // add created date
    // const date = generateFirestoreDate();
    // presetData.createdon = date;

    // add updated date
    // presetData.updatedon = date;

    //  if (settingData) {
    //    presetData = { ...settingData };
    //    dataFound = true;
    //  }
    //
    //  if (dataFound) {
    //    const test = presetData;
    //
    //    // set aipresetid
    //    presetData.aipresetid = id;
    //
    //    // add created date
    //    const date = generateFirestoreDate();
    //    presetData.createdon = date;
    //
    //    // add updated date
    //    presetData.updatedon = date;
    //
    //    // remove aiagentid
    //    delete presetData.aiagentid;
    //
    //    // remove aisettingid
    //    delete presetData.aisettingid;
    //
    //    // clean automationlink
    //    presetData.data.automationlink = "";
    //
    //    // remove data name
    //    // presetData.data.name = "";
    //    delete presetData.data.name;
    //
    //    // remove data nameid
    //    // presetData.data.name = "";
    //    delete presetData.data.nameid;
    //
    //    // Build name from agent, setting, and conversation
    //    let agentName = "";
    //    if (agentData && agentData.name) {
    //      agentName = agentData.name;
    //    }
    //
    //    let settingsName = "";
    //    if (settingSelected && settingSelected.name) {
    //      settingsName = settingSelected.name;
    //    }
    //
    //    let conversationName = "";
    //    if (conversationData && conversationData.name) {
    //      conversationName = conversationData.name;
    //    }
    //
    //    presetData.name = agentName + " - " + settingsName + " - " + conversationName;
    //  }

    return data;
  };

  const processAiSettingsData = (settingsData, id) => {
    let returnData = {};
    let dataFound = false;

    if (settingsData) {
      returnData = { ...settingsData };
      dataFound = true;
    }

    if (dataFound) {
      // set aisettingid
      returnData.aisettingid = id;

      // update name
      returnData.data.name = returnData.data.name + " Copy";

      // clear automation link
      returnData.data.automationlink = "";
    }

    return returnData;
  };

  // clean selected preset ready using in interface
  const processAiPresetDataForSettings = (newPresetData) => {
    let presetData = { ...newPresetData };
    let dataFound = false;

    // remove aipresetid
    delete presetData.aipresetid;

    // add created date
    // const date = generateFirestoreDate();
    // presetData.createdon = date;

    // add updated date
    // presetData.updatedon = date;

    //  if (settingData) {
    //    presetData = { ...settingData };
    //    dataFound = true;
    //  }
    //
    //  if (dataFound) {
    //    const test = presetData;
    //
    //    // set aipresetid
    //    presetData.aipresetid = id;
    //
    //    // add created date
    //    const date = generateFirestoreDate();
    //    presetData.createdon = date;
    //
    //    // add updated date
    //    presetData.updatedon = date;
    //
    //    // remove aiagentid
    //    delete presetData.aiagentid;
    //
    //    // remove aisettingid
    //    delete presetData.aisettingid;
    //
    //    // clean automationlink
    //    presetData.data.automationlink = "";
    //
    //    // remove data name
    //    // presetData.data.name = "";
    //    delete presetData.data.name;
    //
    //    // remove data nameid
    //    // presetData.data.name = "";
    //    delete presetData.data.nameid;
    //
    //    // Build name from agent, setting, and conversation
    //    let agentName = "";
    //    if (agentData && agentData.name) {
    //      agentName = agentData.name;
    //    }
    //
    //    let settingsName = "";
    //    if (settingSelected && settingSelected.name) {
    //      settingsName = settingSelected.name;
    //    }
    //
    //    let conversationName = "";
    //    if (conversationData && conversationData.name) {
    //      conversationName = conversationData.name;
    //    }
    //
    //    presetData.name = agentName + " - " + settingsName + " - " + conversationName;
    //  }

    return presetData;
  };

  // when admin creates a new ai preset
  const processAiPresetData = (uData, agentData, conversationData, id) => {
    let presetData = {};

    let dataFound = false;

    let locationId = "";
    if (uData && uData.locationId) {
      locationId = uData.locationId;
    }

    let userId = "";
    if (uData && uData.userId) {
      userId = uData.userId;
    }

    let aiSettingsData = [];
    if (conversationData && conversationData.aisettings) {
      aiSettingsData = _.cloneDeep(conversationData.aisettings);
    }

    if (locationId !== '' && userId !== '' && aiSettingsData.length > 0) {

      // loop through ai settings
      aiSettingsData.forEach((setting, index) => {
        // remove aisettingsindexid
        delete setting.aisettingsindexid;
      })

      // add created date
      const date = generateFirestoreDate();

      // add locationid
      presetData.locationid = locationId;

      // add userid
      presetData.userid = userId;

      // add aisettings
      presetData.aisettings = aiSettingsData;

      // set aipresetid
      presetData.aipresetid = id;


      presetData.createdon = date;

      // add updated date
      presetData.updatedon = date;


      // Build name from agent, setting, and conversation
      let agentName = "";
      if (agentData && agentData.name) {
        agentName = agentData.name;
      }

      let conversationName = "";
      if (conversationData && conversationData.name) {
        conversationName = conversationData.name;
      }

      // get current date
      const now = new Date();

      const year = now.getFullYear().toString().slice(-2); // Get last two digits of the year
      const month = String(now.getMonth() + 1).padStart(2, '0'); // Get month (0-11, so add 1) and pad with zero if needed
      const day = String(now.getDate()).padStart(2, '0'); // Pad day with zero if needed

      const dateFormatted = `${year}${month}${day}`; // Combine parts into a date string
      // let promptVersion = 1;
      // if (conversationData && conversationData.promptversion) {
      //   promptVersion = conversationData.promptversion;
      // }

      // presetData.name = agentName + " - " + conversationName + " - " + promptVersion;
      presetData.name = agentName + " - " + conversationName + " - " + dateFormatted;

    }

    return presetData;
  };

  const processAiPresetDataOLD = (agentData, settingSelected, conversationData, settingData, id) => {
    let presetData = {};
    let dataFound = false;

    if (settingData) {
      presetData = { ...settingData };
      dataFound = true;
    }

    const test = conversationData
    debugger
    if (dataFound) {
      const test = presetData;

      // set aipresetid
      presetData.aipresetid = id;

      // add created date
      const date = generateFirestoreDate();
      presetData.createdon = date;

      // add updated date
      presetData.updatedon = date;

      // remove aiagentid
      delete presetData.aiagentid;

      // remove aisettingid
      delete presetData.aisettingid;

      // clean automationlink
      presetData.data.automationlink = "";

      // remove data name
      // presetData.data.name = "";
      delete presetData.data.name;

      // remove data nameid
      // presetData.data.name = "";
      delete presetData.data.nameid;

      // Build name from agent, setting, and conversation
      let agentName = "";
      if (agentData && agentData.name) {
        agentName = agentData.name;
      }

      let settingsName = "";
      if (settingSelected && settingSelected.name) {
        settingsName = settingSelected.name;
      }

      let conversationName = "";
      if (conversationData && conversationData.name) {
        conversationName = conversationData.name;
      }

      let promptVersion = 1;
      if (conversationData && conversationData.promptversion) {
        promptVersion = conversationData.promptversion;
      }

      // presetData.name = agentName + " - " + settingsName + " - " + conversationName;
      presetData.name = agentName + " - " + conversationName + " - " + promptVersion;

    }

    return presetData;
  };

  // used in processAiPresetAgentData
  const processAiAgentDataForPresetAgent = (agentData) => {

    let cleanedAgent = _.cloneDeep(agentData);

    return cleanedAgent;

  }

  // used in processAiPresetAgentData
  const processAiConversationDataForPresetAgent = (conversationData) => {

    let cleanedConversation = _.cloneDeep(conversationData);

    // delete messages
    delete cleanedConversation.messages;

    // remove version
    delete cleanedConversation.version;

    // remove versionbasedon
    delete cleanedConversation.versionbasedon;

    return cleanedConversation;

  }


  // when admin creates a new ai preset agent
  const processAiPresetAgentData = (uData, presetAgentName, agentData, conversationsData, id, type, presetAgentRefDoc) => {
    let presetData = {
      aipresetagentid: "",
      presetref: "",
      aiagentid: "",
      aiagent: {},
      aiconversations: [],
      automationlink: "",
      name: "",
    };
    let dataFound = false;

    if (agentData) {
      // presetData = { ...agentData };
      dataFound = true;
    }

    if (dataFound) {


      // process aiagent
      // clean agent
      const cleanAiAgent = processAiAgentDataForPresetAgent(agentData);
      // add

      // add aiagent
      presetData.aiagent = cleanAiAgent;

      // get aiagentid from agent
      presetData.aiagentid = cleanAiAgent.aiagentid;

      // get automationlink from agent
      presetData.automationlink = cleanAiAgent.automationlink;

      if (type === "new" || type === "overwrite") {

        // get presetref from agent
        presetData.presetref = cleanAiAgent.presetref;


      } else if (type === "duplicate") {
        // generate new presetref
        // Get the collection reference
        const agentCollectionRef = collection(db, "aiagents");

        // generate a presetref
        const presetRef = doc(agentCollectionRef);
        // Get the new document Id
        const presetRefId = presetRef.id;

        // set presetref
        presetData.presetref = presetRefId;

        // set presetref in agent
        presetData.aiagent.presetref = presetRefId;

      }

      // process aiagent
      let aiConversationArr = [];

      // loop through conversations
      if (conversationsData && conversationsData.length > 0) {
        conversationsData.forEach((conversation) => {
          // let conversationAgentId = "";
          // if (conversation?.aisettings?.aiagentid) {
          //   conversationAgentId = conversation.aisettings.aiagentid;
          // }

          // clean conversation
          const cleanAiConversation = processAiConversationDataForPresetAgent(conversation);

          // add to array
          aiConversationArr.push(cleanAiConversation);
        });
      }

      // add aiconversations
      presetData.aiconversations = aiConversationArr;


      // user id
      presetData.userid = uData.userId;

      // set aipresetagentid
      presetData.aipresetagentid = id;


      const date = generateFirestoreDate();

      // add created date if doc is not overwrite type
      if (type === "new" || type === "duplicate") {
        presetData.createdon = date;
      }
      // add updated date
      presetData.updatedon = date;

      // // add updated date
      // presetData.updatedon = date;

      // // remove aiagentid
      // delete presetData.aiagentid;

      // // remove aisettingid
      // delete presetData.aisettingid;

      // // clean automationlink
      // presetData.data.automationlink = "";

      // // remove data name
      // // presetData.data.name = "";
      // delete presetData.data.name;

      // // remove data nameid
      // // presetData.data.name = "";
      // delete presetData.data.nameid;

      // Build name from agent, setting, and conversation
      let presetName = "";
      if (presetAgentName) {
        presetName = presetAgentName;
      }
      presetData.name = presetName;

    }


    return presetData;
  };



  const processAiConversationsData = (conversationData, id) => {
    let returnData = {};
    let dataFound = false;

    if (conversationData) {
      returnData = { ...conversationData };
      dataFound = true;
    }

    if (dataFound) {
      // set aiconversationid
      returnData.aiconversationid = id;

      // clear messages array
      // returnData.messages = [];

      // clear ailinksettingid
      returnData.ailinksettingid = "";

      // clear automationlink
      // returnData.automationlink = "";

      // add created date
      const date = generateFirestoreDate();
      returnData.createdon = date;

      // add updated date
      returnData.updatedon = date;

      // add improveprocessing
      returnData.improveprocessing = false;

      // update name
      returnData.name = conversationData.name + " Copy";

      // remove messages
      delete returnData.messages;

      // update name in aisettings
      // returnData.aisettings.name = conversationData.name + " Copy";

      // add archive
      returnData.archive = false;

      // check if name exists inside aiConversationsDocs
      // if it does then add a nuber to the end of the name and check again
      // if it doesn't then use that name
      if (aiConversationsDocs && aiConversationsDocs.length > 0) {
        let nameExists = true;
        let nameCount = 1;
        let newName = returnData.name;
        while (nameExists) {
          nameExists = false;
          aiConversationsDocs.forEach((doc) => {
            if (doc.name === newName) {
              nameExists = true;
              newName = returnData.name + " " + nameCount;
              nameCount++;
            }
          });
        }
        returnData.name = newName;
      }
    }

    return returnData;
  };

  // old: use duplicateUpdateAiConversationSettingsArrayData now
  const duplicateUpdateAiConversationSettingsData = (conversationData, settingsData) => {
    let conversationDataUpdated = null;
    if (conversationData) {
      conversationDataUpdated = { ...conversationData };
    }

    debugger

    let settingsDataUpdated = null;
    if (settingsData) {
      settingsDataUpdated = { ...settingsData };
    }

    // update version
    // let version = 0;
    // if (settingsDataUpdated && settingsDataUpdated.version) {
    //   version = settingsDataUpdated.version;
    // }

    // settingsDataUpdated.version = version + 1;

    // BT version - duplicate conversation
    let promptVersion = 1;
    // let promptVersionCount = 1;

    // add version to aiconversation
    conversationDataUpdated.promptversion = promptVersion;

    // add version based on to aiconversation
    // conversationDataUpdated.promptversioncount = promptVersionCount;

    // add version to aisettings
    settingsDataUpdated.promptversion = promptVersion;

    let version = 1;
    let versionBasedOn = 0;
    if (settingsDataUpdated && settingsDataUpdated.version) {
      //  versionBasedOn = settingsDataUpdated.version;
    }

    // add version to aiconversation
    // conversationDataUpdated.version = version;

    // add version based on to aiconversation
    // conversationDataUpdated.versionbasedon = versionBasedOn;

    // add version to aisettings
    // settingsDataUpdated.version = version;

    // add version based on
    // settingsDataUpdated.versionbasedon = versionBasedOn;

    // clear automationlink from data
    if (!settingsDataUpdated.data) {
      settingsDataUpdated.data = {};
      console.warn("settingsDataUpdated.data not found");
    }

    settingsDataUpdated.data.automationlink = "";

    // JSON stringify settingsDataUpdated
    const settingsUpdatedDataJson = JSON.stringify(settingsDataUpdated);

    // add to conversationDataUpdated
    // conversationDataUpdated.aisettings = settingsUpdatedDataJson;
    conversationDataUpdated.aisettings = settingsDataUpdated;

    // add to messages
    // add settings message to aiconversation doc
    // const message = {
    //   aisettings: settingsUpdatedDataJson,
    //   content: "",
    //   role: "aisettings",
    //   // extra data without processing json
    //   data: {
    //     version: version,
    //     versionbasedon: versionBasedOn,
    //     // versionPrev: versionCurrent,
    //   },
    // };

    // // add message to messages array
    // conversationDataUpdated.messages.push(message);
    delete conversationDataUpdated.messages

    return conversationDataUpdated;
  };

  const duplicateUpdateAiConversationSettingsArrayData = (conversationData, settingsData) => {
    let conversationDataUpdated = null;
    if (conversationData) {
      // conversationDataUpdated = { ...conversationData };
      conversationDataUpdated = _.cloneDeep(conversationData);
    }


    // loop over conversationDataUpdated.aisettings
    if (conversationDataUpdated.aisettings && conversationDataUpdated.aisettings.length > 0) {
      conversationDataUpdated.aisettings.forEach((setting) => {

        // update promptversion
        let promptVersion = 1;
        setting.promptversion = promptVersion;
        setting.promptversioncurrent = promptVersion;

        // generate new aisettingsindexid
        // Get the collection reference
        const collectionRef = collection(db, "aiconversations");

        // Generate "locally" a new document for the given collection reference
        const conversationDocRef = doc(collectionRef);

        // Get the new document Id
        const aiSettingsIndexId = conversationDocRef.id;

        // add new id
        setting.aisettingsindexid = aiSettingsIndexId;
      });
    }
    // let settingsDataUpdated = null;
    // if (settingsData) {
    //   settingsDataUpdated = { ...settingsData };
    // }

    // BT version - duplicate conversation
    // let promptVersion = 1;
    // let promptVersionCount = 1;

    // add version to aiconversation
    // conversationDataUpdated.promptversion = promptVersion;

    // add version to aisettings
    // settingsDataUpdated.promptversion = promptVersion;

    // clear automationlink from data
    // if (!settingsDataUpdated.data) {
    //   settingsDataUpdated.data = {};
    //   console.warn("settingsDataUpdated.data not found");
    // }

    // add to conversationDataUpdated
    // conversationDataUpdated.aisettings = settingsUpdatedDataJson;
    // conversationDataUpdated.aisettings = settingsDataUpdated;

    // // add message to messages array
    // conversationDataUpdated.messages.push(message);
    // old fix as messages array is not in conversations anymore
    delete conversationDataUpdated.messages

    return conversationDataUpdated;
  };

  const getLiveAiConversationsByLinkSettings = async (linkSettingsId) => {
    try {
      let docCount = 0;

      const qa = query(collection(db, "aiconversations"), where("ailinksettingid", "==", linkSettingsId), where("live", "==", true));

      const snapshot = await getDocs(qa);

      if (snapshot.empty) {
        return {
          data: docCount,
        };
      }

      snapshot.forEach((doc) => {
        // if (clog()) console.log(doc.id, " => ", doc.data());
        docCount++;
      });

      return { data: docCount };
    } catch (err) {
      console.error(err);
      return {
        error: err,
      };
    }
  };


  const getAiLinkSettingsDoc = async (locationId, linkSettingsId) => {

    try {
      const docRef = doc(db, "ailinksettings", linkSettingsId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        // const data = docSnap.data();
        return {
          data: true
        };
      } else {
        return {
          data: false,
        };
      }
    } catch (err) {
      console.error(err);
      return {
        error: err,
      };
    }
  }

  const processSettingsDataForLinkSettings = (settingsData) => {
    let cleanedData = { ...settingsData };

    // remove aisettingid
    delete cleanedData.aisettingid;

    return cleanedData;
  };

  const processSettingsDataArrayForLinkSettings = (settingsData) => {
    let clonedData = _.cloneDeep(settingsData);

    // loop over clonedData array and remove name
    clonedData.forEach((data) => {
      // remove name
      delete data.name;

      // update promptversion
      let promptVersion = -1
      if (data.promptversion) {
        promptVersion = data.promptversion;
      }

      let promptVersionCurrent = -1
      if (data.promptversioncurrent) {
        promptVersionCurrent = data.promptversioncurrent;
      }

      data.promptversion = promptVersionCurrent

      // delete promptversioncurrent
      delete data.promptversioncurrent

    });

    let cleanedData = {
      aisettings: clonedData,
    };

    // remove aisettingid
    // delete cleanedData.aisettingid;

    return cleanedData;
  };

  const processSettingsDataArrayForAutomationLink = (settingsData, selectedIndex = -1) => {
    let clonedData = _.cloneDeep(settingsData);

    if (selectedIndex === -1) {
      // loop over clonedData array and update all promptversion to promptversioncurrent
      clonedData.forEach((data) => {
        // update promptversion
        data.promptversion = data.promptversioncurrent;
      });
    }
    else {
      // update promptversion at selected index
      debugger
      if (settingsData[selectedIndex]) {
        clonedData[selectedIndex].promptversion = clonedData[selectedIndex].promptversioncurrent;
      }
      else {
        console.warn('selectedIndex or data does not exist in settings array', selectedIndex)
        console.warn('selectedIndex or data does not exist in settings array', settingsData)
      }
    }

    return clonedData;

  }

  const findAiLinkSettingsLiveConversations = async (locationId, linkSettingsId) => {
    try {
      const qa = query(collection(db, "ailiveconversations"),
        where("locationid", "==", locationId),
        where("ailinksettingid", "==", linkSettingsId),
      );

      const snapshot = await getDocs(qa);

      if (snapshot.empty) {
        return {
          data: 0,
        };
      }

      return { data: 1 };
    } catch (err) {
      console.error(err);
      return {
        error: err,
      };
    }
  };

  const findAiLinkSettingsPromptVersion = async (locationId, conversationId, promptVersion) => {
    try {
      const qa = query(collection(db, "ailinksettings"),
        where("locationid", "==", locationId),
        where("aiconversationid", "==", conversationId),
        where("promptversion", "==", promptVersion),
      );

      const snapshot = await getDocs(qa);

      if (snapshot.empty) {
        console.warn("no ailinksettings doc found");
      }

      let found = false;
      let count = 0;
      let ailinksettingsDocs = [];
      let ailinksettingsDocIds = [];
      let ailinksettingsDoc = null;
      snapshot.forEach((doc) => {
        count++;
        const data = doc.data();
        ailinksettingsDocs.push(data);
        ailinksettingsDocIds.push(doc.ailinksettingid);
      });

      if (count > 1) {
        console.warn("multiple ailinksettings docs found", ailinksettingsDocIds);
      }

      if (count > 0) {
        found = true;
        ailinksettingsDoc = ailinksettingsDocs[0];
      }

      return {
        data: {
          found: found,
          data: ailinksettingsDoc
        }
      };
    } catch (err) {
      console.error(err);
      return {
        error: err,
      };
    }
  };


  const deleteAiLinkSettingsDoc = async (locationId, linkSettingsId) => {
    try {

      const res = await deleteDoc(doc(db, "ailinksettings", linkSettingsId));

      return { data: "success" };
    } catch (err) {
      debugger
      console.error(err);
      return {
        error: err,
      };
    }
  };

  const updateAiLinkSettingsDoc = async (locationId, linkSettingsId) => {
    try {

      const docRef = doc(db, "ailinksettings", linkSettingsId);
      const res = await updateDoc(docRef, {
        "data.automationlink": "",
      });

      return { data: "success" };
    } catch (err) {
      debugger
      console.error(err);
      return {
        error: err,
      };
    }
  };

  const findUpdateAiConversationsNotLive = async (locationId, automationlink) => {
    try {
      let conversationsDocs = [];
      const qa = query(
        collection(db, "aiconversations"),
        where("locationid", "==", locationId),
        where("automationlink", "==", automationlink),
        where("live", "==", false)
      );

      const snapshot = await getDocs(qa);
      if (snapshot.empty) {
        return { data: "no docs found" };
      }
      snapshot.forEach((doc) => {
        conversationsDocs.push(doc.data());
      });
      for (const cDoc of conversationsDocs) {
        const id = cDoc.aiconversationid;
        // wait X milliseconds
        await delay(100);
        const docRef = doc(db, "aiconversations", id);
        const res = await updateDoc(docRef, {
          automationlink: "",
          ailinksettingid: "",
        });
      }

      return { data: "success" };
    } catch (err) {
      console.error(err);
      return {
        error: err,
      };
    }
  };

  const findUpdateAiLinkSettingsDocIsUsed = async (locationId, automationlink, conversationId) => {
    try {
      let linkSettingsDocs = [];

      const qa = query(
        collection(db, "ailinksettings"),
        where("locationid", "==", locationId),
        where("data.automationlink", "==", automationlink),
        where("aiconversationid", "==", conversationId),
        where("inuse", "==", true)
      );

      const snapshot = await getDocs(qa);
      if (snapshot.empty) {
        return { data: 0 };
      }

      snapshot.forEach((doc) => {
        linkSettingsDocs.push(doc.data());
      });

      for (const lsDoc of linkSettingsDocs) {
        const id = lsDoc.ailinksettingid;
        // wait X milliseconds
        await delay(100);
        const docRef = doc(db, "ailinksettings", id);
        const res = await updateDoc(docRef, {
          "data.automationlink": "",
        });
      }

      return { data: 1 };
    } catch (err) {
      console.error(err);
      return {
        error: err,
      };
    }
  };

  //  const getAiLinkSettingsDocNoListen = async (automationlink) => {
  //    try {
  //      let aiLinkSettingsDoc = null;
  //
  //      const qa = query(collection(db, "ailinksettings"), where("data.automationlink", "==", automationlink));
  //
  //      const snapshot = await getDocs(qa);
  //      if (snapshot.empty) {
  //        const err = {
  //          val: "no ailinksettings doc found",
  //        };
  //        return {
  //          error: err,
  //        };
  //      }
  //
  //      snapshot.forEach((doc) => {
  //        // if (clog()) console.log(doc.id, " => ", doc.data());
  //        aiLinkSettingsDoc = doc.data();
  //      });
  //
  //      return { data: aiLinkSettingsDoc };
  //    } catch (err) {
  //      console.error(err);
  //      return {
  //        error: err,
  //      };
  //    }
  //  };

  //  const getAiConversationDocsNoListen = async (locationId, automationlink) => {
  //    try {
  //      let aiConversationDocs = [];
  //
  //      const qa = query(
  //        collection(db, "aiconversations"),
  //        where("locationid", "==", locationId),
  //        where("automationlink", "==", automationlink),
  //        where("live", "==", false)
  //      );
  //
  //      const snapshot = await getDocs(qa);
  //
  //      if (snapshot.empty) {
  //        const err = {
  //          val: "no aiconversations docs found",
  //        };
  //        return {
  //          error: err,
  //        };
  //      }
  //
  //      snapshot.forEach((doc) => {
  //        // if (clog()) console.log(doc.id, " => ", doc.data());
  //        aiConversationDocs.push(doc.data());
  //      });
  //
  //      return { data: aiConversationDocs };
  //    } catch (err) {
  //      console.error(err);
  //      return {
  //        error: err,
  //      };
  //    }
  //  };

  const getAiImprovePromptFromApi = async (payload) => {
    let returnData = {
      status: 0,
      // data: {},
      // error: {},
    };
    try {
      let URL = import.meta.env.VITE_REACT_APP_AI_API_URL;
      /*
      let URL = 'https://api.ecomacy.com'
      if (import.meta.env.VITE_REACT_APP_ENV === 'dev') {
        URL = 'http://localhost:8080'
      }
      */

      //STUAUTH
      if (URL) {
        const userToken = await getUserToken();
        const jwt = "Bearer " + userToken;
        // const jsonData = JSON.stringify(payload);
        // const apiUrlPath = URL.concat("/improve");
        const apiUrlPath = URL.concat("/v2/improve");

        // const apiUrlPath = URL.concat("/gettoken/", payload.locationid, "/", payload.link, "/", payload.url);
        // const apiUrlPath = URL.concat("/gettoken/", jsonData);

        if (clog()) console.log("apiUrlPath", apiUrlPath);
        const response = await axios.get(apiUrlPath, {
          // const response = await axios.post(apiUrlPath, payload, {
          headers: {
            "Content-Type": "application/json",
            Authorization: jwt,
          },
          params: payload,
        });

        // cLog(response.data)
        if (response.status === 200) {
          returnData.status = response.status;
          returnData.data = response.data;
        } else {
          returnData.status = response.status;
          returnData.error = response.error;
        }
        // if (clog()) console.log('getAiWebCodeTokenFromApi returnData:', returnData);
        return returnData;
      } else {
        returnData.status = 490;
        returnData.error = "REACT_APP_API_URL url missing from env";
        return returnData;
      }
    } catch (err) {
      console.error("response error", err);
      returnData.status = 491;
      returnData.error = err;
      return returnData;
    }
  };

  const generateFirestoreDate = () => {
    // generate firestore timestamp
    // firebase.firestore.FieldValue.serverTimestamp()
    // firebase.firestore.Timestamp.now()
    // const timestamp = 1676475521;
    // const timestampDate = new Date(timestamp*1000);
    // const timestampFirestore = admin.firestore.Timestamp.fromDate(timestampDate)
    // const timestampFirestore = admin.firestore.Timestamp.now()
    // const timestampFirestore = firebase.firestore.Timestamp.now()
    const timestampFirestore = serverTimestamp();

    return timestampFirestore;
  };
  const delay = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds));

  const updateAiAgentDoc = async (docId, updateData) => {
    try {
      const docRef = doc(db, "aiagents", docId);
      const result = await updateDoc(docRef, updateData);

      return { data: docId };
    } catch (err) {
      console.error(err);
      return {
        error: err,
      };
    }
  };

  const deleteAiAgentDoc = async (docId) => {
    try {
      // delete aiagent doc
      const docRes = await deleteDoc(doc(db, "aiagents", docId));

      return { data: docId };
    } catch (err) {
      console.error(err);
      return {
        error: err,
      };
    }
  };

  const archiveAiAgentDoc = async (docId, archive, data = {}) => {
    try {
      let date = generateFirestoreDate();

      if (data && data.updatedon) {
        date = data.updatedon;
      }

      let archiveVal = false;
      if (archive) {
        archiveVal = archive;
      }

      const updateData = {
        archive: archiveVal,
        updatedon: date,
      };

      const docRef = doc(db, "aiagents", docId);
      // update aiagent doc
      const result = await updateDoc(docRef, updateData);

      return { data: docId };
    } catch (err) {
      console.error(err);
      return {
        error: err,
      };
    }
  };

  const getAiConversationDocsByAiSettingId = async (id) => {
    try {
      let docs = [];

      const q = query(
        collection(db, "aiconversations"),
        where("aisettingid", "==", id)
        // where("live", "==", false)
      );

      const snapshot = await getDocs(q);
      if (snapshot.empty) {
        // return { data: "no docs found" };
      }

      snapshot.forEach((doc) => {
        const docData = doc.data();
        docs.push(docData);
      });

      return { data: docs };
    } catch (err) {
      console.error(err);
      return {
        error: err,
      };
    }
  };

  const deleteAiConversationDocs = async (fDocs) => {
    try {
      let deletedDocs = [];

      for (const fDoc of fDocs) {
        const docId = fDoc.aiconversationid;
        // wait X milliseconds
        await delay(100);
        const res = await deleteDoc(doc(db, "aiconversations", docId));
        deletedDocs.push(docId);
      }

      return { data: deletedDocs };
    } catch (err) {
      console.error(err);
      return {
        error: err,
      };
    }
  };

  const archiveAiConversationDocs = async (fDocs, archive, data = {}) => {
    try {
      let archivedDocs = [];

      let date = generateFirestoreDate();

      if (data && data.updatedon) {
        date = data.updatedon;
      }

      let archiveVal = false;
      if (archive) {
        archiveVal = archive;
      }

      const updateData = {
        archive: archiveVal,
        updatedon: date,
      };

      for (const fDoc of fDocs) {
        const docId = fDoc.aiconversationid;
        // wait X milliseconds
        await delay(100);
        const docRef = doc(db, "aiconversations", docId);
        const result = await updateDoc(docRef, updateData);

        archivedDocs.push(docId);
      }

      return { data: archivedDocs };
    } catch (err) {
      console.error(err);
      return {
        error: err,
      };
    }
  };

  const getAiPromptVersionsDocsByAiConversationId = async (id) => {
    try {
      let docs = [];

      // let locationid = "";
      // if (uData && uData.locationid) {
      //   locationid = uData.locationid;
      // }
      const q = query(
        collection(db, "aipromptversions"),
        // where("locationid", "==", locationid),
        where("aiconversationid", "==", id)
        // where("live", "==", false)
      );

      const snapshot = await getDocs(q);
      if (snapshot.empty) {
        // return { data: "no docs found" };
      }

      snapshot.forEach((doc) => {
        const docData = doc.data();
        docs.push(docData);
      });

      return { data: docs };
    } catch (err) {
      console.error(err);
      return {
        error: err,
      };
    }
  };

  const getAiPromptVersionsDocsByAiSettingsIndexId = async (id) => {
    try {
      let docs = [];

      // let locationid = "";
      // if (uData && uData.locationid) {
      //   locationid = uData.locationid;
      // }
      const q = query(
        collection(db, "aipromptversions"),
        // where("locationid", "==", locationid),
        where("aisettingsindexid", "==", id)
        // where("live", "==", false)
      );

      const snapshot = await getDocs(q);
      if (snapshot.empty) {
        // return { data: "no docs found" };
      }

      snapshot.forEach((doc) => {
        const docData = doc.data();
        docs.push(docData);
      });

      return { data: docs };
    } catch (err) {
      console.error(err);
      return {
        error: err,
      };
    }
  };
  const deleteAiPromptVersionsDocs = async (fDocs) => {
    try {
      let deletedDocs = [];

      for (const fDoc of fDocs) {
        const docId = fDoc.aipromptversionid;
        // wait X milliseconds
        await delay(100);
        const res = await deleteDoc(doc(db, "aipromptversions", docId));
        deletedDocs.push(docId);
      }

      return { data: deletedDocs };
    } catch (err) {
      console.error(err);
      return {
        error: err,
      };
    }
  };

  const archiveAiPromptVersionsDocs = async (fDocs, archive, data = {}) => {
    try {
      let archivedDocs = [];

      let date = generateFirestoreDate();

      if (data && data.updatedon) {
        date = data.updatedon;
      }

      let archiveVal = false;
      if (archive) {
        archiveVal = archive;
      }

      const updateData = {
        archive: archiveVal,
        updatedon: date,
      };

      for (const fDoc of fDocs) {
        const docId = fDoc.aipromptversionid;
        // wait X milliseconds
        await delay(100);
        const docRef = doc(db, "aipromptversions", docId);
        const result = await updateDoc(docRef, updateData);

        archivedDocs.push(docId);
      }

      return { data: archivedDocs };
    } catch (err) {
      console.error(err);
      return {
        error: err,
      };
    }
  };





  const getAiMessageMessagesDocsByAiConversationId = async (id) => {
    try {
      let docs = [];

      // let locationid = "";
      // if (uData && uData.locationid) {
      //   locationid = uData.locationid;
      // }

      // const subColRef = collection(db, "collection_name", "doc_name", "subcollection_name");
      const q = query(
        collection(db, "aimessages", id, "messages"),
        // where("locationid", "==", locationid),
        // where("aiconversationid", "==", id)
        // where("live", "==", false)
      );

      const snapshot = await getDocs(q);
      if (snapshot.empty) {
        // return { data: "no docs found" };
      }

      snapshot.forEach((doc) => {
        const messageid = doc.id;
        let docData = doc.data();
        // add messageid to docData
        if (!docData.messageid) {
          docData.messageid = messageid;
        }
        // add aiconversationid to docData
        if (!docData.aiconversationid) {
          docData.aiconversationid = id;
        }
        docs.push(docData);
      });

      return { data: docs };
    } catch (err) {
      console.error(err);
      return {
        error: err,
      };
    }
  };

  const deleteAiMessageMessagesDocs = async (fDocs) => {
    try {
      let deletedDocs = [];

      for (const fDoc of fDocs) {
        const parentDocId = fDoc.aiconversationid;
        const docId = fDoc.messageid;
        // wait X milliseconds
        await delay(10);
        const res = await deleteDoc(doc(db, "aimessages", parentDocId, "messages", docId));
        deletedDocs.push(docId);
      }

      return { data: deletedDocs };
    } catch (err) {
      console.error(err);
      return {
        error: err,
      };
    }
  };

  const archiveAiMessagesMessageDocs = async (fDocs, archive, data = {}) => {
    try {
      let archivedDocs = [];

      let date = generateFirestoreDate();

      if (data && data.updatedon) {
        date = data.updatedon;
      }

      let archiveVal = false;
      if (archive) {
        archiveVal = archive;
      }

      const updateData = {
        archive: archiveVal,
        updatedon: date,
      };

      for (const fDoc of fDocs) {
        const docId = fDoc.aipromptversionid;
        // wait X milliseconds
        await delay(100);
        const docRef = doc(db, "aimessages", docId);
        const result = await updateDoc(docRef, updateData);

        archivedDocs.push(docId);
      }

      return { data: archivedDocs };
    } catch (err) {
      console.error(err);
      return {
        error: err,
      };
    }
  };



  const getAiMessagesDocsByAiConversationId = async (id) => {
    try {
      let docs = [];

      // let locationid = "";
      // if (uData && uData.locationid) {
      //   locationid = uData.locationid;
      // }
      const q = query(
        collection(db, "aimessages"),
        // where("locationid", "==", locationid),
        where("id", "==", id)
        // where("live", "==", false)
      );

      const snapshot = await getDocs(q);
      if (snapshot.empty) {
        // return { data: "no docs found" };
      }

      snapshot.forEach((doc) => {
        const docData = doc.data();
        docs.push(docData);
      });

      return { data: docs };
    } catch (err) {
      console.error(err);
      return {
        error: err,
      };
    }
  };

  const deleteAiMessagesDocs = async (fDocs) => {
    try {
      let deletedDocs = [];

      for (const fDoc of fDocs) {
        const docId = fDoc.id;
        // wait X milliseconds
        await delay(100);
        const res = await deleteDoc(doc(db, "aimessages", docId));
        deletedDocs.push(docId);
      }

      return { data: deletedDocs };
    } catch (err) {
      console.error(err);
      return {
        error: err,
      };
    }
  };

  const archiveAiMessagesDocs = async (fDocs, archive, data = {}) => {
    try {
      let archivedDocs = [];

      let date = generateFirestoreDate();

      if (data && data.updatedon) {
        date = data.updatedon;
      }

      let archiveVal = false;
      if (archive) {
        archiveVal = archive;
      }

      const updateData = {
        archive: archiveVal,
        updatedon: date,
      };

      for (const fDoc of fDocs) {
        const docId = fDoc.aipromptversionid;
        // wait X milliseconds
        await delay(100);
        const docRef = doc(db, "aimessages", docId);
        const result = await updateDoc(docRef, updateData);

        archivedDocs.push(docId);
      }

      return { data: archivedDocs };
    } catch (err) {
      console.error(err);
      return {
        error: err,
      };
    }
  };



  const getAiEngineerConversationsDocsByAiConversationId = async (id) => {
    try {
      let docs = [];

      // let locationid = "";
      // if (uData && uData.locationid) {
      //   locationid = uData.locationid;
      // }
      const q = query(
        collection(db, "aiengineerconversations"),
        // where("locationid", "==", locationid),
        where("aiconversationid", "==", id)
      );

      const snapshot = await getDocs(q);
      if (snapshot.empty) {
        // return { data: "no docs found" };
      }

      snapshot.forEach((doc) => {
        const docData = doc.data();
        docs.push(docData);
      });

      return { data: docs };
    } catch (err) {
      console.error(err);
      return {
        error: err,
      };
    }
  };

  const deleteAiEngineerConversationsDocs = async (fDocs) => {
    try {
      let deletedDocs = [];

      for (const fDoc of fDocs) {
        const docId = fDoc.aiengineerconversationid;
        // wait X milliseconds
        await delay(100);
        const res = await deleteDoc(doc(db, "aiengineerconversations", docId));
        deletedDocs.push(docId);
      }

      return { data: deletedDocs };
    } catch (err) {
      console.error(err);
      return {
        error: err,
      };
    }
  };

  const archiveAiEngineerConversationsDocs = async (fDocs, archive, data = {}) => {
    try {
      let archivedDocs = [];

      let date = generateFirestoreDate();

      if (data && data.updatedon) {
        date = data.updatedon;
      }

      let archiveVal = false;
      if (archive) {
        archiveVal = archive;
      }

      const updateData = {
        archive: archiveVal,
        updatedon: date,
      };

      for (const fDoc of fDocs) {
        const docId = fDoc.aiengineerconversationid;
        // wait X milliseconds
        await delay(100);
        const docRef = doc(db, "aiengineerconversations", docId);
        const result = await updateDoc(docRef, updateData);

        archivedDocs.push(docId);
      }

      return { data: archivedDocs };
    } catch (err) {
      console.error(err);
      return {
        error: err,
      };
    }
  };

  const getAiLinkSettingsDocsByAiConversationId = async (id, checkInUse) => {
    try {
      let docs = [];

      const q = query(
        collection(db, "ailinksettings"),
        where("aiconversationid", "==", id)
        // where("live", "==", false)
      );

      const snapshot = await getDocs(q);
      if (snapshot.empty) {
        // return { data: "no docs found" };
      }

      snapshot.forEach((doc) => {
        const docData = doc.data();
        if (checkInUse === true && docData.inuse === false) {
          docs.push(docData);
        }
        else if (checkInUse === false) {
          docs.push(docData);
        }
      });

      return { data: docs };
    } catch (err) {
      console.error(err);
      return {
        error: err,
      };
    }
  };

  const deleteAiLinkSettingsDocs = async (fDocs) => {
    try {
      let deletedDocs = [];

      for (const fDoc of fDocs) {
        const docId = fDoc.ailinksettingid;
        // wait X milliseconds
        await delay(100);
        const res = await deleteDoc(doc(db, "ailinksettings", docId));
        deletedDocs.push(docId);
      }

      return { data: deletedDocs };
    } catch (err) {
      console.error(err);
      return {
        error: err,
      };
    }
  };

  const archiveAiLinkSettingsDocs = async (fDocs, archive, data = {}) => {
    try {
      let archivedDocs = [];

      let date = generateFirestoreDate();

      if (data && data.updatedon) {
        date = data.updatedon;
      }

      let archiveVal = false;
      if (archive) {
        archiveVal = archive;
      }

      const updateData = {
        archive: archiveVal,
        updatedon: date,
      };

      for (const fDoc of fDocs) {
        const docId = fDoc.ailinksettingid;
        // wait X milliseconds
        await delay(100);
        const docRef = doc(db, "ailinksettings", docId);
        const result = await updateDoc(docRef, updateData);

        archivedDocs.push(docId);
      }

      return { data: archivedDocs };
    } catch (err) {
      console.error(err);
      return {
        error: err,
      };
    }
  };

  const processSettingsDataForPromptVersion = (settingsData) => {
    const cleanedData = _.cloneDeep(settingsData);
    // const cleanedData = { ...settingsData };

    // delete automationlink
    delete cleanedData.data.automationlink;

    // delete automationlink
    delete cleanedData.aisettingsindexid;

    // delete name
    delete cleanedData.name;
    debugger
    const promptVersion = settingsData.promptversion;
    const promptVersionCurrent = settingsData.promptversioncurrent;
    // update promptversion
    cleanedData.promptversion = promptVersionCurrent;

    // delete promptversioncurrent
    delete cleanedData.promptversioncurrent;
    //
    return cleanedData;
    //
  };

  const processMultiPromptSettingsDataForPromptVersion = (settingsData, index) => {
    const cleanedData = _.cloneDeep(settingsData);

    // clean automationlink
    // cleanedData.data.automationlink = "";

    return cleanedData;
  };

  const processAiConversationSmallData = (conversationData) => {
    let smallData = {};

    // let messages = [];
    // if (conversationData.messages && conversationData.messages.length > 0) {
    //   messages = conversationData.messages;
    // }

    let name = "";
    if (conversationData.name && conversationData.name !== "") {
      name = conversationData.name;
    }

    // let automationlink = "";
    // if (conversationData.automationlink && conversationData.automationlink !== "") {
    //   automationlink = conversationData.automationlink;
    // }

    let ailinksettingid = "";
    if (conversationData.ailinksettingid && conversationData.ailinksettingid !== "") {
      ailinksettingid = conversationData.ailinksettingid;
    }

    let improveprocessing = false;
    if (conversationData.improveprocessing !== undefined) {
      improveprocessing = conversationData.improveprocessing;
    }

    let archive = false;
    if (conversationData.archive !== undefined) {
      archive = conversationData.archive;
    }

    // get version from settings
    // let version = 0;
    // if (conversationData.aisettings && conversationData.aisettings !== "") {
    //   const aisettings = JSON.parse(conversationData.aisettings);
    //   if (aisettings && aisettings.version) {
    //     version = aisettings.version;
    //   }
    // }
    // let promptversion = 0;
    // if (conversationData.promptversion !== undefined) {
    //   promptversion = conversationData.promptversion;
    // }

    let localstorage = {};
    if (conversationData.localstorage !== undefined) {
      localstorage = conversationData.localstorage;
    }

    smallData = {
      aiconversationid: conversationData.aiconversationid,
      createdon: conversationData.createdon,
      // messages: messages,
      name: name,
      ailinksettingid: ailinksettingid,
      // automationlink: automationlink,
      improveprocessing: improveprocessing,
      archive: archive,
      // promptversion: promptversion,
      localstorage: localstorage,
    };

    return smallData;
  };

  const processAiLiveLinkSettingsSmallData = (linksettingsData) => {
    const name = linksettingsData?.name ?? "";
    const aiagentid = linksettingsData?.aiagentid ?? "";
    const aiconversationid = linksettingsData?.aiconversationid ?? "";
    const ailinksettingid = linksettingsData?.ailinksettingid ?? "";

    const smallData = {
      name,
      aiagentid,
      aiconversationid,
      ailinksettingid
    };

    return smallData;
  };

  const checkForMissingAiAgentVariablesInSettings = (settingsUpdatedDataArray, agentsSelected) => {
    let missingVariables = [];

    let agentVariables = [];
    if (agentsSelected && agentsSelected.inputs && agentsSelected.inputs.length > 0) {
      agentVariables = agentsSelected.inputs;
    }

    let settingsVariablesFound = [];
    for (const settingsData of settingsUpdatedDataArray) {
      // get selected strategy from settingsData.data
      const settingsDataData = settingsData.data;
      if (settingsDataData.strategy) {
        const selectedStrategy = settingsDataData.strategy;

        if (selectedStrategy && selectedStrategy !== "") {
          let strategyData = null
          if (settingsData[selectedStrategy]) {
            strategyData = settingsData[selectedStrategy];

            // loop over strategyData object
            for (const [key, value] of Object.entries(strategyData)) {
              const prompt = strategyData[key]
              // Regular expression to match content within curly brackets
              const regex = /\{([^}]+)\}/g;
              // const regex = /\(SAVEIN=([^)]+)\)/g;

              let variables = [];
              let match;

              // Loop over all matches and push variable names into the variables array
              while ((match = regex.exec(prompt)) !== null) {
                variables.push(match[1]);
              }
              // add variables to settingsVariablesFound
              settingsVariablesFound = [...settingsVariablesFound, ...variables];
            }
          }
        }
      }

    }
    // remove duplicates from settingsVariablesFound
    const settingsVariablesFoundCleaned = _.uniq(settingsVariablesFound);
    // loop over settingsVariablesFoundCleaned
    for (const settingVariable of settingsVariablesFoundCleaned) {
      // check if settingVariable is in agentVariables object shortname
      let found = false;
      for (const agentVariable of agentVariables) {
        if (agentVariable.shortname === settingVariable) {
          found = true;
        }
      }
      // if not found then add to missingVariables
      if (!found) {
        missingVariables.push(settingVariable);
      }

    }
    // check for missing variables in agentVariables object
    // for (const agentVariable of agentVariables) {
    //   debugger
    //   // check if agentVariable.shortname is in settingsVariablesFoundCleaned
    //   if (!settingsVariablesFoundCleaned.includes(agentVariable.shortname)) {
    //     missingVariables.push(agentVariable.shortname);
    //   }
    // }

    // const test0 = missingVariables;
    // const test1 = agentVariables
    // const test2 = settingsUpdatedDataArray;
    // const test3 = agentsSelected;

    return missingVariables;
  }

  const getDiff = (oldData, newData) => {
    //diff, addedDiff, deletedDiff, updatedDiff, detailedDiff
    const difference = diff(oldData, newData);
    return difference;
  };

  return (
    <AiAuthContext.Provider
      value={{

        urlDataParams,

        testVal,
        setTestVal,
        showAiCustomerSettingsDialog,
        setShowAiCustomerSettingsDialog,
        aiCustomerApiKeyStatus,
        setAiCustomerApiKeyStatus,

        aiQuestionsEngineerPanelRef,

        aiAgentsDocs,
        setAiAgentsDocs,
        aiAgentsSelected,
        setAiAgentsSelected,
        aiAgentsSelectedRef,

        aiAgentsIsEditing,
        setAiAgentsIsEditing,
        aiAgentsIsEditingType,
        setAiAgentsIsEditingType,
        aiAgentsIsRemoving,
        setAiAgentsIsRemoving,

        showAiPresetAgentsDialog,
        setShowAiPresetAgentsDialog,
        aiAgentsIsSavingPresetAgent,
        setAiAgentsIsSavingPresetAgent,

        aiAgentsAutomationLinkItems,

        aiCategoriesDoc,
        setAiCategoriesDoc,

        aiSettingsDocs,
        setAiSettingsDocs,
        aiSettingsLiveData,
        setAiSettingsLiveData,
        aiSettingsSelected,
        setAiSettingsSelected,
        aiSettingsSelectedRefresh,
        aiSettingsData,
        setAiSettingsData,
        aiSettingsDataRef,
        aiSettingsDataConversationIdRef,
        aiSettingsDataUpdated,
        setAiSettingsDataUpdated,
        aiSettingsDataDiff,
        setAiSettingsDataDiff,
        aiSettingsSaving,
        setAiSettingsSaving,
        aiSettingsIndexIdSelected,
        setAiSettingsIndexIdSelected,
        aiSettingsIndexIdSelectedRefresh,
        setAiSettingsIndexIdSelectedRefresh,
        aiSettingsItemsSwitchPromptsSelected,
        setAiSettingsItemsSwitchPromptsSelected,
        // updateAiMessagesSwitchPrompt: async (conversationid, switchPromptStr) => {
        //   let returnData = {}
        //   try {
        //     let res = {}

        //     let aiConversationId = ""
        //     if (conversationid) {
        //       aiConversationId = conversationid
        //     }

        //     let switchPrompt = ""
        //     if (switchPromptStr) {
        //       switchPrompt = switchPromptStr
        //     }

        //     if (aiConversationId !== '' && switchPrompt !== '') {

        //       // update aimessages
        //       const docRef = doc(db, "aimessages", linkSettingsId);
        //       const res = await updateDoc(docRef, {
        //         "data.automationlink": "",
        //       });

        //       // return { data: "success" };

        //       returnData.data = {
        //         aiconversationid: aiConversationId,
        //         switchprompt: switchPrompt,
        //       }
        //     }
        //     else {
        //       returnData.error = {
        //         val: "missing data",
        //         aiconversationid: aiConversationId,
        //         switchPrompt: aiLinkSettingId,
        //       }
        //     }

        //     return returnData

        //   } catch (err) {
        //     console.error(err);
        //     return
        //   }
        // },

        removeAiSettingsIndexItem: async (uData, conversationsSelected, settingsIndexIdSelected) => {
          let returnData = {}
          try {
            let res = {}
            const test = conversationsSelected
            const test2 = settingsIndexIdSelected

            let aiConversationId = ""
            if (conversationsSelected && conversationsSelected.aiconversationid) {
              aiConversationId = conversationsSelected.aiconversationid
            }

            let aiLinkSettingId = ""
            if (conversationsSelected && conversationsSelected.ailinksettingid) {
              aiLinkSettingId = conversationsSelected.ailinksettingid
            }

            let removeIndex = -1
            if (settingsIndexIdSelected && settingsIndexIdSelected.index !== undefined && settingsIndexIdSelected.index > -1) {
              removeIndex = settingsIndexIdSelected.index
            }

            let aiSettingsIndexId = ""
            if (settingsIndexIdSelected && settingsIndexIdSelected.aisettingsindexid) {
              aiSettingsIndexId = settingsIndexIdSelected.aisettingsindexid
            }

            if (aiConversationId !== '' && aiLinkSettingId !== '' && removeIndex > -1) {


              // Get a new write batch
              const batch = writeBatch(db);
              const date = generateFirestoreDate();

              // clone conversationsSelected aisettings
              const settingsUpdatedDataArray = _.cloneDeep(conversationsSelected.aisettings)

              // remove aisettings index item from aiconversations
              settingsUpdatedDataArray.splice(removeIndex, 1)

              // update aiconversations
              const cRef = doc(db, "aiconversations", aiConversationId);
              batch.update(cRef, {
                aisettings: settingsUpdatedDataArray,
                updatedon: date,
              });

              // delete aipromptversions
              let aiPromptVersionIds = []
              const promptversionsResult = await getAiPromptVersionsDocsByAiSettingsIndexId(aiSettingsIndexId)
              if (promptversionsResult && promptversionsResult.data) {
                const aipromptversionsDocs = promptversionsResult.data;
                // loop through aipromptversionsDocs
                for (const aipromptversionDoc of aipromptversionsDocs) {
                  const aiPromptVersionId = aipromptversionDoc.aipromptversionid;
                  const pRef = doc(db, "aipromptversions", aiPromptVersionId);
                  batch.delete(pRef)
                  aiPromptVersionIds.push(aiPromptVersionId)
                }

              }
              // remove aisettings index item from ailinksettings
              const processedLinkSettingsDocData = processSettingsDataArrayForLinkSettings(settingsUpdatedDataArray);
              const processedLinkSettingsSettingsData = processedLinkSettingsDocData.aisettings

              // update ailinksettings
              const lRef = doc(db, "ailinksettings", aiLinkSettingId);
              batch.update(lRef, {
                aisettings: processedLinkSettingsSettingsData,
                updatedon: date,
              });


              // commit the batch
              res = await batch.commit();

              returnData.data = {
                aiconversationid: aiConversationId,
                aipromptversionids: aiPromptVersionIds,
                ailinksettingid: aiLinkSettingId,
                aisettings: settingsUpdatedDataArray,
              }
            }
            else {
              returnData.error = {
                val: "missing data",
                aiconversationid: aiConversationId,
                ailinksettingid: aiLinkSettingId,
              }
            }

            return returnData

          } catch (err) {
            console.error(err);
            return
          }
        },
        saveAiSettingsIndexItemName: async (conversationSelected, settingsIndexIdSelected, name) => {
          let returnData = {};
          try {
            let res = {};

            const test = settingsIndexIdSelected;
            const test2 = name;

            let aiConversationId = "";
            if (conversationSelected && conversationSelected.aiconversationid) {
              aiConversationId = conversationSelected.aiconversationid;
            }

            let aiSettingsIndexId = "";
            if (settingsIndexIdSelected && settingsIndexIdSelected.aisettingsindexid) {
              aiSettingsIndexId = settingsIndexIdSelected.aisettingsindexid;
            }

            let index = -1;
            if (settingsIndexIdSelected && settingsIndexIdSelected.index !== undefined && settingsIndexIdSelected.index > -1) {
              index = settingsIndexIdSelected.index;
            }

            if (aiConversationId !== "" && aiSettingsIndexId !== "" && index > -1) {
              // clone conversationsSelected aisettings
              const settingsUpdatedDataArray = _.cloneDeep(conversationSelected.aisettings);

              // update aisettings index item name
              settingsUpdatedDataArray[index].name = name;

              // update aiconversations
              const date = generateFirestoreDate();
              const cRef = doc(db, "aiconversations", aiConversationId);
              const cRes = await updateDoc(cRef, {
                aisettings: settingsUpdatedDataArray,
                updatedon: date,
              });

              returnData.data = {
                aiconversationid: aiConversationId,
                aisettings: settingsUpdatedDataArray,
              };
            }

            return returnData;
          } catch (err) {
            console.error(err);
            returnData.error = err;
            return returnData;
          }
        },
        aiSettingsIsEditing,
        setAiSettingsIsEditing,
        aiSettingsIsEditingType,
        setAiSettingsIsEditingType,

        // aiLinkSettingsData,
        // setAiLinkSettingsData,
        // aiLinkSettingsDataRefresh,
        // setAiLinkSettingsDataRefresh,

        aiStrategySelected,
        setAiStrategySelected,
        aiStrategySelectedRefresh,
        setAiStrategySelectedRefresh,
        aiModelSelected,
        setAiModelSelected,
        aiModelSelectedRefresh,
        setAiModelSelectedRefresh,
        aiModelReset,
        setAiModelReset,
        // aiChatMessagesData,
        //setAiChatMessagesData,

        aiConversationsDocs,
        setAiConversationsDocs,
        aiConversationsDocsRefresh,
        setAiConversationsDocsRefresh,
        aiConversationsIsEditing,
        setAiConversationsIsEditing,
        aiConversationsIsSaving,
        setAiConversationsIsSaving,
        aiConversationsIsRemoving,
        setAiConversationsIsRemoving,
        aiConversationsSelected,
        setAiConversationsSelected,
        aiConversationFetchData,
        setAiConversationFetchData,
        aiConversation,
        setAiConversation,
        refreshAiConversationSettingsData,
        setRefreshAiConversationSettingsData,
        aiMessagesDoc,
        setAiMessagesDoc,
        aiMessages,
        setAiMessages,
        aiMessagesIndexId,
        setAiMessagesIndexId,
        aiConversationMessageCount,
        aiChatInput,
        setAiChatInput,
        aiConversationDuplicatedRefresh,
        setAiConversationDuplicatedRefresh,

        aiPromptVersionsDocs,
        setAiPromptVersionsDocs,
        aiPromptVersionSelected,
        setAiPromptVersionSelected,
        aiPromptVersionReset,
        setAiPromptVersionReset,
        aiEngineersPanelShow,
        setAiEngineersPanelShow,
        aiEngineersPanelHeightResizing,
        setAiEngineersPanelHeightResizing,
        aiEngineersDocs,
        setAiEngineersDocs,
        aiEngineersSelected,
        setAiEngineersSelected,
        aiEngineerConversation,
        setAiEngineerConversation,
        aiEngineerConversationUpdated,
        setAiEngineerConversationUpdated,
        aiEngineerChatInput,
        setAiEngineerChatInput,
        aiShowDescriptionPanel,
        setAiShowDescriptionPanel,
        showSavePresetAlert,
        setShowSavePresetAlert,
        showSavePresetAgentAlert,
        setShowSavePresetAgentAlert,
        showAiAgentVariablesDialog,
        setShowAiAgentVariablesDialog,
        // aiAgentInputs,
        // setAiAgentInputs,
        showAiWebCodeDialog,
        setShowAiWebCodeDialog,
        aiWebCodeDomain,
        setAiWebCodeDomain,
        aiWebCodeCopyHeader,
        setAiWebCodeCopyHeader,
        aiWebCodeCopyPage,
        setAiWebCodeCopyPage,
        aiAgentShowLiveConversations,
        setAiAgentShowLiveConversations,
        aiLiveLinkSettingsDocs,
        setAiLiveLinkSettingsDocs,
        getAiLiveLinkSettingsDocs: async (uData, agentShowLiveConversations) => {
          return new Promise((resolve, reject) => {
            try {

              let locationid = uData?.locationId ?? "";
              if (!locationid) {
                reject({
                  error: "no locationid",
                });
              }

              let aiagentid = agentShowLiveConversations?.aiagentid ?? "";
              if (!aiagentid) {
                reject({
                  error: "no aiagentid",
                });
              }


              if (locationid !== "" && aiagentid !== "") {

                const q = query(
                  collection(db, "ailinksettings"),
                  where("locationid", "==", locationid),
                  where("aiagentid", "==", aiagentid),
                  // where("inuse", "==", true),
                  orderBy("createdon", "desc")
                );

                const unsub = onSnapshot(q, (querySnapshot) => {
                  const ailivelinksettingsDocsArr = [];

                  if (querySnapshot.empty) {
                    // no docs found
                  }

                  querySnapshot.forEach((doc) => {
                    const docData = doc.data();
                    const smallData = processAiLiveLinkSettingsSmallData(docData);
                    // const smallData = docData;

                    ailivelinksettingsDocsArr.push(smallData);
                  });

                  const data = {
                    aiagentid: aiagentid,
                    // ailinksettingid: ailinksettingid,
                    data: ailivelinksettingsDocsArr,
                  }

                  if (clog()) console.log("getAiLiveLinkSettingsDocs", data);
                  setAiLiveLinkSettingsDocs(data);

                  resolve({
                    data: data,
                  });
                });
              }
            } catch (err) {
              console.error(err);
              reject({
                error: err,
              });
            }
          });
        },
        aiLiveLinkSettingsSelected,
        setAiLiveLinkSettingsSelected,
        aiLiveConversationsDocs,
        setAiLiveConversationsDocs,
        getAiLiveConversationsDocs: async (uData, liveLinkSettingsSelected) => {
          return new Promise((resolve, reject) => {
            try {
              let locationid = uData?.locationId ?? "";
              if (!locationid) {
                reject({
                  error: "no locationid",
                });
              }

              let aiagentid = liveLinkSettingsSelected?.aiagentid ?? "";
              if (!aiagentid) {
                reject({
                  error: "no aiagentid",
                });
              }

              let ailinksettingid = liveLinkSettingsSelected?.ailinksettingid ?? "";
              if (!ailinksettingid) {
                reject({
                  error: "no ailinksettingid",
                });
              }

              if (locationid !== "" && aiagentid !== "" && ailinksettingid !== "") {
                let q = {}

                if (ailinksettingid === "all") {
                  // get a
                  q = query(
                    collection(db, "ailiveconversations"),
                    where("locationid", "==", locationid),
                    where("aiagentid", "==", aiagentid),
                    // where("inuse", "==", true),
                    orderBy("createdon", "desc")
                  );
                }
                else {
                  q = query(
                    collection(db, "ailiveconversations"),
                    where("locationid", "==", locationid),
                    where("aiagentid", "==", aiagentid),
                    where("ailinksettingid", "==", ailinksettingid),
                    // where("inuse", "==", true),
                    orderBy("createdon", "desc")
                  );
                }

                const unsub = onSnapshot(q, (querySnapshot) => {
                  const ailiveconversationsDocsArr = [];

                  if (querySnapshot.empty) {
                    // no docs found
                  }

                  querySnapshot.forEach((doc) => {
                    const docData = doc.data();

                    // const smallData = processAiConversationSmallData(docData);
                    const smallData = docData;

                    ailiveconversationsDocsArr.push(smallData);
                  });

                  const data = {
                    aiagentid: aiagentid,
                    ailinksettingid: ailinksettingid,
                    data: ailiveconversationsDocsArr,
                  }

                  if (clog()) console.log("getAiLiveConversationsDocs", data);
                  setAiLiveConversationsDocs(data);

                  resolve({
                    data: data,
                  });
                });
              }
            } catch (err) {
              console.error(err);
              reject({
                error: err,
              });
            }
          });
        },
        getAiLiveConversationsDocsOld: async (uData, agentShowLiveConversations) => {
          return new Promise((resolve, reject) => {
            try {
              let locationid = uData?.locationId ?? "";
              if (!locationid) {
                reject({
                  error: "no locationid",
                });
              }

              let aiagentid = agentShowLiveConversations?.aiagentid ?? "";
              if (!aiagentid) {
                reject({
                  error: "no aiagentid",
                });
              }


              if (locationid !== "" && aiagentid !== "") {

                const q = query(
                  collection(db, "ailiveconversations"),
                  where("locationid", "==", locationid),
                  where("aiagentid", "==", aiagentid),
                  // where("inuse", "==", true),
                  orderBy("createdon", "desc")
                );


                const unsub = onSnapshot(q, (querySnapshot) => {
                  const ailiveconversationsDocsArr = [];

                  if (querySnapshot.empty) {
                    // no docs found
                  }

                  querySnapshot.forEach((doc) => {
                    const docData = doc.data();

                    // const smallData = processAiConversationSmallData(docData);
                    const smallData = docData;

                    ailiveconversationsDocsArr.push(smallData);
                  });

                  const data = {
                    aiagentid: aiagentid,
                    ailinksettingid: "all",
                    data: ailiveconversationsDocsArr,
                  }

                  if (clog()) console.log("getAiLiveConversationsDocs", data);
                  setAiLiveConversationsDocs(data);

                  resolve({
                    data: data,
                  });
                });
              }
            } catch (err) {
              console.error(err);
              reject({
                error: err,
              });
            }
          });
        },
        aiLiveConversationsSelected,
        setAiLiveConversationsSelected,
        aiLiveConversationFetchData,
        setAiLiveConversationFetchData,
        getAiLiveConversationAndMessages: async (userData, conversationsSelected) => {
          try {
            let returnData = {
              ailiveconversation: {},
              aimessages: {},
            };

            let ailiveconversationid = "";
            if (conversationsSelected && conversationsSelected.ailiveconversationid && conversationsSelected.ailiveconversationid !== "") {
              ailiveconversationid = conversationsSelected.ailiveconversationid;
            }
            if (ailiveconversationid !== "") {

              // get conversation
              const resultConversation = await getAiLiveConversation(userData, ailiveconversationid);

              if (resultConversation && resultConversation.data) {
                returnData.ailiveconversation.data = resultConversation.data;

                // get messages
                const resultMessages = await getAiLiveMessages(userData, ailiveconversationid);
                if (resultMessages && resultMessages.data) {
                  returnData.aimessages.data = resultMessages.data;
                }
                else {
                  returnData.aimessages.error = resultMessages.error;
                }
              }
            }
            else {
              returnData.aiconversation.error = {
                val: 'No aiconversationid'
              }
            }

            return returnData;

          } catch (err) {
            console.error(err);
            return {
              aiconversation: {
                error: err,
              }
            };
          }
        },
        aiLiveConversation,
        setAiLiveConversation,
        refreshAiLiveConversationSettingsData,
        setRefreshAiLiveConversationSettingsData,
        aiLiveMessages,
        setAiLiveMessages,
        aiLiveConversationsDocsRefresh,
        setAiLiveConversationsDocsRefresh,
        aiLiveSettingsData,
        setAiLiveSettingsData,
        aiLiveSettingsDataUpdated,
        setAiLiveSettingsDataUpdated,

        getAiLiveConversationLinkSettingsData: async (uData, liveConversationsSelected) => {
          let returnData = {};
          try {
            //
            let locationid = "";
            if (uData && uData.locationId) {
              locationid = uData.locationId;
            }

            let ailinksettingid = "";
            if (liveConversationsSelected && liveConversationsSelected.ailinksettingid && liveConversationsSelected.ailinksettingid !== "") {
              ailinksettingid = liveConversationsSelected.ailinksettingid;
            }

            if (locationid === "") {
              // no locationid
              returnData.error = {
                val: "no locationid",
              };
            }

            if (ailinksettingid === "") {
              // no ailinksettingid
              returnData.error = {
                val: "no ailinksettingid",
              };
            }


            // START: duplicate aiengineerconversations
            let aiLinkSettingsDocs = [];

            // get ailinksettings docs connected to ailinksettingid
            const qec = query(
              collection(db, "ailinksettings"),
              where("locationid", "==", locationid),
              where("ailinksettingid", "==", ailinksettingid),
              orderBy("createdon", "desc")
            );

            const snapshotLinkSettings = await getDocs(qec);

            // if (snapshotEngineerConversations.empty) {
            // }
            snapshotLinkSettings.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              // if (clog()) console.log(doc.id, " => ", doc.data());
              aiLinkSettingsDocs.push(doc.data());
            });

            returnData.data = aiLinkSettingsDocs[0];

            // set aiLiveSettingsData
            setAiLiveSettingsData(aiLinkSettingsDocs[0]);

            return returnData;
          } catch (err) {
            console.error(err);
            return {
              error: err,
            };
          }
        },

        getDiffs: (type, oldData, newData) => {
          //diff, addedDiff, deletedDiff, updatedDiff, detailedDiff
          let difference = getDiff(oldData, newData);

          if (type === "aisettings") {
            // setAiSettingsDataDiff(difference);
          }

          return difference;
        },
        checkCustomerApiKeys: (type, cUserData, settingsDataUpdated) => {
          let returnData = {
            show: false,
            type: [],
            message: "",
          };
          // const test = cUserData;
          // const test1 = settingsDataUpdated;

          if (type === "chat") {
            let modelType = settingsDataUpdated?.data?.modeltype ?? "";
            // model type exists
            if (modelType === "openai") {
              // gpt3
              if (cUserData?.data?.data?.openai_key === undefined || cUserData?.data?.data?.openai_key === "") {
                // openai key does not exist
                returnData.show = true;
                returnData.type.push("openai");
              }
            }
            else if (modelType === "anthropic") {
              // anthropic
              if (cUserData?.data?.data?.anthropic_key === undefined || cUserData?.data?.data?.anthropic_key === "") {
                // anthropic key does not exist
                returnData.show = true;
                returnData.type.push("anthropic");
              }
            }
          }
          else if (type === "engineer") {
            // openai key is needed for engineer
            if (cUserData?.data?.data?.openai_key === undefined || cUserData?.data?.data?.openai_key === "") {
              // openai key does not exist
              returnData.show = true;
              returnData.type.push("openai");
            }
          }

          return returnData
        },
        saveCustomerApiKeys: async (customerData, keyData) => {
          let returnData = {};

          try {
            // wait X milliseconds
            // await delay(1000)

            let res = {};
            // update user doc
            // const usersRef = collection(db, 'users') // collectionRef
            // const docRef = doc(db, "countries", "my.custom.id@gmail.com" );

            let docId = "";
            // if customerData and customerData.userDoc exists
            if (customerData && customerData.data) {
              const customerDoc = customerData.data;
              // if userDoc id exists
              if (customerDoc.id) {
                docId = customerDoc.id;
              }


              if (docId !== "") {
                const customerRef = doc(db, "customers", docId); // docRef

                let data = {}
                if (customerDoc.data) {
                  data = customerDoc.data
                }

                const newData = {
                  ...data,
                  // eapi: keyData.eapiKey,
                  openai_key: keyData.openaiKey,
                  anthropic_key: keyData.anthropicKey,
                }

                res = await updateDoc(customerRef, {
                  data: newData
                });

                returnData = {
                  data: "success",
                };
              } else {
                returnData = {
                  error: "no user doc id",
                };
              }
            }
            return returnData;
          } catch (err) {
            console.error(err);
            return {
              error: err,
            };
          }
        },
        // setAiUserData,
        //  getAiUserData: async () => {
        //
        //    try {
        //      let userDoc = {}
        //      if (user && user.uid) {
        //        // 1: Get UserDoc
        //        const userDoc = await getAiUserDoc(user.uid)
        //        if (userDoc && userDoc.error === undefined) {
        //          // user doc found
        //          setAiUserData({
        //            ...customerUserData,
        //            data: userDoc,
        //            loaded: true,
        //          })
        //
        //        }
        //        else if (userDoc && userDoc.error) {
        //          //
        //          setAiUserData({ ...customerUserData, loaded: true, error: userDoc.error })
        //        }
        //        else {
        //          // no doc found
        //          setAiUserData({
        //            ...customerUserData, loaded: true, error: {
        //              val: 'no doc found'
        //            }
        //          })
        //        }
        //      }
        //    } catch (err) {
        //      console.error(err);
        //      // setAdminUserData({ ...adminUserData, loaded: true, error: err })
        //    }
        //  },
        getAiAgentsDocs: async (userData) => {
          return new Promise((resolve, reject) => {
            try {
              let locationid = "";
              if (userData && userData.locationId) {
                locationid = userData.locationId;
              }

              if (locationid === "") {
                // no locationid
                reject({
                  error: "no locationid",
                });
              }

              if (locationid !== "") {
                // get aiagents docs
                const q = query(
                  collection(db, "aiagents"),
                  where("locationid", "==", locationid),
                  where("archive", "==", false),
                  orderBy("name", "asc")
                );

                const unsub = onSnapshot(q, (querySnapshot) => {
                  const aiagentsDocsArr = [];
                  querySnapshot.forEach((doc) => {
                    const docData = doc.data();

                    let aisettings = [];
                    if (docData && docData.aisettings) {
                      aisettings = docData.aisettings;
                    }

                    let archive = false;
                    if (docData.archive !== undefined) {
                      archive = docData.archive;
                    }

                    let inputs = [];
                    if (docData.inputs !== undefined) {
                      inputs = docData.inputs;
                    }

                    const smallData = {
                      aiagentid: docData.aiagentid,
                      name: docData.name,
                      automationlink: docData.automationlink,
                      aisettings: aisettings,
                      inputs: inputs,
                      domains: docData.domains,
                      webtoken: docData.webtoken,
                      presetref: docData.presetref,
                      archive: archive,
                    };
                    // aisettingsDocsArr.push(doc.data().name);
                    aiagentsDocsArr.push(smallData);
                  });

                  setAiAgentsDocs(aiagentsDocsArr);

                  resolve({
                    data: aiagentsDocsArr,
                  });
                });
              }
            } catch (err) {
              console.error(err);
              reject({
                error: err,
              });
            }
          });
        },
        getAiCategoriesDoc: async (userData, categoryId) => {
          return new Promise((resolve, reject) => {
            try {
              let locationid = "";
              if (userData && userData.locationId) {
                locationid = userData.locationId;
              }

              if (locationid === "") {
                // no locationid
                reject({
                  error: "no locationid",
                });
              }

              let aicategoryid = "";
              if (categoryId && categoryId !== "") {
                aicategoryid = categoryId;
              }

              if (aicategoryid === "") {
                // no aicategoryid
                reject({
                  error: "no aicategoryid",
                });
              }

              if (locationid !== "" && aicategoryid !== "") {
                // if (locationid !== '') {
                const q = query(collection(db, "aicategories"), where("aicategoryid", "==", aicategoryid), orderBy("name", "asc"));
                const unsub = onSnapshot(q, (querySnapshot) => {
                  let aicategoryDoc = null;
                  querySnapshot.forEach((doc) => {
                    const docData = doc.data();
                    const smallData = {
                      aicategoryid: docData.aicategoryid,
                      name: docData.name,
                      aipresets: docData.aipresets,
                    };
                    // aisettingsDocsArr.push(doc.data().name);
                    aicategoryDoc = smallData;
                  });

                  setAiCategoriesDoc(aicategoryDoc);

                  resolve({
                    data: aicategoryDoc,
                  });
                });
              }
            } catch (err) {
              console.error(err);
              reject({
                error: err,
              });
            }
          });
        },
        removeAiAgent: async (agentSelected, delAgent) => {
          let returnData = {
            data: {},
            error: {},
          };

          let deleteAgent = delAgent;
          // deleteAgent = true

          try {
            let aiagentId = "";
            if (agentSelected && agentSelected.aiagentid) {
              aiagentId = agentSelected.aiagentid;
            }

            if (aiagentId === "") {
              returnData.error = "no aiagentId";
              return returnData;
            }


            // if (deleteAgent) {
            //   //reset menu in aiSettingsPanel
            //   setAiAgentsSelected(null);
            //   // Delete aiagent doc
            //   const agentDeletedResult = await deleteAiAgentDoc(aiagentId);
            //   if (agentDeletedResult && agentDeletedResult.data) {
            //     returnData.data.aiagent = aiagentId;
            //   } else if (agentDeletedResult && agentDeletedResult.error) {
            //     returnData.error.aiagent = agentDeletedResult.error;
            //   }
            // } else {
            //   //reset menu in aiSettingsPanel
            //   setAiAgentsSelected(null);
            //   // Archive aiagent doc
            //   const agentArchivedResult = await archiveAiAgentDoc(aiagentId, true);
            //   if (agentArchivedResult && agentArchivedResult.data) {
            //     returnData.data.aiagent = aiagentId;
            //   } else if (agentArchivedResult && agentArchivedResult.error) {
            //     returnData.error.aiagent = agentArchivedResult.error;
            //   }
            // }


            // find all aiconversations with aiagentid
            // loop over aisettings
            let aisettings = [];
            if (agentSelected && agentSelected.aisettings) {
              aisettings = agentSelected.aisettings;
            }

            // loop over aigent aisettings
            for (const sDoc of aisettings) {
              const settingsId = sDoc.aisettingid;
              // get aiconversations
              const conversationsResult = await getAiConversationDocsByAiSettingId(settingsId);

              if (conversationsResult && conversationsResult.data) {
                const aiconversationsDocs = conversationsResult.data;

                // loop over aiconversations
                for (const cDoc of aiconversationsDocs) {
                  const conversationId = cDoc.aiconversationid;




                  // get aimessage messages
                  const messagemessagesResult = await getAiMessageMessagesDocsByAiConversationId(conversationId);
                  if (messagemessagesResult && messagemessagesResult.data) {
                    const aimessagemessagesDocs = messagemessagesResult.data;

                    if (deleteAgent) {
                      // Delete aimessagemessages docs
                      const messagemessagesDeletedResult = await deleteAiMessageMessagesDocs(aimessagemessagesDocs);

                      if (messagemessagesDeletedResult.data) {
                        if (!returnData.data.aimessagemessages) returnData.data.aimessagemessages = [];
                        returnData.data.aimessagemessages = [...returnData.data.aimessagemessages, ...messagemessagesDeletedResult.data];
                      } else if (messagemessagesDeletedResult.error) {
                        if (!returnData.error.aimessagemessages) returnData.error.aimessagemessages = [];
                        returnData.error.aimessagemessages = [...returnData.error.aimessagemessages, ...messagemessagesDeletedResult.error];
                      }
                    }
                    // else {
                    //   // Archive aimessagemessages docs
                    //   const messagemessagesArchivedResult = await archiveAiMessagesMessageDocs(aimessagemessagesDocs, true);

                    //   if (messagemessagesArchivedResult.data) {
                    //     if (!returnData.data.aimessagemessages) returnData.data.aimessagemessages = [];
                    //     returnData.data.aimessagemessages = [...returnData.data.aimessagemessages, ...messagemessagesArchivedResult.data];
                    //   } else if (messagemessagesArchivedResult.error) {
                    //     if (!returnData.error.aimessagemessages) returnData.error.aimessagemessages = [];
                    //     returnData.error.aimessagemessages = [...returnData.error.aimessagemessages, ...messagemessagesArchivedResult.error];
                    //   }
                    // }
                  }


                  // get aimessages
                  const messagesResult = await getAiMessagesDocsByAiConversationId(conversationId);
                  if (messagesResult && messagesResult.data) {
                    const aimessagesDocs = messagesResult.data;

                    if (deleteAgent) {
                      // Delete aimessages docs
                      const messagesDeletedResult = await deleteAiMessagesDocs(aimessagesDocs);

                      if (messagesDeletedResult.data) {
                        if (!returnData.data.aimessages) returnData.data.aimessages = [];
                        returnData.data.aimessages = [...returnData.data.aimessages, ...messagesDeletedResult.data];
                      } else if (messagesDeletedResult.error) {
                        if (!returnData.error.aimessages) returnData.error.aimessages = [];
                        returnData.error.aimessages = [...returnData.error.aimessages, ...messagesDeletedResult.error];
                      }
                    }
                    // else {
                    //   // Archive aimessages docs
                    //   const messagesArchivedResult = await archiveAiMessagesDocs(aimessagesDocs, true);

                    //   if (messagesArchivedResult.data) {
                    //     if (!returnData.data.aimessages) returnData.data.aimessages = [];
                    //     returnData.data.aimessages = [...returnData.data.aimessages, ...messagesArchivedResult.data];
                    //   } else if (messagesArchivedResult.error) {
                    //     if (!returnData.error.aimessages) returnData.error.aimessages = [];
                    //     returnData.error.aimessages = [...returnData.error.aimessages, ...messagesArchivedResult.error];
                    //   }
                    // }
                  }



                  // get aipromptversions
                  const promptversionsResult = await getAiPromptVersionsDocsByAiConversationId(conversationId);
                  if (promptversionsResult && promptversionsResult.data) {
                    const aipromptversionsDocs = promptversionsResult.data;

                    if (deleteAgent) {
                      // Delete aipromptversions docs
                      const promptversionsDeletedResult = await deleteAiPromptVersionsDocs(aipromptversionsDocs);

                      if (promptversionsDeletedResult.data) {
                        if (!returnData.data.aipromptversions) returnData.data.aipromptversions = [];
                        returnData.data.aipromptversions = [...returnData.data.aipromptversions, ...promptversionsDeletedResult.data];
                      } else if (promptversionsDeletedResult.error) {
                        if (!returnData.error.aipromptversions) returnData.error.aipromptversions = [];
                        returnData.error.aipromptversions = [...returnData.error.aipromptversions, ...promptversionsDeletedResult.error];
                      }
                    } else {
                      // Archive aipromptversions docs
                      const promptversionsArchivedResult = await archiveAiPromptVersionsDocs(aipromptversionsDocs, true);

                      if (promptversionsArchivedResult.data) {
                        if (!returnData.data.aipromptversions) returnData.data.aipromptversions = [];
                        returnData.data.aipromptversions = [...returnData.data.aipromptversions, ...promptversionsArchivedResult.data];
                      } else if (promptversionsArchivedResult.error) {
                        if (!returnData.error.aipromptversions) returnData.error.aipromptversions = [];
                        returnData.error.aipromptversions = [...returnData.error.aipromptversions, ...promptversionsArchivedResult.error];
                      }
                    }
                  }


                  // get aiengineerconversations
                  const engineerconversationsResult = await getAiEngineerConversationsDocsByAiConversationId(conversationId);
                  if (engineerconversationsResult && engineerconversationsResult.data) {
                    const aiengineerconversationsDocs = engineerconversationsResult.data;

                    if (deleteAgent) {
                      // Delete aiengineerconversations docs
                      const engineerconversationsDeletedResult = await deleteAiEngineerConversationsDocs(aiengineerconversationsDocs);

                      if (engineerconversationsDeletedResult.data) {
                        if (!returnData.data.aiengineerconversations) returnData.data.aiengineerconversations = [];
                        returnData.data.aiengineerconversations = [
                          ...returnData.data.aiengineerconversations,
                          ...engineerconversationsDeletedResult.data,
                        ];
                      } else if (engineerconversationsDeletedResult.error) {
                        if (!returnData.error.aiengineerconversations) returnData.error.aiengineerconversations = [];
                        returnData.error.aiengineerconversations = [
                          ...returnData.error.aiengineerconversations,
                          ...engineerconversationsDeletedResult.error,
                        ];
                      }
                    } else {
                      // Archive aiengineerconversations docs
                      const engineerconversationsArchivedResult = await archiveAiEngineerConversationsDocs(aiengineerconversationsDocs, true);

                      if (engineerconversationsArchivedResult.data) {
                        if (!returnData.data.aiengineerconversations) returnData.data.aiengineerconversations = [];
                        returnData.data.aiengineerconversations = [
                          ...returnData.data.aiengineerconversations,
                          ...engineerconversationsArchivedResult.data,
                        ];
                      } else if (engineerconversationsArchivedResult.error) {
                        if (!returnData.error.aiengineerconversations) returnData.error.aiengineerconversations = [];
                        returnData.error.aiengineerconversations = [
                          ...returnData.error.aiengineerconversations,
                          ...engineerconversationsArchivedResult.error,
                        ];
                      }
                    }
                  }

                  // get ailinksettings
                  const linksettingsResult = await getAiLinkSettingsDocsByAiConversationId(conversationId, false);

                  if (linksettingsResult && linksettingsResult.data) {
                    const ailinksettingsDocs = linksettingsResult.data;

                    if (deleteAgent) {
                      // Delete ailinksettings docs
                      const linksettingsDeletedResult = await deleteAiLinkSettingsDocs(ailinksettingsDocs);

                      if (linksettingsDeletedResult.data) {
                        if (!returnData.data.ailinksettings) returnData.data.ailinksettings = [];
                        returnData.data.ailinksettings = [...returnData.data.ailinksettings, ...linksettingsDeletedResult.data];
                      } else if (linksettingsDeletedResult.error) {
                        if (!returnData.error.ailinksettings) returnData.error.ailinksettings = [];
                        returnData.error.ailinksettings = [...returnData.error.ailinksettings, ...linksettingsDeletedResult.error];
                      }
                    } else {
                      // Archive ailinksettings docs
                      const linksettingsArchivedResult = await archiveAiLinkSettingsDocs(ailinksettingsDocs, true);

                      if (linksettingsArchivedResult.data) {
                        if (!returnData.data.ailinksettings) returnData.data.ailinksettings = [];
                        returnData.data.ailinksettings = [...returnData.data.ailinksettings, ...linksettingsArchivedResult.data];
                      } else if (linksettingsArchivedResult.error) {
                        if (!returnData.error.ailinksettings) returnData.error.ailinksettings = [];
                        returnData.error.ailinksettings = [...returnData.error.ailinksettings, ...linksettingsArchivedResult.error];
                      }
                    }
                  }
                }

                if (deleteAgent) {
                  // Delete aiconversations docs
                  const conversationsDeletedResult = await deleteAiConversationDocs(aiconversationsDocs);
                  if (conversationsDeletedResult) {
                    if (conversationsDeletedResult.data) {
                      if (!returnData.data.aiconversations) returnData.data.aiconversations = [];
                      returnData.data.aiconversations = [...returnData.data.aiconversations, ...conversationsDeletedResult.data];
                    } else if (conversationsDeletedResult.error) {
                      if (!returnData.error.aiconversations) returnData.error.aiconversations = [];
                      returnData.error.aiconversations = [...returnData.error.aiconversations, ...conversationsDeletedResult.error];
                    }
                  }
                } else {
                  // Archive aiconversations docs
                  const conversationsArchivedResult = await archiveAiConversationDocs(aiconversationsDocs, true);
                  if (conversationsArchivedResult) {
                    if (conversationsArchivedResult.data) {
                      if (!returnData.data.aiconversations) returnData.data.aiconversations = [];
                      returnData.data.aiconversations = [...returnData.data.aiconversations, ...conversationsArchivedResult.data];
                    } else if (conversationsArchivedResult.error) {
                      if (!returnData.error.aiconversations) returnData.error.aiconversations = [];
                      returnData.error.aiconversations = [...returnData.error.aiconversations, ...conversationsArchivedResult.error];
                    }
                  }
                }
              }
            }

            if (deleteAgent) {
              //reset menu in aiSettingsPanel
              setAiAgentsSelected(null);
              // Delete aiagent doc
              const agentDeletedResult = await deleteAiAgentDoc(aiagentId);
              if (agentDeletedResult && agentDeletedResult.data) {
                returnData.data.aiagent = aiagentId;
              } else if (agentDeletedResult && agentDeletedResult.error) {
                returnData.error.aiagent = agentDeletedResult.error;
              }
            } else {
              //reset menu in aiSettingsPanel
              setAiAgentsSelected(null);
              // Archive aiagent doc
              const agentArchivedResult = await archiveAiAgentDoc(aiagentId, true);
              if (agentArchivedResult && agentArchivedResult.data) {
                returnData.data.aiagent = aiagentId;
              } else if (agentArchivedResult && agentArchivedResult.error) {
                returnData.error.aiagent = agentArchivedResult.error;
              }
            }

            if (_.isEmpty(returnData.data)) {
              delete returnData.data;
            }

            if (_.isEmpty(returnData.error)) {
              delete returnData.error;
            }

            if (clog()) console.log("removeAiAgent returnData", returnData);
            return returnData;
          } catch (err) {
            console.error(err);
            returnData = {
              error: err,
            };
            return returnData;
          }
        },
        //        getAiSettingsDocs: async (userData) => {
        //          return new Promise((resolve, reject) => {
        //            try {
        //              let locationid = "";
        //              if (userData && userData.locationId) {
        //                locationid = userData.locationId;
        //              }
        //
        //              if (locationid === "") {
        //                // no locationid
        //                reject({
        //                  error: "no locationid",
        //                });
        //              }
        //
        //              let aiagentid = "";
        //              if (aiAgentsSelected && aiAgentsSelected.aiagentid) {
        //                aiagentid = aiAgentsSelected.aiagentid;
        //              }
        //
        //              if (aiagentid === "") {
        //                // no aiagentid
        //                reject({
        //                  error: "no aiagentid",
        //                });
        //              }
        //
        //              if (locationid !== "" && aiagentid !== "") {
        //                // if (locationid !== '') {
        //                const q = query(
        //                  collection(db, "aisettings"),
        //                  where("locationid", "==", locationid),
        //                  where("aiagentid", "==", aiagentid)
        //                  //  orderBy("createdon", "desc")
        //                );
        //                const unsub = onSnapshot(q, (querySnapshot) => {
        //                  const aisettingsDocsArr = [];
        //                  querySnapshot.forEach((doc) => {
        //                    const docData = doc.data();
        //                    const smallData = {
        //                      aisettingid: docData.aisettingid,
        //                      name: docData.data.name,
        //                      automationlink: docData.data.automationlink,
        //                    };
        //                    // aisettingsDocsArr.push(doc.data().name);
        //                    aisettingsDocsArr.push(smallData);
        //                  });
        //
        //                  setAiSettingsDocs(aisettingsDocsArr);
        //
        //                  resolve({
        //                    data: aisettingsDocsArr,
        //                  });
        //                });
        //              }
        //            } catch (err) {
        //              console.error(err);
        //              reject({
        //                error: err,
        //              });
        //            }
        //          });
        //        },
        // Not currently used
        getAiSettings: async (userData, selectedSettings) => {
          return new Promise((resolve, reject) => {
            try {
              // let aiconversationid = 'MIfmb2exQdkMvTQKzuEK'
              let aisettingid = "";
              if (selectedSettings && selectedSettings.aisettingid) {
                aisettingid = selectedSettings.aisettingid;
              }
              if (aisettingid !== "") {
                const unsub = onSnapshot(doc(db, "aisettings", aisettingid), (doc) => {
                  // if (clog()) console.log("Current data: ", doc.data());
                  // const docData = doc.data()
                  // BT ZZZ Set new setAiSettingsLiveData
                  // set aiConversation state that listens to changes
                  setAiSettingsLiveData(doc.data());
                  resolve({
                    data: doc.data(),
                  });
                });
              }
            } catch (err) {
              console.error(err);
              reject({
                error: err,
              });
            }
          });
        },
        removeAiSettings: async (agentSelected, settingsSelected, deleteSettings) => {
          let returnData = {
            data: {},
            error: {},
          };
          try {
            let aiagentId = "";
            if (agentSelected && agentSelected.aiagentid) {
              aiagentId = agentSelected.aiagentid;
            }

            let settingsId = "";
            if (settingsSelected && settingsSelected.aisettingid) {
              settingsId = settingsSelected.aisettingid;
            }

            if (aiagentId !== "" && settingsId !== "") {
              let aisettingsUpdated = [...agentSelected.aisettings];
              const index = aisettingsUpdated.findIndex((x) => x.aisettingid === settingsId);

              if (index > -1) {
                if (deleteSettings) {
                  // delete settings from aiagent
                  aisettingsUpdated.splice(index, 1);
                } else {
                  // archive settings in aiagent
                  aisettingsUpdated[index].archive = true;
                }
              }

              // update aiagent
              const date = generateFirestoreDate();

              const agentUpdateData = {
                aisettings: aisettingsUpdated,
                updatedon: date,
              };

              // save aiagent
              const agentResult = await updateAiAgentDoc(aiagentId, agentUpdateData);

              if (agentResult && agentResult.data) {
                returnData.data.aiagent = aiagentId;
                // return settingid and aisettings array to update state in parent
                returnData.data.aisettingid = settingsId;
                returnData.data.aisettings = aisettingsUpdated;
              } else if (agentResult && agentResult.error) {
                returnData.error.aiagent = agentResult.error;
              }

              // get aiconversations
              const conversationsResult = await getAiConversationDocsByAiSettingId(settingsId);

              if (conversationsResult && conversationsResult.data) {
                const aiconversationsDocs = conversationsResult.data;

                for (const cDoc of aiconversationsDocs) {
                  const conversationId = cDoc.aiconversationid;
                  // get ailinksettings
                  const linksettingsResult = await getAiLinkSettingsDocsByAiConversationId(conversationId, false);

                  if (linksettingsResult && linksettingsResult.data) {
                    const ailinksettingsDocs = linksettingsResult.data;
                    if (deleteSettings) {
                      // Delete ailinksettings docs
                      const linksettingsDeletedResult = await deleteAiLinkSettingsDocs(ailinksettingsDocs);

                      if (linksettingsDeletedResult.data) {
                        if (!returnData.data.ailinksettings) returnData.data.ailinksettings = [];
                        returnData.data.ailinksettings = [...returnData.data.ailinksettings, ...linksettingsDeletedResult.data];
                      } else if (linksettingsDeletedResult.error) {
                        if (!returnData.error.ailinksettings) returnData.error.ailinksettings = [];
                        returnData.error.ailinksettings = [...returnData.error.ailinksettings, ...linksettingsDeletedResult.error];
                      }
                    } else {
                      // Archive ailinksettings docs
                      const linksettingsArchivedResult = await archiveAiLinkSettingsDocs(ailinksettingsDocs, true);

                      if (linksettingsArchivedResult.data) {
                        if (!returnData.data.ailinksettings) returnData.data.ailinksettings = [];
                        returnData.data.ailinksettings = [...returnData.data.ailinksettings, ...linksettingsArchivedResult.data];
                      } else if (linksettingsArchivedResult.error) {
                        if (!returnData.error.ailinksettings) returnData.error.ailinksettings = [];
                        returnData.error.ailinksettings = [...returnData.error.ailinksettings, ...linksettingsArchivedResult.error];
                      }
                    }
                  }
                }

                if (deleteSettings) {
                  // Delete aiconversations docs
                  const conversationsDeletedResult = await deleteAiConversationDocs(aiconversationsDocs);
                  if (conversationsDeletedResult) {
                    if (conversationsDeletedResult.data) {
                      if (!returnData.data.aiconversations) returnData.data.aiconversations = [];
                      returnData.data.aiconversations = [...returnData.data.aiconversations, ...conversationsDeletedResult.data];
                    } else if (conversationsDeletedResult.error) {
                      if (!returnData.error.aiconversations) returnData.error.aiconversations = [];
                      returnData.error.aiconversations = [...returnData.error.aiconversations, ...conversationsDeletedResult.error];
                    }
                  }
                } else {
                  // Archive aiconversations docs
                  const conversationsArchivedResult = await archiveAiConversationDocs(aiconversationsDocs, true);
                  if (conversationsArchivedResult) {
                    if (conversationsArchivedResult.data) {
                      if (!returnData.data.aiconversations) returnData.data.aiconversations = [];
                      returnData.data.aiconversations = [...returnData.data.aiconversations, ...conversationsArchivedResult.data];
                    } else if (conversationsArchivedResult.error) {
                      if (!returnData.error.aiconversations) returnData.error.aiconversations = [];
                      returnData.error.aiconversations = [...returnData.error.aiconversations, ...conversationsArchivedResult.error];
                    }
                  }
                }
              }

              if (_.isEmpty(returnData.error)) {
                delete returnData.error;
              }

              return returnData;
            } else {
            }
          } catch (err) {
            console.error(err);
            returnData = {
              error: err,
            };
            return returnData;
          }
        },
        saveAiSettings: async (uData, agentsSelected, aiConv, aiSettingsUpdated, promptVersionSelected, settingsIndexIdSelected) => {
          let returnData = {};
          try {
            let res = {};
            let locationId = "";
            if (uData && uData.locationId && uData.locationId !== "") {
              locationId = uData.locationId;
            }

            if (locationId === "") {
              return {
                error: {
                  val: "Location id is missing",
                },
              };
            }

            let aiAgentId = "";
            if (aiConv && aiConv.aiagentid && aiConv.aiagentid !== "") {
              aiAgentId = aiConv.aiagentid;
            }

            if (aiAgentId === "") {
              return {
                error: {
                  val: "agent id is missing",
                },
              };
            }

            let userId = "";
            if (aiConv && aiConv.userid && aiConv.userid !== "") {
              userId = aiConv.userid;
            }

            // let lastMessageRole = "";
            // // check is messages exists in aiConversation and get last message type
            // if (aiConv && aiConv.messages && aiConv.messages.length > 0) {
            //   const lastMessage = aiConv.messages[aiConv.messages.length - 1];
            //   let role = "";
            //   if (lastMessage && lastMessage.role) {
            //     role = lastMessage.role;
            //   }
            //   lastMessageRole = role;
            // }

            // let versionCurrent = 0;
            // let versionNew = 0;
            // const conversationData = { ...aiConversation };
            // const settingsUpdatedData = { ...aiSettingsDataUpdated };
            // was states but this might be easier to pass different data

            const conversationData = { ...aiConv };
            const settingsUpdatedData = { ...aiSettingsUpdated };

            let aiConversationId = "";
            if (conversationData && conversationData.aiconversationid) {
              aiConversationId = conversationData.aiconversationid;
            }

            if (aiConversationId !== "") {
              let aiLinkSettingId = "";
              if (conversationData && conversationData.ailinksettingid && conversationData.ailinksettingid !== "") {
                // isLinked = true;
                aiLinkSettingId = conversationData.ailinksettingid;
              }

              let automationLink = "";
              if (agentsSelected.automationlink && agentsSelected.automationlink !== "") {
                automationLink = agentsSelected.automationlink;
              }

              let aiSettingsIndexId = "";
              if (aiSettingsUpdated.aisettingsindexid && aiSettingsUpdated.aisettingsindexid !== "") {
                aiSettingsIndexId = aiSettingsUpdated.aisettingsindexid;
              }


              // Get a new write batch
              const batch = writeBatch(db);
              // generate date for updatedon
              const date = generateFirestoreDate();

              /* //////////////// START: aipromptversion //////////////// */

              // const test = promptVersionSelected;

              let promptVersionCurrent = 0;
              if (promptVersionSelected && promptVersionSelected.promptversion) {
                promptVersionCurrent = promptVersionSelected.promptversion;
              }

              // let promptVersion = 0;
              // if (promptVersionSelected && promptVersionSelected.promptversion) {
              //   promptVersion = promptVersionSelected.promptversion;
              // }

              let promptVersion = 0;
              if (aiSettingsUpdated && aiSettingsUpdated.promptversion) {
                promptVersion = aiSettingsUpdated.promptversion;
              }

              // if isLinkSettingPromptVersion is true then update linksettings doc
              let isLinkSettingPromptVersion = false;
              if (promptVersion === promptVersionCurrent) {
                isLinkSettingPromptVersion = true;
              }

              let promptVersionName = "";
              if (promptVersionSelected && promptVersionSelected.name) {
                promptVersionName = promptVersionSelected.name;
              }

              let promptVersionId = "";
              if (promptVersionSelected && promptVersionSelected.aipromptversionid) {
                promptVersionId = promptVersionSelected.aipromptversionid;
              }



              // debugger

              // let promptVersionCount = 0;
              // if (aiConv && aiConv.promptversioncount) {
              //   promptVersionCount = aiConv.promptversioncount;
              // }
              // // if promptVersion is greater promptVersionCount
              // if (promptVersion > promptVersionCount) {
              //   promptVersionCount = promptVersion;
              // }

              let isNewPromptVersion = false;
              // if promptVersionId is empty then generate an id
              if (promptVersionId === "") {
                isNewPromptVersion = true;
                // Get the collection reference
                const aipromptversionsRef = collection(db, "aipromptversions");

                // Generate "locally" a new document for the given collection reference
                const aipromptversionsDocRef = doc(aipromptversionsRef);

                // Get the new document Id
                promptVersionId = aipromptversionsDocRef.id;
              }

              // get current selected multiprompt index
              let settingsIndex = 0;
              if (settingsIndexIdSelected && settingsIndexIdSelected.index !== undefined) {
                settingsIndex = settingsIndexIdSelected.index;
              }

              // get current aisettings from aiConv
              let settingsUpdatedDataArray = [];
              if (aiConv && aiConv.aisettings) {
                settingsUpdatedDataArray = aiConv.aisettings;
              }

              // update aisettings promptversion
              // settingsUpdatedData.promptversion = promptVersion;
              settingsUpdatedData.promptversioncurrent = promptVersionCurrent;
              settingsUpdatedData.promptversion = promptVersion;

              // BT aisettings
              // update aisettings array with new aisettings
              settingsUpdatedDataArray[settingsIndex] = settingsUpdatedData;

              // debugger
              // BT aisettings
              // process aisettings for aipromptversion
              const processedPromptVersionSettingsData = processSettingsDataForPromptVersion(settingsUpdatedData);
              // debugger
              const processedPromptVersionDocData = {
                aiconversationid: aiConversationId,
                aipromptversionid: promptVersionId,
                aisettingsindexid: aiSettingsIndexId,
                aisettings: processedPromptVersionSettingsData,
                locationid: locationId,
                name: promptVersionName,
                // promptversion: promptVersion,
                updatedon: date,
              };
              // debugger
              if (isNewPromptVersion) {
                // add createdon
                processedPromptVersionDocData.createdon = date;

                // add archive
                processedPromptVersionDocData.archive = false;
              }

              // if (globaldeleteme && globaldeleteme === 1) {
              //   processedPromptVersionDocData.deleteme = 1;
              // }

              // update promptversion
              const pRef = doc(db, "aipromptversions", promptVersionId);
              /// batch.set(lRef, processedDocData);
              // debugger
              if (isNewPromptVersion) {
                batch.set(pRef, processedPromptVersionDocData);
              } else {
                batch.update(pRef, processedPromptVersionDocData);
              }

              /* //////////////// END: aipromptversion //////////////// */


              /* //////////////// START: aiconversation data //////////////// */

              // BT version - save aisettings
              // let version = 1;
              // let versionSettings = 1;
              // let versionBasedOn = 0;

              // get prompt
              // conversation version is source of truth
              // if (conversationData && conversationData.version) {
              //   // version = conversationData.version;
              //   version = promptVersion;
              // }

              // if (settingsUpdatedData && settingsUpdatedData.version) {
              //   versionSettings = settingsUpdatedData.version;
              // }

              // if aisettings version is less than aiconversation version then update versionbasedon
              // if (version > versionSettings) {
              //   versionBasedOn = versionSettings;
              // }

              // check aisettings version and if it is less than conversation vesion the set versionBasedOn

              // if last message role is not empty string or aisettings, then increment version
              // if (lastMessageRole !== "" && lastMessageRole !== "aisettings" && lastMessageRole !== "aireset") {
              //   // set version to 1
              //   version = version + 1;
              // }

              // update aisettings promptversion
              // settingsUpdatedData.promptversion = promptVersion;

              // update aisettings version
              // settingsUpdatedData.version = version;
              // settingsUpdatedData.version = promptVersion;

              // update aisettings versionbasedon
              // settingsUpdatedData.versionbasedon = versionBasedOn;
              // settingsUpdatedData.versionbasedon = versionBasedOn;

              /// TODO: old message code needs to be saved in aimessages
              // let aiConversationMessages = [];
              // if (aiConversation && aiConversation.messages && aiConversation.messages.length > 0) {
              //   aiConversationMessages = [...aiConversation.messages];
              // }

              // const settingsUpdatedDataJson = JSON.stringify(settingsUpdatedData);

              // // add settings message to aiconversation doc
              // const message = {
              //   aisettings: settingsUpdatedDataJson,
              //   content: "",
              //   role: "aisettings",
              //   // extra data without processing json
              //   data: {
              //     version: version, // update aiconversation message version
              //     versionbasedon: versionBasedOn, // update aiconversation message versionbasedon
              //     promptversion: promptVersion, // update aiconversation message promptversion
              //     promptversionid: promptVersionId, // update aiconversation message promptversionid
              //   },
              // };

              // // if last message role is aisettings then replace last message
              // if (lastMessageRole === "aisettings") {
              //   aiConversationMessages.pop();
              // }
              // aiConversationMessages.push(message);

              /* //////////////// END: aiconversation data  //////////////// */


              // if promptversion is related to the ailinksettings doc then save ailinksettings doc
              // debugger
              let newAiLinkSettingsDoc = true;
              if (isLinkSettingPromptVersion) {
                /* //////////////// START: ailinksettings //////////////// */
                //// ailinksettings


                // debugger
                // if linked, update data for alinksettings doc
                if (aiLinkSettingId !== "") {
                  // check if doc exists
                  const linksettingsDoc = await getAiLinkSettingsDoc(locationId, aiLinkSettingId);

                  if (linksettingsDoc && linksettingsDoc.data && linksettingsDoc.data === true) {
                    newAiLinkSettingsDoc = false;
                  }
                  else {
                    if (clog()) console.warn("ailinksettings doc missing", {
                      aiagentid: aiAgentId,
                      aiconversationid: aiConversationId,
                      ailinksettingid: aiLinkSettingId,
                    });
                  }
                }

                if (newAiLinkSettingsDoc && aiLinkSettingId === "") {
                  // no link setting so generate an ailinksettingid
                  // Get the collection reference
                  const ailinksettingsRef = collection(db, "ailinksettings");

                  // Generate "locally" a new document for the given collection reference
                  const ailinksettingsDocRef = doc(ailinksettingsRef);

                  // Get the new document Id
                  aiLinkSettingId = ailinksettingsDocRef.id;
                  if (clog()) console.warn("new ailinksettings:", aiLinkSettingId);

                }
                // debugger
                // const processedLinkSettingsDocData = processSettingsDataForLinkSettings(settingsUpdatedData);
                const processedLinkSettingsDocData = processSettingsDataArrayForLinkSettings(settingsUpdatedDataArray);
                // debugger
                // aiagentid
                processedLinkSettingsDocData.aiagentid = aiAgentId;
                // locationid
                processedLinkSettingsDocData.locationid = locationId;
                // name
                processedLinkSettingsDocData.name = conversationData.name;
                // userid
                processedLinkSettingsDocData.userid = userId;


                // add conversation id to linksettings
                processedLinkSettingsDocData.aiconversationid = aiConversationId;

                // add doc id
                processedLinkSettingsDocData.ailinksettingid = aiLinkSettingId;

                // update promptversion
                // processedLinkSettingsDocData.promptversion = promptVersion;

                // debugger
                // add automationlink if automationlink matches
                let aiAgentSettingsLinkSettingsId = "";
                if (
                  agentsSelected &&
                  agentsSelected.aisettings &&
                  agentsSelected.aisettings[0] &&
                  agentsSelected.aisettings[0].ailinksettingid &&
                  agentsSelected.aisettings[0].ailinksettingid !== ""
                ) {
                  aiAgentSettingsLinkSettingsId = agentsSelected.aisettings[0].ailinksettingid;
                }

                let aiLinkSettingsAutomationLink = "";
                let aiLinkSettingsWebChatLink = "";
                if (
                  automationLink !== "" &&
                  aiLinkSettingId === aiAgentSettingsLinkSettingsId
                ) {
                  aiLinkSettingsAutomationLink = agentsSelected.automationlink;
                  aiLinkSettingsWebChatLink = agentsSelected.aiagentid;
                }
                processedLinkSettingsDocData.automationlink = aiLinkSettingsAutomationLink;
                processedLinkSettingsDocData.webchatlink = aiLinkSettingsWebChatLink;

                if (newAiLinkSettingsDoc) {
                  // add createdon
                  processedLinkSettingsDocData.createdon = date;
                }

                // add updatedon
                processedLinkSettingsDocData.updatedon = date;

                // add inuse
                processedLinkSettingsDocData.inuse = false;

                // add archive
                processedLinkSettingsDocData.archive = false;

                // remove version and versionbasedon
                // delete processedLinkSettingsDocData.version;
                // delete processedLinkSettingsDocData.versionbasedon;

                // update linksettings
                const lRef = doc(db, "ailinksettings", aiLinkSettingId);
                // debugger
                if (newAiLinkSettingsDoc) {
                  batch.set(lRef, processedLinkSettingsDocData);
                }
                else {
                  batch.update(lRef, processedLinkSettingsDocData);
                }

                /* //////////////// END: ailinksettings //////////////// */

              }



              /* //////////////// START: aiconversation //////////////// */
              // debugger
              // update conversation
              const cRef = doc(db, "aiconversations", aiConversationId);

              const cData = {
                ailinksettingid: aiLinkSettingId, // always update linksettingsid
                // messages: aiConversationMessages,
                // aisettings: settingsUpdatedDataJson,
                // aisettings: settingsUpdatedData,
                aisettings: settingsUpdatedDataArray,
                updatedon: date,
                // version: version, // update aiconversation version
                // versionbasedon: versionBasedOn, // update aiconversation versionbasedon
                // promptversion: promptVersion, // update aiconversation promptversion
                // promptversioncount: promptVersionCount, // update aiconversation promptversioncount
                // promptversionid: promptVersionId, // update aiconversation promptversionid
              }
              // debugger
              batch.update(cRef, cData);

              /* //////////////// END: aiconversation //////////////// */

              returnData.data = {}

              /* //////////////// START: aiagent //////////////// */

              // check for missing variables
              const test1 = settingsUpdatedDataArray;
              const test2 = agentsSelected;
              let aiAgentVariables = [];
              const missingVariables = checkForMissingAiAgentVariablesInSettings(settingsUpdatedDataArray, agentsSelected);
              debugger
              if (missingVariables.length > 0) {
                if (agentsSelected && agentsSelected.inputs) {
                  aiAgentVariables = _.cloneDeep(agentsSelected.inputs);
                }

                // add missing variables
                for (const missingVariable of missingVariables) {
                  const newVariable = {
                    name: 'input.' + missingVariable,
                    shortname: missingVariable,
                    value: "",
                  };
                  aiAgentVariables.push(newVariable);
                }

                // update aiagent
                const aRef = doc(db, "aiagents", aiAgentId);
                const aData = {
                  inputs: aiAgentVariables,
                  updatedon: date,
                }
                batch.update(aRef, aData);

                // debugger
                // settingsUpdatedDataArray
                // agentsSelected
              }
              /* ///////////////// END: aiagent ///////////////// */


              // commit the batch
              res = await batch.commit();

              if (aiAgentVariables.length > 0) {
                // aiagent variables updated
                returnData.data.agentsData = aiAgentVariables;
              }

              returnData.data.settingsData = settingsUpdatedData;


              if (clog()) console.log("------------------------------------------");
              if (clog()) console.log("aiagentid :", aiAgentId);
              if (clog()) console.log("aiconversationid:", aiConversationId);
              if (clog()) console.log("aisettingsindexid:", aiSettingsIndexId);
              if (clog()) console.log("aipromptversionid:", promptVersionId);
              if (clog()) console.log("ailinksettingid:", aiLinkSettingId);
              if (clog()) console.log("new ailinksettingid:", newAiLinkSettingsDoc);
              if (clog()) console.log("promptVersion:", promptVersion);
              if (clog()) console.log("aiAgentVariables:", aiAgentVariables);
              if (clog()) console.log("------------------------------------------");

              return returnData;

            } else {
              return {
                error: "no aiconversationid",
              };
            }

            return returnData;
          } catch (err) {
            console.error(err);
            returnData.error = err;
            return returnData;
          }
        },
        // saveAiSettingsPREPROMPTVERSIONCURRENT: async (uData, agentsSelected, aiConv, aiSettingsUpdated, promptVersionSelected, settingsIndexIdSelected) => {
        //   let returnData = {};
        //   try {
        //     let res = {};
        //     let locationId = "";
        //     if (uData && uData.locationId && uData.locationId !== "") {
        //       locationId = uData.locationId;
        //     }

        //     debugger

        //     if (locationId === "") {
        //       return {
        //         error: {
        //           val: "Location id is missing",
        //         },
        //       };
        //     }

        //     let aiAgentId = "";
        //     if (aiConv && aiConv.aiagentid && aiConv.aiagentid !== "") {
        //       aiAgentId = aiConv.aiagentid;
        //     }

        //     if (aiAgentId === "") {
        //       return {
        //         error: {
        //           val: "agent id is missing",
        //         },
        //       };
        //     }

        //     let userId = "";
        //     if (aiConv && aiConv.userid && aiConv.userid !== "") {
        //       userId = aiConv.userid;
        //     }

        //     // let lastMessageRole = "";
        //     // // check is messages exists in aiConversation and get last message type
        //     // if (aiConv && aiConv.messages && aiConv.messages.length > 0) {
        //     //   const lastMessage = aiConv.messages[aiConv.messages.length - 1];
        //     //   let role = "";
        //     //   if (lastMessage && lastMessage.role) {
        //     //     role = lastMessage.role;
        //     //   }
        //     //   lastMessageRole = role;
        //     // }

        //     // let versionCurrent = 0;
        //     // let versionNew = 0;
        //     // const conversationData = { ...aiConversation };
        //     // const settingsUpdatedData = { ...aiSettingsDataUpdated };
        //     // was states but this might be easier to pass different data

        //     const conversationData = { ...aiConv };
        //     const settingsUpdatedData = { ...aiSettingsUpdated };

        //     let aiConversationId = "";
        //     if (conversationData && conversationData.aiconversationid) {
        //       aiConversationId = conversationData.aiconversationid;
        //     }

        //     if (aiConversationId !== "") {
        //       let aiLinkSettingId = "";
        //       if (conversationData && conversationData.ailinksettingid && conversationData.ailinksettingid !== "") {
        //         // isLinked = true;
        //         aiLinkSettingId = conversationData.ailinksettingid;
        //       }

        //       let automationLink = "";
        //       if (agentsSelected.automationlink && agentsSelected.automationlink !== "") {
        //         automationLink = agentsSelected.automationlink;
        //       }

        //       let aiSettingsIndexId = "";
        //       if (aiSettingsUpdated.aisettingsindexid && aiSettingsUpdated.aisettingsindexid !== "") {
        //         aiSettingsIndexId = aiSettingsUpdated.aisettingsindexid;
        //       }


        //       // Get a new write batch
        //       const batch = writeBatch(db);
        //       // generate date for updatedon
        //       const date = generateFirestoreDate();

        //       /* //////////////// START: aipromptversion //////////////// */

        //       // const test = promptVersionSelected;

        //       let promptVersion = 0;
        //       if (promptVersionSelected && promptVersionSelected.promptversion) {
        //         promptVersion = promptVersionSelected.promptversion;
        //       }

        //       let promptVersionName = "";
        //       if (promptVersionSelected && promptVersionSelected.name) {
        //         promptVersionName = promptVersionSelected.name;
        //       }

        //       let promptVersionId = "";
        //       if (promptVersionSelected && promptVersionSelected.aipromptversionid) {
        //         promptVersionId = promptVersionSelected.aipromptversionid;
        //       }

        //       // let promptVersionCount = 0;
        //       // if (aiConv && aiConv.promptversioncount) {
        //       //   promptVersionCount = aiConv.promptversioncount;
        //       // }
        //       // // if promptVersion is greater promptVersionCount
        //       // if (promptVersion > promptVersionCount) {
        //       //   promptVersionCount = promptVersion;
        //       // }

        //       let isNewPromptVersion = false;
        //       // if promptVersionId is empty then generate an id
        //       if (promptVersionId === "") {
        //         isNewPromptVersion = true;
        //         // Get the collection reference
        //         const aipromptversionsRef = collection(db, "aipromptversions");

        //         // Generate "locally" a new document for the given collection reference
        //         const aipromptversionsDocRef = doc(aipromptversionsRef);

        //         // Get the new document Id
        //         promptVersionId = aipromptversionsDocRef.id;
        //       }

        //       // get current selected multiprompt index
        //       let settingsIndex = 0;
        //       if (settingsIndexIdSelected && settingsIndexIdSelected.index !== undefined) {
        //         settingsIndex = settingsIndexIdSelected.index;
        //       }

        //       // get current aisettings from aiConv
        //       let settingsUpdatedDataArray = [];
        //       if (aiConv && aiConv.aisettings) {
        //         settingsUpdatedDataArray = aiConv.aisettings;
        //       }

        //       // update aisettings promptversion
        //       settingsUpdatedData.promptversion = promptVersion;

        //       // BT aisettings
        //       // update aisettings array with new aisettings
        //       settingsUpdatedDataArray[settingsIndex] = settingsUpdatedData;


        //       // BT aisettings
        //       // process aisettings for aipromptversion
        //       const processedPromptVersionSettingsData = processSettingsDataForPromptVersion(settingsUpdatedData);

        //       const processedPromptVersionDocData = {
        //         aiconversationid: aiConversationId,
        //         aipromptversionid: promptVersionId,
        //         aisettingsindexid: aiSettingsIndexId,
        //         aisettings: processedPromptVersionSettingsData,
        //         locationid: locationId,
        //         name: promptVersionName,
        //         // promptversion: promptVersion,
        //         updatedon: date,
        //       };
        //       debugger
        //       if (isNewPromptVersion) {
        //         // add createdon
        //         processedPromptVersionDocData.createdon = date;

        //         // add archive
        //         processedPromptVersionDocData.archive = false;
        //       }

        //       // if (globaldeleteme && globaldeleteme === 1) {
        //       //   processedPromptVersionDocData.deleteme = 1;
        //       // }

        //       // update promptversion
        //       const pRef = doc(db, "aipromptversions", promptVersionId);
        //       /// batch.set(lRef, processedDocData);
        //       if (isNewPromptVersion) {
        //         batch.set(pRef, processedPromptVersionDocData);
        //       } else {
        //         batch.update(pRef, processedPromptVersionDocData);
        //       }

        //       /* //////////////// END: aipromptversion //////////////// */


        //       /* //////////////// START: aiconversation data //////////////// */

        //       // BT version - save aisettings
        //       // let version = 1;
        //       // let versionSettings = 1;
        //       // let versionBasedOn = 0;

        //       // get prompt
        //       // conversation version is source of truth
        //       // if (conversationData && conversationData.version) {
        //       //   // version = conversationData.version;
        //       //   version = promptVersion;
        //       // }

        //       // if (settingsUpdatedData && settingsUpdatedData.version) {
        //       //   versionSettings = settingsUpdatedData.version;
        //       // }

        //       // if aisettings version is less than aiconversation version then update versionbasedon
        //       // if (version > versionSettings) {
        //       //   versionBasedOn = versionSettings;
        //       // }

        //       // check aisettings version and if it is less than conversation vesion the set versionBasedOn

        //       // if last message role is not empty string or aisettings, then increment version
        //       // if (lastMessageRole !== "" && lastMessageRole !== "aisettings" && lastMessageRole !== "aireset") {
        //       //   // set version to 1
        //       //   version = version + 1;
        //       // }

        //       // update aisettings promptversion
        //       // settingsUpdatedData.promptversion = promptVersion;

        //       // update aisettings version
        //       // settingsUpdatedData.version = version;
        //       // settingsUpdatedData.version = promptVersion;

        //       // update aisettings versionbasedon
        //       // settingsUpdatedData.versionbasedon = versionBasedOn;
        //       // settingsUpdatedData.versionbasedon = versionBasedOn;

        //       /// TODO: old message code needs to be saved in aimessages
        //       // let aiConversationMessages = [];
        //       // if (aiConversation && aiConversation.messages && aiConversation.messages.length > 0) {
        //       //   aiConversationMessages = [...aiConversation.messages];
        //       // }

        //       // const settingsUpdatedDataJson = JSON.stringify(settingsUpdatedData);

        //       // // add settings message to aiconversation doc
        //       // const message = {
        //       //   aisettings: settingsUpdatedDataJson,
        //       //   content: "",
        //       //   role: "aisettings",
        //       //   // extra data without processing json
        //       //   data: {
        //       //     version: version, // update aiconversation message version
        //       //     versionbasedon: versionBasedOn, // update aiconversation message versionbasedon
        //       //     promptversion: promptVersion, // update aiconversation message promptversion
        //       //     promptversionid: promptVersionId, // update aiconversation message promptversionid
        //       //   },
        //       // };

        //       // // if last message role is aisettings then replace last message
        //       // if (lastMessageRole === "aisettings") {
        //       //   aiConversationMessages.pop();
        //       // }
        //       // aiConversationMessages.push(message);

        //       /* //////////////// END: aiconversation data  //////////////// */


        //       /* //////////////// START: ailinksettings //////////////// */
        //       //// ailinksettings

        //       let newAiLinkSettingsDoc = true;

        //       debugger
        //       // if linked, update data for alinksettings doc
        //       if (aiLinkSettingId !== "") {
        //         // check if doc exists
        //         const linksettingsDoc = await getAiLinkSettingsDoc(locationId, aiLinkSettingId);

        //         if (linksettingsDoc && linksettingsDoc.data && linksettingsDoc.data === true) {
        //           newAiLinkSettingsDoc = false;
        //         }
        //         else {
        //           if (clog()) console.warn("ailinksettings doc missing", {
        //             aiagentid: aiAgentId,
        //             aiconversationid: aiConversationId,
        //             ailinksettingid: aiLinkSettingId,
        //           });
        //         }
        //       }

        //       if (newAiLinkSettingsDoc && aiLinkSettingId === "") {
        //         // no link setting so generate an ailinksettingid
        //         // Get the collection reference
        //         const ailinksettingsRef = collection(db, "ailinksettings");

        //         // Generate "locally" a new document for the given collection reference
        //         const ailinksettingsDocRef = doc(ailinksettingsRef);

        //         // Get the new document Id
        //         aiLinkSettingId = ailinksettingsDocRef.id;
        //         if (clog()) console.warn("new ailinksettings:", aiLinkSettingId);

        //       }

        //       // const processedLinkSettingsDocData = processSettingsDataForLinkSettings(settingsUpdatedData);
        //       const processedLinkSettingsDocData = processSettingsDataArrayForLinkSettings(settingsUpdatedDataArray);

        //       // aiagentid
        //       processedLinkSettingsDocData.aiagentid = aiAgentId;
        //       // locationid
        //       processedLinkSettingsDocData.locationid = locationId;
        //       // name
        //       processedLinkSettingsDocData.name = conversationData.name;
        //       // userid
        //       processedLinkSettingsDocData.userid = userId;


        //       // add conversation id to linksettings
        //       processedLinkSettingsDocData.aiconversationid = aiConversationId;

        //       // add doc id
        //       processedLinkSettingsDocData.ailinksettingid = aiLinkSettingId;

        //       // update promptversion
        //       // processedLinkSettingsDocData.promptversion = promptVersion;

        //       debugger
        //       // add automationlink if automationlink matches
        //       let aiAgentSettingsLinkSettingsId = "";
        //       if (
        //         agentsSelected &&
        //         agentsSelected.aisettings &&
        //         agentsSelected.aisettings[0] &&
        //         agentsSelected.aisettings[0].ailinksettingid &&
        //         agentsSelected.aisettings[0].ailinksettingid !== ""
        //       ) {
        //         aiAgentSettingsLinkSettingsId = agentsSelected.aisettings[0].ailinksettingid;
        //       }

        //       let aiLinkSettingsAutomationLink = "";
        //       let aiLinkSettingsWebChatLink = "";
        //       if (
        //         automationLink !== "" &&
        //         aiLinkSettingId === aiAgentSettingsLinkSettingsId
        //       ) {
        //         aiLinkSettingsAutomationLink = agentsSelected.automationlink;
        //         aiLinkSettingsWebChatLink = agentsSelected.aiagentid;
        //       }
        //       processedLinkSettingsDocData.automationlink = aiLinkSettingsAutomationLink;
        //       processedLinkSettingsDocData.webchatlink = aiLinkSettingsWebChatLink;

        //       if (newAiLinkSettingsDoc) {
        //         // add createdon
        //         processedLinkSettingsDocData.createdon = date;
        //       }

        //       // add updatedon
        //       processedLinkSettingsDocData.updatedon = date;

        //       // add inuse
        //       processedLinkSettingsDocData.inuse = false;

        //       // add archive
        //       processedLinkSettingsDocData.archive = false;

        //       // remove version and versionbasedon
        //       // delete processedLinkSettingsDocData.version;
        //       // delete processedLinkSettingsDocData.versionbasedon;

        //       // update linksettings
        //       const lRef = doc(db, "ailinksettings", aiLinkSettingId);

        //       if (newAiLinkSettingsDoc) {
        //         batch.set(lRef, processedLinkSettingsDocData);
        //       }
        //       else {
        //         batch.update(lRef, processedLinkSettingsDocData);
        //       }

        //       /* //////////////// END: ailinksettings //////////////// */



        //       /* //////////////// START: aiconversation //////////////// */

        //       // update conversation
        //       const cRef = doc(db, "aiconversations", aiConversationId);
        //       const cData = {
        //         ailinksettingid: aiLinkSettingId, // always update linksettingsid
        //         // messages: aiConversationMessages,
        //         // aisettings: settingsUpdatedDataJson,
        //         // aisettings: settingsUpdatedData,
        //         aisettings: settingsUpdatedDataArray,
        //         updatedon: date,
        //         // version: version, // update aiconversation version
        //         // versionbasedon: versionBasedOn, // update aiconversation versionbasedon
        //         // promptversion: promptVersion, // update aiconversation promptversion
        //         // promptversioncount: promptVersionCount, // update aiconversation promptversioncount
        //         // promptversionid: promptVersionId, // update aiconversation promptversionid
        //       }
        //       batch.update(cRef, cData);

        //       /* //////////////// END: aiconversation //////////////// */


        //       // commit the batch
        //       // res = await batch.commit();

        //       returnData.data = settingsUpdatedData;

        //       if (clog()) console.log("------------------------------------------");
        //       if (clog()) console.log("aiagentid :", aiAgentId);
        //       if (clog()) console.log("aiconversationid:", aiConversationId);
        //       if (clog()) console.log("aisettingsindexid:", aiSettingsIndexId);
        //       if (clog()) console.log("aipromptversionid:", promptVersionId);
        //       if (clog()) console.log("ailinksettingid:", aiLinkSettingId);
        //       if (clog()) console.log("new ailinksettingid:", newAiLinkSettingsDoc);
        //       if (clog()) console.log("------------------------------------------");
        //       debugger
        //     } else {
        //       return {
        //         error: "no aiconversationid",
        //       };
        //     }

        //     return returnData;
        //   } catch (err) {
        //     console.error(err);
        //     returnData.error = err;
        //     return returnData;
        //   }
        // },
        duplicateAiSettingsItem: async (uData, conversation, settingsDataUpdated, settingsIndexIdSelected) => {


          let returnData = {};
          try {
            let res = {};
            let locationId = "";
            if (uData && uData.locationId && uData.locationId !== "") {
              locationId = uData.locationId;
            }

            let aiConversationId = "";
            if (conversation && conversation.aiconversationid) {
              aiConversationId = conversation.aiconversationid;
            }

            let aiLinkSettingsId = "";
            if (conversation && conversation.ailinksettingid) {
              aiLinkSettingsId = conversation.ailinksettingid;
            }

            // Get a new write batch
            const batch = writeBatch(db);
            // generate date for updatedon
            const date = generateFirestoreDate();

            const test1 = conversation
            const test2 = settingsDataUpdated
            const test3 = settingsIndexIdSelected

            // clone conversation aisettings
            const updatedProcessedDataAiSettings = _.cloneDeep(conversation.aisettings)

            // clone settingsDataUpdated
            const updatedProcessedDataAiSetting = _.cloneDeep(settingsDataUpdated)

            // generate new aisettingsindexid
            // Get the collection reference
            const collectionRef = collection(db, "aiconversations");

            // Generate "locally" a new document for the given collection reference
            const conversationDocRef = doc(collectionRef);

            // Get the new document Id
            const aiSettingsIndexId = conversationDocRef.id;

            // update aisettingsindexid
            updatedProcessedDataAiSetting.aisettingsindexid = aiSettingsIndexId

            //update promptversion
            updatedProcessedDataAiSetting.promptversion = 1

            //update promptversion
            updatedProcessedDataAiSetting.promptversioncurrent = 1
            debugger
            // update name
            let nameStart = 'prompt '
            let nameEnd = 0
            let nameFull = ''
            const settingsCount = conversation.aisettings.length

            if (settingsCount > 0) {
              nameEnd = settingsCount
            }

            // check if name exists
            if (nameEnd > 0) {
              // while nameFull is empty
              while (nameFull === '') {
                // check if name exists
                const nameExists = conversation.aisettings.find(x => x.name === nameStart + nameEnd)
                if (nameExists) {
                  // increment nameEnd
                  nameEnd = nameEnd + 1
                }
                else {
                  // set nameFull
                  nameFull = nameStart + nameEnd
                }
              }
            }

            //update promptversion
            updatedProcessedDataAiSetting.name = nameFull

            // add updatedProcessedDataAiSetting to updatedProcessedDataAiSettings
            updatedProcessedDataAiSettings.push(updatedProcessedDataAiSetting)

            // update conversation
            const cRef = doc(db, "aiconversations", aiConversationId);

            // add deleteme
            if (globaldeleteme && globaldeleteme > 0) {
              debugger
              batch.update(cRef, {
                deleteme: globaldeleteme,
                aisettings: updatedProcessedDataAiSettings,
                updatedon: date,
              });
            }
            else {
              batch.update(cRef, {
                aisettings: updatedProcessedDataAiSettings,
                updatedon: date,
              });
            }


            // create promptversion
            let promptVersion = 1;
            let promptVersionName = "1";
            let promptVersionId = "";
            // let promptVersionCount = 1;

            let isNewPromptVersion = false;
            // if promptVersionId is empty then generate an id
            if (promptVersionId === "") {
              isNewPromptVersion = true;
              // Get the collection reference
              const aipromptversionsRef = collection(db, "aipromptversions");

              // Generate "locally" a new document for the given collection reference
              const aipromptversionsDocRef = doc(aipromptversionsRef);

              // Get the new document Id
              promptVersionId = aipromptversionsDocRef.id;
            }

            // process aisettings for aipromptversion
            // const processedPromptVersionSettingsData = processSettingsDataForPromptVersion(updatedProcessedDataAiSettings);
            const processedPromptVersionSettingsData = processSettingsDataForPromptVersion(updatedProcessedDataAiSetting);

            const processedPromptVersionDocData = {
              aiconversationid: aiConversationId,
              aipromptversionid: promptVersionId,
              aisettings: processedPromptVersionSettingsData,
              aisettingsindexid: aiSettingsIndexId,
              locationid: locationId,
              name: promptVersionName,
              // promptversion: promptVersion,
              updatedon: date,
            };

            if (isNewPromptVersion) {
              // add createdon
              processedPromptVersionDocData.createdon = date;

              // add archive
              processedPromptVersionDocData.archive = false;
            }


            // add deleteme
            if (globaldeleteme && globaldeleteme > 0) {
              processedPromptVersionDocData.deleteme = globaldeleteme;
            }
            // debugger
            // update aipromptversions
            const pRef = doc(db, "aipromptversions", promptVersionId);
            if (isNewPromptVersion) {
              // await setDoc(pRef, processedPromptVersionDocData);
              batch.set(pRef, processedPromptVersionDocData);

            } else {
              // await updateDoc(pRef, processedPromptVersionDocData);
              batch.update(pRef, processedPromptVersionDocData);
            }

            // update ailinksettings
            const processedLinkSettingsDocData = processSettingsDataArrayForLinkSettings(updatedProcessedDataAiSettings);

            // debugger
            // update ailinksettings.aisettings
            const lsRef = doc(db, "ailinksettings", aiLinkSettingsId);

            // add deleteme
            if (globaldeleteme && globaldeleteme > 0) {
              debugger
              batch.update(lsRef, {
                deleteme: globaldeleteme,
                aisettings: processedLinkSettingsDocData,
                updatedon: date,
              });
            }
            else {
              batch.update(lsRef, {
                aisettings: processedLinkSettingsDocData,
                updatedon: date,
              });
            }

            // commit the batch
            res = await batch.commit();

            returnData.data = {
              aiconversationid: aiConversationId,
              aisettingsindexid: aiSettingsIndexId,
              aipromptversionid: promptVersionId,
              ailinksettingid: aiLinkSettingsId,
              aisettings: updatedProcessedDataAiSettings,
              aisetting: updatedProcessedDataAiSetting,
            };

            // debugger
            return returnData

          } catch (err) {
            console.error(err);
            returnData.error = err;
            return returnData;
          }

        },
        //        savePrevAiSettingsToCurrent: async (conversation, settingsMessage) => {
        //          let returnData = {
        //          };
        //          try {
        //
        //            let res = {}
        //
        //            let versionCurrent = 0
        //            let versionNew = 0
        //            const conversationData = { ...aiConversation }
        //            const settingsUpdatedData = { ...aiSettingsDataUpdated }
        //            const settingsLiveData = aiSettingsLiveData
        //
        //            let aiConversationId = ''
        //            if (conversationData && conversationData.aiconversationid) {
        //              aiConversationId = conversationData.aiconversationid
        //            }
        //
        //            if (aiConversationId !== '') {
        //              let isLinked = false
        //              // settingsLive automation link
        //              let settingsLiveAutomationLink = ''
        //              if (settingsLiveData && settingsLiveData.data && settingsLiveData.data.automationlink) {
        //                settingsLiveAutomationLink = settingsLiveData.data.automationlink
        //              }
        //
        //              // BT how to check if automation link for settings live and conversation matches
        //
        //              let version = 0
        //              if (settingsUpdatedData && settingsUpdatedData.version) {
        //                version = settingsUpdatedData.version
        //              }
        //
        //              // if last message role is not empty string or aisettings, then increment version
        //              if (lastMessageRole !== '' && lastMessageRole !== 'aisettings') {
        //                version = version + 1
        //                // update version
        //                settingsUpdatedData.version = version
        //                // remove versionbasedon
        //                delete settingsUpdatedData.versionbasedon
        //              }
        //
        //              let aiConversationMessages = []
        //              if (aiConversation && aiConversation.messages && aiConversation.messages.length > 0) {
        //                aiConversationMessages = [...aiConversation.messages]
        //              }
        //
        //              const settingsUpdatedDataJson = JSON.stringify(settingsUpdatedData)
        //
        //              // add settings message to aiconversation doc
        //              const message = {
        //                aisettings: settingsUpdatedDataJson,
        //                content: '',
        //                role: 'aisettings',
        //                // extra data without processing json
        //                data: {
        //                  version: version,
        //                  // versionPrev: versionCurrent,
        //                }
        //              }
        //
        //              // if last message role is aisettings then replace last message
        //              if (lastMessageRole === 'aisettings') {
        //                aiConversationMessages.pop()
        //              }
        //
        //              aiConversationMessages.push(message)
        //
        //              const test = aiConversationMessages
        //
        //              // Get a new write batch
        //              const batch = writeBatch(db);
        //
        //              // update conversation
        //              const cRef = doc(db, "aiconversations", aiConversationId);
        //              batch.update(cRef, {
        //                messages: aiConversationMessages,
        //                aisettings: settingsUpdatedDataJson,
        //              });
        //
        //              // commit the batch
        //              res = await batch.commit();
        //
        //              returnData.data = settingsUpdatedData
        //
        //            }
        //            else {
        //              return {
        //                error: 'no aiconversationid'
        //              }
        //            }
        //
        //            return returnData;
        //
        //          } catch (err) {
        //            console.error(err);
        //            returnData.error = err;
        //            return returnData;
        //          }
        //        },

        // not used anymore
        duplicateAiSettings: async (message) => {
          let returnData = {};
          try {
            // Get the collection reference
            const collectionRef = collection(db, "aisettings");

            // Generate "locally" a new document for the given collection reference
            const docRef = doc(collectionRef);

            // Get the new document Id
            const id = docRef.id;

            const processedData = processAiSettingsData(aiSettingsData, id);

            //  Sets the new document with its uuid as property
            // await setDoc(docRef, { uuid: documentUuid, ... })
            const result = await setDoc(docRef, processedData);

            returnData.data = { aisettingid: id };
            //            const ref = db.collection('your_collection_name').doc();
            //
            //            const newDoc = await addDoc(collection(db, "aisettings"), message);
            //            if (clog()) console.log("Document written with ID: ", newDoc.id);
            //            return newDoc.id
            return returnData;
          } catch (err) {
            console.error(err);
            returnData.status = 501;
            returnData.error = {
              val: "Duplicate ai settings failed",
            };
            // return returnData;
          }
        },
        //  getAiLinkSettings: async (userData, selectedAgent) => {
        //    return new Promise((resolve, reject) => {
        //
        //      try {
        //
        //        let locationid = ''
        //        if (userData && userData.locationid) {
        //          locationid = userData.locationid
        //        }
        //
        //        // let aiconversationid = 'MIfmb2exQdkMvTQKzuEK'
        //        let automationlink = ''
        //        if (selectedAgent && selectedAgent.automationlink) {
        //          automationlink = selectedAgent.automationlink
        //        }
        //        if (locationid !== '' && automationlink !== '') {
        //          const q = query(
        //            collection(db, "ailinksettings"),
        //            where("locationid", "==", locationid),
        //            // where("aisettingid", "==", aisettingid),
        //            where("data.automationlink", "==", automationlink),
        //            // orderBy("createdon", "desc"),
        //            // orderBy("data.name", "asc"),
        //          );
        //
        //          const unsub = onSnapshot(q, (querySnapshot) => {
        //            const ailinksettingsDocsArr = [];
        //            querySnapshot.forEach((doc) => {
        //              const docData = doc.data();
        //              // aisettingsDocsArr.push(doc.data().name);
        //              ailinksettingsDocsArr.push(docData);
        //            });
        //            if (ailinksettingsDocsArr.length > 1) {
        //              console.warn('More than 1 ailinksettings doc exists:', ailinksettingsDocsArr)
        //            }
        //
        //            //setAiSettingsLiveData(ailinksettingsDocsArr[0])
        //            if (ailinksettingsDocsArr.length === 1) {
        //              setAiLinkSettingsData(ailinksettingsDocsArr[0])
        //            }
        //            else {
        //              // no doc found
        //              setAiLinkSettingsData(null)
        //            }
        //
        //            resolve({
        //              data: ailinksettingsDocsArr
        //            })
        //
        //          });
        //
        //
        //        }
        //        else {
        //          console.warn('getAiLinkSettings: missing locationid or automationlink:', {
        //            locationid: locationid,
        //            automationlink: automationlink
        //          })
        //          resolve({
        //            error: {
        //              locationid: locationid,
        //              automationlink: automationlink
        //            }
        //          })
        //        }
        //
        //      } catch (err) {
        //        console.error(err);
        //        reject({
        //          error: err
        //        })
        //
        //      }
        //    })
        //  },
        getAiConversationsDocs: async (userData) => {
          return new Promise((resolve, reject) => {
            try {
              let locationid = "";
              if (userData && userData.locationId) {
                locationid = userData.locationId;
              }

              let aisettingid = "";
              if (aiSettingsSelected && aiSettingsSelected.aisettingid && aiSettingsSelected.aisettingid !== "") {
                aisettingid = aiSettingsSelected.aisettingid;
              }

              if (locationid === "") {
                // no locationid
                reject({
                  error: "no locationid",
                });
              }

              if (aisettingid === "") {
                // no aisettingid
                reject({
                  error: "no aisettingid",
                });
              }

              if (locationid !== "" && aisettingid !== "") {
                const q = query(
                  collection(db, "aiconversations"),
                  where("locationid", "==", locationid),
                  where("aisettingid", "==", aisettingid),
                  where("live", "==", false),
                  orderBy("createdon", "desc")
                );
                const unsub = onSnapshot(q, (querySnapshot) => {
                  const aiconversationsDocsArr = [];

                  if (querySnapshot.empty) {
                    // no docs found
                  }

                  querySnapshot.forEach((doc) => {
                    const docData = doc.data();

                    // // START: copy to processAiConversationSmallData
                    // let messages = [];
                    // if (docData.messages && docData.messages.length > 0) {
                    //   messages = docData.messages;
                    // }

                    // let name = "";
                    // if (docData.name && docData.name !== "") {
                    //   name = docData.name;
                    // }

                    // let automationlink = "";
                    // if (docData.automationlink && docData.automationlink !== "") {
                    //   automationlink = docData.automationlink;
                    // }

                    // let ailinksettingid = "";
                    // if (docData.ailinksettingid && docData.ailinksettingid !== "") {
                    //   ailinksettingid = docData.ailinksettingid;
                    // }

                    // let improveprocessing = false;
                    // if (docData.improveprocessing !== undefined) {
                    //   improveprocessing = docData.improveprocessing;
                    // }

                    // let archive = false;
                    // if (docData.archive !== undefined) {
                    //   archive = docData.archive;
                    // }

                    // // get version from settings
                    // // let version = 0;
                    // // if (docData.aisettings && docData.aisettings !== "") {
                    // //   const aisettings = JSON.parse(docData.aisettings);
                    // //   if (aisettings && aisettings.version) {
                    // //     version = aisettings.version;
                    // //   }
                    // // }
                    // let promptversion = 0;
                    // if (docData.promptversion !== undefined) {
                    //   promptversion = docData.promptversion;
                    // }

                    // const smallData = {
                    //   aiconversationid: docData.aiconversationid,
                    //   createdon: docData.createdon,
                    //   // messages: messages,
                    //   name: name,
                    //   ailinksettingid: ailinksettingid,
                    //   automationlink: automationlink,
                    //   improveprocessing: improveprocessing,
                    //   archive: archive,
                    //   promptversion: promptversion,
                    // };

                    // END: copy to processAiConversationSmallData

                    // const smallData = processAiConversationSmallData(docData);
                    // aiconversationsDocsArr.push(smallData);

                    aiconversationsDocsArr.push(docData);

                  });

                  setAiConversationsDocs(aiconversationsDocsArr);

                  resolve({
                    data: aiconversationsDocsArr,
                  });
                });
              }
            } catch (err) {
              console.error(err);
              reject({
                error: err,
              });
            }
          });
        },
        prepareAiConversationSmallData: (conversation, settingsData, settingsIndexIdSelected) => {
          const index = settingsIndexIdSelected.index;

          const updatedAiConversation = _.cloneDeep(conversation);
          // update aiconversation with new aisettings at se
          updatedAiConversation.aisettings[index] = settingsData;

          // make sure that promptversion is set correctly in aiConversation
          // updatedAiConversation.promptversion = settingsData.promptversion;

          // const smallData = processAiConversationSmallData(updatedAiConversation);
          const smallData = updatedAiConversation;

          return smallData;
        },
        getAiConversationOLD: async (userData, conversationsSelected) => {
          return new Promise((resolve, reject) => {
            try {
              let aiconversationid = "";
              if (conversationsSelected && conversationsSelected.aiconversationid && conversationsSelected.aiconversationid !== "") {
                aiconversationid = conversationsSelected.aiconversationid;
              }
              if (aiconversationid !== "") {
                const unsub = onSnapshot(doc(db, "aiconversations", aiconversationid), (doc) => {
                  // if (clog()) console.log("Current data: ", doc.data());
                  // const docData = doc.data()
                  // set aiConversation state that listens to changes
                  const data = doc.data();
                  if (data) {
                    setAiConversation(doc.data());
                  } else {
                    setAiConversation(null);
                  }

                  // if (doc.messages && doc.messages.length > 0) {
                  //   aiConversationMessageCount.current = doc.messages.length;
                  // }
                  resolve({
                    data: doc.data(),
                  });
                });
              }
            } catch (err) {
              console.error(err);
              reject({
                error: err,
              });
            }
          });
        },
        getAiConversationAndMessages: async (userData, conversationsSelected) => {
          try {
            let returnData = {
              aiconversation: {},
              aimessagesdoc: {},
              aimessages: {},
            };

            let aiconversationid = "";
            if (conversationsSelected && conversationsSelected.aiconversationid && conversationsSelected.aiconversationid !== "") {
              aiconversationid = conversationsSelected.aiconversationid;
            }
            if (aiconversationid !== "") {
              // get conversation
              const resultConversation = await getAiConversation(userData, aiconversationid);

              if (resultConversation && resultConversation.data) {
                returnData.aiconversation.data = resultConversation.data;

                // get messages doc
                const resultMessageDoc = await getAiMessagesDoc(userData, aiconversationid);

                if (resultMessageDoc && resultMessageDoc.data) {
                  returnData.aimessagesdoc.data = resultMessageDoc.data;
                }
                else {
                  returnData.aimessagesdoc.error = resultMessageDoc.error;
                }

                // get messages
                const resultMessages = await getAiMessages(userData, aiconversationid);

                if (resultMessages && resultMessages.data) {
                  returnData.aimessages.data = resultMessages.data;
                }
                else {
                  returnData.aimessages.error = resultMessages.error;
                }
              }
            }
            else {
              returnData.aiconversation.error = {
                val: 'No aiconversationid'
              }
            }

            return returnData;

          } catch (err) {
            console.error(err);
            return {
              aiconversation: {
                error: err,
              }
            };
          }
        },
        // duplicateAiConversationOLD: async (uData, conversation) => {
        //   let returnData = {};
        //   try {
        //     let locationid = "";
        //     if (uData && uData.locationId && uData.locationId !== "") {
        //       locationid = uData.locationId;
        //     }

        //     let originalConversationId = "";
        //     if (conversation && conversation.aiconversationid && conversation.aiconversationid !== "") {
        //       originalConversationId = conversation.aiconversationid;
        //     }

        //     if (locationid !== "" && originalConversationId !== "" && conversation) {
        //       const date = generateFirestoreDate();

        //       // Get the collection reference
        //       const collectionRef = collection(db, "aiconversations");

        //       // Generate "locally" a new document for the given collection reference
        //       const conversationDocRef = doc(collectionRef);

        //       // Get the new document Id
        //       const id = conversationDocRef.id;

        //       // START: duplicate aiengineerconversations
        //       let engineerConversationDocs = [];

        //       // get aiengineerconversations docs connected to aiconversationid
        //       const qec = query(
        //         collection(db, "aiengineerconversations"),
        //         where("locationid", "==", locationid),
        //         where("aiconversationid", "==", originalConversationId),
        //         orderBy("createdon", "desc")
        //       );

        //       const snapshotEngineerConversations = await getDocs(qec);

        //       // if (snapshotEngineerConversations.empty) {
        //       // }
        //       snapshotEngineerConversations.forEach((doc) => {
        //         // doc.data() is never undefined for query doc snapshots
        //         // if (clog()) console.log(doc.id, " => ", doc.data());
        //         engineerConversationDocs.push(doc.data());
        //       });

        //       for (const ecDoc of engineerConversationDocs) {
        //         // wait X milliseconds
        //         await delay(100);
        //         // generate a new id
        //         const ecRef = collection(db, "aiengineerconversations");

        //         // Generate "locally" a new document for the given collection reference
        //         const ecDocRef = doc(ecRef);

        //         // Get the new document Id
        //         const aiengineerconversationid = ecDocRef.id;

        //         const newDocData = _.cloneDeep(ecDoc);

        //         // update aiengineerconversationid
        //         newDocData.aiengineerconversationid = aiengineerconversationid;

        //         // update aiconversationid
        //         newDocData.aiconversationid = id;
        //         // update createdon
        //         newDocData.createdon = date;
        //         // update updatedon
        //         newDocData.updatedon = date;

        //         if (globaldeleteme && globaldeleteme === 1) {
        //           newDocData.deleteme = 1;
        //         }

        //         const engineerDocRef = doc(db, "aiengineerconversations", aiengineerconversationid);
        //         const ecRes = await setDoc(engineerDocRef, newDocData);
        //       }
        //       // END: duplicate aiengineerconversations

        //       let processedData = processAiConversationsData(conversation, id);

        //       // update aisettings
        //       const updatedProcessedData = duplicateUpdateAiConversationSettingsData(processedData, aiSettingsDataUpdated);

        //       const updatedProcessedDataAiSettings = updatedProcessedData.aisettings;

        //       // create aipromptversion
        //       // START: promptVersion
        //       // const test = promptVersionSelected;

        //       let promptVersion = 1;
        //       let promptVersionName = "1";
        //       let promptVersionId = "";
        //       let promptVersionCount = 1;

        //       let isNewPromptVersion = false;
        //       // if promptVersionId is empty then generate an id
        //       if (promptVersionId === "") {
        //         isNewPromptVersion = true;
        //         // Get the collection reference
        //         const aipromptversionsRef = collection(db, "aipromptversions");

        //         // Generate "locally" a new document for the given collection reference
        //         const aipromptversionsDocRef = doc(aipromptversionsRef);

        //         // Get the new document Id
        //         promptVersionId = aipromptversionsDocRef.id;
        //       }

        //       // process aisettings for aipromptversion
        //       const processedPromptVersionSettingsData = processSettingsDataForPromptVersion(updatedProcessedDataAiSettings);

        //       const processedPromptVersionDocData = {
        //         aiconversationid: id,
        //         aipromptversionid: promptVersionId,
        //         aisettings: processedPromptVersionSettingsData,
        //         locationid: updatedProcessedData.locationid,
        //         name: promptVersionName,
        //         promptversion: promptVersion,
        //         updatedon: date,
        //       };

        //       if (isNewPromptVersion) {
        //         // add createdon
        //         processedPromptVersionDocData.createdon = date;

        //         // add archive
        //         processedPromptVersionDocData.archive = false;
        //       }

        //       if (globaldeleteme && globaldeleteme === 1) {
        //         processedPromptVersionDocData.deleteme = globaldeleteme;
        //       }

        //       // update aipromptversions
        //       const pRef = doc(db, "aipromptversions", promptVersionId);
        //       /// batch.set(lRef, processedDocData);
        //       if (isNewPromptVersion) {
        //         // batch.set(pRef, processedPromptVersionDocData);
        //         await setDoc(pRef, processedPromptVersionDocData);
        //       } else {
        //         await updateDoc(pRef, processedPromptVersionDocData);
        //       }

        //       // END: promptVersion

        //       if (globaldeleteme && globaldeleteme === 1) {
        //         updatedProcessedData.deleteme = 1;
        //       }

        //       // save aiconversation doc
        //       await setDoc(conversationDocRef, updatedProcessedData);

        //       returnData.data = {
        //         aiconversationid: id,
        //         aipromptversionid: promptVersionId,
        //       };
        //     } else {
        //       returnData.error = {
        //         val: "missing locationid or conversation data",
        //       };
        //     }
        //     return returnData;
        //   } catch (err) {
        //     console.error(err);
        //     returnData.error = {
        //       val: "duplicateAiConversation failed",
        //     };
        //     // return returnData;
        //   }
        // },
        duplicateAiConversation: async (uData, conversation) => {
          let returnData = {};
          try {
            let res = {};
            let locationId = "";
            if (uData && uData.locationId && uData.locationId !== "") {
              locationId = uData.locationId;
            }

            let aiAgentId = "";
            if (conversation && conversation.aiagentid && conversation.aiagentid !== "") {
              aiAgentId = conversation.aiagentid;
            }

            if (aiAgentId === "") {
              return {
                error: {
                  val: "agent id is missing",
                },
              };
            }

            let userId = "";
            if (conversation && conversation.userid && conversation.userid !== "") {
              userId = conversation.userid;
            }

            let originalConversationId = "";
            if (conversation && conversation.aiconversationid && conversation.aiconversationid !== "") {
              originalConversationId = conversation.aiconversationid;
            }

            if (locationId !== "" && originalConversationId !== "" && conversation) {
              // Get a new write batch
              const batch = writeBatch(db);

              const date = generateFirestoreDate();

              // Get the collection reference
              const collectionRef = collection(db, "aiconversations");

              // Generate "locally" a new document for the given collection reference
              const conversationDocRef = doc(collectionRef);

              // Get the new document Id
              const aiConversationId = conversationDocRef.id;


              /* /////////// START: aiengineerconversations /////////// */
              // START: duplicate aiengineerconversations
              let engineerConversationDocs = [];

              // get aiengineerconversations docs connected to aiconversationid
              const qec = query(
                collection(db, "aiengineerconversations"),
                where("locationid", "==", locationId),
                where("aiconversationid", "==", originalConversationId),
                orderBy("createdon", "desc")
              );

              const snapshotEngineerConversations = await getDocs(qec);

              // if (snapshotEngineerConversations.empty) {
              // }
              snapshotEngineerConversations.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                // if (clog()) console.log(doc.id, " => ", doc.data());
                engineerConversationDocs.push(doc.data());
              });

              for (const ecDoc of engineerConversationDocs) {
                // wait X milliseconds
                await delay(100);
                // generate a new id
                const ecRef = collection(db, "aiengineerconversations");

                // Generate "locally" a new document for the given collection reference
                const ecDocRef = doc(ecRef);

                // Get the new document Id
                const aiengineerconversationid = ecDocRef.id;

                const newDocData = _.cloneDeep(ecDoc);

                // update aiengineerconversationid
                newDocData.aiengineerconversationid = aiengineerconversationid;

                // update aiconversationid
                newDocData.aiconversationid = aiConversationId;
                // update createdon
                newDocData.createdon = date;
                // update updatedon
                newDocData.updatedon = date;


                // add deleteme
                if (globaldeleteme && globaldeleteme > 0) {
                  newDocData.deleteme = globaldeleteme;
                }

                const engineerDocRef = doc(db, "aiengineerconversations", aiengineerconversationid);
                // const ecRes = await setDoc(engineerDocRef, newDocData);
                batch.set(engineerDocRef, newDocData);

              }

              // END: duplicate aiengineerconversations

              /* /////////// END: aiengineerconversations /////////// */

              let processedData = processAiConversationsData(conversation, aiConversationId);
              debugger
              // update aisettings
              // const updatedProcessedData = duplicateUpdateAiConversationSettingsData(processedData, aiSettingsDataUpdated);
              const updatedProcessedData = duplicateUpdateAiConversationSettingsArrayData(processedData, aiSettingsDataUpdated);
              debugger
              const updatedProcessedDataAiSettings = updatedProcessedData.aisettings;


              /* /////////// START: aipromptversions /////////// */

              let promptVersionIds = [];
              // loop over updatedProcessedDataAiSettings array
              for (const updatedProcessedDataAiSetting of updatedProcessedDataAiSettings) {

                // create aipromptversion
                // const test = promptVersionSelected;
                let promptVersion = 1;
                let promptVersionName = "1";
                let promptVersionId = "";
                // let promptVersionCount = 1;

                let isNewPromptVersion = false;
                // if promptVersionId is empty then generate an id
                if (promptVersionId === "") {
                  isNewPromptVersion = true;
                  // Get the collection reference
                  const aipromptversionsRef = collection(db, "aipromptversions");

                  // Generate "locally" a new document for the given collection reference
                  const aipromptversionsDocRef = doc(aipromptversionsRef);

                  // Get the new document Id
                  promptVersionId = aipromptversionsDocRef.id;
                }
                // save aiSettingsIndexId for later use
                const aiSettingsIndexId = updatedProcessedDataAiSetting.aisettingsindexid;

                // process aisettings for aipromptversion
                // const processedPromptVersionSettingsData = processSettingsDataForPromptVersion(updatedProcessedDataAiSettings);
                debugger
                const processedPromptVersionSettingsData = processSettingsDataForPromptVersion(updatedProcessedDataAiSetting);

                const processedPromptVersionDocData = {
                  aiconversationid: aiConversationId,
                  aipromptversionid: promptVersionId,
                  aisettings: processedPromptVersionSettingsData,
                  aisettingsindexid: aiSettingsIndexId,
                  locationid: updatedProcessedData.locationid,
                  name: promptVersionName,
                  // promptversion: promptVersion,
                  updatedon: date,
                };

                if (isNewPromptVersion) {
                  // add createdon
                  processedPromptVersionDocData.createdon = date;

                  // add archive
                  processedPromptVersionDocData.archive = false;
                }

                // if (globaldeleteme && globaldeleteme === 1) {
                //   processedPromptVersionDocData.deleteme = globaldeleteme;
                // }

                // add deleteme
                if (globaldeleteme && globaldeleteme > 0) {
                  processedPromptVersionDocData.deleteme = globaldeleteme;
                }

                // update aipromptversions
                const pRef = doc(db, "aipromptversions", promptVersionId);
                if (isNewPromptVersion) {
                  // await setDoc(pRef, processedPromptVersionDocData);
                  batch.set(pRef, processedPromptVersionDocData);

                } else {
                  // await updateDoc(pRef, processedPromptVersionDocData);
                  batch.update(pRef, processedPromptVersionDocData);

                }

                promptVersionIds.push(promptVersionId);

              }
              /* //////////// END: aipromptversions //////////// */

              /* /////////// START: ailinksettings /////////// */

              // create ailinksettings doc
              // Get the collection reference
              const ailinksettingsRef = collection(db, "ailinksettings");

              // Generate "locally" a new document for the given collection reference
              const ailinksettingsDocRef = doc(ailinksettingsRef);

              // Get the new document Id
              const aiLinkSettingsId = ailinksettingsDocRef.id;

              // const processedLinkSettingsDocData = processSettingsDataForLinkSettings(updatedProcessedDataAiSettings);
              const processedLinkSettingsDocData = processSettingsDataArrayForLinkSettings(updatedProcessedDataAiSettings);
              debugger
              // aiagentid
              processedLinkSettingsDocData.aiagentid = aiAgentId;
              // locationid
              processedLinkSettingsDocData.locationid = locationId;
              // name
              processedLinkSettingsDocData.name = updatedProcessedData.name;
              // userid
              processedLinkSettingsDocData.userid = userId;

              // add aiconversationid
              processedLinkSettingsDocData.aiconversationid = aiConversationId;

              // add ailinksettingid
              processedLinkSettingsDocData.ailinksettingid = aiLinkSettingsId;

              // add promptversion from aiconversation
              // processedLinkSettingsDocData.promptversion = promptVersion;

              processedLinkSettingsDocData.automationlink = '';
              processedLinkSettingsDocData.webchatlink = '';

              // add updatedon
              processedLinkSettingsDocData.createdon = date;

              // add updatedon
              processedLinkSettingsDocData.updatedon = date;

              // add inuse
              processedLinkSettingsDocData.inuse = false;

              // add archive
              processedLinkSettingsDocData.archive = false;

              // add deleteme
              if (globaldeleteme && globaldeleteme > 0) {
                processedLinkSettingsDocData.deleteme = globaldeleteme;
              }

              batch.set(ailinksettingsDocRef, processedLinkSettingsDocData);

              /* /////////// END: ailinksettings ///////////// */

              // add ailinksettingsid
              updatedProcessedData.ailinksettingid = aiLinkSettingsId;

              // add deleteme
              if (globaldeleteme && globaldeleteme > 0) {
                updatedProcessedData.deleteme = globaldeleteme;
              }
              // save aiconversation doc
              // await setDoc(conversationDocRef, updatedProcessedData);
              batch.set(conversationDocRef, updatedProcessedData);

              // commit the batch
              res = await batch.commit();

              returnData.data = {
                aiconversationid: aiConversationId,
                aipromptversionids: promptVersionIds,
                ailinksettingsid: aiLinkSettingsId,
              };

              if (clog()) console.log("---------------------------------------------------");
              if (clog()) console.log("duplicateAiConversation result: ", returnData.data);
              if (clog()) console.log("---------------------------------------------------");
            } else {
              returnData.error = {
                val: "missing locationid or conversation data",
              };
            }
            return returnData;
          } catch (err) {
            debugger
            console.error(err);
            returnData.error = {
              val: "duplicateAiConversation failed",
            };
            // return returnData;
          }
        },
        duplicateAiConversationWithPrevSettings: async (settingsMessage) => {
          let returnData = {};
          try {
            // like duplicateAiConversation but using settings from selected aisettings json
            // let settingsJson = null;
            // if (settingsMessage && settingsMessage.aisettings && settingsMessage.aisettings !== "") {
            //   settingsJson = settingsMessage.aisettings;
            // }

            let settings = null;
            if (settingsMessage && settingsMessage.aisettings) {
              settings = settingsMessage.aisettings;
            }

            // if (settingsJson) {
            if (settings) {
              // const settingsJsonParsed = JSON.parse(settingsJson);
              // Get the collection reference
              const collectionRef = collection(db, "aiconversations");

              // Generate "locally" a new document for the given collection reference
              const docRef = doc(collectionRef);

              // Get the new document Id
              const id = docRef.id;

              let processedData = processAiConversationsData(aiConversation, id);

              // update aisettings
              const updatedProcessedData = duplicateUpdateAiConversationSettingsData(processedData, settings);

              //  Sets the new document with its uuid as property
              const result = await setDoc(docRef, updatedProcessedData);

              returnData.data = { aiconversationid: id };
            } else {
              returnData.error = {
                val: "no aisettings data parsed",
              };
            }

            // needs prompt versions

            // needs aiengineerconversations

            return returnData;
          } catch (err) {
            console.error(err);
            returnData.error = err;
            return returnData;
          }
        },
        getAiWebCodeToken: async (userData, agentsSelected, agentDomains) => {
          let returnData = {
            status: 200,
            data: {},
            error: {},
          };
          try {
            let locationid = "";
            if (userData && userData.locationId) {
              locationid = userData.locationId;
            }

            let automationlink = "";
            if (agentsSelected && agentsSelected.automationlink && agentsSelected.automationlink !== "") {
              automationlink = agentsSelected.automationlink;
            }

            // let domains = [];
            // if (agentDomains && agentDomains.length > 0) {
            //   domains = agentDomains;
            // }

            // let aisettingid = "";
            // if (settingsSelected && settingsSelected.aisettingid) {
            //   aisettingid = settingsSelected.aisettingid;
            // }

            // let aiconversationid = "";
            // if (conversationsSelected && conversationsSelected.aiconversationid) {
            //   aiconversationid = conversationsSelected.aiconversationid;
            // }
            if (locationid !== "" && automationlink !== "") {
              const data = {
                locationid: locationid,
                link: automationlink,
                // url: url,
                // domains: domains,
              };

              let result = await getAiWebCodeTokenFromApi(data);
              returnData.status = result.status;

              if (result.status === 200 && result.data) {
                returnData.status = result.status;
                returnData.data = result.data;
              } else {
                returnData.status = result.status;
                returnData.error = result.error.code;
              }
              // let result = {status: 'success'};
              return returnData;
            }
          } catch (err) {
            console.error(err);
            returnData.status = 501;
            returnData.error = {
              val: "Web Code Token data fetch failed",
            };
            // return returnData;
          }
        },
        postAiMessage: async (userData, settingsSelected, conversationsSelected, messages, settingsItemsSwitchPromptsSelected) => {
          let returnData = {
            status: 200,
            data: {},
            error: {},
          };
          try {

            let locationid = "";
            if (userData && userData.locationId) {
              locationid = userData.locationId;
            }

            let aisettingid = "";
            if (settingsSelected && settingsSelected.aisettingid) {
              aisettingid = settingsSelected.aisettingid;
            }

            let aiconversationid = "";
            if (conversationsSelected && conversationsSelected.aiconversationid) {
              aiconversationid = conversationsSelected.aiconversationid;
            }

            if (locationid !== "" && aisettingid !== "" && aiconversationid !== "") {



              let switchPrompt = '';
              if (settingsItemsSwitchPromptsSelected && settingsItemsSwitchPromptsSelected[aiconversationid]) {
                const convSwitchPromptData = settingsItemsSwitchPromptsSelected[aiconversationid];

                let convSwitchPromptDataSettingsIndexId = '';
                if (convSwitchPromptData.aisettingsindexid) {
                  convSwitchPromptDataSettingsIndexId = convSwitchPromptData.aisettingsindexid;
                }

                // check convSwitchPromptDataSettingsIndexId exists in conversationsSelected.aisettings array of objects
                let convSwitchPromptDataSettingsIndexIdExists = false;
                if (conversationsSelected.aisettings && conversationsSelected.aisettings.length > 0) {
                  for (const convSettings of conversationsSelected.aisettings) {
                    if (convSettings.aisettingsindexid === convSwitchPromptDataSettingsIndexId) {
                      convSwitchPromptDataSettingsIndexIdExists = true;
                      break;
                    }
                  }
                }

                // if exists then set switchprompt value
                if (
                  convSwitchPromptDataSettingsIndexIdExists &&
                  convSwitchPromptData &&
                  convSwitchPromptData.switchprompt
                ) {
                  switchPrompt = convSwitchPromptData.switchprompt;
                }
              }

              const data = {
                locationid: locationid,
                aisettingid: aisettingid,
                aiconversationid: aiconversationid,
                messages: messages,
              };

              if (switchPrompt !== '') {
                data.switchprompt = switchPrompt;
              }

              let result
              // if (clog()) console.log('postEcomacyAiMessageFromApi: data:', data);
              result = await postEcomacyAiMessageFromApi(data);
              returnData.status = result.status;

              if (result.status === 200 && result.data) {
                returnData.status = result.status;
                returnData.data = result.data;
              } else {
                returnData.status = result.status;
                returnData.error = result.error.code;
                // returnData.error = result.error.code;
              }
              // let result = {status: 'success'};
              return returnData;
            }
          } catch (err) {
            console.error(err);
            returnData.status = 501;
            returnData.error = {
              val: "Ecomacy customers data fetch failed",
            };
            // return returnData;
          }
        },

        resetAiMessages: async (userData, conversationsSelected, message) => {
          let returnData = {
            status: 200,
            data: {},
            error: {},
          };
          try {
            let locationid = "";
            if (userData && userData.locationId) {
              locationid = userData.locationId;
            }

            // let aisettingid = "";
            // if (settingsSelected && settingsSelected.aisettingid) {
            //   aisettingid = settingsSelected.aisettingid;
            // }

            let aiconversationid = "";
            if (conversationsSelected && conversationsSelected.aiconversationid) {
              aiconversationid = conversationsSelected.aiconversationid;
            }

            if (locationid !== "" && aiconversationid !== "") {
              // const data = {
              //   locationid: locationid,
              //   // aisettingid: aisettingid,
              //   aiconversationid: aiconversationid,
              //   messages: messages,
              // };

              // let aiConversationMessages = [];
              // if (aiConversation && aiConversation.messages && aiConversation.messages.length > 0) {
              //   aiConversationMessages = [...aiConversation.messages];
              // }

              // aiConversationMessages.push(message);

              // const docRef = doc(db, "aiconversations", aiconversationid);

              // // Update the document
              // const result = await updateDoc(docRef, {
              //   messages: aiConversationMessages,
              //   updatedon: date,
              // });


              // createdAt: date
              // createdBy: locationid
              // data
              //  additional_kwargs: {},
              //  content: "",
              //  type: "aireset"

              // generate date for updatedon
              const date = generateFirestoreDate();

              const data = {
                createdAt: date,
                createdBy: locationid,
                data: {
                  additional_kwargs: {},
                  content: "",
                },
                type: "aireset"
              }

              // debugger
              // Get a new write batch
              // const batch = writeBatch(db);

              // collection(db, `aimessages/${ailiveconversationid}/messages`),
              const messagesDocRef = doc(db, "aimessages", aiconversationid);

              const messagesDocResult = await updateDoc(messagesDocRef, {
                state: deleteField(),
                switch_prompt: deleteField(),
              });
              // batch.update(messagesDocRef, {
              //   state: deleteField(),
              //   switch_prompt: deleteField(),
              // });


              const messagesColRef = collection(messagesDocRef, "messages")
              const result = await addDoc(messagesColRef, data);

              // const messagesColRef = doc(messagesDocRef, "messages", "");
              // const messagesColRef = collection(messagesDocRef, "messages")
              // batch.set(messagesColRef, data);


              // commit the batch
              // await batch.commit();

              returnData.data = { aiconversationid: aiconversationid };

              return returnData;
            }
          } catch (err) {
            console.error(err);
            returnData.error = err;
            return returnData;
          }
        },
        getAiPromptVersionsDocs: async (userData, conversationsSelected, settingsIndexIdSelected) => {
          return new Promise((resolve, reject) => {
            try {
              let locationid = "";
              if (userData && userData.locationId) {
                locationid = userData.locationId;
              }

              if (locationid === "") {
                // no locationid
                reject({
                  error: "no locationid",
                });
              }

              let aiconversationid = "";
              if (conversationsSelected && conversationsSelected.aiconversationid && conversationsSelected.aiconversationid !== "") {
                aiconversationid = conversationsSelected.aiconversationid;
              }

              if (aiconversationid === "") {
                // no locationid
                reject({
                  error: "no conversationid",
                });
              }

              let aisettingsindexid = "";
              if (settingsIndexIdSelected.aisettingsindexid !== "") {
                aisettingsindexid = settingsIndexIdSelected.aisettingsindexid;
              }

              // BT debugger
              if (locationid !== "" && aiconversationid !== "" && aisettingsindexid !== "") {
                const q = query(
                  collection(db, "aipromptversions"),
                  where("locationid", "==", locationid),
                  where("aiconversationid", "==", aiconversationid),
                  where("aisettingsindexid", "==", aisettingsindexid),
                  // where("archive", "==", false),
                  orderBy("createdon", "asc")
                );

                const unsub = onSnapshot(q, (querySnapshot) => {
                  const docsArr = [];
                  querySnapshot.forEach((doc) => {
                    const docData = doc.data();

                    const smallData = {
                      ...docData,
                      // aiagentid: docData.aiagentid,
                      // name: docData.name,
                      // automationlink: docData.automationlink,
                      // aisettings: aisettings,
                      // domains: docData.domains,
                      // webtoken: docData.webtoken,
                      // archive: archive,
                    };
                    // aisettingsDocsArr.push(doc.data().name);
                    docsArr.push(smallData);
                  });

                  setAiPromptVersionsDocs(docsArr);

                  resolve({
                    data: docsArr,
                  });
                });
              }
            } catch (err) {
              console.error(err);
              reject({
                error: err,
              });
            }
          });
        },
        getSelectedIndexFromAiPromptVersionsDocs: (conversationsSelected, promptVersionsDocs, settingsIndexIdSelected) => {
          let returnData = {
            index: 0,
            name: "",
            promptversion: -1,
            aipromptversionid: "",
          };

          let settingsIndexIdSelectedIndex = -1;
          if (settingsIndexIdSelected?.index > -1) {
            settingsIndexIdSelectedIndex = settingsIndexIdSelected.index;
          }

          // let conversationsSelectedPromptVersion = null;
          // if (conversationsSelected && conversationsSelected.promptversion) {
          //   conversationsSelectedPromptVersion = conversationsSelected.promptversion;
          // }

          if (settingsIndexIdSelectedIndex > -1) {
            const settingsSelected = conversationsSelected.aisettings[settingsIndexIdSelectedIndex]
            // BT debugger
            let conversationsSelectedPromptVersion = null;
            // if (settingsSelected && settingsSelected.promptversion) {
            //   conversationsSelectedPromptVersion = settingsSelected.promptversion;
            // }
            if (settingsSelected && settingsSelected.promptversioncurrent) {
              conversationsSelectedPromptVersion = settingsSelected.promptversioncurrent;
            }
            if (conversationsSelectedPromptVersion !== null) {
              // find index of conversationsSelectedPromptVersion in aiPromptVersionsDocs
              // const index = _.findIndex(promptVersionsDocs, { promptversion: conversationsSelectedPromptVersion });
              const index = _.findIndex(promptVersionsDocs, (item) => item.aisettings.promptversion === conversationsSelectedPromptVersion);
              if (index !== -1) {
                const selected = promptVersionsDocs[index];
                returnData = {
                  index: index,
                  name: selected.name,
                  // promptversion: selected.promptversion,
                  promptversion: selected.aisettings.promptversion,
                  aipromptversionid: selected.aipromptversionid,
                  aiconversationid: selected.aiconversationid,
                  aisettingsindexid: selected.aisettingsindexid,
                };
                // BT debugger
              }
            }

          }
          return returnData;
        },
        getSelectedNewIndexFromAiPromptVersionsDocs: (promptVersionAdded, promptVersionsDocs) => {
          let returnData = {
            index: 0,
            name: "",
            promptversion: -1,
            aipromptversionid: "",
          };

          let conversationsSelectedPromptVersion = null;
          if (promptVersionAdded && promptVersionAdded.promptversion) {
            conversationsSelectedPromptVersion = promptVersionAdded.promptversion;
          }
          if (conversationsSelectedPromptVersion !== null) {
            // find index of conversationsSelectedPromptVersion in aiPromptVersionsDocs
            // const index = _.findIndex(promptVersionsDocs, { promptversion: conversationsSelectedPromptVersion });
            const index = _.findIndex(promptVersionsDocs, (item) => item.aisettings.promptversion === conversationsSelectedPromptVersion);

            if (index !== -1) {
              const selected = promptVersionsDocs[index];

              returnData = {
                index: index,
                name: selected.name,
                promptversion: selected.aisettings.promptversion,
                aipromptversionid: selected.aipromptversionid,
                aiconversationid: selected.aiconversationid,
              };
            }
          }
          return returnData;
        },

        getAiPromptVersionData: async (uData, promptVersionSelected) => {
          let returnData = {};
          try {
            //
            const docRef = doc(db, "aipromptversions", promptVersionSelected.aipromptversionid);
            const docData = await getDoc(docRef);

            if (docData.exists()) {
              const data = docData.data();
              if (clog()) console.log("aipromptversion:", data);
              const dataProcessed = processAiPromptVersionDataForSettings(data);

              returnData.data = dataProcessed;
            } else {
              if (clog()) console.log("Document does not exist");
              returnData.error = {
                val: "Document does not exist",
              };
            }

            return returnData;
          } catch (err) {
            console.error(err);
            return {
              error: err,
            };
          }
        },
        cleanAiPromptVersionDataForAiSettings: (uData, promptVersionData) => {
          let returnData = {};

          let newData = {};
          if (promptVersionData) {
            newData = { ...promptVersionData };

            // remove locationid
            delete newData.locationid;

            // remove aipromptversionid
            delete newData.aipromptversionid;

            // remove prompt
            delete newData.prompt;

            // remove deleteme
            // delete newData.deleteme;

            // if (clog()) console.log("cleanAiPromptVersionDataForAiSettings: newData:", newData);
            returnData.data = newData;
          } else {
            returnData.error = {
              val: "no promptVersionData",
            };
          }

          return returnData;
        },
        mergeAiPromptVersionAiSettings: (uData, promptVersionData, settingsDataUpdated) => {
          let returnData = {};

          if (!promptVersionData) {
            returnData.error = {
              val: "no promptVersionData data",
            };
            return returnData;
          }

          if (!settingsDataUpdated) {
            returnData.error = {
              val: "no settingsDataUpdated data",
            };
            return returnData;
          }

          if (promptVersionData && settingsDataUpdated) {
            // create base data from promptVersionData
            let newData = { ...promptVersionData.aisettings };
            // if (clog()) console.log("promptVersionData:", promptVersionData);
            // if (clog()) console.log("settingsDataUpdated:", settingsDataUpdated);

            // get automationlink from settingsDataUpdated
            // let automationlink = "";
            // if (settingsDataUpdated.data && settingsDataUpdated.data.automationlink) {
            //   automationlink = settingsDataUpdated.data.automationlink;
            // }

            // add automationlink to newData
            // newData.data.automationlink = automationlink;
            // if (clog()) console.log("newData:", newData);
            // add aisettingsindexid
            newData.aisettingsindexid = settingsDataUpdated.aisettingsindexid;

            // promptversion

            // aisettingsPromptVersion
            let aisettingsPromptVersion = 0;
            if (settingsDataUpdated.promptversion !== undefined) {
              aisettingsPromptVersion = settingsDataUpdated.promptversion;
            }
            newData.promptversion = aisettingsPromptVersion;

            // promptVersionDataPromptVersion
            let promptVersionDataPromptVersion = 0;
            if (promptVersionData.aisettings?.promptversion !== undefined) {
              promptVersionDataPromptVersion = promptVersionData.aisettings.promptversion;
            }
            newData.promptversioncurrent = promptVersionDataPromptVersion;

            debugger
            // add promptversioncurrent
            returnData.data = newData;
          }

          return returnData;
        },
        processAddPromptVersionAiSettingsData: (type, uData, settingsData, newPromptVersion, settingsDataUpdated) => {
          let newSettingsData = {};

          // process data for aisettings
          newSettingsData = _.cloneDeep(settingsData);

          if (type === "new") {
            // add aiagentid
            // newSettingsData.aiagentid = settingsDataUpdated.aiagentid;

            // add aisettingid
            // newSettingsData.aisettingid = settingsDataUpdated.aisettingid;

            // add aisettingsindexid
            newSettingsData.aisettingsindexid = settingsDataUpdated.aisettingsindexid;
            // add promptversion
            // newSettingsData.promptversion = newPromptVersion;
            newSettingsData.promptversion = settingsDataUpdated.promptversion;
            // add promptversioncurrent
            newSettingsData.promptversioncurrent = newPromptVersion;

            // add promptversion
            // newSettingsData.version = newPromptVersion;

            // add promptversion
            // newSettingsData.versionbasedon = 0;

            // add automationlink
            // newSettingsData.data.automationlink = settingsDataUpdated.data.automationlink;
          }

          if (type === "duplicate") {
            // add promptversion
            // newSettingsData.promptversion = newPromptVersion;
            newSettingsData.promptversion = settingsDataUpdated.promptversion;

            // add promptversioncurrent
            newSettingsData.promptversioncurrent = newPromptVersion;

            // add promptversion
            // newSettingsData.version = newPromptVersion;

            // add promptversion
            // newSettingsData.versionbasedon = 0;
          }

          return newSettingsData;
        },
        getAiEngineersDocs: async (userData) => {
          return new Promise((resolve, reject) => {
            try {
              let locationid = "";
              if (userData && userData.locationId) {
                locationid = userData.locationId;
              }

              if (locationid === "") {
                // no locationid
                reject({
                  error: "no locationid",
                });
              }

              if (locationid !== "") {
                //if (clog()) console.log("rc.engineer_releaseversion:", rc.engineer_releaseversion);
                const q = query(
                  collection(db, "aiengineers"),
                  where("releaseversion", "<=", rc.engineer_releaseversion)
                  // where("locationid", "==", locationid),
                  // where("archive", "==", false)
                  //  orderBy("createdon", "desc")
                );

                const unsub = onSnapshot(q, (querySnapshot) => {
                  const docsArr = [];
                  querySnapshot.forEach((doc) => {
                    const docData = doc.data();
                    // let aisettings = [];
                    // if (docData && docData.aisettings) {
                    //   aisettings = docData.aisettings;
                    // }

                    // let archive = false;
                    // if (docData.archive !== undefined) {
                    //   archive = docData.archive;
                    // }

                    const smallData = {
                      ...docData,
                      // aiagentid: docData.aiagentid,
                      // name: docData.name,
                      // automationlink: docData.automationlink,
                      // aisettings: aisettings,
                      // domains: docData.domains,
                      // webtoken: docData.webtoken,
                      // archive: archive,
                    };
                    // aisettingsDocsArr.push(doc.data().name);
                    docsArr.push(smallData);
                  });

                  setAiEngineersDocs(docsArr);

                  resolve({
                    data: docsArr,
                  });
                });
              }
            } catch (err) {
              console.error(err);
              reject({
                error: err,
              });
            }
          });
        },
        getAiEngineerConversation: async (uData, conversationsSelected, aiEngineerSelected) => {
          return new Promise((resolve, reject) => {
            try {

              let locationid = "";
              if (uData && uData.locationId && uData.locationId !== "") {
                locationid = uData.locationId;
              }

              let aiconversationid = "";
              if (conversationsSelected && conversationsSelected.aiconversationid && conversationsSelected.aiconversationid !== "") {
                aiconversationid = conversationsSelected.aiconversationid;
              }

              let aiengineerid = "";
              if (aiEngineerSelected && aiEngineerSelected.aiengineerid && aiEngineerSelected.aiengineerid !== "") {
                aiengineerid = aiEngineerSelected.aiengineerid;
              }

              if (locationid !== "" && aiconversationid !== "" && aiengineerid !== "") {
                // const q = query(
                //   collection(db, "aicategories"),
                //   where("userid", "==", userId),
                //   where("superadmin", "==", false),
                //   // where("aisettingid", "==", aisettingid),
                //   orderBy("name", "asc")
                // );

                const q = query(
                  collection(db, "aiengineerconversations"),
                  where("locationid", "==", locationid),
                  where("aiconversationid", "==", aiconversationid),
                  where("aiengineerid", "==", aiengineerid)
                  //  orderBy("createdon", "desc")
                );

                let data = null;
                const unsub = onSnapshot(q, (querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    data = doc.data();
                    // const docData = doc.data();
                    // const smallData = {
                    //   ...docData,
                    //   // aiagentid: docData.aiagentid,
                    //   // name: docData.name,
                    // };
                    //docsArr.push(smallData);
                  });
                  if (data) {
                    setAiEngineerConversation(data);
                  } else {
                    setAiEngineerConversation(null);
                  }

                  // if (doc.messages && doc.messages.length > 0) {
                  //   aiConversationMessageCount.current = doc.messages.length;
                  // }
                  resolve({
                    data: data,
                  });
                });
              } else {
                // missing data
                setAiEngineerConversation(null);
                resolve({
                  error: "missing conversationsSelected and aiEngineerSelected data",
                });
              }
            } catch (err) {
              debugger;
              console.error(err);
              resolve({
                error: err,
              });
            }
          });
        },
        postAiEngineerMessage: async (userData, engineerId, conversationsSelected, messages, messageId) => {
          let returnData = {
            status: 200,
            data: {},
            error: {},
          };
          try {
            let locationid = "";
            if (userData && userData.locationId) {
              locationid = userData.locationId;
            }

            let aiconversationid = "";
            if (conversationsSelected && conversationsSelected.aiconversationid) {
              aiconversationid = conversationsSelected.aiconversationid;
            }

            let aiengineerid = "";
            if (engineerId && engineerId !== "") {
              aiengineerid = engineerId;
            }

            // "aiconversationid": st.aiconversationid,
            // "aiengineerid": st.aiengineerid,
            // "locationid": st. locationid,

            if (locationid !== "" && aiconversationid !== "" && aiengineerid !== "") {
              const data = {
                locationid: locationid,
                aiconversationid: aiconversationid,
                // aiengineerid: "0M3W7HsM7QjdWLXfqqAR",
                aiengineerid: aiengineerid,
                treeitemid: messageId,
                messages: messages,
              };

              // if (clog()) console.log('postEcomacyAiMessageFromApi: data:', data);
              let result = await postEcomacyAiEngineerMessageFromApi(data);
              returnData.status = result.status;

              if (result.status === 200 && result.data) {
                returnData.status = result.status;
                returnData.data = result.data;
              } else {
                returnData.status = result.status;
                returnData.error = result.error.code;
                // returnData.error = result.error.code;
              }
              // let result = {status: 'success'};
              return returnData;
            }
          } catch (err) {
            console.error(err);
            returnData.status = 501;
            returnData.error = {
              val: "Ecomacy customers data fetch failed",
            };
            // return returnData;
          }
        },
        aiEngineerConversationSelectDifferentQuestion: (type, engineerUpdated, message) => {
          // const test1 = engineerUpdated;
          // const test2 = message;
          const messageId = message.id;
          const messagePath = message.path;

          // clone engineerUpdated messages array
          const messages = [...engineerUpdated.messages];

          // find message in messages array
          const messageIndex = messages.findIndex((message) => message.id === messageId);

          let messageChoice = [];
          if (message.choice && message.choice.length > 0) {
            messageChoice = [...message.choice];
          }

          // TEMP DELETE
          // messageChoice = [0, 4, 8];

          // find messageId in messageChoice array
          const messageChoiceIndex = messageChoice.findIndex((choice) => choice === messagePath);

          let selectedMessageChoice = -1;
          let selectedMessage = messages[messageIndex];
          let selectedMessageContent = "";
          if (type === "previous") {
            // go to previous item in choices
            // get previous id in messageChoice array
            selectedMessageChoice = messageChoice[messageChoiceIndex - 1];
            selectedMessageContent = selectedMessage.question[messageChoiceIndex - 1];
          } else if (type === "next") {
            // go to next item in choices
            // get next id in messageChoice array
            selectedMessageChoice = messageChoice[messageChoiceIndex + 1];
            selectedMessageContent = selectedMessage.question[messageChoiceIndex + 1];
          }

          if (messageIndex > -1 && selectedMessageChoice > -1) {
            // update path in messages array at messageIndex
            messages[messageIndex].path = selectedMessageChoice;
            messages[messageIndex].content = selectedMessageContent;
          }

          return messages;
        },
        updateAiEngineerConversationMessages: async (userData, engineerConversationUpdated, messages) => {
          try {
            let aiEngineerConversationId = "";
            if (engineerConversationUpdated && engineerConversationUpdated.aiengineerconversationid) {
              aiEngineerConversationId = engineerConversationUpdated.aiengineerconversationid;
            }
            const updateData = {
              messages: messages,
            };

            const docRef = doc(db, "aiengineerconversations", aiEngineerConversationId);
            const result = await updateDoc(docRef, updateData);

            return { data: aiEngineerConversationId };
          } catch (err) {
            console.error(err);
            return {
              error: err,
            };
          }
        },
        saveAiAgentsNew: async (uData, name, automationLink, settingsData) => {
          let res = {};

          // with code from saveAiSettingsNewFromPreset
          let returnData = {};
          try {
            // add locationid
            let locationId = "";
            if (uData.locationId && uData.locationId !== "") {
              locationId = uData.locationId;
            }

            let settingsName = "";
            if (settingsData && settingsData.name) {
              settingsName = settingsData.name;
            }

            let userId = "";
            if (uData && uData.userId && uData.userId !== "") {
              userId = uData.userId;
            }

            // save aiagents doc
            // Get the collection reference
            const agentCollectionRef = collection(db, "aiagents");

            const aRef = doc(agentCollectionRef);
            // Get the new document Id
            const aiAgentId = aRef.id;


            // generate webtoken
            // let webtokenRes = await getAiWebCodeToken(uData, automationLink);
            let webtokenRes = await getAiWebCodeToken(uData, aiAgentId);

            // TEMP
            // webtokenRes.status = 200;
            // webtokenRes.data = "123456789abc"
            // if webtoken is generated
            if (webtokenRes && webtokenRes.status === 200 && webtokenRes.data) {
              const webtoken = webtokenRes.data;

              // generate a presetref
              const presetRef = doc(agentCollectionRef);
              // Get the new document Id
              const presetRefId = presetRef.id;

              const date = generateFirestoreDate();

              let newAgentData = {};

              // if (globaldeleteme && globaldeleteme === 1) {
              //   newAgentData.deleteme = globaldeleteme;
              // }

              // add aiagentid
              newAgentData.aiagentid = aiAgentId;

              // add presetref
              newAgentData.presetref = presetRefId;

              // add name
              newAgentData.name = name;

              // add automationlink
              newAgentData.automationlink = automationLink;

              // add createdon
              newAgentData.createdon = date;

              // add updatedon
              newAgentData.updatedon = date;

              // add locationid
              newAgentData.locationid = locationId;

              // generate aisettingid
              const docRefForAiSettings = doc(agentCollectionRef);
              // Get the new document Id
              const aiSettingId = docRefForAiSettings.id;

              // generate id for new aiLinkSettings doc
              const aiLinkSettingsRef = collection(db, "ailinksettings"); // collectionRef
              const linkSettingsRef = doc(aiLinkSettingsRef); // docRef
              // update to new id
              const aiLinkSettingId = linkSettingsRef.id;


              // add aisettings
              let newAiSetting = {
                aisettingid: aiSettingId,
                name: settingsName,
                automationlink: automationLink,
                archive: false,
                ailinksettingid: aiLinkSettingId,
              };

              let aiSettingsArr = [];

              // add new aisetting to aisettings array
              aiSettingsArr.push(newAiSetting);

              newAgentData.aisettings = aiSettingsArr;

              // add domains
              newAgentData.domains = [];

              // add webtoken
              newAgentData.webtoken = webtoken;

              // add archive
              newAgentData.archive = false;


              if (globaldeleteme && globaldeleteme > 0) {
                newAgentData.deleteme = globaldeleteme;
              }

              // Get a new write batch
              const batch = writeBatch(db);

              batch.set(aRef, newAgentData);

              // // generate id for new aiLinkSettings doc
              // const aiLinkSettingsRef = collection(db, "ailinksettings"); // collectionRef
              // const linkSettingsRef = doc(aiLinkSettingsRef); // docRef
              // // update to new id
              // const aiLinkSettingId = linkSettingsRef.id;

              // build new conversation
              const conversationRef = collection(db, "aiconversations");

              // Generate "locally" a new document for the given collection reference
              const conversationDocRef = doc(conversationRef);

              // Get the new document Id for aiConversationId
              const aiConversationId = conversationDocRef.id;



              let newConversationData = {};

              // if (globaldeleteme && globaldeleteme === 1) {
              //   newConversationData.deleteme = globaldeleteme;
              // }

              // add aiagentid
              newConversationData.aiagentid = aiAgentId;

              // add aiconversationid
              newConversationData.aiconversationid = aiConversationId;

              // add aisettingid
              newConversationData.aisettingid = aiSettingId;

              // add ailinksettingid
              newConversationData.ailinksettingid = aiLinkSettingId;

              // add automationlink
              // newConversationData.automationlink = automationLink;

              // add name
              newConversationData.name = settingsName;

              // set live to false
              newConversationData.live = false;

              // add archive
              newConversationData.archive = false;

              // add updatedon
              newConversationData.updatedon = date;

              // add createdon
              newConversationData.createdon = date;

              // add locationid
              newConversationData.locationid = locationId;

              // add userid
              newConversationData.userid = userId

              // process settings data
              let settingsUpdatedDataArray = _.cloneDeep(settingsData.aisettings);

              // loop over settingsData
              for (let i = 0; i < settingsUpdatedDataArray.length; i++) {
                const setting = settingsUpdatedDataArray[i];
                // generate aiSettingsIndexId
                // loop this for each aisetting in array
                // Generate "locally" a new document for the given collection reference
                const settingsindexidDocRef = doc(conversationRef);
                // Get the new document Id for aiConversationId
                const aiSettingsIndexId = settingsindexidDocRef.id;

                // add aisettingsindexid
                setting.aisettingsindexid = aiSettingsIndexId;

              }


              // add settingsDataJson
              // newConversationData.aisettings = settingsDataJson;
              newConversationData.aisettings = settingsUpdatedDataArray;


              delete newConversationData.messages

              debugger
              if (globaldeleteme && globaldeleteme > 0) {
                newConversationData.deleteme = globaldeleteme;
              }

              const cRef = doc(db, "aiconversations", aiConversationId);
              batch.set(cRef, newConversationData);



              /* //////////////// START: aipromptversion //////////////// */

              let promptVersions = [];
              let promptVersionIds = [];
              // loop over settingsUpdatedDataArray
              for (let i = 0; i < settingsUpdatedDataArray.length; i++) {
                const settingsUpdatedData = settingsUpdatedDataArray[i];

                const aiSettingsIndexId = settingsUpdatedData.aisettingsindexid;

                // const test = promptVersionSelected;

                let promptVersion = 1;

                let promptVersionName = "1";

                let promptVersionId = "";

                // let promptVersionCount = 1;

                let isNewPromptVersion = false;
                // if promptVersionId is empty then generate an id
                if (promptVersionId === "") {
                  isNewPromptVersion = true;
                  // Get the collection reference
                  const aipromptversionsRef = collection(db, "aipromptversions");

                  // Generate "locally" a new document for the given collection reference
                  const aipromptversionsDocRef = doc(aipromptversionsRef);

                  // Get the new document Id
                  promptVersionId = aipromptversionsDocRef.id;
                }

                // process aisettings for aipromptversion
                const processedPromptVersionSettingsData = processSettingsDataForPromptVersion(settingsUpdatedData);

                const processedPromptVersionDocData = {
                  aiconversationid: aiConversationId,
                  aipromptversionid: promptVersionId,
                  aisettingsindexid: aiSettingsIndexId,
                  aisettings: processedPromptVersionSettingsData,
                  locationid: locationId,
                  name: promptVersionName,
                  // promptversion: promptVersion,
                  updatedon: date,
                };

                if (isNewPromptVersion) {
                  // add createdon
                  processedPromptVersionDocData.createdon = date;

                  // add archive
                  processedPromptVersionDocData.archive = false;
                }

                if (globaldeleteme && globaldeleteme > 0) {
                  processedPromptVersionDocData.deleteme = globaldeleteme;
                }

                // update aipromptversions
                const pRef = doc(db, "aipromptversions", promptVersionId);
                /// batch.set(lRef, processedDocData);
                if (isNewPromptVersion) {
                  batch.set(pRef, processedPromptVersionDocData);
                } else {
                  batch.update(pRef, processedPromptVersionDocData);
                }

                promptVersionIds.push(promptVersionId);

                // use this to check values are correct
                promptVersions.push(processedPromptVersionDocData);


              }
              /* //////////////// END: aipromptversion //////////////// */



              const processedLinkSettingsDocData = processSettingsDataArrayForLinkSettings(settingsUpdatedDataArray);

              // aiagentid
              processedLinkSettingsDocData.aiagentid = aiAgentId;
              // locationid
              processedLinkSettingsDocData.locationid = locationId;
              // name
              processedLinkSettingsDocData.name = newConversationData.name;
              // userid
              processedLinkSettingsDocData.userid = userId;

              // add conversation id to linksettings
              processedLinkSettingsDocData.aiconversationid = aiConversationId;

              // add doc id
              processedLinkSettingsDocData.ailinksettingid = aiLinkSettingId;


              // add automationlink if automationlink matches
              let aiAgentSettingsLinkSettingsId = "";
              if (
                newAgentData &&
                newAgentData.aisettings &&
                newAgentData.aisettings[0] &&
                newAgentData.aisettings[0].ailinksettingid &&
                newAgentData.aisettings[0].ailinksettingid !== ""
              ) {
                aiAgentSettingsLinkSettingsId = newAgentData.aisettings[0].ailinksettingid;
              }

              let aiLinkSettingsAutomationLink = "";
              let aiLinkSettingsWebChatLink = "";
              if (
                automationLink !== "" &&
                aiLinkSettingId === aiAgentSettingsLinkSettingsId
              ) {
                aiLinkSettingsAutomationLink = newAgentData.automationlink;
                aiLinkSettingsWebChatLink = newAgentData.aiagentid;
              }

              // add webchatlink regardless as this is  new linksettings
              // aiLinkSettingsWebChatLink = newAgentData.aiagentid;

              processedLinkSettingsDocData.automationlink = aiLinkSettingsAutomationLink;
              processedLinkSettingsDocData.webchatlink = aiLinkSettingsWebChatLink;

              // add createdon
              processedLinkSettingsDocData.createdon = date;

              // add updatedon
              processedLinkSettingsDocData.updatedon = date;

              // add inuse
              processedLinkSettingsDocData.inuse = false;

              // add archive
              processedLinkSettingsDocData.archive = false;

              if (globaldeleteme && globaldeleteme > 0) {
                processedLinkSettingsDocData.deleteme = globaldeleteme;
              }

              const lsRef = doc(db, "ailinksettings", aiLinkSettingId);
              batch.set(lsRef, processedLinkSettingsDocData);

              if (clog()) console.log("aiAgentId", aiAgentId);
              if (clog()) console.log("aiSettingId", aiSettingId);
              if (clog()) console.log("aiConversationId", aiConversationId);
              if (clog()) console.log("aiLinkSettingId", aiLinkSettingId);

              // use this to check data is correct
              const aiAgentDoc = newAgentData
              const aiConversationsDoc = newConversationData
              const aiPromptVersionsDocs = promptVersions
              const aiLinkSettingsDoc = processedLinkSettingsDocData

              // commit the batch
              res = await batch.commit();

              returnData.data = {
                aiagentid: aiAgentId,
                aisettingid: aiSettingId,
                aiconversationid: aiConversationId,
                aipromptversionids: promptVersionIds,
                ailinksettingid: aiLinkSettingId,
              };

              // returnData.data = { aiagentid: id };
            } else {
              returnData.error = webtokenRes.error;
            }

            return returnData;
          } catch (err) {
            console.error(err);
            returnData.error = err;
            return returnData;
          }
        },
        saveAiAgentsName: async (id, name) => {
          let returnData = {};
          try {
            // generate date for updatedon
            const date = generateFirestoreDate();

            // Get the document reference
            const docRef = doc(db, "aiagents", id);

            // update aiagents doc
            const result = await updateDoc(docRef, {
              name: name,
              updatedon: date,
            });

            returnData.data = { aiagentid: id };
            return returnData;
          } catch (err) {
            console.error(err);
            returnData.error = err;
            return returnData;
          }
        },
        saveAiAgentsAutomationLink: async (aiagentid, automationlink) => {
          let returnData = {
            data: {},
            error: {},
          };
          try {
            // generate date for updatedon
            const date = generateFirestoreDate();


            // 1:
            // use aiAgentsSelected to find ailinksettings doc
            //// aiagent.aisettings[0]ailinksettingid

            let ailinksettingid = "";
            if (aiAgentsSelected && aiAgentsSelected.aisettings && aiAgentsSelected.aisettings[0] && aiAgentsSelected.aisettings[0].ailinksettingid) {
              ailinksettingid = aiAgentsSelected.aisettings[0].ailinksettingid;
            }

            let aisettingsArray = [];
            if (aiAgentsSelected && aiAgentsSelected.aisettings) {
              aisettingsArray = aiAgentsSelected.aisettings;
            }

            if (ailinksettingid === "") {
              return returnData.error = {
                val: "ailinksettingid is missing",
              };
            }

            // 2:
            // use ailinksettingid to find ailinksettings doc
            // Get the ailinksettings document reference
            const ailinksettingsDocRef = doc(db, "ailinksettings", ailinksettingid);
            const ailinksettingsDocSnap = await getDoc(ailinksettingsDocRef);
            let ailinksettingsDocData = {};
            if (ailinksettingsDocSnap.exists()) {
              ailinksettingsDocData = ailinksettingsDocSnap.data();
            }

            // // 3:
            // // use ailinksettingsDocData to find aiconversationid
            // let aiconversationid = "";
            // if (ailinksettingsDocData && ailinksettingsDocData.aiconversationid) {
            //   aiconversationid = ailinksettingsDocData.aiconversationid;
            // }

            // if (aiconversationid === "") {
            //   return returnData.error = {
            //     val: "aiconversationid is missing",
            //   };
            // }

            // 4:
            // update aiagent
            //// aiagent.automationlink
            //// aiagent.aisettings[0].automationlink

            // Get the aiagents document reference
            const aiagentsDocRef = doc(db, "aiagents", aiagentid);

            // update aisettings array
            aisettingsArray[0].automationlink = automationlink;

            // update aiagents doc
            const aiagentsDocResult = await updateDoc(aiagentsDocRef, {
              automationlink: automationlink,
              aisettings: aisettingsArray,
              updatedon: date,
            });
            returnData.data = {
              ...returnData.data,
              aiagentid: aiagentid
            };
            // 5:
            // update ailinksettings
            //// ailinksetting.data.automationlink

            // update ailinksettings doc
            const ailinksettingsDocResult = await updateDoc(ailinksettingsDocRef, {
              automationlink: automationlink,
              updatedon: date,
            });
            returnData.data = {
              ...returnData.data,
              ailinksettingid: ailinksettingid,
            };

            // // 6:
            // // update aiconversation = ailinksettings.aiconversationid
            // //// aiconversation.aisettings.data.automationlink
            // const aiconversationsDocRef = doc(db, "aiconversations", aiconversationid);

            // // update aiconversations doc
            // const aiconversationsDocResult = await updateDoc(aiconversationsDocRef, {
            //   automationlink: automationlink,
            //   "aisettings.data.automationlink": automationlink,
            //   updatedon: date,
            // });
            // returnData.data = {
            //   ...returnData.data,
            //   aiconversationid: aiconversationid,
            // };

            // // 7:
            // // find aipromptversions = ailinksettings.aiconversationid
            // //// aipromptversion.aisettings.data.automationlink
            // const qpv = query(collection(
            //   db, "aipromptversions"),
            //   where("aiconversationid", "==", aiconversationid)
            // );

            // const snapshotPromptVersions = await getDocs(qpv);
            // if (snapshotPromptVersions.empty) {
            //   const err = {
            //     val: "no promptversion Docs found",
            //   };
            // }

            // let promptversionsDocs = [];
            // snapshotPromptVersions.forEach((doc) => {
            //   // doc.data() is never undefined for query doc snapshots
            //   // if (clog()) console.log(doc.id, " => ", doc.data());
            //   // const docData = doc.data();
            //   promptversionsDocs.push(doc.data());
            // });

            // let promptVersionsProcessed = [];
            // for (const pvDoc of promptversionsDocs) {
            //   const id = pvDoc.aipromptversionid;
            //   // wait X milliseconds
            //   await delay(100);
            //   // Get the document reference
            //   const promptversionDocRef = doc(db, "aipromptversions", id);
            //   // update aipromptversions doc
            //   const aipromptversionDocResult = await updateDoc(promptversionDocRef, {
            //     "aisettings.data.automationlink": automationlink,
            //     updatedon: date,
            //   });

            //   promptVersionsProcessed.push(id);
            // }

            // returnData.data = {
            //   ...returnData.data,
            //   aipromptversionids: promptVersionsProcessed,
            // };

            // returnData.data = { aiagentid: id };
            return returnData;
          } catch (err) {
            console.error(err);
            returnData.error = err;
            return returnData;
          }
        },
        saveAiAgentsInputs: async (id, inputs) => {
          let returnData = {};
          try {
            // generate date for updatedon
            const date = generateFirestoreDate();

            // Get the document reference
            const docRef = doc(db, "aiagents", id);

            // update aiagents doc
            const result = await updateDoc(docRef, {
              inputs: inputs,
              updatedon: date,
            });

            returnData.data = { aiagentid: id };
            return returnData;
          } catch (err) {
            console.error(err);
            returnData.error = err;
            return returnData;
          }
        },
        saveAiAgentsDomains: async (id, domains) => {
          let returnData = {};
          try {
            // generate date for updatedon
            const date = generateFirestoreDate();

            // Get the document reference
            const docRef = doc(db, "aiagents", id);

            // update aiagents doc
            const result = await updateDoc(docRef, {
              domains: domains,
              updatedon: date,
            });

            returnData.data = { aiagentid: id };
            return returnData;
          } catch (err) {
            console.error(err);
            returnData.error = err;
            return returnData;
          }
        },
        saveAiSettingsName: async (id, name) => {
          let returnData = {};
          try {
            //  // Get the document reference
            //  const docRef = doc(db, "aisettings", id);

            //  // Update the document
            //  const result = await updateDoc(docRef, {
            //    "data.name": name,
            //  });

            let aiSettingId = "";
            if (id) {
              aiSettingId = id;
            }

            if (aiSettingId !== "") {
              let aiAgentId = "";
              let aiSettingsArr = null;
              if (aiAgentsSelected) {
                if (aiAgentsSelected.aiagentid && aiAgentsSelected.aiagentid !== "") {
                  aiAgentId = aiAgentsSelected.aiagentid;
                }

                if (aiAgentsSelected.aisettings) {
                  aiSettingsArr = aiAgentsSelected.aisettings;
                }

                if (aiAgentId !== "" && aiSettingsArr !== null) {
                  // find the index of the ai settings
                  const index = aiSettingsArr.findIndex((x) => x.aisettingid === id);
                  if (index !== -1) {
                    // generate date for updatedon
                    const date = generateFirestoreDate();
                    let newAiSettingsArr = [...aiSettingsArr];
                    // update found index with new name
                    newAiSettingsArr[index].name = name;

                    /// Get the document reference
                    const docRef = doc(db, "aiagents", aiAgentId);

                    // update aiagents doc
                    const result = await updateDoc(docRef, {
                      aisettings: newAiSettingsArr,
                      updatedon: date,
                    });

                    returnData.data = {
                      index: index,
                      settingsDocs: newAiSettingsArr,
                    };
                  } else {
                    returnData.error = {
                      val: "Ai settings data is missing",
                    };
                  }
                } else {
                  returnData.error = {
                    val: "Ai agent id or ai settings array is missing",
                  };
                }
              }
            }

            return returnData;
          } catch (err) {
            console.error(err);
            returnData.error = err;
            return returnData;
          }
        },
        // saveAiSettingsAutomationLinkOLD: async (uData) => {
        //   let res = {};
        //   let procData = {
        //     data: {
        //       aiagentid: "",
        //       aisettingid: "",
        //       aiconversationid: "",
        //       automationlink: "",
        //       ailinksettingid: "",
        //       ailinksettingidprev: "",
        //     },
        //     aiAgent: "",
        //     aiLinkSettingsNotUsed: "",
        //     aiLinkSettingsUsed: "",
        //     aiConversationsOld: "",
        //     aiConversationsNew: "",
        //     res: null,
        //   };

        //   let returnData = {};

        //   try {
        //     let locationId = "";
        //     if (uData && uData.locationId && uData.locationId !== "") {
        //       locationId = uData.locationId;
        //     }

        //     if (locationId === "") {
        //       return {
        //         error: {
        //           val: "Location id is missing",
        //         },
        //       };
        //     }

        //     // const test = aiAgentsSelected;
        //     // const test1 = aiConversation;
        //     // const test2 = aiSettingsDataUpdated; // <- settings data
        //     // const test3 = aiSettingsSelected; // <- settings data

        //     let aiAgentId = "";
        //     let automationLink = "";
        //     let aiSettingsArr = [];

        //     let saveAiAgent = false;

        //     // generate date for updatedon
        //     const date = generateFirestoreDate();

        //     if (aiAgentsSelected) {
        //       if (aiAgentsSelected.aiagentid && aiAgentsSelected.aiagentid !== "") {
        //         aiAgentId = aiAgentsSelected.aiagentid;
        //         // add to return data
        //         procData.data.aiagentid = aiAgentId;
        //       }
        //       if (aiAgentsSelected.automationlink && aiAgentsSelected.automationlink !== "") {
        //         automationLink = aiAgentsSelected.automationlink;
        //         // add to return data
        //         procData.data.automationlink = automationLink;
        //       }
        //       if (aiAgentsSelected.aisettings && aiAgentsSelected.aisettings !== "") {
        //         aiSettingsArr = aiAgentsSelected.aisettings;
        //       }
        //     }

        //     let aiSettingId = "";
        //     if (aiSettingsSelected && aiSettingsSelected.aisettingid && aiSettingsSelected.aisettingid !== "") {
        //       aiSettingId = aiSettingsSelected.aisettingid;
        //       // add to return data
        //       procData.data.aisettingid = aiSettingId;
        //     }

        //     let aiLinkSettingIdCurrent = "";
        //     if (aiSettingsSelected && aiSettingsSelected.ailinksettingid && aiSettingsSelected.ailinksettingid !== "") {
        //       aiLinkSettingIdCurrent = aiSettingsSelected.ailinksettingid;
        //       // add to return data
        //       procData.data.ailinksettingidprev = aiLinkSettingIdCurrent;
        //     }


        //     let aiConversationId = "";
        //     if (aiConversation?.aiconversationid !== "") {
        //       aiConversationId = aiConversation.aiconversationid;
        //       // add to return data
        //       procData.data.aiconversationid = aiConversationId;
        //     }

        //     let aiConversationPromptVersion = -1;
        //     if (aiConversation?.promptversion) {
        //       aiConversationPromptVersion = aiConversation.promptversion;
        //     }

        //     // let aiConversationPromptVersionBasedOn = 0;
        //     // if (aiConversation?.promptversionbasedon) {
        //     //   aiConversationPromptVersionBasedOn = aiConversation.promptversionbasedon;
        //     // }


        //     // let aiLinkSettingsDocData = null;
        //     let aiLinkSettingId = "";

        //     if (automationLink !== "") {

        //       //// Step 1:  save aiconversations docs - old
        //       // find all aiconversations with matching automationlink and update
        //       const fucResult = await findUpdateAiConversationsNotLive(locationId, automationLink);
        //       if (fucResult && fucResult.data) {
        //         procData.aiConversationsOld = "processed";
        //       }
        //       ///  let aiConversationDocs = [];
        //       ///  const acsResult = await getAiConversationDocsNoListen(locationId, automationLink);
        //       ///  if (acsResult && acsResult.data) {
        //       ///    aiConversationDocs = acsResult.data;
        //       ///  } else if (acsResult.error) {
        //       ///    console.error("No aiconversations docs found with automationlink:", acsResult.error);
        //       ///  }
        //       ///
        //       ///  if (aiConversationDocs.length > 0) {
        //       ///    // loop over aiConversationDocs
        //       ///    for (let i = 0; i < aiConversationDocs.length; i++) {
        //       ///      const cdoc = aiConversationDocs[i];
        //       ///      // update automationlink to ''
        //       ///      // update ailinksettings to ''
        //       ///      const acsRef = doc(db, "aiconversations", cdoc.aiconversationid);
        //       ///      batch.update(acsRef, {
        //       ///        automationlink: "",
        //       ///        ailinksettingid: "",
        //       ///        updatedon: date,
        //       ///      });
        //       ///
        //       ///      procData.aiConversationsOld = "processed";
        //       ///    }
        //       ///  }

        //       // START OLD: get ailinksettings doc by data.automationlink
        //       // const lsResult = await getAiLinkSettingsDocNoListen(automationLink);
        //       // if (lsResult && lsResult.data) {
        //       //   aiLinkSettingsDocData = lsResult.data;
        //       //   if (aiLinkSettingsDocData.ailinksettingid && aiLinkSettingsDocData.ailinksettingid !== "") {
        //       //     aiLinkSettingId = aiLinkSettingsDocData.ailinksettingid;
        //       //   }
        //       // }
        //       // END OLD: get ailinksettings doc by data.automationlink

        //       // START NEW: get ailinksettings doc by data.automationlink
        //       // Step 2: find and delete all ailinksettings docs that are not used
        //


        //       /******************* START ********************/
        //       // use aiLinkSettingIdCurrent to see if any ailiveconversations exist
        //       // yes: aiLinkSettingIdCurrent is in use so remove automationlink from ailinksettings doc
        //       // no: aiLinkSettingIdCurrent is not in use so delete ailinksettings doc
        //       const flsResult = await findAiLinkSettingsLiveConversations(locationId, aiLinkSettingIdCurrent);
        //       // ailiveconversations exist - update doc
        //       if (flsResult?.data === 1) {
        //         const ulsResult = await updateAiLinkSettingsDoc(locationId, aiLinkSettingIdCurrent);
        //         if (ulsResult && ulsResult.data) {
        //           procData.aiLinkSettingsUsed = "processed";
        //           procData.aiLinkSettingsNotUsed = "not needed";
        //         }
        //       }

        //       // ailiveconversations don't exist - delete doc
        //       else if (flsResult?.data === 0) {
        //         const dlsResult = await deleteAiLinkSettingsDoc(locationId, aiLinkSettingIdCurrent);
        //         if (dlsResult && dlsResult.data) {
        //           procData.aiLinkSettingsUsed = "not needed";
        //           procData.aiLinkSettingsNotUsed = "processed";
        //         }
        //       }
        //       else {
        //         // error occured
        //         procData.aiLinkSettingsUsed = "error occured";
        //         procData.aiLinkSettingsNotUsed = "processed";
        //       }
        //       // Switch back to previous ailinksettings doc if nothing has changed
        //       // Need extra tests. Check Github for notes on this
        //       //// additional search
        //       // find ailinksettings doc with:
        //       //		locationid: locationid
        //       //		aiconversationid (aiConversation.aiconversationid)
        //       //		promptversion (aiConversation.promptversion)

        //       // do settings match with aiSettingsDataUpdated?
        //       // yes: use that doc id
        //       // no: generate new doc id
        //       // const flsResult = await findAiLinkSettingsLiveConversations(locationId, aiLinkSettingIdCurrent);
        //       let foundAiLinkSettingsDoc = false;
        //       const flspvResult = await findAiLinkSettingsPromptVersion(locationId, aiConversationId, aiConversationPromptVersion);
        //       if (flspvResult?.data.found) {
        //         // TODO: saveAiSettingsAutomationLink does data match??
        //         // const compareRes = compareAiSettingsData(flspvResult.data.data, aiSettingsDataUpdated);

        //         // set linksettings doc id
        //         if (flspvResult.data.data?.ailinksettingid !== '') {
        //           foundAiLinkSettingsDoc = true;
        //           aiLinkSettingId = flspvResult.data.data.ailinksettingid;
        //         }
        //       }

        //       // if a previous linksettings doc was not found during findAiLinkSettingsPromptVersion
        //       // then create a new ailinksettingsid
        //       if (!foundAiLinkSettingsDoc) {
        //         // generate id for new aiLinkSettings doc
        //         const aiLinkSettingsRef = collection(db, "ailinksettings"); // collectionRef
        //         const linkSettingsRef = doc(aiLinkSettingsRef); // docRef
        //         // update to new id
        //         aiLinkSettingId = linkSettingsRef.id;
        //         // END NEW: get ailinksettings doc by data.automationlink
        //       }

        //       /******************** END *********************/

        //     }

        //     if (aiAgentId !== "" && automationLink !== "" && aiSettingId !== "" && aiConversationId !== "" && aiLinkSettingId !== "") {
        //       // all necessary data exists, proceed with save


        //       // TODO DONE Update ailinksettingid in aiSettingsArr using aiLinkSettingId
        //       let aiSettingsArrNew = [...aiSettingsArr];

        //       // NEW: aiagents doc
        //       // add aiLinkSettingsid to aiSettingsArrNew
        //       aiSettingsArrNew[0].ailinksettingid = aiLinkSettingId;
        //       saveAiAgent = true;

        //       // OLD: aiagents doc
        //       // search aisettings array and find index containing automationlink === 'link_asa'
        //       // const linkIndexCurrent = _.findIndex(aiSettingsArr, { automationlink: automationLink });

        //       // // search aisettings array and find index containing aisettingid
        //       // const linkIndexNew = _.findIndex(aiSettingsArr, { aisettingid: aiSettingId });
        //       // if (linkIndexNew > -1 && linkIndexCurrent !== linkIndexNew) {
        //       //   if (linkIndexCurrent > -1) {
        //       //     // update old index if it exists
        //       //     aiSettingsArrNew[linkIndexCurrent].automationlink = "";
        //       //   }
        //       //   // if indexes don't match then process
        //       //   // update new index
        //       //   aiSettingsArrNew[linkIndexNew].automationlink = automationLink;

        //       //   saveAiAgent = true;
        //       // }

        //       // Get a new write batch
        //       const batch = writeBatch(db);

        //       //// save aiagent
        //       if (saveAiAgent) {
        //         // save aisettings arr into aiagent
        //         const aRef = doc(db, "aiagents", aiAgentId);
        //         batch.update(aRef, {
        //           aisettings: aiSettingsArrNew,
        //           updatedon: date,
        //         });

        //         procData.aiAgent = "processed";
        //       } else {
        //         procData.aiAgent = "not needed";
        //       }

        //       //// Step 4: save ailinksettings
        //       const processedDocData = processSettingsDataForLinkSettings(aiSettingsDataUpdated);

        //       // add aiconversationid
        //       processedDocData.aiconversationid = aiConversationId;

        //       // add automationlink
        //       processedDocData.data.automationlink = automationLink;

        //       // add updatedon
        //       processedDocData.createdon = date;

        //       // add updatedon
        //       processedDocData.updatedon = date;

        //       // add ailinksettingid
        //       processedDocData.ailinksettingid = aiLinkSettingId;

        //       // add inuse
        //       processedDocData.inuse = false;

        //       // add archive
        //       processedDocData.archive = false;

        //       // add promptversion from aiconversation
        //       processedDocData.promptversion = aiConversationPromptVersion;

        //       // add promptversion from aiconversation
        //       // processedDocData.promptversionbasedon = aiConversationPromptVersionBasedOn;


        //       if (globaldeleteme && globaldeleteme === 1) {
        //         processedDocData.deleteme = 1;
        //       }
        //       debugger
        //       // remove version and versionbasedon
        //       // delete processedDocData.version;
        //       // delete processedDocData.versionbasedon;
        //
        //       // update linksettings
        //       const lRef = doc(db, "ailinksettings", aiLinkSettingId);
        //       batch.set(lRef, processedDocData);

        //       procData.aiLinkSettings = "processed";

        //       //// Step 5:  save aiconversations doc - new - use aiConversationId
        //       // add ailinksettingid = aiLinkSettingId
        //       // add automationlink = automationLink
        //
        //       const acRef = doc(db, "aiconversations", aiConversationId);
        //       batch.update(acRef, {
        //         automationlink: automationLink,
        //         ailinksettingid: aiLinkSettingId,
        //         updatedon: date,
        //       });

        //       procData.aiConversationsNew = "processed";

        //       // commit the batch
        //       res = await batch.commit();

        //       // add to return data
        //       procData.data.ailinksettingid = aiLinkSettingId;

        //       procData.res = res;
        //       returnData.data = procData;
        //     } else {
        //       console.warn("saveAiSettingsAutomationLink missing data:", {
        //         aiAgentId: aiAgentId,
        //         automationLink: automationLink,
        //         aiSettingId: aiSettingId,
        //         aiConversationId: aiConversationId,
        //         aiLinkSettingId: aiLinkSettingId,
        //       });
        //       returnData.error = procData;
        //     }

        //     return returnData;
        //   } catch (err) {
        //     console.error(err);
        //     returnData.error = err;
        //     return returnData;
        //   }
        // },
        // saveAiSettingsAutomationLinkOLD: async (uData) => {
        //   let res = {};
        //   let procData = {
        //     data: {
        //       aiagentid: "",
        //       automationlink: "",
        //       aisettingid: "",
        //       aiconversationid: "",
        //       aiconversationidprev: [],
        //       // aipromptversionid: [],
        //       // aipromptversionidprev: [],
        //       ailinksettingid: "",
        //       ailinksettingidprev: "",
        //       aiagentaisettings: [],
        //       aiagentaisettingsprev: []
        //     },
        //     aiagentid: "",
        //     res: null,
        //   };

        //   let returnData = {};

        //   try {
        //     let locationId = "";
        //     if (uData && uData.locationId && uData.locationId !== "") {
        //       locationId = uData.locationId;
        //     }

        //     if (locationId === "") {
        //       return {
        //         error: {
        //           val: "Location id is missing",
        //         },
        //       };
        //     }

        //     // const test = aiAgentsSelected;
        //     // const test1 = aiConversation;
        //     // const test2 = aiSettingsDataUpdated; // <- settings data
        //     // const test3 = aiSettingsSelected; // <- settings data

        //     let aiAgentId = "";
        //     let automationLink = "";
        //     let aiSettingsArr = [];

        //     // let saveAiAgent = false;

        //     // generate date for updatedon
        //     const date = generateFirestoreDate();

        //     if (aiAgentsSelected) {
        //       if (aiAgentsSelected.aiagentid && aiAgentsSelected.aiagentid !== "") {
        //         aiAgentId = aiAgentsSelected.aiagentid;
        //         // add to return data
        //         procData.aiagentid = aiAgentId;
        //         procData.data.aiagentid = aiAgentId;
        //       }
        //       if (aiAgentsSelected.automationlink && aiAgentsSelected.automationlink !== "") {
        //         automationLink = aiAgentsSelected.automationlink;
        //         // add to return data
        //         procData.data.automationlink = automationLink;
        //       }
        //       if (aiAgentsSelected.aisettings && aiAgentsSelected.aisettings !== "") {
        //         aiSettingsArr = aiAgentsSelected.aisettings;
        //         // add to return data
        //         procData.data.aiagentaisettingsprev = aiSettingsArr;
        //       }
        //     }

        //     let aiSettingId = "";
        //     if (aiSettingsSelected && aiSettingsSelected.aisettingid && aiSettingsSelected.aisettingid !== "") {
        //       aiSettingId = aiSettingsSelected.aisettingid;
        //       // add to return data
        //       procData.data.aisettingid = aiSettingId;
        //     }

        //     let aiLinkSettingIdCurrent = "";
        //     if (aiSettingsSelected && aiSettingsSelected.ailinksettingid && aiSettingsSelected.ailinksettingid !== "") {
        //       aiLinkSettingIdCurrent = aiSettingsSelected.ailinksettingid;
        //     }

        //     if (clog()) console.log("aiLinkSettingIdCurrent Old", aiLinkSettingIdCurrent);

        //     let aiConversationId = "";
        //     if (aiConversation?.aiconversationid !== "") {
        //       aiConversationId = aiConversation.aiconversationid;
        //     }

        //     if (clog()) console.log("aiConversationId New", aiConversationId);

        //     let aiConversationPromptVersion = -1;
        //     if (aiConversation?.promptversion) {
        //       aiConversationPromptVersion = aiConversation.promptversion;
        //     }

        //     // let aiConversationPromptVersionBasedOn = 0;
        //     // if (aiConversation?.promptversionbasedon) {
        //     //   aiConversationPromptVersionBasedOn = aiConversation.promptversionbasedon;
        //     // }


        //     let aiLinkSettingId = "";
        //     if (aiConversation?.ailinksettingid !== "") {
        //       aiLinkSettingId = aiConversation.ailinksettingid;
        //     }
        //     if (clog()) console.log("XXXXXXX ailinksettingid NEW", aiLinkSettingId);
        //     debugger
        //     if (
        //       aiAgentId !== "" &&
        //       automationLink !== "" &&
        //       aiSettingId !== "" &&
        //       aiConversationId !== "" &&
        //       aiLinkSettingId !== "" &&
        //       aiLinkSettingIdCurrent !== ""
        //     ) {

        //       // Get a new write batch
        //       const batch = writeBatch(db);
        //       debugger
        //       /* /////////////// START: Update old aiconversations //////////////////////// */
        //       //// update all old aiconversations docs found with automationlink
        //       // remove automationlink
        //       let conversationsDocs = [];
        //       const qa = query(
        //         collection(db, "aiconversations"),
        //         where("locationid", "==", locationId),
        //         where("automationlink", "==", automationLink),
        //         // where("ailinksettingid", "==", aiLinkSettingIdCurrent),
        //         where("live", "==", false)
        //       );

        //       const conversationSnapshot = await getDocs(qa);
        //       if (conversationSnapshot.empty) {
        //         return { data: "no docs found" };
        //       }
        //       conversationSnapshot.forEach((doc) => {
        //         conversationsDocs.push(doc.data());
        //       });
        //       for (const cDoc of conversationsDocs) {
        //         const id = cDoc.aiconversationid;
        //         // wait X milliseconds
        //         // await delay(100);
        //         const conversationPrevDocRef = doc(db, "aiconversations", id);

        //         // const res = await updateDoc(docRef, data);
        //         batch.update(conversationPrevDocRef, {
        //           automationlink: "",
        //           "aisettings.data.automationlink": "",
        //           // ailinksettingid: "",
        //           updatedon: date,
        //         })
        //         // add to return data
        //         procData.data.aiconversationidprev.push(id);
        //       }

        //       /* /////////////// END: Update old aiconversations //////////////////////// */


        //       /* /////////////// START: Update old aipromptversions //////////////////////// */
        //       //// update all old aipromptversion docs found with automationlink
        //       // remove automationlink
        //       // for (const cDoc of conversationsDocs) {
        //       //   const conId = cDoc.aiconversationid;

        //       //   // find related promptversion docs
        //       //   let promptversionPrevDocs = [];
        //       //   const qa = query(
        //       //     collection(db, "aipromptversions"),
        //       //     where("locationid", "==", locationId),
        //       //     where("aiconversationid", "==", conId),
        //       //   );

        //       //   const promptversionPrevSnapshot = await getDocs(qa);
        //       //   if (promptversionPrevSnapshot.empty) {
        //       //     return { data: "no docs found" };
        //       //   }
        //       //   promptversionPrevSnapshot.forEach((doc) => {
        //       //     promptversionPrevDocs.push(doc.data());
        //       //   });

        //       //   for (const pDoc of promptversionPrevDocs) {
        //       //     const proId = pDoc.aipromptversionid;
        //       //     // wait X milliseconds
        //       //     // await delay(100);
        //       //     const promptversionPrevDocRef = doc(db, "aipromptversions", proId);

        //       //     // const res = await updateDoc(docRef, data);
        //       //     batch.update(promptversionPrevDocRef, {
        //       //       // automationlink: "",
        //       //       "data.automationlink": "",
        //       //       // ailinksettingid: "",
        //       //       updatedon: date,
        //       //     })
        //       //     // add to return data
        //       //     procData.data.aipromptversionidprev.push(proId);
        //       //   }
        //       // }
        //       /* /////////////// END: Update old aipromptversions //////////////////////// */


        //       /* /////////////// START: Update old ailinksettings //////////////////////// */
        //       //// update old ailinksettings doc
        //       // remove automationlink
        //       const linksettingPrevDocRef = doc(db, "ailinksettings", aiLinkSettingIdCurrent);
        //       batch.update(linksettingPrevDocRef, {
        //         "data.automationlink": "",
        //         updatedon: date,
        //       });
        //       // add to return data
        //       procData.data.ailinksettingidprev = aiLinkSettingIdCurrent;

        //       /* /////////////// END: Update old ailinksettings //////////////////////// */


        //       /* /////////////// START: Update new aiconversations //////////////////////// */

        //       //// update new aiconversations doc
        //       // add automationlink
        //       const conversationDocRef = doc(db, "aiconversations", aiConversationId);
        //       batch.update(conversationDocRef, {
        //         automationlink: automationLink,
        //         "aisettings.data.automationlink": automationLink,
        //         updatedon: date,
        //       });
        //       // add to return data
        //       procData.data.aiconversationid = aiConversationId;

        //       /* /////////////// END: Update new aiconversations //////////////////////// */


        //       /* /////////////// START: Update new aipromptversions //////////////////////// */
        //       //// update all old aipromptversion docs found with automationlink
        //       // remove automationlink
        //       // find related promptversion docs
        //       // let promptversionDocs = [];
        //       // const qpv = query(
        //       //   collection(db, "aipromptversions"),
        //       //   where("locationid", "==", locationId),
        //       //   where("aiconversationid", "==", aiConversationId),
        //       // );

        //       // const promptversionSnapshot = await getDocs(qpv);
        //       // if (promptversionSnapshot.empty) {
        //       //   return { data: "no docs found" };
        //       // }
        //       // promptversionSnapshot.forEach((doc) => {
        //       //   promptversionDocs.push(doc.data());
        //       // });

        //       // for (const pDoc of promptversionDocs) {
        //       //   const proId = pDoc.aipromptversionid;
        //       //   // wait X milliseconds
        //       //   // await delay(100);
        //       //   const promptversionDocRef = doc(db, "aipromptversions", proId);

        //       //   // const res = await updateDoc(docRef, data);
        //       //   batch.update(promptversionDocRef, {
        //       //     // automationlink: "",
        //       //     "data.automationlink": automationLink,
        //       //     // ailinksettingid: "",
        //       //     updatedon: date,
        //       //   })
        //       //   // add to return data
        //       //   procData.data.aipromptversionid.push(proId);
        //       // }
        //       /* /////////////// END: Update new aipromptversions //////////////////////// */


        //       /* /////////////// START: Update new ailinksettings //////////////////////// */

        //       //// update new ailinksettings doc
        //       // add automationlink
        //       // add automationlink
        //       const linksettingDocRef = doc(db, "ailinksettings", aiLinkSettingId);
        //       batch.update(linksettingDocRef, {
        //         "data.automationlink": automationLink,
        //         updatedon: date,
        //       });
        //       // add to return data
        //       procData.data.ailinksettingid = aiLinkSettingId;

        //       /* /////////////// END: Update new ailinksettings //////////////////////// */


        //       /* /////////////// START: Update aiagent aisettings //////////////////////// */

        //       //// update aiagent aisettings
        //       // Update ailinksettingid in aiSettingsArr using aiLinkSettingId
        //       // let aiSettingsArrNew = [...aiSettingsArr];
        //       let aiSettingsArrNew = _.cloneDeep(aiSettingsArr);

        //       // add aiLinkSettingsid to aiSettingsArrNew
        //       aiSettingsArrNew[0].ailinksettingid = aiLinkSettingId;

        //       //// save aiagent
        //       // save aisettings arr into aiagent
        //       // update aiagents doc
        //       const agentRef = doc(db, "aiagents", aiAgentId);
        //       batch.update(agentRef, {
        //         aisettings: aiSettingsArrNew,
        //         updatedon: date,
        //       });
        //       // add to return data
        //       procData.data.aiagentaisettings = aiSettingsArrNew;

        //       /* /////////////// END: Update aiagent aisettings //////////////////////// */

        //       // commit the batch
        //       // res = await batch.commit();
        //       returnData.data = procData;

        //     } else {
        //       console.warn("saveAiSettingsAutomationLink missing data:", {
        //         aiAgentId: aiAgentId,
        //         automationLink: automationLink,
        //         aiSettingId: aiSettingId,
        //         aiConversationId: aiConversationId,
        //         aiLinkSettingId: aiLinkSettingId,
        //         aiLinkSettingIdCurrent: aiLinkSettingIdCurrent,
        //       });
        //       returnData.error = procData;
        //     }

        //     return returnData;
        //   } catch (err) {
        //     console.error(err);
        //     returnData.error = err;
        //     return returnData;
        //   }
        // },
        saveAiSettingsAutomationLink: async (uData) => {
          let res = {};
          let procData = {
            data: {
              aiagentid: "",
              automationlink: "",
              aisettingid: "",
              aiconversationid: "",
              aiconversationidprev: [],
              ailinksettingid: "",
              ailinksettingidprev: "",
              aiagentaisettings: [],
              aiagentaisettingsprev: [],

              aisettingsdataarray: [],
              aisettingsindexid: '',
              aisettingsindex: -1,
              iscurrentlinksettings: false,
              ailinksettingsdataarray: [],

            },
            aiagentid: "",
            res: null,
          };

          let returnData = {};

          try {
            let locationId = "";
            if (uData && uData.locationId && uData.locationId !== "") {
              locationId = uData.locationId;
            }

            if (locationId === "") {
              return {
                error: {
                  val: "Location id is missing",
                },
              };
            }

            const test = aiAgentsSelected;
            const test0 = aiPromptVersionSelected;
            const test1 = aiConversation;
            const test2 = aiSettingsDataUpdated; // <- settings data
            const test3 = aiSettingsSelected; // <- settings data

            // debugger
            let aiAgentId = "";
            let automationLink = "";
            let aiSettingsArr = [];

            // let saveAiAgent = false;

            // generate date for updatedon
            const date = generateFirestoreDate();

            if (aiAgentsSelected) {
              if (aiAgentsSelected.aiagentid && aiAgentsSelected.aiagentid !== "") {
                aiAgentId = aiAgentsSelected.aiagentid;
                // add to return data
                //procData.aiagentid = aiAgentId;
                procData.data.aiagentid = aiAgentId;
              }
              if (aiAgentsSelected.automationlink && aiAgentsSelected.automationlink !== "") {
                automationLink = aiAgentsSelected.automationlink;
                // add to return data
                procData.data.automationlink = automationLink;
              }
              if (aiAgentsSelected.aisettings && aiAgentsSelected.aisettings !== "") {
                aiSettingsArr = aiAgentsSelected.aisettings;
                // add to return data
                procData.data.aiagentaisettingsprev = aiSettingsArr;
              }
            }

            let aiSettingId = "";
            if (aiSettingsSelected && aiSettingsSelected.aisettingid && aiSettingsSelected.aisettingid !== "") {
              aiSettingId = aiSettingsSelected.aisettingid;
              // add to return data
              procData.data.aisettingid = aiSettingId;
            }

            let aiLinkSettingIdCurrent = "";
            if (aiSettingsSelected && aiSettingsSelected.ailinksettingid && aiSettingsSelected.ailinksettingid !== "") {
              aiLinkSettingIdCurrent = aiSettingsSelected.ailinksettingid;
            }

            if (clog()) console.log("aiLinkSettingIdCurrent Old", aiLinkSettingIdCurrent);

            let aiConversationId = "";
            if (aiConversation?.aiconversationid !== "") {
              aiConversationId = aiConversation.aiconversationid;
            }

            let aiConversationSettingsDataArray = [];
            if (aiConversation?.aisettings) {
              aiConversationSettingsDataArray = aiConversation.aisettings;
            }


            if (clog()) console.log("aiConversationId New", aiConversationId);


            let aiLinkSettingId = "";
            if (aiConversation?.ailinksettingid !== "") {
              aiLinkSettingId = aiConversation.ailinksettingid;
            }

            let isCurrentLinkSettingInUse = false;
            if (aiLinkSettingIdCurrent !== "" && aiLinkSettingId !== "" && aiLinkSettingIdCurrent === aiLinkSettingId) {
              isCurrentLinkSettingInUse = true;
            }
            // add to return data
            procData.data.iscurrentlinksettings = isCurrentLinkSettingInUse;


            let aiSettingsIndexId = ''
            if (aiPromptVersionSelected.aisettingsindexid) {
              aiSettingsIndexId = aiPromptVersionSelected.aisettingsindexid;
            }
            // add to return data
            procData.data.aisettingsindexid = aiSettingsIndexId;


            let aiSettingsIndex = -1;
            // find index in clonedData array using aiSettingsIndexId
            aiConversationSettingsDataArray.forEach((data, i) => {
              if (data.aisettingsindexid === aiSettingsIndexId) {
                aiSettingsIndex = i;
              }
            });
            // add to return data
            procData.data.aisettingsindex = aiSettingsIndex;

            // debugger

            if (clog()) console.log("XXXXXXX ailinksettingid NEW", aiLinkSettingId);
            if (
              aiAgentId !== "" &&
              // automationLink !== "" &&
              aiSettingId !== "" &&
              aiConversationId !== "" &&
              aiLinkSettingId !== "" &&
              aiLinkSettingIdCurrent !== "" &&
              aiSettingsIndexId !== "" &&
              aiSettingsIndex !== -1
            ) {

              // Get a new write batch
              const batch = writeBatch(db);



              let settingsUpdatedDataArray = [];
              if (isCurrentLinkSettingInUse) {
                //// ailinksettingsid is the same so...
                // 1: update aiconversation doc: update aisettings array item only
                // promptversion = promptversioncurrent;

                // 2: update ailinksettings doc: aisettings

                /* /////////////// START: 1: Update aiconversations //////////////////////// */
                // debugger
                settingsUpdatedDataArray = processSettingsDataArrayForAutomationLink(aiConversationSettingsDataArray, aiSettingsIndex)
                // debugger
                //// update new aiconversations doc
                // update aisettings array
                const conversationDocRef = doc(db, "aiconversations", aiConversationId);
                batch.update(conversationDocRef, {
                  aisettings: settingsUpdatedDataArray,
                  updatedon: date,
                });
                // add to return data
                procData.data.aiconversationid = aiConversationId;
                procData.data.aisettingsdataarray = settingsUpdatedDataArray;

                /* /////////////// END: 1: Update aiconversations //////////////////////// */

                /* /////////////// START: 2 - Update current ailinksettings //////////////////////// */

                const processedLinkSettingsDocData = processSettingsDataArrayForLinkSettings(settingsUpdatedDataArray);
                const processedLinkSettingsSettingsData = processedLinkSettingsDocData.aisettings

                //// update new ailinksettings doc
                // add automationlink
                const linksettingDocRef = doc(db, "ailinksettings", aiLinkSettingId);
                batch.update(linksettingDocRef, {
                  aisettings: processedLinkSettingsSettingsData,
                  updatedon: date,
                });
                // add to return data
                procData.data.ailinksettingid = aiLinkSettingId;
                procData.data.ailinksettingsdataarray = processedLinkSettingsSettingsData;

                /* /////////////// END: 2 - Update new ailinksettings //////////////////////// */

              }
              else {
                // ailinksettingsid is different so...
                // 1: update old ailinksettings doc - remove automationlink

                // 2: update aiconversations doc: aisettings
                // update all aisettings array items
                // promptversion = promptversioncurrent;

                // 3: update ailinksettings doc: aisettings, automationlink and webchatlink

                // 4: update aiagent doc: aisettings

                // debugger
                /* /////////////// START: 1 - Update old ailinksettings //////////////////////// */
                //// update old ailinksettings doc
                // remove automationlink
                const linksettingPrevDocRef = doc(db, "ailinksettings", aiLinkSettingIdCurrent);
                batch.update(linksettingPrevDocRef, {
                  // "data.automationlink": "",
                  automationlink: "",
                  webchatlink: "",
                  updatedon: date,
                });
                // add to return data
                procData.data.ailinksettingidprev = aiLinkSettingIdCurrent;

                /* /////////////// END: 1 - Update old ailinksettings //////////////////////// */

                // aiconversations: update all aisettings array items
                // promptversion = promptversioncurrent;


                /* /////////////// START: 2: Update aiconversations //////////////////////// */
                settingsUpdatedDataArray = processSettingsDataArrayForAutomationLink(aiConversationSettingsDataArray)
                //debugger
                //// update new aiconversations doc
                // update aisettings array
                const conversationDocRef = doc(db, "aiconversations", aiConversationId);
                batch.update(conversationDocRef, {
                  aisettings: settingsUpdatedDataArray,
                  updatedon: date,
                });
                // add to return data
                procData.data.aiconversationid = aiConversationId;
                procData.data.aisettingsdataarray = settingsUpdatedDataArray;

                /* /////////////// END: 2: Update aiconversations //////////////////////// */


                /* /////////////// START: 3 - Update new ailinksettings //////////////////////// */

                const processedLinkSettingsDocData = processSettingsDataArrayForLinkSettings(settingsUpdatedDataArray);
                const processedLinkSettingsSettingsData = processedLinkSettingsDocData.aisettings

                //// update new ailinksettings doc
                // add automationlink
                const linksettingDocRef = doc(db, "ailinksettings", aiLinkSettingId);
                batch.update(linksettingDocRef, {
                  aisettings: processedLinkSettingsSettingsData,
                  automationlink: automationLink,
                  webchatlink: aiAgentId,
                  updatedon: date,
                });
                // add to return data
                procData.data.ailinksettingid = aiLinkSettingId;
                procData.data.ailinksettingsdataarray = processedLinkSettingsSettingsData;

                /* /////////////// END: 3 - Update new ailinksettings //////////////////////// */

                /* /////////////// START: 4 - Update aiagent aisettings //////////////////////// */

                //// update aiagent aisettings
                // Update ailinksettingid in aiSettingsArr using aiLinkSettingId
                // let aiSettingsArrNew = [...aiSettingsArr];
                let aiSettingsArrNew = _.cloneDeep(aiSettingsArr);

                // add aiLinkSettingsid to aiSettingsArrNew
                aiSettingsArrNew[0].ailinksettingid = aiLinkSettingId;

                //// save aiagent
                // save aisettings arr into aiagent
                // update aiagents doc
                const agentRef = doc(db, "aiagents", aiAgentId);
                batch.update(agentRef, {
                  aisettings: aiSettingsArrNew,
                  updatedon: date,
                });
                // add to return data
                procData.data.aiagentaisettings = aiSettingsArrNew;

                /* /////////////// END: 4 - Update aiagent aisettings //////////////////////// */

              }

              // commit the batch
              res = await batch.commit();
              returnData = procData;

            } else {
              console.warn("saveAiSettingsAutomationLink missing data:", {
                aiAgentId: aiAgentId,
                automationLink: automationLink,
                aiSettingId: aiSettingId,
                aiConversationId: aiConversationId,
                aiLinkSettingId: aiLinkSettingId,
                aiLinkSettingIdCurrent: aiLinkSettingIdCurrent,
                aiSettingsIndexId: aiSettingsIndexId,
                aiSettingsIndex: aiSettingsIndex
              });
              returnData.error = procData;
            }

            return returnData;
          } catch (err) {
            console.error(err);
            returnData.error = err;
            return returnData;
          }
        },
        // saveAiSettingsAutomationLinkOLD: async (uData) => {
        //   let res = {};
        //   let procData = {
        //     data: {
        //       aiagentid: "",
        //       automationlink: "",
        //       aisettingid: "",
        //       aiconversationid: "",
        //       aiconversationidprev: [],
        //       // aipromptversionid: [],
        //       // aipromptversionidprev: [],
        //       ailinksettingid: "",
        //       ailinksettingidprev: "",
        //       aiagentaisettings: [],
        //       aiagentaisettingsprev: []
        //     },
        //     aiagentid: "",
        //     res: null,
        //   };

        //   let returnData = {};

        //   try {
        //     let locationId = "";
        //     if (uData && uData.locationId && uData.locationId !== "") {
        //       locationId = uData.locationId;
        //     }

        //     if (locationId === "") {
        //       return {
        //         error: {
        //           val: "Location id is missing",
        //         },
        //       };
        //     }

        //     // const test = aiAgentsSelected;
        //     // const test1 = aiConversation;
        //     // const test2 = aiSettingsDataUpdated; // <- settings data
        //     // const test3 = aiSettingsSelected; // <- settings data

        //     let aiAgentId = "";
        //     let automationLink = "";
        //     let aiSettingsArr = [];

        //     // let saveAiAgent = false;

        //     // generate date for updatedon
        //     const date = generateFirestoreDate();

        //     if (aiAgentsSelected) {
        //       if (aiAgentsSelected.aiagentid && aiAgentsSelected.aiagentid !== "") {
        //         aiAgentId = aiAgentsSelected.aiagentid;
        //         // add to return data
        //         procData.aiagentid = aiAgentId;
        //         procData.data.aiagentid = aiAgentId;
        //       }
        //       if (aiAgentsSelected.automationlink && aiAgentsSelected.automationlink !== "") {
        //         automationLink = aiAgentsSelected.automationlink;
        //         // add to return data
        //         procData.data.automationlink = automationLink;
        //       }
        //       if (aiAgentsSelected.aisettings && aiAgentsSelected.aisettings !== "") {
        //         aiSettingsArr = aiAgentsSelected.aisettings;
        //         // add to return data
        //         procData.data.aiagentaisettingsprev = aiSettingsArr;
        //       }
        //     }

        //     let aiSettingId = "";
        //     if (aiSettingsSelected && aiSettingsSelected.aisettingid && aiSettingsSelected.aisettingid !== "") {
        //       aiSettingId = aiSettingsSelected.aisettingid;
        //       // add to return data
        //       procData.data.aisettingid = aiSettingId;
        //     }

        //     let aiLinkSettingIdCurrent = "";
        //     if (aiSettingsSelected && aiSettingsSelected.ailinksettingid && aiSettingsSelected.ailinksettingid !== "") {
        //       aiLinkSettingIdCurrent = aiSettingsSelected.ailinksettingid;
        //     }

        //     if (clog()) console.log("aiLinkSettingIdCurrent Old", aiLinkSettingIdCurrent);

        //     let aiConversationId = "";
        //     if (aiConversation?.aiconversationid !== "") {
        //       aiConversationId = aiConversation.aiconversationid;
        //     }

        //     if (clog()) console.log("aiConversationId New", aiConversationId);

        //     let aiConversationPromptVersion = -1;
        //     if (aiConversation?.promptversion) {
        //       aiConversationPromptVersion = aiConversation.promptversion;
        //     }

        //     // let aiConversationPromptVersionBasedOn = 0;
        //     // if (aiConversation?.promptversionbasedon) {
        //     //   aiConversationPromptVersionBasedOn = aiConversation.promptversionbasedon;
        //     // }


        //     let aiLinkSettingId = "";
        //     if (aiConversation?.ailinksettingid !== "") {
        //       aiLinkSettingId = aiConversation.ailinksettingid;
        //     }
        //     if (clog()) console.log("XXXXXXX ailinksettingid NEW", aiLinkSettingId);
        //     if (
        //       aiAgentId !== "" &&
        //       // automationLink !== "" &&
        //       aiSettingId !== "" &&
        //       aiConversationId !== "" &&
        //       aiLinkSettingId !== "" &&
        //       aiLinkSettingIdCurrent !== ""
        //     ) {

        //       // Get a new write batch
        //       const batch = writeBatch(db);

        //       /* /////////////// START: Update old aiconversations //////////////////////// */
        //       // AUTOMATIONLINK NO LONGER IN CONVERSATIONS

        //       //// update all old aiconversations docs found with automationlink
        //       // remove automationlink
        //       // let conversationsDocs = [];
        //       // const qa = query(
        //       //   collection(db, "aiconversations"),
        //       //   where("locationid", "==", locationId),
        //       //   // where("automationlink", "==", automationLink),
        //       //   where("ailinksettingid", "==", aiLinkSettingIdCurrent),
        //       //   where("live", "==", false)
        //       // );

        //       // const conversationSnapshot = await getDocs(qa);
        //       // if (conversationSnapshot.empty) {
        //       //   return { data: "no docs found" };
        //       // }
        //       // conversationSnapshot.forEach((doc) => {
        //       //   conversationsDocs.push(doc.data());
        //       // });
        //       // for (const cDoc of conversationsDocs) {
        //       //   const id = cDoc.aiconversationid;
        //       //   // wait X milliseconds
        //       //   // await delay(100);
        //       //   const conversationPrevDocRef = doc(db, "aiconversations", id);

        //       //   // const res = await updateDoc(docRef, data);
        //       //   batch.update(conversationPrevDocRef, {
        //       //     automationlink: "",
        //       //     "aisettings.data.automationlink": "",
        //       //     // ailinksettingid: "",
        //       //     updatedon: date,
        //       //   })
        //       //   // add to return data
        //       //   procData.data.aiconversationidprev.push(id);
        //       // }

        //       /* /////////////// END: Update old aiconversations //////////////////////// */


        //       /* /////////////// START: Update old aipromptversions //////////////////////// */
        //       //// update all old aipromptversion docs found with automationlink
        //       // remove automationlink
        //       // for (const cDoc of conversationsDocs) {
        //       //   const conId = cDoc.aiconversationid;

        //       //   // find related promptversion docs
        //       //   let promptversionPrevDocs = [];
        //       //   const qa = query(
        //       //     collection(db, "aipromptversions"),
        //       //     where("locationid", "==", locationId),
        //       //     where("aiconversationid", "==", conId),
        //       //   );

        //       //   const promptversionPrevSnapshot = await getDocs(qa);
        //       //   if (promptversionPrevSnapshot.empty) {
        //       //     return { data: "no docs found" };
        //       //   }
        //       //   promptversionPrevSnapshot.forEach((doc) => {
        //       //     promptversionPrevDocs.push(doc.data());
        //       //   });

        //       //   for (const pDoc of promptversionPrevDocs) {
        //       //     const proId = pDoc.aipromptversionid;
        //       //     // wait X milliseconds
        //       //     // await delay(100);
        //       //     const promptversionPrevDocRef = doc(db, "aipromptversions", proId);

        //       //     // const res = await updateDoc(docRef, data);
        //       //     batch.update(promptversionPrevDocRef, {
        //       //       // automationlink: "",
        //       //       "data.automationlink": "",
        //       //       // ailinksettingid: "",
        //       //       updatedon: date,
        //       //     })
        //       //     // add to return data
        //       //     procData.data.aipromptversionidprev.push(proId);
        //       //   }
        //       // }
        //       /* /////////////// END: Update old aipromptversions //////////////////////// */


        //       /* /////////////// START: Update old ailinksettings //////////////////////// */
        //       //// update old ailinksettings doc
        //       // remove automationlink
        //       const linksettingPrevDocRef = doc(db, "ailinksettings", aiLinkSettingIdCurrent);
        //       batch.update(linksettingPrevDocRef, {
        //         // "data.automationlink": "",
        //         automationlink: "",
        //         webchatlink: "",
        //         updatedon: date,
        //       });
        //       // add to return data
        //       procData.data.ailinksettingidprev = aiLinkSettingIdCurrent;

        //       /* /////////////// END: Update old ailinksettings //////////////////////// */


        //       /* /////////////// START: Update new aiconversations //////////////////////// */
        //       // AUTOMATIONLINK NO LONGER IN CONVERSATIONS
        //       //// update new aiconversations doc
        //       // add automationlink
        //       // const conversationDocRef = doc(db, "aiconversations", aiConversationId);
        //       // batch.update(conversationDocRef, {
        //       //   automationlink: automationLink,
        //       //   "aisettings.data.automationlink": automationLink,
        //       //   updatedon: date,
        //       // });
        //       // // add to return data
        //       // procData.data.aiconversationid = aiConversationId;

        //       /* /////////////// END: Update new aiconversations //////////////////////// */


        //       /* /////////////// START: Update new aipromptversions //////////////////////// */
        //       //// update all old aipromptversion docs found with automationlink
        //       // remove automationlink
        //       // find related promptversion docs
        //       // let promptversionDocs = [];
        //       // const qpv = query(
        //       //   collection(db, "aipromptversions"),
        //       //   where("locationid", "==", locationId),
        //       //   where("aiconversationid", "==", aiConversationId),
        //       // );

        //       // const promptversionSnapshot = await getDocs(qpv);
        //       // if (promptversionSnapshot.empty) {
        //       //   return { data: "no docs found" };
        //       // }
        //       // promptversionSnapshot.forEach((doc) => {
        //       //   promptversionDocs.push(doc.data());
        //       // });

        //       // for (const pDoc of promptversionDocs) {
        //       //   const proId = pDoc.aipromptversionid;
        //       //   // wait X milliseconds
        //       //   // await delay(100);
        //       //   const promptversionDocRef = doc(db, "aipromptversions", proId);

        //       //   // const res = await updateDoc(docRef, data);
        //       //   batch.update(promptversionDocRef, {
        //       //     // automationlink: "",
        //       //     "data.automationlink": automationLink,
        //       //     // ailinksettingid: "",
        //       //     updatedon: date,
        //       //   })
        //       //   // add to return data
        //       //   procData.data.aipromptversionid.push(proId);
        //       // }
        //       /* /////////////// END: Update new aipromptversions //////////////////////// */


        //       /* /////////////// START: Update new ailinksettings //////////////////////// */

        //       //// update new ailinksettings doc
        //       // add automationlink
        //       const linksettingDocRef = doc(db, "ailinksettings", aiLinkSettingId);
        //       batch.update(linksettingDocRef, {
        //         // "data.automationlink": automationLink,
        //         automationlink: automationLink,
        //         webchatlink: aiAgentId,
        //         updatedon: date,
        //       });
        //       // add to return data
        //       procData.data.ailinksettingid = aiLinkSettingId;

        //       /* /////////////// END: Update new ailinksettings //////////////////////// */


        //       /* /////////////// START: Update aiagent aisettings //////////////////////// */

        //       //// update aiagent aisettings
        //       // Update ailinksettingid in aiSettingsArr using aiLinkSettingId
        //       // let aiSettingsArrNew = [...aiSettingsArr];
        //       let aiSettingsArrNew = _.cloneDeep(aiSettingsArr);

        //       // add aiLinkSettingsid to aiSettingsArrNew
        //       aiSettingsArrNew[0].ailinksettingid = aiLinkSettingId;

        //       //// save aiagent
        //       // save aisettings arr into aiagent
        //       // update aiagents doc
        //       const agentRef = doc(db, "aiagents", aiAgentId);
        //       batch.update(agentRef, {
        //         aisettings: aiSettingsArrNew,
        //         updatedon: date,
        //       });
        //       // add to return data
        //       procData.data.aiagentaisettings = aiSettingsArrNew;

        //       /* /////////////// END: Update aiagent aisettings //////////////////////// */

        //       // commit the batch
        //       res = await batch.commit();
        //       returnData.data = procData;

        //     } else {
        //       console.warn("saveAiSettingsAutomationLink missing data:", {
        //         aiAgentId: aiAgentId,
        //         automationLink: automationLink,
        //         aiSettingId: aiSettingId,
        //         aiConversationId: aiConversationId,
        //         aiLinkSettingId: aiLinkSettingId,
        //         aiLinkSettingIdCurrent: aiLinkSettingIdCurrent,
        //       });
        //       returnData.error = procData;
        //     }

        //     return returnData;
        //   } catch (err) {
        //     console.error(err);
        //     returnData.error = err;
        //     return returnData;
        //   }
        // },
        saveAiSettingsNewFromPreset: async (uData, name, agentData, settingsData) => {
          // saveAiAgentsNew using parts of this function
          let returnData = {};
          try {
            // add locationid
            let locationId = "";
            if (uData.locationId && uData.locationId !== "") {
              locationId = uData.locationId;
            }

            let settingsName = "";

            if (name && name !== "") {
              settingsName = name;
            }

            if (settingsName === "" && settingsData && settingsData.name) {
              settingsName = settingsData.name;
            }

            let aiAgentId = "";
            if (agentData && agentData.aiagentid) {
              aiAgentId = agentData.aiagentid;
            }

            let res = {};

            // generate date for updatedon
            const date = generateFirestoreDate();

            //// update aiagent
            // generate aisettingid for aisettings
            // Get the collection reference
            const agentCollectionRef = collection(db, "aiagents");

            // Generate "locally" a new document for the given collection reference
            const agentDocRef = doc(agentCollectionRef);

            // Get the new document Id
            const aiSettingId = agentDocRef.id;

            let newAiSetting = {
              aisettingid: aiSettingId,
              name: settingsName,
              automationlink: "",
              ailinksettingid: "",
              archive: false,
            };

            let aiSettingsArr = [];
            if (agentData.aisettings && agentData.aisettings.length > 0) {
              aiSettingsArr = [...agentData.aisettings];
            }
            // add new aisetting to aisettings array
            aiSettingsArr.push(newAiSetting);

            // Get a new write batch
            const batch = writeBatch(db);
            //
            // update aiagents doc
            const aRef = doc(db, "aiagents", aiAgentId);
            batch.update(aRef, {
              aisettings: aiSettingsArr, // always update linksettingsid
              updatedon: date,
            });

            //// create aiconversation
            // generate aiconversationid
            const conversationCollectionRef = collection(db, "aiconversations");

            // Generate "locally" a new document for the given collection reference
            const conversationDocRef = doc(conversationCollectionRef);

            // Get the new document Id
            const aiConversationId = conversationDocRef.id;

            // let newConversationData = { ...settingsData };
            let newConversationData = {};

            // add aiconversationid
            newConversationData.aiconversationid = aiConversationId;

            // add aisettingid
            newConversationData.aisettingid = aiSettingId;

            // add name
            newConversationData.name = settingsName;

            // set live to false
            newConversationData.live = false;

            // add archive
            newConversationData.archive = false;

            // add updatedon
            newConversationData.updatedon = date;

            // add createdon
            newConversationData.createdon = date;

            // add locationid
            newConversationData.locationid = locationId;

            // add ailinksettingid
            newConversationData.ailinksettingid = "";

            // process settings data
            let newSettingsData = { ...settingsData };

            // add aiagentid
            newSettingsData.aiagentid = aiAgentId;

            // add aisettingid
            newSettingsData.aisettingid = aiSettingId;

            // add userid
            newSettingsData.userid = uData.userId;

            // add locationid
            newSettingsData.locationid = locationId;

            // remove createdon
            delete newSettingsData.createdon;

            // remove updatedon
            delete newSettingsData.updatedon;

            // add version and versionbasedon
            newConversationData.version = 1;
            newConversationData.versionbasedon = 0;

            // stringify settings data
            const settingsDataJson = JSON.stringify(newSettingsData);

            // add settingsDataJson
            // newConversationData.aisettings = settingsDataJson;
            newConversationData.aisettings = newSettingsData;

            // add settingsDataJson to messages
            // newConversationData.messages = [];

            // const message = {
            //   aisettings: settingsDataJson,
            //   content: "",
            //   role: "aisettings",
            //   // extra data without processing json
            //   data: {
            //     version: 1,
            //     versionbasedon: 0,
            //     // versionPrev: versionCurrent,
            //   },
            // };
            // newConversationData.messages.push(message);
            delete newConversationData.messages

            const cRef = doc(db, "aiconversations", aiConversationId);
            batch.set(cRef, newConversationData);

            if (clog()) console.log("aiSettingId", aiSettingId);
            if (clog()) console.log("aiConversationId", aiConversationId);

            // commit the batch
            res = await batch.commit();

            returnData.data = {
              aisettingid: aiSettingId,
              aiconversationid: aiConversationId,
            };

            return returnData;
          } catch (err) {
            console.error(err);
            returnData.error = err;
            return returnData;
          }
        },
        getAiSettingsActive: (settings) => {
          let returnData = [];

          if (settings && settings.length > 0) {
            returnData = [...settings];
            // loop over returnData
            returnData.forEach((item, index) => {
              // check if item is active
              if (item.archive !== undefined && item.archive === true) {
                // remove item from array
                returnData.splice(index, 1);
              }
            });
          }

          return returnData;
        },
        saveAiConversationsName: async (id, name) => {
          let returnData = {};
          try {
            let res = {};

            let procData = {
              aiconversationid: id,
              aipromptversions: [],
              ailinksettings: [],
            }

            const date = generateFirestoreDate();

            // START: OLD CODE
            // Get the document reference
            // const docRef = doc(db, "aiconversations", id);

            // // Update the document
            // const result = await updateDoc(docRef, {
            //   name: name,
            //   'aisettings.name': name,
            // });
            // END: OLD CODE


            // Get a new write batch
            const batch = writeBatch(db);

            // update conversation
            const cRef = doc(db, "aiconversations", id);
            batch.update(cRef, {
              name: name,
              // 'aisettings.name': name,
              updatedon: date,
            });

            // find all aipromptversions with aiconversationid
            // const aipromptversionsResult = await getAiPromptVersionsDocsByAiConversationId(id, false);
            // if (aipromptversionsResult && aipromptversionsResult.data && aipromptversionsResult.data.length > 0) {
            //   // loop over aipromptversions
            //   for (const pvDoc of aipromptversionsResult.data) {
            //     const pvId = pvDoc.aipromptversionid;
            //     const pvRef = doc(db, "aipromptversions", pvId);
            //     batch.update(pvRef, {
            //       // 'aisettings.name': name,
            //       name: name,
            //       updatedon: date,
            //     });
            //     procData.aipromptversions.push(pvId);
            //   }
            // }

            // find ailinksettings docs with aiconversationid
            const ailinksettingsResult = await getAiLinkSettingsDocsByAiConversationId(id, false);
            if (ailinksettingsResult && ailinksettingsResult.data && ailinksettingsResult.data.length > 0) {
              // loop over ailinksettings
              for (const lsDoc of ailinksettingsResult.data) {
                const lsId = lsDoc.ailinksettingid;
                const lsRef = doc(db, "ailinksettings", lsId);
                batch.update(lsRef, {
                  name: name,
                  updatedon: date,
                });
                procData.ailinksettings.push(lsId);
              }
            }

            // commit the batch
            res = await batch.commit();

            if (clog()) console.log("saveAiConversationsName", procData);
            returnData.data = {
              aiconversationid: id,
              data: procData,
              name: name,
            };
            return returnData;
          } catch (err) {
            console.error(err);
            returnData.error = err;
            return returnData;
          }
        },
        saveAiConversationLocalStorage: async (uData, conversationSelected, type, data) => {
          let returnData = {};
          let updateData = {};
          try {
            let aiconversationid = "";
            if (conversationSelected && conversationSelected.aiconversationid && conversationSelected.aiconversationid !== "") {
              aiconversationid = conversationSelected.aiconversationid;
            }

            if (aiconversationid !== "") {
              // Get the document reference
              const docRef = doc(db, "aiconversations", aiconversationid);
              if (type === "aiengineerid") {
                updateData = {
                  "localstorage.aiengineerid": data.aiengineerid,
                };
              }

              // Update the document
              const result = await updateDoc(docRef, updateData);

              returnData.data = {
                aiconversationid: aiconversationid,
                updateData: updateData,
              };
            } else {
              returnData.error = {
                val: "aiconversationid not found",
              };
            }

            return returnData;
          } catch (err) {
            console.error(err);
            returnData.error = err;
            return returnData;
          }
        },
        removeAiConversation: async (uData, conversationsSelected) => {
          let returnData = {
            data: {},
            error: {},
          };
          try {

            let deleteConversation = true;

            let locationid = "";
            if (uData.locationId && uData.locationId !== "") {
              locationid = uData.locationId;
            }

            if (locationid !== "" && conversationsSelected) {


              //// START: reused code from removeAiAgent
              // mock the array that fetching conversations would generate in removeAiAgent
              const aiconversationsDocs = [];
              aiconversationsDocs.push(conversationsSelected);

              // loop over aiconversations
              for (const cDoc of aiconversationsDocs) {
                const conversationId = cDoc.aiconversationid;

                // get aimessage messages
                const messagemessagesResult = await getAiMessageMessagesDocsByAiConversationId(conversationId);
                if (messagemessagesResult && messagemessagesResult.data) {
                  const aimessagemessagesDocs = messagemessagesResult.data;

                  if (deleteConversation) {
                    // Delete aimessagemessages docs
                    const messagemessagesDeletedResult = await deleteAiMessageMessagesDocs(aimessagemessagesDocs);

                    if (messagemessagesDeletedResult.data) {
                      if (!returnData.data.aimessagemessages) returnData.data.aimessagemessages = [];
                      returnData.data.aimessagemessages = [...returnData.data.aimessagemessages, ...messagemessagesDeletedResult.data];
                    } else if (messagemessagesDeletedResult.error) {
                      if (!returnData.error.aimessagemessages) returnData.error.aimessagemessages = [];
                      returnData.error.aimessagemessages = [...returnData.error.aimessagemessages, ...messagemessagesDeletedResult.error];
                    }
                  }
                  // else {
                  //   // Archive aimessagemessages docs
                  //   const messagemessagesArchivedResult = await archiveAiMessagesMessageDocs(aimessagemessagesDocs, true);

                  //   if (messagemessagesArchivedResult.data) {
                  //     if (!returnData.data.aimessagemessages) returnData.data.aimessagemessages = [];
                  //     returnData.data.aimessagemessages = [...returnData.data.aimessagemessages, ...messagemessagesArchivedResult.data];
                  //   } else if (messagemessagesArchivedResult.error) {
                  //     if (!returnData.error.aimessagemessages) returnData.error.aimessagemessages = [];
                  //     returnData.error.aimessagemessages = [...returnData.error.aimessagemessages, ...messagemessagesArchivedResult.error];
                  //   }
                  // }
                }


                // get aimessages
                const messagesResult = await getAiMessagesDocsByAiConversationId(conversationId);
                if (messagesResult && messagesResult.data) {
                  const aimessagesDocs = messagesResult.data;

                  if (deleteConversation) {
                    // Delete aimessages docs
                    const messagesDeletedResult = await deleteAiMessagesDocs(aimessagesDocs);

                    if (messagesDeletedResult.data) {
                      if (!returnData.data.aimessages) returnData.data.aimessages = [];
                      returnData.data.aimessages = [...returnData.data.aimessages, ...messagesDeletedResult.data];
                    } else if (messagesDeletedResult.error) {
                      if (!returnData.error.aimessages) returnData.error.aimessages = [];
                      returnData.error.aimessages = [...returnData.error.aimessages, ...messagesDeletedResult.error];
                    }
                  }
                  // else {
                  //   // Archive aimessages docs
                  //   const messagesArchivedResult = await archiveAiMessagesDocs(aimessagesDocs, true);

                  //   if (messagesArchivedResult.data) {
                  //     if (!returnData.data.aimessages) returnData.data.aimessages = [];
                  //     returnData.data.aimessages = [...returnData.data.aimessages, ...messagesArchivedResult.data];
                  //   } else if (messagesArchivedResult.error) {
                  //     if (!returnData.error.aimessages) returnData.error.aimessages = [];
                  //     returnData.error.aimessages = [...returnData.error.aimessages, ...messagesArchivedResult.error];
                  //   }
                  // }
                }



                // get aipromptversions
                const promptversionsResult = await getAiPromptVersionsDocsByAiConversationId(conversationId);
                if (promptversionsResult && promptversionsResult.data) {
                  const aipromptversionsDocs = promptversionsResult.data;

                  if (deleteConversation) {
                    // Delete aipromptversions docs
                    const promptversionsDeletedResult = await deleteAiPromptVersionsDocs(aipromptversionsDocs);

                    if (promptversionsDeletedResult.data) {
                      if (!returnData.data.aipromptversions) returnData.data.aipromptversions = [];
                      returnData.data.aipromptversions = [...returnData.data.aipromptversions, ...promptversionsDeletedResult.data];
                    } else if (promptversionsDeletedResult.error) {
                      if (!returnData.error.aipromptversions) returnData.error.aipromptversions = [];
                      returnData.error.aipromptversions = [...returnData.error.aipromptversions, ...promptversionsDeletedResult.error];
                    }
                  } else {
                    // Archive aipromptversions docs
                    const promptversionsArchivedResult = await archiveAiPromptVersionsDocs(aipromptversionsDocs, true);

                    if (promptversionsArchivedResult.data) {
                      if (!returnData.data.aipromptversions) returnData.data.aipromptversions = [];
                      returnData.data.aipromptversions = [...returnData.data.aipromptversions, ...promptversionsArchivedResult.data];
                    } else if (promptversionsArchivedResult.error) {
                      if (!returnData.error.aipromptversions) returnData.error.aipromptversions = [];
                      returnData.error.aipromptversions = [...returnData.error.aipromptversions, ...promptversionsArchivedResult.error];
                    }
                  }
                }


                // get aiengineerconversations
                const engineerconversationsResult = await getAiEngineerConversationsDocsByAiConversationId(conversationId);
                if (engineerconversationsResult && engineerconversationsResult.data) {
                  const aiengineerconversationsDocs = engineerconversationsResult.data;

                  if (deleteConversation) {
                    // Delete aiengineerconversations docs
                    const engineerconversationsDeletedResult = await deleteAiEngineerConversationsDocs(aiengineerconversationsDocs);

                    if (engineerconversationsDeletedResult.data) {
                      if (!returnData.data.aiengineerconversations) returnData.data.aiengineerconversations = [];
                      returnData.data.aiengineerconversations = [
                        ...returnData.data.aiengineerconversations,
                        ...engineerconversationsDeletedResult.data,
                      ];
                    } else if (engineerconversationsDeletedResult.error) {
                      if (!returnData.error.aiengineerconversations) returnData.error.aiengineerconversations = [];
                      returnData.error.aiengineerconversations = [
                        ...returnData.error.aiengineerconversations,
                        ...engineerconversationsDeletedResult.error,
                      ];
                    }
                  } else {
                    // Archive aiengineerconversations docs
                    const engineerconversationsArchivedResult = await archiveAiEngineerConversationsDocs(aiengineerconversationsDocs, true);

                    if (engineerconversationsArchivedResult.data) {
                      if (!returnData.data.aiengineerconversations) returnData.data.aiengineerconversations = [];
                      returnData.data.aiengineerconversations = [
                        ...returnData.data.aiengineerconversations,
                        ...engineerconversationsArchivedResult.data,
                      ];
                    } else if (engineerconversationsArchivedResult.error) {
                      if (!returnData.error.aiengineerconversations) returnData.error.aiengineerconversations = [];
                      returnData.error.aiengineerconversations = [
                        ...returnData.error.aiengineerconversations,
                        ...engineerconversationsArchivedResult.error,
                      ];
                    }
                  }
                }

                // get ailinksettings
                const linksettingsResult = await getAiLinkSettingsDocsByAiConversationId(conversationId, true);

                if (linksettingsResult && linksettingsResult.data) {
                  const ailinksettingsDocs = linksettingsResult.data;

                  if (deleteConversation) {
                    // Delete ailinksettings docs
                    const linksettingsDeletedResult = await deleteAiLinkSettingsDocs(ailinksettingsDocs);

                    if (linksettingsDeletedResult.data) {
                      if (!returnData.data.ailinksettings) returnData.data.ailinksettings = [];
                      returnData.data.ailinksettings = [...returnData.data.ailinksettings, ...linksettingsDeletedResult.data];
                    } else if (linksettingsDeletedResult.error) {
                      if (!returnData.error.ailinksettings) returnData.error.ailinksettings = [];
                      returnData.error.ailinksettings = [...returnData.error.ailinksettings, ...linksettingsDeletedResult.error];
                    }
                  } else {
                    // Archive ailinksettings docs
                    const linksettingsArchivedResult = await archiveAiLinkSettingsDocs(ailinksettingsDocs, true);

                    if (linksettingsArchivedResult.data) {
                      if (!returnData.data.ailinksettings) returnData.data.ailinksettings = [];
                      returnData.data.ailinksettings = [...returnData.data.ailinksettings, ...linksettingsArchivedResult.data];
                    } else if (linksettingsArchivedResult.error) {
                      if (!returnData.error.ailinksettings) returnData.error.ailinksettings = [];
                      returnData.error.ailinksettings = [...returnData.error.ailinksettings, ...linksettingsArchivedResult.error];
                    }
                  }
                }
              }

              if (deleteConversation) {
                // Delete aiconversations docs
                const conversationsDeletedResult = await deleteAiConversationDocs(aiconversationsDocs);
                if (conversationsDeletedResult) {
                  if (conversationsDeletedResult.data) {
                    if (!returnData.data.aiconversations) returnData.data.aiconversations = [];
                    returnData.data.aiconversations = [...returnData.data.aiconversations, ...conversationsDeletedResult.data];
                  } else if (conversationsDeletedResult.error) {
                    if (!returnData.error.aiconversations) returnData.error.aiconversations = [];
                    returnData.error.aiconversations = [...returnData.error.aiconversations, ...conversationsDeletedResult.error];
                  }
                }
              } else {
                // Archive aiconversations docs
                const conversationsArchivedResult = await archiveAiConversationDocs(aiconversationsDocs, true);
                if (conversationsArchivedResult) {
                  if (conversationsArchivedResult.data) {
                    if (!returnData.data.aiconversations) returnData.data.aiconversations = [];
                    returnData.data.aiconversations = [...returnData.data.aiconversations, ...conversationsArchivedResult.data];
                  } else if (conversationsArchivedResult.error) {
                    if (!returnData.error.aiconversations) returnData.error.aiconversations = [];
                    returnData.error.aiconversations = [...returnData.error.aiconversations, ...conversationsArchivedResult.error];
                  }
                }
              }
              //// END: reused code from removeAiAgent





              // delete
              // returnData.data = {
              //   aiconversationid: id,
              //   aipromptversionids: promptVersionDocsDeleted,
              //   aiengineerconversationids: engineerConversationDocsDeleted,
              // };
              if (clog()) console.log("removeAiConversation:", returnData.data);
            } else {
              returnData.error = {
                val: "Location id or aiconversationid is missing",
              };
            }


            if (_.isEmpty(returnData.data)) {
              delete returnData.data;
            }

            if (_.isEmpty(returnData.error)) {
              delete returnData.error;
            }


            return returnData;
          } catch (err) {
            console.error(err);
            returnData.error = err;
            return returnData;
          }
        },
        removeAiConversationOLD: async (uData, id) => {
          let returnData = {};
          try {
            let locationid = "";
            if (uData.locationId && uData.locationId !== "") {
              locationid = uData.locationId;
            }

            if (locationid !== "" && id !== "") {
              // Delete the document
              const result = await deleteDoc(doc(db, "aiconversations", id));

              // START: delete aipromptversions
              let promptVersionDocs = [];
              let promptVersionDocsDeleted = [];

              // get aiengineerconversations docs connected to aiconversationid
              const qpv = query(
                collection(db, "aipromptversions"),
                where("locationid", "==", locationid),
                where("aiconversationid", "==", id),
                orderBy("createdon", "desc")
              );

              const snapshotPromptVersions = await getDocs(qpv);

              // if (snapshotEngineerConversations.empty) {
              // }
              snapshotPromptVersions.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                // if (clog()) console.log(doc.id, " => ", doc.data());
                promptVersionDocs.push(doc.data());
              });

              for (const pvDoc of promptVersionDocs) {
                // wait X milliseconds
                const aipromptversionid = pvDoc.aipromptversionid;
                await delay(100);
                const pvRes = await deleteDoc(doc(db, "aipromptversions", aipromptversionid));
                promptVersionDocsDeleted.push(aipromptversionid);
              }

              // END: delete aipromptversions




              // START: delete aiengineerconversations
              let engineerConversationDocs = [];
              let engineerConversationDocsDeleted = [];

              // get aiengineerconversations docs connected to aiconversationid
              const qec = query(
                collection(db, "aiengineerconversations"),
                where("locationid", "==", locationid),
                where("aiconversationid", "==", id),
                orderBy("createdon", "desc")
              );

              const snapshotEngineerConversations = await getDocs(qec);

              // if (snapshotEngineerConversations.empty) {
              // }
              snapshotEngineerConversations.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                // if (clog()) console.log(doc.id, " => ", doc.data());
                engineerConversationDocs.push(doc.data());
              });

              for (const ecDoc of engineerConversationDocs) {
                // wait X milliseconds
                const aiengineerconversationid = ecDoc.aiengineerconversationid;
                await delay(100);
                const ecRes = await deleteDoc(doc(db, "aiengineerconversations", aiengineerconversationid));
                engineerConversationDocsDeleted.push(aiengineerconversationid);
              }

              // END: delete aiengineerconversations


              // delete
              returnData.data = {
                aiconversationid: id,
                aipromptversionids: promptVersionDocsDeleted,
                aiengineerconversationids: engineerConversationDocsDeleted,
              };
              if (clog()) console.log("removeAiConversation:", returnData.data);
            } else {
              returnData.error = {
                val: "Location id or aiconversationid is missing",
              };
            }

            return returnData;
          } catch (err) {
            console.error(err);
            returnData.error = err;
            return returnData;
          }
        },
        getDefaultAiPreset: (uData) => {
          let returnData = {};

          // const newAiPreset = {
          //   data: {
          //     // automationlink: "",
          //     // description: "",
          //     model: "gpt-4",
          //     strategy: "chat",
          //   },
          //   "gpt-4": {
          //     temperature: 1,
          //     max_tokens: 256,
          //     top_p: 1,
          //     frequency_penalty: 0,
          //     presence_penalty: 0,
          //   },
          //   chat: {
          //     prompt: "",
          //   },
          //   locationid: uData.locationId,
          //   name: "Blank",
          //   userid: uData.userId,
          //   promptversion: 1,
          // };

          const newAiPreset = {
            aisettings: [
              {
                data: {
                  // automationlink: "",
                  // description: "",
                  model: "gpt-4",
                  strategy: "chat",
                },
                "gpt-4": {
                  temperature: 1,
                  max_tokens: 256,
                  top_p: 1,
                  frequency_penalty: 0,
                  presence_penalty: 0,
                },
                chat: {
                  prompt: "",
                },
                name: "prompt 0",
                promptversion: 1,
                promptversioncurrent: 1,
              }
            ],
            name: "Blank",
            locationid: uData.locationId,
            userid: uData.userId,
          };
          returnData.data = newAiPreset;

          return returnData;
        },
        getAiPreset: async (presetId) => {
          let returnData = {};

          try {
            const docRef = doc(db, "aipresets", presetId);
            const docData = await getDoc(docRef);

            if (docData.exists()) {
              const data = docData.data();
              if (clog()) console.log("aipreset:", data);
              const dataProcessed = processAiPresetDataForSettings(data);

              returnData.data = dataProcessed;
            } else {
              if (clog()) console.log("Document does not exist");
              returnData.error = {
                val: "Document does not exist",
              };
            }

            return returnData;
          } catch (err) {
            console.error(err);
            return {
              error: err,
            };
          }
        },
        saveAiPreset: async (uData) => {
          let returnData = {};
          try {
            // const test0 = aiAgentsSelected;
            // const test1 = aiSettingsSelected;
            // const test2 = aiConversation;
            // const test3 = aiSettingsData;
            // const test4 = aiSettingsDataUpdated;

            // Get the collection reference
            const collectionRef = collection(db, "aipresets");

            // Generate "locally" a new document for the given collection reference
            const docRef = doc(collectionRef);

            // Get the new document Id
            const id = docRef.id;
            // const id = "aipresetid123";

            // const processedData = processAiPresetData(aiAgentsSelected, aiSettingsSelected, aiConversation, aiSettingsData, id);
            const processedData = processAiPresetData(uData, aiAgentsSelected, aiConversation, id);
            debugger
            //  Sets the new document with its uuid as property
            let result = {};
            result = await setDoc(docRef, processedData);

            returnData.data = {
              name: processedData.name,
              aipresetid: processedData.aipresetid,
            };

            return returnData;
          } catch (err) {
            console.error(err);
            returnData.status = 501;
            returnData.error = {
              val: "Save AI Preset failed",
            };
            // return returnData;
          }
        },
        updateAiConversationImproveProcessing: async (uData, conversationSelected, type) => {
          let returnData = {};
          try {
            // update aiagent
            const date = generateFirestoreDate();

            let docId = "";
            if (conversationSelected && conversationSelected.aiconversationid && conversationSelected.aiconversationid !== "") {
              docId = conversationSelected.aiconversationid;
            }
            let improveprocessingVal;
            if (type === "add") {
              improveprocessingVal = true;
            } else if (type === "remove") {
              improveprocessingVal = false;
            }

            if (docId !== "") {
              const updateData = {
                updatedon: date,
                improveprocessing: improveprocessingVal,
              };

              const docRef = doc(db, "aiconversations", docId);
              const result = await updateDoc(docRef, updateData);

              returnData.data = {
                aiconversationid: docId,
              };
            } else {
              returnData.error = {
                val: "aiconversationid not found",
              };
            }

            return returnData;
          } catch (err) {
            console.error(err);
            return {
              error: err,
            };
          }
        },
        getAiQuestionsImprovePrompt: async (uData, conversationSelected, iterationCount) => {
          let returnData = {
            status: 200,
            data: {},
            error: {},
          };
          try {
            let locationid = "";
            if (uData && uData.locationId) {
              locationid = uData.locationId;
            }

            let conversationId = "";
            if (conversationSelected && conversationSelected.aiconversationid && conversationSelected.aiconversationid !== "") {
              conversationId = conversationSelected.aiconversationid;
            }

            let iterations = 1;
            if (iterationCount && iterationCount > 0) {
              iterations = iterationCount;
            }

            let language = "en";

            if (locationid !== "" && conversationId !== "") {
              const data = {
                locationid: locationid,
                aiconversationid: conversationId,
                // prompt: promptData,
                iterations: iterations,
                language: language,
              };

              let result = await getAiImprovePromptFromApi(data);
              returnData.status = result.status;

              if (result.status === 200 && result.data) {
                returnData.status = result.status;
                returnData.data = result.data;
              } else {
                returnData.status = result.status;
                returnData.error = result.error.code;
                // returnData.error = result.error.code;
              }
              // let result = {status: 'success'};
              return returnData;
            }
          } catch (err) {
            console.error(err);
            returnData.status = 501;
            returnData.error = {
              val: "Ecomacy customers data fetch failed",
            };
            // return returnData;
          }
        },
        saveAiPresetAgent: async (uData, presetAgentName, type, presetAgentRefDoc) => {
          let returnData = {};
          try {
            const test0 = aiAgentsSelected;
            const test1 = aiSettingsSelected;
            const test2 = aiConversation;
            const test3 = aiSettingsData;
            const test4 = aiSettingsDataUpdated;
            const test5 = aiConversationsDocs;

            // Get the collection reference
            const collectionRef = collection(db, "aipresetagents");

            // Generate "locally" a new document for the given collection reference
            const docRef = doc(collectionRef);

            let id = "";
            if (
              type === "overwrite" &&
              presetAgentRefDoc?.aipresetagentsDoc?.aipresetagentid
            ) {
              // use existing aipresetagentid
              id = presetAgentRefDoc.aipresetagentsDoc.aipresetagentid;
            } else {
              // use new aipresetagentid
              // Get the new document Id
              id = docRef.id;
            }

            const processedData = processAiPresetAgentData(uData, presetAgentName, aiAgentsSelected, aiConversationsDocs, id, type, presetAgentRefDoc);

            //  Sets the new document with its uuid as property
            // const result = {};
            let result = {}

            if (type === "new") {
              result = await setDoc(docRef, processedData);
            }
            else if (type === "duplicate") {
              // create new doc
              result = await setDoc(docRef, processedData);

              // update aiagent doc
              // update presetref
              const aiagentid = processedData.aiagent.aiagentid;
              const aRef = doc(db, "aiagents", aiagentid);
              const updateData = {
                presetref: processedData.presetref,
                updatedon: processedData.updatedon,
              };

              let resultAgent = {}
              resultAgent = await updateDoc(aRef, updateData);
            }
            else if (type === "overwrite") {
              // update aiagent and aiconversations
              const docRef = doc(db, "aipresetagents", id);

              const updateData = {
                aiagent: processedData.aiagent,
                aiagentid: processedData.aiagentid,
                aiconversations: processedData.aiconversations,
                // presetref: processedData.presetref,
                updatedon: processedData.updatedon,
              };
              result = await updateDoc(docRef, updateData);
            }



            returnData.data = {
              name: processedData.name,
              aipresetagentid: processedData.aipresetagentid,
            };

            return returnData;
          } catch (err) {
            console.error(err);
            returnData.status = 501;
            returnData.error = {
              val: "Save AI Preset Agent failed",
            };
            // return returnData;
          }
        },
        getAiPresetAgentRefDoc: async (uData, agentsSelected) => {
          let returnData = {};
          try {
            // const test0 = aiAgentsSelected;
            // const test1 = aiSettingsSelected;
            // const test2 = aiConversation;
            // const test3 = aiSettingsData;
            // const test4 = aiSettingsDataUpdated;
            // const test5 = aiConversationsDocs;

            let locationid = "";
            if (uData && uData.locationId) {
              locationid = uData.locationId;
            }

            let presetref = "";
            if (agentsSelected?.presetref) {
              presetref = agentsSelected.presetref;
            }

            if (locationid && presetref) {

              // Get the collection reference
              let presetagentsDoc = {};
              let docExists = false;
              // get aiengineerconversations docs connected to aiconversationid
              const q = query(
                collection(db, "aipresetagents"),
                // where("locationid", "==", locationid),
                where("presetref", "==", presetref),
                orderBy("createdon", "desc")
              );

              const snapshot = await getDocs(q);

              // if (snapshotEngineerConversations.empty) {
              // }
              snapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                // if (clog()) console.log(doc.id, " => ", doc.data());
                docExists = true;
                const docData = doc.data();
                const aipresetagentid = docData.aipresetagentid;
                const presetref = docData.presetref;
                const name = docData.name;

                const smallData = {
                  aipresetagentid: aipresetagentid,
                  presetref: presetref,
                  name: name,
                }

                presetagentsDoc = smallData;
              });

              returnData.data = {
                presetref: presetref,
                aipresetagentsDoc: presetagentsDoc,
                docExists: docExists,
              };

            } else {
              returnData.error = {
                val: "Location id or presetref is missing",
              };
            }

            return returnData;

          } catch (err) {
            console.error(err);
            returnData.status = 501;
            returnData.error = {
              val: "Save AI Preset Agent failed",
            };
            // return returnData;
          }
        },
      }
      }
    >
      {children}
    </AiAuthContext.Provider >
  );
};

// export default AdminAuthProvider;
