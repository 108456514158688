import React, {
  useState,
  useEffect,
  // useCallback
  useContext,
  useRef,
} from "react";
import PropTypes from "prop-types";

import { create, all } from "mathjs";

import {} from "firebase/auth";
// import { useAuthState } from 'react-firebase-hooks/auth';
import {setd, debug, setc, clog} from "../helpers/HelperFunctions";
import { AdminAuthContext } from "../helpers/AdminAuthProvider";

import { formatCurrencyNumber, getCurrencySymbol } from "../code/locale";

import {
  getFirestore,
  collection,
  query,
  where,
  // orderBy
} from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";

import { FixedSizeList as List } from "react-window";

import { firebase } from "../code/firebase";
import { processKeywordsUserData } from "../code/ecomacy";

import _ from "lodash";

import { useTheme, styled } from "@mui/material/styles";

import {
  Box,
  Button,
  // LoadingButton,
  CircularProgress,
  Typography,
  Grid,
  Stack,
  Divider,
  IconButton,
  InputLabel,
  ListItem,
  ListItemButton,
  ListItemText,
  // ListItemIcon,
  ListItemSecondaryAction,
  OutlinedInput,
} from "@mui/material";

import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/EditOutlined";

import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/ClearOutlined";
import DoneIcon from "@mui/icons-material/DoneOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircleOutlined";
import CircleIcon from "@mui/icons-material/CircleOutlined";
import CancelIcon from "@mui/icons-material/CancelOutlined";

import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { SettingOutlined, FileImageOutlined, UserOutlined, UsergroupAddOutlined } from "@ant-design/icons";

import MainCard from "./MainCard";

// import GoogleMapPane from './GoogleMapPane'
import LoadingPane from "./LoadingPane";
// import { isDisabled } from "@testing-library/user-event/dist/utils";
import { set } from "date-fns";

// create a mathjs instance
const math = create(all);

const AdminAiPresetAgentSetupTab = (props) => {
  const theme = useTheme();


  const { adminUserData, setAdminUserData } = useContext(AdminAuthContext);
  const { adminAiCategoryAgentsDocs, setAdminAiCategoryAgentsDocs } = useContext(AdminAuthContext);

  const { getAiPresetAgentsDocs } = useContext(AdminAuthContext);
  const { getAdminAiCategoryAgentsSuperAdminDoc } = useContext(AdminAuthContext);
  const { adminAiPresetAgentsSuperAdminDocs, setAdminAiPresetAgentsSuperAdminDocs } = useContext(AdminAuthContext);
  const [adminAiPresetAgentsDocsCombined, setAdminAiPresetAgentsDocsCombined] = useState(null);

  const { adminAiCategoryAgentSelected, setAdminAiCategoryAgentSelected } = useContext(AdminAuthContext);
  const adminCategoryAgentSelectedIndex = useRef(-1);
  const { adminAiCategoryAgentsIsEditing, setAdminAiCategoryAgentsIsEditing } = useContext(AdminAuthContext);

  const { saveAdminAiCategoryAgentNew } = useContext(AdminAuthContext);
  const { saveAdminAiCategoryAgentName } = useContext(AdminAuthContext);
  const [aiCategoryAgentName, setAiCategoryAgentName] = useState("");
  const [aiCategoryAgentNameSaving, setAiCategoryAgentNameSaving] = useState(false);
  const { deleteAdminAiCategoryAgent } = useContext(AdminAuthContext);

  // const [adminAiPresetAgentSetupTabLoading, setAdminAiPresetAgentSetupTabLoading] = useState();
  const { adminAiPresetAgentSetupTabLoading, setAdminAiPresetAgentSetupTabLoading } = useContext(AdminAuthContext);
  const { adminAiPresetAgentsDocs, setAdminAiPresetAgentsDocs } = useContext(AdminAuthContext);
  const { adminCategoryAgentAiPresetAgents, setAdminCategoryAgentAiPresetAgents } = useContext(AdminAuthContext);
  const { adminCategoryAgentAiPresetAgentsUpdated, setAdminCategoryAgentAiPresetAgentsUpdated } = useContext(AdminAuthContext);
  const { adminCategoryAgentAiPresetAgentsIdsUpdated, setAdminCategoryAgentAiPresetAgentsIdsUpdated } = useContext(AdminAuthContext);

  const { saveAdminAiCategoryAgentPresetAgentsData } = useContext(AdminAuthContext);
  const { adminAiPresetAgentsIsEditing, setAdminAiPresetAgentsIsEditing } = useContext(AdminAuthContext);

  const { saveAdminAiPresetAgentName } = useContext(AdminAuthContext);
  const [aiPresetAgentName, setAiPresetAgentName] = useState("");
  const [aiPresetAgentNameSaving, setAiPresetAgentNameSaving] = useState(false);


  const [init, setInit] = useState(true);
  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = React.useState(false);

  const [selectedRow, setSelectedRow] = useState({
    type: "",
    aipresetagentid: "",
    name: "",
  });

  // reset states when tab is changed
  useEffect(() => {
    setAdminAiCategoryAgentSelected(null);
    setAdminCategoryAgentAiPresetAgents(null);
    setAdminCategoryAgentAiPresetAgentsUpdated(null);
    setAdminCategoryAgentAiPresetAgentsIdsUpdated(null);
  }, []);

  useEffect(() => {
    const getData = async () => {
      const preResult = await getAiPresetAgentsDocs();
      // get super admin category doc
      // temp
      const catId = "LXNq3GeiT4kNQTpRMtaR";
      const acsaResult = await getAdminAiCategoryAgentsSuperAdminDoc(catId);
    };

    if (adminAiPresetAgentSetupTabLoading) {
      getData()
        // make sure to catch any error
        .catch(console.error);

        setAdminAiPresetAgentSetupTabLoading(false);
    }
  }, [adminAiPresetAgentSetupTabLoading]);

  // triggered when category is selected
  useEffect(() => {
    const getData = async () => {
      if (adminAiCategoryAgentSelected) {
        // set adminCategoryAgentAiPresetAgentsUpdated
        let categoryAiPresets = [];
        if (adminAiCategoryAgentSelected && adminAiCategoryAgentSelected.aipresetagents) {
          categoryAiPresets = adminAiCategoryAgentSelected.aipresetagents;
        }
        setAdminCategoryAgentAiPresetAgents(categoryAiPresets);
        setAdminCategoryAgentAiPresetAgentsUpdated(categoryAiPresets);

        let categoryAiPresetsIds = [];
        if (adminAiCategoryAgentSelected && adminAiCategoryAgentSelected.aipresetagentsids) {
          categoryAiPresetsIds = adminAiCategoryAgentSelected.aipresetagentsids;
        }
        setAdminCategoryAgentAiPresetAgentsIdsUpdated(categoryAiPresetsIds);
      } else {
        // trigged when category is deleted
        if (adminCategoryAgentAiPresetAgents !== null || adminCategoryAgentAiPresetAgentsUpdated !== null) {
          setAdminCategoryAgentAiPresetAgents(null);
          setAdminCategoryAgentAiPresetAgentsUpdated(null);
          setAdminCategoryAgentAiPresetAgentsIdsUpdated(null);
        }
      }
    };

    getData()
      // make sure to catch any error
      .catch(console.error);
  }, [adminAiCategoryAgentSelected]);

  useEffect(() => {
    if (adminAiPresetAgentsDocs && adminAiPresetAgentsSuperAdminDocs) {
      const test = adminAiPresetAgentsSuperAdminDocs;
      // combine adminAiPresetAgentsDocs and adminAiPresetAgentsSuperAdminDocs
      let aiPresetsCombinedArray = [...adminAiPresetAgentsDocs];
      // add divider
      const defaultDivider = {
        aipresetagentid: "aiPresetDivider",
        name: "Default Agents",
      };
      aiPresetsCombinedArray.push(defaultDivider);
      // add super admin docs
      aiPresetsCombinedArray = [...aiPresetsCombinedArray, ...adminAiPresetAgentsSuperAdminDocs];
      setAdminAiPresetAgentsDocsCombined(aiPresetsCombinedArray);
    }
  }, [adminAiPresetAgentsSuperAdminDocs]);

  const saveButtonSx = {
    // ...(success && {
    //   bgcolor: theme.palette.success.main,
    //   '&:hover': {
    //     // bgcolor: theme.palette.success.dark,
    //     bgcolor: theme.palette.success.main,
    //   },
    // }),
    // ml: 1,
    height: "40px",
    width: "100%",
    fontWeight: 700,
  };

  const isListButtonDisabled = () => {
    let disabled = false;

    if (adminAiPresetAgentsIsEditing.active === true) {
      disabled = true;
    }

    if (adminAiCategoryAgentsIsEditing.active === true) {
      disabled = true;
    }

    return disabled;
  };

  const isCategorySelectDisabled = () => {
    let disabled = false;

    if (adminAiPresetAgentsIsEditing.active === true) {
      disabled = true;
    }

    if (adminAiCategoryAgentsIsEditing.active === true) {
      disabled = true;
    }

    return disabled;
  };

  const isCategoryAddRemoveEditDisabled = (type) => {
    let disabled = false;

    if (adminAiPresetAgentsIsEditing.active === true) {
      disabled = true;
    }

    if (adminAiCategoryAgentSelected === null || adminAiCategoryAgentSelected === undefined) {
      // only disable edit & remove button
      if (type === "edit" || type === "remove") {
        disabled = true;
      }
    }

    // don't allow editing of category if preset save is pending
    const saveDisabled = isSaveDisabled();
    if (!saveDisabled) {
      disabled = true;
    }

    return disabled;
  };

  const handleOnChangeAiCategory = (event) => {
    if (clog()) console.log("event:", event.target.value);
    // let fullData = customersData.data
    let categories = [];
    if (adminAiCategoryAgentsDocs && adminAiCategoryAgentsDocs.length > 0) {
      categories = adminAiCategoryAgentsDocs;
    }

    // update item
    const val = event.target.value;

    // find category in adminAiCategoryAgentsDocs array by key aicategoryagentid
    const selectedCategoryFound = adminAiCategoryAgentsDocs.find((c) => c.aicategoryagentid === val);
    let categorySelected = null;
    if (selectedCategoryFound) {
      categorySelected = selectedCategoryFound;
    }

    setAdminAiCategoryAgentSelected(categorySelected);

    resetSelectedRow();
  };

  const handleOnChangeAiCategoryName = (event) => {
    const val = event.target.value;
    if (clog()) console.log("event:", event.target.value);
    setAiCategoryAgentName(val);
  };

  const handleOnClickAiCategoryEdit = () => {
    // const test = adminAiCategoryAgentSelected
    //
    if (adminAiCategoryAgentsIsEditing.active === false) {
      let name = "";
      if (adminAiCategoryAgentSelected && adminAiCategoryAgentSelected.name) {
        name = adminAiCategoryAgentSelected.name;
      }
      setAiCategoryAgentName(name);

      // activate edit mode
      setAdminAiCategoryAgentsIsEditing({
        active: true,
        type: "edit",
      });
    } else {
      // deactivate edit mode
      setAdminAiCategoryAgentsIsEditing({
        active: false,
        type: "",
      });
    }
  };

  const handleOnClickAiCategoryAdd = (e) => {
    // const test = adminAiCategoryAgentSelected
    //
    if (adminAiCategoryAgentsIsEditing.active === false) {
      let name = "";

      setAiCategoryAgentName(name);

      // activate edit mode
      setAdminAiCategoryAgentsIsEditing({
        active: true,
        type: "add",
      });
    } else {
      // deactivate edit mode
      setAdminAiCategoryAgentsIsEditing({
        active: false,
        type: "",
      });
    }
  };

  const handleOnClickAiCategoryRemove = (e) => {
    // const test = adminAiCategoryAgentSelected
    //
    if (adminAiCategoryAgentsIsEditing.active === false) {
      // activate edit mode
      setAdminAiCategoryAgentsIsEditing({
        active: true,
        type: "remove",
      });
    } else {
      // deactivate edit mode
      setAdminAiCategoryAgentsIsEditing({
        active: false,
        type: "",
      });
    }
  };

  const handleOnClickRemoveConfirm = async () => {
    const result = await deleteAdminAiCategoryAgent(adminAiCategoryAgentSelected.aicategoryagentid);
    if (result && result.data && result.data.aicategoryagentid && result.data.aicategoryagentid !== "") {
      // unselect category in menu
      setAdminAiCategoryAgentSelected(null);
      // BT any other cleanup?
    }

    // deactivate edit mode
    setAdminAiCategoryAgentsIsEditing({
      active: false,
      type: "",
    });
  };

  const handleOnClickAiCategoryNewSave = async (event) => {
    if (clog()) console.log("handleOnClickAiCategoryNewSave");
    //
    // setAiSettingsName(name)
    setAiCategoryAgentNameSaving(true);

    const result = await saveAdminAiCategoryAgentNew(aiCategoryAgentName);
    //
    if (result && result.data && result.data.aicategoryagentid && result.data.aicategoryagentid !== "") {
      // set adminAiCategor
      setAdminAiCategoryAgentSelected(result.data);

      // update adminAiCategoryAgentSelected
      // setAdminAiCategoryAgentSelected({
      //   ...adminAiCategoryAgentSelected,
      //   name: result.data.name,
      // });
    }

    setAiCategoryAgentNameSaving(false);

    // deactivate edit mode
    setAdminAiCategoryAgentsIsEditing({
      active: false,
      type: "",
    });
  };

  const handleOnClickAiCategoryNameSave = async (event) => {
    if (clog()) console.log("handleOnClickAiCategoryNameSave");
    //
    // setAiSettingsName(name)
    setAiCategoryAgentNameSaving(true);

    let aiCategoryId = "";
    if (adminAiCategoryAgentSelected && adminAiCategoryAgentSelected.aicategoryagentid && adminAiCategoryAgentSelected.aicategoryagentid !== "") {
      aiCategoryId = adminAiCategoryAgentSelected.aicategoryagentid;
    }

    if (aiCategoryId !== "") {
      const result = await saveAdminAiCategoryAgentName(aiCategoryId, aiCategoryAgentName);
      //
      if (result && result.data && result.data.aicategoryagentid && result.data.aicategoryagentid !== "") {
        // update adminAiCategoryAgentSelected
        setAdminAiCategoryAgentSelected({
          ...adminAiCategoryAgentSelected,
          name: result.data.name,
        });
      }
    } else {
      console.warn("no categoryagent id found");
    }

    setAiCategoryAgentNameSaving(false);

    // deactivate edit mode
    setAdminAiCategoryAgentsIsEditing({
      active: false,
      type: "",
    });
  };

  const isAiCategoryNameSaveDisabled = () => {
    let disabled = false;

    let aiCategorySelectedName = "";
    if (adminAiCategoryAgentSelected && adminAiCategoryAgentSelected.name && adminAiCategoryAgentSelected.name !== "") {
      aiCategorySelectedName = adminAiCategoryAgentSelected.name;
    }

    if (aiCategoryAgentName === "") {
      disabled = true;
    }

    if (aiCategoryAgentName === aiCategorySelectedName) {
      disabled = true;
    }

    return disabled;
  };

  const handleOnClickSave = async () => {
    const result = await saveAdminAiCategoryAgentPresetAgentsData(adminAiCategoryAgentSelected, adminCategoryAgentAiPresetAgentsUpdated, adminCategoryAgentAiPresetAgentsIdsUpdated);
    if (result && result.data) {
      // cleanup stuff
      // update adminCategoryAgentAiPresetAgents
      setAdminCategoryAgentAiPresetAgents(result.data);
    }
  };

  const isSaveDisabled = () => {
    // compare adminCategoryAgentAiPresetAgentsUpdated with adminCategoryAgentAiPresetAgents
    let isDisabled = true;

    if (adminCategoryAgentAiPresetAgentsUpdated === null || adminCategoryAgentAiPresetAgents === null) {
      isDisabled = true;
    } else if (adminCategoryAgentAiPresetAgentsUpdated.length !== adminCategoryAgentAiPresetAgents.length) {
      isDisabled = false;
    } else if (adminCategoryAgentAiPresetAgentsUpdated && adminCategoryAgentAiPresetAgentsUpdated.length > 0) {
      if (adminCategoryAgentAiPresetAgents && adminCategoryAgentAiPresetAgents.length > 0) {
        if (adminCategoryAgentAiPresetAgentsUpdated.length !== adminCategoryAgentAiPresetAgents.length) {
          isDisabled = false;
        } else {
          // compare each item
          for (let i = 0; i < adminCategoryAgentAiPresetAgentsUpdated.length; i++) {
            const item = adminCategoryAgentAiPresetAgentsUpdated[i];
            const itemCurrent = adminCategoryAgentAiPresetAgents[i];
            if (item.aipresetagentid !== itemCurrent.aipresetagentid) {
              isDisabled = false;
              break;
            }
          }
        }
      } else {
        isDisabled = false;
      }
    }

    if (adminAiPresetAgentsIsEditing.active === true) {
      isDisabled = true;
    }

    return isDisabled;
  };

  const resetSelectedRow = () => {
    const data = {
      type: "",
      name: "",
      aipresetagentid: "",
    };

    setSelectedRow(data);
  };

  const isSelectedRow = (type, item) => {
    let isSelected = false;
    if ("categorypreset" === type && selectedRow.type === type) {
      let aipresetagentid = "";
      if (item.item) {
        const itemObj = item.item;
        aipresetagentid = itemObj.aipresetagentid;
      } else {
        aipresetagentid = item.aipresetagentid;
      }

      if (aipresetagentid === selectedRow.aipresetagentid) {
        isSelected = true;
      }
    } else if ("preset" === type && selectedRow.type === type) {
      let aipresetagentid = "";
      if (item.item) {
        const itemObj = item.item;
        aipresetagentid = itemObj.aipresetagentid;
      } else {
        aipresetagentid = item.aipresetagentid;
      }

      if (aipresetagentid === selectedRow.aipresetagentid) {
        isSelected = true;
      }
    }

    return isSelected;
  };

  const isDisabledRow = (type, item) => {
    let isDisabled = false;
    if ("preset" === type) {
      const test = adminCategoryAgentAiPresetAgentsUpdated;
      const test1 = item;

      // use lodash to find item in adminCategoryAgentAiPresetAgentsUpdated
      const foundItem = _.find(adminCategoryAgentAiPresetAgentsUpdated, { aipresetagentid: item.aipresetagentid });
      if (foundItem) {
        isDisabled = true;
      }
    }

    // if (!adminAiCategoryAgentSelected) {
    //   isDisabled = true;
    // }
    return isDisabled;
  };

  const handleCategoryAiPresetsRowOnClick = (e, index, item) => {
    let click = 0;
    switch (e.detail) {
      case 1:
        if (clog()) console.log("click");
        click = 1;
        break;
      case 2:
        if (clog()) console.log("double click");
        click = 2;
        break;
    }

    let data = {
      type: "",
      aipresetagentid: "",
      name: "",
    };

    if (item) {
      data.type = "categorypreset";
      data.aipresetagentid = item.aipresetagentid;
      data.name = item.name;
    }

    if (!_.isEqual(data, selectedRow)) {
      setSelectedRow(data);
      if (clog()) console.log("Category Preset Clicked:", data.id);
    } else {
      setSelectedRow({
        type: "",
        aipresetagentid: "",
        name: "",
      });
    }
  };

  const handleOnClickCategoryAiPresetRemove = (e, item) => {
    e.stopPropagation();
    e.preventDefault();

    if (clog()) console.log("handleOnClickCategoryAiPresetRemove:", item);

    // remove item from adminCategoryAgentAiPresetAgentsUpdated
    const newPresetArr = adminCategoryAgentAiPresetAgentsUpdated.filter((preset) => preset.aipresetagentid !== item.aipresetagentid);

    setAdminCategoryAgentAiPresetAgentsUpdated(newPresetArr);

    let newAiPresetsIds = [...adminCategoryAgentAiPresetAgentsIdsUpdated];

    // find aipresetagentid in newAiPresetsIds
    const index = newAiPresetsIds.indexOf(item.aipresetagentid);
    if (index > -1) {
      // remove aipresetagentid from newAiPresetsIds
      newAiPresetsIds.splice(index, 1);
    }

    setAdminCategoryAgentAiPresetAgentsIdsUpdated(newAiPresetsIds);

    // reset selected row
    resetSelectedRow();
  };

  const renderSelectedPresetsRows = (props) => {
    const { index, style } = props;
    // const item = googleCategoriesRows[index]
    let name = "";
    const item = adminCategoryAgentAiPresetAgentsUpdated[index];
    if (item) {
      name = item.name;
      let automationlink = "";
      if (item.automationlink) {
        automationlink = item.automationlink;
      }

      // const searchItemObj = searchResultsArr[index]
      // const item = searchItemObj.item
      // const id = item.id
      // const t = item.t
      return (
        <div key={index}>
        <ListItem style={style} key={index} component="div" disablePadding divider>
          <ListItemButton
            sx={{ pt: 0, pb: 0, height: "100%" }}
            onClick={(event) => handleCategoryAiPresetsRowOnClick(event, index, item)}
            // selected={index === 1 ? true : false}
            selected={isSelectedRow("categorypreset", item)}
            disabled={isListButtonDisabled()}
          >
            <ListItemText
              primary={name}
              secondary={automationlink}
              sx={{
                "& .MuiListItemText-secondary": {
                  fontSize: "15px",
                  lineHeight: "1",
                },
                color: item.active === 0 ? theme.palette.text.disabled : theme.palette.text.primary,
                ".Mui-selected &": {
                  color: theme.palette.primary.main,
                },
                // textTransform: "capitalize !important",
              }}
            />

            {
              // isSelectedRow("categorypreset", item) && (
            }
            <ListItemSecondaryAction>
              <IconButton
                aria-label="Remove"
                color="primary"
                // size="small"
                // variant="light"
                onClick={(event) => handleOnClickCategoryAiPresetRemove(event, item)}
                // disabled={isAiAgentsEditDisabled()}
                // sx={{
                //   // ml: 0.5,
                //   "&.MuiIconButton-sizeSmall": {
                //     fontSize: "16px",
                //   },
                // }}
              >
                <CancelIcon fontSize="inherit" />
              </IconButton>
            </ListItemSecondaryAction>
            {
              // )
            }
          </ListItemButton>
        </ListItem>
        </div>
      );
    }
  };

  const handleAiPresetsRowOnClick = (index, item) => {
    if (clog()) console.log("handleAiPresetsRowOnClick:", index, item);
    let data = {
      type: "preset",
      aipresetagentid: "",
      name: "",
    };

    if (item.item) {
      const itemObj = item.item;
      data.type = "preset";
      data.aipresetagentid = itemObj.aipresetagentid;
      data.name = itemObj.name;
    } else {
      data.type = "preset";
      data.aipresetagentid = item.aipresetagentid;
      data.name = item.name;
    }

    if (!_.isEqual(data, selectedRow)) {
      setSelectedRow(data);
      if (clog()) console.log("Preset Clicked:", data.aipresetagentid);
    } else {
      // same row has been clicked on
      setSelectedRow({
        type: "",
        aipresetagentid: "",
        name: "",
      });
    }
  };

  const handleOnChangeAiPresetName = (event) => {
    const val = event.target.value;
    if (clog()) console.log("event:", event.target.value);
    setAiPresetAgentName(val);
  };

  const handleOnClickAiPresetEdit = (e, item) => {
    e.stopPropagation();
    e.preventDefault();
    //
    if (adminAiPresetAgentsIsEditing.active === false) {
      let name = "";
      if (item && item.name) {
        name = item.name;
      }
      setAiPresetAgentName(name);

      // activate edit mode
      setAdminAiPresetAgentsIsEditing({
        active: true,
        type: "edit",
      });
    } else {
      // deactivate edit mode
      setAdminAiPresetAgentsIsEditing({
        active: false,
        type: "",
      });
    }
  };

  const handleOnClickAiPresetNameSave = async (event, item) => {
    if (clog()) console.log("handleOnClickAiPresetNameSave:", item);
    //
    // setAiSettingsName(name)
    setAiPresetAgentNameSaving(true);

    const result = await saveAdminAiPresetAgentName(item.aipresetagentid, aiPresetAgentName);
    //
    if (result && result.data && result.data.aipresetagentid && result.data.aipresetagentid !== "") {
      // check if item exists in adminCategoryAgentAiPresetAgents
      // const test = adminCategoryAgentAiPresetAgents;
      const presetIdx = _.findIndex(adminCategoryAgentAiPresetAgents, { aipresetagentid: result.data.aipresetagentid });
      if (presetIdx !== -1) {
        // update item in adminCategoryAgentAiPresetAgents
        const newPresetArr = [...adminCategoryAgentAiPresetAgents];
        // only update state if name is different
        if (newPresetArr[presetIdx].name !== result.data.name) {
          newPresetArr[presetIdx].name = result.data.name;
          setAdminCategoryAgentAiPresetAgents(newPresetArr);
        }
      }

      const presetUpdatedIdx = _.findIndex(adminCategoryAgentAiPresetAgentsUpdated, { aipresetagentid: result.data.aipresetagentid });
      if (presetUpdatedIdx !== -1) {
        // update item in adminCategoryAgentAiPresetAgents
        const newPresetUpdatedArr = [...adminCategoryAgentAiPresetAgentsUpdated];
        // only update state if name is different
        if (newPresetUpdatedArr[presetUpdatedIdx].name !== result.data.name) {
          newPresetUpdatedArr[presetUpdatedIdx].name = result.data.name;
          setAdminCategoryAgentAiPresetAgentsUpdated(newPresetUpdatedArr);
        }
      }

      // update selected row
      if (clog()) console.log('selectedRow:', selectedRow)
      setSelectedRow({
        ...selectedRow,
        name: result.data.name,
      });

      // update adminAiPresetAgentsDocs
      // if (clog()) console.log('adminAiPresetAgentsDocs:', adminAiPresetAgentsDocs)
      const presetAgentDocsIdx = _.findIndex(adminAiPresetAgentsDocs, { aipresetagentid: result.data.aipresetagentid });
      if (presetAgentDocsIdx !== -1) {
        // update item in adminCategoryAgentAiPresetAgents
        const newPresetAgentArr = [...adminAiPresetAgentsDocs];
        // only update state if name is different
        if (newPresetAgentArr[presetAgentDocsIdx].name !== result.data.name) {
          newPresetAgentArr[presetAgentDocsIdx].name = result.data.name;
          setAdminAiPresetAgentsDocs(newPresetAgentArr);
        }
      }

      // update adminAiPresetAgentsDocsCombined
      // if (clog()) console.log('adminAiPresetAgentsDocsCombined:', adminAiPresetAgentsDocsCombined)
      // const presetAgentDocsCombinedIdx = _.findIndex(adminAiPresetAgentsDocsCombined, { aipresetagentid: result.data.aipresetagentid });
      // if (presetAgentDocsCombinedIdx !== -1) {
      //   // update item in adminCategoryAgentAiPresetAgents
      //   const newPresetAgentCombinedArr = [...adminAiPresetAgentsDocsCombined];
      //   // only update state if name is different
      //   if (newPresetAgentCombinedArr[presetAgentDocsCombinedIdx].name !== result.data.name) {
      //     newPresetAgentCombinedArr[presetAgentDocsCombinedIdx].name = result.data.name;
      //     setAdminAiPresetAgentsDocsCombined(newPresetAgentCombinedArr);
      //   }
      // }


      // const test1 = adminCategoryAgentAiPresetAgentsUpdated
    }
    //
    setAiPresetAgentNameSaving(false);

    // deactivate edit mode
    setAdminAiPresetAgentsIsEditing({
      active: false,
      type: "",
    });
  };

  const isAiPresetNameSaveDisabled = (item) => {
    let disabled = false;
    if (aiPresetAgentName === "") {
      disabled = true;
    }

    if (aiPresetAgentName === item.name) {
      disabled = true;
    }

    return disabled;
  };

  const handleOnClickAiPresetAdd = (e, item) => {
    e.stopPropagation();
    e.preventDefault();
    debugger
    if (clog()) console.log("handleOnClickAiPresetAdd:", item);

    // add item to adminCategoryAgentAiPresetAgentsUpdated
    let newPresetArr = [...adminCategoryAgentAiPresetAgentsUpdated];
    newPresetArr.push(item);

    // sort by name
    newPresetArr = _.sortBy(newPresetArr, "name");

    setAdminCategoryAgentAiPresetAgentsUpdated(newPresetArr);

    // add item to adminCategoryAgentAiPresetAgentsUpdated
    let newPresetIdsArr = [...adminCategoryAgentAiPresetAgentsIdsUpdated];

    newPresetIdsArr.push(item.aipresetagentid);
    setAdminCategoryAgentAiPresetAgentsIdsUpdated(newPresetIdsArr);

    // reset selected row
    resetSelectedRow();
  };

  const isAiPresetAddDisabled = (item) => {
    // default disable funtionality
    let disabled = isDisabledRow("preset", item);

    // no category is selected so dont show add button
    if (adminAiCategoryAgentSelected === null) {
      disabled = true;
    }

    return disabled;
  };

  const isAiPresetEditDisabled = (item) => {
    // default disable funtionality
    let disabled = false;

    if (adminAiPresetAgentsIsEditing.active === true) {
      disabled = true;
    }

    if (adminAiCategoryAgentsIsEditing.active === true) {
      disabled = true;
    }

    return disabled;
  };

  const renderAiPresetsRows = (props) => {
    const { index, style } = props;
    // let id = ''
    let t = "";

    // const item = adminAiPresetAgentsDocs[index];
    const item = adminAiPresetAgentsDocsCombined[index];

    if (item) {
      // if (item.item) {
      //   const itemObj = item.item;
      //   // id = itemObj.id
      //   t = itemObj.t;
      // } else {
      //   // id = item.id
      //   t = item.t;
      // }

      if (clog()) console.log('XXXXX item:', item)
      let name = "";
      if (item.name) {
        name = item.name;
      }

      let automationlink = "";
      if (item.automationlink) {
        automationlink = item.automationlink;
      }

      const selectedRow = isSelectedRow("preset", item);

      // const searchItemObj = searchResultsArr[index]
      // const item = searchItemObj.item
      // const id = item.id
      // const t = item.t

      if (item.aipresetagentid === "aiPresetDivider") {
        return (
          <ListItem
          style={style}
          key={index}
          component="div"
          disablePadding
          divider
          sx={{
            backgroundColor: "#f5f5f5 !important",
            // height: '30px !important',
          }}
          >
            <ListItemText
              primary={name}
              secondary={automationlink}
              primaryTypographyProps={{style: {fontWeight: 'bold'}}}
              sx={{
                "& .MuiListItemText-secondary": {
                  fontSize: "15px",
                  lineHeight: "1",
                },
                // textTransform: "capitalize !important",
                pl: 2,
                color: 'grey.600'
              }}
            />
          </ListItem>
        );
      }
      else if (item.userid !== adminUserData.uid) {
        return (
          <ListItem style={style} key={index} component="div" disablePadding divider>
            <Box
              sx={{
                pt: 0,
                pb: 0,
                pl: 2,
                height: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <ListItemText
                primary={name}
                secondary={automationlink}
                sx={{
                  "& .MuiListItemText-secondary": {
                    fontSize: "15px",
                    lineHeight: "1",
                  },
                  // textTransform: "capitalize !important",
                }}
              />

              {isDisabledRow("preset", item) ? (
                <ListItemSecondaryAction>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <IconButton aria-label="Add" color="primary" onClick={(event) => handleOnClickCategoryAiPresetRemove(event, item)}>
                      <CheckCircleIcon fontSize="inherit" />
                    </IconButton>
                  </Box>
                </ListItemSecondaryAction>
              ) : (
                <ListItemSecondaryAction>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <IconButton
                      aria-label="Add"
                      color="primary"
                      onClick={(event) => handleOnClickAiPresetAdd(event, item)}
                      disabled={isAiPresetAddDisabled(item)}
                    >
                      <CircleIcon fontSize="inherit" />
                    </IconButton>
                  </Box>
                </ListItemSecondaryAction>
              )}
            </Box>
          </ListItem>
        );
      }
      else if (selectedRow && adminAiPresetAgentsIsEditing.active === true) {
        return (
          <ListItem style={style} key={index} component="div" disablePadding divider>
            <Box
              sx={{
                pt: 0,
                pb: 0,
                height: "100%",
                display: "flex",
              }}
            >
              <Box
                name="aiPresetsNameInput"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  pl: 1,
                  width: "100%",
                }}
              >
                <TextField
                  required
                  fullWidth
                  label="Preset Name"
                  name="Preset Name"
                  autoComplete="name"
                  autoFocus
                  sx={{
                    minWidth: "300px",
                    input: {
                      py: "7px",
                      px: "8px",
                      backgroundColor: "white",
                    },
                  }}
                  value={aiPresetAgentName}
                  onChange={(e) => handleOnChangeAiPresetName(e)}
                />
              </Box>

              <ListItemSecondaryAction>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Box>
                    {aiPresetAgentNameSaving ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "36px",
                          height: "36px",
                        }}
                      >
                        <CircularProgress
                          size={12}
                          sx={{
                            p: 0,
                            m: 0,
                          }}
                        />
                      </Box>
                    ) : (
                      <IconButton
                        aria-label="Save"
                        color="primary"
                        onClick={(event) => handleOnClickAiPresetNameSave(event, item)}
                        disabled={isAiPresetNameSaveDisabled(item)}
                      >
                        <DoneIcon fontSize="inherit" />
                      </IconButton>
                    )}
                  </Box>
                  <Box>
                    <IconButton aria-label="Cancel" color="primary" onClick={(event) => handleOnClickAiPresetEdit(event, item)}>
                      <ClearIcon fontSize="inherit" />
                    </IconButton>
                  </Box>
                </Box>
              </ListItemSecondaryAction>
            </Box>
          </ListItem>
        );
      }
      else if (selectedRow) {
        return (
          <ListItem style={style} key={index} component="div" disablePadding divider>
            <ListItemButton
              sx={{ pt: 0, pb: 0, height: "100%" }}
              onClick={() => handleAiPresetsRowOnClick(index, item)}
              selected={selectedRow}
              disabled={isListButtonDisabled()}
            >
              <ListItemText
                primary={name}
                secondary={automationlink}
                sx={{
                  "& .MuiListItemText-secondary": {
                    fontSize: "15px",
                    lineHeight: "1",
                  },
                  //textTransform: "capitalize !important",
                }}
              />

              <ListItemSecondaryAction>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Box>
                    <IconButton
                      aria-label="Edit"
                      color="primary"
                      onClick={(event) => handleOnClickAiPresetEdit(event, item)}
                      disabled={isAiPresetEditDisabled(item)}
                    >
                      <EditIcon fontSize="inherit" />
                    </IconButton>
                  </Box>

                  {isDisabledRow("preset", item) ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <IconButton aria-label="Add" color="primary" onClick={(event) => handleOnClickCategoryAiPresetRemove(event, item)}>
                        <CheckCircleIcon fontSize="inherit" />
                      </IconButton>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <IconButton
                        aria-label="Add"
                        color="primary"
                        onClick={(event) => handleOnClickAiPresetAdd(event, item)}
                        disabled={isAiPresetAddDisabled(item)}
                      >
                        <CircleIcon fontSize="inherit" />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              </ListItemSecondaryAction>
            </ListItemButton>
          </ListItem>
        );
      }
      else {
        const test = item;
        return (
          <ListItem style={style} key={index} component="div" disablePadding divider>
            <ListItemButton
              sx={{ pt: 0, pb: 0, height: "100%" }}
              onClick={() => handleAiPresetsRowOnClick(index, item)}
              selected={selectedRow}
              disabled={isListButtonDisabled()}
            >
              <ListItemText
                primary={name}
                secondary={automationlink}
                sx={{
                  "& .MuiListItemText-secondary": {
                    fontSize: "15px",
                    lineHeight: "1",
                  },
                  // textTransform: "capitalize !important",
                }}
              />

              {isDisabledRow("preset", item) ? (
                <ListItemSecondaryAction>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <IconButton aria-label="Add" color="primary" onClick={(event) => handleOnClickCategoryAiPresetRemove(event, item)}>
                      <CheckCircleIcon fontSize="inherit" />
                    </IconButton>
                  </Box>
                </ListItemSecondaryAction>
              ) : (
                <ListItemSecondaryAction>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <IconButton
                      aria-label="Add"
                      color="primary"
                      onClick={(event) => handleOnClickAiPresetAdd(event, item)}
                      disabled={isAiPresetAddDisabled(item)}
                    >
                      <CircleIcon fontSize="inherit" />
                    </IconButton>
                  </Box>
                </ListItemSecondaryAction>
              )}
            </ListItemButton>
          </ListItem>
        );
      }
    }
  };

  const getSelectedCategoryId = () => {
    let selectedCategory = "none";
    if (adminAiCategoryAgentSelected && adminAiCategoryAgentSelected.aicategoryagentid && adminAiCategoryAgentSelected.aicategoryagentid !== "") {
      selectedCategory = adminAiCategoryAgentSelected.aicategoryagentid;
    }

    return selectedCategory;
  };

  const showCategoriesControls = () => {
    if (adminAiCategoryAgentsIsEditing.active === true && adminAiCategoryAgentsIsEditing.type === "edit") {
      return (
        // START Category Edit
        <Box
          name="aiCategoriesNameInput"
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            // pl: 1,

            // backgroundColor: "#cc0000",
          }}
        >
          <Box>
            <TextField
              required
              fullWidth
              label="Category Name"
              name="Category Name"
              autoComplete="name"
              autoFocus
              sx={{
                minWidth: "300px",
                input: {
                  // py: "7px",
                  // px: "8px",
                  backgroundColor: "white",
                },
              }}
              value={aiCategoryAgentName}
              onChange={(e) => handleOnChangeAiCategoryName(e)}
            />
          </Box>

          <Box
            sx={{
              ml: 1,
            }}
          >
            {aiCategoryAgentNameSaving ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "30px",
                  height: "30px",
                }}
              >
                <CircularProgress
                  size={12}
                  sx={{
                    p: 0,
                    m: 0,
                  }}
                  // variant="determinate"
                />
              </Box>
            ) : (
              <IconButton
                aria-label="Save"
                color="primary"
                size="small"
                // variant="light"
                onClick={(event) => handleOnClickAiCategoryNameSave(event)}
                disabled={isAiCategoryNameSaveDisabled()}
                sx={{
                  // ml: 0.5,
                  "&.MuiIconButton-sizeSmall": {
                    fontSize: "16px",
                  },
                }}
              >
                <DoneIcon fontSize="inherit" />
              </IconButton>
            )}
          </Box>

          <Box>
            <IconButton
              aria-label="Cancel"
              color="primary"
              size="small"
              // variant="light"
              onClick={() => handleOnClickAiCategoryEdit()}
              // disabled={isCategoryAddRemoveEditDisabled("add")}
              sx={{
                // ml: 0.5,
                "&.MuiIconButton-sizeSmall": {
                  fontSize: "16px",
                },
              }}
            >
              <ClearIcon fontSize="inherit" />
            </IconButton>
          </Box>
        </Box>
        // END Category Edit
      );
    } else if (adminAiCategoryAgentsIsEditing.active === true && adminAiCategoryAgentsIsEditing.type === "add") {
      return (
        // START Category Edit
        <Box
          name="aiCategoriesNameInput"
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            // pl: 1,

            // backgroundColor: "#cc0000",
          }}
        >
          <Box>
            <TextField
              required
              fullWidth
              label="Collection Name"
              name="Collection Name"
              autoComplete="name"
              autoFocus
              sx={{
                minWidth: "300px",
                input: {
                  // py: "7px",
                  // px: "8px",
                  backgroundColor: "white",
                },
              }}
              value={aiCategoryAgentName}
              onChange={(e) => handleOnChangeAiCategoryName(e)}
            />
          </Box>

          <Box
            sx={{
              ml: 1,
            }}
          >
            {aiCategoryAgentNameSaving ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "30px",
                  height: "30px",
                }}
              >
                <CircularProgress
                  size={12}
                  sx={{
                    p: 0,
                    m: 0,
                  }}
                  // variant="determinate"
                />
              </Box>
            ) : (
              <IconButton
                aria-label="Save"
                color="primary"
                size="small"
                // variant="light"
                onClick={(event) => handleOnClickAiCategoryNewSave(event)}
                disabled={isAiCategoryNameSaveDisabled()}
                sx={{
                  // ml: 0.5,
                  "&.MuiIconButton-sizeSmall": {
                    fontSize: "16px",
                  },
                }}
              >
                <DoneIcon fontSize="inherit" />
              </IconButton>
            )}
          </Box>

          <Box>
            <IconButton
              aria-label="Cancel"
              color="primary"
              size="small"
              // variant="light"
              onClick={() => handleOnClickAiCategoryEdit()}
              // disabled={isCategoryAddRemoveEditDisabled("add")}
              sx={{
                // ml: 0.5,
                "&.MuiIconButton-sizeSmall": {
                  fontSize: "16px",
                },
              }}
            >
              <ClearIcon fontSize="inherit" />
            </IconButton>
          </Box>
        </Box>
        // END Category Edit
      );
    } else {
      return (
        // START Category Select
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            // backgroundColor: "#cc0000",
          }}
        >
          <Box>
            <FormControl
              sx={{
                // m: 1,
                minWidth: 120,
              }}
            >
              <InputLabel>Collection</InputLabel>
              <Select
                // id="demo-simple-select"
                // value={age}
                label="Collection"
                // onChange={handleChange}
                // value={adminAiCategoryAgentSelected && adminAiCategoryAgentSelected.aicategoryagentid ? adminAiCategoryAgentSelected.aicategoryagentid : ""}
                value={getSelectedCategoryId()}
                onChange={(event) => handleOnChangeAiCategory(event)}
                disabled={isCategorySelectDisabled()}
                sx={{
                  minWidth: 300,
                }}
              >
                {adminAiCategoryAgentSelected === null && (
                  <MenuItem
                    value="none"
                    disabled
                    sx={{
                      display: "none",
                    }}
                  >
                    Select a Collection
                  </MenuItem>
                )}
                {adminAiCategoryAgentsDocs &&
                  adminAiCategoryAgentsDocs.map((c, index) => {
                    return (
                      <MenuItem key={"aicategory" + index} value={c.aicategoryagentid}>
                        {c.name}
                      </MenuItem>
                    );
                  })}

                {
                  // adminUserData && adminUserData.userDoc && adminUserData.userDoc.memberships && adminUserData.userDoc.memberships.map((m, index) => {
                  //   return (
                  //     <MenuItem key={'membership' + index} value={index}>{m.name}</MenuItem>
                  //   )
                  // })
                }
              </Select>
            </FormControl>
          </Box>

          {adminAiCategoryAgentsIsEditing.active === true && adminAiCategoryAgentsIsEditing.type === "remove" ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                // backgroundColor: "#cc0000",
              }}
            >
              <Box
                sx={{
                  ml: 1,
                }}
              >
                <IconButton
                  aria-label="Save"
                  color="primary"
                  size="small"
                  // variant="light"
                  onClick={(event) => handleOnClickRemoveConfirm(event)}
                  // disabled={isAiCategoryNameSaveDisabled()}
                  sx={{
                    // ml: 0.5,
                    "&.MuiIconButton-sizeSmall": {
                      fontSize: "16px",
                    },
                  }}
                >
                  <DoneIcon fontSize="inherit" />
                </IconButton>
              </Box>

              <Box>
                <IconButton
                  aria-label="Cancel"
                  color="primary"
                  size="small"
                  // variant="light"
                  onClick={() => handleOnClickAiCategoryEdit()}
                  // disabled={isCategoryAddRemoveEditDisabled("add")}
                  sx={{
                    // ml: 0.5,
                    "&.MuiIconButton-sizeSmall": {
                      fontSize: "16px",
                    },
                  }}
                >
                  <ClearIcon fontSize="inherit" />
                </IconButton>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                // backgroundColor: "#cc0000",
              }}
            >
              <Box
                sx={{
                  ml: 1,
                }}
              >
                <IconButton
                  aria-label="Edit"
                  color="primary"
                  size="small"
                  // variant="light"
                  onClick={() => handleOnClickAiCategoryEdit()}
                  disabled={isCategoryAddRemoveEditDisabled("edit")}
                  sx={{
                    // ml: 0.5,
                    "&.MuiIconButton-sizeSmall": {
                      fontSize: "16px",
                    },
                  }}
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>
              </Box>
              <Box>
                <IconButton
                  aria-label="Add"
                  color="primary"
                  size="small"
                  // variant="light"
                  onClick={() => handleOnClickAiCategoryAdd()}
                  disabled={isCategoryAddRemoveEditDisabled("add")}
                  sx={{
                    // ml: 0.5,
                    "&.MuiIconButton-sizeSmall": {
                      fontSize: "16px",
                    },
                  }}
                >
                  <AddIcon fontSize="inherit" />
                </IconButton>
              </Box>
              <Box>
                <IconButton
                  aria-label="Remove"
                  color="primary"
                  size="small"
                  // variant="light"
                  onClick={() => handleOnClickAiCategoryRemove()}
                  disabled={isCategoryAddRemoveEditDisabled("remove")}
                  sx={{
                    // ml: 0.5,
                    "&.MuiIconButton-sizeSmall": {
                      fontSize: "16px",
                    },
                  }}
                >
                  <RemoveIcon fontSize="inherit" />
                </IconButton>
              </Box>
            </Box>
          )}
        </Box>
        // END Category Select
      );
    }
  };


  // if (clog()) console.log("adminAiPresetAgentSetupTabLoading:", adminAiPresetAgentSetupTabLoading)
  // if (clog()) console.log("adminAiPresetAgentsDocs:", adminAiPresetAgentsDocs)
  // if (clog()) console.log("adminAiPresetAgentsDocsCombined:", adminAiPresetAgentsDocsCombined)

  // if (adminAiPresetAgentSetupTabLoading || !adminAiPresetAgentsDocs) {
  if (adminAiPresetAgentSetupTabLoading) {
    return (
      <LoadingPane
      // text={'Error loading Ecomacy Data'}
      //showSpinner={false}
      />
    );
  }

  if (!adminAiPresetAgentsDocsCombined) {
    return (
      <LoadingPane
      // text={'Error loading Ecomacy Data'}
      //showSpinner={false}
      />
    );
  }

  const test = adminUserData;
  const test1 = adminAiCategoryAgentsDocs;
  const test2 = adminAiPresetAgentsDocs;
  const test3 = adminAiPresetAgentsDocsCombined;

  if (clog()) console.log("adminAiPresetAgentsDocs:", adminAiPresetAgentsDocs.length)
  if (clog()) console.log("adminAiPresetAgentsDocsCombined:", adminAiPresetAgentsDocsCombined.length)
  return (
    <Box width="1154px">
      <Grid container spacing={3} mt={0}>
        <Grid item xs={12} md={12}>
          <Box
            sx={{
              // backgroundColor: '#cc0000',
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {showCategoriesControls()}

            <Box>
              <Button
              aria-label="Save"
              color="success"
              variant="contained"
              sx={{
                minWidth: "70px",
              }}
              onClick={handleOnClickSave}
              disabled={isSaveDisabled()}
          >
              SAVE
            </Button>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            sx={
              {
                // backgroundColor: "#cc0000",
              }
            }
          >
            {
              <Stack spacing={0.5}>
                <Box
                  sx={{
                    bgcolor: "background.paper",
                    pb: "2px",
                  }}
                >
                  <InputLabel sx={{ fontWeight: 700 }}>Agents Collection</InputLabel>
                </Box>
                <MainCard
                  content={false}
                  // codeString={scrollListCodeString}
                >
                  <Box sx={{ width: "100%", height: 390, bgcolor: "background.paper" }}>
                    <List
                      height={600}
                      width="100%"
                      itemSize={56}
                      // data={googleCategoriesRows}
                      // itemCount={googleCategoriesRows.length}
                      data={adminCategoryAgentAiPresetAgentsUpdated ? adminCategoryAgentAiPresetAgentsUpdated : []}
                      itemCount={adminCategoryAgentAiPresetAgentsUpdated ? adminCategoryAgentAiPresetAgentsUpdated.length : 0}
                      overscanCount={5}
                      scrollToItem={0}
                    >
                      {renderSelectedPresetsRows}
                    </List>
                  </Box>
                </MainCard>
              </Stack>
            }
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            sx={
              {
                // backgroundColor: "#cc0000",
              }
            }
          >
            <Stack spacing={0.5}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  // p: 1,
                  // m: 1,
                  bgcolor: "background.paper",
                }}
              >
                <InputLabel sx={{ fontWeight: 700 }}>Agents</InputLabel>
                <Typography variant="h6" align="right" sx={{ textDecoration: "none" }} color="textSecondary">
                  <b>Results:</b>{" "}
                  {
                    // adminAiPresetAgentsDocs.length
                    // exclude header row
                    adminAiPresetAgentsDocsCombined.length - 1
                  }
                </Typography>
              </Box>

              <MainCard content={false}>
                <Box sx={{ width: "100%", height: 390, bgcolor: "background.paper" }}>
                  <List
                    height={800}
                    itemCount={adminAiPresetAgentsDocsCombined.length}
                    itemSize={56}
                    width="100%"
                    // data={adminAiPresetAgentsDocs}
                    // itemCount={adminAiPresetAgentsDocs.length}
                    // data={adminAiPresetAgentsDocsCombined}
                    // overscanCount={5}
                    // scrollToItem={0}
                  >
                    {renderAiPresetsRows}
                  </List>
                </Box>
              </MainCard>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default React.memo(AdminAiPresetAgentSetupTab);
