//const mapsApiKey = process.env.REACT_APP_MAPS_API
import { LatLng, computeOffset } from 'spherical-geometry-js';
// import _ from 'lodash';


const getLatLong = (centerPos, disUnit, col) => {

    const centerLatLong = new LatLng(centerPos.lat, centerPos.lng);

    let newLatLong = centerLatLong

    const ver = col.ver
    const verDirDisArray = ver.split('-')
    const verDir = verDirDisArray[0]
    const verMultiplier = parseInt(verDirDisArray[1])

    let verDegrees = 0
    let verCalc = false

    if ('n' === verDir) {
        verDegrees = 0
        verCalc = true
    }
    else if ('s' === verDir) {
        verDegrees = 180
        verCalc = true
    }

    // process ver
    if (verCalc) {
        const verDistance = disUnit * verMultiplier
        const verComputed = computeOffset(newLatLong, verDistance, verDegrees);
        newLatLong = verComputed
    }


    const hor = col.hor
    const horDirDisArray = hor.split('-')
    const horDir = horDirDisArray[0]
    const horMultiplier = parseInt(horDirDisArray[1])


    let horDegrees = 0
    let horCalc = false

    if ('e' === horDir) {
        horDegrees = 90
        horCalc = true
    }
    else if ('w' === horDir) {
        horDegrees = -90
        horCalc = true
    }

    // process ver
    if (horCalc) {
        const horDistance = disUnit * horMultiplier
        const horComputed = computeOffset(newLatLong, horDistance, horDegrees);
        newLatLong = horComputed
    }

    return newLatLong


}



const buildGridData = (data) => {

    const distanceUnit = data.distance
    const grid = data.gridTemplate

    const centerPos = {
        lat: data.lat,
        lng: data.lng
    };
    // centerPos = center

    // if (clog()) console.log('centerPos:', centerPos)

    // let returnData = []
    let returnData = {}
    // const gridId = 'g' + gridNum

    let rowNum = -1
    let colNum = -1
    let itemNum = -1

    // const grid = grids[gridId]
    if (grid) {
        // loop over rows
        Object.keys(grid).forEach((keyName, keyIndex) => {

            const row = grid[keyName]
            rowNum = keyIndex + 1

            // loop over columns
            Object.keys(row).forEach((keyName, keyIndex) => {
                const col = row[keyName]
                colNum = keyIndex + 1

                const newLatLong = getLatLong(centerPos, distanceUnit, col)

                // const lat = getLat(centerPos, distanceUnit, col.hor)
                // const lng = getLong(centerPos, distanceUnit, col.ver)

                const data = {
                    id: col.id,
                    col: colNum,
                    row: rowNum,
                    lat: newLatLong.latitude,
                    lng: newLatLong.longitude
                }

                // if (clog()) console.log(col.id + ' old lat:' + centerPos.lat)
                // if (clog()) console.log(col.id + ' new lat:' + lat)
                itemNum = itemNum + 1
                // returnData.push(data)
                returnData[itemNum] = data


            })

        })

    }



    // if (clog()) console.log(JSON.stringify(returnData));
    return returnData

}


const generateLatLngTest = async (count) => {

    try {
        const itemCount = count

        let returnData = {
            data: [],
            error: '',
            success: 200
        }

        if (3 === itemCount) {
            returnData.data = [
                {
                    address: '160 City Rd, London EC1V 2NX, UK',
                    lat: 51.527365,
                    lng: -0.088916,
                },
                {
                    address: '162 City Rd, London EC1V 2NX, UK',
                    lat: 51.527447,
                    lng: -0.090032
                },
                {
                    address: '164 City Rd, London EC1V 2NX, UK',
                    lat: 51.527407,
                    lng: -0.090085
                }
            ]
        }
        if (2 === itemCount) {
            returnData.data = [
                {
                    address: '160 City Rd, London EC1V 2NX, UK',
                    lat: 51.527365,
                    lng: -0.088916,
                },
                {
                    address: '162 City Rd, London EC1V 2NX, UK',
                    lat: 51.527447,
                    lng: -0.090032
                }
                // {
                //     lat: 51.291727,
                //     lng: -0.323387
                // },
                // {
                //     lat: 51.28520205852488,
                //     lng: -0.3513527880108058
                // },
                // {
                //     lat: 51.28037039683326,
                //     lng: -0.2971077928936183
                // }
            ]
        }

        if (1 === itemCount) {
            returnData.data = [
                // {
                //     lat: 51.291727,
                //     lng: -0.323387
                // }
                {
                    address: '160 City Rd, London EC1V 2NX, UK',
                    lat: 51.527365,
                    lng: -0.088916,
                }
            ]
        }

        if (0 === itemCount) {
            returnData.data = []
        }

        return returnData


        // const centerLatLong = new LatLng(centerPos.lat, centerPos.lng);

        //let newLatLong = centerLatLong

    } catch (err) {
        console.error(err);
        let returnData = {
            data: [],
            error: 'PlaceId Location fetch failed: ' + err,
            success: 403
        }

        return returnData
    }

}

const generateLatLng = async (data) => {


    try {

        let returnData = {
            data: [],
            error: '',
            success: 0
        }

        // Google Place ID
        let userPlaceId = ''
        if (data.social && data.social.googlePlaceId) {
            userPlaceId = data.social.googlePlaceId
        }

        // Address
        let addressArr = []

        let address = ''
        if (data.address && data.address !== '') {
            address = data.address
            addressArr.push(address)
        }

        let city = ''
        if (data.city && data.city !== '') {
            city = data.city
            addressArr.push(city)
        }

        let state = ''
        if (data.state && data.state !== '') {
            state = data.state
            addressArr.push(state)
        }

        let postalCode = ''
        if (data.postalCode && data.postalCode !== '') {
            postalCode = data.postalCode
            addressArr.push(postalCode)
        }

        // let country = ''
        // if (data.country && data.country !== '') {
        //     if (data.country === 'GB') {
        //         country = 'UK'
        //     }
        //     else {
        //         country = data.country
        //     }
        //     addressArr.push(country)
        // }

        const addressStr = addressArr.join(', ')

        // get lat long

        const geocoder = new window.google.maps.Geocoder();

        let placeIdResultSuccess = 0
        let addressResultSuccess = 0
        let postalCodeResultSuccess = 0

        // get location by placeId
        if (userPlaceId !== '') {
            // userPlaceId = 'ChIJgUbEo8cfqokR5lP9_Wh_DaM'
            const placeIdResult = await getLocationByPlaceId(geocoder, userPlaceId)
            placeIdResultSuccess = placeIdResult.success

            returnData = placeIdResult
        }

        // get location by address
        if (placeIdResultSuccess !== 200) {
            if (addressStr !== '') {
                const addressResult = await getLocationByAddress(geocoder, addressStr)
                addressResultSuccess = addressResult.success

                returnData = addressResult
            }
        }

        // get location by postalCode
        if (placeIdResultSuccess !== 200 && addressResultSuccess !== 200) {
            if (postalCode !== '') {
                const postalCodeResult = await getLocationByPostalCode(geocoder, postalCode)
                postalCodeResultSuccess = postalCodeResult.success
                returnData = postalCodeResult
            }

            if (postalCodeResultSuccess !== 200 ) {
                returnData.success = 500
            }

        }



        return returnData


        // const centerLatLong = new LatLng(centerPos.lat, centerPos.lng);

        //let newLatLong = centerLatLong

    } catch (err) {
        console.error(err);
        let returnData = {
            data: [],
            error: 'PlaceId Location fetch failed: ' + err,
            success: 403
        }

        return returnData
    }

}


const getLocationByPlaceId = async (geocoder, userPlaceId) => {

    return new Promise(function (resolve, reject) {
        // const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ placeId: userPlaceId }, function (results, status) {
            if (status === window.google.maps.GeocoderStatus.OK) {

                // loop over results and build Lat Lng data
                if (results.length > 0) {

                    let itemArr = []

                    for (var i = 0; i < results.length; i++) {
                        const item = results[i]
                        const address = item.formatted_address
                        const lat = item.geometry.location.lat();
                        const lng = item.geometry.location.lng();

                        const itemData = {
                            address: address,
                            lat: lat,
                            lng: lng
                        }

                        itemArr.push(itemData)
                    }

                    const returnData = {
                        data: itemArr,
                        error: '',
                        success: 200
                    }
                    resolve(returnData)
                }
                else {
                    const returnData = {
                        data: [],
                        error: 'No Locations found',
                        success: 401
                    }
                    resolve(returnData)
                }

            } else {
                // alert('Geocode was not successful for the following reason: ' + status);
                const returnData = {
                    data: [],
                    error: 'Geocode was not successful for the following reason: ' + status,
                    success: 402
                }

                resolve(returnData)
            }
        });

    })
}




const getLocationByAddress = async (geocoder, userAddress) => {

    return new Promise(function (resolve, reject) {

        geocoder.geocode({ address: userAddress }, function (results, status) {
            if (status === window.google.maps.GeocoderStatus.OK) {

                // loop over results and build Lat Lng data

                if (results.length > 0) {

                    let itemArr = []

                    for (var i = 0; i < results.length; i++) {
                        const item = results[i]
                        const address = item.formatted_address
                        const lat = item.geometry.location.lat();
                        const lng = item.geometry.location.lng();

                        const itemData = {
                            address: address,
                            lat: lat,
                            lng: lng
                        }

                        itemArr.push(itemData)
                    }


                    const returnData = {
                        data: itemArr,
                        error: '',
                        success: 200
                    }
                    resolve(returnData)
                }
                else {
                    const returnData = {
                        data: [],
                        error: 'No Locations found',
                        success: 401
                    }
                    resolve(returnData)
                }


            } else {
                // alert('Geocode was not successful for the following reason: ' + status);
                const returnData = {
                    data: [],
                    error: 'Geocode was not successful for the following reason: ' + status,
                    success: 402
                }

                resolve(returnData)
            }
        });

    })
    // const centerLatLong = new LatLng(centerPos.lat, centerPos.lng);

    //let newLatLong = centerLatLong

}



const getLocationByPostalCode = async (geocoder, postalCode) => {

    return new Promise(function (resolve, reject) {

        geocoder.geocode({ address: postalCode }, function (results, status) {
            if (status === window.google.maps.GeocoderStatus.OK) {

                // loop over results and build Lat Lng data

                if (results.length > 0) {

                    let itemArr = []

                    for (var i = 0; i < results.length; i++) {
                        const item = results[i]
                        const address = item.formatted_address
                        const lat = item.geometry.location.lat();
                        const lng = item.geometry.location.lng();

                        const itemData = {
                            address: address,
                            lat: lat,
                            lng: lng
                        }

                        itemArr.push(itemData)
                    }


                    const returnData = {
                        data: itemArr,
                        error: '',
                        success: 200
                    }
                    resolve(returnData)
                }
                else {
                    const returnData = {
                        data: [],
                        error: 'No Locations found',
                        success: 401
                    }
                    resolve(returnData)
                }


            } else {
                // alert('Geocode was not successful for the following reason: ' + status);
                const returnData = {
                    data: [],
                    error: 'Geocode was not successful for the following reason: ' + status,
                    success: 402
                }

                resolve(returnData)
            }
        });

    })
    // const centerLatLong = new LatLng(centerPos.lat, centerPos.lng);

    //let newLatLong = centerLatLong

}
/*
const getLocationByPlaceIdOld = async (geocoder, userPlaceId) => {
    // let returnData = {
    //     data: [],
    //     error: '',
    //     success: 0
    // }
    try {
        // const geocoder = new window.google.maps.Geocoder();
        await geocoder.geocode({ placeId: userPlaceId }, function (results, status) {
            if (status == window.google.maps.GeocoderStatus.OK) {
                const test = results

                // loop over results and build Lat Lng data

                if (results.length > 0) {
                    const returnData = {
                        data: results,
                        error: '',
                        success: 200
                    }
                    return (returnData)
                }
                else {
                    const returnData = {
                        data: [],
                        error: 'No Locations found',
                        success: 401
                    }
                    return (returnData)
                }

            } else {
                // alert('Geocode was not successful for the following reason: ' + status);
                const returnData = {
                    data: [],
                    error: 'Geocode was not successful for the following reason: ' + status,
                    success: 402
                }

                return (returnData)
            }
        });

        // const centerLatLong = new LatLng(centerPos.lat, centerPos.lng);

        //let newLatLong = centerLatLong

    } catch (err) {
        console.error(err);
        const returnData = {
            data: [],
            error: 'PlaceId Location fetch failed: ' + err,
            success: 403
        }

        return (returnData)
    }
}

*/

export {
    getLatLong,
    buildGridData,
    generateLatLng,
    generateLatLngTest
}
