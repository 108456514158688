import { initializeApp } from "firebase/app";
import { getAnalytics, isSupported as isSupportedAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import {
  // collection,
  // doc,
  // getDoc,
  // getDocs,
  getFirestore,
} from "firebase/firestore";
import { getAuth, signInWithCustomToken } from "firebase/auth";
// import { getAuth, signInWithCustomToken } from "firebase/auth"
import { getFunctions, httpsCallable } from "firebase/functions";

import { getRemoteConfig, isSupported } from "firebase/remote-config";

// import { getRemoteConfig } from "firebase/remote-config";
// import { fetchAndActivate } from "firebase/remote-config";
// import { getValue } from "firebase/remote-config";

import { getUrlParams } from "./ecomacy";



// const userData = getUrlParams();

// let type = "";
// if (userData.type !== "") {
//   type = userData.type;
// }

// let locationId = "";
// if (userData.locationId !== "") {
//   locationId = userData.locationId;
// }
// let email = "";
// if (userData.email !== "") {
//   email = userData.email;
// }

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
//   measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
// };

const firebaseConfig = {
  apiKey: import.meta.env.VITE_REACT_APP_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_REACT_APP_FIREBASE_PROJECT_ID,
  messagingSenderId: import.meta.env.VITE_REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_REACT_APP_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialise Firebase
const firebase = initializeApp(firebaseConfig);
// const analytics = getAnalytics(firebase);
const analytics = async () => await isSupportedAnalytics && getAnalytics(firebase);
// if (clog()) console.log('getAnalytics')

const projectStorage = getStorage();
const db = getFirestore(firebase);
const auth = getAuth();

const remoteConfig = async () => await isSupported && getRemoteConfig(firebase);
// For development only
// remoteConfig.settings = {
//   minimumFetchIntervalMillis: 30000,
// };

const functions = getFunctions(firebase, "europe-west1");


// const functions = getFunctions(firebase)

// connect to local auth
// connectAuthEmulator(auth, "http://localhost:9099")
// connectAuthEmulator(auth, "http://localhost:5000")


// Initialize Remote Config and get a reference to the service
// // const remoteConfig = getRemoteConfig(firebase);
// const remoteConfig = getRemoteConfig();
// remoteConfig.settings.minimumFetchIntervalMillis = 15000;
// const isFetched = await fetchAndActivate(remoteConfig);
//
// if (isFetched) {
//   if (clog()) console.log("Remote config fetched");
//   let strategyAllowed = getValue(remoteConfig, "strategy_openai_questionchat");
// }
// else {
//   if (clog()) console.log("Remote config not fetched");
// }




const initialiseFirebase = async (req, res, next) => { };

// const test = async (req, res, next) => {
//     const docRef = doc(db, "keywords", 'HwxnNn22f6hjIk7h2vbQ')
//     const snapshot = await getDoc(docRef)
//     let snapshot_data = snapshot.data()
// }

// auth user.
// if they change their email we will loose access
// const authenticate = async (req, res, next) => {
const authenticate = async (urlDataParams) => {
  try {

    let type = "";
    if (urlDataParams.type !== "") {
      type = urlDataParams.type;
    }

    let locationId = "";
    if (urlDataParams.locationId !== "") {
      locationId = urlDataParams.locationId;
    }
    // if admin don't auto-login from url params
    // if (locationId === 'admin') {
    //     return
    // }
    if (type === "admin") {
      return { data: "isAdmin" };
    }

    //STUAUTH
    const user = auth.currentUser;
    if (user) {
      return user;
    } else {
      // call cloud function - seems to have cors issues
      const authorise = httpsCallable(functions, "authorise");
      const token = await authorise({ loc: locationId });
      if (token.data && token.data !== "") {
        const newuser = await signInWithCustomToken(auth, token.data);
        return { data: newuser };
      } else {
        return { error: "no token" };
      }
    }
  } catch (err) {
    console.error(err);
    return { error: err };
  }
};
// BT: better way to do this?
// authenticate()

const getUserToken = async () => {
  try {
    //STUAUTH
    const currentUser = auth.currentUser;
    const token = await currentUser.getIdToken();
    return token;
  } catch (err) {
    console.error(err);
  }
};

// testDb()

//
// save keywords, and grids
//

/*
const saveKeywordData = async (payload) => {
    try {

        // get new grid from firestore

        // loop over set and call dfs to strat search
        // rember to pass callback with key to update on returned call

        post_array.push({
            "language_code": "en",
            "location_code": 2840,
            "keyword": encodeURI("albert einstein")
        })

        const offerRef = db.collection('offers').doc(offerid)
        const doc = await offerRef.get();
        if (!doc.exists) {
            return ({
                error: 'No such document!'
            })
        }

        return {
            data: doc.data()
        }

    } catch (error) {
        return ({
            error: error
        })
    }
}
*/

export {
  auth,
  firebase,
  initialiseFirebase,
  authenticate,
  projectStorage,
  db,
  getUserToken,
  analytics,
  remoteConfig,
};
