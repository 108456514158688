import React, { useEffect, useRef, useContext, useState } from "react";
// import * as React from 'react';
import _ from "lodash";
import Highlight from "react-highlight";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { CustomerAuthContext } from "../helpers/CustomerAuthProvider";
import { AiAuthContext } from "../helpers/AiAuthProvider";
import {setd, debug, setc, clog} from "../helpers/HelperFunctions";

import {
  Box,
  Typography,
  Link,
  Snackbar,
  Alert,
  // Button,
  CircularProgress,
  Chip,
  IconButton,
} from "@mui/material";

import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircleOutlined";
import ErrorIcon from "@mui/icons-material/ErrorOutlined";
import CircleIcon from "@mui/icons-material/CircleOutlined";
import AddIcon from "@mui/icons-material/AddOutlined";
import RemoveIcon from "@mui/icons-material/RemoveOutlined";
import DoneIcon from "@mui/icons-material/DoneOutlined";
import ClearIcon from "@mui/icons-material/ClearOutlined";
import CancelIcon from "@mui/icons-material/Cancel";

import DeleteIcon from "@mui/icons-material/DeleteOutlined";

import { set } from "date-fns";

export default function AiWebCodeDialog(props) {
  const { userData } = props;


  const { adminStatus, setAdminStatus } = useContext(CustomerAuthContext);
  const { aiAgentsSelected, setAiAgentsSelected } = useContext(AiAuthContext);
  const { aiSettingsSelected, setAiSettingsSelected } = useContext(AiAuthContext);

  const { showAiWebCodeDialog, setShowAiWebCodeDialog } = useContext(AiAuthContext);
  // const { saveAdminApiKeys } = useContext(AdminAuthContext);

  const { aiWebCodeDomain, setAiWebCodeDomain } = useContext(AiAuthContext);
  const { aiWebCodeCopyHeader, setAiWebCodeCopyHeader } = useContext(AiAuthContext);
  const { aiWebCodeCopyPage, setAiWebCodeCopyPage } = useContext(AiAuthContext);

  const { getAiWebCodeToken } = useContext(AiAuthContext);

  const [open, setOpen] = React.useState(false);
  const [generatingWebToken, setGeneratingWebToken] = React.useState(false);
  const [savingDomains, setSavingDomains] = React.useState(false);

  const { saveAiAgentsDomains } = useContext(AiAuthContext);

  const [openSnackbar, setOpenSnackbar] = React.useState({
    open: false,
    message: "",
  });

  const [domains, setDomains] = React.useState([]);
  const domainsRef = useRef([]);
  const domainRef = useRef(null);

  const [domainIsEditing, setDomainIsEditing] = React.useState({
    index: -1,
    active: false,
  });

  const domainTextFieldRef = React.useRef(null);


  useEffect(() => {
    if (showAiWebCodeDialog === true) {
      let agentDomains = [];
      if (aiAgentsSelected && aiAgentsSelected.domains) {
        agentDomains = aiAgentsSelected.domains;
      }
      // agentDomains = [
      //   'apple.com',
      //   'google.com',
      // ]
      setDomains(agentDomains);
      domainsRef.current = agentDomains;

      let webtoken = "";
      if (aiAgentsSelected && aiAgentsSelected.webtoken) {
        webtoken = aiAgentsSelected.webtoken;
      }

      const headerCopy = `<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.3/jquery.min.js"></script>
<script>
  (function () {$(document).ready(function () { window.aiagenttoken = '${webtoken}'; })})();
</script>
<script src="https://cdn2b.com/aiagent.min.js"></script>
<link rel="stylesheet" href="https://cdn2b.com/aiagent.min.css">`;

      // if (clog()) console.log("data", data);
      setAiWebCodeCopyHeader(headerCopy);

      const pageCopy = `<style>
  .aiagent-container.ac1 {
    --ca-messagefontsize: 18px;
    --ca-chatinputfontsize: 16px;
    --ca-chatbuttonfontsize: 18px;

    --ca-agent: #F9DB6D;
    --ca-agentmessage: #F9DB6D;
    --ca-agentmessageloading: #000000;
    --ca-agentmessagetext: #000000;

    --ca-user: #29375B;
    --ca-usermessage: #29375B;
    --ca-usermessagetext: #ffffff;

    --ca-chatheight: 400px;
    --ca-chatheightmobile: 400px;
    --ca-chat: #ffffff;
    --ca-chatborder: #cccccc;
    --ca-chatinputborder: #cccccc;
    --ca-chatbutton: #fe5d2d;
    --ca-chatbuttontext: #ffffff;
  }
</style>
<div class="aiagent-container ac1" style="display: none;">
Please type your questions below.
</div>`;
      setAiWebCodeCopyPage(pageCopy);

      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [showAiWebCodeDialog]);


  useEffect(() => {
    if (domains.length === 0) {
      if (domainTextFieldRef.current !== null) domainTextFieldRef.current.focus();
    }

    if (domainIsEditing.active === true) {
      if (domainTextFieldRef.current !== null) domainTextFieldRef.current.focus();
    }
  }, [domainIsEditing, domains]);

  // const handleClickOpen = () => {
  //   setShowSettingsDialog(true);
  // };
  const handleClose = () => {
    setShowAiWebCodeDialog(false);
    // clean states
    setAiWebCodeDomain("");
    setAiWebCodeCopyHeader(null);
    setAiWebCodeCopyPage(null);
  };

  const handleChange = (event) => {
    const val = event.target.value;

    if (domainIsEditing.active === true) {
      setAiWebCodeDomain(val);
      // update domains val at index
      let newDomains = [...domains];
      newDomains[domainIsEditing.index] = val;
      setDomains(newDomains);
    } else {
      setAiWebCodeDomain(val);
    }
  };

  const handleGenerate = async (uData) => {
    if (clog()) console.log("handleGenerate");
    setGeneratingWebToken(true);

    let result = await getAiWebCodeToken(userData, aiAgentsSelected, domains);

    if (result && result.data) {
      const { data } = result;

      // if (clog()) console.log("data", data);
      setAiWebCodeCopyHeader(data);

      const pageData = `<style>
.aiagent-container.ac1 {
	--ca-messagefontsize: 18px;
	--ca-chatinputfontsize: 16px;
	--ca-chatbuttonfontsize: 18px;

  --ca-agent: #F9DB6D;
  --ca-agentmessage: #F9DB6D;
  --ca-agentmessageloading: #000000;
  --ca-agentmessagetext: #000000;

  --ca-user: #29375B;
  --ca-usermessage: #29375B;
  --ca-usermessagetext: #ffffff;

  --ca-chatheight: 400px;
  --ca-chatheightmobile: 400px;
  --ca-chat: #ffffff;
  --ca-chatborder: #cccccc;
  --ca-chatinputborder: #cccccc;
  --ca-chatbutton: #fe5d2d;
  --ca-chatbuttontext: #ffffff;
}
</style>
<div class="aiagent-container ac1" style="display: none;">
Please type your questions below.
</div>`;
      setAiWebCodeCopyPage(pageData);
    }

    // save domains to customer doc
    const agentRes = await saveAiAgentsDomains(aiAgentsSelected.aiagentid, domains);
    if (agentRes && agentRes.data) {
      // update aiAgentsSelected with new domains
      const newAiAgentsSelected = _.cloneDeep(aiAgentsSelected);
      newAiAgentsSelected.domains = domains;
      setAiAgentsSelected(newAiAgentsSelected);

      // set ref equal to disable generate button
      domainsRef.current = domains;
    }

    setGeneratingWebToken(false);
  };

  const isGenerateDisabled = () => {
    let disabled = false;

    if (_.isEqual(domains, domainsRef.current)) {
      disabled = true;
    }

    if (domains.length === 0) {
      disabled = true;
    }

    if (domainIsEditing.active === true) {
      disabled = true;
    }
    return disabled;
  };

  const handleCopyHeaderCode = () => {
    setOpenSnackbar({
      open: true,
      message: "Copied Website Header Code to Clipboard",
    });
    navigator.clipboard.writeText(aiWebCodeCopyHeader);
  };

  const handleCopyPageCode = () => {
    setOpenSnackbar({
      open: true,
      message: "Copied Website Page Code to Clipboard",
    });
    navigator.clipboard.writeText(aiWebCodeCopyPage);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar({
      open: false,
      message: "",
    });
  };

  const handleDomainRemoveClick = async (index) => {
    if (clog()) console.log("handleDomainRemoveClick", index);
    if (domainIsEditing.active === true) {
      setSavingDomains(true);
      const newDomains = [...domains];
      newDomains.splice(index, 1);

      const agentRes = await saveAiAgentsDomains(aiAgentsSelected.aiagentid, newDomains);
      if (agentRes && agentRes.data) {
        setDomains(newDomains);
        // set ref equal to disable generate button
        domainsRef.current = newDomains;

        // update aiAgentsSelected with new domains
        const newAiAgentsSelected = _.cloneDeep(aiAgentsSelected);
        newAiAgentsSelected.domains = newDomains;
        setAiAgentsSelected(newAiAgentsSelected);
      }
      setSavingDomains(false);
    }
  };

  const handleDomainClick = (index) => {
    // if (clog()) console.log("handleDomainClick", index);

    if (domainIsEditing.active === true && domainIsEditing.index !== index) {
      setAiWebCodeDomain(domains[index]);
      domainRef.current = domains[index];
      setDomainIsEditing({
        index: index,
        active: true,
      });
    } else if (domainIsEditing.active === true) {
      setAiWebCodeDomain("");
      domainRef.current = null;
      setDomainIsEditing({
        index: -1,
        active: false,
      });
    } else {
      setAiWebCodeDomain(domains[index]);
      domainRef.current = domains[index];
      setDomainIsEditing({
        index: index,
        active: true,
      });

    }
  };

  const cleanDomain = (d) => {
    let domain = (" " + d).slice(1);

    let cleanedDomain = domain.replace(/^https?:\/\//, "");
    cleanedDomain = cleanedDomain.replace(/^http?:\/\//, "");
    // remove spaces
    cleanedDomain = cleanedDomain.replace(/ /g, "");

    return cleanedDomain;
  };
  const isAddDomainDisabled = () => {
    let disabled = true;

    // let domain = (" " + aiWebCodeDomain).slice(1);

    // let cleanedDomain = domain.replace(/^https?:\/\//, "");
    // cleanedDomain = cleanedDomain.replace(/^http?:\/\//, "");
    // // remove spaces
    // cleanedDomain = cleanedDomain.replace(/ /g, "");

    const cleanedDomain = cleanDomain(aiWebCodeDomain);

    if (cleanedDomain !== "") {
      const foundDomain = _.indexOf(domains, cleanedDomain);
      if (foundDomain === -1) {
        disabled = false;
      }
    }

    if (domainIsEditing.active === true && domainRef.current !== aiWebCodeDomain) {
      disabled = false;
    }

    if (aiWebCodeDomain === "") {
      disabled = true;
    }

    return disabled;
  };

  const handleAddDomain = async () => {
    if (domainIsEditing.active === true) {
      setSavingDomains(true);
      // save domains to customer doc
      const agentRes = await saveAiAgentsDomains(aiAgentsSelected.aiagentid, domains);

      if (agentRes && agentRes.data) {
        domainsRef.current = domains;

        // update aiAgentsSelected with new domains
        const newAiAgentsSelected = _.cloneDeep(aiAgentsSelected);
        newAiAgentsSelected.domains = domains;
        setAiAgentsSelected(newAiAgentsSelected);

        setSavingDomains(false);
        setDomainIsEditing({
          index: -1,
          active: false,
        });
      }
    } else {
      setSavingDomains(true);

      // temp: generate webtoken
      // let result = await getAiWebCodeToken(userData, aiAgentsSelected, domains);
      // if (result && result.data) {
      //   const test = result.data;
      //   debugger
      // }

      const newDomains = [...domains];

      // check for commas
      if (aiWebCodeDomain.indexOf(",") !== -1) {
        // remove spaces
        let aiWebCodeDomainNoSpaces = aiWebCodeDomain.replace(/ /g, "");
        const domainsToAdd = aiWebCodeDomainNoSpaces.split(",");
        domainsToAdd.forEach((domain) => {
          const cleanedDomain = cleanDomain(domain);

          if (cleanedDomain !== "") {
            const foundDomain = _.indexOf(domains, cleanedDomain);
            if (foundDomain === -1) {
              newDomains.push(cleanedDomain);
            }
          }
        });
      } else {
        const cleanedDomain = cleanDomain(aiWebCodeDomain);

        if (cleanedDomain !== "") {
          const foundDomain = _.indexOf(domains, cleanedDomain);
          if (foundDomain === -1) {
            newDomains.push(cleanedDomain);
          }
        }
      }

      // save domains to customer doc
      const agentRes = await saveAiAgentsDomains(aiAgentsSelected.aiagentid, newDomains);
      if (agentRes && agentRes.data) {
        setDomains(newDomains);
        // set ref equal to disable add button
        domainsRef.current = newDomains;

        // update aiAgentsSelected with new domains
        const newAiAgentsSelected = _.cloneDeep(aiAgentsSelected);
        newAiAgentsSelected.domains = newDomains;
        setAiAgentsSelected(newAiAgentsSelected);
      }
      setSavingDomains(false);
    }

    setAiWebCodeDomain("");
  };

  const handleEditDomainCancel = () => {
    // update domains val at index with original value
    let newDomains = [...domains];
    newDomains[domainIsEditing.index] = domainRef.current;
    setDomains(newDomains);

    setAiWebCodeDomain("");
    domainRef.current = null;
    setDomainIsEditing({
      index: -1,
      active: false,
    });
  };

  // const handleOnClickSave = async (event) => {
  //   let result;
  //   if (clog()) console.log("handleOnClickSave");

  //   setAdminApiKeysSaving(true);

  //   const apiKeys = {
  //     eapiKey: eapiKey,
  //     openaiKey: openaiKey,
  //     anthropicKey: anthropicKey,
  //   };
  //   // result = await saveAiSettingsX(userData, aiConversation, aiSettingsDataUpdated, lastMessageRole);
  //   result = await saveAdminApiKeys(adminUserData, apiKeys);
  //   if (result && result.data) {
  //     const { data } = result;

  //     // trigger useEffect in AiSettingsPanel to load new settings
  //     // setAiSettingsDocsRefresh(val)
  //     setAdminApiKeysSaving(false);
  //     setShowSettingsDialog(false);
  //     // reload admin
  //     // getAdminUserData();
  //     getAdminUserData(adminStatus);
  //   }
  // };

  // const isSaveDisabled = () => {
  //   let disabled = false;

  //   if (eapiKey === "") {
  //     disabled = true;
  //   }

  //   if (eapiKey !== "" && eapiKey === eapiKeyRef.current) {
  //     disabled = true;
  //   }

  //   return disabled;
  // };

  // const showApiStatusIcon = (type) => {
  //   const apiKeyStatus = adminApiKeyStatus[type];

  //   if (apiKeyStatus === "valid") {
  //     return <CheckCircleIcon color="success" />;
  //   } else if (apiKeyStatus === "invalid") {
  //     return <ErrorIcon color="error" />;
  //   } else {
  //     return <CircleIcon />;
  //   }
  // };

  const domainInputAutoFocus = () => {

    if (domains.length === 0) {
      if (domainTextFieldRef.current !== null) domainTextFieldRef.current.focus();
    }

    if (domainIsEditing.active === true) {
      if (domainTextFieldRef.current !== null) domainTextFieldRef.current.focus();
    }

    return;
  }
  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={openSnackbar.open}
        onClose={() => handleSnackbarClose()}
        autoHideDuration={4000}
      // message="Copied to clipboard"
      // disabled
      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: "100%" }} variant="filled">
          {openSnackbar.message}
        </Alert>
      </Snackbar>
      <Dialog
        fullWidth
        sx={{
          minWidth: "500px",
          minHeight: "300px",
        }}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Website Code</DialogTitle>
        <DialogContent
          dividers={true}
        >
          <DialogContentText
            sx={{
              textAlign: "center",
            }}
          >
            Generate the code needed for Ai Agent Tools to work on your website.

          </DialogContentText>

          <Box
            name="domain"
            sx={{
              mt: 3,
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontWeight: "bold",
                }}
              >
                Step 1: Add your Domain Names
              </Typography>
            </Box>

            <Box
              sx={{
                mt: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                }}
              >
                <TextField
                  autoFocus
                  //autoFocus={domainInputAutoFocus()}
                  inputRef={domainTextFieldRef}
                  // margin="dense"
                  id="Website URL"
                  // label="Agency API Key"
                  type="websiteUrl"
                  fullWidth
                  variant="outlined"
                  value={aiWebCodeDomain}
                  // variant="standard"
                  onChange={(event) => handleChange(event)}
                  onKeyPress={(event) => {
                    if (event.key === "Enter")
                      if (isAddDomainDisabled() === false) {
                        // if (clog()) console.log('Enter Pressed')
                        handleAddDomain();
                      }
                  }}
                  InputProps={
                    {
                      // endAdornment: domainIsEditing.active ? (
                      //   <Box
                      //     sx={{
                      //       display: "flex",
                      //       flexDirection: "row",
                      //     }}
                      //   >
                      //     <IconButton
                      //       aria-label="Cancel"
                      //       color="primary"
                      //       onClick={() => handleEditDomainCancel()}
                      //       disabled={aiWebCodeDomain === "" ? true : false}
                      //       //
                      //     >
                      //       <ClearIcon fontSize="inherit" />
                      //     </IconButton>
                      //     <IconButton
                      //       aria-label="Add"
                      //       color="primary"
                      //       onClick={() => handleAddDomain()}
                      //       // disabled={aiWebCodeDomain === "" ? true : false}
                      //       disabled={isAddDomainDisabled()}
                      //     >
                      //       <DoneIcon fontSize="inherit" />
                      //     </IconButton>
                      //   </Box>
                      // ) : (
                      //   <IconButton
                      //     aria-label="Add"
                      //     color="primary"
                      //     variant="contained"
                      //     onClick={() => handleAddDomain()}
                      //     // disabled={aiWebCodeDomain === "" ? true : false}
                      //     disabled={isAddDomainDisabled()}
                      //     //
                      //   >
                      //     <AddIcon fontSize="inherit" />
                      //   </IconButton>
                      // ),
                      // sx: {
                      //   pr: "1px",
                      // },
                    }
                  }
                />
              </Box>

              {domainIsEditing.active === true && (
                <Box
                  sx={{
                    ml: 2,
                  }}
                >
                  {savingDomains ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "72px",
                        // height: "36.5px",
                        // backgroundColor: "primary.main",
                      }}
                    >
                      <CircularProgress
                        size={20}
                        sx={
                          {
                            // color: green[500],
                            // position: "absolute",
                            // top: "50%",
                            // left: "50%",
                            // marginTop: "-10px",
                            // marginLeft: "-10px",
                          }
                        }
                      />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <IconButton
                        aria-label="Cancel"
                        color="primary"
                        onClick={() => handleEditDomainCancel()}
                      // disabled={aiWebCodeDomain === "" ? true : false}
                      //
                      >
                        <ClearIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton
                        aria-label="Save"
                        color="primary"
                        onClick={() => handleAddDomain()}
                        // disabled={aiWebCodeDomain === "" ? true : false}
                        disabled={isAddDomainDisabled()}
                      >
                        <DoneIcon fontSize="inherit" />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              )}

              {domainIsEditing.active === false && (
                <Box
                  sx={{
                    ml: 2,
                  }}
                >
                  {savingDomains ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "72px",
                        // height: "36.5px",
                        // backgroundColor: "primary.main",
                      }}
                    >
                      <CircularProgress
                        size={20}
                        sx={
                          {
                            // color: green[500],
                            // position: "absolute",
                            // top: "50%",
                            // left: "50%",
                            // marginTop: "-10px",
                            // marginLeft: "-10px",
                          }
                        }
                      />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        width: "72px",
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={() => handleAddDomain()}
                        disabled={isAddDomainDisabled()}
                        sx={{
                          width: "72px",
                        }}
                      >
                        Add
                      </Button>
                    </Box>
                  )}
                </Box>
              )}

              {/* <Box
                sx={{
                  ml: 2,
                }}
              >
                {generatingWebToken ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "91px",
                      // height: "36.5px",
                      // backgroundColor: "primary.main",
                    }}
                  >
                    <CircularProgress
                      size={20}
                      sx={
                        {
                          // color: green[500],
                          // position: "absolute",
                          // top: "50%",
                          // left: "50%",
                          // marginTop: "-10px",
                          // marginLeft: "-10px",
                        }
                      }
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      width: "91px",
                    }}
                  >
                    <Button variant="contained" onClick={() => handleGenerate(userData)} disabled={isGenerateDisabled()}>
                      Generate
                    </Button>
                  </Box>
                )}
              </Box> */}
            </Box>

            {domains && domains.length > 0 ? (
              <Box
                sx={{
                  mt: 1,
                }}
              >
                {domains.map((domain, index) => {
                  if (domainIsEditing.active === true && domainIsEditing.index === index) {
                    return (
                      <Chip
                        key={"domain-" + index}
                        label={domain}
                        // variant="outlined"
                        color="primary"
                        sx={{
                          mb: 1,
                          mr: 1,
                        }}
                        onClick={() => handleEditDomainCancel(index)}
                        onDelete={() => handleDomainRemoveClick(index)}
                        deleteIcon={<DeleteIcon />}

                      />
                    );
                  } else {
                    return (
                      <Chip
                        key={"domain-" + index}
                        label={domain}
                        // variant="outlined"
                        sx={{
                          mb: 1,
                          mr: 1,
                        }}
                        onClick={() => handleDomainClick(index)}
                      // deleteIcon={
                      //   <IconButton
                      //   disabled
                      //   sx={{
                      //     p: "0px",
                      //     m: "0px",
                      //     width: "auto",
                      //   }}
                      //   >
                      //     <DeleteIcon />
                      //   </IconButton>
                      // }
                      // onDelete={() => handleDomainRemoveClick(index)}
                      />
                    );
                  }
                })}
              </Box>
            ) : (
              <Box
                sx={{
                  mt: 1,
                  height: 40,
                }}
              ></Box>
            )}
          </Box>

          <Box
            name="codeHeader"
            sx={{
              mt: 1,
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontWeight: "bold",
                  color: aiWebCodeCopyHeader ? "inherit" : "text.disabled",
                }}
              >
                Step 2: Copy Code To Website Header
              </Typography>
            </Box>

            {aiWebCodeCopyHeader ? (
              <Box
                name="codeHolder"
                sx={{
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(24, 144, 255,0.7)",
                    borderRadius: "4px",
                  }}
                  onClick={handleCopyHeaderCode}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    <FileCopyIcon
                      // color="primary"
                      sx={{
                        color: "#fff",
                        fontSize: 30,
                        mb: 1,
                      }}
                    />
                    Click To Copy
                  </Box>
                </Box>
                <Box
                  sx={{
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    mt: 1,
                    px: 2,
                    minHeight: "165px",
                    maxWidth: "100%",
                    overflow: "auto",
                  }}
                >
                  {
                    // <Highlight language="javascript">{`function foo() { return 'bar' }`}</Highlight>
                  }
                  <Highlight language="javascript">{aiWebCodeCopyHeader}</Highlight>

                  {
                    // <TextField
                    //   // autoFocus
                    //   // margin="dense"
                    //   id="websiteCode"
                    //   // label="Website Code"
                    //   type="websiteCode"
                    //   fullWidth
                    //   variant="outlined"
                    //   InputProps={{
                    //     readOnly: true,
                    //   }}
                    //   multiline
                    //   rows={5}
                    //   value={aiWebCodeCopyHeader}
                    //   onClick={handleCopyHeaderCode}
                    //   // onChange={(event) => handleChange(event)}
                    // />
                  }
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  mt: 1,
                  px: 2,
                  minHeight: "200px",
                }}
              ></Box>
            )}
          </Box>

          <Box
            name="codePage"
            sx={{
              mt: 3,
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontWeight: "bold",
                  color: aiWebCodeCopyPage ? "inherit" : "text.disabled",
                }}
              >
                Step 3: Copy Code To Website Page
              </Typography>
            </Box>

            {aiWebCodeCopyPage ? (
              <Box
                name="codeHolder"
                sx={{
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(24, 144, 255,0.7)",
                    borderRadius: "4px",
                  }}
                  onClick={handleCopyPageCode}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    <FileCopyIcon
                      // color="primary"
                      sx={{
                        color: "#fff",
                        fontSize: 30,
                        mb: 1,
                      }}
                    />
                    Click To Copy
                  </Box>
                </Box>
                <Box
                  sx={{
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    mt: 1,
                    px: 2,
                    minHeight: "96px",
                    maxHeight: "200px",
                    maxWidth: "100%",
                    overflow: "auto",
                  }}
                >
                  {<Highlight language="javascript">{aiWebCodeCopyPage}</Highlight>}
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  mt: 1,
                  px: 2,
                  minHeight: "96px",
                }}
              ></Box>
            )}
          </Box>

          {
            // <Box
            //   name="code"
            //   sx={{
            //     mt: 3,
            //   }}
            // >
            //   <Box>
            //     <Typography
            //       sx={{
            //         fontWeight: "bold",
            //       }}
            //     >
            //       Step 3: Copy Code To Web Page
            //     </Typography>
            //   </Box>
            //   <Box
            //     sx={{
            //       mt: 1,
            //     }}
            //   >
            //     <TextField
            //       // autoFocus
            //       // margin="dense"
            //       id="websiteCode"
            //       // label="Website Code"
            //       type="websiteCode"
            //       fullWidth
            //       variant="outlined"
            //       InputProps={{
            //         readOnly: true,
            //       }}
            //       multiline
            //       rows={5}
            //       value={aiWebCodeCopyHeader ? aiWebCodeCopyHeader : ""}
            //       onClick={handleCopyHeaderCode}
            //       // onChange={(event) => handleChange(event)}
            //     />
            //   </Box>
            // </Box>
          }

          {
            // <Box
            //   sx={{
            //     mt: 3,
            //   }}
            // >
            //   <Button variant="contained" onClick={handleCopyHeaderCode} disabled={isGenerateDisabled()}>
            //     Copy Website Code
            //   </Button>
            // </Box>
          }

          {
            // <Box
            //   sx={{
            //     mt: 1,
            //   }}
            // >
            //   <Typography
            //     sx={
            //       {
            //         // fontWeight: "bold",
            //       }
            //     }
            //   >
            //     To find your Agency API Key{" "}
            //     <Link target="_blank" rel="noopener" href="https://app.gohighlevel.com/settings/api_key">
            //       click here
            //     </Link>
            //     .
            //   </Typography>
            // </Box>
          }
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
