import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  // useCallback
} from "react";
import PropTypes from "prop-types";

import {
  getFirestore,
  collection,
  query,
  where,
  // orderBy
} from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { firebase } from "../code/firebase";
import { processKeywordsUserData } from "../code/ecomacy";

// third-party
import { FixedSizeList } from "react-window";

import { RemoteConfigAuthContext } from "../helpers/RemoteConfigAuthProvider";
import { CustomerAuthContext } from "../helpers/CustomerAuthProvider";

import { AiAuthContext } from "../helpers/AiAuthProvider";
import { setd, debug, setc, clog } from "../helpers/HelperFunctions";

import _ from "lodash";

import {
  useTheme,
  // styled
} from "@mui/material/styles";

import {
  Box,
  //Tab,
  //Tabs,
  Typography,
  IconButton,
  Grid,
  Paper,
  Slider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  CircularProgress,
} from "@mui/material";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import LinkIcon from "@mui/icons-material/LinkOutlined";
import LinkOffIcon from "@mui/icons-material/LinkOffOutlined";
import AddIcon from "@mui/icons-material/AddOutlined";
import RemoveIcon from "@mui/icons-material/RemoveOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/EditOutlined";
import SaveIcon from "@mui/icons-material/SaveOutlined";
import ClearIcon from "@mui/icons-material/ClearOutlined";
import DoneIcon from "@mui/icons-material/DoneOutlined";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";

import DotCircleIcon from "@mui/icons-material/RadioButtonChecked"
import CircleIcon from "@mui/icons-material/RadioButtonUnchecked"
import DotIcon from "@mui/icons-material/FiberManualRecord"

import Container from "@mui/material/Container";
import GridKeywordTimelinePane from "./GridKeywordTimelinePane";
import LoadingPane from "./LoadingPane";
import LoadingSubPane from "./LoadingSubPane";
import { set } from "date-fns";
import { ref } from "firebase/storage";

// import MainCard from './MainCard';
// import { getDfsLocationData } from '../code/ecomacy'

// import './GridKeywordsPane.css'
// const auth = getAuth(firebase)

const doubleClickMs = 250;

const AiHistoryPanel = (props) => {
  const theme = useTheme();

  const { rc } = useContext(RemoteConfigAuthContext);

  const { userData } = props;
  const { adminStatus, setAdminStatus } = useContext(CustomerAuthContext);

  const { aiAgentsSelected, setAiAgentsSelected } = useContext(AiAuthContext);

  const { aiSettingsData, setAiSettingsData } = useContext(AiAuthContext);
  const { aiSettingsDataUpdated, setAiSettingsDataUpdated } = useContext(AiAuthContext);
  const { aiSettingsIndexIdSelected, setAiSettingsIndexIdSelected } = useContext(AiAuthContext);
  const { aiSettingsIndexIdSelectedRefresh, setAiSettingsIndexIdSelectedRefresh } = useContext(AiAuthContext);
  const { aiMessagesDoc, setAiMessagesDoc } = useContext(AiAuthContext);
  const { aiMessagesIndexId, setAiMessagesIndexId } = useContext(AiAuthContext);
  // const { aiSettingsIsRemoving, setAiSettingsIsRemoving } = useContext(AiAuthContext);

  const { aiSettingsItemsSwitchPromptsSelected, setAiSettingsItemsSwitchPromptsSelected } = useContext(AiAuthContext);

  const [aiSettingsItemName, setAiSettingsItemName] = useState("");
  const { saveAiSettingsIndexItemName } = useContext(AiAuthContext);
  const [aiSettingsItemNameSaving, setAiSettingsItemNameSaving] = useState(false);

  // const { updateAiMessagesSwitchPrompt } = useContext(AiAuthContext);

  const { removeAiSettingsIndexItem } = useContext(AiAuthContext);

  const { aiSettingsDataConversationIdRef } = useContext(AiAuthContext);

  const { duplicateAiSettingsItem } = useContext(AiAuthContext);


  const { aiModelSelected, setAiModelSelected } = useContext(AiAuthContext);

  const { aiSettingsDataRef } = useContext(AiAuthContext);
  const { aiModelSelectedRefresh, setAiModelSelectedRefresh } = useContext(AiAuthContext);
  const { aiStrategySelectedRefresh, setAiStrategySelectedRefresh } = useContext(AiAuthContext);

  const { aiSettingsSelected, setAiSettingsSelected } = useContext(AiAuthContext);
  const { aiSettingsSelectedRefresh } = useContext(AiAuthContext);

  const { aiConversationsDocs, setAiConversationsDocs } = useContext(AiAuthContext);
  const { aiConversationsDocsRefresh, setAiConversationsDocsRefresh } = useContext(AiAuthContext);

  const { aiConversationsSelected, setAiConversationsSelected } = useContext(AiAuthContext);
  const { getAiConversationsDocs } = useContext(AiAuthContext);
  const { aiConversationDuplicatedRefresh, setAiConversationDuplicatedRefresh } = useContext(AiAuthContext);

  const { aiConversation, setAiConversation } = useContext(AiAuthContext);

  const { aiAgentsIsEditing, setAiAgentsIsEditing } = useContext(AiAuthContext);
  const { aiSettingsIsEditing, setAiSettingsIsEditing } = useContext(AiAuthContext);
  const { aiConversationsIsEditing, setAiConversationsIsEditing } = useContext(AiAuthContext);
  const { aiConversationsIsRemoving, setAiConversationsIsRemoving } = useContext(AiAuthContext);

  const { refreshAiConversationSettingsData, setRefreshAiConversationSettingsData } = useContext(AiAuthContext);

  const [aiConversationsName, setAiConversationsName] = useState("");
  const { saveAiConversationsName } = useContext(AiAuthContext);
  const [aiConversationsNameSaving, setAiConversationsNameSaving] = useState(false);

  const { aiConversationFetchData, setAiConversationFetchData } = useContext(AiAuthContext);

  const { duplicateAiConversation } = useContext(AiAuthContext);
  const { removeAiConversation } = useContext(AiAuthContext);

  // const { saveAiSettingsAutomationLink } = useContext(AiAuthContext);
  // const [automationLinkSaving, setAutomationLinkSaving] = useState(false);

  const { saveAiSettingsTemplate } = useContext(AiAuthContext);
  const [savePresetSaving, setSavePresetSaving] = useState(false);
  const { showSavePresetAlert, setShowSavePresetAlert } = useContext(AiAuthContext);
  const { saveAiPreset, setsaveAiPreset } = useContext(AiAuthContext);

  const [init, setInit] = useState(true);

  const [model, setModel] = useState({});


  /* --------------------- START: Double Click --------------------- */

  const selectedConversationDocIndexRef = useRef(-1);
  const selectedConversationDocDataRef = useRef({});

  const [clicks, setClicks] = useState(0);

  useEffect(() => {
    let singleClickTimer;
    if (clicks === 1) {
      singleClickTimer = setTimeout(function () {
        handleSingleClick();
        setClicks(0);
      }, doubleClickMs);
    } else if (clicks === 2) {
      handleDoubleClick();
      setClicks(0);
    }
    return () => clearTimeout(singleClickTimer);
  }, [clicks]);

  /* ---------------------- END: Double Click ---------------------- */

  // if (clog()) console.log('AiModelPanel aiSettingsData', aiSettingsData);

  useEffect(() => {
    setInit(false);
  }, [init]);




  useEffect(() => {
    if (
      aiSettingsIndexIdSelectedRefresh === true &&
      aiMessagesDoc !== null &&
      aiMessagesDoc.id === aiConversationsSelected.aiconversationid
    ) {
      const test1 = aiSettingsIndexIdSelected
      const test2 = aiMessagesDoc

      // conversation exists
      if (aiConversationsSelected) {
        // BT debugger
        let settingsIndex = -1;

        if (aiMessagesDoc && aiMessagesDoc.noDocYet === true) {
          // no message doc yet so use first aiSettingsIndex
          const convData = aiConversationsSelected
          // BT aisettings
          // let settingsIndex = -1;
          let aisettingsindexid = "";
          let settingsName = "";
          if (convData && convData.aisettings && convData.aisettings.length > 0) {
            settingsIndex = 0;
            aisettingsindexid = convData.aisettings[settingsIndex].aisettingsindexid;
            settingsName = convData.aisettings[settingsIndex].name;
          }

          // update so we know what aisettingsindexid to search promptversions for
          setAiSettingsIndexIdSelected({
            index: settingsIndex,
            aisettingsindexid: aisettingsindexid,
            aiconversationid: convData.aiconversationid,
            name: settingsName,
          })

          const messagesIndexId = settingsIndex + 1;
          setAiMessagesIndexId({
            index: settingsIndex,
            name: messagesIndexId.toString(),
            aimessagesid: convData.aiconversationid,
            aiconversationid: convData.aiconversationid,
            exists: false,
          });
        }
        else {

          // WORKING BUT WRONG
          // const convData = aiConversationsSelected
          // // BT aisettings
          // // let settingsIndex = -1;
          // let aisettingsindexid = "";
          // let settingsName = "";
          // if (convData && convData.aisettings && convData.aisettings.length > 0) {
          //   settingsIndex = 0;
          //   aisettingsindexid = convData.aisettings[settingsIndex].aisettingsindexid;
          //   settingsName = convData.aisettings[settingsIndex].name;
          // }

          // // update so we know what aisettingsindexid to search promptversions for
          // setAiSettingsIndexIdSelected({
          //   index: settingsIndex,
          //   aisettingsindexid: aisettingsindexid,
          //   aiconversationid: convData.aiconversationid,
          //   name: settingsName,
          // })

          // const messagesIndexId = settingsIndex + 1;
          // setAiMessagesIndexId({
          //   index: settingsIndex,
          //   name: messagesIndexId.toString(),
          //   aimessagesid: convData.aiconversationid,
          //   aiconversationid: convData.aiconversationid,
          //   exists: false,
          // });
          // NEW: WORKING
          // message doc exists so use switch_prompt if it exists
          //let settingsIndex = -1;
          const messagesDoc = aiMessagesDoc
          const convData = aiConversationsSelected
          let switchPrompt = '';
          let switchPromptInt = -1;
          if (messagesDoc && messagesDoc.switch_prompt && messagesDoc.switch_prompt !== "") {
            switchPrompt = messagesDoc.switch_prompt;
          }
          if (switchPrompt !== "") {
            switchPromptInt = parseInt(switchPrompt) - 1;
          }

          // BT aisettings
          let aisettingsindexid = "";
          let settingsName = "";
          if (convData && convData.aisettings && convData.aisettings.length > 0) {
            settingsIndex = 0;
            if (switchPromptInt > 0) {
              settingsIndex = switchPromptInt;
            }
            aisettingsindexid = convData.aisettings[settingsIndex].aisettingsindexid;
            settingsName = convData.aisettings[settingsIndex].name;
          }

          // update so we know what aisettingsindexid to search promptversions for
          setAiSettingsIndexIdSelected({
            index: settingsIndex,
            aisettingsindexid: aisettingsindexid,
            aiconversationid: convData.aiconversationid,
            name: settingsName,
          })

          setAiMessagesIndexId({
            index: switchPromptInt,
            name: switchPrompt,
            aimessagesid: convData.aiconversationid,
            aiconversationid: convData.aiconversationid,
            exists: true,
          });
        }

      }
      else {
        // conversation does not exist
        setAiSettingsIndexIdSelected({
          index: -1,
          aisettingsindexid: "",
          aiconversationid: "",
          name: "",
        })

        setAiMessagesIndexId({
          index: -1,
          name: "",
          aimessagesid: "",
          aiconversationid: "",
          exists: false,
        });
      }

      setAiSettingsIndexIdSelectedRefresh(false)
    }
    else if (
      aiMessagesDoc !== null &&
      aiMessagesDoc.id === aiConversationsSelected.aiconversationid
    ) {
      // if aiMessagesDoc.switch_prompt exists and is different from aiMessagesIndexId.name
      if (aiMessagesDoc.switch_prompt && aiMessagesDoc.switch_prompt !== aiMessagesIndexId.name) {
        const switchPrompt = aiMessagesDoc.switch_prompt;
        const switchPromptInt = parseInt(switchPrompt) - 1;
        // debugger
        setAiMessagesIndexId({
          index: switchPromptInt,
          name: switchPrompt,
          aimessagesid: aiMessagesDoc.id,
          aiconversationid: aiMessagesDoc.id,
          exists: true,
        });
      }
    }
  }, [aiSettingsIndexIdSelectedRefresh, aiMessagesDoc]);


  // BT ZZZ
  // triggered when aiSettingsDataUpdated changes
  // & aiConversationsDocsRefresh is true
  useEffect(() => {
    // declare the data fetching function
    const fetchAiConversationsDocs = async () => {
      const returnData = await getAiConversationsDocs(userData);
      if (returnData && returnData.data) {
        if (clog()) console.log("AiHistoryPanel - useEffect [aiConversationsDocsRefresh] returnData.data", returnData.data);
        // set in getAiSettingsDocs function
        // setAiConversationsDocs(returnData.data)
        // if aiconversations exist, set the first one as selected
        if (returnData.data.length > 0) {
          const convData = returnData.data[0]
          // BT aisettings
          // let settingsIndex = -1;
          // let aisettingsindexid = "";
          // let settingsName = "";
          // if (convData && convData.aisettings && convData.aisettings.length > 0) {
          //   settingsIndex = 0;
          //   aisettingsindexid = convData.aisettings[0].aisettingsindexid;
          //   settingsName = convData.aisettings[0].name;
          // }
          // update so we know what aisettingsindexid to search promptversions for
          // setAiSettingsIndexIdSelected({
          //   index: settingsIndex,
          //   aisettingsindexid: aisettingsindexid,
          //   aiconversationid: convData.aiconversationid,
          //   name: settingsName,
          // })

          setAiConversationsSelected(convData);

          setAiSettingsIndexIdSelectedRefresh(true)

        } else {
          // no conversations exist
          // setAiSettingsIndexIdSelected({
          //   index: -1,
          //   aisettingsindexid: "",
          //   aiconversationid: "",
          //   name: "",
          // })
          setAiConversationsSelected(null);

          setAiSettingsIndexIdSelectedRefresh(true)

        }
      }
    };
    // CHECK: does the init need to exist?
    // if (!init && aiConversationsDocsRefresh) {
    if (aiConversationsDocsRefresh) {
      // if (clog()) console.log("AiHistoryPanel - useEffect [aiConversationsDocsRefresh] setAiConversationsSelected")

      // call the function
      // BT ZZZ
      fetchAiConversationsDocs(userData)
        // make sure to catch any error
        .catch(console.error);

      setAiConversationsDocsRefresh(false);
    }
    // }, [aiSettingsDataUpdated])
  }, [aiConversationsDocsRefresh]);

  // triggered when aiconversation is duplicated
  // & aiConversationsDocsRefresh is true
  useEffect(() => {
    // declare the data fetching function
    const fetchAiConversationsDocs = async () => {
      const returnData = await getAiConversationsDocs(userData);
      if (returnData && returnData.data) {
        if (clog()) console.log("AiHistoryPanel - useEffect [aiConversationDuplicatedRefresh] returnData.data", returnData.data);
        // set in getAiSettingsDocs function
        // setAiConversationsDocs(returnData.data)
        // BT ZZZ
        // if aiconversations exist, set the first one as selected
        if (returnData.data.length > 0) {
          debugger

          const convData = returnData.data[0]


          // BT aisettings
          // let settingsIndex = -1;
          // let aisettingsindexid = "";
          // let settingsName = "";
          // if (convData && convData.aisettings && convData.aisettings.length > 0) {
          //   settingsIndex = 0;
          //   aisettingsindexid = convData.aisettings[0].aisettingsindexid;
          //   settingsName = convData.aisettings[0].name;
          // }
          // update so we know what aisettingsindexid to search promptversions for
          // setAiSettingsIndexIdSelected({
          //   index: settingsIndex,
          //   aisettingsindexid: aisettingsindexid,
          //   aiconversationid: convData.aiconversationid,
          //   name: settingsName,
          // })

          // setAiConversationsSelected(returnData.data[0]);
          setAiConversationsSelected(convData);

          setAiSettingsIndexIdSelectedRefresh(true)
        }
      }
    };

    if (!init && aiConversationDuplicatedRefresh) {
      // call the function
      fetchAiConversationsDocs(userData)
        // make sure to catch any error
        .catch(console.error);

      setAiConversationDuplicatedRefresh(false);
    }
  }, [aiConversationDuplicatedRefresh]);



  // triggered when refreshAiConversationSettingsData is true
  useEffect(() => {
    if (refreshAiConversationSettingsData && aiConversation) {
      let settingsData = null;
      // unpack aisettings data
      // if (aiConversation && aiConversation.aisettings && aiConversation.aisettings !== "") {
      //   settingsData = JSON.parse(aiConversation.aisettings);
      // }
      // BT debugger

      if (aiConversation && aiConversation.aisettings) {
        settingsData = aiConversation.aisettings;
      }
      if (settingsData) {
        // if (debug()) debugger
        // BT aisettings
        const settingsIndexIdSelectedIndex = aiSettingsIndexIdSelected.index

        setAiSettingsData(settingsData[settingsIndexIdSelectedIndex]);
        setAiSettingsDataUpdated(settingsData[settingsIndexIdSelectedIndex]);
        // set ref for comparisons after settings save
        aiSettingsDataRef.current = settingsData[settingsIndexIdSelectedIndex];

        //set ref for use in QuestionPanel to compare improve prompt data
        aiSettingsDataConversationIdRef.current = aiConversation.aiconversationid;
      } else {
        // BT ZZZ what to do here?
        // no aisettings data in aiconversation
        setAiSettingsData(null);
        setAiSettingsDataUpdated(null);
        // set ref for comparisons after settings save
        aiSettingsDataRef.current = null;

        //set ref for use in QuestionPanel to compare improve prompt data
        aiSettingsDataConversationIdRef.current = aiConversation.aiconversationid;
      }
      // trigger useEffect in AiModelPanel
      setAiModelSelectedRefresh(true);
      // trigger useEffect in AiQuestionsPanel
      setAiStrategySelectedRefresh(true);

      setRefreshAiConversationSettingsData(false);
    }
  }, [refreshAiConversationSettingsData]);




  // function renderRow(props) {
  //   const { index, style } = props;

  //   return (
  //     <ListItem style={style} key={index} component="div" disablePadding divider>
  //       <ListItemButton onClick={handleConversationListItemButtonOnClick()}>
  //         <ListItemText primary={`Item ${index + 1}`} />
  //       </ListItemButton>
  //     </ListItem>
  //   );
  // }


  const handleConversationListItemButtonOnClick = (selectedConversationDoc) => {

    // BT aisettings
    let settingsIndex = -1;
    let aisettingsindexid = "";
    let settingsName = "";
    if (selectedConversationDoc && selectedConversationDoc.aisettings && selectedConversationDoc.aisettings.length > 0) {
      settingsIndex = 0;
      aisettingsindexid = selectedConversationDoc.aisettings[0].aisettingsindexid;
      settingsName = selectedConversationDoc.aisettings[0].name;
    }
    // update so we know what aisettingsindexid to search promptversions for
    setAiSettingsIndexIdSelected({
      index: settingsIndex,
      aisettingsindexid: aisettingsindexid,
      aiconversationid: selectedConversationDoc.aiconversationid,
      name: settingsName,

    })

    setAiConversationsSelected(selectedConversationDoc);
  };

  const isConversationIconButtonDisabled = (conversation) => {
    let disabled = false;

    if (aiAgentsIsEditing.active || aiConversationsIsEditing.active) {
      disabled = true;
    }

    if (aiSettingsIsEditing.active && aiSettingsIsEditing.type !== "improvePromptProcessing") {
      disabled = true;
    }
    // is prompt improve in progress
    // if (conversation && conversation.improveprocessing) {
    //   disabled = true;
    // }

    return disabled;
  };

  /* --------------------- START: Double Click --------------------- */

  const handleSingleClick = () => {
    const index = selectedConversationDocIndexRef.current;
    const selectedSettingsItem = selectedConversationDocDataRef.current;

    // console.log('single clicked!');

    setAiSettingsIndexIdSelected({
      index: index,
      aisettingsindexid: selectedSettingsItem.aisettingsindexid,
      aiconversationid: aiConversationsSelected.aiconversationid,
      name: selectedSettingsItem.name,

    })
  };

  const handleDoubleClick = async () => {

    const index = selectedConversationDocIndexRef.current;
    const selectedSettingsItem = selectedConversationDocDataRef.current;

    // console.log('double clicked!', selectedSettingsItem);
    // console.log('double clicked!', aiMessagesDoc);

    const switchPromptInt = index + 1;
    const switchPrompt = switchPromptInt.toString();

    const conversationId = aiConversationsSelected.aiconversationid;
    const settingsIndexId = selectedSettingsItem.aisettingsindexid;

    const result = processSwitchPromptSelected(conversationId, settingsIndexId, switchPrompt)

    setAiSettingsItemsSwitchPromptsSelected(result)

    // aiSettingsItemsSwitchPromptsSelected
    // setAiSettingsItemsSwitchPromptsSelected

    // aiSettingsIndexIdSelectedRefresh
    // setAiSettingsIndexIdSelectedRefresh
    // update aimessages switch_prompt


    setAiSettingsIndexIdSelected({
      index: index,
      aisettingsindexid: selectedSettingsItem.aisettingsindexid,
      aiconversationid: aiConversationsSelected.aiconversationid,
      name: selectedSettingsItem.name,
    })
  };

  const processRemoveSwitchPromptSelected = (conversationId, settingsIndexId) => {

    let returnData = {
      newData: {},
      setState: false,
    }

    const newData = _.cloneDeep(aiSettingsItemsSwitchPromptsSelected)
    // check if conversationId exists in newData
    if (newData[conversationId]) {
      // check if settingsIndexId exists in newData[conversationId]
      if (newData[conversationId].aisettingsindexid === settingsIndexId) {
        // if it exists then remove it
        delete newData[conversationId];

        // set returnData
        returnData.newData = newData;
        returnData.setState = true;
      }
    }

    return returnData;
  }

  const processSwitchPromptSelected = (conversationId, settingsIndexId, switchPrompt) => {
    const newData = _.cloneDeep(aiSettingsItemsSwitchPromptsSelected)

    // check if conversationId exists in newData
    if (newData[conversationId]) {
      // check if settingsIndexId exists in newData[conversationId]
      if (newData[conversationId].aisettingsindexid === settingsIndexId) {
        // if it exists then remove it
        delete newData[conversationId];
      }
      else {
        // update it with new values
        newData[conversationId] = {
          aisettingsindexid: settingsIndexId,
          switchprompt: switchPrompt,
        }
      }
    }
    else {
      newData[conversationId] = {
        aisettingsindexid: settingsIndexId,
        switchprompt: switchPrompt,
      }
    }

    return newData;
  }


  const handleSettingsListItemButtonSingleDoubleClick = (e, index, selectedSettingsItem) => {
    e.preventDefault();

    // set refs
    selectedConversationDocIndexRef.current = index;
    selectedConversationDocDataRef.current = selectedSettingsItem;

    if (e.type === 'click') {
      // console.log('Left click');
      setClicks(clicks + 1);
    } else if (e.type === 'contextmenu') {
      // console.log('Right click');
    }
  };

  /* --------------------- END: Double Click --------------------- */

  // const handleSettingsListItemButtonOnClick = (index, selectedSettingsItem) => {

  //   setAiSettingsIndexIdSelected({
  //     index: index,
  //     aisettingsindexid: selectedSettingsItem.aisettingsindexid,
  //     aiconversationid: aiConversationsSelected.aiconversationid,
  //     name: selectedSettingsItem.name,

  //   })
  // };

  const isSettingsIconButtonDisabled = (conversation) => {
    let disabled = false;

    if (aiAgentsIsEditing.active || aiConversationsIsEditing.active) {
      disabled = true;
    }

    if (aiSettingsIsEditing.active && aiSettingsIsEditing.type !== "improvePromptProcessing") {
      disabled = true;
    }
    // is prompt improve in progress
    // if (conversation && conversation.improveprocessing) {
    //   disabled = true;
    // }

    return disabled;
  };


  const linkIconActiveDisabled = () => {
    let linkColor = "primary.main";

    if (aiAgentsIsEditing.active || aiSettingsIsEditing.active || aiConversationsIsEditing.active) {
      linkColor = "grey.300";
    }

    return linkColor;
  };

  const linkIconUnassignedDisabled = () => {
    let linkColor = "error.main";
    // linkColor = "primary.main";
    if (aiAgentsIsEditing.active || aiSettingsIsEditing.active || aiConversationsIsEditing.active) {
      linkColor = "grey.300";
    }

    return linkColor;
  };

  const showAiLinkSettingsLink = (conversation) => {
    // if (conversation && conversation.ailinksettingid && conversation.ailinksettingid !== "") {

    if (
      // conversation &&
      // conversation.automationlink &&
      // conversation.automationlink !== ""
      aiAgentsSelected.aisettings[0].automationlink !== undefined &&
      aiAgentsSelected.aisettings[0].automationlink !== "" &&
      conversation.ailinksettingid &&
      conversation.ailinksettingid !== "" &&
      conversation.ailinksettingid === aiAgentsSelected.aisettings[0].ailinksettingid
    ) {
      //
      return (
        <LinkIcon
          // color="primary"
          sx={{
            // color: "primary.main",
            color: linkIconActiveDisabled(),
            fontSize: 16,
          }}
        />
      );
    }
    else if (
      aiAgentsSelected.aisettings[0].automationlink !== undefined &&
      aiAgentsSelected.aisettings[0].automationlink === "" &&
      conversation.ailinksettingid &&
      conversation.ailinksettingid !== "" &&
      conversation.ailinksettingid === aiAgentsSelected.aisettings[0].ailinksettingid
      // conversation &&
      // // conversation.automationlink &&
      // conversation.automationlink === "" &&
      // conversation.ailinksettingid &&
      // conversation.ailinksettingid !== "" &&
      // conversation.ailinksettingid === aiAgentsSelected.aisettings[0].ailinksettingid
    ) {
      //
      return (
        <LinkOffIcon
          // color="primary"
          sx={{
            // color: "primary.main",
            color: linkIconUnassignedDisabled(),
            fontSize: 16,
          }}
        />
      );
    }
    else {
      return <></>;
    }
  };

  // generic for conversation controls
  const isAiHistoryConversationsEditDisabled = () => {
    let disabled = false;

    if (aiAgentsIsEditing.active || aiSettingsIsEditing.active || aiConversationsIsEditing.active) {
      disabled = true;
    }

    return disabled;
  };

  const isAiHistoryConversationsRemoveDisabled = () => {
    let disabled = false;

    if (aiAgentsIsEditing.active || aiSettingsIsEditing.active || aiConversationsIsEditing.active) {
      disabled = true;
    }

    // one conversation must exist
    if (aiConversationsDocs && aiConversationsDocs.length < 2) {
      disabled = true;
    }

    // selected conversation has ailinksettingid string
    // if (aiConversationsSelected && aiConversationsSelected.ailinksettingid && aiConversationsSelected.ailinksettingid !== "") {
    if (aiConversationsSelected && aiConversationsSelected.automationlink && aiConversationsSelected.automationlink !== "") {
      disabled = true;
    }

    return disabled;
  };

  const handleOnChangeAiConversationsName = (event) => {
    const val = event.target.value;
    if (clog()) console.log("event:", event.target.value);
    setAiConversationsName(val);
  };

  const handleOnClickAiConversationsEdit = () => {
    //
    if (aiConversationsIsEditing.active === false) {
      let name = "";
      if (aiConversationsSelected && aiConversationsSelected.name) {
        name = aiConversationsSelected.name;
      }
      setAiConversationsName(name);

      // activate edit mode
      setAiConversationsIsEditing({
        active: true,
        type: "edit",
      });
    } else {
      // deactivate edit mode
      setAiConversationsIsEditing({
        active: false,
        type: "",
      });
    }
  };

  const handleOnClickAiConversationsNameSave = async () => {
    //
    // setAiSettingsName(name)
    setAiConversationsNameSaving(true);

    // set a state so that aiChat knows to not refetch conversation
    // will be set back to true in AiChatPanel useEffect [aiConversationsSelected]
    setAiConversationFetchData(false);

    const result = await saveAiConversationsName(aiConversationsSelected.aiconversationid, aiConversationsName);

    if (result && result.data && result.data.aiconversationid && result.data.aiconversationid !== "") {
      // update aiConversationsSelected with new name
      debugger
      let updatedAiConversationsSelected = { ...aiConversationsSelected };
      updatedAiConversationsSelected.name = result.data.name;
      setAiConversationsSelected(updatedAiConversationsSelected);
    }

    setAiConversationsNameSaving(false);

    // deactivate edit mode
    setAiConversationsIsEditing({
      active: false,
      type: "",
    });
  };

  const isAiConversationsNameSaveDisabled = () => {
    let disabled = false;
    if (aiConversationsName === "") {
      disabled = true;
    }

    if (aiConversationsName === aiConversationsSelected.name) {
      disabled = true;
    }

    return disabled;
  };

  const handleOnClickAiConversationsAdd = async () => {
    const result = await duplicateAiConversation(userData, aiConversation);

    if (result && result.data && result.data.aiconversationid && result.data.aiconversationid !== "") {
      // const val = result.data.aiconversationid

      // trigger useEffect in AiHistoryPanel to activate latest conversation
      setAiConversationDuplicatedRefresh(true);
    }

    // deactivate edit mode
    setAiConversationsIsEditing({
      active: false,
      type: "",
    });
  };

  const handleOnClickAiConversationsRemoveConfirm = async () => {
    setAiConversationsIsRemoving(true);

    const result = await removeAiConversation(userData, aiConversationsSelected);

    if (result && result.data && result.data.aiconversations && result.data.aiconversations.length > 0) {
      // trigger refresh of aiConversationsDocs
      setAiConversationsDocsRefresh(true);
    }
    setAiConversationsIsRemoving(false);

    // const result = await removeAiConversation(userData, aiConversationsSelected.aiconversationid);

    // if (result && result.data && result.data.aiconversationid && result.data.aiconversationid !== "") {
    //   // trigger refresh of aiConversationsDocs
    //   setAiConversationsDocsRefresh(true);
    // }


    // deactivate edit mode
    setAiConversationsIsEditing({
      active: false,
      type: "",
    });
  };

  const handleOnClickAiConversationsRemove = () => {
    //
    if (aiConversationsIsEditing.active === false) {
      // activate edit mode
      setAiConversationsIsEditing({
        active: true,
        type: "remove",
      });
    } else {
      // deactivate edit mode
      setAiConversationsIsEditing({
        active: false,
        type: "",
      });
    }
  };


  const isSavePresetDisabled = () => {
    let disabled = false;

    if (aiAgentsSelected === null) {
      disabled = true;
    }

    if (aiSettingsSelected === null) {
      disabled = true;
    }

    if (aiConversation === null) {
      disabled = true;
    }

    if (aiAgentsIsEditing.active === true) {
      disabled = true;
    }

    if (aiSettingsIsEditing.active === true) {
      disabled = true;
    }

    if (aiConversationsIsEditing.active === true) {
      disabled = true;
    }

    // make sure that module and strategy exist and are not empty
    // should aiSettingsDataUpdated or aiSettingsData
    if (aiSettingsData && aiSettingsData.data) {
      if (
        (aiSettingsData.data.module && aiSettingsData.data.module === "") ||
        (aiSettingsData.data.strategy && aiSettingsData.data.strategy === "")
      ) {
        disabled = true;
      }
    } else {
      disabled = true;
    }

    return disabled;
  };

  const handleOnClickSavePresetConfirm = async () => {
    if (clog()) console.log("handleOnClickSavePreset");
    // setAiSettingsName(name)
    setSavePresetSaving(true);

    const result = await saveAiPreset(userData);

    if (result && result.data) {
      // what to do here?
      if (clog()) console.log("handleOnClickSavePreset result", result.data);
    } else {
      if (clog()) console.log("handleOnClickSavePreset result", result.error);
    }

    // show alert
    setShowSavePresetAlert(true);
    setSavePresetSaving(false);
    //
    // deactivate edit mode
    setAiConversationsIsEditing({
      active: false,
      type: "",
    });
  };

  const handleOnClickSavePreset = async () => {
    //
    if (aiConversationsIsEditing.active === false) {
      // activate edit mode
      setAiConversationsIsEditing({
        active: true,
        type: "save",
      });
    } else {
      // deactivate edit mode
      setAiConversationsIsEditing({
        active: false,
        type: "",
      });
    }
  };

  const showAiConversationsEditingControls = () => {
    return (
      <Box
        name={"historyControls"}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          // justifyContent: "flex-end",
          justifyContent: "space-between",
          // pb: 1,
          borderStyle: "solid",
          borderWidth: 1,
          borderColor: theme.palette.border.normal,
          borderBottom: 0,
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
          backgroundColor: theme.palette.background.paper,
        }}
      >
        {adminStatus.isAdmin ? (
          <Box name="conversationSavePreset">
            <IconButton
              aria-label="SavePreset"
              color="primary"
              size="small"
              // variant="light"
              onClick={handleOnClickSavePreset}
              // disabled
              disabled={isSavePresetDisabled()}
              sx={{
                "&.MuiIconButton-sizeSmall": {
                  fontSize: "16px",
                  borderRadius: "0px",
                },
              }}
            >
              <SaveIcon fontSize="inherit" />
            </IconButton>
          </Box>
        ) : (
          <Box name="conversationSavePreset">
            <></>
          </Box>
        )}

        <Box
          name="conversationControlsRight"
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >

          {
            // <Box name="conversationLink">
            //   <IconButton
            //     aria-label="Link"
            //     color="primary"
            //     size="small"
            //     // variant="light"
            //     onClick={handleOnClickAutomationLink}
            //     // disabled
            //     disabled={isAutomationLinkDisabled()}
            //     sx={{
            //       "&.MuiIconButton-sizeSmall": {
            //         fontSize: "16px",
            //         borderRadius: "0px",
            //       },
            //     }}
            //   >
            //     <LinkIcon fontSize="inherit" />
            //   </IconButton>
            // </Box>
          }
          <Box name="conversationAdd">
            <IconButton
              aria-label="Edit"
              color="primary"
              size="small"
              sx={{
                "&.MuiIconButton-sizeSmall": {
                  fontSize: "13px",
                  borderRadius: "0px",
                },
              }}
              onClick={handleOnClickAiConversationsAdd}
              disabled={isAiHistoryConversationsEditDisabled()}
            >
              <FileCopyIcon fontSize="inherit" />
            </IconButton>
          </Box>

          <Box name="conversationRemove">
            <IconButton
              aria-label="Edit"
              color="primary"
              size="small"
              sx={{
                // mx: 1,
                "&.MuiIconButton-sizeSmall": {
                  fontSize: "16px",
                  borderRadius: "0px",
                },
              }}
              onClick={handleOnClickAiConversationsRemove}
              disabled={isAiHistoryConversationsRemoveDisabled()}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </Box>

          <Box
            name="conversationEdit"
            sx={
              {
                // mr: 1,
              }
            }
          >
            <IconButton
              aria-label="Edit"
              color="primary"
              size="small"
              sx={{
                "&.MuiIconButton-sizeSmall": {
                  fontSize: "16px",
                  borderRadius: "0px",
                },
              }}
              onClick={handleOnClickAiConversationsEdit}
              disabled={isAiHistoryConversationsEditDisabled()}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
          </Box>
        </Box>
      </Box>
    );
  };


  // generic for settings controls

  const isAiHistorySettingsItemEditDisabled = () => {
    let disabled = false;

    if (aiAgentsIsEditing.active || aiSettingsIsEditing.active || aiConversationsIsEditing.active) {
      disabled = true;
    }

    return disabled;
  };

  const isAiHistorySettingsItemRemoveDisabled = () => {
    let disabled = false;

    if (aiAgentsIsEditing.active || aiSettingsIsEditing.active || aiConversationsIsEditing.active) {
      disabled = true;
    }

    // one conversation must exist
    if (aiConversationsSelected && aiConversationsSelected.aisettings && aiConversationsSelected.aisettings.length < 2) {
      disabled = true;
    }

    // selected conversation has ailinksettingid string
    // if (aiConversationsSelected && aiConversationsSelected.ailinksettingid && aiConversationsSelected.ailinksettingid !== "") {
    // if (aiConversationsSelected && aiConversationsSelected.automationlink && aiConversationsSelected.automationlink !== "") {
    //   disabled = true;
    // }

    return disabled;
  };

  const handleOnChangeAiSettingsItemName = (event) => {
    const val = event.target.value;
    if (clog()) console.log("event:", event.target.value);
    setAiSettingsItemName(val);
  };

  const handleOnClickAiSettingsItemEdit = () => {
    //
    if (aiConversationsIsEditing.active === false) {
      let name = "";
      if (aiSettingsIndexIdSelected && aiSettingsIndexIdSelected.name) {
        name = aiSettingsIndexIdSelected.name;
      }
      setAiSettingsItemName(name);

      // activate edit mode
      setAiConversationsIsEditing({
        active: true,
        type: "editSetting",
      });
    } else {
      // deactivate edit mode
      setAiConversationsIsEditing({
        active: false,
        type: "",
      });
    }
  };

  const handleOnClickAiSettingsItemNameSave = async () => {
    //
    // setAiSettingsName(name)
    setAiSettingsItemNameSaving(true);

    // set a state so that aiChat knows to not refetch conversation
    // will be set back to true in AiChatPanel useEffect [aiConversationsSelected]
    // setAiConversationFetchData(false);

    const result = await saveAiSettingsIndexItemName(aiConversationsSelected, aiSettingsIndexIdSelected, aiSettingsItemName);

    if (result && result.data && result.data.aisettings) {
      // update aiConversationsSelected with new name

      setAiSettingsIndexIdSelected({
        ...aiSettingsIndexIdSelected,
        name: aiSettingsItemName,
      })

      let updatedAiConversationsSelected = _.cloneDeep(aiConversationsSelected);
      updatedAiConversationsSelected.aisettings = result.data.aisettings;
      setAiConversationsSelected(updatedAiConversationsSelected);

    }

    setAiSettingsItemNameSaving(false);

    // deactivate edit mode
    setAiConversationsIsEditing({
      active: false,
      type: "",
    });
  };

  const isAiSettingsItemNameSaveDisabled = () => {
    let disabled = false;
    if (aiSettingsItemName === "") {
      disabled = true;
    }

    if (aiSettingsItemName === aiSettingsIndexIdSelected.name) {
      disabled = true;
    }

    return disabled;
  };

  const handleOnClickAiSettingsItemAdd = async () => {

    const result = await duplicateAiSettingsItem(userData, aiConversation, aiSettingsDataUpdated, aiSettingsIndexIdSelected);

    if (result && result.data) {

      const aiSettingsDataNew = result.data.aisettings
      const aiSettingDataNew = result.data.aisetting

      // update aiSettingsIndexIdSelected
      // BT aisettings
      let index = -1;
      const foundIndex = aiSettingsDataNew.findIndex((item) => item.aisettingsindexid === aiSettingDataNew.aisettingsindexid);
      if (foundIndex > -1) {
        index = foundIndex;
      }

      let aisettingsindexid = "";
      if (aiSettingDataNew.aisettingsindexid && aiSettingDataNew.aisettingsindexid !== "") {
        aisettingsindexid = aiSettingDataNew.aisettingsindexid;
      }

      let name = "";
      if (aiSettingDataNew.name && aiSettingDataNew.name !== "") {
        name = aiSettingDataNew.name;
      }

      let aiconversationid = "";
      if (aiConversation.aiconversationid && aiConversation.aiconversationid !== "") {
        aiconversationid = aiConversation.aiconversationid;
      }


      // update so we know what aisettingsindexid to search promptversions for
      setAiSettingsIndexIdSelected({
        index: index,
        aisettingsindexid: aisettingsindexid,
        aiconversationid: aiconversationid,
        name: name,
      })

      // update aiConversationsSelected
      setAiConversationsSelected({
        ...aiConversationsSelected,
        aisettings: aiSettingsDataNew
      });

    }
    // if (result && result.data && result.data.aiconversationid && result.data.aiconversationid !== "") {
    //   // const val = result.data.aiconversationid

    //   // trigger useEffect in AiHistoryPanel to activate latest conversation
    //   setAiConversationDuplicatedRefresh(true);
    // }

    // deactivate edit mode
    setAiConversationsIsEditing({
      active: false,
      type: "",
    });
  };

  const handleOnClickAiSettingsItemRemoveConfirm = async () => {
    setAiConversationsIsRemoving(true);
    // let result = {
    //   data: {},
    // }
    const result = await removeAiSettingsIndexItem(userData, aiConversationsSelected, aiSettingsIndexIdSelected);

    if (result && result.data) {

      // remove switch prompt from aiSettingsItemsSwitchPromptsSelected if found
      const switchPromptResult = processRemoveSwitchPromptSelected(aiConversationsSelected.aiconversationid, aiSettingsIndexIdSelected.aisettingsindexid)
      if (switchPromptResult.setState === true && switchPromptResult.newData) {
        setAiSettingsItemsSwitchPromptsSelected(switchPromptResult.newData)
      }

       const aisettings = result.data.aisettings

      // BT aisettings
      let settingsIndex = -1;
      let aisettingsindexid = "";
      let settingsName = "";
      if (aisettings && aisettings.length > 0) {
        settingsIndex = 0;
        aisettingsindexid = aisettings[0].aisettingsindexid;
        settingsName = aisettings[0].name;
      }

      setAiSettingsIndexIdSelected({
        index: settingsIndex,
        aisettingsindexid: aisettingsindexid,
        aiconversationid: aiConversationsSelected.aiconversationid,
        name: settingsName,

      })

      // update aiConversationsSelected
      setAiConversationsSelected({
        ...aiConversationsSelected,
        aisettings: result.data.aisettings
      });

    }
    setAiConversationsIsRemoving(false);

    // deactivate edit mode
    setAiConversationsIsEditing({
      active: false,
      type: "",
    });
  };

  const handleOnClickAiSettingsItemRemove = () => {
    //
    if (aiConversationsIsEditing.active === false) {
      // activate edit mode
      setAiConversationsIsEditing({
        active: true,
        // type: "remove",
        type: "removeSetting",
      });
    } else {
      // deactivate edit mode
      setAiConversationsIsEditing({
        active: false,
        type: "",
      });
    }
  };

  const showAiSettingsEditingControls = () => {
    return (
      <Box
        name={"settingsControls"}
        sx={{
          mt: 2,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          // justifyContent: "flex-end",
          justifyContent: "space-between",
          // pb: 1,
          borderStyle: "solid",
          borderWidth: 1,
          borderColor: theme.palette.border.normal,
          borderBottom: 0,
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Box name="settingsSavePreset">
          <></>
        </Box>

        <Box
          name="settingsControlsRight"
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >


          <Box name="settingsAdd">
            <IconButton
              aria-label="Edit"
              color="primary"
              size="small"
              sx={{
                "&.MuiIconButton-sizeSmall": {
                  fontSize: "13px",
                  borderRadius: "0px",
                },
              }}
              onClick={handleOnClickAiSettingsItemAdd}
              disabled={isAiHistorySettingsItemEditDisabled()}
            >
              <FileCopyIcon fontSize="inherit" />
            </IconButton>
          </Box>

          <Box name="settingsRemove">
            <IconButton
              aria-label="Edit"
              color="primary"
              size="small"
              sx={{
                // mx: 1,
                "&.MuiIconButton-sizeSmall": {
                  fontSize: "16px",
                  borderRadius: "0px",
                },
              }}
              onClick={handleOnClickAiSettingsItemRemove}
              disabled={isAiHistorySettingsItemRemoveDisabled()}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </Box>

          <Box
            name="settingsEdit"
            sx={
              {
                // mr: 1,
              }
            }
          >
            <IconButton
              aria-label="Edit"
              color="primary"
              size="small"
              sx={{
                "&.MuiIconButton-sizeSmall": {
                  fontSize: "16px",
                  borderRadius: "0px",
                },
              }}
              onClick={handleOnClickAiSettingsItemEdit}
              disabled={isAiHistorySettingsItemEditDisabled()}
            >
              <EditIcon fontSize="inherit" />
            </IconButton>
          </Box>
        </Box>
      </Box>
    );
  };

  const showAiMessageSwitchPromptIcon = (setting, index) => {

    // aiConversationsSelected
    // aiSettingsItemsSwitchPromptsSelected

    const conversationId = aiConversationsSelected.aiconversationid
    const settingsIndexId = setting.aisettingsindexid

    const messagesDoc = aiMessagesDoc
    // const test = setting
    // const test2 = index
    // const test3 = aiMessagesDoc

    if (messagesDoc) {
      let switchPrompt = '';
      // let switchPromptInt = -1;
      let switchPromptInt = 0;
      if (messagesDoc && messagesDoc.switch_prompt && messagesDoc.switch_prompt !== "") {
        switchPrompt = messagesDoc.switch_prompt;
      }
      if (switchPrompt !== "") {
        switchPromptInt = parseInt(switchPrompt) - 1;
      }

      if (
        aiSettingsItemsSwitchPromptsSelected[conversationId] &&
        aiSettingsItemsSwitchPromptsSelected[conversationId].aisettingsindexid === settingsIndexId
      ) {
        // conversationId and settingsIndexId exist in aiSettingsItemsSwitchPromptsSelected
        if (index === switchPromptInt) {
          return (
            <DotCircleIcon
              // color="primary"
              sx={{
                color: "primary.main",
                // color: linkIconActiveDisabled(),
                fontSize: 16,
              }}
            />
          );
        }
        else {
          return (
            <CircleIcon
              // color="primary"
              sx={{
                color: "primary.main",
                // color: linkIconActiveDisabled(),
                fontSize: 16,
              }}
            />
          );

        }
      }
      else if (
        aiSettingsItemsSwitchPromptsSelected[conversationId] &&
        aiSettingsItemsSwitchPromptsSelected[conversationId].aisettingsindexid !== settingsIndexId
      ) {
        // conversationId exists but settingsIndexId in aiSettingsItemsSwitchPromptsSelected
        // but settingsIndexId is different
        if (index === switchPromptInt) {
          return (
            <DotIcon
              // color="primary"
              sx={{
                color: "primary.main",
                // color: linkIconActiveDisabled(),
                fontSize: 11,
              }}
            />
          );
        }
      }
      else {
        if (index === switchPromptInt) {
          return (
            <DotIcon
              // color="primary"
              sx={{
                color: "primary.main",
                // color: linkIconActiveDisabled(),
                fontSize: 11,
              }}
            />
          );
        }
        else {
          // return (
          //   <DotIcon
          //     // color="primary"
          //     sx={{
          //       color: "primary.main",
          //       // color: linkIconActiveDisabled(),
          //       fontSize: 11,
          //     }}
          //   />
          // );
          // return (
          //   <CircleIcon
          //     // color="primary"
          //     sx={{
          //       color: "primary.main",
          //       // color: linkIconActiveDisabled(),
          //       fontSize: 16,
          //     }}
          //   />
          // );
          return <></>;
        }
      }

    }
    else {
      return <></>;
    }



  }
  if (init || aiConversationsDocs === null) {
    return <LoadingSubPane spin={true} />;
  }

  if (aiConversationsDocs) {
    const conversationsCount = aiConversationsDocs.length;

    //    <Box
    //    sx={{
    //      width: '100%',
    //      // height={400}
    //      height: '100%',
    //      bgcolor: 'background.paper',
    //      overflow: 'hidden'
    //    }}
    //  >

    return (
      <Box
        name={"historyPanel"}
        height={"100%"}
        // bgcolor="#cc0000"
        sx={{
          display: "flex",
          flexDirection: "column",
          // maxWidth: "260px",
          // overflow: 'hidden',
        }}
      >
        {showAiConversationsEditingControls()}

        <Box
          name={"historyList"}
          sx={{
            // flexGrow: 1,
            // height: "100%",
          }}
        >
          {
            // <Typography>Ai Chat Panel</Typography>
          }

          <Box
            // component={wrapper}
            // ref={wrapper} // Option 1: use ref to scroll to bottom
            id={"historyScrollWrapper"}
            sx={{
              backgroundColor: theme.palette.background.paper,
              maxHeight: "100%",
              // flexGrow: 1,
              overflow: "auto",
              // display: 'flex', // Option 2: reverse column so that the last message is at the top
              // flexDirection: "column-reverse" // Option 2: would need to reverse the order of the messages
            }}
          >
            <List
              sx={{
                p: 0,
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: theme.palette.border.normal,
                borderBottom: 0,
                borderBottomLeftRadius: "4px",
                borderBottomRightRadius: "4px",
              }}
            >
              {aiConversationsDocs.map((c, index) => {
                // const dateTest = c.createdon.toDate()
                let dateString = "";
                if (c.createdon && c.createdon.seconds) {
                  const date = new Date(c.createdon.seconds * 1000);
                  dateString = date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear();
                }

                if (
                  aiConversationsIsEditing.active &&
                  aiConversationsIsEditing.type === "edit" &&
                  c.aiconversationid === aiConversationsSelected.aiconversationid
                ) {
                  return (
                    <ListItem
                      key={c.aiconversationid}
                      disablePadding
                      // divider
                      sx={{
                        backgroundColor:
                          aiConversationsSelected && aiConversationsSelected.aiconversationid === c.aiconversationid ? "grey.100" : "transparent",
                        // color: aiConversationsSelected && aiConversationsSelected.aiconversationid === c.aiconversationid ? theme.palette.primary.contrastText : 'inherit'
                        borderBottomColor: theme.palette.border.normal,
                        borderBottomStyle: "solid",
                        borderBottomWidth: "1px",
                        // pr: 0,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          minHeight: "46px",
                          alignItems: "center",
                          pl: 1,
                        }}
                      >
                        <Box
                          sx={{
                            flexGrow: 1,
                          }}
                        >
                          <TextField
                            // margin="normal"
                            required
                            fullWidth
                            // id="email"
                            // label="Name"
                            name="name"
                            autoComplete="name"
                            autoFocus
                            sx={{
                              input: {
                                py: "7px",
                                px: "8px",
                                backgroundColor: "white",
                              },
                            }}
                            // size="small"
                            // value={aiSettingsDataUpdated.data.name}
                            value={aiConversationsName}
                            onChange={(e) => handleOnChangeAiConversationsName(e)}
                          // onChange={(e) => handleOnChangeAiSettingsData(e, 'name')}
                          />
                        </Box>

                        {aiConversationsNameSaving ? (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "30px",
                              height: "30px",
                              ml: 1,
                            }}
                          >
                            <CircularProgress
                              size={12}
                              sx={{
                                p: 0,
                                m: 0,
                              }}
                            // variant="determinate"
                            />
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              ml: 1,
                            }}
                          >
                            <IconButton
                              aria-label="Save"
                              color="primary"
                              size="small"
                              sx={{
                                "&.MuiIconButton-sizeSmall": {
                                  fontSize: "16px",
                                },
                              }}
                              // variant="light"
                              onClick={handleOnClickAiConversationsNameSave}
                              disabled={isAiConversationsNameSaveDisabled()}
                            >
                              <DoneIcon fontSize="inherit" />
                            </IconButton>
                          </Box>
                        )}

                        <Box>
                          <IconButton
                            aria-label="Cancel"
                            color="primary"
                            size="small"
                            sx={{
                              "&.MuiIconButton-sizeSmall": {
                                fontSize: "16px",
                              },
                            }}
                            // variant="light"
                            onClick={handleOnClickAiConversationsEdit}
                          // disabled={isSendDisabled()}
                          >
                            <ClearIcon fontSize="inherit" />
                          </IconButton>
                        </Box>
                      </Box>
                    </ListItem>
                  );
                } else if (
                  aiConversationsIsEditing.active &&
                  aiConversationsIsEditing.type === "remove" &&
                  c.aiconversationid === aiConversationsSelected.aiconversationid
                ) {
                  return (
                    <ListItem
                      key={c.aiconversationid}
                      disablePadding
                      // divider
                      sx={{
                        backgroundColor:
                          aiConversationsSelected && aiConversationsSelected.aiconversationid === c.aiconversationid ? "grey.100" : "transparent",
                        // color: aiConversationsSelected && aiConversationsSelected.aiconversationid === c.aiconversationid ? theme.palette.primary.contrastText : 'inherit'
                        borderBottomColor: theme.palette.border.normal,
                        borderBottomStyle: "solid",
                        borderBottomWidth: "1px",
                        // pr: 0,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          minHeight: "46px",
                          alignItems: "center",
                          pl: 2,
                        }}
                      >
                        <Box
                          sx={{
                            flexGrow: 1,
                            // backgroundColor: '#cc0000',
                            overflow: "hidden",
                          }}
                        >
                          <Typography
                            // nowrap
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              // maxWidth: "240px",
                            }}
                          >
                            {c.name && c.name !== "" ? c.name : c.aiconversationid}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            ml: 1,
                          }}
                        >
                          <IconButton
                            aria-label="Confirm"
                            color="primary"
                            size="small"
                            sx={{
                              "&.MuiIconButton-sizeSmall": {
                                fontSize: "16px",
                              },
                            }}
                            // variant="light"
                            onClick={() => handleOnClickAiConversationsRemoveConfirm()}
                          // disabled={isAiConversationsNameSaveDisabled()}
                          >
                            <DoneIcon fontSize="inherit" />
                          </IconButton>
                        </Box>
                        <Box>
                          <IconButton
                            aria-label="Cancel"
                            color="primary"
                            size="small"
                            sx={{
                              "&.MuiIconButton-sizeSmall": {
                                fontSize: "16px",
                              },
                            }}
                            // variant="light"
                            onClick={handleOnClickAiConversationsEdit}
                          // disabled={isSendDisabled()}
                          >
                            <ClearIcon fontSize="inherit" />
                          </IconButton>
                        </Box>
                      </Box>
                    </ListItem>
                  );
                }
                // else if (
                //   aiConversationsIsEditing.active &&
                //   aiConversationsIsEditing.type === "link" &&
                //   c.aiconversationid === aiConversationsSelected.aiconversationid
                // ) {
                //   return (
                //     <ListItem
                //       key={c.aiconversationid}
                //       disablePadding
                //       // divider
                //       sx={{
                //         backgroundColor:
                //           aiConversationsSelected && aiConversationsSelected.aiconversationid === c.aiconversationid ? "grey.100" : "transparent",
                //         // color: aiConversationsSelected && aiConversationsSelected.aiconversationid === c.aiconversationid ? theme.palette.primary.contrastText : 'inherit'
                //         borderBottomColor: theme.palette.border.normal,
                //         borderBottomStyle: "solid",
                //         borderBottomWidth: "1px",
                //         // pr: 0,
                //       }}
                //     >
                //       <Box
                //         sx={{
                //           display: "flex",
                //           flexDirection: "row",
                //           width: "100%",
                //           minHeight: "46px",
                //           alignItems: "center",
                //           pl: 2,
                //         }}
                //       >
                //         <Box
                //           sx={{
                //             flexGrow: 1,
                //             // backgroundColor: '#cc0000',
                //             overflow: "hidden",
                //           }}
                //         >
                //           <Typography
                //             // nowrap
                //             sx={{
                //               whiteSpace: "nowrap",
                //               overflow: "hidden",
                //               textOverflow: "ellipsis",
                //               // maxWidth: "240px",
                //             }}
                //           >
                //             {c.name && c.name !== "" ? c.name : c.aiconversationid}
                //           </Typography>
                //         </Box>

                //         {automationLinkSaving ? (
                //           <Box
                //             sx={{
                //               display: "flex",
                //               alignItems: "center",
                //               justifyContent: "center",
                //               width: "30px",
                //               height: "30px",
                //               ml: 1,
                //             }}
                //           >
                //             <CircularProgress
                //               size={12}
                //               sx={{
                //                 p: 0,
                //                 m: 0,
                //               }}
                //             // variant="determinate"
                //             />
                //           </Box>
                //         ) : (
                //           <Box
                //             sx={{
                //               ml: 1,
                //             }}
                //           >
                //             <IconButton
                //               aria-label="Confirm"
                //               color="primary"
                //               size="small"
                //               sx={{
                //                 "&.MuiIconButton-sizeSmall": {
                //                   fontSize: "16px",
                //                 },
                //               }}
                //               // variant="light"
                //               onClick={() => handleOnClickAutomationLinkConfirm()}
                //             // disabled={isAiConversationsNameSaveDisabled()}
                //             >
                //               <DoneIcon fontSize="inherit" />
                //             </IconButton>
                //           </Box>
                //         )}

                //         <Box>
                //           <IconButton
                //             aria-label="Cancel"
                //             color="primary"
                //             size="small"
                //             sx={{
                //               "&.MuiIconButton-sizeSmall": {
                //                 fontSize: "16px",
                //               },
                //             }}
                //             // variant="light"
                //             onClick={handleOnClickAiConversationsEdit}
                //           // disabled={isSendDisabled()}
                //           >
                //             <ClearIcon fontSize="inherit" />
                //           </IconButton>
                //         </Box>
                //       </Box>
                //     </ListItem>
                //   );
                // }
                else if (
                  aiConversationsIsEditing.active &&
                  aiConversationsIsEditing.type === "save" &&
                  c.aiconversationid === aiConversationsSelected.aiconversationid
                ) {
                  return (
                    <ListItem
                      key={c.aiconversationid}
                      disablePadding
                      // divider
                      sx={{
                        backgroundColor:
                          aiConversationsSelected && aiConversationsSelected.aiconversationid === c.aiconversationid ? "grey.100" : "transparent",
                        // color: aiConversationsSelected && aiConversationsSelected.aiconversationid === c.aiconversationid ? theme.palette.primary.contrastText : 'inherit'
                        borderBottomColor: theme.palette.border.normal,
                        borderBottomStyle: "solid",
                        borderBottomWidth: "1px",
                        // pr: 0,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          minHeight: "46px",
                          alignItems: "center",
                          pl: 2,
                        }}
                      >
                        <Box
                          sx={{
                            flexGrow: 1,
                            // backgroundColor: '#cc0000',
                            overflow: "hidden",
                          }}
                        >
                          <Typography
                            // nowrap
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              // maxWidth: "240px",
                            }}
                          >
                            {c.name && c.name !== "" ? c.name : c.aiconversationid}
                          </Typography>
                        </Box>

                        {savePresetSaving ? (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "30px",
                              height: "30px",
                              ml: 1,
                            }}
                          >
                            <CircularProgress
                              size={12}
                              sx={{
                                p: 0,
                                m: 0,
                              }}
                            // variant="determinate"
                            />
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              ml: 1,
                            }}
                          >
                            <IconButton
                              aria-label="Confirm"
                              color="primary"
                              size="small"
                              sx={{
                                "&.MuiIconButton-sizeSmall": {
                                  fontSize: "16px",
                                },
                              }}
                              // variant="light"
                              onClick={() => handleOnClickSavePresetConfirm()}
                            // disabled={isAiConversationsNameSaveDisabled()}
                            >
                              <DoneIcon fontSize="inherit" />
                            </IconButton>
                          </Box>
                        )}

                        <Box>
                          <IconButton
                            aria-label="Cancel"
                            color="primary"
                            size="small"
                            sx={{
                              "&.MuiIconButton-sizeSmall": {
                                fontSize: "16px",
                              },
                            }}
                            // variant="light"
                            onClick={handleOnClickAiConversationsEdit}
                          // disabled={isSendDisabled()}
                          >
                            <ClearIcon fontSize="inherit" />
                          </IconButton>
                        </Box>
                      </Box>
                    </ListItem>
                  );
                } else {
                  return (
                    <ListItem
                      key={c.aiconversationid}
                      disablePadding
                      // divider
                      sx={{
                        // backgroundColor: aiConversationsSelected && aiConversationsSelected.aiconversationid === c.aiconversationid ? "grey.100" : "transparent",
                        // color: aiConversationsSelected && aiConversationsSelected.aiconversationid === c.aiconversationid ? theme.palette.primary.contrastText : 'inherit'
                        borderBottomColor: theme.palette.border.normal,
                        borderBottomStyle: "solid",
                        borderBottomWidth: "1px",
                        // pr: 0,
                      }}
                    >
                      <ListItemButton
                        onClick={() => handleConversationListItemButtonOnClick(c)}
                        disabled={isConversationIconButtonDisabled(c)}
                        sx={{
                          pr: "8px",
                          minHeight: "46px",
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            // justifyContent: 'space-between',
                          }}
                        >
                          <Box
                            sx={{
                              flexGrow: 1,
                              // backgroundColor: '#cc0000',
                              overflow: "hidden",
                            }}
                          >
                            <Typography
                              // nowrap
                              sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                fontWeight: aiConversationsSelected && aiConversationsSelected.aiconversationid === c.aiconversationid ? "bold" : "normal",
                                color: aiConversationsSelected && aiConversationsSelected.aiconversationid === c.aiconversationid ? theme.palette.primary.main : "inherit",
                              }}
                            >
                              {c.name && c.name !== "" ? c.name : c.aiconversationid}
                            </Typography>


                            {
                              rc.history_show_conversation_id && (
                                <ListItemText
                                  // BT temp to show conversation id
                                  // secondary={c.aiconversationid}
                                  primary={c.name && c.name === "" ? c.name : c.aiconversationid}
                                  // sx={{
                                  //   '&.MuiListItemText-secondary': {}
                                  // }}
                                  sx={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                />
                              )
                            }
                          </Box>
                          <Box
                            sx={{
                              width: "16px",
                              maxWidth: "16px",
                              pt: "6px",
                              ml: 1,
                              // backgroundColor: 'green',
                            }}
                          >
                            {showAiLinkSettingsLink(c)}
                          </Box>

                          {c.improveprocessing && (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "30px",
                                height: "30px",
                                ml: 1,
                              }}
                            >
                              <CircularProgress
                                size={12}
                                sx={{
                                  p: 0,
                                  m: 0,
                                }}
                              // variant="determinate"
                              />
                            </Box>
                          )}
                        </Box>
                      </ListItemButton>
                    </ListItem>
                  );
                }
              })}

              {
                //  <ListItem disablePadding divider><ListItemButton><ListItemText primary='hello' /></ListItemButton></ListItem>
              }
            </List>
          </Box>
        </Box>




        {
          rc.history_show_settings_selector && (
            <Box
              name={"settingsList"}
              sx={{
                // mt: 2,
                // flexGrow: 1,
                // height: "100%",
                overflow: "hidden",
              }}
            >

              {showAiSettingsEditingControls()}

              <Box
                // component={wrapper}
                // ref={wrapper} // Option 1: use ref to scroll to bottom
                id={"settingsScrollWrapper"}
                sx={{
                  backgroundColor: theme.palette.background.paper,
                  maxHeight: "100%",
                  // flexGrow: 1,
                  overflow: "auto",
                  // display: 'flex', // Option 2: reverse column so that the last message is at the top
                  // flexDirection: "column-reverse" // Option 2: would need to reverse the order of the messages
                }}
              >
                <List
                  sx={{
                    p: 0,
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: theme.palette.border.normal,
                    borderBottom: 0,
                    borderBottomLeftRadius: "4px",
                    borderBottomRightRadius: "4px",
                  }}
                >
                  {aiConversationsSelected && aiConversationsSelected.aisettings && aiConversationsSelected.aisettings.map((s, index) => {

                    if (
                      aiConversationsIsEditing.active &&
                      aiConversationsIsEditing.type === "editSetting" &&
                      s.aisettingsindexid === aiSettingsIndexIdSelected.aisettingsindexid
                    ) {
                      return (
                        <ListItem
                          key={s.aisettingsindexid}
                          disablePadding
                          // divider
                          sx={{
                            backgroundColor:
                              aiSettingsIndexIdSelected && aiSettingsIndexIdSelected.aisettingsindexid === s.aisettingsindexid ? "grey.100" : "transparent",
                            // color: aiConversationsSelected && aiConversationsSelected.aiconversationid === c.aiconversationid ? theme.palette.primary.contrastText : 'inherit'
                            borderBottomColor: theme.palette.border.normal,
                            borderBottomStyle: "solid",
                            borderBottomWidth: "1px",
                            // pr: 0,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%",
                              minHeight: "46px",
                              alignItems: "center",
                              pl: 1,
                            }}
                          >
                            <Box
                              sx={{
                                flexGrow: 1,
                              }}
                            >
                              <TextField
                                // margin="normal"
                                required
                                fullWidth
                                // id="email"
                                // label="Name"
                                name="name"
                                autoComplete="name"
                                autoFocus
                                sx={{
                                  input: {
                                    py: "7px",
                                    px: "8px",
                                    backgroundColor: "white",
                                  },
                                }}
                                // size="small"
                                // value={aiSettingsDataUpdated.data.name}
                                value={aiSettingsItemName}
                                onChange={(e) => handleOnChangeAiSettingsItemName(e)}
                              // onChange={(e) => handleOnChangeAiSettingsData(e, 'name')}
                              />
                            </Box>

                            {aiSettingsItemNameSaving ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "30px",
                                  height: "30px",
                                  ml: 1,
                                }}
                              >
                                <CircularProgress
                                  size={12}
                                  sx={{
                                    p: 0,
                                    m: 0,
                                  }}
                                // variant="determinate"
                                />
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  ml: 1,
                                }}
                              >
                                <IconButton
                                  aria-label="Save"
                                  color="primary"
                                  size="small"
                                  sx={{
                                    "&.MuiIconButton-sizeSmall": {
                                      fontSize: "16px",
                                    },
                                  }}
                                  // variant="light"
                                  onClick={handleOnClickAiSettingsItemNameSave}
                                  disabled={isAiSettingsItemNameSaveDisabled()}
                                >
                                  <DoneIcon fontSize="inherit" />
                                </IconButton>
                              </Box>
                            )}

                            <Box>
                              <IconButton
                                aria-label="Cancel"
                                color="primary"
                                size="small"
                                sx={{
                                  "&.MuiIconButton-sizeSmall": {
                                    fontSize: "16px",
                                  },
                                }}
                                // variant="light"
                                onClick={handleOnClickAiConversationsEdit}
                              // disabled={isSendDisabled()}
                              >
                                <ClearIcon fontSize="inherit" />
                              </IconButton>
                            </Box>
                          </Box>
                        </ListItem>
                      );
                    }
                    else if (
                      aiConversationsIsEditing.active &&
                      aiConversationsIsEditing.type === "removeSetting" &&
                      s.aisettingsindexid === aiSettingsIndexIdSelected.aisettingsindexid
                    ) {
                      return (
                        <ListItem
                          key={s.aisettingsindexid}
                          disablePadding
                          // divider
                          sx={{
                            backgroundColor:
                              aiSettingsIndexIdSelected && aiSettingsIndexIdSelected.aisettingsindexid === s.aisettingsindexid ? "grey.100" : "transparent",
                            // color: aiConversationsSelected && aiConversationsSelected.aiconversationid === c.aiconversationid ? theme.palette.primary.contrastText : 'inherit'
                            borderBottomColor: theme.palette.border.normal,
                            borderBottomStyle: "solid",
                            borderBottomWidth: "1px",
                            // pr: 0,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%",
                              minHeight: "46px",
                              alignItems: "center",
                              pl: 2,
                            }}
                          >
                            <Box
                              sx={{
                                flexGrow: 1,
                                // backgroundColor: '#cc0000',
                                overflow: "hidden",
                              }}
                            >
                              <Typography
                                // nowrap
                                sx={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  // maxWidth: "240px",
                                }}
                              >
                                {s.name && s.name !== "" ? s.name : s.aisettingsindexid}
                              </Typography>
                            </Box>

                            <Box
                              sx={{
                                ml: 1,
                              }}
                            >
                              <IconButton
                                aria-label="Confirm"
                                color="primary"
                                size="small"
                                sx={{
                                  "&.MuiIconButton-sizeSmall": {
                                    fontSize: "16px",
                                  },
                                }}
                                // variant="light"
                                onClick={() => handleOnClickAiSettingsItemRemoveConfirm()}
                              // disabled={isAiConversationsNameSaveDisabled()}
                              >
                                <DoneIcon fontSize="inherit" />
                              </IconButton>
                            </Box>
                            <Box>
                              <IconButton
                                aria-label="Cancel"
                                color="primary"
                                size="small"
                                sx={{
                                  "&.MuiIconButton-sizeSmall": {
                                    fontSize: "16px",
                                  },
                                }}
                                // variant="light"
                                onClick={handleOnClickAiConversationsEdit}
                              // disabled={isSendDisabled()}
                              >
                                <ClearIcon fontSize="inherit" />
                              </IconButton>
                            </Box>
                          </Box>
                        </ListItem>
                      );
                    } else {
                      // if (clog()) console.log('setting', s)
                      return (
                        <ListItem
                          key={s.aisettingsindexid
                          }
                          disablePadding
                          // divider
                          sx={{
                            // backgroundColor: aiConversationsSelected && aiConversationsSelected.aiconversationid === s.aiconversationid ? "grey.100" : "transparent",
                            // color: aiConversationsSelected && aiConversationsSelected.aiconversationid === s.aiconversationid ? theme.palette.primary.contrastText : 'inherit'
                            borderBottomColor: theme.palette.border.normal,
                            borderBottomStyle: "solid",
                            borderBottomWidth: "1px",
                            // pr: 0,
                          }}
                        >
                          <ListItemButton
                            // onClick={() => handleSettingsListItemButtonOnClick(index, s)}
                            onClick={(e) => handleSettingsListItemButtonSingleDoubleClick(e, index, s)}
                            disabled={isSettingsIconButtonDisabled(s)}
                            sx={{
                              pr: "8px",
                              minHeight: "46px",
                            }}
                          >
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                // justifyContent: 'space-between',
                              }}
                            >
                              <Box
                                sx={{
                                  flexGrow: 1,
                                  // backgroundColor: '#cc0000',
                                  overflow: "hidden",
                                }}
                              >
                                <Typography
                                  // nowrap
                                  sx={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    fontWeight: aiSettingsIndexIdSelected && aiSettingsIndexIdSelected.aisettingsindexid === s.aisettingsindexid ? "bold" : "normal",
                                    color: aiSettingsIndexIdSelected && aiSettingsIndexIdSelected.aisettingsindexid === s.aisettingsindexid ? theme.palette.primary.main : "inherit",
                                  }}
                                >
                                  {s.name && s.name !== "" ? s.name : s.aisettingsindexid}
                                </Typography>


                              </Box>
                              <Box
                                sx={{
                                  width: "16px",
                                  maxWidth: "16px",
                                  // pt: "6px",
                                  ml: 1,
                                  display: "flex",
                                  // alignItems: "center",
                                  justifyContent: "center",
                                  // backgroundColor: '#ccc',
                                }}
                              >


                                {showAiMessageSwitchPromptIcon(s, index)}
                              </Box>

                            </Box>
                          </ListItemButton>
                        </ListItem>
                      );
                    }
                  })}

                  {
                    //  <ListItem disablePadding divider><ListItemButton><ListItemText primary='hello' /></ListItemButton></ListItem>
                  }
                </List>
              </Box>
            </Box>
          )}
      </Box>
    );

  }

  if (aiConversationsDocs && aiConversationsDocs.length === 0) {
    return (
      <Box>
        <Typography>No conversations found</Typography>
      </Box>
    );
  }
};

export default React.memo(AiHistoryPanel);
