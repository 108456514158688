import React from // useState,
  // useEffect,
  // useCallback
  "react";

import './assets/animations.css';

import ScrollTop from "./components/ScrollTop";
import ThemeCustomization from "./themes";

// import Routes from 'routes';
// import Routes from "./routes/Routes";
import AdminRoutes from "./routes/AdminRoutes";
import AiRoutes from "./routes/AiRoutes";

import { getUrlParams } from "./code/ecomacy";

import { AdminAuthProvider } from "./helpers/AdminAuthProvider";
import { AdminAiAuthProvider } from "./helpers/AdminAiAuthProvider";
import { CustomerAuthProvider } from "./helpers/CustomerAuthProvider";
import { AiAuthProvider } from "./helpers/AiAuthProvider";
import { RemoteConfigAuthProvider } from "./helpers/RemoteConfigAuthProvider";
import {setd, debug, setc, clog} from "./helpers/HelperFunctions";

const App = (props) => {
  let urlData = null;
  if (props && props.urlData) {
    urlData = props.urlData;
  }

  // const [userData, setUserData] = useState(tempUserData)
  // const [loaded, setLoaded] = useState(true)
  const urlDataParams = getUrlParams(urlData);

  // if (window.clog()) console.log('App import.meta.env.VITE_REACT_APP_ENV', import.meta.env.VITE_REACT_APP_ENV)

  if (urlDataParams && urlDataParams.type && urlDataParams.type === "admin") {
    return (
      <ThemeCustomization>
        <ScrollTop>
          <RemoteConfigAuthProvider>

            <AdminAuthProvider
              urlDataParams={urlData}
            >
              <AdminAiAuthProvider
                urlDataParams={urlData}
              >
                <AdminRoutes
                // userData={userData}
                />
              </AdminAiAuthProvider>

            </AdminAuthProvider>
          </RemoteConfigAuthProvider>
        </ScrollTop>
      </ThemeCustomization>
    );
  } else if (urlDataParams && urlDataParams.type && urlDataParams.type === "ai") {
    return (
      <ThemeCustomization>
        <ScrollTop>
          <RemoteConfigAuthProvider>
            <CustomerAuthProvider
              urlDataParams={urlDataParams}
            >
              <AiAuthProvider
                urlDataParams={urlDataParams}
              >
                <AiRoutes
                  urlDataParams={urlDataParams}
                // userData={userData}
                />
              </AiAuthProvider>
            </CustomerAuthProvider>
          </RemoteConfigAuthProvider>
        </ScrollTop>
      </ThemeCustomization>
    );
  } else if (urlDataParams && urlDataParams.type && urlDataParams.type === "grid") {
    // return (
    //   <ThemeCustomization>
    //     <ScrollTop>
    //       <RemoteConfigAuthProvider>
    //         <CustomerAuthProvider>
    //           <Routes
    //           // userData={userData}
    //           />
    //         </CustomerAuthProvider>
    //       </RemoteConfigAuthProvider>
    //     </ScrollTop>
    //   </ThemeCustomization>
    // );
  } else {
    if (
      urlDataParams && urlDataParams.url && urlDataParams.url !== "" && urlDataParams.url !== "/") {
      // redirect to admin
      // const url = urlDataParams.url.concat("/ai");
      if (clog()) console.log("redirect to admin", urlDataParams.url);
      window.location.href = '/admin';
    }

    // return (
    //   <ThemeCustomization>
    //     <ScrollTop>
    //     <RemoteConfigAuthProvider>
    //       <CustomerAuthProvider>
    //         <Routes
    //         // userData={userData}
    //         />
    //       </CustomerAuthProvider>
    //     </RemoteConfigAuthProvider>
    //     </ScrollTop>
    //   </ThemeCustomization>
    // )
  }
};

export default App;
