import React, { useEffect, useState, useContext } from "react";

import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import { firebase, authenticate } from "../code/firebase";

// import ReactDOM from "react-dom/client";
import {
  // BrowserRouter,
  Routes,
  Route,
  // useNavigate
} from "react-router-dom";

import MainLayout from "../layout/MainLayout";
import AiLayout from "../layout/AiLayout";
//import TestPane from '../components/TestPane'
// import GridKeywordsContainer from '../components/GridKeywordsContainer'
// import SettingsContainer from '../components/SettingsContainer'
// import GridKeywordsPane from '../components/GridKeywordsPane'

import AiContainer from "../components/AiContainer";
import LoadingPane from "../components/LoadingPane";

import { CustomerAuthContext } from "../helpers/CustomerAuthProvider";

const auth = getAuth(firebase);

// ==============================|| ROUTING RENDER ||============================== //

export default function AiRoutes(props) {


  const { getAdminStatus } = useContext(CustomerAuthContext);
  const { logout } = useContext(CustomerAuthContext);

  const [user, loadingUser, errorUser] = useAuthState(auth);
  const [init, setInit] = useState(true);
  // const userData = props.userData
  const urlDataParams = props.urlDataParams;

  // const userData = {}

  useEffect(() => {
    const getUserStatus = async () => {
      const idTokenResult = await getAdminStatus();
      // check if user is an admin
      if (idTokenResult.isAdmin === false && idTokenResult.isSuperAdmin === false) {
        // check to see if user.uid matches locationId
        if (user.uid !== urlDataParams.locationId) {
          // if not the log them in anonymously to correct account
          await logout();
          // authenticate();
          // reload the page
          window.location.reload(true);
        }
        else {
          // user matches locationId, proceed with loading data
          setInit(false);
        }
      } else {
        const result = await authenticate(urlDataParams);
        setInit(false);
      }
    };

    const authUser = async () => {
      const result = await authenticate(urlDataParams);
      if (result.error) {
        ;
      }
      setInit(false);
    };

    if (loadingUser === false && init === true) {
      if (user) {
        getUserStatus()
          // make sure to catch any error
          .catch(console.error);
      } else {
        authUser()
          // make sure to catch any error
          .catch(console.error);
        // log in anonymously
        // const result = await authenticate();

      }

      // setInit(false);
    }
  }, [loadingUser]);

  // useEffect(() => {
  //   if (user || errorUser) {
  //     setInit(false);
  //   }
  // }, [user, errorUser]);

  if (!init && user) {
    return (
      <Routes>
        <Route path="/" element={<AiLayout />}>
          <Route
            index
            element={<AiContainer />}
            // element={<SettingsContainer props={userData} />}
          />
        </Route>
      </Routes>
    );
  }

  if (loadingUser) {
    return (
      <LoadingPane
        text={"Loading User Data..."}
        showSpinner={true}
        noProgress={true}
        // progress={100}
      />
    );
  }

  if (errorUser) {
    return (
      <LoadingPane
        text={"Error Loading User. Please contact support"}
        showSpinner={false}
        // progress={100}
      />
    );
  }

  if (init) {
    return (
      <LoadingPane
        text={"Loading User..."}
        showSpinner={true}
        noProgress={true}
        // progress={100}
      />
    );
  }

  if (!init && !user) {
    if (clog()) console.log("urlDataParams", urlDataParams);
    return (
      <LoadingPane
        text={"Error loading user. Please try again."}
        showSpinner={false}
        // progress={100}
      />
    );
  }

  // return (
  //   <Routes>
  //     <Route path="/" element={<App />}>
  //       <Route index element={<Home />} />
  //       <Route path="teams" element={<Teams />}>
  //         <Route path=":teamId" element={<Team />} />
  //         <Route path="new" element={<NewTeamForm />} />
  //         <Route index element={<LeagueStandings />} />
  //       </Route>
  //     </Route>
  //   </Routes>
  // )
}
