import React, { useContext } from "react";

// import PropTypes from 'prop-types';

// import { useTheme } from '@mui/material/styles';
import {
  Container,
  Box,
  CircularProgress,
  Button,
  // Tab,
  // Tabs,
  // Grid
} from "@mui/material";
// import Container from '@mui/material/Container';

import {setd, debug, setc, clog} from "../helpers/HelperFunctions";
import { AdminAuthContext } from "../helpers/AdminAuthProvider";

const NoApiKeyPane = (props) => {


  const { adminUserData, setAdminUserData } = useContext(AdminAuthContext);
  const { showSettingsDialog, setShowSettingsDialog } = useContext(AdminAuthContext);

  let text = "";
  if (props.text) {
    text = props.text;
  }

  let buttonText = "";
  if (props.buttonText) {
    buttonText = props.buttonText;
  }

  let showSpinner = true;
  if (props.showSpinner === true || props.showSpinner === false) {
    showSpinner = props.showSpinner;
  }

  let progress = 100;
  if (props.progress) {
    progress = props.progress;
  }

  const handleShowSettingsDialog = () => {
    if (clog()) console.log("show dialog:", adminUserData);
    setShowSettingsDialog(true);
  };

  // const theme = useTheme();


  if (clog()) console.log('AdminApiSetupPane adminUserData:', adminUserData)

  return (
    <Container
      maxWidth="lg"
      sx={{
        minHeight: `calc(100vh - 153px)`,
        height: `calc(100vh - 153px)`,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        {showSpinner ? <CircularProgress variant="determinate" value={progress} /> : <></>}

        <Box
          sx={{
            mt: 1,
            fontSize: "20px",
            fontWeight: 700,
          }}
        >
          {text}
        </Box>

        <Box
          sx={{
            mt: 1,
          }}
        >
          <Button
            aria-label="Save"
            color="primary"
            variant="contained"
            sx={{
              minWidth: "70px",
            }}
            onClick={handleShowSettingsDialog}
            // disabled={isSaveDisabled()}
          >
            {buttonText}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default React.memo(NoApiKeyPane);
