import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import StarRatings from 'react-star-ratings';

// material-ui
import {
  //Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Link,
  Tooltip
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
// third-party
import { useTable, useSortBy, useFlexLayout } from 'react-table';

// project import
import MainCard from './MainCard';
import ScrollX from './ScrollX';
// import LinearWithLabel from './@extended/Progress/LinearWithLabel';
import { HeaderSort } from './third-party/ReactTable';
// import makeData from '../data/react-table';
import '../assets/extra.css'

import { useTheme } from '@mui/material/styles';


// ==============================|| REACT TABLE ||============================== //

function ReactTable({ columns, data, getHeaderProps }) {
  const theme = useTheme();

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data
    },
    useFlexLayout,
    useSortBy
  );

  const dataLength = data.length
  const sortingRow = rows.slice(0, dataLength);
  // const sortingRow = rows.slice(0, 20);

  const handleHelpIconOnClick = (e) => {
    e.stopPropagation();
  }
  return (
    <Table {...getTableProps()}>
      <TableHead>
        {headerGroups.map((headerGroup, i) => (
          <TableRow key={i} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, index) => {
              return (
              <TableCell key={index} {...column.getHeaderProps([{ className: column.className }, getHeaderProps(column), (column.getSortByToggleProps({ title: undefined }))])}>
                <HeaderSort column={column} />
                <Box
                  sx={{
                    display: 'inline',
                    position: 'relative'
                  }}
                >
                  {column.tooltipText !== '' &&
                    <Tooltip
                    title={column.tooltipText}
                    placement="top"
                    arrow
                    componentsProps={{
                      tooltip: {
                        sx: {
                          // color: "purple",
                          // backgroundColor: "lightblue",
                          fontSize: '14px'
                        }
                      }
                    }}
                    >
                      <HelpIcon
                        color="primary"
                        onClick={handleHelpIconOnClick}
                        sx={{
                          position: 'absolute',
                          top: '-1.5px',
                          fontSize: 16,
                          // pt: '4px',
                          ml: '4px'
                        }}
                      />
                    </Tooltip>
                  }


                </Box>
              </TableCell>
            )}
            )}
          </TableRow>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {sortingRow.map((row, i) => {
          prepareRow(row);

          return (
            <TableRow
            key={i}
            sx={{
              // borderLeftWidth: 0,
              backgroundColor: '#fff',
              ...(row.original.userLocation === true  && {
                backgroundColor: theme.palette.primary.lighter,
                '&:hover': {
                  backgroundColor: theme.palette.primary[100] + ' !important',
                }
              })
            }}
            {...row.getRowProps()}
            >
              {row.cells.map((cell, index) => {

                return (
                <TableCell key={index} {...cell.getCellProps([{ className: cell.column.className }])}>
                  {cell.render('Cell')}
                </TableCell>
              )})}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

ReactTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  getHeaderProps: PropTypes.func,
};

// ==============================|| REACT TABLE - SORTING ||============================== //

const GridItemTable = (props) => {
  const theme = useTheme();

  const { tableData, gridItemParams } = props

  // const data = useMemo(() => makeData(40), []);
  // if (clog()) console.log(' data', JSON.stringify(data))
  const data = useMemo(() => tableData, []);
  /*
    const columns = useMemo(
      () => [
        {
          Header: 'First Name',
          accessor: 'firstName'
        },
        {
          Header: 'Last Name',
          accessor: 'lastName'
        },
        {
          Header: 'Email',
          accessor: 'email'
        },
        {
          Header: 'Age',
          accessor: 'age',
          className: 'cell-right'
        },
        {
          Header: 'Role',
          accessor: 'role'
        },
        {
          Header: 'Visits',
          accessor: 'visits',
          className: 'cell-right'
        },
        {
          Header: 'Status',
          accessor: 'status',
          // eslint-disable-next-line
          Cell: ({ value }) => {
            switch (value) {
              case 'Complicated':
                return <Chip color="error" label="Complicated" size="small" variant="light" />;
              case 'Relationship':
                return <Chip color="success" label="Relationship" size="small" variant="light" />;
              case 'Single':
              default:
                return <Chip color="info" label="Single" size="small" variant="light" />;
            }
          }
        },
        {
          Header: 'Profile Progress',
          accessor: 'progress',
          // eslint-disable-next-line
          Cell: ({ value }) => <LinearWithLabel value={value} sx={{ minWidth: 75 }} />
        }
      ],
      []
    );
  */
  const columns = useMemo(
    () => [
      {
        Header: 'Pos',
        accessor: 'position',
        width: 100,
        tooltipText: 'This is the position of the business in the Google search results.',
        // eslint-disable-next-line
        Cell: ({ row, value }) => {
          return (
            <Box
              sx={{
                color: '#fff',
                fontSize: '14px',
                fontWeight: 'bold',
                backgroundColor: theme.palette.primary.main,
                borderRadius: '50px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                width: '22px',
                height: '22px',
                ...(row.original.userLocation === true  && {
                  backgroundColor: theme.palette.success.main,
                })
              }}
            >
              {value}
            </Box>
          )
        }
      },
      {
        Header: 'Business Name',
        accessor: 'businessName',
        className: 'noTextOverflow',
        tooltipText: '',
        // maxWidth: 200,
        // minWidth: 140,
        width: 440,
        Cell: ({ row, value }) => {
          // if (clog()) console.log('row', row)
          let cid = ''
          if (row.original.cid !== '') {
            cid = row.original.cid
          }

          let val = value
          if (cid && cid !== '') {
            val = <Link
              sx={{
                fontWeight: 700,
                color: theme.palette.primary.main,
                '&:hover': {
                  color: theme.palette.primary.dark + ' !important',
                  textDecoration: 'none'
                },
                '&:visited': {
                  color: theme.palette.primary.main
                }
              }}
              href={`https://maps.google.com/?cid=${cid}`} target="_blank" rel="noreferrer"
            >
              {value}
            </Link>
          }

          return (
            <b>{val}</b>
          )
        }
      },
      {
        Header: 'Proximity',
        accessor: 'proximity',
        tooltipText: 'This is the distance between the selected Grid Point and the competing business ranking for the keyword.',
        width: 130,
        // eslint-disable-next-line
        Cell: ({ value }) => {

          let unitText = 'miles'
          if (gridItemParams && gridItemParams.distanceunit) {
            if (gridItemParams.distanceunit === 'meters') {
              unitText = 'kilometers'
            }
            else {
              unitText = gridItemParams.distanceunit
            }
          }

          var round = parseFloat(value.toFixed(2));
          return (
            round + ' ' + unitText
          )
        }

      },
      {
        Header: 'Reviews',
        accessor: 'reviews',
        tooltipText: 'This is the number of reviews this business has on Google.',
        width: 130,
        // className: 'cell-right'
      },
      {
        Header: 'Rating',
        accessor: 'rating',
        tooltipText: 'This is the average rating score for Google reviews of this business.',
        // eslint-disable-next-line
        Cell: ({ row, value }) => {
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                // justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  pt: '4px'
                }}
              >
                <StarRatings
                  rating={value}
                  starDimension="16px"
                  starSpacing="0px"
                  starRatedColor='#ffbf00'
                />
              </Box>
              <Box
                sx={{
                  pl: '5px'
                }}
              >
                {value}
              </Box>

            </Box>
          )
        }
      }
    ],
    []
  );

//  const getTrProps = (state, rowInfo, instance) => {
//    if (rowInfo) {
//      return {
//        style: {
//          border: rowInfo
//            ? rowInfo.row.age >= 20
//              ? "solid 1px black"
//              : "none"
//            : "none"
//        }
//      };
//    }
//    else {
//    }
//    return {};
//  };

  return (
    <MainCard content={false}>
      <ScrollX>
        <ReactTable
        columns={columns}
        data={data}
        getHeaderProps={(column) => column.getSortByToggleProps()}
        // getTrProps={(row) => row.getTrProps()}
        />
      </ScrollX>
    </MainCard>
  );
};

export default GridItemTable;
