import React, { useState, useEffect, useContext, useLayoutEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";

import {
  getFirestore,
  collection,
  query,
  where,
  // orderBy
} from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { firebase } from "../code/firebase";
import { processKeywordsUserData } from "../code/ecomacy";

import { RemoteConfigAuthContext } from "../helpers/RemoteConfigAuthProvider";
import { CustomerAuthContext } from "../helpers/CustomerAuthProvider";
import { AiAuthContext } from "../helpers/AiAuthProvider";
import { setd, debug, setc, clog } from "../helpers/HelperFunctions";

import _ from "lodash";

import {
  useTheme,
  // styled
} from "@mui/material/styles";

import {
  Box,
  // Tab,
  // Tabs,
  Typography,
  Button,
  Grid,
  Paper,
  // Slider,
  TextField,
  TextareaAutosize,
  IconButton,
  CircularProgress,
  LinearProgress,
  Chip,
  Snackbar,
  Alert,
  Grow,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { styled } from "@mui/system";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import LinkIcon from "@mui/icons-material/LinkOutlined";
import LinkOffIcon from "@mui/icons-material/LinkOffOutlined";

import Container from "@mui/material/Container";
import GridKeywordTimelinePane from "./GridKeywordTimelinePane";
import LoadingSubPane from "./LoadingSubPane";
import { set } from "date-fns";
import { re } from "mathjs";

// import MainCard from './MainCard';
// import { getDfsLocationData } from '../code/ecomacy'

// import './GridKeywordsPane.css'
// const auth = getAuth(firebase)

// const StyledTextareaAutosize = styled(TextareaAutosize)(
//   ({ theme }) => `
//   width: 100%;
//   max-height: calc(100vh - 99px);
//   font-family: IBM Plex Sans, sans-serif;
//   font-size: 0.875rem;
//   font-weight: 400;
//   line-height: 1.5;
//   padding: 12px;
//   border-radius: 4px 4px 0 4px;
//   color: ${theme.palette.mode === 'dark' ? theme.palette.grey[300] : theme.palette.grey[900]};
//   background: ${theme.palette.mode === 'dark' ? theme.palette.grey[900] : '#fff'};
//   border: 1px solid ${theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[300]};

//   &:hover {
//     border-color: ${theme.palette.primary[400]};
//   }

//   &:focus {
//     border-color: ${theme.palette.primary[400]};
//   }

//   // firefox
//   &:focus-visible {
//     outline: 0;
//   }
// `,
// );

// const StyledTextField = styled(TextField)`
//   textarea {
//     resize: both;
//   }
// `;

const BorderTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    // boxShadow: theme.shadows[1],
  },
  [`& .${tooltipClasses.arrow}::before`]: {
    color: theme.palette.common.white,
    // boxShadow: theme.shadows[1],

    border: `1px solid ${theme.palette.primary.main}`,
    boxSizing: "border-box",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    // boxShadow: theme.shadows[1],
    fontSize: "14px",
    border: `1px solid ${theme.palette.primary.main}`,
    // width: "400px",
    maxWidth: "300px",
  },
}));

const AiQuestionsPanel = (props) => {
  const theme = useTheme();


  const { rc } = useContext(RemoteConfigAuthContext);
  const { userData } = props;

  const { adminStatus, setAdminStatus } = useContext(CustomerAuthContext);

  const { testVal, setTestVal } = useContext(AiAuthContext);

  const { aiAgentsSelected, setAiAgentsSelected } = useContext(AiAuthContext);

  const { aiConversationsSelected, setAiConversationsSelected } = useContext(AiAuthContext);
  const { aiConversationFetchData, setAiConversationFetchData } = useContext(AiAuthContext);
  const { prepareAiConversationSmallData } = useContext(AiAuthContext);


  const { aiSettingsData, setAiSettingsData } = useContext(AiAuthContext);
  const { aiSettingsDataUpdated, setAiSettingsDataUpdated } = useContext(AiAuthContext);
  const { aiSettingsDataRef } = useContext(AiAuthContext);
  const { aiSettingsDataConversationIdRef } = useContext(AiAuthContext);

  const { aiSettingsSelected, setAiSettingsSelected } = useContext(AiAuthContext);
  const { aiSettingsSelectedRefresh } = useContext(AiAuthContext);

  const { aiSettingsIndexIdSelected, setAiSettingsIndexIdSelected } = useContext(AiAuthContext);

  const { saveAiSettings } = useContext(AiAuthContext);
  const { aiSettingsSaving, setAiSettingsSaving } = useContext(AiAuthContext);
  // const [aiSettingsSaving, setAiSettingsSaving] = useState(false);

  const { saveAiSettingsAutomationLink } = useContext(AiAuthContext);


  const { getAiPromptVersionsDocs } = useContext(AiAuthContext);
  const { aiPromptVersionsDocs, setAiPromptVersionDocs } = useContext(AiAuthContext);
  // const [aiPromptVersionsTabs, setAiPromptVersionsTabs] = useState(null);
  const { getSelectedIndexFromAiPromptVersionsDocs } = useContext(AiAuthContext);
  const { getSelectedNewIndexFromAiPromptVersionsDocs } = useContext(AiAuthContext);

  const { aiPromptVersionSelected, setAiPromptVersionSelected } = useContext(AiAuthContext);
  const { getAiPromptVersionData } = useContext(AiAuthContext);
  const aiPromptVersionSelectedinit = useRef(true);
  const { cleanAiPromptVersionDataForAiSettings } = useContext(AiAuthContext);
  const { mergeAiPromptVersionAiSettings } = useContext(AiAuthContext);
  const { aiPromptVersionReset, setAiPromptVersionReset } = useContext(AiAuthContext);
  const { getDefaultAiPreset } = useContext(AiAuthContext);
  const { processAddPromptVersionAiSettingsData } = useContext(AiAuthContext);
  const [aiPromptVersionAdded, setAiPromptVersionAdded] = useState(null);
  const [aiPromptVersionNewSaving, setAiPromptVersionNewSaving] = useState("");

  const [aiPromptVersionAddAutomationLinkSaving, setAiPromptVersionAddAutomationLinkSaving] = useState(false);
  // const [automationLinkSaving, setAutomationLinkSaving] = useState(false);


  const { aiEngineersPanelShow, setAiEngineersPanelShow } = useContext(AiAuthContext);

  const { aiModelSelected, setAiModelSelected } = useContext(AiAuthContext);
  const { aiModelSelectedRefresh, setAiModelSelectedRefresh } = useContext(AiAuthContext);
  const { aiStrategySelected, setAiStrategySelected } = useContext(AiAuthContext);
  const { aiStrategySelectedRefresh, setAiStrategySelectedRefresh } = useContext(AiAuthContext);

  const [improvePromptIterations, setImprovePromptIterations] = useState(1);
  const { updateAiConversationImproveProcessing } = useContext(AiAuthContext);
  const { getAiQuestionsImprovePrompt } = useContext(AiAuthContext);
  const [improvePromptSaving, setImprovePromptSaving] = useState(false);
  const { aiConversation, setAiConversation } = useContext(AiAuthContext);
  // const { saveAiSettingsX } = useContext(AiAuthContext);

  const { aiAgentsIsEditing, setAiAgentsIsEditing } = useContext(AiAuthContext);
  const { aiAgentsIsRemoving, setAiAgentsIsRemoving } = useContext(AiAuthContext);
  const { aiSettingsIsEditing, setAiSettingsIsEditing } = useContext(AiAuthContext);
  const { aiConversationsIsEditing, setAiConversationsIsEditing } = useContext(AiAuthContext);
  const { aiConversationsIsRemoving, setAiConversationsIsRemoving } = useContext(AiAuthContext);

  const { aiEngineersPanelHeightResizing, setAiEngineersPanelHeightResizing } = useContext(AiAuthContext);

  const { aiMessagesDoc, setAiMessagesDoc } = useContext(AiAuthContext);


  const [init, setInit] = useState(true);

  const [strategy, setStrategy] = useState(null);

  // const [questionsHeight, setQuestionsHeight] = useState(0);
  // const elementRef = useRef(null);

  const [improvePromptProgress, setImprovePromptProgress] = React.useState(0);

  const [openSnackbar, setOpenSnackbar] = React.useState({
    open: false,
    message: "",
  });

  // // initial load - based on AiChatPanel: fetchAiConversationData
  // useEffect(() => {
  //   if (init && aiConversationsSelected) {
  //     debugger
  //     fetchAiPromptVersionsData()
  //       // make sure to catch any error
  //       .catch(console.error);

  //     setInit(false);
  //   }
  // }, [init, aiConversationsSelected]);

  // // aiConversationsSelected is changed - based on AiChatPanel: fetchAiConversationData
  // useEffect(() => {
  //   if (!init && aiConversationFetchData === false) {
  //     // aiConversationSelected data has been updated but is still the same conversation
  //     // happens when editing the name of a selected conversation in HistoryPanel
  //     setAiConversationFetchData(true);
  //   } else if (!init && aiConversationsSelected) {
  //     if (clog()) console.log("useEffect [aiConversationsSelected]");
  //     debugger
  //     fetchAiPromptVersionsData()
  //       // make sure to catch any error
  //       .catch(console.error);
  //     // setInit(false)
  //   } else if (!init && aiConversationsSelected === null) {
  //   }
  // }, [aiConversationsSelected]);


    // initial load - based on AiChatPanel: fetchAiConversationData
    // NOTE: used to use above code:
    // useEffect > [aiConversationsSelected] to fetch aiPromptVersionsDocs
    useEffect(() => {
      if (
        init &&
        aiSettingsIndexIdSelected?.index > -1
        ) {
        fetchAiPromptVersionsData()
          // make sure to catch any error
          .catch(console.error);

        setInit(false);
      }
    }, [init, aiSettingsIndexIdSelected]);


  // aiSettingsIndexIdSelected is changed - based on AiChatPanel: fetchAiConversationData
  useEffect(() => {
    if (!init) {

      let aiSettingsIndexIdSelectedSettingsIndexId = "";
      if (aiSettingsIndexIdSelected.aisettingsindexid) {
        aiSettingsIndexIdSelectedSettingsIndexId = aiSettingsIndexIdSelected.aisettingsindexid;
      }

      if (aiSettingsIndexIdSelectedSettingsIndexId !== "") {
        // check if aiSettingsIndexIdSelectedSettingsIndexId exists in aiPromptVersionsDocs
        const found = aiPromptVersionsDocs.find((element) => element.aisettingsindexid === aiSettingsIndexIdSelectedSettingsIndexId);
        // aisettingsindexid not found so fetch docs
        if (!found) {
          // BT debugger
          //
          if (clog()) console.log("useEffect [aiSettingsIndexIdSelected]");
          fetchAiPromptVersionsData()
            // make sure to catch any error
            .catch(console.error);
        }

      }
    }
  }, [aiSettingsIndexIdSelected]);

  const fetchAiPromptVersionsData = useCallback(async () => {
    // BT ZZZ
    // const test = aiSettingsIndexIdSelected
    // BT debugger
    const result = await getAiPromptVersionsDocs(userData, aiConversationsSelected, aiSettingsIndexIdSelected);
    // trigger useEffect in AiHistoryPanel
    // setRefreshAiConversationSettingsData(true);
    if (result && result.data) {
      if (clog()) console.log("getAiPromptVersionsDocs result.data", result.data);
      //setAiPromptVersionsTabs(result.data);
      ;

      // find and set the current selected prompt version
      const selected = getSelectedIndexFromAiPromptVersionsDocs(aiConversationsSelected, result.data, aiSettingsIndexIdSelected);

      // first fetch so set aiPromptVersionSelected init to true
      aiPromptVersionSelectedinit.current = true;

      setAiPromptVersionSelected(selected);
      //   setAiConversation(result.data)
      // if (result.data.messages && result.data.messages.length > 0) {
      //   // setAiChatMessagesData(result.data.messages)
      //   messageCount.current = result.data.messages.length;
      // }
    }
  }, [getAiPromptVersionsDocs]);

  // when aiPromptVersionSelected changes
  useEffect(() => {
    // declare the data fetching function
    const fetchAiPromptVersionData = async (uData, promptVersionSelected) => {
      // const test = aiSettingsDataUpdated;
      if (promptVersionSelected.aipromptversionid && promptVersionSelected.aipromptversionid !== "") {
        const returnData = await getAiPromptVersionData(uData, promptVersionSelected);
        if (returnData && returnData.data) {
          const cleanedPromptVersionData = cleanAiPromptVersionDataForAiSettings(uData, returnData.data);
          if (clog()) console.log("cleanAiPromptVersionDataForAiSettings cleanedPromptVersionData", cleanedPromptVersionData);
          if (cleanedPromptVersionData && cleanedPromptVersionData.data) {
            const mergedAiSettingsData = mergeAiPromptVersionAiSettings(uData, cleanedPromptVersionData.data, aiSettingsDataUpdated);
            if (mergedAiSettingsData && mergedAiSettingsData.data) {
              setAiSettingsSaving(true);
              // add mergedAiSettingsData to aiSettingsDataUpdated
              // get settings name from aiSettingsDataUpdated
              let settingsName = "";
              if (aiSettingsDataUpdated && aiSettingsDataUpdated.name && aiSettingsDataUpdated.name !== "") {
                settingsName = aiSettingsDataUpdated.name;
              }

              const newSettingsData = {
                ...mergedAiSettingsData.data,
                name: settingsName
              }

              // save aiSettingsDataUpdated
              const result = await saveAiSettings(uData, aiAgentsSelected, aiConversation, newSettingsData, aiPromptVersionSelected, aiSettingsIndexIdSelected);

              if (result && result.data) {
                const { data } = result;

                const settingsData = data.settingsData

                // update aiConversationsSelected
                // const smallData = prepareAiConversationSmallData(aiConversation, data, aiSettingsIndexIdSelected);
                const smallData = prepareAiConversationSmallData(aiConversation, settingsData, aiSettingsIndexIdSelected);
                setAiConversationsSelected(smallData);

                setAiSettingsData(newSettingsData);
                setAiSettingsDataUpdated(newSettingsData);
                // set ref for comparisons after settings save
                aiSettingsDataRef.current = newSettingsData;

                // update aiConversation to show current conversation
                // setAiConversation(aiConversationTemp.current);

                // trigger useEffect in AiModelPanel
                setAiModelSelectedRefresh(true);
                // trigger useEffect in AiQuestionsPanel
                setAiStrategySelectedRefresh(true);

              }

              setAiSettingsSaving(false);

            }
          }
        }
      }
    };

    if (
      aiPromptVersionSelected.aiconversationid !== "" &&
      aiConversationsSelected.aiconversationid !== ""
      // && aiPromptVersionSelected.aiconversationid !== aiConversationsSelected.aiconversationid
    ) {
      // BT debugger
      if (aiPromptVersionSelectedinit.current === true) {
        // initial fetch so don't run
        aiPromptVersionSelectedinit.current = false;
      } else {
        // if (aiPromptVersionSelected.promptversion !== aiConversationsSelected.promptversion) {
        // get aiPromptVersionSelected
        fetchAiPromptVersionData(userData, aiPromptVersionSelected)
          // make sure to catch any error
          .catch(console.error);
        // }
      }
    }
  }, [aiPromptVersionSelected]);

  useEffect(() => {
    // triggered from AiSettingsPanel SAVE handleOnClickCancel
    if (aiPromptVersionReset === true) {
      // find and set the current selected prompt version

      const selected = getSelectedIndexFromAiPromptVersionsDocs(aiConversationsSelected, aiPromptVersionsDocs, aiSettingsIndexIdSelected);

      // first fetch so set aiPromptVersionSelected init to true
      // aiPromptVersionSelectedinit.current = true;
      setAiPromptVersionSelected(selected);

      setAiPromptVersionReset(false);
    }
  }, [aiPromptVersionReset]);

  useEffect(() => {
    if (aiPromptVersionsDocs.length > 0 && aiPromptVersionAdded !== null) {
      // check if aiPromptVersionSelected is set
      if (aiPromptVersionSelected && aiPromptVersionSelected.promptversion !== -1) {
        // find and set the current selected prompt version
        debugger
        const selected = getSelectedNewIndexFromAiPromptVersionsDocs(aiPromptVersionAdded, aiPromptVersionsDocs, aiSettingsIndexIdSelected);
        debugger
        setAiPromptVersionSelected(selected);
      }
      setAiPromptVersionAdded(null);
    }
  }, [aiPromptVersionsDocs]);

  // useLayoutEffect(() => {
  //   const handleResize = () => {
  //     // if (clog()) console.log('handleResize: ', elementRef.current);
  //     if (elementRef.current) {
  //       if (clog()) console.log("handleResize:", elementRef.current.offsetHeight);
  //       // setQuestionsHeight(elementRef.current.offsetHeight - 57);
  //       setQuestionsHeight(elementRef.current.offsetHeight);
  //     }
  //   };

  //   handleResize();
  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, [aiStrategySelected]);

  // useEffect(() => {
  //   // if (!aiEngineersPanelShow) {
  //     if (elementRef.current) {
  //       if (clog()) console.log("handleShowHide:", elementRef.current.offsetHeight);
  //       // setQuestionsHeight(elementRef.current.offsetHeight - 57);
  //       setQuestionsHeight(elementRef.current.offsetHeight);
  //     }
  //   // }

  // }, [aiEngineersPanelShow]);

  // useEffect(() => {
  //   if (!aiEngineersPanelShow) {
  //     if (elementRef.current) {
  //       if (clog()) console.log("handleShowHide:", elementRef.current.offsetHeight);
  //       // setQuestionsHeight(elementRef.current.offsetHeight - 57);
  //       setQuestionsHeight(elementRef.current.offsetHeight);
  //     }
  //   }

  // }, [aiEngineerConversationFetched]);

  // init
  // useEffect(() => {
  //   debugger
  //   if (init) {
  //     setInit(false);
  //   }
  // }, []);

  // timer for improvePromptProgress bar
  useEffect(() => {
    if (improvePromptSaving) {
      setImprovePromptProgress(0);

      const timer = setInterval(() => {
        setImprovePromptProgress((oldProgress) => {
          if (oldProgress === 100) {
            return 0;
          }
          const difference = Math.random() * 10;
          return Math.min(oldProgress + difference, 100);
        });
      }, 500);

      return () => {
        clearInterval(timer);
      };
    } else {
      setImprovePromptProgress(0);
    }
  }, [improvePromptSaving]);

  useEffect(() => {
    //// check for improveprocessing flag
    if (aiConversation && aiConversation.improveprocessing) {
      setImprovePromptSaving(true);
      setAiSettingsIsEditing({
        active: true,
        type: "improvePromptProcessing",
      });
    } else {
      setImprovePromptSaving(false);
      setAiSettingsIsEditing({
        active: false,
        type: "",
      });

      // if (!init && aiConversation && aiConversationsSelected) {
      //   if (aiConversation.aiconversationid === aiConversationsSelected.aiconversationid) {
      //     debugger
      //   }
      // }
    }
  }, [aiConversation]);

  // useEffect(() => {

  // }, [aiSettingsData]);

  useEffect(() => {
    if (!improvePromptSaving) {
      let aiSettingsDataConversationId = "";
      if (aiSettingsDataConversationIdRef.current && aiSettingsDataConversationIdRef.current !== "") {
        aiSettingsDataConversationId = aiSettingsDataConversationIdRef.current;
      }

      if (aiSettingsDataConversationId !== "" && aiConversation.aiconversationid === aiSettingsDataConversationIdRef.current) {
        // check
        //// check for updated prompt
        let liveSettingsData = null;
        // unpack aisettings data
        // if (aiConversation && aiConversation.aisettings && aiConversation.aisettings !== "") {
        //   liveSettingsData = JSON.parse(aiConversation.aisettings);
        // }
        if (aiConversation && aiConversation.aisettings) {
          liveSettingsData = aiConversation.aisettings;
        }

        // compare to aiSettingsData
        if (liveSettingsData && aiSettingsData && aiSettingsData.aisettingid === liveSettingsData.aisettingid) {
          // aisettingid matches
          let liveSettingsStrategy = "";
          if (liveSettingsData.data.strategy && liveSettingsData.data.strategy && liveSettingsData.data.strategy !== "") {
            liveSettingsStrategy = liveSettingsData.data.strategy;
          }

          let settingsStrategy = "";
          if (aiSettingsData.data.strategy && aiSettingsData.data.strategy && aiSettingsData.data.strategy !== "") {
            settingsStrategy = aiSettingsData.data.strategy;
          }

          if (liveSettingsStrategy !== "" && settingsStrategy !== "" && liveSettingsStrategy === settingsStrategy) {
            // strategy matches
            let liveStrategyData = null;
            if (liveSettingsData && liveSettingsData[liveSettingsStrategy]) {
              liveStrategyData = liveSettingsData[liveSettingsStrategy];
            }

            let strategyData = null;
            if (aiSettingsData && aiSettingsData[settingsStrategy]) {
              strategyData = aiSettingsData[settingsStrategy];
            }

            if (liveStrategyData && strategyData) {
              // compare data
              if (!_.isEqual(liveStrategyData, strategyData)) {
                // data is different
                // update aiSettingsData
                setAiSettingsData({
                  ...aiSettingsData,
                  [settingsStrategy]: liveStrategyData,
                });
                // update aiSettingsDataUpdated
                setAiSettingsDataUpdated({
                  ...aiSettingsDataUpdated,
                  [settingsStrategy]: liveStrategyData,
                });
                if (debug()) debugger;
              } else {

              }
            }
          }
        }
      }
    }
  }, [improvePromptSaving]);

  // if (clog()) console.log('AiModelPanel aiSettingsData', aiSettingsData);

  // use this to populate missing data
  //  useEffect(() => {
  //    if (!init && aiStrategySelected) {
  //      // set menu
  //      // find aiSettingsDataUpdated
  //      let selectedStrategyId = ''
  //      if (aiStrategySelected.strategy && aiStrategySelected.strategy !== '') {
  //        selectedStrategyId = aiStrategySelected.strategy
  //      }
  //      if (
  //        selectedStrategyId !== ''
  //      ) {
  //        if (
  //          aiSettingsDataUpdated &&
  //          aiSettingsDataUpdated[selectedStrategyId]
  //        ) {
  //
  //          let strategyData = null
  //          if (aiSettingsDataUpdated[selectedStrategyId]) {
  //            strategyData = aiSettingsDataUpdated[selectedStrategyId]
  //          }
  //
  //          if (strategyData) {
  //            setStrategy(strategyData)
  //          }
  //        }
  //        else {
  //          setStrategy(null)
  //        }
  //      }
  //    }
  //  }, [aiStrategySelected])

  //  useEffect(() => {
  //    if (init && aiStrategySelected) {
  //      // set menu
  //      // find aiSettingsDataUpdated
  //      let selectedStrategyId = ''
  //      if (aiStrategySelected.strategy && aiStrategySelected.strategy !== '') {
  //        selectedStrategyId = aiStrategySelected.strategy
  //      }
  //
  //      if (
  //        selectedStrategyId !== '' &&
  //        aiSettingsDataUpdated &&
  //        aiSettingsDataUpdated[selectedStrategyId]
  //      ) {
  //
  //        let strategyData = null
  //        if (aiSettingsDataUpdated[selectedStrategyId]) {
  //          strategyData = aiSettingsDataUpdated[selectedStrategyId]
  //        }
  //
  //        if (strategyData) {
  //          setStrategy(strategyData)
  //        }
  //      }
  //      setInit(false)
  //    }
  //  }, [init, aiStrategySelected])

  //  useEffect(() => {
  //    if (!init && aiStrategySelected) {
  //      // set menu
  //      // find aiSettingsDataUpdated
  //      let selectedStrategyId = ''
  //      if (aiStrategySelected.strategy && aiStrategySelected.strategy !== '') {
  //        selectedStrategyId = aiStrategySelected.strategy
  //      }
  //      if (
  //        selectedStrategyId !== ''
  //      ) {
  //        if (
  //          aiSettingsDataUpdated &&
  //          aiSettingsDataUpdated[selectedStrategyId]
  //        ) {
  //
  //          let strategyData = null
  //          if (aiSettingsDataUpdated[selectedStrategyId]) {
  //            strategyData = aiSettingsDataUpdated[selectedStrategyId]
  //          }
  //
  //          if (strategyData) {
  //            setStrategy(strategyData)
  //          }
  //        }
  //        else {
  //          setStrategy(null)
  //        }
  //      }
  //    }
  //  }, [aiStrategySelected])

  //  useEffect(() => {
  //    // triggered from AiSettingsPanel fetchAiSettingsData
  //    if (aiStrategySelectedRefresh === true) {
  //      // set menu
  //      // find aiSettingsDataUpdated
  //      let selectedStrategyId = ''
  //      if (aiStrategySelected.strategy && aiStrategySelected.strategy !== '') {
  //        selectedStrategyId = aiStrategySelected.strategy
  //      }
  //
  //      if (
  //        selectedStrategyId !== '' &&
  //        aiSettingsDataUpdated &&
  //        aiSettingsDataUpdated[selectedStrategyId]
  //      ) {
  //
  //        let strategyData = null
  //        if (aiSettingsDataUpdated[selectedStrategyId]) {
  //          strategyData = aiSettingsDataUpdated[selectedStrategyId]
  //        }
  //
  //        if (strategyData) {
  //          setStrategy(strategyData)
  //        }
  //      }
  //
  //      setAiStrategySelectedRefresh(false)
  //    }
  //  }, [aiStrategySelectedRefresh])

  // useEffect(() => {
  //   // compare strategy to aiSettingsDataUpdated[]
  //   if (!init) {
  //     let selectedStrategyId = ''
  //     if (aiStrategySelected.strategy && aiStrategySelected.strategy !== '') {
  //       selectedStrategyId = aiStrategySelected.strategy
  //     }
  //   }
  // }, [strategy])

  //  useEffect(() => {
  //    // compare strategy to aiSettingsDataUpdated[]
  //    if (!init) {
  //      if (aiSettingsDataUpdated && aiStrategySelected) {
  //      }
  //      // let selectedStrategyId = ''
  //      //  if (aiStrategySelected.strategy && aiStrategySelected.strategy !== '') {
  //      //    selectedStrategyId = aiStrategySelected.strategy
  //      //  }
  //    }
  //  }, [aiStrategySelected]);

  // PromptVersion tab change
  const handlePromptVersionChange = async (event, newValue) => {

    let returnData = {};
    try {
      let res = {}

      const selected = aiPromptVersionsDocs[newValue];

      const promptVersionSelected = {
        index: newValue,
        name: selected.name,
        promptversion: selected.aisettings.promptversion,
        aipromptversionid: selected.aipromptversionid,
        aiconversationid: selected.aiconversationid,
        aisettingsindexid: selected.aisettingsindexid,
      };
      // BT debugger
      if (aiSettingsData.promptversion === promptVersionSelected.promptversion) {
        // if selected prompt version matches current saved version then cancel edit mode
        setAiSettingsIsEditing({
          active: false,
          type: "",
        });
      } else {

        if (aiSettingsIsEditing.active === false) {
          setAiSettingsIsEditing({
            active: true,
            type: "promptVersionChange",
          });
        }
      }
      // BT debugger
      setAiPromptVersionSelected(promptVersionSelected);

    }
    catch (err) {
      if (debug()) debugger;
    }

    // OLD CODE
    // const selected = aiPromptVersionsDocs[newValue];

    // const promptVersionSelected = {
    //   index: newValue,
    //   name: selected.name,
    //   promptversion: selected.aisettings.promptversion,
    //   aipromptversionid: selected.aipromptversionid,
    //   aiconversationid: selected.aiconversationid,
    //   aisettingsindexid: selected.aisettingsindexid,
    // };
    // debugger
    // if (aiSettingsData.promptversion === promptVersionSelected.promptversion) {
    //   // if selected prompt version matches current saved version then cancel edit mode
    //   setAiSettingsIsEditing({
    //     active: false,
    //     type: "",
    //   });
    // } else {
    //   if (aiSettingsIsEditing.active === false) {
    //     setAiSettingsIsEditing({
    //       active: true,
    //       type: "promptVersionChange",
    //     });
    //   }
    // }
    // debugger
    // setAiPromptVersionSelectedX(promptVersionSelected);
  };

  const handleOnChangeCommitted = (modelType) => {
    // if (clog()) console.log('newValue:', newValue)
    // if (clog()) console.log('model:', model)
    // if (clog()) console.log('handleOnChangeCommitted:', aiSettingsDataUpdated)

    let key = "";
    if (modelType && modelType !== "") {
      key = modelType;
    }

    // if (key !== '') {
    //   // update aiSettingsDataUpdated model
    //   setAiSettingsDataUpdated({
    //     ...aiSettingsDataUpdated,
    //     [key]: model
    //   })
    // }
  };

  const handleQuestionChange = (event, key) => {
    const val = event.target.value;

    let dataOriginal = {};
    if (aiSettingsData && aiSettingsData[aiStrategySelected.strategy]) {
      dataOriginal = aiSettingsData[aiStrategySelected.strategy];
    }

    let data = {};
    if (aiSettingsDataUpdated && aiSettingsDataUpdated[aiStrategySelected.strategy]) {
      data = _.cloneDeep(aiSettingsDataUpdated[aiStrategySelected.strategy]);
    }
    data[key] = val;

    // compare data to dataOriginal
    const isEqual = _.isEqual(data, dataOriginal);
    if (isEqual === false) {
      // if different, set aiSettingsIsEditing
      setAiSettingsIsEditing({
        active: true,
        type: "model",
      });
    } else {
      // if same, set aiSettingsIsEditing
      setAiSettingsIsEditing({
        active: false,
        type: "",
      });
    }

    // if (clog()) console.log("handleQuestionChange val:", val);

    setAiSettingsDataUpdated({
      ...aiSettingsDataUpdated,
      [aiStrategySelected.strategy]: data,
    });
    // setStrategy({
    //   ...strategy,
    //   [key]: val
    // })
  };

  const isTextFieldDisabled = () => {
    let disabled = false;

    if (aiAgentsIsEditing.active || aiConversationsIsEditing.active) {
      disabled = true;
    }

    if (
      aiSettingsIsEditing.active &&
      aiSettingsIsEditing.type !== "add" &&
      aiSettingsIsEditing.active &&
      aiSettingsIsEditing.type !== "promptVersionNew"
    ) {
      disabled = true;
    }

    if (
      (aiSettingsIsEditing.active && aiSettingsIsEditing.type === "questions") ||
      (aiSettingsIsEditing.active && aiSettingsIsEditing.type === "strategy") ||
      (aiSettingsIsEditing.active && aiSettingsIsEditing.type === "model")
    ) {
      disabled = false;
    }

    // if (aiEngineersPanelHeightResizing) {
    //   disabled = true;
    // }

    return disabled;
  };

  // read only allows text to be read during settings add
  const isTextFieldReadOnly = () => {
    let readOnly = false;

    if (aiSettingsIsEditing.active && aiSettingsIsEditing.type === "add") {
      readOnly = true;
    }

    // if (aiEngineersPanelHeightResizing) {
    //   readOnly = true;
    // }

    return readOnly;
  };

  const handleOnChangeImprovePromptIterations = (type) => {
    if (type === "increase") {
      setImprovePromptIterations(improvePromptIterations + 1);
    } else if (type === "decrease") {
      setImprovePromptIterations(improvePromptIterations - 1);
    }
  };

  const isIterationsIncreaseDecreaseDisabled = (type) => {
    let disabled = false;

    let prompt = "";

    let settingsDataStrategy = null;
    if (aiSettingsDataUpdated && aiSettingsDataUpdated[aiStrategySelected.strategy]) {
      settingsDataStrategy = aiSettingsDataUpdated[aiStrategySelected.strategy];
    }

    if (aiStrategySelected.strategy === "chat") {
      if (settingsDataStrategy && settingsDataStrategy.prompt && settingsDataStrategy.prompt !== "") {
        prompt = settingsDataStrategy.prompt;
      }
    }

    if (prompt === "") {
      disabled = true;
    }

    if (type === "increase") {
      if (improvePromptIterations === 9) {
        disabled = true;
      }
    } else if (type === "decrease") {
      if (improvePromptIterations === 1) {
        disabled = true;
      }
    }

    if (aiAgentsIsEditing.active || aiConversationsIsEditing.active || aiSettingsIsEditing.active) {
      disabled = true;
    }

    return disabled;
  };

  const isIterationsTextDisabled = () => {
    let disabled = false;
    let prompt = "";

    let settingsDataStrategy = null;
    if (aiSettingsDataUpdated && aiSettingsDataUpdated[aiStrategySelected.strategy]) {
      settingsDataStrategy = aiSettingsDataUpdated[aiStrategySelected.strategy];
    }

    if (aiStrategySelected.strategy === "chat") {
      if (settingsDataStrategy && settingsDataStrategy.prompt && settingsDataStrategy.prompt !== "") {
        prompt = settingsDataStrategy.prompt;
      }
    }

    if (prompt === "") {
      disabled = true;
    }

    if (aiAgentsIsEditing.active || aiConversationsIsEditing.active || aiSettingsIsEditing.active) {
      disabled = true;
    }

    return disabled;
  };

  const isImprovePromptDisabled = () => {
    let disabled = false;
    let prompt = "";

    let settingsDataStrategy = null;
    if (aiSettingsDataUpdated && aiSettingsDataUpdated[aiStrategySelected.strategy]) {
      settingsDataStrategy = aiSettingsDataUpdated[aiStrategySelected.strategy];
    }

    if (aiStrategySelected.strategy === "chat") {
      if (settingsDataStrategy && settingsDataStrategy.prompt && settingsDataStrategy.prompt !== "") {
        prompt = settingsDataStrategy.prompt;
      }
    }

    if (prompt === "") {
      disabled = true;
    }

    if (aiAgentsIsEditing.active || aiConversationsIsEditing.active || aiSettingsIsEditing.active) {
      disabled = true;
    }

    return disabled;
  };

  const handleOnClickImprovePrompt = async () => {
    let settingsDataStrategy = null;
    if (aiSettingsDataUpdated && aiSettingsDataUpdated[aiStrategySelected.strategy]) {
      settingsDataStrategy = aiSettingsDataUpdated[aiStrategySelected.strategy];
    }

    setImprovePromptSaving(true);

    // activate edit mode
    setAiSettingsIsEditing({
      active: true,
      type: "improvePrompt",
    });

    if (settingsDataStrategy) {
      let promptVal = "";
      if (aiStrategySelected.strategy === "chat") {
        if (settingsDataStrategy.prompt && settingsDataStrategy.prompt !== "") {
          promptVal = settingsDataStrategy.prompt;
        }
      }

      let objectivesVal = "";
      let whatVal = "";
      let whoVal = "";

      if (aiStrategySelected.strategy === "question_chat") {
        if (settingsDataStrategy.objectives) {
          objectivesVal = settingsDataStrategy.objectives;
        }

        if (settingsDataStrategy.what) {
          whatVal = settingsDataStrategy.what;
        }

        if (settingsDataStrategy.who) {
          whoVal = settingsDataStrategy.who;
        }
      }

      if (
        (aiStrategySelected.strategy === "chat" && promptVal !== "") ||
        (aiStrategySelected.strategy === "chatwithknowledge" && promptVal !== "") ||
        (aiStrategySelected.strategy === "question_chat" && objectivesVal !== "" && whatVal !== "" && whoVal !== "")
      ) {
        // update aiconversation with improveprocessing true
        // const resultConversationPre = await updateAiConversationImproveProcessing(userData, aiConversationsSelected, "add");
        //if (resultConversationPre && resultConversationPre.data) {
        // improveprocessing flag added
        // call api to get improve prompt
        const resultImprovePrompt = await getAiQuestionsImprovePrompt(userData, aiConversationsSelected, improvePromptIterations);

        if (resultImprovePrompt && resultImprovePrompt.status && resultImprovePrompt.status === 200) {
          // improve prompt success status returned
          // update aiconversation with improveprocessing flag
          const resultConversationPre = await updateAiConversationImproveProcessing(userData, aiConversationsSelected, "add");
          if (resultConversationPre && resultConversationPre.data) {
          }
        } else {
          // something went wrong...
          // update aiconversation with improveprocessing false
          // const resultConversationPost = await updateAiConversationImproveProcessing(userData, aiConversationsSelected, "remove");
          // if (resultConversationPost && resultConversationPost.data) {
          //   // improveprocessing flag removed
          // } else {
          //   // improveprocessing flag remove failed
          //   if (debug()) debugger;
          // }
          setImprovePromptSaving(false);

          setAiSettingsIsEditing({
            active: false,
            type: "",
          });
        }
        //}
      }
      // backup of above
      // if (
      //   (aiStrategySelected.strategy === "chat" && promptVal !== "") ||
      //   (aiStrategySelected.strategy === "question_chat" && objectivesVal !== "" && whatVal !== "" && whoVal !== "")
      // ) {
      //   // update aiconversation with improveprocessing true
      //   debugger
      //   const resultConversationPre = await updateAiConversationImproveProcessing(userData, aiConversationsSelected, "add");
      //   if (resultConversationPre && resultConversationPre.data) {
      //     // improveprocessing flag added
      //     // call api to get improve prompt
      //     const resultImprovePrompt = await getAiQuestionsImprovePrompt(userData, aiConversationsSelected, improvePromptIterations);

      //     if (resultImprovePrompt && resultImprovePrompt.data) {
      //     } else {
      //       // something went wrong...
      //       // update aiconversation with improveprocessing false
      //       const resultConversationPost = await updateAiConversationImproveProcessing(userData, aiConversationsSelected, "remove");
      //       if (resultConversationPost && resultConversationPost.data) {
      //         // improveprocessing flag removed
      //       } else {
      //         // improveprocessing flag remove failed
      //         if (debug()) debugger;
      //       }

      //       setAiSettingsIsEditing({
      //         active: false,
      //         type: "",
      //       });
      //     }
      //   }
      // }

      if (clog()) console.log("handleOnClickImprovePrompt");
    }
    // setImprovePromptSaving(false);

    // deactivate edit mode
    // setAiSettingsIsEditing({
    //   active: false,
    //   type: "",
    // });
  };

  const handleOnClickAddPromptVersion = async (type) => {
    let newSettingsData = null;

    debugger
    // get current prompt version count
    const aiConversationId = aiConversation.aiconversationid;
    const promptVersionCount = aiPromptVersionsDocs.length;
    const newPromptVersion = promptVersionCount + 1;
    const name = newPromptVersion + "";

    // add new prompt version
    const newPromptVersionItem = {
      index: promptVersionCount,
      name: name,
      promptversion: newPromptVersion,
      aipromptversionid: "",
      aiconversationid: aiConversationId,
    };

    debugger
    if (type === "new") {
      // get default data
      const returnData = getDefaultAiPreset(userData);
      if (returnData && returnData.data) {
        debugger

        let settingsData = {};
        if (returnData.data && returnData.data.aisettings && returnData.data.aisettings[0]) {
          settingsData = returnData.data.aisettings[0];
        }
        // process data for aisettings
        // newSettingsData = processAddPromptVersionAiSettingsData(type, userData, returnData.data, newPromptVersion);
        newSettingsData = processAddPromptVersionAiSettingsData(type, userData, settingsData, newPromptVersion, aiSettingsDataUpdated);
      }
    }

    if (type === "duplicate") {
      debugger
      // process data for aisettings
      newSettingsData = processAddPromptVersionAiSettingsData(type, userData, aiSettingsDataUpdated, newPromptVersion, aiSettingsDataUpdated);
    }

    if (newSettingsData) {
      // setAiPromptVersionNewSaving(type);

      setAiSettingsIsEditing({
        active: true,
        type: "promptVersionNew",
      });
      debugger
      setAiSettingsDataUpdated(newSettingsData);
      setAiPromptVersionSelected(newPromptVersionItem);
      setAiPromptVersionAdded({
        index: promptVersionCount,
        promptversion: newPromptVersion,
      });

      // trigger useEffect in AiModelPanel
      setAiModelSelectedRefresh(true);
      // trigger useEffect in AiQuestionsPanel
      setAiStrategySelectedRefresh(true);

    }
  };

  const isPromptVersionTabDisabled = () => {
    let disabled = false;

    if (aiAgentsIsEditing.active || aiConversationsIsEditing.active) {
      disabled = true;
    }
    if (aiSettingsIsEditing.active && aiSettingsIsEditing.type !== "promptVersionChange") {
      disabled = true;
    }

    return disabled;
  };

  const isPromptVersionAddDisabled = () => {
    let disabled = false;

    if (aiAgentsIsEditing.active || aiConversationsIsEditing.active || aiSettingsIsEditing.active) {
      disabled = true;
    }

    return disabled;
  };

  const showAiLinkSettingsLink = (promptVersionDoc) => {
    // if (conversation && conversation.ailinksettingid && conversation.ailinksettingid !== "") {
    // const test = aiAgentsSelected
    // const test1 = aiConversationsSelected
    // const test2 = promptVersionDoc
    let promptVersionDocAiSettingsIndexId = ""
    if (promptVersionDoc.aisettingsindexid && promptVersionDoc.aisettingsindexid !== "") {
      promptVersionDocAiSettingsIndexId = promptVersionDoc.aisettingsindexid
    }
    // const promptVersionDocAiSettingsIndexId = promptVersionDoc.aisettingsindexid

    let promptVersionDocPromptVersion = ""
    if (promptVersionDoc.aisettings && promptVersionDoc.aisettings.promptversion && promptVersionDoc.aisettings.promptversion !== "") {
      promptVersionDocPromptVersion = promptVersionDoc.aisettings.promptversion
    }
    // const promptVersionDocPromptVersion = promptVersionDoc.aisettings.promptversion

    const aiConversationsSelectedAiSettings = aiConversationsSelected.aisettings

    let isAiLinkSettingsLink = false;
    // loop over aiConversationsSelectedAiSettings
    if (promptVersionDocPromptVersion !== '' && aiConversationsSelectedAiSettings && aiConversationsSelectedAiSettings.length > 0) {
      aiConversationsSelectedAiSettings.forEach((item) => {
        if (item.aisettingsindexid === promptVersionDocAiSettingsIndexId && item.promptversion === promptVersionDocPromptVersion) {
          isAiLinkSettingsLink = true;
        }
      });
    }
    // debugger
    if (
      isAiLinkSettingsLink === true &&
      aiAgentsSelected.aisettings[0].automationlink !== undefined &&
      aiAgentsSelected.aisettings[0].automationlink !== "" &&
      aiConversationsSelected.ailinksettingid &&
      aiConversationsSelected.ailinksettingid !== "" &&
      aiConversationsSelected.ailinksettingid === aiAgentsSelected.aisettings[0].ailinksettingid
    ) {
      //
      return (
        <LinkIcon
          // color="primary"
          sx={{
            color: "primary.main",
            // color: linkIconActiveDisabled(),
            fontSize: 12,
            "&.MuiTab-iconWrapper": {
              // mt: 0,
              mb: 0,
            },
          }}
        />
      );
    }
    else if (
      isAiLinkSettingsLink === true &&
      aiAgentsSelected.aisettings[0].automationlink !== undefined &&
      aiAgentsSelected.aisettings[0].automationlink === "" &&
      aiConversationsSelected.ailinksettingid &&
      aiConversationsSelected.ailinksettingid !== "" &&
      aiConversationsSelected.ailinksettingid === aiAgentsSelected.aisettings[0].ailinksettingid
    ) {
      //
      return (
        <LinkOffIcon
          // color="primary"
          sx={{
            color: "error.main",
            // color: linkIconUnassignedDisabled(),
            fontSize: 12,
            "&.MuiTab-iconWrapper": {
              // mt: 0,
              mb: 0,
            },
          }}
        />
      );
    }
    else {
      return (
        <LinkIcon
          sx={{
            color: "#ffffff",
            fontSize: 12,
            "&.MuiTab-iconWrapper": {
              // mt: 0,
              mb: 0,
            },
          }}
        />
      );
    }
  };

  const showPromptVersionControls = () => {
    if (aiPromptVersionsDocs && aiPromptVersionsDocs.length > 0) {

      return (
        <Box
          name={"promptVersionControls"}
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            // alignItems: "flex-start",
            // alignItems: "flex-end",
            justifyContent: "space-between",
            // pb: 1,
            backgroundColor: theme.palette.background.paper,
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: theme.palette.border.normal,
            borderRadius: "4px",
            mb: 2,
            // py: 2,
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              // maxWidth: "calc(100% - 98px)",
              maxWidth: "calc(100% - 98px)",
              // width: "200px",
              // maxWidth: "200px",
              // maxWidth: {
              //   xs: "100%",
              //   // xs: 320,
              //   // sm: 480,
              // },
              //  bgcolor: "#cc0000",
            }}
          >
            <Tabs
              value={aiPromptVersionSelected.index}
              onChange={handlePromptVersionChange}
              variant="scrollable"
              // scrollButtons="auto"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="Prompt Versions"
              disabled={isPromptVersionTabDisabled()}
              // sx={{
              //   minHeight: "30px",
              //   height: "30px",
              //   "& .MuiButtonBase-root": {
              //     // minWidth: 0,
              //     // maxWidth: '100%',
              //     minWidth: "40px",
              //     maxWidth: "40px",
              //     width: "40px",
              //     minHeight: "30px",
              //     height: "30px",
              //     px: 1,
              //     py: 0,
              //   },
              //   [`& .${tabsClasses.scrollButtons}`]: {
              //     "&.Mui-disabled": { opacity: 0.3 },
              //   },
              // }}
              sx={{
                minHeight: "40px",
                height: "40px",
                "& .MuiButtonBase-root": {
                  minWidth: "40px",
                  maxWidth: "40px",
                  width: "40px",
                  minHeight: "40px",
                  height: "40px",
                  px: 1,
                  py: 0,
                  justifyContent: "flex-start",
                },
                [`& .${tabsClasses.scrollButtons}`]: {
                  "&.Mui-disabled": { opacity: 0.3 },
                },
                "& .MuiTabs-scrollButtons > .MuiSvgIcon-root": {
                  // mt: '-10px',
                  // mt: '13px',
                  mt: "2px",
                }
              }}
            >
              {
                // // loop 30 times
                // [...Array(30)].map((e, i) => (
                //   <Tab key={"i" + i} label={`${i + 1}`} />
                // ))
              }

              {
                // loop over aiPromptVersionsDocs
                aiPromptVersionsDocs.map((doc, i) => {

                  // return <></>;
                  return <Tab
                    key={"i" + i}
                    name={doc.aipromptversionid}
                    icon={showAiLinkSettingsLink(doc)}
                    iconPosition="top"
                    // iconPosition="bottom"
                    label={doc.name}
                    disabled={isPromptVersionTabDisabled()}
                  />;
                })
              }

              {
                // if aiPromptVersionSelected.index is equal to count then a new prompt version was added
                // aiConversation && aiConversation.promptversioncount && aiPromptVersionSelected.index === aiConversation.promptversioncount && (
                aiPromptVersionSelected.index === aiPromptVersionsDocs.length && (
                  <Tab
                    key={"i" + aiPromptVersionSelected.index}
                    name={aiPromptVersionSelected.aipromptversionid}
                    icon={showAiLinkSettingsLink(aiPromptVersionSelected)}
                    iconPosition="top"
                    // iconPosition="bottom"
                    label={aiPromptVersionSelected.name}
                    disabled={isPromptVersionTabDisabled()}
                  />
                )
              }
            </Tabs>
          </Box>

          {aiPromptVersionAddAutomationLinkSaving ? (
            <Box
              sx={{
                // mt: '-2px',
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "30px",
                height: "30px",
              }}
            >
              <CircularProgress
                size={12}
                sx={{
                  p: 0,
                  m: 0,
                }}
              // variant="determinate"
              />
            </Box>
          ) : (
            <Box
              sx={{
                // mt: '-2px',
              }}
            >
              <IconButton
                aria-label="Add Automation Link"
                color="primary"
                size="small"
                sx={{
                  "&.MuiIconButton-sizeSmall": {
                    fontSize: "16px",
                    borderRadius: "0px",
                  },
                }}
                onClick={() => handleOnClickAutomationLink()}
                disabled={isPromptVersionAddAutomationLinkDisabled()}
              >
                <LinkIcon fontSize="inherit" />
              </IconButton>
            </Box>
          )}

          {aiPromptVersionNewSaving === "new" ? (
            <Box
              sx={{
                // mt: '-2px',
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "30px",
                height: "30px",
              }}
            >
              <CircularProgress
                size={12}
                sx={{
                  p: 0,
                  m: 0,
                }}
              // variant="determinate"
              />
            </Box>
          ) : (
            <Box
              sx={{
                // mt: '-2px',
              }}
            >
              <IconButton
                aria-label="Add"
                color="primary"
                size="small"
                sx={{
                  "&.MuiIconButton-sizeSmall": {
                    fontSize: "16px",
                    borderRadius: "0px",
                  },
                }}
                onClick={() => handleOnClickAddPromptVersion("new")}
                disabled={isPromptVersionAddDisabled()}
              >
                <AddIcon fontSize="inherit" />
              </IconButton>
            </Box>
          )}

          {aiPromptVersionNewSaving === "duplicate" ? (
            <Box
              sx={{
                // mt: '-2px',
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "30px",
                height: "30px",
                mr: 1,
              }}
            >
              <CircularProgress
                size={12}
                sx={{
                  p: 0,
                  m: 0,
                }}
              // variant="determinate"
              />
            </Box>
          ) : (
            <Box
              sx={{
                mr: 1,
                // mt: '-2px',
              }}
            >
              <IconButton
                aria-label="Duplicate"
                color="primary"
                size="small"
                sx={{
                  "&.MuiIconButton-sizeSmall": {
                    fontSize: "13px",
                    borderRadius: "0px",
                  },
                }}
                onClick={() => handleOnClickAddPromptVersion("duplicate")}
                disabled={isPromptVersionAddDisabled()}
              >
                <FileCopyIcon fontSize="inherit" />
              </IconButton>
            </Box>
          )}

          {
            // <Box
            //   sx={{
            //     mr: 1,
            //   }}
            // >
            //   <IconButton
            //     aria-label="Delete"
            //     color="primary"
            //     size="small"
            //     sx={{
            //       "&.MuiIconButton-sizeSmall": {
            //         fontSize: "16px",
            //         borderRadius: "0px",
            //       },
            //     }}
            //     disabled={isPromptVersionAddDisabled()}
            //   >
            //     <DeleteIcon fontSize="inherit" />
            //   </IconButton>
            // </Box>
          }
        </Box>
      );
    } else {
      return <></>;
    }
  };


  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar({
      open: false,
      message: "",
    });
  };

  const agentInputSnackbar = () => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={openSnackbar.open}
        onClose={() => handleSnackbarClose()}
        autoHideDuration={4000}
      // message="Copied to clipboard"
      // disabled
      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: "100%" }} variant="filled">
          {openSnackbar.message}
        </Alert>
      </Snackbar>

    )
  }

  // copy automation link to clipboard
  const handleInputChipClick = (input) => {
    let shortname = input?.shortname || "";

    if (shortname !== "") {

      const shortnameFormatted = '{' + shortname + '}';
      setOpenSnackbar({
        open: true,
        message: `Copied "${shortname}" to Clipboard`,
      });

      navigator.clipboard.writeText(shortnameFormatted);
    }
  };


  const showAgentInputTooltip = (input) => {

    // let inputValue = ''
    // if (input.value && input.value !== '') {
    //   inputValue = input.value
    // }

    let inputShortName = ''
    if (input.shortname && input.shortname !== '') {
      inputShortName = input.shortname
    }

    let aiMessagesDocState = {}
    // check aimessages doc state exists
    if (aiMessagesDoc && aiMessagesDoc.state) {
      aiMessagesDocState = aiMessagesDoc.state
    }

    // check if input.value exists in aiMessagesDoc
    let variableInMessage = ''
    if (aiMessagesDocState && aiMessagesDocState[inputShortName]) {
      const variableData = aiMessagesDocState[inputShortName]
      variableInMessage = variableData
    }
    // testing code
    // const iMessagesVariables = {
    //   'WHO': 'Custom value found in messages data',
    // }
    // if (iMessagesVariables && iMessagesVariables[inputShortName]) {
    //   const variableData = iMessagesVariables[inputShortName]
    //   variableInMessage = variableData
    // }


    return (
      <Box>

        <Typography
          sx={{
            fontSize: 12,
            fontWeight: 600,
            color: theme.palette.primary.main,
          }}
        >
          Default:
        </Typography>
        <Typography>{input.value}</Typography>
        {
          variableInMessage !== '' && (
            <Box
              sx={{
                mt: 1,
              }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  fontWeight: 600,
                  color: theme.palette.primary.main,
                }}
              >
                Message:
              </Typography>
              <Typography>{variableInMessage}</Typography>
            </Box>
          )

        }
      </Box>
    )

  }

  const highlightAgentInputBorder = (input, type) => {

    const test = 2

    let inputShortName = ''
    if (input.shortname && input.shortname !== '') {
      inputShortName = input.shortname
    }


    let aiMessagesDocState = {}
    // check aimessages doc state exists
    if (aiMessagesDoc && aiMessagesDoc.state) {
      aiMessagesDocState = aiMessagesDoc.state
    }

    // check if input.value exists in aiMessagesDocState
    let variableInMessage = ''
    if (aiMessagesDocState && aiMessagesDocState[inputShortName]) {
      const variableData = aiMessagesDocState[inputShortName]
      variableInMessage = variableData
    }


    // check if input.shortname exists in aiAgentsSelected.inputs
    let aiAgentInputs = [];
    if (aiAgentsSelected && aiAgentsSelected.inputs) {
      aiAgentInputs = aiAgentsSelected.inputs;
    }

    let variableHasValue = false
    // loop over agentInputs
    if (aiAgentInputs.length > 0) {
      // loop over aiAgentInputs
      aiAgentInputs.forEach((item) => {
        // check if input.shortname does not exist in aiAgentInputs item
        if (item.shortname === inputShortName && item.value !== '') {
          variableHasValue = true
        }
      })
    }

    // // (SAVEIN=50_LEADS)
    // // search aiconversation.aisettings - selected prompt for input.shortname
    // let settingsData = [];
    // // if (aiConversationsSelected && aiConversationsSelected.aisettings) {
    // if (aiConversation && aiConversation.aisettings) {
    //   settingsData = aiConversation.aisettings;
    // }
    // debugger
    // if (settingsData.length > 0) {
    //     // loop over settingsData
    //     settingsData.forEach((item) => {
    //       // get selected stategy from item.data
    //       let strategySelected = '';
    //       if (item.data && item.data.strategy && item.data.strategy !== '') {
    //         strategySelected = item.data.strategy;
    //       }

    //       if (strategySelected !== '') {
    //         const strategyData = item[strategySelected];
    //         // loop over startegyData object
    //         for (const [key, value] of Object.entries(strategyData)) {
    //           const strategyDataItem = strategyData[key];
    //           debugger

    //         }

    //       }
    //     })
    // }


    if ('normal' === type) {
      if (variableHasValue === false) {
        return theme.palette.error.main + " !important";
      }
      else if (variableInMessage !== '') {
        return theme.palette.success.main + " !important";
      }
      else {
        return theme.palette.grey[300] + " !important";
      }
    }
    else if ('hover' === type) {
      if (variableHasValue === false) {
        return theme.palette.error.main + " !important";
      }
      else if (variableInMessage !== '') {
        return theme.palette.success.main + " !important";
      }
      else {
        return theme.palette.primary.main + " !important";
      }
    }

  }

  const showAgentInputControls = () => {
    if (aiAgentsSelected?.inputs?.length > 0) {
      return (
        <Box
          name={"agentInputControls"}
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            flexWrap: "wrap",
            // justifyContent: "flex-end",
            // justifyContent: "space-between",

            // pb: 1,
            // backgroundColor: theme.palette.background.paper,
            // borderStyle: "solid",
            // borderWidth: 1,
            // borderColor: theme.palette.border.normal,
            // borderRadius: "4px",
            mb: 1,
          }}
        >

          { // loop over inputs

            aiAgentsSelected.inputs.map((input, i) => {
              // console.log('input:', input)
              return (
                <Box
                  key={'input' + i}
                  sx={{
                    mr: 1,
                    mb: 1,
                    // width: "100px",
                    // height: "40px",
                    // backgroundColor: "#cc0000",
                  }}
                >


                  <BorderTooltip
                    title={
                      <Box>
                        {showAgentInputTooltip(input)}
                      </Box>
                    }
                    placement="bottom"
                    arrow
                    leaveDelay={100}
                  >
                    <Grow in={true}>
                      {
                        // <Box
                        //   sx={{
                        //     animation: "blink 1s infinite",
                        //     "@keyframes blink": {
                        //       from: {
                        //         borderColor: "red"
                        //       },
                        //        to: {
                        //         borderColor: "green"
                        //       },
                        //       "0%": {
                        //         borderColor: "red"
                        //       },
                        //       "50%": {
                        //         borderColor: "green"
                        //       },
                        //       "100%": {
                        //         borderColor: "red"
                        //       }
                        //     },
                        //     borderRadius: "4px",
                        //     borderWidth: '2px',
                        //     borderStyle: "solid",
                        //   }}
                        // >
                      }


                      <Chip
                        label={input.shortname}
                        // clickable
                        variant="outlined"
                        // color="primary"
                        onClick={() => handleInputChipClick(input)}
                        sx={{
                          // "@keyframes blink": {
                          //   "0%": {
                          //     borderColor: "red !important"
                          //   },
                          //   "50%": {
                          //     borderColor: "green !important"
                          //   },
                          //   "100%": {
                          //     borderColor: "red !important"
                          //   }
                          // },
                          // animation: "blink 1s infinite !important",

                          backgroundColor: theme.palette.background.paper,
                          borderColor: highlightAgentInputBorder(input, 'normal'),
                          // borderColor: 'transparent',
                          "&:hover": {
                            // color: theme.palette.primary.contrastText,
                            // backgroundColor: theme.palette.primary.main + " !important",
                            backgroundColor: theme.palette.background.paper + " !important",
                            borderColor: highlightAgentInputBorder(input, 'hover'),
                          },
                        }}
                      />

                      {
                        // </Box>
                      }
                    </Grow>
                  </BorderTooltip>



                </Box>
              )
            })

          }

        </Box >
      );
    } else {
      return <></>;
    }
  };


  const showQuestionControls = () => {
    return (
      <Box
        name={"questionControls"}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          // justifyContent: "space-between",
          // pb: 1,
          borderStyle: "solid",
          borderWidth: 1,
          borderColor: theme.palette.border.normal,
          borderRadius: "4px",
          mb: 2,
          // py: 2,
          backgroundColor: theme.palette.background.paper,
        }}
      >
        {improvePromptSaving ? (
          <Box
            sx={{
              // width: "100%",
              flexGrow: 1,
              pl: 2,
              pr: 2,
            }}
          >
            <LinearProgress variant="determinate" value={improvePromptProgress} />
          </Box>
        ) : (
          <></>
        )}

        <Box
          name="questionControlsRight"
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box
            // width={80}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // backgroundColor: "#cc0000",
              // mt: 4,
              mr: 1,
            }}
          >
            <Typography
              sx={{
                color: isIterationsTextDisabled() ? "grey.400" : "grey.800",
              }}
            >
              Iterations:
            </Typography>
          </Box>

          <Box
            // width={80}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // backgroundColor: "#cc0000",
              // mt: 4,
            }}
          >
            <Typography
              sx={{
                color: isIterationsTextDisabled() ? "grey.400" : "grey.800",
              }}
            >
              {improvePromptIterations}
            </Typography>
          </Box>

          <Box>
            <IconButton
              aria-label="IterationsDecrease"
              color="primary"
              size="small"
              sx={{
                "&.MuiIconButton-sizeSmall": {
                  fontSize: "16px",
                  borderRadius: "0px",
                },
              }}
              onClick={() => handleOnChangeImprovePromptIterations("decrease")}
              disabled={isIterationsIncreaseDecreaseDisabled("decrease")}
            >
              <ArrowLeftIcon fontSize="inherit" />
            </IconButton>
          </Box>

          <Box>
            <IconButton
              aria-label="IterationsIncrease"
              color="primary"
              size="small"
              sx={{
                "&.MuiIconButton-sizeSmall": {
                  fontSize: "16px",
                  borderRadius: "0px",
                },
              }}
              onClick={() => handleOnChangeImprovePromptIterations("increase")}
              disabled={isIterationsIncreaseDecreaseDisabled("increase")}
            >
              <ArrowRightIcon fontSize="inherit" />
            </IconButton>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // backgroundColor: "#cc0000",
              px: 1,
              // ml: 1,
            }}
          >
            <Box name="improvePrompt">
              <IconButton
                aria-label="Edit"
                color="primary"
                size="small"
                sx={{
                  "&.MuiIconButton-sizeSmall": {
                    fontSize: "13px",
                    borderRadius: "0px",
                  },
                }}
                onClick={handleOnClickImprovePrompt}
                disabled={isImprovePromptDisabled()}
              >
                <FileCopyIcon fontSize="inherit" />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };



  const isPromptVersionAddAutomationLinkDisabled = () => {
    let disabled = false;



    // check if prompt version is linked to ai settings
    const aiAgentAutomationLink = aiAgentsSelected.aisettings[0].automationlink
    const aiAgentLinkAiSettingId = aiAgentsSelected.aisettings[0].ailinksettingid

    let promptVersionDocAiSettingsIndexId = ""
    if (aiPromptVersionSelected.aisettingsindexid && aiPromptVersionSelected.aisettingsindexid !== "") {
      promptVersionDocAiSettingsIndexId = aiPromptVersionSelected.aisettingsindexid
    }
    // const promptVersionDocAiSettingsIndexId = aiPromptVersionSelected.aisettingsindexid

    let promptVersionDocPromptVersion = ""
    if (aiPromptVersionSelected.promptversion && aiPromptVersionSelected.promptversion !== "") {
      promptVersionDocPromptVersion = aiPromptVersionSelected.promptversion
    }
    // const promptVersionDocPromptVersion = aiPromptVersionSelected.promptversion

    const aiConversationsSelectedAiSettings = aiConversationsSelected.aisettings

    let isAiLinkSettingsLink = false;
    // loop over aiConversationsSelectedAiSettings
    if (promptVersionDocPromptVersion !== '' && aiConversationsSelectedAiSettings && aiConversationsSelectedAiSettings.length > 0) {
      aiConversationsSelectedAiSettings.forEach((item) => {
        if (item.aisettingsindexid === promptVersionDocAiSettingsIndexId && item.promptversion === promptVersionDocPromptVersion) {
          isAiLinkSettingsLink = true;
        }
      });
    }

    // debugger
    if (
      isAiLinkSettingsLink === true &&
      aiAgentsSelected.aisettings[0].automationlink !== undefined &&
      aiAgentsSelected.aisettings[0].automationlink !== "" &&
      aiConversationsSelected.ailinksettingid &&
      aiConversationsSelected.ailinksettingid !== "" &&
      aiConversationsSelected.ailinksettingid === aiAgentsSelected.aisettings[0].ailinksettingid
    ) {
      disabled = true;
    }
    else if (
      isAiLinkSettingsLink === true &&
      aiAgentsSelected.aisettings[0].automationlink !== undefined &&
      aiAgentsSelected.aisettings[0].automationlink === "" &&
      aiConversationsSelected.ailinksettingid &&
      aiConversationsSelected.ailinksettingid !== "" &&
      aiConversationsSelected.ailinksettingid === aiAgentsSelected.aisettings[0].ailinksettingid
    ) {
      disabled = true;
    }




    if (aiAgentsSelected === null) {
      disabled = true;
    }

    if (aiSettingsSelected === null) {
      disabled = true;
    }

    if (aiConversation === null) {
      disabled = true;
    }

    // if (
    //   aiAgentsSelected !== null &&
    //   aiSettingsSelected !== null &&
    //   aiConversation !== null &&
    //   // aiConversation !== undefined &&
    //   // aiConversation.ailinksettingid &&
    //   // aiConversation.ailinksettingid !== ""
    //   aiConversation.automationlink &&
    //   aiConversation.automationlink !== ""
    // ) {
    //   disabled = true;
    // }

    if (aiAgentsIsEditing.active === true) {
      disabled = true;
    }

    if (aiSettingsIsEditing.active === true) {
      disabled = true;
    }

    if (aiConversationsIsEditing.active === true) {
      disabled = true;
    }

    return disabled;
  };

  const handleOnClickAutomationLinkConfirm = async () => {
    if (clog()) console.log("handleOnClickAutomationLink");
    // setAiSettingsName(name)
    setAiPromptVersionAddAutomationLinkSaving(true);

    const result = await saveAiSettingsAutomationLink(userData);

    if (result.data) {
      const resultData = result.data

      let isCurrentLinkSettings = false;
      if (resultData.iscurrentlinksettings) {
        isCurrentLinkSettings = true;
      }

      let newAiSettingsDataArray = [];
      if (resultData.aisettingsdataarray) {
        newAiSettingsDataArray = resultData.aisettingsdataarray;
      }

      let selectedIndex = -1;
      if (resultData.aisettingsindex !== undefined && resultData.aisettingsindex > -1) {
        selectedIndex = resultData.aisettingsindex;
      }

      let newAiSettingsDataItem = {};
      if (newAiSettingsDataArray && newAiSettingsDataArray[selectedIndex]) {
        newAiSettingsDataItem = newAiSettingsDataArray[selectedIndex];
      }

      if (clog()) console.log("handleOnClickAutomationLinkConfirm result:", resultData);

      // stop useffect in AiSettingsPanel from refetching aiconversation
      aiSettingsSelectedRefresh.current = false;

      if (!isCurrentLinkSettings) {
        // update aiSettingsSelected with new ailinksettingid
        const newAiSettingsSelected = _.cloneDeep(aiSettingsSelected);
        newAiSettingsSelected.ailinksettingid = resultData.ailinksettingid;
        setAiSettingsSelected(newAiSettingsSelected)
      }


      // update aiSettingsData
      // const newAiSettingsData = _.cloneDeep(aiSettingsData);
      setAiSettingsData(newAiSettingsDataItem)

      // update aiSettingsDataUpdated
      // const newAiSettingsDataUpdated = _.cloneDeep(aiSettingsDataUpdated);
      setAiSettingsDataUpdated(newAiSettingsDataItem)


      // update aiConversationsSelected aisettings
      setAiConversationsSelected({
        ...aiConversationsSelected,
        aisettings: newAiSettingsDataArray,

      })


      if (!isCurrentLinkSettings) {
        // update aiAgentsSelected with new ailinksettingid
        const newAiAgentsSelected = _.cloneDeep(aiAgentsSelected);
        newAiAgentsSelected.aisettings[0].ailinksettingid = resultData.ailinksettingid;
        setAiAgentsSelected(newAiAgentsSelected)
      }

      // if (clog()) console.log("handleOnClickAutomationLink result", result.data);
    } else {
      if (clog()) console.log("handleOnClickAutomationLinkConfirm error:", result.error);
    }
    setAiPromptVersionAddAutomationLinkSaving(false);

    // deactivate edit mode
    setAiConversationsIsEditing({
      active: false,
      type: "",
    });
  };

  const handleOnClickAutomationLink = async () => {
    //
    debugger
    if (aiConversationsIsEditing.active === false) {
      // activate edit mode
      setAiConversationsIsEditing({
        active: true,
        type: "link",
      });
    } else {
      // deactivate edit mode
      setAiConversationsIsEditing({
        active: false,
        type: "",
      });
    }
  };

  const handleAutomationLinkDialogClose = () => {
    setAiConversationsIsEditing({
      active: false,
      type: "",
    });
  };

  const showAutomationLinkDialog = () => {
    let open = false;
    if ((aiConversationsIsEditing.active && aiConversationsIsEditing.type === "link")) {
      open = true;
    }

    let title = "Connect Automation";

    return (
      <Dialog
        open={open}
        onClose={handleAutomationLinkDialogClose}
        sx={{
          "& .MuiDialog-paper": {
            position: "absolute",
            top: "10px",
            // left: "50%",
            // transform: "translate(-50%, -50%)",
          },
          // position: "absolute",
          // top: "0px",
          // alignItems: "flex-start",
        }}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent
          dividers={true}
        >
          {
            <DialogContentText>
              Are you sure you want to connect this prompt to the automation link?
            </DialogContentText>
          }

        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",

              mr: 1,
            }}
          >
            <Button
              aria-label="Cancel"
              color="primary"
              variant="outlined"
              // size="small"
              // variant="light"
              sx={{
                mr: 1,
              }}
              onClick={handleOnClickAutomationLink}
            // disabled={isSendDisabled()}
            >
              Cancel
            </Button>

            {aiPromptVersionAddAutomationLinkSaving ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "64px",
                  height: "36.5px",
                }}
              >
                <CircularProgress
                  size={12}
                  sx={{
                    p: 0,
                    m: 0,
                  }}
                // variant="determinate"
                />
              </Box>
            ) : (
              <Button
                aria-label="Save"
                color="primary"
                variant="contained"
                // size="small"
                // variant="light"
                onClick={() => handleOnClickAutomationLinkConfirm()}
              // disabled={isAiAgentsNameSaveDisabled()}
              >
                Save
              </Button>
            )}
          </Box>
        </DialogActions>
      </Dialog>
    );
  };


  // const showMinRowsBasedOnHeight = () => {
  //   let minRows = 1;
  //   const extra = 96;
  //   const vInputPadding = 33;
  //   const lineHeight = 20;

  //   // remove input padding and components sizes above input
  //   const spaceRemaining = questionsHeight - (extra + vInputPadding);

  //   const lines = Math.floor(spaceRemaining / lineHeight);

  //   if (clog()) console.log("questionsHeight:", questionsHeight);
  //   if (clog()) console.log("lines:", lines);

  //   if (lines > 1) {
  //     minRows = lines;
  //   } else {
  //     minRows = 1;
  //   }

  //   return minRows;
  // };


  if (aiAgentsIsRemoving || aiConversationsIsRemoving) {
    return <LoadingSubPane spin={true} />;
  }

  if (
    aiSettingsDataUpdated &&
    aiStrategySelected &&
    aiStrategySelected.strategy &&
    aiStrategySelected.strategy !== ""
    // aiSettingsDataUpdated[aiStrategySelected.strategy]
  ) {
    if (aiStrategySelected.strategy === "question_chat") {
      let objectivesVal = "";
      if (
        aiSettingsDataUpdated &&
        aiSettingsDataUpdated[aiStrategySelected.strategy] &&
        aiSettingsDataUpdated[aiStrategySelected.strategy].objectives
      ) {
        objectivesVal = aiSettingsDataUpdated[aiStrategySelected.strategy].objectives;
      }

      let whatVal = "";
      if (aiSettingsDataUpdated && aiSettingsDataUpdated[aiStrategySelected.strategy] && aiSettingsDataUpdated[aiStrategySelected.strategy].what) {
        whatVal = aiSettingsDataUpdated[aiStrategySelected.strategy].what;
      }

      let whoVal = "";
      if (aiSettingsDataUpdated && aiSettingsDataUpdated[aiStrategySelected.strategy] && aiSettingsDataUpdated[aiStrategySelected.strategy].who) {
        whoVal = aiSettingsDataUpdated[aiStrategySelected.strategy].who;
      }

      return (
        <Box
          sx={{
            maxHeight: "100%",
            // overflow: 'auto',
          }}
        >
          {showAutomationLinkDialog()}
          {agentInputSnackbar()}
          {showPromptVersionControls()}
          {showAgentInputControls()}

          {
            // showQuestionControls()
          }

          <TextField
            id="objectives"
            sx={{
              backgroundColor: theme.palette.background.paper,
              // mt: 2,
              "& .MuiOutlinedInput-input": {
                fontSize: "15px",
              },
            }}
            fullWidth
            label="Objectives"
            multiline
            // rows={5}
            value={objectivesVal}
            onChange={(event) => handleQuestionChange(event, "objectives")}
            disabled={isTextFieldDisabled()}
            InputProps={{
              readOnly: isTextFieldReadOnly(),
            }}
          // defaultValue="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text"
          />

          <TextField
            id="what"
            sx={{
              mt: 4,
              backgroundColor: theme.palette.background.paper,
              "& .MuiOutlinedInput-input": {
                fontSize: "15px",
              },
            }}
            fullWidth
            label="What"
            multiline
            // rows={10}
            value={whatVal}
            onChange={(event) => handleQuestionChange(event, "what")}
            disabled={isTextFieldDisabled()}
            InputProps={{
              readOnly: isTextFieldReadOnly(),
            }}
          // defaultValue="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text"
          />

          <TextField
            id="who"
            sx={{
              mt: 4,
              backgroundColor: theme.palette.background.paper,
              "& .MuiOutlinedInput-input": {
                fontSize: "15px",
              },
            }}
            fullWidth
            label="Who"
            multiline
            // rows={10}
            value={whoVal}
            onChange={(event) => handleQuestionChange(event, "who")}
            disabled={isTextFieldDisabled()}
            InputProps={{
              readOnly: isTextFieldReadOnly(),
            }}
          // defaultValue="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text"
          />
        </Box>
      );
    } else if (aiStrategySelected.strategy === "chat" || aiStrategySelected.strategy === "chatwithknowledge") {
      let promptVal = "";
      if (aiSettingsDataUpdated && aiSettingsDataUpdated[aiStrategySelected.strategy] && aiSettingsDataUpdated[aiStrategySelected.strategy].prompt) {
        promptVal = aiSettingsDataUpdated[aiStrategySelected.strategy].prompt;
      }


      return (
        <Box
          name={"chatPanel"}
          sx={{
            // backgroundColor: "#cc0000",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {showAutomationLinkDialog()}
          {agentInputSnackbar()}
          {showPromptVersionControls()}
          {showAgentInputControls()}

          {
            // showQuestionControls()
          }
          <TextField
            id="prompt"
            sx={{
              flexGrow: 1,
              // height : '100%',
              backgroundColor: theme.palette.background.paper,
              // mt: 2,
              "& .MuiInputBase-multiline": {
                pr: 0,
                flexGrow: 1,
                userSelect: "none",
                // lineHeight: 2,
              },
              "& .MuiOutlinedInput-input": {
                fontSize: "15px",
                height: "100% !important",
                overflow: "auto !important",
                // userSelect: "none !important",
                pr: 2,
              },
              "& .MuiOutlinedInput-input.Mui-disabled": {
                color: theme.palette.text.primary,
                WebkitTextFillColor: theme.palette.text.primary,
              },
            }}
            fullWidth
            label="Prompt"
            multiline
            placeholder="Enter your chat prompt"
            value={promptVal}
            onChange={(event) => handleQuestionChange(event, "prompt")}
            disabled={isTextFieldDisabled()}
            InputProps={{
              readOnly: isTextFieldReadOnly(),
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
      );

    }
  }

  if (init) {
    return <LoadingSubPane spin={true} />;
  }

  return <></>;
};

export default React.memo(AiQuestionsPanel);
