// material-ui
import { alpha, createTheme } from '@mui/material/styles';

// third-party
import { presetDarkPalettes, presetPalettes } from '@ant-design/colors';

// project import
import ThemeOption from './theme';

// ==============================|| DEFAULT THEME - PALETTE  ||============================== //

const Palette = (mode, presetColor) => {
  const colors = mode === 'dark' ? presetDarkPalettes : presetPalettes;

  const customGrey = '#d0d7de';

  // CUSTOM GREY: #d0d7de
  // let greyPrimary = [
  //   '#ffffff',
  //   '#fafafa',
  //   '#f5f5f5',
  //   '#f0f0f0',
  //   '#d9d9d9',
  //   '#bfbfbf',
  //   '#8c8c8c',
  //   '#595959',
  //   '#262626',
  //   '#141414',
  //   '#000000'
  // ];

  // '#cc0000' was '#d9d9d9',
  let greyPrimary = [
    '#ffffff',
    '#fafafa',
    '#f5f5f5',
    '#f0f0f0',
    customGrey, // <- custom colors.grey[4]
    '#bfbfbf',
    '#8c8c8c',
    '#595959',
    '#262626',
    '#141414',
    '#000000'
  ];

  let greyAscent = ['#fafafa', '#bfbfbf', '#434343', '#1f1f1f'];
  let greyConstant = ['#fafafb', '#e6ebf1'];

  if (mode === 'dark') {
    greyPrimary = ['#000000', '#141414', '#1e1e1e', '#595959', '#8c8c8c', '#bfbfbf', '#d9d9d9', '#f0f0f0', '#f5f5f5', '#fafafa', '#ffffff'];
    // greyPrimary.reverse();
    greyAscent = ['#fafafa', '#bfbfbf', '#434343', '#1f1f1f'];
    greyConstant = ['#121212', '#d3d8db'];
  }
  colors.grey = [...greyPrimary, ...greyAscent, ...greyConstant];

  const paletteColor = ThemeOption(colors, presetColor, mode);

  return createTheme({
    palette: {
      mode,
      common: {
        black: '#000',
        white: '#fff'
      },
      ...paletteColor,
      text: {
        primary: mode === 'dark' ? alpha(paletteColor.grey[900], 0.87) : paletteColor.grey[700],
        secondary: mode === 'dark' ? alpha(paletteColor.grey[900], 0.45) : paletteColor.grey[500],
        disabled: mode === 'dark' ? alpha(paletteColor.grey[900], 0.1) : paletteColor.grey[400]
      },
      action: {
        disabled: paletteColor.grey[300]
      },
      divider: mode === 'dark' ? alpha(paletteColor.grey[900], 0.05) : paletteColor.grey[200],
      background: {
        paper: mode === 'dark' ? paletteColor.grey[100] : paletteColor.grey[0],
        bglight: mode === 'dark' ? "#f6f8fa" : "#f6f8fa",
        bg: mode === 'dark' ? "#f6f8fa" : "#f6f8fa",
        bgdark: mode === 'dark' ? "#f6f8fa" : "#f6f8fa",
        bgdarker: mode === 'dark' ? "#f6f8fa" : "#f6f8fa",
        default: paletteColor.grey.A50
      },
      // CUSTOM
      border: {
        light: mode === 'dark' ? paletteColor.grey[300] : paletteColor.grey[300],
        // normal: mode === 'dark' ? paletteColor.grey[300] : "#d0d7de",
        normal: mode === 'dark' ? paletteColor.grey[300] : customGrey,
        dark: mode === 'dark' ? paletteColor.grey[300] : paletteColor.grey[300],
      },
      boxshadow: {
        // #8c959f = 140, 149, 159
        // light: "rgba(140, 149, 159, 0.15) 0px 3px 6px 0px",
        // normal: "rgba(140, 149, 159, 0.15) 0px 3px 6px 0px",
        light: `0px 3px 6px 0px ${alpha("#8c959f", 0.05)}`,
        normal: `0px 3px 6px 0px ${alpha("#8c959f", 0.15)}`,
        dark: `0px 3px 6px 0px ${alpha("#8c959f", 0.5)}`,
      }
    }
  });
};

export default Palette;
