import React, { createContext, useState, useRef } from "react";
// import auth from '@react-native-firebase/auth';
import { getUserToken } from "../code/firebase";

import { getAuth, connectAuthEmulator, signInWithEmailAndPassword, signInWithCustomToken, signOut, sendPasswordResetEmail } from "firebase/auth";

import {
  // collection,
  doc,
  setDoc,
  updateDoc,
  onSnapshot,
  getDoc,
  getDocs,
  getFirestore,
  collection,
  query,
  where,
  orderBy,
  deleteDoc,
  serverTimestamp,
} from "firebase/firestore";

import _ from "lodash";
import { nanoid } from "nanoid";

import { getFunctions, httpsCallable } from "firebase/functions";
import { useAuthState } from "react-firebase-hooks/auth";

import { getUrlParams } from "../code/ecomacy";
import { firebase } from "../code/firebase";
import { re } from "mathjs";

// const axios = require("axios");
import axios from 'axios';

import {setd, debug, setc, clog} from "./HelperFunctions";

// BT: where to get this from?
const tokenPriceInit = 0.012;
const monthlyPriceMinInit = 20;

// let type = "";
// if (userData.locationId !== "") {
//   type = userData.type;
// }
// let locationId = "";
// if (userData.locationId !== "") {
//   locationId = userData.locationId;
// }
// let email = "";
// if (userData.email !== "") {
//   email = userData.email;
// }

const auth = getAuth(firebase);
const functions = getFunctions(firebase, "europe-west1");
const db = getFirestore(firebase);

/**
 * This provider is created
 * to access user in admin area
 */

const initialState = {
  // login: () => {},
  // logout: () => {},
  // authenticate: () => {},
  // getUserToken: () => {},
};

export const AdminAuthContext = createContext(initialState);
// export const AuthContext = createContext({} as ContextState);
// const Context = createContext({} as ContextState);

export const AdminAuthProvider = ({ children }) => {



  let props = {}
  if (children && children.props) {
    props = { ...children.props };
  }

  let userData = {};
  if (props.userData) {
    userData = props.urlDataParams;
  }

  let type = "";
  if (userData.locationId !== "") {
    type = userData.type;
  }
  let locationId = "";
  if (userData.locationId !== "") {
    locationId = userData.locationId;
  }
  let email = "";
  if (userData.email !== "") {
    email = userData.email;
  }

  const urlDataParams = props.urlDataParams;

  // const [user, setUser] = useState(null);
  const [testVal, setTestVal] = useState("");

  const [adminStatus, setAdminStatus] = useState({
    loaded: false,
    isSuperAdmin: false,
    isAdmin: false,
  });

  const [adminUserData, setAdminUserData] = useState({
    loaded: false,
    isAdmin: false,
    // isSuperAdmin: false,
    userDoc: {},
    uid: "",
    // adminApiKeyStatus: "empty",
    memberships: [],
    error: null,
  });

  const [adminApiKeyStatus, setAdminApiKeyStatus] = useState({
    eapiKey: "",
    openaiKey: "",
    anthropicKey: "",
  });

  // for comparisons when userDoc changes
  const adminUserDocRef = useRef(null);

  const [adminMembershipsData, setAdminMembershipsData] = useState([]);

  const [adminAiCategoryAgentsDocs, setAdminAiCategoryAgentsDocs] = useState(null);

  const [adminAiCategoryAgentsSuperAdminDocs, setAdminAiCategoryAgentsSuperAdminDocs] = useState(null);

  const [adminAiPresetAgentsSuperAdminDocs, setAdminAiPresetAgentsSuperAdminDocs] = useState(null);




  const [adminAiCategoriesDocs, setAdminAiCategoriesDocs] = useState(null);

  const [adminAiCategoriesSuperAdminDocs, setAdminAiCategoriesSuperAdminDocs] = useState(null);

  const [adminAiPresetsSuperAdminDocs, setAdminAiPresetsSuperAdminDocs] = useState(null);

  const [adminAiSoftwareConfig, setAdminAiSoftwareConfig] = useState(null);
  const [adminAiSoftwareConfigRefresh, setAdminAiSoftwareConfigRefresh] = useState(false);

  const adminAiCustomersMaxCount = useRef(0);
  const [adminAiCustomersActiveCount, setAdminAiCustomersActiveCount] = useState(null);

  const [customersEcData, setCustomersEcData] = useState({
    data: null,
    error: null,
    loaded: false,
  });

  const [customersData, setCustomersData] = useState({
    data: null,
    // dataOrig: null,
    error: null,
    loaded: false,
    // synced: false
  });

  const [adminPlanData, setAdminPlanData] = useState({
    data: {},
    error: null,
    loaded: false,
  });

  // const customersDataOrigObj = useRef(null);
  const [customersDataOrig, setCustomersDataOrig] = useState(null);

  const [customersDataSynced, setCustomersDataSynced] = useState(false);

  const tokenPrice = useRef(tokenPriceInit);
  const monthlyPriceMin = useRef(monthlyPriceMinInit);

  const [adminShowForgotPassword, setAdminShowForgotPassword] = useState(false);
  const [adminShowCheckEmail, setAdminShowCheckEmail] = useState(false);

  // AdminAiPresetAgentSetupTab
  const [adminAiCategoryAgentSelected, setAdminAiCategoryAgentSelected] = useState(null);
  const [adminAiPresetAgentSetupTabLoading, setAdminAiPresetAgentSetupTabLoading] = useState(true);

  const [adminAiCategoryAgentsIsEditing, setAdminAiCategoryAgentsIsEditing] = useState({
    active: false,
    type: "",
  });

  const [adminAiPresetAgentsDocs, setAdminAiPresetAgentsDocs] = useState(null);
  // const adminCategoryAiPresets = useRef(null);
  const [adminCategoryAgentAiPresetAgents, setAdminCategoryAgentAiPresetAgents] = useState(null);
  const [adminCategoryAgentAiPresetAgentsUpdated, setAdminCategoryAgentAiPresetAgentsUpdated] = useState(null);
  const [adminCategoryAgentAiPresetAgentsIdsUpdated, setAdminCategoryAgentAiPresetAgentsIdsUpdated] = useState(null);

  const [adminAiPresetAgentsIsEditing, setAdminAiPresetAgentsIsEditing] = useState({
    active: false,
    type: "",
  });



  // AdminAiSetupTab
  const [adminAiCategorySelected, setAdminAiCategorySelected] = useState(null);
  const [adminAiSetupTabLoading, setAdminAiSetupTabLoading] = useState(true);

  const [adminAiCategoriesIsEditing, setAdminAiCategoriesIsEditing] = useState({
    active: false,
    type: "",
  });

  const [adminAiPresetsDocs, setAdminAiPresetsDocs] = useState(null);
  // const adminCategoryAiPresets = useRef(null);
  const [adminCategoryAiPresets, setAdminCategoryAiPresets] = useState(null);
  const [adminCategoryAiPresetsUpdated, setAdminCategoryAiPresetsUpdated] = useState(null);
  const [adminCategoryAiPresetsIdsUpdated, setAdminCategoryAiPresetsIdsUpdated] = useState(null);

  const [adminAiPresetsIsEditing, setAdminAiPresetsIsEditing] = useState({
    active: false,
    type: "",
  });

  const [showSettingsDialog, setShowSettingsDialog] = useState(false);

  const [user, loadingUser, errorUser] = useAuthState(auth);

  const getAdminUserDoc = async (uid) => {
    return new Promise((resolve, reject) => {
      try {
        let userDoc = null;
        const q = query(collection(db, "users"), where("userid", "==", uid));
        // let customerDocs = []
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // customerDocs.push(doc.data().name);
            userDoc = doc.data();
          });
          // if (clog()) console.log("Current cities in CA: ", cities.join(", "));
          if (userDoc) {
            // compare aisoftwareconfig settings
            // const res = compareAiSoftwareConfigSettings(userDoc)
            let uid = userDoc.userid;
            // user doc found
            setAdminUserData({
              ...adminUserData,
              userDoc: userDoc,
              loaded: true,
              isAdmin: true,
              uid: uid,
            });

            //
          }

          resolve(userDoc);

          // resolve({
          //   data: customerDocs
          // })

          // resolve(userDoc)
        });
      } catch (err) {
        console.error(err);
        // setAdminUserData({
        //   ...adminUserData,
        //   loaded: true,
        //   error: err
        // })

        resolve({
          error: err,
        });
      }
    });
  };

  const getAdminUserDocOLD = async (uid) => {
    try {
      let userDoc = null;
      const q = query(collection(db, "users"), where("userid", "==", uid));

      const snapshot = await getDocs(q);

      if (snapshot.empty) {
        return userDoc;
      }
      snapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        // if (clog()) console.log(doc.id, " => ", doc.data());
        userDoc = doc.data();
      });

      return userDoc;
    } catch (err) {
      console.error(err);
      return {
        error: err,
      };
    }
  };

  const getAiSoftwareConfigDoc = async (aiSoftwareConfigId) => {
    let returnData = {};

    try {
      const docRef = doc(db, "aisoftwareconfigs", aiSoftwareConfigId);
      const docData = await getDoc(docRef);

      if (docData.exists()) {
        if (clog()) console.log(docData.data());
        returnData.data = docData.data();
      } else {
        if (clog()) console.log("Document does not exist");
        returnData.error = {
          val: "Document does not exist",
        };
      }

      return returnData;
    } catch (err) {
      console.error(err);
      returnData.error = err;
      return returnData;
    }
  };

  const getAdminCustomerDocs = async (uid) => {
    return new Promise((resolve, reject) => {
      try {
        let customersDataOrigObject = {};
        const q = query(collection(db, "customers"), where("userid", "==", uid));
        // let customerDocs = []
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          let customerDocs = [];
          // const cities = [];
          querySnapshot.forEach((doc) => {
            // customerDocs.push(doc.data().name);

            // BT temp fix aienabled
            // const docData = doc.data()
            const docData = { ...doc.data() };
            if (docData.aienabled === undefined) {
              docData.aienabled = false;
            }

            customerDocs.push(docData);

            // let customerDocClone = _.cloneDeep(docData)

            // customersDataOrigObject[docData.id] = docData
            // if (docData.id === '0PmBJQrhaR4GHno2mR5e') {
            // }
            // customersDataOrigObject[docData.id] = customerDocClone
          });
          // if (clog()) console.log("Current cities in CA: ", cities.join(", "));

          setCustomersData({
            ...customersData,
            data: customerDocs,
            // dataOrig: customerDocs,
            loaded: true,
          });

          // create customersDataOrig
          //// setCustomersDataOrig(customersDataOrigObject)

          // ref for comparison
          // let customerDocsClone = _.cloneDeep(customerDocs)

          resolve({
            data: customerDocs,
          });
        });

        // setCustomersData({
        //   ...customersData,
        //   data: [],
        //   // dataOrig: customerDocs,
        //   loaded: true,
        // })

        // return unsubscribe
      } catch (err) {
        console.error(err);
        setCustomersData({
          ...customersData,
          error: err,
          loaded: true,
        });

        reject({
          error: err,
        });
      }
    });
  };

  const getSuperAdminUserDocs = async (uid) => {
    return new Promise((resolve, reject) => {
      try {
        let customersDataOrigObject = {};
        const q = query(collection(db, "users"), where("isagency", "==", true));
        // let customerDocs = []
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          let customerDocs = [];
          querySnapshot.forEach((doc) => {
            // customerDocs.push(doc.data().name);

            // BT temp fix aienabled
            // const docData = doc.data()
            const docData = { ...doc.data() };
            if (docData.aienabled === undefined) {
              docData.aienabled = false;
            }

            customerDocs.push(docData);

            // let customerDocClone = _.cloneDeep(docData)

            // customersDataOrigObject[docData.id] = docData
            // if (docData.id === '0PmBJQrhaR4GHno2mR5e') {
            // }
            // customersDataOrigObject[docData.id] = customerDocClone
          });
          // if (clog()) console.log("Current cities in CA: ", cities.join(", "));

          setCustomersData({
            ...customersData,
            data: customerDocs,
            // dataOrig: customerDocs,
            loaded: true,
          });

          // create customersDataOrig
          //// setCustomersDataOrig(customersDataOrigObject)

          // ref for comparison
          // let customerDocsClone = _.cloneDeep(customerDocs)

          resolve({
            data: customerDocs,
          });
        });

        // setCustomersData({
        //   ...customersData,
        //   data: [],
        //   // dataOrig: customerDocs,
        //   loaded: true,
        // })

        // return unsubscribe
      } catch (err) {
        console.error(err);
        setCustomersData({
          ...customersData,
          error: err,
          loaded: true,
        });

        reject({
          error: err,
        });
      }
    });
  };

  const createAdminCustomerDoc = async (cData, aUid) => {
    try {
      let res = {};

      let itemData = {
        businessname: "",
        aienabled: false,
        enabled: false,
        locationid: "",
        token: "",
        userid: "",
        data: {
          //  multiple:
        },
      };

      let userId = "";
      if (aUid && aUid !== "") {
        userId = aUid;
      }
      itemData.userid = userId;

      let businessName = "";
      if (cData && cData.businessname) {
        businessName = cData.businessname;
      }
      itemData.businessname = businessName;

      let locationId = "";
      if (cData && cData.locationid) {
        locationId = cData.locationid;
      }
      itemData.locationid = locationId;
      itemData.token = locationId;

      //
      // const ref = db.collection('customers').doc();
      // itemData.id = id

      // const customersRef = collection(db, "customers"); // collectionRef
      // const customerRef = doc(customersRef); // docRef
      // const id = customerRef.id; // a docRef has an id property
      const customerRef = doc(db, "customers", locationId);
      const id = locationId;

      const customerData = { id, ...itemData }; // insert the id among the data
      res = await setDoc(customerRef, customerData); // create the document

      return { data: res };
    } catch (error) {
      return { error: error };
    }
  };

  const updateAdminCustomerDoc = async (customerData, busName) => {
    try {
      let res = {};
      const customerDocId = customerData?.id || "";
      const businessName = busName || "";

      if (!customerDocId || !businessName) {
        return {
          error: {
            val: 'customerDocId or businessName is missing'
          }
        };
      }

      const itemData = { businessname: businessName };
      const customerRef = doc(db, "customers", customerDocId);
      // res = await updateDoc(customerRef, itemData); // Update the document

      // clone customerData
      // const customersDataClone = _.cloneDeep(customersData);
      // const newCustomersData = customersDataClone.data;
      // setCustomersData({
      //   ...customersData,
      //   data: customerDocs,
      //   // dataOrig: customerDocs,
      //   loaded: true,
      // });

      return { data: res };
    } catch (error) {
      return { error: error };
    }
  };

  // const createAdminCustomerDocsOLD = async (eCustomerData, fCustomerData, adminUid) => {
  //   try {
  //     const fCustomersCount = fCustomerData.length;
  //     const eCustomersCount = eCustomerData.length;
  //     debugger
  //     let failed = [];

  //     // add extra docs
  //     if (eCustomersCount > fCustomersCount) {
  //       for (var i = 0; i < eCustomerData.length; i++) {
  //         const eCustomer = eCustomerData[i];
  //         const eCustomerLocId = eCustomer.locationid;
  //         // look in fCustomerData where locationid
  //         var foundItem = _.find(fCustomerData, { locationid: eCustomerLocId });
  //         if (!foundItem) {
  //           // if (clog()) console.log('item not found:', eCustomerLocId);

  //           // Create doc
  //           const res = await createAdminCustomerDoc(eCustomer, adminUid);
  //           if (res && res.error) {
  //             failed.push(eCustomer);
  //           }
  //         } else {
  //           // if (clog()) console.log('item found:', eCustomerLocId);
  //         }
  //       }

  //       if (failed.length === 0) {
  //         setCustomersDataSynced(true);
  //       }
  //     } else {
  //       setCustomersDataSynced(true);
  //     }
  //   } catch (err) {
  //     console.error(err);

  //     // setCustomersData({
  //     //   ...customersData,
  //     //   error: err,
  //     //   loaded: true,
  //     // })
  //   }
  // };

  const createAdminCustomerDocs = async (eCustomerData, fCustomerData, adminUid) => {
    try {
      // const fCustomersCount = fCustomerData.length;
      // const eCustomersCount = eCustomerData.length;

      let failed = [];
      let res = {};
      // add extra docs
      // if (eCustomersCount > fCustomersCount) {
      for (var i = 0; i < eCustomerData.length; i++) {
        const eCustomer = eCustomerData[i];
        const eCustomerLocId = eCustomer.locationid;
        // look in fCustomerData where locationid
        var foundItem = _.find(fCustomerData, { locationid: eCustomerLocId });
        if (!foundItem) {
          // if (clog()) console.log('item not found:', eCustomerLocId);
          if (clog()) console.warn("location customer doc not found:", eCustomerLocId);
          // Create doc
          res = await createAdminCustomerDoc(eCustomer, adminUid);
          if (res && res.error) {
            failed.push(eCustomer);
          }
        } else {
          // if (clog()) console.log('item found:', eCustomerLocId);
          // check if data is different
          // businessname
          // email
          if (eCustomer.businessname !== foundItem.businessname) {
            if (clog()) {
              console.group();
              if (clog()) console.log("businessname needs updating");
              if (clog()) console.log("ec businessname:", eCustomer.businessname);
              if (clog()) console.log("cus businessname:", foundItem.businessname);
              console.groupEnd();
            }
            // res = await updateAdminCustomerDoc(foundItem, eCustomer.businessname);
            // if (res && res.error) {
            //   failed.push(eCustomer);
            // }
          }
          // if (eCustomer.email !== foundItem.email) {
          //   debugger
          //   if (clog()) {
          //     console.group();
          //     if (clog()) console.log("ec email:", eCustomer.email);
          //     if (clog()) console.log("cus email:", foundItem.email);
          //     console.groupEnd();
          //   }
          // }

        }
      }

      if (failed.length === 0) {
        setCustomersDataSynced(true);
      }
      // } else {
      //   setCustomersDataSynced(true);
      // }
    } catch (err) {
      console.error(err);

      // setCustomersData({
      //   ...customersData,
      //   error: err,
      //   loaded: true,
      // })
    }
  };

  const getAdminPlanDoc = async (planid) => {
    try {
      let planDoc = null;
      const q = query(collection(db, "plans"), where("planid", "==", planid));

      const snapshot = await getDocs(q);

      if (snapshot.empty) {
        return planDoc;
      }
      snapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        // if (clog()) console.log(doc.id, " => ", doc.data());
        planDoc = doc.data();
      });

      return planDoc;
    } catch (err) {
      console.error(err);
      return {
        error: err,
      };
    }
  };

  const requestEcomacyLocationsFromApi = async (data) => {
    let returnData = {
      status: 0,
      data: {},
      error: {},
    };
    try {
      const jwt = data.jwt;
      const payload = data.payload;

      let URL = import.meta.env.VITE_REACT_APP_AI_API_URL;
      /*
      let URL = 'https://api.ecomacy.com'
      if (import.meta.env.VITE_REACT_APP_ENV === 'dev') {
        URL = 'http://localhost:8080'
      }
      */

      if (URL) {
        const apiUrlPath = URL.concat("/acl/", payload.eapi);
        // const response = await axios.get(URL + '/apguser', payload, {
        const response = await axios.get(apiUrlPath, {
          headers: {
            Authorization: jwt,
          },
        });

        // if (clog()) console.log('response:', response);
        // cLog(response.data)
        if (response.status === 200) {
          returnData.status = response.status;
          returnData.data = response.data;
        } else {
          returnData.status = response.status;
          returnData.error = response.error;
        }
        // if (clog()) console.log('6 requestMfaFromApi URL', URL);

        return returnData;
      } else {
        returnData.status = 490;
        returnData.error = "REACT_APP_API_URL url missing from env";
        return returnData;
      }
    } catch (err) {
      console.error("response error", err);
      returnData.status = 491;
      returnData.error = err;
      return returnData;
    }
  };

  const getEcomacyLocations = async (userData) => {
    let returnData = {
      status: 200,
      data: {},
      error: {},
    };
    try {
      if (userData && userData.eapi && userData.eapi !== "") {
        // call fn API to get data

        //STULOGIN
        const userToken = await getUserToken();
        const jwt = "Bearer " + userToken;

        // if (clog()) console.log('4 getEcomacyLocations:', jwt);
        const data = {
          jwt: jwt,
          payload: {
            eapi: userData.eapi,
          },
        };
        // if (clog()) console.log('getEcomacyLocations data:', data);
        let result = await requestEcomacyLocationsFromApi(data);

        if (clog()) console.log("customers:", result.data.data);

        returnData.status = result.status;

        if (result.status === 200 && result.data && result.data.data) {
          const apiData = result.data.data;
          returnData.status = apiData.status;
          if (apiData.data) {
            returnData.data = apiData.data;
          } else if (apiData.error) {
            returnData.error = apiData.error;
          }
        } else {
          returnData.error = result.error.code;
        }
        // let result = {status: 'success'};
        return returnData;
      }
    } catch (err) {
      console.error(err);
      returnData.status = 501;
      returnData.error = {
        val: "Ecomacy customers data fetch failed",
      };
      // return returnData;
    }
  };

  const generateFirestoreDate = () => {
    // generate firestore timestamp
    // firebase.firestore.FieldValue.serverTimestamp()
    // firebase.firestore.Timestamp.now()
    // const timestamp = 1676475521;
    // const timestampDate = new Date(timestamp*1000);
    // const timestampFirestore = admin.firestore.Timestamp.fromDate(timestampDate)
    // const timestampFirestore = admin.firestore.Timestamp.now()
    // const timestampFirestore = firebase.firestore.Timestamp.now()
    const timestampFirestore = serverTimestamp();

    return timestampFirestore;
  };

  const delay = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds));

  const updateAiSoftwareConfigAndCategories = async (userDoc) => {
    // START: aiSoftwareConfig
    // AiSettings
    let aiSoftwareConfigId = "";
    if (userDoc && userDoc.aisoftwareconfigid) {
      aiSoftwareConfigId = userDoc.aisoftwareconfigid;
    }

    // check if userDoc has aisoftwareconfig map
    let aiSoftwareConfig = null;
    if (userDoc && userDoc.aisoftwareconfig) {
      aiSoftwareConfig = userDoc.aisoftwareconfig;
      if (aiSoftwareConfig.customers) {
        // set max count ref for customers
        adminAiCustomersMaxCount.current = aiSoftwareConfig.customers;
      }
    }

    // if not then fetch the doc from firestore using the aisoftwareconfigid
    if (aiSoftwareConfig === null && aiSoftwareConfigId !== "") {
      const aiSoftwareConfigResult = await getAiSoftwareConfigDoc(aiSoftwareConfigId);
      if (aiSoftwareConfigResult && aiSoftwareConfigResult.data && aiSoftwareConfigResult.data.config) {
        aiSoftwareConfig = aiSoftwareConfigResult.data.config;
        if (aiSoftwareConfig && aiSoftwareConfig.customers) {
          // set max count ref for customers
          adminAiCustomersMaxCount.current = aiSoftwareConfig.customers;
        }
      }
    }

    // might not need to be used?
    if (aiSoftwareConfig !== null) {
      setAdminAiSoftwareConfig(aiSoftwareConfig);
    }

    // set aicategory data
    const aicategoriesResult = await getAiCategoriesDocs();

    // set aicategory data
    const aicategoriesSuperAdminResult = await getAiCategoriesSuperAdminDocs();

    // set aicategoryagent data
    const aicategoryagentResult = await getAiCategoryAgentsDocs();

    // set aicategoryagent data
    const aicategoryagentsSuperAdminResult = await getAiCategoryAgentsSuperAdminDocs();

    // END: aiSoftwareConfig

    return;
  };

  const getAiCategoriesDocs = async () => {
    return new Promise((resolve, reject) => {
      try {
        let userId = "";
        if (user && user.uid && user.uid !== "") {
          userId = user.uid;
        }

        if (userId === "") {
          // no locationid
          reject({
            error: "no userId",
          });
        }

        if (userId !== "") {
          const q = query(
            collection(db, "aicategories"),
            where("userid", "==", userId),
            where("superadmin", "==", false),
            // where("aisettingid", "==", aisettingid),
            orderBy("name", "asc")
          );
          const unsub = onSnapshot(q, (querySnapshot) => {
            const aicategoriesDocsArr = [];

            querySnapshot.forEach((doc) => {
              const docData = doc.data();

              let smallData = docData;
              aicategoriesDocsArr.push(smallData);
            });

            setAdminAiCategoriesDocs(aicategoriesDocsArr);

            resolve({
              data: aicategoriesDocsArr,
            });
          });
        }
      } catch (err) {
        console.error(err);
        reject({
          error: err,
        });
      }
    });
  };

  const getAiCategoriesSuperAdminDocs = async () => {
    return new Promise((resolve, reject) => {
      try {
        let userId = "";
        if (user && user.uid && user.uid !== "") {
          userId = user.uid;
        }

        if (userId === "") {
          // no locationid
          reject({
            error: "no userId",
          });
        }

        if (userId !== "") {
          const q = query(
            collection(db, "aicategories"),
            where("userid", "==", userId),
            where("superadmin", "==", true),
            // where("aisettingid", "==", aisettingid),
            orderBy("name", "asc")
          );
          const unsub = onSnapshot(q, (querySnapshot) => {
            const aicategoriesDocsArr = [];

            querySnapshot.forEach((doc) => {
              const docData = doc.data();

              let smallData = docData;
              aicategoriesDocsArr.push(smallData);
            });

            setAdminAiCategoriesSuperAdminDocs(aicategoriesDocsArr);

            resolve({
              data: aicategoriesDocsArr,
            });
          });
        }
      } catch (err) {
        console.error(err);
        reject({
          error: err,
        });
      }
    });
  };


  const getAiCategoryAgentsDocs = async () => {
    return new Promise((resolve, reject) => {
      try {
        let userId = "";
        if (user && user.uid && user.uid !== "") {
          userId = user.uid;
        }

        if (userId === "") {
          // no locationid
          reject({
            error: "no userId",
          });
        }

        if (userId !== "") {
          const q = query(
            collection(db, "aicategoryagents"),
            where("userid", "==", userId),
            where("superadmin", "==", false),
            // where("aisettingid", "==", aisettingid),
            orderBy("name", "asc")
          );
          const unsub = onSnapshot(q, (querySnapshot) => {
            const aicategoryagentsDocsArr = [];

            querySnapshot.forEach((doc) => {
              const docData = doc.data();

              let smallData = docData;
              aicategoryagentsDocsArr.push(smallData);
            });

            setAdminAiCategoryAgentsDocs(aicategoryagentsDocsArr);

            resolve({
              data: aicategoryagentsDocsArr,
            });
          });
        }
      } catch (err) {
        console.error(err);
        reject({
          error: err,
        });
      }
    });
  };

  const getAiCategoryAgentsSuperAdminDocs = async () => {
    return new Promise((resolve, reject) => {
      try {
        let userId = "";
        if (user && user.uid && user.uid !== "") {
          userId = user.uid;
        }

        if (userId === "") {
          // no locationid
          reject({
            error: "no userId",
          });
        }

        if (userId !== "") {
          const q = query(
            collection(db, "aicategories"),
            where("userid", "==", userId),
            where("superadmin", "==", true),
            // where("aisettingid", "==", aisettingid),
            orderBy("name", "asc")
          );
          const unsub = onSnapshot(q, (querySnapshot) => {
            const aicategoriesDocsArr = [];

            querySnapshot.forEach((doc) => {
              const docData = doc.data();

              let smallData = docData;
              aicategoriesDocsArr.push(smallData);
            });

            setAdminAiCategoryAgentsSuperAdminDocs(aicategoriesDocsArr);

            resolve({
              data: aicategoriesDocsArr,
            });
          });
        }
      } catch (err) {
        console.error(err);
        reject({
          error: err,
        });
      }
    });
  };



  const fetchAdminStatus = async () => {
    let isSuperAdmin = false;
    let isAdmin = false;
    let isDemoAdmin = false;

    if (user) {
      const idTokenResult = await user.getIdTokenResult();

      // check if superadmin
      const superAdminClaim = idTokenResult.claims.superadmin;
      if (superAdminClaim) {
        isSuperAdmin = true;
        isAdmin = true;
      }
      // check if admin
      const adminClaim = idTokenResult.claims.admin;
      if (adminClaim) {
        isAdmin = true;
      }

      // check if demoadmin
      const demoAdminClaim = idTokenResult.claims.demoadmin;
      if (demoAdminClaim) {
        isAdmin = true;
        isDemoAdmin = true;
      }
    }

    const returnData = {
      isSuperAdmin: isSuperAdmin,
      isAdmin: isAdmin,
      isDemoAdmin: isDemoAdmin,
    };

    return returnData;
  };

  return (
    <AdminAuthContext.Provider
      value={{
        urlDataParams,

        user,
        // setUser,
        testVal,
        setTestVal,

        tokenPrice,
        monthlyPriceMin,

        adminStatus,
        setAdminStatus,

        adminApiKeyStatus,
        setAdminApiKeyStatus,

        adminUserData,
        setAdminUserData,
        adminUserDocRef,
        adminMembershipsData,
        setAdminMembershipsData,
        adminPlanData,
        setAdminPlanData,
        customersData,
        setCustomersData,
        customersEcData,
        setCustomersEcData,
        customersDataSynced,
        setCustomersDataSynced,

        adminAiCategoryAgentsDocs,
        setAdminAiCategoryAgentsDocs,
        adminAiCategoryAgentsIsEditing,
        setAdminAiCategoryAgentsIsEditing,

        adminAiCategoryAgentsSuperAdminDocs,
        setAdminAiCategoryAgentsSuperAdminDocs,
        adminAiPresetAgentsSuperAdminDocs,
        setAdminAiPresetAgentsSuperAdminDocs,


        adminAiCategoriesDocs,
        setAdminAiCategoriesDocs,
        adminAiCategoriesIsEditing,
        setAdminAiCategoriesIsEditing,

        adminAiCategoriesSuperAdminDocs,
        setAdminAiCategoriesSuperAdminDocs,
        adminAiPresetsSuperAdminDocs,
        setAdminAiPresetsSuperAdminDocs,


        adminAiSoftwareConfig,
        setAdminAiSoftwareConfig,
        adminAiSoftwareConfigRefresh,
        setAdminAiSoftwareConfigRefresh,
        adminAiCustomersMaxCount,
        adminAiCustomersActiveCount,
        setAdminAiCustomersActiveCount,

        customersDataOrig,
        setCustomersDataOrig,

        adminShowForgotPassword,
        setAdminShowForgotPassword,
        adminShowCheckEmail,
        setAdminShowCheckEmail,

        adminAiCategoryAgentSelected,
        setAdminAiCategoryAgentSelected,
        adminAiPresetAgentSetupTabLoading,
        setAdminAiPresetAgentSetupTabLoading,
        adminAiPresetAgentsDocs,
        setAdminAiPresetAgentsDocs,
        adminCategoryAgentAiPresetAgents,
        setAdminCategoryAgentAiPresetAgents,
        adminCategoryAgentAiPresetAgentsUpdated,
        setAdminCategoryAgentAiPresetAgentsUpdated,
        adminCategoryAgentAiPresetAgentsIdsUpdated,
        setAdminCategoryAgentAiPresetAgentsIdsUpdated,
        adminAiPresetAgentsIsEditing,
        setAdminAiPresetAgentsIsEditing,

        adminAiCategorySelected,
        setAdminAiCategorySelected,
        adminAiSetupTabLoading,
        setAdminAiSetupTabLoading,
        adminAiPresetsDocs,
        setAdminAiPresetsDocs,
        adminCategoryAiPresets,
        setAdminCategoryAiPresets,
        adminCategoryAiPresetsUpdated,
        setAdminCategoryAiPresetsUpdated,
        adminCategoryAiPresetsIdsUpdated,
        setAdminCategoryAiPresetsIdsUpdated,
        adminAiPresetsIsEditing,
        setAdminAiPresetsIsEditing,

        showSettingsDialog,
        setShowSettingsDialog,
        // customersDataOrigObj,
        // loginMfa: async (props: AuthProps) => {
        login: async (email, password) => {
          try {
            // const {email, password} = props;
            // if (clog()) console.log('props', props);
            // if (clog()) console.log('email', email);
            // if (clog()) console.log('password', password);
            // await auth().signInWithEmailAndPassword(email, password);
            //STULOGIN
            await signInWithEmailAndPassword(auth, email, password);
          } catch (e) {
            if (clog()) console.log(e);
          }
        },
        logout: async () => {
          try {
            await signOut(auth);
          } catch (e) {
            console.error(e);
          }
        },
        resetPassword: async (email) => {
          try {
            let returnData = {};

            // const res = await auth().sendPasswordResetEmail();
            const res = await sendPasswordResetEmail(auth, email);
            if (res && res.error) {
              returnData.error = res.error;
            }
            else {
              returnData.data = {
                val: 'success'
              };
            }

            return returnData;
          } catch (err) {
            console.error(err);
            return {
              error: {
                val: err,
              },
            };
          }
        },
        authenticate: async (req, res, next) => {
          try {
            // if admin don't auto-login from url params
            // if (locationId === 'admin') {
            //     return
            // }
            if (type === "admin") {
              return;
            }

            const user = auth.currentUser;
            if (user) {
              if (clog()) console.log("email:", email);
              if (clog()) console.log("locationId:", locationId);
              if (clog()) console.log("user:", user);
              return user;
            } else {
              if (clog()) console.log("email:", email);
              if (clog()) console.log("locationId:", locationId);

              //
              // call cloud function - seems to have cors issues
              //
              const authorise = httpsCallable(functions, "authorise");
              const token = await authorise({ loc: locationId });
              if (clog()) console.log("token:", token);

              // const newuser = await signInWithEmailAndPassword(auth, email, locationId)
              const newuser = await signInWithCustomToken(auth, token.data);

              if (clog()) console.log("newuser:", newuser);
              return newuser;
            }
          } catch (err) {
            console.error(err);
          }
        },
        getSetAdminStatus: async () => {
          let isSuperAdmin = false;
          let isAdmin = false;
          let isDemoAdmin = false;

          if (user) {
            const idTokenResult = await user.getIdTokenResult();
            // check if superadmin
            const superAdminClaim = idTokenResult.claims.superadmin;
            if (superAdminClaim) {
              isSuperAdmin = true;
              isAdmin = true;
            }
            // check if admin
            const adminClaim = idTokenResult.claims.admin;
            if (adminClaim) {
              isAdmin = true;
            }

            // check if demoadmin
            const demoAdminClaim = idTokenResult.claims.demoadmin;
            if (demoAdminClaim) {
              isAdmin = true;
              isDemoAdmin = true;
            }

            // set state for use on admin login screen etc
            setAdminStatus({
              loaded: true,
              isSuperAdmin: isSuperAdmin,
              isAdmin: isAdmin,
              isDemoAdmin: isDemoAdmin,
            });
          }

          return isAdmin;
        },
        getAdminStatus: async () => {
          let isSuperAdmin = false;
          let isAdmin = false;
          let isDemoAdmin = false;

          if (user) {
            const idTokenResult = await user.getIdTokenResult();

            // check if superadmin
            const superAdminClaim = idTokenResult.claims.superadmin;
            if (superAdminClaim) {
              isSuperAdmin = true;
              isAdmin = true;
            }
            // check if admin
            const adminClaim = idTokenResult.claims.admin;
            if (adminClaim) {
              isAdmin = true;
            }

            // check if demoadmin
            const demoAdminClaim = idTokenResult.claims.demoadmin;
            if (demoAdminClaim) {
              isAdmin = true;
              isDemoAdmin = true;
            }
          }

          const returnData = {
            isSuperAdmin: isSuperAdmin,
            isAdmin: isAdmin,
            isDemoAdmin: isDemoAdmin,
          };

          return returnData;
        },
        resetAdminStatus: async () => {
          // reset admin status
          setAdminStatus({
            loaded: false,
            isSuperAdmin: false,
            isAdmin: false,
            isDemoAdmin: false,
          });

          return;
        },
        getAdminUserData: async (aStatus) => {
          try {
            // let userDoc = {}
            if (user && user.uid) {
              // 1: Get UserDoc

              const userDoc = await getAdminUserDoc(user.uid);
              if (userDoc && userDoc.error === undefined) {
                // now set in getAdminUserDoc snapshot subscribe
                // setAdminUserData({
                //   ...adminUserData,
                //   userDoc: userDoc,
                //   loaded: true,
                //   isAdmin: true,
                // })

                // set membership data
                let memberships = [];
                if (userDoc && userDoc.memberships) {
                  memberships = userDoc.memberships;
                }
                if (memberships.length > 0) {
                  setAdminMembershipsData(memberships);
                }
                // set for comparison later
                adminUserDocRef.current = userDoc;
                const aiConfigRes = await updateAiSoftwareConfigAndCategories(userDoc);

                let ecomacyResult = {};

                // 2: Call ecomacy api and get customers data
                // check if user has eapi key

                if (userDoc && userDoc.eapi && userDoc.eapi !== "") {
                  // 2: Call ecomacy api and get customers data
                  ecomacyResult = await getEcomacyLocations(userDoc);
                  // find customers in firestore
                  if (ecomacyResult && ecomacyResult.status === 200) {
                    setAdminApiKeyStatus({
                      ...adminApiKeyStatus,
                      eapiKey: "valid",
                    });

                    setCustomersEcData({
                      ...customersEcData,
                      data: ecomacyResult.data,
                      error: null,
                      loaded: true,
                    });
                  } else {
                    setAdminApiKeyStatus({
                      ...adminApiKeyStatus,
                      eapiKey: "invalid",
                    });

                    setCustomersEcData({
                      ...customersEcData,
                      data: null,
                      error: ecomacyResult.error,
                      loaded: true,
                    });
                  }
                } else {
                  setAdminApiKeyStatus({
                    ...adminApiKeyStatus,
                    eapiKey: "empty",
                  });

                  setCustomersEcData({
                    ...customersEcData,
                    data: null,
                    error: "noEapiKey",
                    loaded: true,
                  });
                }
                // 3: if ecomacy customers exist then find firestore customers data
                if (ecomacyResult && ecomacyResult.data && ecomacyResult.data.length > 0) {
                  // get Customer docs and set snapshot state

                  const adStatus = await fetchAdminStatus();

                  let customersResult = {};

                  if (adStatus.isSuperAdmin === false) {
                    customersResult = await getAdminCustomerDocs(user.uid);
                  }

                  // super admin so handle differently
                  if (adStatus.isSuperAdmin === true) {
                    customersResult = await getSuperAdminUserDocs(user.uid);

                  }

                  let customersDataOrigObject = {};

                  // create
                  if (
                    customersResult &&
                    customersResult.data &&
                    // adStatus.loaded === true &&
                    adStatus.isAdmin === true &&
                    adStatus.isSuperAdmin === false
                  ) {
                    const createCustomersResult = await createAdminCustomerDocs(ecomacyResult.data, customersResult.data, user.uid);
                    // set in createAdminCustomerDocs
                    // setCustomersDataSynced(true);

                    // loop through customersResult
                    for (var i = 0; i < customersResult.data.length; i++) {
                      const customerResultData = customersResult.data[i];
                      let customerDocClone = _.cloneDeep(customerResultData);
                      // customersDataOrigObject[customerResultData.id] = customerResultData
                      customersDataOrigObject[customerResultData.id] = customerDocClone;
                    }
                  }

                  // super admin so handle differently
                  else if (
                    customersResult &&
                    customersResult.data &&
                    // adStatus.loaded === true &&
                    adStatus.isAdmin === true &&
                    adStatus.isSuperAdmin === true
                  ) {
                    // don't create customer docs
                    for (var i = 0; i < customersResult.data.length; i++) {
                      const customerResultData = customersResult.data[i];
                      let customerDocClone = _.cloneDeep(customerResultData);
                      // customersDataOrigObject[customerResultData.id] = customerResultData
                      customersDataOrigObject[customerResultData.id] = customerDocClone;

                    }
                    setCustomersDataSynced(true);
                  }

                  setCustomersDataOrig(customersDataOrigObject);

                  // BT: need to refetch customer docs??
                } else {
                  // no ecomacy customers
                  // setCustomersData({
                  //   ...customersEcData,
                  //   error: ecomacyResult.error,
                  //   loaded: true,
                  // })
                }
              } else if (userDoc && userDoc.error) {
                //
                setAdminUserData({ ...adminUserData, loaded: true, error: userDoc.error });
              } else {
                // no doc found
                setAdminUserData({
                  ...adminUserData,
                  loaded: true,
                  error: {
                    val: "no doc found",
                  },
                });
              }
            }
            return;
          } catch (err) {
            console.error(err);
            setAdminUserData({ ...adminUserData, loaded: true, error: err });
          }
        },
        saveAdminApiKeys: async (adminData, keyData) => {
          let returnData = {};

          try {
            // wait X milliseconds
            // await delay(1000)

            let res = {};
            // update user doc
            // const usersRef = collection(db, 'users') // collectionRef
            // const docRef = doc(db, "countries", "my.custom.id@gmail.com" );

            let docId = "";
            // if adminData and adminData.userDoc exists
            if (adminData && adminData.userDoc) {
              const userDoc = adminData.userDoc;
              // if userDoc id exists
              if (userDoc.id) {
                docId = userDoc.id;
              }
            }

            if (docId !== "") {
              const userRef = doc(db, "users", docId); // docRef
              const apiKeyData = {
                eapi: keyData.eapiKey,
                openai_key: keyData.openaiKey,
                anthropic_key: keyData.anthropicKey,
              };
              res = await updateDoc(userRef, apiKeyData);

              returnData = {
                data: "success",
              };
            } else {
              returnData = {
                error: "no user doc id",
              };
            }

            return returnData;
          } catch (err) {
            console.error(err);
            return {
              error: err,
            };
          }
        },
        getAdminPlanData: async () => {
          try {
            // let userDoc = {}
            if (adminUserData && adminUserData.userDoc && adminUserData.userDoc.planid) {
              // 1: Get UserDoc
              const planDoc = await getAdminPlanDoc(adminUserData.userDoc.planid);
              if (planDoc && planDoc.error === undefined) {
                // user doc found
                setAdminPlanData({
                  ...adminPlanData,
                  data: planDoc,
                  loaded: true,
                });
              } else {
                // user doc found
                setAdminPlanData({
                  ...adminPlanData,
                  error: planDoc.error,
                  loaded: true,
                });
              }
            } else {
              // no doc found
              setAdminPlanData({
                ...adminPlanData,
                loaded: true,
                error: {
                  val: "no plan id found",
                },
              });
            }
          } catch (err) {
            console.error(err);
            setAdminPlanData({ ...adminPlanData, loaded: true, error: err });
          }
        },
        saveAdminCustomer: async (row) => {
          try {
            // wait X milliseconds
            await delay(1000);
            // const currentUser = Firebase.auth().currentUser

            // const customersRef = collection(db, 'customers') // collectionRef
            // const customerRef = doc(customersRef) // docRef
            // const id = customerRef.id // a docRef has an id property
            // const customerData = { id, ...itemData } // insert the id among the data
            // res = await setDoc(customerRef, customerData) // create the document

            let res = {};

            let id = "";
            if (row && row.id && row.id !== "") {
              id = row.id;
            }

            if (id !== "") {
              const customerRef = doc(db, "customers", id);
              res = await setDoc(customerRef, row); // create the document

              // update ref
              //

              // let row = _.cloneDeep(customerResultData)

              // customersDataOrigObj.current = {customersDataOrigObject}
              // let customersDataOrigObject = _.cloneDeep(customersDataOrig)
              // customersDataOrigObject[id] = row

              // setCustomersDataOrig(customersDataOrigObject)
              // setCustomersDataOrig({
              //   ...customersDataOrig,
              //   [id]: row
              // })

              return {
                data: row,
              };
            } else {
              return {
                error: "no customer doc id found",
              };
            }
          } catch (err) {
            console.error(err);
            return {
              error: err,
            };
          }
        },
        saveAdminUserMemberships: async (docId, data) => {
          try {
            // wait X milliseconds
            // await delay(1000)

            let res = {};
            // update user doc
            // const usersRef = collection(db, 'users') // collectionRef
            // const docRef = doc(db, "countries", "my.custom.id@gmail.com" );

            const userRef = doc(db, "users", docId); // docRef
            // const userRef = doc(db, "users", docId);
            const membershipData = { memberships: data };
            res = await updateDoc(userRef, membershipData);

            // res = await userRef.set({
            //   memberships: data,
            // })

            const returnData = {
              data: "success",
            };
            return returnData;
          } catch (err) {
            console.error(err);
            return {
              error: err,
            };
          }
        },
        createMembershipId: (membershipDataUpdated) => {
          let uuid = "";
          let useId = false;
          while (useId === false) {
            // generate id
            uuid = nanoid(10);
            // check if id exists in membershipDataUpdated
            const idFound = _.findIndex(membershipDataUpdated, { membershipid: uuid });

            if (idFound === -1) {
              useId = true;
              break;
            }
          }

          return uuid;
        },
        getUserToken: async () => {
          try {
            // const currentUser = Firebase.auth().currentUser
            const currentUser = auth.currentUser;
            const token = await currentUser.getIdToken();

            return token;
            // if (currentUser) {
            //     return currentUser
            // } else {
            //     const newuser = await signInWithEmailAndPassword(auth, email, locationId)
            //     return newuser
            // }
          } catch (err) {
            console.error(err);
          }
        },
        compareAiSoftwareConfigSettings: async () => {
          try {
            // check if aisoftwareconfigid is different
            const test = adminUserDocRef.current;
            const test1 = adminUserData.userDoc;
            let updateAiConfigSettings = false;

            // does the userDoc have a aisoftwareconfig map?
            let adminUserAiSoftwareConfig = null;
            if (adminUserData && adminUserData.userDoc && adminUserData.userDoc.aisoftwareconfig) {
              adminUserAiSoftwareConfig = adminUserData.userDoc.aisoftwareconfig;
            }

            if (adminUserAiSoftwareConfig) {
              // does the ref map have a aisoftwareconfig?
              let adminUserAiSoftwareConfigRef = null;
              if (adminUserDocRef.current && adminUserDocRef.current.aisoftwareconfig) {
                adminUserAiSoftwareConfigRef = adminUserDocRef.current.aisoftwareconfig;
              }

              // compare adminUserAiSoftwareConfigRef with adminUserAiSoftwareConfig
              const isEqual = _.isEqual(adminUserAiSoftwareConfigRef, adminUserAiSoftwareConfig);
              if (!isEqual) {
                updateAiConfigSettings = true;
              }
            }

            if (updateAiConfigSettings === false) {
              // check if aisoftwareconfigid is different
              // does the userDoc have a aisoftwareconfigid?
              let adminUserAiSoftwareConfigId = null;
              if (adminUserData && adminUserData.userDoc && adminUserData.userDoc.aisoftwareconfigid) {
                adminUserAiSoftwareConfigId = adminUserData.userDoc.aisoftwareconfigid;
              }
              let adminUserAiSoftwareConfigIdRef = null;
              if (adminUserDocRef.current && adminUserDocRef.current.aisoftwareconfigid) {
                adminUserAiSoftwareConfigIdRef = adminUserDocRef.current.aisoftwareconfigid;
              }

              // compare adminUserAiSoftwareConfigIdRef with adminUserAiSoftwareConfigId
              if (adminUserAiSoftwareConfigIdRef !== adminUserAiSoftwareConfigId) {
                updateAiConfigSettings = true;
              }
            }

            // if true then aiconfig settings need to be updated
            if (updateAiConfigSettings === true) {
              const aiConfigRes = await updateAiSoftwareConfigAndCategories(adminUserData.userDoc);
              // update ref
              adminUserDocRef.current = adminUserData.userDoc;
            }
          } catch (err) {
            console.error(err);
          }
        },



        getAiPresetAgentsDocs: async () => {
          return new Promise((resolve, reject) => {
            try {
              let userId = "";
              if (user && user.uid && user.uid !== "") {
                userId = user.uid;
              }

              if (userId === "") {
                // no locationid
                reject({
                  error: "no userId",
                });
              }

              if (userId !== "") {
                const q = query(
                  collection(db, "aipresetagents"),
                  where("userid", "==", userId),
                  // where("aisettingid", "==", aisettingid),
                  orderBy("name", "asc")
                );
                const unsub = onSnapshot(q, (querySnapshot) => {
                  const aipresetagentsDocsArr = [];


                  querySnapshot.forEach((doc) => {
                    const docData = doc.data();
                    // let aiagentid = '';
                    // if (docData?.aiagent?.aiagentid) {
                    //   aiagentid = aiagent.aiagentid;
                    // }

                    const smallData = {
                      aipresetagentid: docData.aipresetagentid,
                      aiagentid: docData.aiagentid,
                      name: docData.name,
                      userid: docData.userid,
                      automationlink: docData.automationlink,
                      presetref: docData.presetref,
                    };

                    aipresetagentsDocsArr.push(smallData);
                  });

                  setAdminAiPresetAgentsDocs(aipresetagentsDocsArr);

                  resolve({
                    data: aipresetagentsDocsArr,
                  });
                });
              }
            } catch (err) {
              console.error(err);
              reject({
                error: err,
              });
            }
          });
        },
        saveAdminAiCategoryAgentPresetAgentsData: async (categorySelected, categoryPresets, categoryPresetsIds) => {
          let returnData = {};
          try {
            let newCategoryData = { ...categorySelected };

            // add cate
            const test2 = categorySelected;
            const test3 = categoryPresets;

            let aiCategoryAgentId = "";
            if (categorySelected && categorySelected.aicategoryagentid && categorySelected.aicategoryagentid !== "") {
              aiCategoryAgentId = categorySelected.aicategoryagentid;
            }
            let res = {};

            if (aiCategoryAgentId !== "") {
              // update user doc
              // let data
              const categoryRef = doc(db, "aicategoryagents", aiCategoryAgentId); // docRef
              // const userRef = doc(db, "users", docId);
              const presetsData = {
                aipresetagents: categoryPresets,
                aipresetagentsids: categoryPresetsIds,
              };
              res = await updateDoc(categoryRef, presetsData);

              returnData.data = categoryPresets;
            } else {
              returnData.error = {
                val: "no aiCategoryAgentId",
              };
            }

            return returnData;
          } catch (err) {
            console.error(err);
            returnData.error = err;

            return returnData;
          }
        },
        saveAdminAiPresetAgentName: async (id, name) => {
          let returnData = {};
          try {
            // update the aipreset doc
            // Get the document reference
            const docRef = doc(db, "aipresetagents", id);

            // Update the document
            const result = await updateDoc(docRef, {
              name: name,
            });

            // find all aicategoryagents docs that have this aipresetagentid in aipresetagentsids array
            const q = query(collection(db, "aicategoryagents"), where("aipresetagentsids", "array-contains", id));
            const snapshot = await getDocs(q);

            let aiCategoryAgentsDocs = [];
            snapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              // if (clog()) console.log(doc.id, " => ", doc.data());
              const docData = doc.data();
              const item = {
                aicategoryagentid: docData.aicategoryagentid,
                aipresetagents: docData.aipresetagents,
              };
              aiCategoryAgentsDocs.push(item);
            });

            if (aiCategoryAgentsDocs.length > 0) {
              for (const cDoc of aiCategoryAgentsDocs) {
                let res = {};
                let updatedPresets = [...cDoc.aipresetagents];
                // find aipresetagentid in aipresets array
                const idx = _.findIndex(updatedPresets, { aipresetagentid: id });
                if (idx !== -1) {
                  // update name
                  updatedPresets[idx].name = name;
                  await delay(50);
                  // const aiCategoryId = cDoc.aicategoryagentid;
                  const pRef = doc(db, "aicategoryagents", cDoc.aicategoryagentid); // docRef
                  res = await updateDoc(pRef, {
                    aipresetagents: updatedPresets,
                  });
                }
              }
            }

            // search adminAiCategoriesDocs array
            //            for (const cDoc of adminAiCategoriesDocs) {
            //              let res = {};
            //              let updatedPresets = [];
            //              let aiCategoryId = "";
            //              if (cDoc.aicategoryid && cDoc.aicategoryid !== "") {
            //                aiCategoryId = cDoc.aicategoryid;
            //              }
            //
            //              if (cDoc.aipresets && cDoc.aipresets.length > 0) {
            //                updatedPresets = [...cDoc.aipresets];
            //                let presetUpdated = false;
            //                if (updatedPresets.length > 0) {
            //                  for (const preset of updatedPresets) {
            //                    // does aipresetid match id
            //                    if (preset.aipresetid && preset.aipresetid !== "" && preset.aipresetid === id) {
            //                      // await delay(50);
            //                      // update name
            //                      preset.name = name;
            //                      presetUpdated = true;
            //                    }
            //                  }
            //                }
            //                // if preset updated then update the doc
            //                if (presetUpdated && aiCategoryId !== "") {
            //                  await delay(50);
            //                  // const aiCategoryId = cDoc.aicategoryid;
            //                  const pRef = doc(db, "aicategories", aiCategoryId); // docRef
            //                  res = await updateDoc(pRef, {
            //                    aipresets: updatedPresets,
            //                  });
            //                }
            //              }
            //            }

            returnData.data = {
              aipresetagentid: id,
              name: name,
            };
            return returnData;
          } catch (err) {
            console.error(err);
            returnData.error = err;
            return returnData;
          }
        },
        saveAdminAiCategoryAgentName: async (id, name) => {
          let returnData = {};
          try {
            // update the aicategory doc
            // Get the document reference
            const docRef = doc(db, "aicategoryagents", id);
            //
            // Update the aicategoryagents document
            const result = await updateDoc(docRef, {
              name: name,
            });

            // BT what other docs do we need to update?

            returnData.data = {
              aicategoryagentid: id,
              name: name,
            };
            return returnData;
          } catch (err) {
            console.error(err);
            returnData.error = err;
            return returnData;
          }
        },
        saveAdminAiCategoryAgentNew: async (name) => {
          let returnData = {};
          try {
            let res;
            const test = name;

            // create
            const cRefs = collection(db, "aicategoryagents"); // collectionRef
            const cRef = doc(cRefs); // docRef
            const id = cRef.id; // a docRef has an id property

            const data = {};

            data.name = name;
            data.aicategoryagentid = id;
            data.aipresetagents = [];
            data.aipresetagentsids = [];

            // add created date
            const date = generateFirestoreDate();
            data.createdon = date;

            // add updated date
            data.updatedon = date;

            // add userid
            data.userid = user.uid;

            // add superadmin false
            data.superadmin = false;

            res = await setDoc(cRef, data); // create the document

            // remove date related data
            delete data.createdon;
            delete data.updatedon;

            // BT what other docs do we need to update?

            returnData.data = data;

            return returnData;
          } catch (err) {
            console.error(err);
            returnData.error = err;
            return returnData;
          }
        },
        saveAdminAiCategoryAgentSuperAdminNew: async (name) => {
          let returnData = {};
          try {
            let res;
            const test = name;

            // create
            const cRefs = collection(db, "aicategoryagents"); // collectionRef
            const cRef = doc(cRefs); // docRef
            const id = cRef.id; // a docRef has an id property

            const data = {};

            data.name = name;
            data.aicategoryagentid = id;
            data.aipresetagents = [];
            data.aipresetagentsids = [];

            // add created date
            const date = generateFirestoreDate();
            data.createdon = date;

            // add updated date
            data.updatedon = date;

            // add userid
            data.userid = user.uid;

            // add superadmin false
            data.superadmin = true;

            res = await setDoc(cRef, data); // create the document

            // remove date related data
            delete data.createdon;
            delete data.updatedon;

            // BT what other docs do we need to update?

            returnData.data = data;

            return returnData;
          } catch (err) {
            console.error(err);
            returnData.error = err;
            return returnData;
          }
        },
        deleteAdminAiCategoryAgent: async (id) => {
          let returnData = {};
          try {
            let res;

            // delete
            const docRef = doc(db, "aicategoryagents", id);

            res = await deleteDoc(docRef); // create the document

            // BT what other docs do we need to update?

            returnData.data = {
              aicategoryagentid: id,
            };

            return returnData;
          } catch (err) {
            console.error(err);
            returnData.error = err;
            return returnData;
          }
        },
        getAdminAiCategoryAgentsSuperAdminDoc: async (categoryagentId) => {
          return new Promise((resolve, reject) => {
            try {
              if (categoryagentId === "") {
                // no locationid
                reject({
                  error: "no categoryagentId",
                });
              }

              if (categoryagentId !== "") {
                const q = query(
                  collection(db, "aicategoryagents"),
                  where("aicategoryagentid", "==", categoryagentId),
                  where("superadmin", "==", true),
                  // where("aisettingid", "==", aisettingid),
                  orderBy("name", "asc")
                );
                const unsub = onSnapshot(q, (querySnapshot) => {
                  let aiPresetAgentsArr = [];

                  querySnapshot.forEach((doc) => {
                    const docData = doc.data();
                    if (docData && docData.aipresetagents) {
                      aiPresetAgentsArr = docData.aipresetagents;
                    }
                  });
                  setAdminAiPresetAgentsSuperAdminDocs(aiPresetAgentsArr);

                  resolve({
                    data: aiPresetAgentsArr,
                  });
                });
              }
            } catch (err) {
              console.error(err);
              reject({
                error: err,
              });
            }
          });
        },


        getAiPresetsDocs: async () => {
          return new Promise((resolve, reject) => {
            try {
              let userId = "";
              if (user && user.uid && user.uid !== "") {
                userId = user.uid;
              }

              if (userId === "") {
                // no locationid
                reject({
                  error: "no userId",
                });
              }

              if (userId !== "") {
                const q = query(
                  collection(db, "aipresets"),
                  where("userid", "==", userId),
                  // where("aisettingid", "==", aisettingid),
                  orderBy("name", "asc")
                );
                const unsub = onSnapshot(q, (querySnapshot) => {
                  const aipresetsDocsArr = [];

                  querySnapshot.forEach((doc) => {
                    const docData = doc.data();

                    const smallData = {
                      aipresetid: docData.aipresetid,
                      name: docData.name,
                      userid: docData.userid,
                    };

                    aipresetsDocsArr.push(smallData);
                  });

                  setAdminAiPresetsDocs(aipresetsDocsArr);

                  resolve({
                    data: aipresetsDocsArr,
                  });
                });
              }
            } catch (err) {
              console.error(err);
              reject({
                error: err,
              });
            }
          });
        },
        saveAdminAiCategoryPresetsData: async (categorySelected, categoryPresets, categoryPresetsIds) => {
          let returnData = {};
          try {
            let newCategoryData = { ...categorySelected };

            // add cate
            const test2 = categorySelected;
            const test3 = categoryPresets;

            let aiCategoryId = "";
            if (categorySelected && categorySelected.aicategoryid && categorySelected.aicategoryid !== "") {
              aiCategoryId = categorySelected.aicategoryid;
            }
            let res = {};

            if (aiCategoryId !== "") {
              // update user doc
              // let data
              const categoryRef = doc(db, "aicategories", aiCategoryId); // docRef
              // const userRef = doc(db, "users", docId);
              const presetsData = {
                aipresets: categoryPresets,
                aipresetsids: categoryPresetsIds,
              };
              res = await updateDoc(categoryRef, presetsData);

              returnData.data = categoryPresets;
            } else {
              returnData.error = {
                val: "no aiCategoryId",
              };
            }

            return returnData;
          } catch (err) {
            console.error(err);
            returnData.error = err;

            return returnData;
          }
        },
        saveAdminAiPresetName: async (id, name) => {
          let returnData = {};
          try {
            // update the aipreset doc
            // Get the document reference
            const docRef = doc(db, "aipresets", id);

            // Update the document
            const result = await updateDoc(docRef, {
              name: name,
            });

            // find all aicategories docs that have this aipresetid in aipresetsids array
            const q = query(collection(db, "aicategories"), where("aipresetsids", "array-contains", id));
            const snapshot = await getDocs(q);

            let aiCategoriesDocs = [];
            snapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              // if (clog()) console.log(doc.id, " => ", doc.data());
              const docData = doc.data();
              const item = {
                aicategoryid: docData.aicategoryid,
                aipresets: docData.aipresets,
              };
              aiCategoriesDocs.push(item);
            });

            if (aiCategoriesDocs.length > 0) {
              for (const cDoc of aiCategoriesDocs) {
                let res = {};
                let updatedPresets = [...cDoc.aipresets];
                // find aipresetid in aipresets array
                const idx = _.findIndex(updatedPresets, { aipresetid: id });
                if (idx !== -1) {
                  // update name
                  updatedPresets[idx].name = name;
                  await delay(50);
                  // const aiCategoryId = cDoc.aicategoryid;
                  const pRef = doc(db, "aicategories", cDoc.aicategoryid); // docRef
                  res = await updateDoc(pRef, {
                    aipresets: updatedPresets,
                  });
                }
              }
            }

            // search adminAiCategoriesDocs array
            //            for (const cDoc of adminAiCategoriesDocs) {
            //              let res = {};
            //              let updatedPresets = [];
            //              let aiCategoryId = "";
            //              if (cDoc.aicategoryid && cDoc.aicategoryid !== "") {
            //                aiCategoryId = cDoc.aicategoryid;
            //              }
            //
            //              if (cDoc.aipresets && cDoc.aipresets.length > 0) {
            //                updatedPresets = [...cDoc.aipresets];
            //                let presetUpdated = false;
            //                if (updatedPresets.length > 0) {
            //                  for (const preset of updatedPresets) {
            //                    // does aipresetid match id
            //                    if (preset.aipresetid && preset.aipresetid !== "" && preset.aipresetid === id) {
            //                      // await delay(50);
            //                      // update name
            //                      preset.name = name;
            //                      presetUpdated = true;
            //                    }
            //                  }
            //                }
            //                // if preset updated then update the doc
            //                if (presetUpdated && aiCategoryId !== "") {
            //                  await delay(50);
            //                  // const aiCategoryId = cDoc.aicategoryid;
            //                  const pRef = doc(db, "aicategories", aiCategoryId); // docRef
            //                  res = await updateDoc(pRef, {
            //                    aipresets: updatedPresets,
            //                  });
            //                }
            //              }
            //            }

            returnData.data = {
              aipresetid: id,
              name: name,
            };
            return returnData;
          } catch (err) {
            console.error(err);
            returnData.error = err;
            return returnData;
          }
        },
        saveAdminAiCategoryName: async (id, name) => {
          let returnData = {};
          try {
            // update the aicategory doc
            // Get the document reference
            const docRef = doc(db, "aicategories", id);
            //
            // Update the aicategories document
            const result = await updateDoc(docRef, {
              name: name,
            });

            // BT what other docs do we need to update?

            returnData.data = {
              aicategoryid: id,
              name: name,
            };
            return returnData;
          } catch (err) {
            console.error(err);
            returnData.error = err;
            return returnData;
          }
        },
        saveAdminAiCategoryNew: async (name) => {
          let returnData = {};
          try {
            let res;
            const test = name;

            // create
            const cRefs = collection(db, "aicategories"); // collectionRef
            const cRef = doc(cRefs); // docRef
            const id = cRef.id; // a docRef has an id property

            const data = {};

            data.name = name;
            data.aicategoryid = id;
            data.aipresets = [];
            data.aipresetsids = [];

            // add created date
            const date = generateFirestoreDate();
            data.createdon = date;

            // add updated date
            data.updatedon = date;

            // add userid
            data.userid = user.uid;

            // add superadmin false
            data.superadmin = false;

            res = await setDoc(cRef, data); // create the document

            // remove date related data
            delete data.createdon;
            delete data.updatedon;

            // BT what other docs do we need to update?

            returnData.data = data;

            return returnData;
          } catch (err) {
            console.error(err);
            returnData.error = err;
            return returnData;
          }
        },
        saveAdminAiCategorySuperAdminNew: async (name) => {
          let returnData = {};
          try {
            let res;
            const test = name;

            // create
            const cRefs = collection(db, "aicategories"); // collectionRef
            const cRef = doc(cRefs); // docRef
            const id = cRef.id; // a docRef has an id property

            const data = {};

            data.name = name;
            data.aicategoryid = id;
            data.aipresets = [];
            data.aipresetsids = [];

            // add created date
            const date = generateFirestoreDate();
            data.createdon = date;

            // add updated date
            data.updatedon = date;

            // add userid
            data.userid = user.uid;

            // add superadmin false
            data.superadmin = true;

            res = await setDoc(cRef, data); // create the document

            // remove date related data
            delete data.createdon;
            delete data.updatedon;

            // BT what other docs do we need to update?

            returnData.data = data;

            return returnData;
          } catch (err) {
            console.error(err);
            returnData.error = err;
            return returnData;
          }
        },
        deleteAdminAiCategory: async (id) => {
          let returnData = {};
          try {
            let res;

            // delete
            const docRef = doc(db, "aicategories", id);

            res = await deleteDoc(docRef); // create the document

            // BT what other docs do we need to update?

            returnData.data = {
              aicategoryid: id,
            };

            return returnData;
          } catch (err) {
            console.error(err);
            returnData.error = err;
            return returnData;
          }
        },
        getAdminAiCategoriesSuperAdminDoc: async (categoryId) => {
          return new Promise((resolve, reject) => {
            try {
              if (categoryId === "") {
                // no locationid
                reject({
                  error: "no categoryId",
                });
              }

              if (categoryId !== "") {
                const q = query(
                  collection(db, "aicategories"),
                  where("aicategoryid", "==", categoryId),
                  where("superadmin", "==", true),
                  // where("aisettingid", "==", aisettingid),
                  orderBy("name", "asc")
                );
                const unsub = onSnapshot(q, (querySnapshot) => {
                  let aiPresetsArr = [];

                  querySnapshot.forEach((doc) => {
                    const docData = doc.data();
                    if (docData && docData.aipresets) {
                      aiPresetsArr = docData.aipresets;
                    }
                  });

                  setAdminAiPresetsSuperAdminDocs(aiPresetsArr);

                  resolve({
                    data: aiPresetsArr,
                  });
                });
              }
            } catch (err) {
              console.error(err);
              reject({
                error: err,
              });
            }
          });
        },
      }}
    >
      {children}
    </AdminAuthContext.Provider>
  );
};

// export default AdminAuthProvider;
