import React, { createContext, useState, useRef, useContext } from "react";

// import { Link } from "react-router-dom";

import { useTheme } from "@mui/material/styles";

// material-ui
import { Grid, Stack, Typography, Box, Container, Button, Divider, Link, useMediaQuery } from "@mui/material";

// project import
// import useAuth from 'hooks/useAuth';
import AuthWrapper from "../sections/auth/AuthWrapper";

import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebase } from "../code/firebase";

import AuthLogin from "../sections/auth/auth-forms/AuthLogin";
import AuthForgotPassword from "../sections/auth/auth-forms/AuthForgotPassword";

import LoadingPane from "./LoadingPane";
import AnimateButton from "../components/@extended/AnimateButton";

import {setd, debug, setc, clog} from "../helpers/HelperFunctions";
import { AdminAuthContext } from "../helpers/AdminAuthProvider";

// ================================|| LOGIN ||================================ //

const auth = getAuth(firebase);

const AdminLoginContainer = () => {
  const theme = useTheme();

  // const { isLoggedIn } = useAuth();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  if (clog()) console.log("AdminLoginContainer");
  const { logout } = useContext(AdminAuthContext);

  const [user, loadingUser, errorUser] = useAuthState(auth);



  const { adminShowForgotPassword, setAdminShowForgotPassword } = useContext(AdminAuthContext);
  const { adminShowCheckEmail, setAdminShowCheckEmail } = useContext(AdminAuthContext);

  const handleLogout = async () => {
    await logout();
  };

  const logoutButton = () => {
    if (user) {
      if (clog()) console.log("UID:", user.uid);
      return (
        <Grid item xs={12}>
          {
            // <Typography variant="p">Email: {user.email}</Typography>
            // <Typography variant="p">UID: {user.uid}</Typography>
          }
          {
            // <Button
            //   disableElevation
            //   // disabled={isSubmitting}
            //   fullWidth
            //   size="large"
            //   type="submit"
            //   variant="contained"
            //   color="primary"
            //   onClick={() => handleLogout()}
            // >
            //   Logout
            // </Button>
          }
        </Grid>
      );
    }
  };

  const handleOnClickBackToLogin = () => {
    setAdminShowForgotPassword(false);
    setAdminShowCheckEmail(false);
  };

  if (loadingUser) {
    return (
      <Container component="main" maxWidth="lg">
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#fff",
            border: `1px solid ${theme.palette.divider}`,
            padding: 4,
          }}
        >
          <LoadingPane
            text={"Loading..."}
            showSpinner={true}
            // progress={50}
          />
        </Box>
      </Container>
    );
  }

  if (adminShowForgotPassword && adminShowCheckEmail) {
    return (
      <AuthWrapper>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
              <Typography variant="h3">Hi, Check Your Mail</Typography>
              <Typography color="secondary" sx={{ mb: 0.5, mt: 1.25 }}>
                We have sent password recovery instructions to your email.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <AnimateButton>
              <Button
                // component={Link}
                // to={isLoggedIn ? '/auth/login' : '/login'}
                // to={user ? "/admin" : "/admin"}
                onClick={() => handleOnClickBackToLogin()}
                disableElevation
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="primary"
              >
                Sign in
              </Button>
            </AnimateButton>
          </Grid>
          {
            // <Grid item xs={12}>
            //   <Divider>
            //     <Typography variant={matchDownSM ? "subtitle1" : "h5"}>Sign up with</Typography>
            //   </Divider>
            // </Grid>
            // <Grid item xs={12}>
            //   <FirebaseSocial />
            // </Grid>
          }
        </Grid>
      </AuthWrapper>
    );
  }

  if (adminShowForgotPassword) {
    return (
      <AuthWrapper>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
              <Typography variant="h3">Forgot Password</Typography>
              <Link
                variant="h6"
                // component={RouterLink}
                // to={user ? '/forgotpassword' : '/forgotpassword'}
                color="text.primary"
                onClick={() => handleOnClickBackToLogin()}
                sx={{
                  cursor: "pointer",
                  color: theme.palette.primary.main,
                }}
              >
                Back to Login
              </Link>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <AuthForgotPassword />
          </Grid>
        </Grid>
      </AuthWrapper>
    );
  }

  return (
    <AuthWrapper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
            <Typography variant="h3">Login</Typography>
            {
              //   <Typography component={Link} to={user ? "/auth/register" : "/register"} variant="body1" sx={{ textDecoration: "none" }} color="primary">
              //   Don&apos;t have an account?
              // </Typography>
            }
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <AuthLogin />
        </Grid>
        {logoutButton()}
      </Grid>
    </AuthWrapper>
  );
};

export default AdminLoginContainer;
