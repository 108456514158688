import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import PropTypes from "prop-types";

import {
  getFirestore,
  collection,
  query,
  where,
  // orderBy
} from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { firebase } from "../code/firebase";
import { processKeywordsUserData } from "../code/ecomacy";

import { RemoteConfigAuthContext } from "../helpers/RemoteConfigAuthProvider";
import { CustomerAuthContext } from "../helpers/CustomerAuthProvider";

import { AiAuthContext } from "../helpers/AiAuthProvider";
import { setd, debug, setc, clog } from "../helpers/HelperFunctions";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import _ from "lodash";

import { useTheme, styled } from "@mui/material/styles";

import {
  Box,
  //Tab,
  Tabs,
  Typography,
  Button,
  IconButton,
  Grid,
  Paper,
  Slider,
  TextField,
  Menu,
  MenuItem,
  ListSubheader,
  Snackbar,
  Alert,
  Collapse,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

// ContentCopy
// FileCopy

import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import DescriptionIcon from "@mui/icons-material/DescriptionOutlined";
import VpnKeyIcon from "@mui/icons-material/VpnKeyOutlined";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/SaveOutlined";
import AddIcon from "@mui/icons-material/AddOutlined";
import RemoveIcon from "@mui/icons-material/RemoveOutlined";
import EditIcon from "@mui/icons-material/EditOutlined";
import ClearIcon from "@mui/icons-material/ClearOutlined";
import LinkIcon from "@mui/icons-material/LinkOutlined";
import CircleIcon from "@mui/icons-material/CircleOutlined";
import DoneIcon from "@mui/icons-material/DoneOutlined";
import CloseIcon from "@mui/icons-material/Close";
import CodeIcon from "@mui/icons-material/CodeOutlined";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import ConversationsIcon from '@mui/icons-material/SmsOutlined';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

// import ConversationsIcon from '@mui/icons-material/QuestionAnswerOutlined';
// import LanguageIcon from '@mui/icons-material/LanguageOutlined';
import Container from "@mui/material/Container";
import GridKeywordTimelinePane from "./GridKeywordTimelinePane";
import LoadingPane from "./LoadingPane";

import AiAgentVariablesDialog from "./AiAgentVariablesDialog";
import AiWebCodeDialog from "./AiWebCodeDialog";
import AiCustomerSettingsDialog from "./AiCustomerSettingsDialog";
import AiPresetAgentsDialog from "./AiPresetAgentsDialog";



import { re } from "mathjs";

const BorderTooltip = styled(({ className, ...props }) => <Tooltip {...props} title={props.title} arrow classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white,
      // boxShadow: theme.shadows[1],
    },
    [`& .${tooltipClasses.arrow}::before`]: {
      color: theme.palette.common.white,
      // boxShadow: theme.shadows[1],

      border: `1px solid ${theme.palette.primary.main}`,
      boxSizing: "border-box",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      // boxShadow: theme.shadows[1],
      fontSize: "14px",
      border: `1px solid ${theme.palette.primary.main}`,
    },
  })
);

const AiSettingsPanel = (props) => {
  const theme = useTheme();
  const { userData } = props;


  const { rc } = useContext(RemoteConfigAuthContext);

  const { adminStatus, setAdminStatus } = useContext(CustomerAuthContext);

  const { testVal, setTestVal } = useContext(AiAuthContext);

  const { aiAgentsDocs, setAiAgentsDocs } = useContext(AiAuthContext);
  const { aiAgentsSelected, setAiAgentsSelected } = useContext(AiAuthContext);
  const { aiAgentsSelectedRef } = useContext(AiAuthContext);

  const [aiAgentsDocsRefresh, setAiAgentsDocsRefresh] = useState(null);
  const [aiAgentsSelectedRefresh, setAiAgentsSelectedRefresh] = useState(null);

  const { aiAgentsIsEditing, setAiAgentsIsEditing } = useContext(AiAuthContext);
  const { aiAgentsIsRemoving, setAiAgentsIsRemoving } = useContext(AiAuthContext);
  // const { aiAgentsIsSavingPresetAgent, setAiAgentsIsSavingPresetAgent } = useContext(AiAuthContext);

  // const { saveAiPresetAgent } = useContext(AiAuthContext);
  // const [aiPresetAgentName, setAiPresetAgentName] = useState("");



  const [aiAgentsName, setAiAgentsName] = useState("");
  const { aiAgentsAutomationLinkItems } = useContext(AiAuthContext);
  const [aiAgentsAutomationLink, setAiAgentsAutomationLink] = useState("");
  const [aiAgentsAutomationLinkInUse, setAiAgentsAutomationLinkInUse] = useState(false);
  const [aiAgentsAutomationLinkIllegalChars, setAiAgentsAutomationLinkIllegalChars] = useState(false);
  const { saveAiAgentsName } = useContext(AiAuthContext);
  const { saveAiAgentsAutomationLink } = useContext(AiAuthContext);
  const [aiAgentsNewAiPreset, setAiAgentsNewAiPreset] = useState(null);
  const [aiAgentsNameSaving, setAiAgentsNameSaving] = useState(false);
  const { saveAiAgentsNew } = useContext(AiAuthContext);

  const [aiAgentFullDelete, setAiAgentFullDelete] = useState(false);
  const { removeAiAgent } = useContext(AiAuthContext);
  // const [aiAgentsRemoving, setAiAgentsRemoving] = useState(false);

  const { aiAgentShowLiveConversations, setAiAgentShowLiveConversations } = useContext(AiAuthContext);
  const { aiLiveConversationsDocs, setAiLiveConversationsDocs } = useContext(AiAuthContext);
  const { getAiLiveConversationsDocs } = useContext(AiAuthContext);


  const { aiCategoriesDoc, setAiCategoriesDoc } = useContext(AiAuthContext);

  const { aiSettingsDocs, setAiSettingsDocs } = useContext(AiAuthContext);
  const { aiSettingsSelected, setAiSettingsSelected } = useContext(AiAuthContext);
  const { aiSettingsSelectedRefresh } = useContext(AiAuthContext);

  const [aiSettingsDocsRefresh, setAiSettingsDocsRefresh] = useState("");
  const { getAiSettingsActive } = useContext(AiAuthContext);

  const { aiConversationsDocsRefresh, setAiConversationsDocsRefresh } = useContext(AiAuthContext);
  const { aiConversationsIsSaving, setAiConversationsIsSaving } = useContext(AiAuthContext);

  const { getAiSettings } = useContext(AiAuthContext);

  const { aiSettingsData, setAiSettingsData } = useContext(AiAuthContext);
  const { aiSettingsDataRef } = useContext(AiAuthContext);
  const { aiSettingsDataUpdated, setAiSettingsDataUpdated } = useContext(AiAuthContext);
  const { aiSettingsIndexIdSelected, setAiSettingsIndexIdSelected } = useContext(AiAuthContext);

  const { aiSettingsIsEditing, setAiSettingsIsEditing } = useContext(AiAuthContext);
  const [aiSettingsName, setAiSettingsName] = useState("");
  const { saveAiSettingsName } = useContext(AiAuthContext);
  const [aiSettingsNewAiPreset, setAiSettingsNewAiPreset] = useState(null);
  const [aiSettingsNameSaving, setAiSettingsNameSaving] = useState(false);

  const [aiSettingsFullDelete, setAiSettingsFullDelete] = useState(false);
  const { removeAiSettings } = useContext(AiAuthContext);
  const [aiSettingsRemoving, setAiSettingsRemoving] = useState(false);

  const { duplicateAiSettings } = useContext(AiAuthContext);
  const { saveAiSettings } = useContext(AiAuthContext);
  const { aiSettingsSaving, setAiSettingsSaving } = useContext(AiAuthContext);
  // const [aiSettingsSaving, setAiSettingsSaving] = useState(false);

  const { prepareAiConversationSmallData } = useContext(AiAuthContext);

  const { aiConversationsSelected, setAiConversationsSelected } = useContext(AiAuthContext);
  const { aiConversationFetchData, setAiConversationFetchData } = useContext(AiAuthContext);

  const { aiPromptVersionSelected, setAiPromptVersionSelected } = useContext(AiAuthContext);
  const { aiPromptVersionReset, setAiPromptVersionReset } = useContext(AiAuthContext);

  const { getDefaultAiPreset } = useContext(AiAuthContext);
  const { getAiPreset } = useContext(AiAuthContext);
  const [aiPresetSelected, setAiPresetSelected] = useState("");
  // holds current selected settings while checking out presets
  const aiSettingsDataUpdatedTemp = useRef(null);
  const aiConversationTemp = useRef(null);
  const { saveAiSettingsNewFromPreset } = useContext(AiAuthContext);
  const [aiSettingsNewSaving, setAiSettingsNewSaving] = useState(false);

  const { aiModelReset, setAiModelReset } = useContext(AiAuthContext);

  const { aiShowDescriptionPanel, setAiShowDescriptionPanel } = useContext(AiAuthContext);

  const { aiModelSelectedRefresh, setAiModelSelectedRefresh } = useContext(AiAuthContext);
  const { aiStrategySelectedRefresh, setAiStrategySelectedRefresh } = useContext(AiAuthContext);

  const [init, setInit] = useState(true);

  const [initAiAgentSelected, setInitAiAgentSelected] = useState(true);

  const [model, setModel] = useState({});

  const { aiConversationsIsEditing, setAiConversationsIsEditing } = useContext(AiAuthContext);
  const { aiConversationsIsRemoving, setAiConversationsIsRemoving } = useContext(AiAuthContext);

  const { aiConversation, setAiConversation } = useContext(AiAuthContext);

  const [showSaveLinkedAlert, setShowSaveLinkedAlert] = useState(false);
  const { showSavePresetAlert, setShowSavePresetAlert } = useContext(AiAuthContext);
  const { showSavePresetAgentAlert, setShowSavePresetAgentAlert } = useContext(AiAuthContext);

  const { showAiPresetAgentsDialog, setShowAiPresetAgentsDialog } = useContext(AiAuthContext);
  const { showAiAgentVariablesDialog, setShowAiAgentVariablesDialog } = useContext(AiAuthContext);

  const { showAiWebCodeDialog, setShowAiWebCodeDialog } = useContext(AiAuthContext);

  const { showAiCustomerSettingsDialog, setShowAiCustomerSettingsDialog } = useContext(AiAuthContext);



  const { getDiffs } = useContext(AiAuthContext);

  // const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9-_]+$/;
  // const ALPHA_NUMERIC_DASH_REGEX = /^[A-Za-z0-9_-]+$/;

  // const ALPHA_NUMERIC_DASH_REGEX = /[a-zA-Z0-9_-]+$/;
  const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9_-]+$/;
  const ALPHA_NUMERIC_DASH_REGEX_EXCLUDE = /[^a-zA-Z0-9_-]/g;

  // const ALPHA_NUMERIC_DASH_REGEX = /^\w\_\-+$/;
  // const ALPHA_NUMERIC_DASH_REGEX = /([^a-z0-9 \_\-_]+)/gi;

  // const [aiAgentAnchorEl, setAiAgentAnchorEl] = React.useState(null);
  // const aiAgentMenuOpen = Boolean(aiAgentAnchorEl);

  // if (clog()) console.log('AiModelPanel aiSettingsData', aiSettingsData);
  /*
    useEffect(() => {

      if (init) {
        // set menu
        // find aiSettingsDataUpdated
        let selectedModel = ''
        if (
          aiSettingsDataUpdated &&
          aiSettingsDataUpdated.data &&
          aiSettingsDataUpdated.data.model &&
          aiSettingsDataUpdated.data.model !== '') {

          selectedModel = aiSettingsDataUpdated.data.model

          var foundIdx = _.findIndex(modelList, { model: aiSettingsDataUpdated.data.model });
          if (foundIdx > -1) {
            const foundModel = modelList[foundIdx]
            setAiModelSelected(foundModel)
            setModel(aiSettingsDataUpdated[selectedModel])
          }
        }

        setInit(false)
      }
      // if (map) {
      //   updateBounds(map)
      //   // handleRecenterMapReset()
      // }
    }, [init])
  */

  useEffect(() => {
    if (init) {

      setInit(false);
      // if (clog()) console.log("AiSettingsPanel - useEffect [init]")

    }
  }, [init]);

  // 1: triggered when new agent is added
  useEffect(() => {
    if (!init && aiAgentsSelectedRefresh !== null) {
      // use aiAgentsSelected aiagentid to find in aiagentsdocs
      var foundIdx = _.findIndex(aiAgentsDocs, { aiagentid: aiAgentsSelectedRefresh.aiagentid });
      if (foundIdx > -1) {
        const foundAgent = aiAgentsDocs[foundIdx];
        // set aiagent with new data
        setAiAgentsSelected(foundAgent);
        aiAgentsSelectedRef.current = foundAgent;

        let settingsDocs = [];
        if (foundAgent && foundAgent.aisettings) {
          settingsDocs = foundAgent.aisettings;
        }
        if (debug()) debugger;
        // const settingsDocsActive = getAiSettingsActive(settingsDocs);

        // set aiSettingsDocs
        setAiSettingsDocs(settingsDocs);
        // reset aiSettingsSelected
        // setAiSettingsSelected(null)
        // need to set blank settings if no settings

        // set first item in settingsDocs as selected
        if (settingsDocs.length > 0) {
          setAiSettingsSelected(settingsDocs[0]);
        } else {
          setAiSettingsSelected(null);
        }
      }
      setAiAgentsSelectedRefresh(null);
    }
  }, [aiAgentsDocs]);

  // 2a: triggered when new setting is added
  useEffect(() => {
    if (!init && aiAgentsDocsRefresh !== null) {
      // use aiAgentsSelected aiagentid to find in aiagentsdocs
      var foundIdx = _.findIndex(aiAgentsDocs, { aiagentid: aiAgentsSelected.aiagentid });
      if (foundIdx > -1) {
        const foundAgent = aiAgentsDocs[foundIdx];
        // set aiagent with new data
        setAiAgentsSelected(foundAgent);
        aiAgentsSelectedRef.current = foundAgent;
      }
      // now set in 1b below
      // setAiAgentsDocsRefresh(null);
    }
  }, [aiAgentsDocs]);

  // 2b: triggered when new setting is added
  useEffect(() => {
    if (!init && aiAgentsDocsRefresh !== null) {
      // use aiAgentsDocsRefresh aisettingsid to find in aiAgentsSelected aisettings array
      if (aiAgentsDocsRefresh.aisettingid && aiAgentsDocsRefresh.aisettingid !== "") {
        var foundIdx = _.findIndex(aiAgentsSelected.aisettings, { aisettingid: aiAgentsDocsRefresh.aisettingid });
        if (foundIdx > -1) {
          const settingsDocs = aiAgentsSelected.aisettings;
          // const settingsDocsActive = getAiSettingsActive(settingsDocs);
          if (debug()) debugger;

          // set aiSettingsDocs
          setAiSettingsDocs(settingsDocs);

          // set found item in settingsDocs as selected
          if (settingsDocs.length > 0) {
            setAiSettingsSelected(settingsDocs[foundIdx]);
          }
        }
      }

      setAiAgentsDocsRefresh(null);
    }
  }, [aiAgentsSelected]);

  /*
   // triggered when aiagents is changed
    useEffect(() => {
      // declare the data fetching function
      const fetchAiLinkSettingsData = async () => {
        const returnData = await getAiLinkSettings(urlDataParams, aiAgentsSelected);

        if (returnData && returnData.data) {
          // set in getAiLinkSettings
          // setAiLinkSettingsData(returnData.data)
        }
      }
      if (
        !init &&
        // initSettings &&
        aiAgentsSelected !== null
      ) {
        // call the function
        fetchAiLinkSettingsData()
          // make sure to catch any error
          .catch(console.error);

        // trigger useEffect in AiHistoryPanel
        // setAiConversationsDocsRefresh(true)
      }
    }, [aiAgentsSelected])
    */
  // triggered when aisettings is changed
  useEffect(() => {
    if (
      !init &&
      // initSettings &&
      aiSettingsSelected !== null
    ) {
      // BT debugger
      if (aiSettingsSelectedRefresh.current === true) {
        if (clog()) console.log("AiSettingsPanel - useEffect [aiSettingsSelected] setAiConversationsDocsRefresh agent:", aiAgentsSelected)
        if (clog()) console.log("AiSettingsPanel - useEffect [aiSettingsSelected] setAiConversationsDocsRefresh settings:", aiSettingsSelected)
        setAiConversationsDocsRefresh(true);
      }
      else {
        // setAiSettingsSelected was updated but no refresh is needed
        // reset it for next time
        aiSettingsSelectedRefresh.current = true
      }

    }
  }, [aiSettingsSelected]);

  useEffect(() => {
    if (!init && aiSettingsDocsRefresh !== "") {
      var foundIdx = _.findIndex(aiSettingsDocs, { aisettingid: aiSettingsDocsRefresh });
      if (foundIdx > -1) {
        const foundSettings = aiSettingsDocs[foundIdx];
        setAiSettingsSelected(foundSettings);
        if (clog()) console.log("AiSettingsPanel - useEffect [aiSettingsDocsRefresh] setAiSettingsSelected:", foundSettings)

      }
      setAiSettingsDocsRefresh("");
    }
  }, [aiSettingsDocsRefresh]);



  // aiConversationsSelected is changed - based on AiChatPanel: fetchAiConversationData
  // useEffect(() => {
  //   if (
  //     !init &&
  //     aiAgentShowLiveConversations.active === true &&
  //     aiAgentShowLiveConversations.aiagentid !== aiLiveConversationsDocs.aiagentid
  //   ) {
  //     // aiAgentShowLiveConversations is active and
  //     // aiagentid is different than aiLiveConversationsDocs.aiagentid
  //     fetchAiLiveConversations()
  //       // make sure to catch any error
  //       .catch(console.error);
  //   }
  // }, [aiAgentShowLiveConversations]);

  // const fetchAiLiveConversations = useCallback(async () => {
  //   const result = await getAiLiveConversationsDocs(userData, aiAgentShowLiveConversations);
  //   if (result && result.data) {

  //   }
  // }, [getAiLiveConversationsDocs]);




  // SF event for oauth redirect
  const handleOnClickOauth = (event) => {
    if (clog()) console.log("handleOnClick SF event for oauth redirect");
    const redirect = import.meta.env.VITE_REACT_APP_OOATH_REDIRECT;
    const clientid = import.meta.env.VITE_REACT_APP_OOATH_CLIENTID;
    const scopes = [
      "contacts.write",
      "contacts.readonly",
      "conversations/message.readonly",
      "conversations/message.write",
      "conversations.write",
      "conversations.readonly",
    ];
    const scope = scopes.join(" ");

    if (clog()) console.log("redirect", redirect);
    if (clog()) console.log("clientid", clientid);

    const url = `https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=${redirect}/oauth/callback&client_id=${clientid}&scope=${scope}`;
    window.location.href = url;
  };


  const handleOnClickBackToAdmin = (event) => {
    // navigate to admin
    window.location.href = "/admin";
  }

  const isShowCustomerDialogDisabled = () => {
  };


  const handleShowCustomerDialog = () => {
    if (clog()) console.log('handleShowCustomerDialog');
    setShowAiCustomerSettingsDialog({
      show: true,
      type: [],
      message: "",
    });
  };



  const isShowAiAgentLiveConversationsDisabled = () => {
    let disabled = false;

    if (aiAgentsSelected === null) {
      disabled = true;
    }

    return disabled;
  };


  const handleShowAiAgentLiveConversations = () => {
    // handleOnChangeAgents <- does this need modifying?

    if (aiAgentShowLiveConversations.active === true) {
      setAiAgentShowLiveConversations({
        active: false,
        aiagentid: "",
      });
    } else {
      setAiAgentShowLiveConversations({
        active: true,
        aiagentid: aiAgentsSelected.aiagentid,
      });
    }
  };



  const isShowAiAgentVariablesDialogDisabled = () => {
    let disabled = true;
    if (aiAgentsSelected !== null && aiAgentsSelected.automationlink && aiAgentsSelected.automationlink !== "") {
      disabled = false;
    }

    if (aiAgentsIsEditing.active === true || aiConversationsIsEditing.active === true) {
      disabled = true;
    }

    if (aiSettingsIsEditing.active === true && aiSettingsIsEditing.type !== "improvePromptProcessing") {
      disabled = true;
    }

    return disabled;
  };


  const handleShowAiAgentVariablesDialog = () => {
    setShowAiAgentVariablesDialog(true);
  };


  const isShowAiWebCodeDialogDisabled = () => {
    let disabled = true;
    if (aiAgentsSelected !== null && aiAgentsSelected.automationlink && aiAgentsSelected.automationlink !== "") {
      disabled = false;
    }

    if (aiAgentsIsEditing.active === true || aiConversationsIsEditing.active === true) {
      disabled = true;
    }

    if (aiSettingsIsEditing.active === true && aiSettingsIsEditing.type !== "improvePromptProcessing") {
      disabled = true;
    }

    return disabled;
  };


  const handleShowAiWebCodeDialog = () => {
    setShowAiWebCodeDialog(true);
  };


  const handleOnChangeSettings = (event) => {
    const val = event.target.value;

    var foundIdx = _.findIndex(aiSettingsDocs, { aisettingid: val });
    if (foundIdx > -1) {
      const foundSettings = aiSettingsDocs[foundIdx];
      setAiSettingsSelected(foundSettings);
    }
  };

  const handleOnChangeNewSettingsFromPreset = async (event) => {
    let returnData = {};

    const val = event.target.value;

    if (clog()) console.log("handleOnChangeNewSettingsFromPreset val", val);

    setAiPresetSelected(val);

    if (val === "new") {
      // setAiSettingsDataUpdated(null);
      returnData = getDefaultAiPreset(userData);
    } else if (val === "duplicate") {
      const presetData = aiSettingsDataUpdated;
      const presetDataCleaned = _.omit(presetData, ["createdon", "updatedon"]);

      setAiSettingsNewAiPreset(presetDataCleaned);
    } else {
      // get preset data
      returnData = await getAiPreset(val);
    }

    if (returnData && returnData.data) {
      const presetData = returnData.data;
      const presetDataCleaned = _.omit(presetData, ["createdon", "updatedon"]);
      if (debug()) debugger;
      // set val for use when creating new ai agent
      setAiSettingsNewAiPreset(presetDataCleaned);
    }

  };

  const handleOnChangeNewSettingsFromPresetNewAiAgent = async (event) => {
    let returnData = {};

    const val = event.target.value;

    if (clog()) console.log("handleOnChangeNewSettingsFromPresetNewAiAgent val", val);

    setAiPresetSelected(val);
    if (val === "new") {
      // setAiSettingsDataUpdated(null);
      returnData = getDefaultAiPreset(userData);
    } else {
      // get preset data
      returnData = await getAiPreset(val);
    }

    if (returnData && returnData.data) {
      const presetData = returnData.data;
      const presetDataCleaned = _.omit(presetData, ["createdon", "updatedon"]);
      if (debug()) debugger;
      // set val for use when creating new ai agent
      setAiAgentsNewAiPreset(presetDataCleaned);
    }
  };

  const getSelectedSettingsId = () => {
    let selectedSettings = "";
    if (aiSettingsSelected && aiSettingsSelected.aisettingid && aiSettingsSelected.aisettingid !== "") {
      selectedSettings = aiSettingsSelected.aisettingid;
    }

    return selectedSettings;
  };

  const handleOnChangeAgentsOLD = (event) => {
    const val = event.target.value;

    var foundIdx = _.findIndex(aiAgentsDocs, { aiagentid: val });
    if (foundIdx > -1) {
      const foundAgent = aiAgentsDocs[foundIdx];
      setAiAgentsSelected(foundAgent);
      aiAgentsSelectedRef.current = foundAgent;

      if (initAiAgentSelected) {
        // first time agent is being selected
        setInitAiAgentSelected(false);
        // set ref for comparisons after aigent change
        aiAgentsSelectedRef.current = foundAgent;
      } else {
        // subsequent agent being selected
        // find item in aiSettingsDocs that matches automationlink
        var foundIdx = _.findIndex(aiSettingsDocs, { automationlink: foundAgent.automationlink });
        if (foundIdx > -1) {
          const foundSettings = aiSettingsDocs[foundIdx];
          setAiSettingsSelected(foundSettings);
        }
      }
    }
  };

  const handleOnChangeAgents = (event) => {
    const val = event.target.value;
    var foundIdx = _.findIndex(aiAgentsDocs, { aiagentid: val });
    if (foundIdx > -1) {
      const foundAgent = aiAgentsDocs[foundIdx];
      setAiAgentsSelected(foundAgent);
      aiAgentsSelectedRef.current = foundAgent;
      // if (clog()) console.log('-------------------------------------');
      // if (clog())if (clog()) console.log('XXXX setAiAgentsSelected', foundAgent);

      let settingsDocs = [];
      if (foundAgent && foundAgent.aisettings) {
        settingsDocs = foundAgent.aisettings;
      }
      const settingsDocsActive = getAiSettingsActive(settingsDocs);
      // if (clog()) console.log('XXXX settingsDocsActive', settingsDocsActive);
      // if (debug()) debugger;
      // set aiSettingsDocs
      setAiSettingsDocs(settingsDocsActive);
      // reset aiSettingsSelected
      // setAiSettingsSelected(null)

      // set first item in settingsDocsActive as selected
      if (settingsDocsActive.length > 0) {
        setAiSettingsSelected(settingsDocsActive[0]);
      } else {
        // if no settings docs, reset aiSettingsSelected
        setAiSettingsSelected(null);
      }
    }
  };

  const getSelectedAgentId = () => {
    let selectedAgent = "none";
    if (aiAgentsSelected && aiAgentsSelected.aiagentid && aiAgentsSelected.aiagentid !== "") {
      selectedAgent = aiAgentsSelected.aiagentid;
    }

    return selectedAgent;
  };

  const isDuplicateDisabled = () => {
    let disabled = false;

    if (aiAgentsSelected === null) {
      disabled = true;
    }

    if (aiAgentsIsEditing.active === true) {
      disabled = true;
    }

    if (aiSettingsSelected === null) {
      disabled = true;
    }

    if (aiSettingsIsEditing.active === true) {
      disabled = true;
    }

    if (aiConversationsIsEditing.active === true) {
      disabled = true;
    }

    return disabled;
  };

  const handleOnClickDuplicate = async (event) => {
    if (clog()) console.log("handleOnClickDuplicate");
    const result = await duplicateAiSettings();

    if (result && result.data && result.data.aisettingid && result.data.aisettingid !== "") {
      const val = result.data.aisettingid;

      // trigger useEffect in AiSettingsPanel to load new settings
      setAiSettingsDocsRefresh(val);
    }
  };

  const handleOnClickNewSettings = async (event) => {
    if (clog()) console.log("handleOnClickNewSettings");
    const result = await duplicateAiSettings();

    if (result && result.data && result.data.aisettingid && result.data.aisettingid !== "") {
      const val = result.data.aisettingid;

      // trigger useEffect in AiSettingsPanel to load new settings
      setAiSettingsDocsRefresh(val);
    }
  };

  // const getObjectDiff = (obj1, obj2) => {
  //   const diff = Object.keys(obj1).reduce((result, key) => {
  //     if (!obj2.hasOwnProperty(key)) {
  //       result.push(key);
  //     } else if (_.isEqual(obj1[key], obj2[key])) {
  //       const resultKeyIndex = result.indexOf(key);
  //       result.splice(resultKeyIndex, 1);
  //     }
  //     return result;
  //   }, Object.keys(obj2));

  //   return diff;
  // };

  const isSaveDisabled = () => {
    // compare aiSettingsDataUpdated to aiSettingsData using lodash
    // if different, enable save button
    // if same, disable save button
    let disabled = true;
    const settingsData = aiSettingsData
    const settingsDataUpdated = aiSettingsDataUpdated

    const isEqual = _.isEqual(aiSettingsDataUpdated, aiSettingsData);

    // if (clog()) console.log("aiSettingsData", aiSettingsData);
    // if (clog()) console.log("aiSettingsDataUpdated", aiSettingsDataUpdated);

    if (isEqual === false) {
      // const diff = getObjectDiff(aiSettingsDataUpdated, aiSettingsData);
      // const diff = getDiffs('aisettings', aiSettingsData, aiSettingsDataUpdated);
      // if (clog()) console.log("aisettings diff", diff);
    }

    if (
      aiSettingsDataUpdated &&
      // aiSettingsDataUpdated.data &&
      aiSettingsData &&
      // aiSettingsData.data &&
      // !_.isEqual(aiSettingsDataUpdated.data, aiSettingsData.data)
      // !_.isEqual(aiSettingsDataUpdated, aiSettingsData)
      isEqual === false &&
      aiSettingsIsEditing.active === false
    ) {
      disabled = false;
    }

    if (aiAgentsSelected === null) {
      disabled = true;
    }

    if (aiAgentsIsEditing.active === true) {
      disabled = true;
    }

    if (aiSettingsSelected === null) {
      disabled = true;
    }

    if (aiSettingsIsEditing.active === true) {
      disabled = true;
    }

    // questions panel is editing
    if (aiSettingsIsEditing.active === true && aiSettingsIsEditing.type === "questions") {
      disabled = false;
    }

    // questions panel - promptVersionChage is editing
    if (aiSettingsIsEditing.active === true && aiSettingsIsEditing.type === "promptVersionChange") {
      disabled = false;
    }

    // questions panel - promptVersionChage is editing
    if (aiSettingsIsEditing.active === true && aiSettingsIsEditing.type === "promptVersionNew") {
      disabled = false;
    }

    // model panel is editing
    if (
      (aiSettingsIsEditing.active === true && aiSettingsIsEditing.type === "strategy") ||
      (aiSettingsIsEditing.active === true && aiSettingsIsEditing.type === "model") ||
      (aiSettingsIsEditing.active === true && aiSettingsIsEditing.type === "modelParams")
    ) {
      disabled = false;
    }

    if (aiConversationsIsEditing.active === true) {
      disabled = true;
    }

    if (aiConversationsIsSaving) {
      disabled = true;
    }

    return disabled;
  };

  const handleOnClickSave = async (event) => {
    let result;
    if (clog()) console.log("handleOnClickSave");

    let isLinked = false;
    if (aiConversation && aiConversation.ailinksettingid && aiConversation.ailinksettingid !== "") {
      isLinked = true;
    }
    // temp
    isLinked = false;

    if (isLinked) {
      // show warning alert
      if (!showSaveLinkedAlert) {
        setShowSaveLinkedAlert(true);
      }
    } else {
      setAiSettingsSaving(true);
      // let lastMessageRole = "";
      // // check is messages exists in aiConversation and get last message type
      // if (aiConversation && aiConversation.messages && aiConversation.messages.length > 0) {
      //   const lastMessage = aiConversation.messages[aiConversation.messages.length - 1];
      //   let role = "";
      //   if (lastMessage && lastMessage.role) {
      //     role = lastMessage.role;
      //   }
      //   lastMessageRole = role;
      // }

      // check if variables are missing from:
      // aiconversation
      // aiSettingsDataUpdated

      //
      // BT aisettings
      result = await saveAiSettings(userData, aiAgentsSelected, aiConversation, aiSettingsDataUpdated, aiPromptVersionSelected, aiSettingsIndexIdSelected);
      if (result && result.data) {
        const { data } = result;

        const settingsData = data.settingsData

        let agentsData = null;
        if (data.agentsData) {
          agentsData = data.agentsData;
        }

        // set aiConversationFetchData to true
        setAiConversationFetchData(false);
        debugger
        if (agentsData) {
          // if agentsData exists then update aiAgentsSelected
          setAiAgentsSelected({
            ...aiAgentsSelected,
            inputs: agentsData,
          });
        }

        // update aiConversationsSelected
        const smallData = prepareAiConversationSmallData(aiConversation, settingsData, aiSettingsIndexIdSelected);
        debugger
        setAiConversationsSelected(smallData);

        //
        // setAiConversation <- always up to date in getAiConversation subscription
        setAiSettingsData(settingsData);
        setAiSettingsDataUpdated(settingsData);
        // set ref for comparisons after settings save
        aiSettingsDataRef.current = settingsData;

        // trigger useEffect in AiSettingsPanel to load new settings
        // setAiSettingsDocsRefresh(val)
        setAiSettingsSaving(false);
      }

      // cancel all editing
      setAiAgentsIsEditing({
        active: false,
        type: "",
      });

      setAiConversationsIsEditing({
        active: false,
        type: "",
      });

      setAiSettingsIsEditing({
        active: false,
        type: "",
      });
    }
  };

  const handleOnClickCancel = (event) => {
    // setAiConversation <- always up to date in getAiConversation subscription
    debugger
    setAiSettingsDataUpdated(aiSettingsData);
    // set ref for comparisons after settings save
    // aiSettingsDataRef.current = data;

    // cancel all editing
    setAiAgentsIsEditing({
      active: false,
      type: "",
    });

    setAiConversationsIsEditing({
      active: false,
      type: "",
    });

    setAiSettingsIsEditing({
      active: false,
      type: "",
    });

    // trigger useEffect in AiModelPanel to load original settings
    setAiModelReset(true);

    // trigger useEffect in AiQuestionsPanel to load original settings
    setAiPromptVersionReset(true);
  };

  const handleOnChangeAiSettingsData = (event, key) => {
    const val = event.target.value;

    if (clog()) console.log("event:", event.target.value);

    // if id is already taken then warn user
    if (key === "aisettingid") {
      // check if name id already exists
      var foundIdx = _.findIndex(aiSettingsDocs, { aisettingid: val });
      if (foundIdx > -1) {
        // aisettingid already exists
        // show warning
        // alert('Name ID already exists')
        // return
      }
    }

    let data = _.cloneDeep(aiSettingsDataUpdated.data);
    // update name
    data[key] = val;

    setAiSettingsDataUpdated({
      ...aiSettingsDataUpdated,
      data: data,
    });
  };


  const isAutomationLinkItemDisabled = (val) => {

    let foundIdx = -1;
    foundIdx = _.findIndex(aiAgentsDocs, { automationlink: val });
    if (foundIdx > -1) {
      // automationlink already exists
      return true;
    }
    else {
      return false;
    }
  }

  const getAiAgentsAutomationLinkValue = () => {
    let val = -1;

    if (aiAgentsAutomationLink && aiAgentsAutomationLink !== "") {
      val = aiAgentsAutomationLink;
    }

    return val;
  }
  const handleOnChangeAiAgentsAutomationLink = (event) => {
    if (clog()) console.log("event:", event.target.value);

    // let categoryagents = [];
    // if (adminAiCategoryAgentsDocs && adminAiCategoryAgentsDocs.length > 0) {
    //   categoryagents = adminAiCategoryAgentsDocs;
    // }

    // const test = aiAgentsSelected

    // update item
    let val = event.target.value;
    if (val === -1) {
      setAiAgentsAutomationLink('');
    }
    else {
      setAiAgentsAutomationLink(val);
    }

  };

  const handleOnChangeAiAgentsAutomationLinkOLD = (event) => {
    var val = event.target.value;

    // const valRaw = event.target.value;
    // var val = valRaw.replace(ALPHA_NUMERIC_DASH_REGEX_EXCLUDE, '');
    // var re = /^[a-zA-Z0-9_]+$/; // or /^\w+$/ as mentioned
    // if (!re.test(val)) {
    //   debugger
    // }
    // else {
    //   debugger
    // }

    let foundIdx = -1;

    if (val === "") {
      setAiAgentsAutomationLinkInUse(false);
      setAiAgentsAutomationLinkIllegalChars(false);
    }

    // if (aiAgentsIsEditing.active === true && aiAgentsIsEditing.type === "add" && aiAgentsAutomationLink !== "") {
    if (aiAgentsIsEditing.active === true && aiAgentsIsEditing.type === "add" && val !== "") {
      // check if name id already exists in aiAgentsDocs
      foundIdx = _.findIndex(aiAgentsDocs, { automationlink: val });
      if (foundIdx > -1) {
        // automationlink already exists
        setAiAgentsAutomationLinkInUse(true);
      } else {
        setAiAgentsAutomationLinkInUse(false);
      }

      const regexTest = ALPHA_NUMERIC_DASH_REGEX.test(val);
      if (!regexTest) {
        // automation link contains illegal characters
        setAiAgentsAutomationLinkIllegalChars(true);
      } else {
        setAiAgentsAutomationLinkIllegalChars(false);
      }
    }

    // if (aiAgentsIsEditing.active === true && aiAgentsIsEditing.type === "edit"&& aiAgentsAutomationLink !== "") {
    if (aiAgentsIsEditing.active === true && aiAgentsIsEditing.type === "edit" && val !== "") {
      // check if name id already exists in aiAgentsDocs
      foundIdx = _.findIndex(aiAgentsDocs, { automationlink: val });
      if (foundIdx > -1) {
        // is index the same as the one being edited?
        const id = aiAgentsSelected.aiagentid;
        var foundAgentIdx = _.findIndex(aiAgentsDocs, { aiagentid: id });
        if (foundAgentIdx !== foundIdx) {
          // automationlink already exists
          setAiAgentsAutomationLinkInUse(true);
        } else {
          setAiAgentsAutomationLinkInUse(false);
        }
      } else {
        setAiAgentsAutomationLinkInUse(false);
      }

      const regexTest = ALPHA_NUMERIC_DASH_REGEX.test(val);
      if (!regexTest) {
        // automation link contains illegal characters
        setAiAgentsAutomationLinkIllegalChars(true);
      } else {
        setAiAgentsAutomationLinkIllegalChars(false);
      }
    }
    // if (clog()) console.log("event:", event.target.value);

    setAiAgentsAutomationLink(val);
  };

  const isAiAgentsAutomationLinkInUse = () => {
    let inUse = false;
    if (aiAgentsAutomationLinkInUse) {
      inUse = aiAgentsAutomationLinkInUse;
    }

    return inUse;
  };

  const isAiAgentsAutomationLinkIllegalChars = () => {
    let illegalChars = false;
    if (aiAgentsAutomationLinkIllegalChars) {
      illegalChars = aiAgentsAutomationLinkIllegalChars;
    }

    return illegalChars;
  };

  const aiAgentsAutomationLinkHasError = () => {
    let error = false;
    if (aiAgentsAutomationLinkInUse || aiAgentsAutomationLinkIllegalChars) {
      error = true;
    }

    return error;
  };

  const aiAgentsAutomationLinkErrorText = () => {
    let errorText = "";
    if (aiAgentsAutomationLinkInUse) {
      errorText = "Automation Link already in use.";
    }

    if (aiAgentsAutomationLinkIllegalChars) {
      // errorText = 'Only A-Z, a-z, 0-9, _ and - are allowed.';
      errorText = "Only letters (A-Z, a-z), numbers (0-9), underscores (_) and dashes (-) are allowed.";
    }

    return errorText;
  };

  const handleOnChangeAiAgentsName = (event) => {
    const val = event.target.value;

    // if (clog()) console.log("event:", event.target.value);

    setAiAgentsName(val);

  };

  const handleOnChangeAiSettingsName = (event) => {
    const val = event.target.value;

    if (clog()) console.log("event:", event.target.value);

    setAiSettingsName(val);
    // let data = _.cloneDeep(aiSettingsDataUpdated.data)

    // update name
    // data[key] = val

    // setAiSettingsDataUpdated({
    //   ...aiSettingsDataUpdated,
    //   data: data
    // })
  };

  const handleOnClickShowDescriptionPanel = () => {
    setAiShowDescriptionPanel(!aiShowDescriptionPanel);
    setShowSavePresetAlert(true);
  };

  const handleOnClickAiAgentsAdd = () => {
    //
    if (aiAgentsIsEditing.active === false) {
      let name = "";
      setAiAgentsName(name);

      let automationlink = "";
      setAiAgentsAutomationLink(automationlink);

      // activate edit mode
      setAiAgentsIsEditing({
        active: true,
        type: "add",
      });

      // activate edit mode
      // setAiSettingsIsEditing({
      //   active: true,
      //   type: "add",
      // });
    } else {
      // deactivate edit mode
      setAiAgentsIsEditing({
        active: false,
        type: "",
      });
    }
  };

  const handleOnClickAiAgentsEdit = () => {
    //
    if (aiAgentsIsEditing.active === false) {
      let name = "";
      if (aiAgentsSelected && aiAgentsSelected.name) {
        name = aiAgentsSelected.name;
      }
      setAiAgentsName(name);

      let automationlink = "";
      if (aiAgentsSelected && aiAgentsSelected.automationlink) {
        automationlink = aiAgentsSelected.automationlink;
      }
      setAiAgentsAutomationLink(automationlink);

      // activate edit mode
      setAiAgentsIsEditing({
        active: true,
        type: "edit",
      });

      // aiAgentMenu
      // setAiAgentAnchorEl(null);
    } else {
      if (aiAgentsIsEditing.type === "add") {
        setAiPresetSelected("");
        // setAiSettingsIsEditing({
        //   active: false,
        //   type: "",
        // });
      }

      // deactivate edit mode
      setAiAgentsIsEditing({
        active: false,
        type: "",
      });
    }
  };

  const handleOnClickAiAgentsNameSave = async () => {
    setAiAgentsNameSaving(true);
    if (aiAgentsIsEditing.type === "add") {
      const result = await saveAiAgentsNew(userData, aiAgentsName, aiAgentsAutomationLink, aiAgentsNewAiPreset);
      if (result && result.data && result.data.aiagentid && result.data.aiagentid !== "") {
        // clean states
        setAiAgentsNewAiPreset(null);
        setAiPresetSelected("");
        setAiAgentsNameSaving(false);
        setAiAgentsIsEditing({
          active: false,
          type: "",
        });

        setAiSettingsIsEditing({
          active: false,
          type: "",
        });

        // refresh with new aiagentid
        const aiAgentId = result.data.aiagentid;
        setAiAgentsSelectedRefresh({
          aiagentid: aiAgentId,
        });

        // trigger useEffect in AiModelPanel
        // setAiModelSelectedRefresh(true);
        // trigger useEffect in AiQuestionsPanel
        // setAiStrategySelectedRefresh(true);
      } else {
        setAiAgentsNameSaving(false);
        // deactivate edit mode
        setAiAgentsIsEditing({
          active: false,
          type: "",
        });
      }
    }

    if (aiAgentsIsEditing.type === "edit") {
      //
      // setAiSettingsName(name)

      // what needs to be updated?
      // aiAgentsName aiAgentsSelected.name
      // aiAgentsAutomationLink aiAgentsSelected.automationlink
      if (aiAgentsName !== aiAgentsSelected.name) {
        const resultName = await saveAiAgentsName(aiAgentsSelected.aiagentid, aiAgentsName);
        if (resultName && resultName.data && resultName.data.aiagentid && resultName.data.aiagentid !== "") {
          // clone data
          // const dataUpdated = _.cloneDeep(aiSettingsDataUpdated)
          // dataUpdated.data.name = aiSettingsName
          // update ref and state
          // aiSettingsDataRef.current = dataUpdated
          // setAiSettingsDataUpdated(dataUpdated)

          // update aiAgentsSelected with new name
          setAiAgentsSelected({
            ...aiAgentsSelected,
            name: aiAgentsName,
          });
        }
      }

      if (aiAgentsAutomationLink !== aiAgentsSelected.automationlink) {
        const resultAutomationLink = await saveAiAgentsAutomationLink(aiAgentsSelected.aiagentid, aiAgentsAutomationLink);
        if (resultAutomationLink && resultAutomationLink.data && resultAutomationLink.data.aiagentid && resultAutomationLink.data.aiagentid !== "") {

          // update aiAgentsSelected with new automationlink
          setAiAgentsSelected({
            ...aiAgentsSelected,
            automationlink: aiAgentsAutomationLink,
          });
        }
      }


      setAiAgentsNameSaving(false);
      // deactivate edit mode
      setAiAgentsIsEditing({
        active: false,
        type: "",
      });
    }

    // setAiAgentsNameSaving(false);
    // // deactivate edit mode
    // setAiAgentsIsEditing({
    //   active: false,
    //   type: "",
    // });
  };

  const isAiAgentsNameSaveDisabled = () => {
    let disabled = false;
    const inUse = isAiAgentsAutomationLinkInUse();
    const illegalChars = isAiAgentsAutomationLinkIllegalChars();

    if (aiAgentsName === "") {
      disabled = true;
    }

    if (aiAgentsName !== "" && inUse) {
      disabled = true;
    }

    if (aiAgentsName !== "" && illegalChars) {
      disabled = true;
    }

    if (aiAgentsIsEditing.type === "add" && aiAgentsAutomationLink === "") {
      disabled = true;
    }

    if (aiAgentsIsEditing.type === "edit" && aiAgentsAutomationLink === aiAgentsSelected.automationlink && aiAgentsName === aiAgentsSelected.name) {
      disabled = true;
    }

    // if (aiAgentsSelected !== null && aiAgentsName === aiAgentsSelected.name) {
    //   disabled = true;
    // }

    if (aiAgentsIsEditing.type === "add" && aiPresetSelected === "") {
      disabled = true;
    }

    return disabled;
  };

  const handleOnClickAiAgentsRemoveConfirm = async () => {
    setAiAgentsIsRemoving(true);

    const result = await removeAiAgent(aiAgentsSelected, aiAgentFullDelete);
    if (result && result.data && !result.data.error) {
      // clean states
      if (result.data.aiagent) {
        // refresh with new aiagentid
        const aiAgentsDocsClone = [...aiAgentsDocs];
        // remove from aiAgentsDocsClone
        const index = aiAgentsDocsClone.findIndex((x) => x.aiagentid === aiAgentsSelected.aiagentid);
        if (index > -1) {
          aiAgentsDocsClone.splice(index, 1);
        }
        // get first aiagentid
        if (aiAgentsDocsClone.length > 0) {
          const foundAgent = aiAgentsDocsClone[0];
          // const foundAgent = aiAgentsDocs[foundIdx];

          setAiAgentsSelected(foundAgent);
          aiAgentsSelectedRef.current = foundAgent;

          let settingsDocs = [];
          if (foundAgent && foundAgent.aisettings) {
            settingsDocs = foundAgent.aisettings;
          }

          const settingsDocsActive = getAiSettingsActive(settingsDocs);
          // set aiSettingsDocs
          setAiSettingsDocs(settingsDocsActive);

          // set first item in settingsDocsActive as selected
          if (settingsDocsActive.length > 0) {
            setAiSettingsSelected(settingsDocsActive[0]);
          } else {
            // if no settings docs, reset aiSettingsSelected
            setAiSettingsSelected(null);
          }
        } else {
          // if no aiAgentsDocs, reset aiAgentsSelected and aiSettingsSelected
          setAiAgentsSelected(null);
          aiAgentsSelectedRef.current = null;
          setAiSettingsSelected(null);
        }
      }
    }

    setAiAgentsIsRemoving(false);
    // deactivate edit mode
    setAiAgentsIsEditing({
      active: false,
      type: "",
    });
  };

  const handleOnClickAiAgentsRemove = () => {
    //
    if (aiAgentsIsEditing.active === false) {
      // activate edit mode
      setAiAgentsIsEditing({
        active: true,
        type: "remove",
      });

      // aiAgentMenu
      // setAiAgentAnchorEl(null);
    } else {
      // deactivate edit mode
      setAiAgentsIsEditing({
        active: false,
        type: "",
      });
    }
  };

  const handleOnClickAiSettingsAdd = () => {
    //
    if (aiSettingsIsEditing.active === false) {
      // let name = "";
      // if (aiSettingsDataUpdated && aiSettingsDataUpdated.data && aiSettingsDataUpdated.data.name) {
      //   name = aiSettingsDataUpdated.data.name;
      // }
      // setAiSettingsName(name);

      // add ref of current settings
      aiSettingsDataUpdatedTemp.current = aiSettingsDataUpdated;
      // add ref of current conversation
      aiConversationTemp.current = aiConversation;

      // activate edit mode
      setAiSettingsIsEditing({
        active: true,
        type: "add",
      });
    } else {
      // reset preset select menu
      setAiPresetSelected("");

      // update aiSettingsSelected to show current settings
      setAiSettingsDataUpdated(aiSettingsDataUpdatedTemp.current);

      // update aiConversation to show current conversation
      setAiConversation(aiConversationTemp.current);

      // trigger useEffect in AiModelPanel
      setAiModelSelectedRefresh(true);
      // trigger useEffect in AiQuestionsPanel
      setAiStrategySelectedRefresh(true);

      // reset ref
      aiSettingsDataUpdatedTemp.current = null;

      // deactivate edit mode
      setAiSettingsIsEditing({
        active: false,
        type: "",
      });
    }
  };

  const handleOnClickAiSettingsRemoveConfirm = async () => {
    setAiSettingsRemoving(true);

    const result = await removeAiSettings(aiAgentsSelected, aiSettingsSelected, aiSettingsFullDelete);
    if (result && result.data && result.data.aisettingid && result.data.aisettingid !== "" && result.data.aisettings) {
      const settingsDocs = result.data.aisettings;
      const settingsDocsActive = getAiSettingsActive(settingsDocs);

      if (debug()) debugger;
      // update aiSettingsDocs
      setAiSettingsDocs(settingsDocsActive);

      // change aiSettingsSelected to first item in list
      // set first item in settingsDocsActive as selected
      if (settingsDocsActive.length > 0) {
        setAiSettingsSelected(settingsDocsActive[0]);
      } else {
        setAiSettingsSelected(null);
      }
    }
    setAiSettingsRemoving(false);

    //
    // deactivate edit mode
    setAiSettingsIsEditing({
      active: false,
      type: "",
    });
  };

  const handleOnClickAiSettingsRemove = () => {
    //
    if (aiSettingsIsEditing.active === false) {
      // activate edit mode
      setAiSettingsIsEditing({
        active: true,
        type: "remove",
      });
    } else {
      // deactivate edit mode
      setAiSettingsIsEditing({
        active: false,
        type: "",
      });
    }
  };

  const isAiSettingsRemoveDisabled = () => {
    let disabled = false;

    if (aiSettingsSelected === null) {
      disabled = true;
    }

    if (aiAgentsSelected === null) {
      disabled = true;
    }

    if (aiAgentsIsEditing.active === true || aiConversationsIsEditing.active === true) {
      disabled = true;
    }

    if (aiSettingsIsEditing.type === "improvePrompt" || aiSettingsIsEditing.type === "questions") {
      disabled = true;
    }

    if (aiSettingsIsEditing.type === "strategy" || aiSettingsIsEditing.type === "model" || aiSettingsIsEditing.type === "modelParams") {
      disabled = true;
    }

    if (
      aiAgentsSelected !== null &&
      aiSettingsSelected !== null &&
      aiAgentsSelected.automationlink !== null &&
      aiSettingsSelected.automationlink !== null &&
      aiAgentsSelected.automationlink !== "" &&
      aiSettingsSelected.automationlink !== "" &&
      aiAgentsSelected.automationlink === aiSettingsSelected.automationlink
    ) {
      disabled = true;
    }

    return disabled;
  };

  const handleOnClickAiSettingsNewSave = async () => {
    //
    setAiSettingsNewSaving(true);
    const result = await saveAiSettingsNewFromPreset(userData, aiAgentsSelected, aiSettingsDataUpdated);

    if (result && result.data && result.data.aisettingid && result.data.aisettingid !== "") {
      setAiAgentsDocsRefresh(result.data);
    }

    setAiSettingsNewSaving(false);

    // deactivate edit mode
    setAiSettingsIsEditing({
      active: false,
      type: "",
    });
  };

  const isAiSettingsNewSaveDisabled = () => {
    let disabled = false;
    if (aiPresetSelected === "") {
      disabled = true;
    }

    return disabled;
  };

  const isAiSettingsNewCancelDisabled = () => {
    let disabled = false;

    // if (aiPresetSelected === "") {
    //   disabled = true;
    // }
    // when adding a new aiagent
    if (aiAgentsIsEditing.active === true && aiAgentsIsEditing.type === "add") {
      disabled = true;
    }

    // when no aisetting exists
    if (aiSettingsDocs && aiSettingsDocs.length === 0) {
      disabled = true;
    }
    return disabled;
  };

  const handleOnClickAiSettingsEdit = () => {
    //
    if (aiSettingsIsEditing.active === false) {
      let name = "";
      // if (aiSettingsDataUpdated && aiSettingsDataUpdated.data && aiSettingsDataUpdated.data.name) {
      //   name = aiSettingsDataUpdated.data.name;
      // }

      if (aiSettingsSelected && aiSettingsSelected.name && aiSettingsSelected.name !== "") {
        name = aiSettingsSelected.name;
      }
      // update aiSettingsName
      setAiSettingsName(name);

      // activate edit mode
      setAiSettingsIsEditing({
        active: true,
        type: "edit",
      });
    } else {
      // reset aiSettingsName
      // setAiSettingsName("");

      if (aiSettingsIsEditing.type === "add") {
        setAiSettingsNewAiPreset(null);
        setAiPresetSelected("");
        setAiSettingsName("");
      }

      // deactivate edit mode
      setAiSettingsIsEditing({
        active: false,
        type: "",
      });
    }
  };

  const handleOnClickAiSettingsNameSave = async () => {
    //
    setAiSettingsNameSaving(true);
    // const test = aiSettingsDataUpdated;

    if (aiSettingsIsEditing.type === "add") {
      // const result = await saveAiSettingsNewFromPreset(userData, aiAgentsSelected, aiSettingsDataUpdated);
      const result = await saveAiSettingsNewFromPreset(userData, aiSettingsName, aiAgentsSelected, aiSettingsNewAiPreset);

      if (result && result.data && result.data.aisettingid && result.data.aisettingid !== "") {
        // clean states
        setAiSettingsNameSaving(false);
        setAiSettingsName("");
        setAiSettingsNewAiPreset(null);
        setAiPresetSelected("");

        setAiSettingsIsEditing({
          active: false,
          type: "",
        });

        setAiAgentsDocsRefresh(result.data);
      } else {
        setAiSettingsNameSaving(false);

        // deactivate edit mode
        setAiSettingsIsEditing({
          active: false,
          type: "",
        });

        // reset aiSettingsName
        setAiSettingsName("");
      }
    }

    if (aiSettingsIsEditing.type === "edit") {
      const result = await saveAiSettingsName(aiSettingsDataUpdated.aisettingid, aiSettingsName);

      if (result && result.data && result.data.index && result.data.index > 0 && result.data.settingsDocs.length > 0) {
        // return index and data
        const settingsDocs = result.data.settingsDocs;
        const index = result.data.index;
        // const settingsDocsActive = getAiSettingsActive(settingsDocs);

        if (debug()) debugger;
        // set aiSettingsDocs
        setAiSettingsDocs(settingsDocs);
        // reset aiSettingsSelected
        // setAiSettingsSelected(null)

        // set first item in settingsDocs as selected
        if (settingsDocs.length > 0) {
          setAiSettingsSelected(settingsDocs[index]);
        }

        // clone data
        // const dataUpdated = _.cloneDeep(aiSettingsDataUpdated);
        // dataUpdated.data.name = aiSettingsName;

        // update ref and state
        // aiSettingsDataRef.current = dataUpdated;
        // setAiSettingsDataUpdated(dataUpdated);
      }

      setAiSettingsNameSaving(false);

      // deactivate edit mode
      setAiSettingsIsEditing({
        active: false,
        type: "",
      });

      // reset aiSettingsName
      setAiSettingsName("");
    }

    // setAiSettingsNameSaving(false);

    // // deactivate edit mode
    // setAiSettingsIsEditing({
    //   active: false,
    //   type: "",
    // });

    // // reset aiSettingsName
    // setAiSettingsName("");
  };

  const isAiSettingsNameSaveDisabled = () => {
    let disabled = false;
    if (aiSettingsName === "") {
      disabled = true;
    }

    if (aiSettingsSelected !== null && aiSettingsName === aiSettingsSelected.name) {
      disabled = true;
    }

    if (aiSettingsIsEditing.type === "add" && aiPresetSelected === "") {
      disabled = true;
    }

    return disabled;
  };

  const isAiAgentsDisabled = () => {
    let disabled = false;

    if (aiConversationsIsEditing.active === true) {
      disabled = true;
    }

    if (aiSettingsIsEditing.active === true && aiSettingsIsEditing.type !== "improvePromptProcessing") {
      disabled = true;
    }

    return disabled;
  };

  const isAiAgentsAddDisabled = () => {
    let disabled = false;

    // if (aiAgentsSelected === null) {
    //   disabled = true;
    // }

    if (aiConversationsIsEditing.active === true) {
      disabled = true;
    }

    if (aiSettingsIsEditing.active === true && aiSettingsIsEditing.type !== "improvePromptProcessing") {
      disabled = true;
    }

    return disabled;
  };

  const isAiAgentsEditDisabled = () => {
    let disabled = false;

    if (aiAgentsSelected === null) {
      disabled = true;
    }

    if (aiConversationsIsEditing.active === true) {
      disabled = true;
    }

    if (aiSettingsIsEditing.active === true && aiSettingsIsEditing.type !== "improvePromptProcessing") {
      disabled = true;
    }

    return disabled;
  };

  //  const handleAiAgentMenuClick = (event) => {
  //    const test = event.currentTarget
  //    setAiAgentAnchorEl(event.currentTarget);
  //  };
  //
  //  const handleAiAgentMenuClose = () => {
  //    setAiAgentAnchorEl(null);
  //  };
  //
  //  const aiAgentMenu = (show) => {
  //    return (
  //      <Box name="agentMenu"
  //        sx={{
  //          display: show ? "block" : "none",
  //        }}
  //      >
  //        <BorderTooltip title={isAiAgentsEditDisabled() ? "" : "AI Agent Options"} placement="left" arrow leaveDelay={200}>
  //          <span>
  //            <IconButton
  //              id="ai-agent-menu"
  //              color="primary"
  //              aria-controls={aiAgentMenuOpen ? "basic-menu" : undefined}
  //              aria-haspopup="true"
  //              aria-expanded={aiAgentMenuOpen ? "true" : undefined}
  //              onClick={handleAiAgentMenuClick}
  //              disabled={isAiAgentsEditDisabled()}
  //            >
  //              <CircleIcon
  //                sx={{
  //                  fontSize: "10px",
  //                }}
  //              />
  //            </IconButton>
  //          </span>
  //        </BorderTooltip>
  //        <Menu
  //          id="basic-menu"
  //          anchorEl={aiAgentAnchorEl}
  //          open={aiAgentMenuOpen}
  //          onClose={handleAiAgentMenuClose}
  //          MenuListProps={{
  //            "aria-labelledby": "basic-button",
  //          }}
  //          // anchorOrigin={{
  //          //   vertical: 'top',
  //          //   horizontal: 'left',
  //          // }}
  //          // transformOrigin={{
  //          //   vertical: 'top',
  //          //   horizontal: 'left',
  //          // }}
  //        >
  //          <MenuItem
  //            onClick={handleOnClickAiAgentsEdit}
  //            // disabled={isDisabled}
  //          >
  //            Edit
  //          </MenuItem>
  //          <MenuItem
  //            onClick={handleOnClickAiAgentsAdd}
  //            // disabled={isDisabled}
  //          >
  //            Add
  //          </MenuItem>
  //          <MenuItem
  //            onClick={handleAiAgentMenuClose}
  //            // disabled={isDisabled}
  //          >
  //            Remove
  //          </MenuItem>
  //        </Menu>
  //      </Box>
  //    );
  //  };



  const handleAiAgentDialogClose = () => {
    setAiAgentsIsEditing({
      active: false,
      type: "",
    });
  };

  const showAiAgentAddEditDialog = () => {
    let open = false;
    if ((aiAgentsIsEditing.active && aiAgentsIsEditing.type === "add") || aiAgentsIsEditing.type === "edit") {
      open = true;
    }

    let title = "";
    if (aiAgentsIsEditing.type === "add") {
      title = "Add AI Agent";
    } else if (aiAgentsIsEditing.type === "edit") {
      title = "Edit AI Agent";
    }

    // const menuItems = [];

    // // Using a for loop to generate an array of JSX elements
    // for (let i = 1; i < 19; i++) {
    //   menuItems.push(<MenuItem key={"automationlink" + index} value={index}>
    //   {'A' + index}
    // </MenuItem>);
    // }

    return (
      <Dialog
        open={open}
        onClose={handleAiAgentDialogClose}
        sx={{
          "& .MuiDialog-paper": {
            position: "absolute",
            top: "10px",
            // left: "50%",
            // transform: "translate(-50%, -50%)",
          },
          // position: "absolute",
          // top: "0px",
          // alignItems: "flex-start",
        }}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent
          dividers={true}
        >
          {
            // <DialogContentText>
            //   To subscribe to this website, please enter your email address here. We will send updates occasionally.
            // </DialogContentText>
          }
          <Box
            width={300}
            sx={{
              mt: 1,
              // mt: 4,
              // mr: 1,
            }}
          >
            <TextField
              // margin="normal"
              required
              fullWidth
              // id="email"
              label="AI Agent Name"
              name="name"
              autoComplete="agentname"
              placeholder="Add AI Agent Name..."
              InputLabelProps={{ shrink: true }}
              // autoFocus
              // value={aiSettingsDataUpdated.data.name}
              value={aiAgentsName}
              onChange={(e) => handleOnChangeAiAgentsName(e)}
            />
          </Box>

          {
            //// Old Automation Link
            // <Box
            //   width={300}
            //   sx={{
            //     mt: 4,
            //     mb: isAiAgentsAutomationLinkInUse() === true ? "9px" : 4,
            //     // mt: 1,
            //     // mr: 1,
            //     //position: "relative",
            //   }}
            // >
            //   <TextField
            //     required
            //     fullWidth
            //     label="Automation Link"
            //     name="automationlink"
            //     autoComplete="automationlink"
            //     placeholder="Add Automation Link..."
            //     InputProps={{
            //       readOnly: aiAgentsIsEditing.active === true && aiAgentsIsEditing.type === "add" ? false : true,
            //     }}
            //     InputLabelProps={{ shrink: true }}
            //     value={aiAgentsAutomationLink}
            //     onChange={(e) => handleOnChangeAiAgentsAutomationLink(e)}
            //     onKeyDown={(event) => {
            //       if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
            //         event.preventDefault();
            //       }
            //     }}
            //     error={aiAgentsAutomationLinkHasError() === true ? true : false}
            //     helperText={aiAgentsAutomationLinkErrorText()}
            //   />
            // </Box>
          }

          {
            <Box
              sx={{
                width: 300,
              }}
            >
              <FormControl
                sx={{
                  mt: 2,
                  mb: 2,
                  width: '100%',
                }}
              >
                <InputLabel>Automation Link</InputLabel>
                <Select
                  label="Automation Link"
                  value={getAiAgentsAutomationLinkValue()}
                  onChange={(event) => handleOnChangeAiAgentsAutomationLink(event)}
                // sx={{
                //   width: 300,
                // }}
                >
                  <MenuItem
                    value={-1}
                  // disabled={true}
                  >
                    Unassigned
                  </MenuItem>


                  {aiAgentsAutomationLinkItems &&
                    aiAgentsAutomationLinkItems.map((a, index) => {
                      return (
                        <MenuItem
                          key={"automationlink" + index}
                          value={a.value}
                          disabled={isAutomationLinkItemDisabled(a.value) === true ? true : false}
                        >
                          {a.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Box>
          }

          {aiAgentsIsEditing.type === "add" && (
            <Box
              width={300}
              sx={
                {
                  // mt: 4,
                }
              }
            >
              <FormControl
                sx={{
                  // mt: 3,
                  // m: 1,
                  // minWidth: 120
                  width: "100%",
                }}
              >
                <InputLabel>Add New Settings</InputLabel>
                <Select
                  label="Add New Settings"
                  value={aiPresetSelected}
                  // disabled={isAiSettingsDisabled()}
                  onChange={(event) => handleOnChangeNewSettingsFromPresetNewAiAgent(event)}
                >
                  <MenuItem value="new">New Blank Setting</MenuItem>
                  {
                    // <MenuItem value="duplicate">Duplicate Current Setting</MenuItem>
                  }
                  <ListSubheader
                    color="primary"
                    sx={{
                      "&.MuiListSubheader-colorPrimary": {
                        lineHeight: "38px",
                        // backgroundColor: '#efefef',
                        // textTransform: "uppercase",
                      },
                    }}
                  >
                    Presets
                  </ListSubheader>
                  {aiCategoriesDoc &&
                    aiCategoriesDoc.aipresets &&
                    aiCategoriesDoc.aipresets.map((s, index) => {
                      return (
                        <MenuItem key={s.aipresetid} value={s.aipresetid}>
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              // flexDirection: 'row',
                              alignItems: "center",
                              justifyContent: "space-between !important",
                            }}
                          >
                            {s.name}
                          </Box>
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",

              mr: 1,
            }}
          >
            <Button
              aria-label="Cancel"
              color="primary"
              variant="outlined"
              // size="small"
              // variant="light"
              sx={{
                mr: 1,
              }}
              onClick={handleOnClickAiAgentsEdit}
            // disabled={isSendDisabled()}
            >
              Cancel
            </Button>

            {aiAgentsNameSaving ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "64px",
                  height: "36.5px",
                }}
              >
                <CircularProgress
                  size={12}
                  sx={{
                    p: 0,
                    m: 0,
                  }}
                // variant="determinate"
                />
              </Box>
            ) : (
              <Button
                aria-label="Save"
                color="primary"
                variant="contained"
                // size="small"
                // variant="light"
                onClick={handleOnClickAiAgentsNameSave}
                disabled={isAiAgentsNameSaveDisabled()}
              >
                Save
              </Button>
            )}
          </Box>
        </DialogActions>
      </Dialog>
    );
  };

  const handleAiAgentFullDelete = (event) => {
    setAiAgentFullDelete(event.target.checked);
  };

  const handleOnClickAiAgentsRemoveCancel = () => {
    // reset checkbox
    setAiAgentFullDelete(false);

    // reset removing
    setAiAgentsIsRemoving(false);

    // deactivate edit mode
    setAiAgentsIsEditing({
      active: false,
      type: "",
    });
  };

  const showAiAgentRemoveDialog = () => {
    let open = false;
    if (aiAgentsIsEditing.active && aiAgentsIsEditing.type === "remove") {
      open = true;
    }

    let title = "Delete AI Agent";

    return (
      <Dialog
        name="aiagentremovedialog"
        open={open}
        onClose={handleAiAgentDialogClose}
        sx={{
          "& .MuiDialog-paper": {
            position: "absolute",
            top: "10px",
            // left: "50%",
            // transform: "translate(-50%, -50%)",
          },
          // position: "absolute",
          // top: "0px",
          // alignItems: "flex-start",
        }}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent
          dividers={true}
        >
          <DialogContentText>Are you sure you want to delete this agent? All settings and conversation data will be lost.</DialogContentText>

          {rc.aiagent_delete && (
            <Box>
              <FormControlLabel label="Full Delete" control={<Checkbox checked={aiAgentFullDelete} onChange={handleAiAgentFullDelete} />} />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",

              mr: 1,
            }}
          >
            <Button
              aria-label="Cancel"
              color="primary"
              variant="outlined"
              // size="small"
              // variant="light"
              sx={{
                mr: 1,
              }}
              onClick={handleOnClickAiAgentsRemoveCancel}
            // disabled={isSendDisabled()}
            >
              Cancel
            </Button>

            {aiAgentsIsRemoving ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "64px",
                  height: "36.5px",
                }}
              >
                <CircularProgress
                  size={12}
                  sx={{
                    p: 0,
                    m: 0,
                  }}
                // variant="determinate"
                />
              </Box>
            ) : (
              <Button
                aria-label="Archive"
                color="primary"
                variant="contained"
                // size="small"
                // variant="light"
                onClick={handleOnClickAiAgentsRemoveConfirm}
              // disabled={isAiAgentsNameSaveDisabled()}
              >
                Delete
              </Button>
            )}
          </Box>
        </DialogActions>
      </Dialog>
    );
  };


  /* START: aiagentpreset */

  // const handleOnClickAiAgentsSavePresetAgentCancel = () => {
  //   // // reset checkbox
  //   // setAiAgentFullDelete(false);

  //   // // reset removing
  //   // setAiAgentsIsRemoving(false);

  //   // reset name
  //   if (aiPresetAgentName !== "") {
  //     setAiPresetAgentName("");
  //   }
  //   // deactivate edit mode
  //   setAiAgentsIsEditing({
  //     active: false,
  //     type: "",
  //   });
  // };

  // const handleOnChangeAiAgentPresetName = (event) => {
  //   setAiPresetAgentName(event.target.value);
  // };

  // const isAiPresetAgentSaveDisabled = () => {
  //   let disabled = false;
  //   if (aiPresetAgentName === "") {
  //     disabled = true;
  //   }

  //   return disabled;
  // }

  // const showAiAgentSavePresetAgentDialog = () => {
  //   let open = false;
  //   if (aiAgentsIsEditing.active && aiAgentsIsEditing.type === "savepresetagent") {
  //     open = true;
  //   }

  //   let title = "Save AI Agent Preset";

  //   return (
  //     <Dialog
  //       name="aiagentsaveagentpresetdialog"
  //       open={open}
  //       onClose={handleAiAgentDialogClose}
  //       maxWidth="sm"
  //       sx={{
  //         "& .MuiDialog-paper": {
  //           position: "absolute",
  //           top: "10px",
  //           // left: "50%",
  //           // transform: "translate(-50%, -50%)",
  //         },
  //         // position: "absolute",
  //         // top: "0px",
  //         // alignItems: "flex-start",
  //       }}
  //     >
  //       <DialogTitle>{title}</DialogTitle>
  //       <DialogContent
  //         dividers={true}
  //       >
  //         <DialogContentText>Save AI Agent Preset</DialogContentText>

  //         <Box
  //           sx={{
  //             mt: 2,
  //             width: 300,
  //           }}

  //         >
  //           <TextField
  //             required
  //             fullWidth
  //             label="Agent Preset Name"
  //             name="agentpresetname"
  //             autoComplete="agentpresetname"
  //             placeholder="Add Agent Preset Name..."
  //             // disabled={true}
  //             // InputProps={{
  //             //   readOnly: aiAgentsIsEditing.active === true && aiAgentsIsEditing.type === "add" ? false : true,
  //             // }}
  //             InputLabelProps={{ shrink: true }}
  //             // autoFocus
  //             // value={aiSettingsDataUpdated.data.name}
  //             value={aiPresetAgentName}
  //             onChange={(e) => handleOnChangeAiAgentPresetName(e)}
  //           // onKeyDown={(event) => {
  //           //   if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
  //           //     event.preventDefault();
  //           //   }
  //           // }}
  //           // error={aiAgentsAutomationLinkHasError() === true ? true : false}
  //           // helperText={aiAgentsAutomationLinkErrorText()}
  //           />
  //         </Box>

  //       </DialogContent>
  //       <DialogActions>
  //         <Box
  //           sx={{
  //             display: "flex",
  //             flexDirection: "row",

  //             mr: 1,
  //           }}
  //         >
  //           <Button
  //             aria-label="Cancel"
  //             color="primary"
  //             variant="outlined"
  //             // size="small"
  //             // variant="light"
  //             sx={{
  //               mr: 1,
  //             }}
  //             onClick={handleOnClickAiAgentsSavePresetAgentCancel}
  //             disabled={aiAgentsIsSavingPresetAgent}
  //           >
  //             Cancel
  //           </Button>

  //           {aiAgentsIsSavingPresetAgent ? (
  //             <Box
  //               sx={{
  //                 display: "flex",
  //                 alignItems: "center",
  //                 justifyContent: "center",
  //                 width: "64px",
  //                 height: "36.5px",
  //               }}
  //             >
  //               <CircularProgress
  //                 size={12}
  //                 sx={{
  //                   p: 0,
  //                   m: 0,
  //                 }}
  //               // variant="determinate"
  //               />
  //             </Box>
  //           ) : (
  //             <Button
  //               aria-label="Save"
  //               color="primary"
  //               variant="contained"
  //               // size="small"
  //               // variant="light"
  //               onClick={handleOnClickSavePresetAgentConfirm}
  //               disabled={isAiPresetAgentSaveDisabled()}
  //             >
  //               Save
  //             </Button>
  //           )}
  //         </Box>
  //       </DialogActions>
  //     </Dialog>
  //   );
  // };

  const isSavePresetAgentDisabled = () => {
    let disabled = false;

    if (aiAgentsSelected === null) {
      disabled = true;
    }

    if (aiSettingsSelected === null) {
      disabled = true;
    }

    if (aiConversation === null) {
      disabled = true;
    }

    if (aiSettingsDataUpdated === null) {
      disabled = true;
    }

    // if (aiAgentsIsEditing.active === true) {
    //   disabled = true;
    // }

    if (aiSettingsIsEditing.active === true) {
      disabled = true;
    }

    if (aiConversationsIsEditing.active === true) {
      disabled = true;
    }

    // if (aiSettingsDataUpdated?.data?.automationlink === "") {
    //   disabled = true;
    // }

    // // make sure that module and strategy exist and are not empty
    // // should aiSettingsDataUpdated or aiSettingsData
    // if (aiSettingsData && aiSettingsData.data) {
    //   if (
    //     (aiSettingsData.data.module && aiSettingsData.data.module === "") ||
    //     (aiSettingsData.data.strategy && aiSettingsData.data.strategy === "")
    //   ) {
    //     disabled = true;
    //   }
    // } else {
    //   disabled = true;
    // }

    return disabled;
  };

  // const handleOnClickSavePresetAgentConfirm = async () => {
  //   if (clog()) console.log("handleOnClickSavePresetAgentConfirm");
  //   // // setAiSettingsName(name)
  //   setAiAgentsIsSavingPresetAgent(true);

  //   const result = await saveAiPresetAgent(userData, aiPresetAgentName);

  //   if (result && result.data) {
  //     // what to do here?
  //     if (clog()) console.log("handleOnClickSavePresetAgent result", result.data);
  //   } else {
  //     if (clog()) console.log("handleOnClickSavePresetAgent result", result.error);
  //   }


  //   setAiAgentsIsSavingPresetAgent(false);

  //   // reset name
  //   setAiPresetAgentName("");

  //   // deactivate edit mode
  //   setAiAgentsIsEditing({
  //     active: false,
  //     type: "",
  //   });

  //   // show alert
  //   setShowSavePresetAgentAlert(true);
  // };



  const handleOnClickSavePresetAgent = async () => {
    setShowAiPresetAgentsDialog(true);
  };




  const showAiAgentsEditingControls = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          mr: 2,
        }}
      >
        {
          // aiAgentMenu(true)
        }



        {adminStatus.isAdmin ? (
          <Box name="agentsSavePresetAgent">
            <IconButton
              aria-label="SavePreset"
              color="primary"
              size="small"
              // variant="light"
              onClick={handleOnClickSavePresetAgent}
              // disabled
              disabled={isSavePresetAgentDisabled()}
              sx={{
                "&.MuiIconButton-sizeSmall": {
                  fontSize: "16px",
                  borderRadius: "0px",
                },
              }}
            >
              <SaveIcon fontSize="inherit" />
            </IconButton>
          </Box>
        ) : (
          <Box name="agentsSavePresetAgent">
            <></>
          </Box>
        )}

        <Box name="agentVariables">
          <IconButton
            aria-label="Variables"
            color="primary"
            size="small"
            sx={{
              "&.MuiIconButton-sizeSmall": {
                fontSize: "16px",
              },
            }}
            // variant="light"
            onClick={handleShowAiAgentVariablesDialog}
            disabled={isShowAiAgentVariablesDialogDisabled()}
          >
            <FormatListBulletedIcon fontSize="inherit" />
          </IconButton>
        </Box>

        <Box name="agentCodeCopy">
          <IconButton
            aria-label="CodeCopy"
            color="primary"
            size="small"
            sx={{
              "&.MuiIconButton-sizeSmall": {
                fontSize: "16px",
              },
            }}
            // variant="light"
            onClick={handleShowAiWebCodeDialog}
            disabled={isShowAiWebCodeDialogDisabled()}
          >
            <CodeIcon fontSize="inherit" />
          </IconButton>
        </Box>
        <Box name="agentAdd">
          <IconButton
            data-testid="aiagentAdd"

            aria-label="Add"
            color="primary"
            size="small"
            // variant="light"
            onClick={handleOnClickAiAgentsAdd}
            // disabled={aiAgentsSelected !== null ? false : true}
            disabled={isAiAgentsAddDisabled()}
            sx={{
              // ml: 0.5,
              "&.MuiIconButton-sizeSmall": {
                fontSize: "16px",
              },
            }}
          >
            <AddIcon fontSize="inherit" />
          </IconButton>
        </Box>

        <Box name="agentRemove">
          <IconButton
            aria-label="remove"
            color="primary"
            size="small"
            // variant="light"
            onClick={handleOnClickAiAgentsRemove}
            // disabled={aiAgentsSelected !== null ? false : true}
            disabled={isAiAgentsEditDisabled()}
            sx={{
              // ml: 0.5,
              "&.MuiIconButton-sizeSmall": {
                fontSize: "16px",
              },
            }}
          >
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        </Box>

        <Box
          name="agentEdit"
          sx={{
            mr: 1,
          }}
        >
          <IconButton
            aria-label="Edit"
            color="primary"
            size="small"
            // variant="light"
            onClick={handleOnClickAiAgentsEdit}
            // disabled={aiAgentsSelected !== null ? false : true}
            disabled={isAiAgentsEditDisabled()}
            sx={{
              // ml: 0.5,
              "&.MuiIconButton-sizeSmall": {
                fontSize: "16px",
              },
            }}
          >
            <EditIcon fontSize="inherit" />
          </IconButton>
        </Box>

        <Box
          width={250}
        // sx={{
        //   display: 'flex',
        //   alignItems: 'center',
        // }}
        >
          <FormControl
            sx={{
              // mt: 3,
              // m: 1,
              // minWidth: 120
              width: "100%",
              "& .MuiInputLabel-root.MuiInputLabel-shrink": {
                backgroundColor: theme.palette.background.bg
              }
            }}
          >
            <InputLabel
              // id="aiagentSelectMenu"
              // aria-labelledby="aiagentSelectMenu"
              sx={{
                // backgroundColor: theme.palette.background.bg,
              }}
            >AI Agent</InputLabel>
            <Select
              aria-label="aiagentSelectMenu"
              // id="aiagentSelectMenu"
              //aria-labelledby="aiagentSelectMenu"
              // className="aiagentSelectMenu"
              data-testid="aiagentSelectMenu"

              label="AI Agent"
              autoFocus={aiAgentsSelected === null ? true : false}
              value={getSelectedAgentId()}
              disabled={isAiAgentsDisabled()}
              onChange={(event) => handleOnChangeAgents(event)}
              sx={{
                "& .MuiSelect-outlined.MuiOutlinedInput-input": {
                  backgroundColor: theme.palette.background.paper,
                }
              }}
            >
              {aiAgentsSelected === null && (
                <MenuItem
                  value="none"
                  disabled
                  sx={{
                    display: "none",
                  }}
                >
                  Select an AI Agent
                </MenuItem>
              )}
              {aiAgentsDocs &&
                aiAgentsDocs.map((a, index) => {
                  return (
                    <MenuItem key={a.aiagentid} value={a.aiagentid}>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          // flexDirection: 'row',
                          alignItems: "center",
                          justifyContent: "space-between !important",
                        }}
                      >
                        {a.name}
                      </Box>
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Box>
      </Box>
    );
    // }
  };

  const linkIconActiveDisabled = () => {
    let linkColor = "primary.main";

    if (aiAgentsIsEditing.active || aiSettingsIsEditing.active || aiConversationsIsEditing.active) {
      linkColor = "grey.300";
    }

    return linkColor;
  };

  const showLinkIcon = (setting) => {
    // if setting automationlink is found in aiAgentsSelected
    if (
      aiAgentsSelected !== null &&
      // aiSettingsSelected !== null &&
      aiAgentsSelected.automationlink &&
      setting.automationlink &&
      aiAgentsSelected.automationlink !== "" &&
      setting.automationlink !== "" &&
      aiAgentsSelected.automationlink === setting.automationlink
    ) {
      return (
        <LinkIcon
          // color="primary"
          sx={{
            color: linkIconActiveDisabled(),
            fontSize: 16,
          }}
        />
      );
    } else {
      return <></>;
    }
  };

  const handleAiSettingsDialogClose = () => {
    setAiSettingsIsEditing({
      active: false,
      type: "",
    });
  };

  const showAiSettingsAddEditDialog = () => {
    let open = false;
    if ((aiSettingsIsEditing.active && aiSettingsIsEditing.type === "add") || aiSettingsIsEditing.type === "edit") {
      open = true;
    }

    let title = "";
    if (aiSettingsIsEditing.type === "add") {
      title = "Add Setting";
    } else if (aiSettingsIsEditing.type === "edit") {
      title = "Edit Setting";
    }

    return (
      <Dialog
        open={open}
        onClose={handleAiSettingsDialogClose}
        sx={{
          "& .MuiDialog-paper": {
            position: "absolute",
            top: "10px",
            // left: "50%",
            // transform: "translate(-50%, -50%)",
          },
          // position: "absolute",
          // top: "0px",
          // alignItems: "flex-start",
        }}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent
          dividers={true}
        >
          {
            // <DialogContentText>
            //   To subscribe to this website, please enter your email address here. We will send updates occasionally.
            // </DialogContentText>
          }
          <Box
            width={300}
            sx={{
              mt: 1,
              // mb: 4,
              // mt: 4,
              // mr: 1,
            }}
          >
            <TextField
              // margin="normal"
              required
              fullWidth
              // id="email"
              label="Settings Name"
              name="name"
              autoComplete="settingsname"
              placeholder="Add Setting Name..."
              InputLabelProps={{ shrink: true }}
              // autoFocus
              // value={aiSettingsDataUpdated.data.name}
              value={aiSettingsName}
              onChange={(e) => handleOnChangeAiSettingsName(e)}
            />
          </Box>

          {aiSettingsIsEditing.type === "add" && (
            <Box
              width={300}
              sx={{
                mt: 4,
              }}
            >
              <FormControl
                sx={{
                  // mt: 3,
                  // m: 1,
                  // minWidth: 120
                  width: "100%",
                }}
              >
                <InputLabel>Add New Settings</InputLabel>
                <Select
                  label="Add New Settings"
                  value={aiPresetSelected}
                  // disabled={isAiSettingsDisabled()}
                  onChange={(event) => handleOnChangeNewSettingsFromPreset(event)}
                >
                  <MenuItem value="new">New Blank Setting</MenuItem>
                  {aiSettingsDataUpdated && <MenuItem value="duplicate">Duplicate Current Setting</MenuItem>}
                  <ListSubheader
                    color="primary"
                    sx={{
                      "&.MuiListSubheader-colorPrimary": {
                        lineHeight: "38px",
                        // backgroundColor: '#efefef',
                        // textTransform: "uppercase",
                      },
                    }}
                  >
                    Presets
                  </ListSubheader>
                  {aiCategoriesDoc &&
                    aiCategoriesDoc.aipresets &&
                    aiCategoriesDoc.aipresets.map((s, index) => {
                      return (
                        <MenuItem key={s.aipresetid} value={s.aipresetid}>
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              // flexDirection: 'row',
                              alignItems: "center",
                              justifyContent: "space-between !important",
                            }}
                          >
                            {s.name}
                          </Box>
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",

              mr: 1,
            }}
          >
            <Button
              aria-label="Cancel"
              color="primary"
              variant="outlined"
              // size="small"
              // variant="light"
              sx={{
                mr: 1,
              }}
              onClick={handleOnClickAiSettingsEdit}
            // disabled={isSendDisabled()}
            >
              Cancel
            </Button>

            {aiSettingsNameSaving ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "64px",
                  height: "36.5px",
                }}
              >
                <CircularProgress
                  size={12}
                  sx={{
                    p: 0,
                    m: 0,
                  }}
                // variant="determinate"
                />
              </Box>
            ) : (
              <Button
                aria-label="Save"
                color="primary"
                variant="contained"
                // size="small"
                // variant="light"
                onClick={handleOnClickAiSettingsNameSave}
                disabled={isAiSettingsNameSaveDisabled()}
              >
                Save
              </Button>
            )}
          </Box>
        </DialogActions>
      </Dialog>
    );
  };

  const handleAiSettingsFullDelete = (event) => {
    setAiSettingsFullDelete(event.target.checked);
  };

  const handleOnClickAiSettingsRemoveCancel = () => {
    // reset checkbox
    setAiSettingsFullDelete(false);

    // deactivate edit mode
    setAiSettingsIsEditing({
      active: false,
      type: "",
    });
  };

  const showAiSettingsRemoveDialog = () => {
    let open = false;
    if (aiSettingsIsEditing.active && aiSettingsIsEditing.type === "remove") {
      open = true;
    }

    let title = "Delete Setting";

    return (
      <Dialog
        name="aisettingremovedialog"
        open={open}
        onClose={handleAiSettingsDialogClose}
        sx={{
          "& .MuiDialog-paper": {
            position: "absolute",
            top: "10px",
            // left: "50%",
            // transform: "translate(-50%, -50%)",
          },
          // position: "absolute",
          // top: "0px",
          // alignItems: "flex-start",
        }}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent
          dividers={true}
        >
          <DialogContentText>Are you sure you want to delete this setting? All conversation data will be lost.</DialogContentText>

          {rc.aisetting_delete && (
            <Box>
              <FormControlLabel label="Full Delete" control={<Checkbox checked={aiSettingsFullDelete} onChange={handleAiSettingsFullDelete} />} />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",

              mr: 1,
            }}
          >
            <Button
              aria-label="Cancel"
              color="primary"
              variant="outlined"
              // size="small"
              // variant="light"
              sx={{
                mr: 1,
              }}
              onClick={handleOnClickAiSettingsRemoveCancel}
            // disabled={isSendDisabled()}
            >
              Cancel
            </Button>

            {aiSettingsRemoving ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "64px",
                  height: "36.5px",
                }}
              >
                <CircularProgress
                  size={12}
                  sx={{
                    p: 0,
                    m: 0,
                  }}
                // variant="determinate"
                />
              </Box>
            ) : (
              <Button
                aria-label="Archive"
                color="primary"
                variant="contained"
                // size="small"
                // variant="light"
                onClick={handleOnClickAiSettingsRemoveConfirm}
              // disabled={isAiAgentsNameSaveDisabled()}
              >
                Delete
              </Button>
            )}
          </Box>
        </DialogActions>
      </Dialog>
    );
  };

  const isAiSettingsDisabled = () => {
    let disabled = false;
    if (aiAgentsSelected === null) {
      disabled = true;
    }

    if (aiAgentsIsEditing.active === true || aiConversationsIsEditing.active === true) {
      disabled = true;
    }

    if (
      aiSettingsIsEditing.type === "improvePrompt" ||
      aiSettingsIsEditing.type === "questions" ||
      aiSettingsIsEditing.type === "strategy" ||
      aiSettingsIsEditing.type === "model" ||
      aiSettingsIsEditing.type === "modelParams" ||
      aiSettingsIsEditing.type === "engineerNewQuestion"
    ) {
      disabled = true;
    }

    return disabled;
  };

  const isAiSettingsAddDisabled = () => {
    let disabled = false;

    // if (aiSettingsSelected === null) {
    //   disabled = true;
    // }

    if (aiAgentsSelected === null) {
      disabled = true;
    }

    if (aiAgentsIsEditing.active === true || aiConversationsIsEditing.active === true) {
      disabled = true;
    }

    if (
      aiSettingsIsEditing.type === "improvePrompt" ||
      aiSettingsIsEditing.type === "questions" ||
      aiSettingsIsEditing.type === "strategy" ||
      aiSettingsIsEditing.type === "model" ||
      aiSettingsIsEditing.type === "modelParams" ||
      aiSettingsIsEditing.type === "engineerNewQuestion"
    ) {
      disabled = true;
    }

    return disabled;
  };

  const isAiSettingsEditDisabled = () => {
    let disabled = false;

    if (aiSettingsSelected === null) {
      disabled = true;
    }

    if (aiAgentsSelected === null) {
      disabled = true;
    }

    if (aiAgentsIsEditing.active === true || aiConversationsIsEditing.active === true) {
      disabled = true;
    }

    if (
      aiSettingsIsEditing.type === "improvePrompt" ||
      aiSettingsIsEditing.type === "questions" ||
      aiSettingsIsEditing.type === "strategy" ||
      aiSettingsIsEditing.type === "model" ||
      aiSettingsIsEditing.type === "modelParams" ||
      aiSettingsIsEditing.type === "engineerNewQuestion"
    ) {
      disabled = true;
    }

    return disabled;
  };

  const showAiSettingsEditingControls = () => {
    // if (aiAgentsIsEditing.active === true && aiAgentsIsEditing.type === "add") {
    //   return (
    //     <Box
    //       sx={{
    //         display: "flex",
    //         flexDirection: "row",
    //         alignItems: "center",
    //         ml: 2,
    //       }}
    //     >
    //       <Box
    //         sx={{
    //           mr: 1,
    //           display: "flex",
    //           flexDirection: "row",
    //         }}
    //       >
    //         {aiSettingsNewSaving ? (
    //           <Box
    //             sx={{
    //               display: "flex",
    //               alignItems: "center",
    //               justifyContent: "center",
    //               width: "30px",
    //               height: "30px",
    //             }}
    //           >
    //             <CircularProgress
    //               size={12}
    //               sx={{
    //                 p: 0,
    //                 m: 0,
    //               }}
    //               // variant="determinate"
    //             />
    //           </Box>
    //         ) : (
    //           <IconButton
    //             aria-label="Save"
    //             color="primary"
    //             size="small"
    //             // variant="light"
    //             onClick={() => handleOnClickAiSettingsNewSave()}
    //             disabled={isAiSettingsNewSaveDisabled()}
    //             sx={{
    //               "&.MuiIconButton-sizeSmall": {
    //                 fontSize: "16px",
    //               },
    //             }}
    //           >
    //             <DoneIcon fontSize="inherit" />
    //           </IconButton>
    //         )}

    //         <IconButton
    //           aria-label="Cancel"
    //           color="primary"
    //           size="small"
    //           // variant="light"
    //           onClick={handleOnClickAiSettingsAdd}
    //           disabled={isAiSettingsNewCancelDisabled()}
    //           sx={{
    //             // ml: 0.5,
    //             "&.MuiIconButton-sizeSmall": {
    //               fontSize: "16px",
    //             },
    //           }}
    //         >
    //           <ClearIcon fontSize="inherit" />
    //         </IconButton>
    //       </Box>

    //       <Box width={250}>
    //         <FormControl
    //           sx={{
    //             // mt: 3,
    //             // m: 1,
    //             // minWidth: 120
    //             width: "100%",
    //           }}
    //         >
    //           <InputLabel>Add Settings</InputLabel>
    //           <Select
    //             label="Add New Settings"
    //             value={aiPresetSelected}
    //             // disabled={isAiSettingsDisabled()}
    //             onChange={(event) => handleOnChangeNewSettingsFromPresetNewAiAgent(event)}
    //           >
    //             <MenuItem value="new">New Blank Setting</MenuItem>
    //             {
    //               // <MenuItem value="duplicate">Duplicate Current Setting</MenuItem>
    //             }
    //             <ListSubheader
    //               color="primary"
    //               sx={{
    //                 "&.MuiListSubheader-colorPrimary": {
    //                   lineHeight: "38px",
    //                   // backgroundColor: '#efefef',
    //                   // textTransform: "uppercase",
    //                 },
    //               }}
    //             >
    //               Presets
    //             </ListSubheader>
    //             {aiCategoriesDoc &&
    //               aiCategoriesDoc.aipresets &&
    //               aiCategoriesDoc.aipresets.map((s, index) => {
    //                 return (
    //                   <MenuItem key={s.aipresetid} value={s.aipresetid}>
    //                     <Box
    //                       sx={{
    //                         width: "100%",
    //                         display: "flex",
    //                         // flexDirection: 'row',
    //                         alignItems: "center",
    //                         justifyContent: "space-between !important",
    //                       }}
    //                     >
    //                       {s.name}
    //                     </Box>
    //                   </MenuItem>
    //                 );
    //               })}
    //           </Select>
    //         </FormControl>
    //       </Box>
    //     </Box>
    //   );
    // } else

    // if (
    //   // (aiAgentsSelected && aiAgentsSelected.aisettings.length === 0) ||
    //   aiSettingsIsEditing.active &&
    //   aiSettingsIsEditing.type === "addX"
    //   //
    // ) {
    //   return (
    //     <Box
    //       sx={{
    //         display: "flex",
    //         flexDirection: "row",
    //         alignItems: "center",
    //         ml: 2,
    //       }}
    //     >
    //       <Box
    //         sx={{
    //           mr: 1,
    //           display: "flex",
    //           flexDirection: "row",
    //         }}
    //       >
    //         {aiSettingsNewSaving ? (
    //           <Box
    //             sx={{
    //               display: "flex",
    //               alignItems: "center",
    //               justifyContent: "center",
    //               width: "30px",
    //               height: "30px",
    //             }}
    //           >
    //             <CircularProgress
    //               size={12}
    //               sx={{
    //                 p: 0,
    //                 m: 0,
    //               }}
    //               // variant="determinate"
    //             />
    //           </Box>
    //         ) : (
    //           <IconButton
    //             aria-label="Save"
    //             color="primary"
    //             size="small"
    //             // variant="light"
    //             onClick={() => handleOnClickAiSettingsNewSave()}
    //             disabled={isAiSettingsNewSaveDisabled()}
    //             sx={{
    //               "&.MuiIconButton-sizeSmall": {
    //                 fontSize: "16px",
    //               },
    //             }}
    //           >
    //             <DoneIcon fontSize="inherit" />
    //           </IconButton>
    //         )}

    //         <IconButton
    //           aria-label="Cancel"
    //           color="primary"
    //           size="small"
    //           // variant="light"
    //           onClick={handleOnClickAiSettingsAdd}
    //           disabled={isAiSettingsNewCancelDisabled()}
    //           sx={{
    //             // ml: 0.5,
    //             "&.MuiIconButton-sizeSmall": {
    //               fontSize: "16px",
    //             },
    //           }}
    //         >
    //           <ClearIcon fontSize="inherit" />
    //         </IconButton>
    //       </Box>

    //       <Box width={250}>
    //         <FormControl
    //           sx={{
    //             // mt: 3,
    //             // m: 1,
    //             // minWidth: 120
    //             width: "100%",
    //           }}
    //         >
    //           <InputLabel>Add New Settings</InputLabel>
    //           <Select
    //             label="Add New Settings"
    //             value={aiPresetSelected}
    //             disabled={isAiSettingsDisabled()}
    //             onChange={(event) => handleOnChangeNewSettingsFromPreset(event)}
    //           >
    //             <MenuItem value="new">New Blank Setting</MenuItem>
    //             <MenuItem value="duplicate">Duplicate Current Setting</MenuItem>
    //             <ListSubheader
    //               color="primary"
    //               sx={{
    //                 "&.MuiListSubheader-colorPrimary": {
    //                   lineHeight: "38px",
    //                   // backgroundColor: '#efefef',
    //                   // textTransform: "uppercase",
    //                 },
    //               }}
    //             >
    //               Presets
    //             </ListSubheader>
    //             {aiCategoriesDoc &&
    //               aiCategoriesDoc.aipresets &&
    //               aiCategoriesDoc.aipresets.map((s, index) => {
    //                 return (
    //                   <MenuItem key={s.aipresetid} value={s.aipresetid}>
    //                     <Box
    //                       sx={{
    //                         width: "100%",
    //                         display: "flex",
    //                         // flexDirection: 'row',
    //                         alignItems: "center",
    //                         justifyContent: "space-between !important",
    //                       }}
    //                     >
    //                       {s.name}
    //                     </Box>
    //                   </MenuItem>
    //                 );
    //               })}
    //           </Select>
    //         </FormControl>
    //       </Box>
    //     </Box>
    //   );
    // }
    // else if (aiSettingsIsEditing.active && aiSettingsIsEditing.type === "remove") {
    //   return (
    //     <Box
    //       sx={{
    //         display: "flex",
    //         flexDirection: "row",
    //         alignItems: "center",
    //         ml: 2,
    //       }}
    //     >
    //       <Box
    //         sx={{
    //           mr: 1,
    //           display: "flex",
    //           flexDirection: "row",
    //         }}
    //       >
    //         {aiSettingsRemoving ? (
    //           <Box
    //             sx={{
    //               display: "flex",
    //               alignItems: "center",
    //               justifyContent: "center",
    //               width: "30px",
    //               height: "30px",
    //             }}
    //           >
    //             <CircularProgress
    //               size={12}
    //               sx={{
    //                 p: 0,
    //                 m: 0,
    //               }}
    //               // variant="determinate"
    //             />
    //           </Box>
    //         ) : (
    //           <IconButton
    //             aria-label="Remove"
    //             color="primary"
    //             size="small"
    //             // variant="light"
    //             onClick={() => handleOnClickAiSettingsRemoveConfirm()}
    //             disabled={isAiSettingsRemoveDisabled()}
    //             sx={{
    //               "&.MuiIconButton-sizeSmall": {
    //                 fontSize: "16px",
    //               },
    //             }}
    //           >
    //             <DoneIcon fontSize="inherit" />
    //           </IconButton>
    //         )}

    //         <IconButton
    //           aria-label="Cancel"
    //           color="primary"
    //           size="small"
    //           // variant="light"
    //           onClick={handleOnClickAiSettingsRemove}
    //           // disabled={isSendDisabled()}
    //           sx={{
    //             // ml: 0.5,
    //             "&.MuiIconButton-sizeSmall": {
    //               fontSize: "16px",
    //             },
    //           }}
    //         >
    //           <ClearIcon fontSize="inherit" />
    //         </IconButton>
    //       </Box>

    //       <Box
    //         width={250}
    //         // sx={{
    //         //   display: 'flex',
    //         //   alignItems: 'center',
    //         // }}
    //       >
    //         <FormControl
    //           sx={{
    //             // mt: 3,
    //             // m: 1,
    //             // minWidth: 120
    //             width: "100%",
    //           }}
    //         >
    //           <InputLabel>Settings</InputLabel>
    //           <Select
    //             label="Settings"
    //             value={getSelectedSettingsId()}
    //             disabled={true}
    //             onChange={(event) => handleOnChangeSettings(event)}
    //             autoFocus={aiAgentsSelected !== null && aiSettingsSelected === null ? true : false}
    //           >
    //             {aiSettingsDocs &&
    //               aiSettingsDocs.map((s, index) => {
    //                 return (
    //                   <MenuItem key={s.aisettingid} value={s.aisettingid}>
    //                     <Box
    //                       sx={{
    //                         width: "100%",
    //                         display: "flex",
    //                         // flexDirection: 'row',
    //                         alignItems: "center",
    //                         justifyContent: "space-between !important",
    //                       }}
    //                     >
    //                       {s.name}

    //                       {showLinkIcon(s)}
    //                     </Box>
    //                   </MenuItem>
    //                 );
    //               })}
    //           </Select>
    //         </FormControl>
    //       </Box>
    //     </Box>
    //   );
    // }
    // else if (aiSettingsIsEditing.active && aiSettingsIsEditing.type === "editX") {
    //   return (
    //     <Box
    //       sx={{
    //         display: "flex",
    //         flexDirection: "row",
    //         alignItems: "center",
    //         ml: 2,
    //       }}
    //     >
    //       <Box
    //         sx={{
    //           mr: 1,
    //           display: "flex",
    //           flexDirection: "row",
    //         }}
    //       >
    //         {aiSettingsNameSaving ? (
    //           <Box
    //             sx={{
    //               display: "flex",
    //               alignItems: "center",
    //               justifyContent: "center",
    //               width: "30px",
    //               height: "30px",
    //             }}
    //           >
    //             <CircularProgress
    //               size={12}
    //               sx={{
    //                 p: 0,
    //                 m: 0,
    //               }}
    //               // variant="determinate"
    //             />
    //           </Box>
    //         ) : (
    //           <IconButton
    //             aria-label="Save"
    //             color="primary"
    //             size="small"
    //             // variant="light"
    //             onClick={() => handleOnClickAiSettingsNameSave()}
    //             disabled={isAiSettingsNameSaveDisabled()}
    //             sx={{
    //               "&.MuiIconButton-sizeSmall": {
    //                 fontSize: "16px",
    //               },
    //             }}
    //           >
    //             <DoneIcon fontSize="inherit" />
    //           </IconButton>
    //         )}

    //         <IconButton
    //           aria-label="Cancel"
    //           color="primary"
    //           size="small"
    //           // variant="light"
    //           onClick={handleOnClickAiSettingsEdit}
    //           // disabled={isSendDisabled()}
    //           sx={{
    //             // ml: 0.5,
    //             "&.MuiIconButton-sizeSmall": {
    //               fontSize: "16px",
    //             },
    //           }}
    //         >
    //           <ClearIcon fontSize="inherit" />
    //         </IconButton>
    //       </Box>

    //       <Box width={250}>
    //         <TextField
    //           // margin="normal"
    //           required
    //           fullWidth
    //           // id="email"
    //           label="Settings Name"
    //           name="name"
    //           autoComplete="name"
    //           // autoFocus
    //           // value={aiSettingsDataUpdated.data.name}
    //           value={aiSettingsName}
    //           onChange={(e) => handleOnChangeAiSettingsName(e)}
    //           // onChange={(e) => handleOnChangeAiSettingsData(e, 'name')}
    //         />
    //       </Box>
    //     </Box>
    //   );
    // } else {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          ml: 2,
        }}
      >
        <Box name="settingsAdd">
          <IconButton
            aria-label="Add"
            color="primary"
            size="small"
            // variant="light"
            onClick={handleOnClickAiSettingsAdd}
            // disabled={aiSettingsSelected !== null ? false : true}
            disabled={isAiSettingsAddDisabled()}
            sx={{
              // ml: 0.5,
              "&.MuiIconButton-sizeSmall": {
                fontSize: "16px",
              },
            }}
          >
            <AddIcon fontSize="inherit" />
          </IconButton>
        </Box>

        <Box name="settingsRemove">
          <IconButton
            aria-label="Edit"
            color="primary"
            size="small"
            // variant="light"
            onClick={handleOnClickAiSettingsRemove}
            // disabled={aiSettingsSelected !== null ? false : true}
            // disabled={isAiSettingsEditDisabled()}
            disabled={isAiSettingsRemoveDisabled()}
            sx={{
              // ml: 0.5,
              "&.MuiIconButton-sizeSmall": {
                fontSize: "16px",
              },
            }}
          >
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        </Box>

        <Box
          name="settingsEdit"
          sx={{
            mr: 1,
          }}
        >
          <IconButton
            aria-label="Edit"
            color="primary"
            size="small"
            // variant="light"
            onClick={handleOnClickAiSettingsEdit}
            // disabled={aiSettingsSelected !== null ? false : true}
            disabled={isAiSettingsEditDisabled()}
            sx={{
              // ml: 0.5,
              "&.MuiIconButton-sizeSmall": {
                fontSize: "16px",
              },
            }}
          >
            <EditIcon fontSize="inherit" />
          </IconButton>
        </Box>

        <Box
          width={250}
        // sx={{
        //   display: 'flex',
        //   alignItems: 'center',
        // }}
        >
          <FormControl
            sx={{
              // mt: 3,
              // m: 1,
              // minWidth: 120
              width: "100%",
            }}
          >
            <InputLabel>Settings</InputLabel>
            <Select
              label="Settings"
              value={getSelectedSettingsId()}
              disabled={isAiSettingsDisabled()}
              onChange={(event) => handleOnChangeSettings(event)}
              autoFocus={aiAgentsSelected !== null && aiSettingsSelected === null ? true : false}
            >
              {aiSettingsDocs &&
                aiSettingsDocs.map((s, index) => {
                  // if (s.archive === false) {
                  return (
                    <MenuItem key={s.aisettingid} value={s.aisettingid}>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          // flexDirection: 'row',
                          alignItems: "center",
                          justifyContent: "space-between !important",
                        }}
                      >
                        {s.name}

                        {showLinkIcon(s)}
                      </Box>
                    </MenuItem>
                  );
                  // }
                  // else {
                  //   return null;
                  // }
                })}
            </Select>
          </FormControl>
        </Box>
      </Box>
    );
    // }
  };

  const isShowOauthDisabled = () => {
    let disabled = false;
    // if (aiSettingsSelected === null) {
    //   disabled = true;
    // }

    return disabled;
  };

  const isShowInfoDisabled = () => {
    let disabled = false;
    if (aiSettingsSelected === null) {
      disabled = true;
    }

    return disabled;
  };

  //  const isAutomationLinkDisabled = () => {
  //    let disabled = false;
  //
  //    if (aiAgentsSelected === null) {
  //      disabled = true;
  //    }
  //
  //    if (aiSettingsSelected === null) {
  //      disabled = true;
  //    }
  //
  //    if (aiConversation === null) {
  //      disabled = true;
  //    }
  //
  //    if (
  //      aiAgentsSelected !== null &&
  //      aiSettingsSelected !== null &&
  //      aiConversation !== null &&
  //      // aiConversation !== undefined &&
  //      aiConversation.ailinksettingid &&
  //      aiConversation.ailinksettingid !== ""
  //    ) {
  //      disabled = true;
  //    }
  //
  //    if (aiAgentsIsEditing.active === true) {
  //      disabled = true;
  //    }
  //
  //    if (aiSettingsIsEditing.active === true) {
  //      disabled = true;
  //    }
  //
  //    if (aiConversationsIsEditing.active === true) {
  //      disabled = true;
  //    }
  //
  //    return disabled;
  //  };

  const saveLinkedAlertBox = () => {
    const showSnackbar = false;
    if (showSnackbar) {
      return (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={showSaveLinkedAlert}
          onClose={() => setShowSaveLinkedAlert(false)}
        // message="I love snacks"
        // key={vertical + horizontal}
        >
          <Alert
            severity="info"
            variant="filled"
            action={
              <Box>
                <Button color="inherit" size="small" onClick={() => setShowSaveLinkedAlert(false)}>
                  CANCEL
                </Button>
                <Button color="inherit" size="small" onClick={() => setShowSaveLinkedAlert(false)}>
                  CONFIRM
                </Button>
              </Box>
            }
          >
            Are you sure?
          </Alert>
        </Snackbar>
      );
    } else {
      return (
        <Collapse in={showSaveLinkedAlert}>
          <Alert
            severity="info"
            // severity="info"
            variant="filled"
            // variant="outlined"
            action={
              <Box>
                <Button color="inherit" size="small" onClick={() => setShowSaveLinkedAlert(false)}>
                  CANCEL
                </Button>
                <Button color="inherit" size="small" onClick={() => setShowSaveLinkedAlert(false)}>
                  CONFIRM
                </Button>
              </Box>
            }
            sx={{
              // mt: 2,
              borderRadius: 0,
            }}
          >
            Are you sure?
          </Alert>
        </Collapse>
      );
    }
  };

  const handleSavePresetAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSavePresetAlert(false);
  };

  const savedPresetAlertBox = () => {
    const showSnackbar = true;
    if (showSnackbar) {
      return (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{
            mt: 0,
          }}
          open={showSavePresetAlert}
          onClose={handleSavePresetAlertClose}
          autoHideDuration={3000}
        // message="I love snacks"
        // key={vertical + horizontal}
        >
          <Alert
            severity="info"
            variant="filled"
            action={
              <Box>
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleSavePresetAlertClose}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            }
          >
            Template Saved
          </Alert>
        </Snackbar>
      );
    } else {
      return (
        <Collapse in={showSavePresetAlert}>
          <Alert
            severity="info"
            // severity="info"
            variant="filled"
            // variant="outlined"
            action={
              <Box>
                <Button color="inherit" size="small" onClick={() => setShowSavePresetAlert(false)}>
                  CANCEL
                </Button>
                <Button color="inherit" size="small" onClick={() => setShowSavePresetAlert(false)}>
                  CONFIRM
                </Button>
              </Box>
            }
            sx={{
              // mt: 2,
              borderRadius: 0,
            }}
          >
            Are you sure?
          </Alert>
        </Collapse>
      );
    }
  };


  const handleSavePresetAgentAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSavePresetAgentAlert(false);
  };

  const savedPresetAgentAlertBox = () => {
    const showSnackbar = true;
    if (showSnackbar) {
      return (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{
            mt: 0,
          }}
          open={showSavePresetAgentAlert}
          onClose={handleSavePresetAgentAlertClose}
          autoHideDuration={3000}
        // message="I love snacks"
        // key={vertical + horizontal}
        >
          <Alert
            severity="info"
            variant="filled"
            action={
              <Box>
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleSavePresetAgentAlertClose}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            }
          >
            Agent Template Saved
          </Alert>
        </Snackbar>
      );
    } else {
      return (
        <Collapse in={showSavePresetAlert}>
          <Alert
            severity="info"
            // severity="info"
            variant="filled"
            // variant="outlined"
            action={
              <Box>
                <Button color="inherit" size="small" onClick={() => setShowSavePresetAlert(false)}>
                  CANCEL
                </Button>
                <Button color="inherit" size="small" onClick={() => setShowSavePresetAlert(false)}>
                  CONFIRM
                </Button>
              </Box>
            }
            sx={{
              // mt: 2,
              borderRadius: 0,
            }}
          >
            Are you sure?
          </Alert>
        </Collapse>
      );
    }
  };

  // if (clog()) console.log('showAiCustomerSettingsDialog:', showAiCustomerSettingsDialog)
  return (
    <Box>
      {
        // showSaveLinkedAlert && saveLinkedAlertBox()
      }
      {saveLinkedAlertBox()}

      {savedPresetAlertBox()}

      {savedPresetAgentAlertBox()}

      <AiAgentVariablesDialog userData={userData} />
      <AiWebCodeDialog userData={userData} />
      <AiCustomerSettingsDialog userData={userData} />
      <AiPresetAgentsDialog userData={userData} />

      <Box
        name="AiSettingsTopBar"
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          height: 70,
          px: 2,
          backgroundColor: theme.palette.background.bgdarker,
        }}
      >

        {adminStatus.isAdmin && (
          <Box name="backtoadmin"
            sx={{
              // mr: 1
            }}
          >
            <IconButton
              aria-label="Show Info"
              color="primary"
              size="small"
              sx={{
                "&.MuiIconButton-sizeSmall": {
                  fontSize: "16px",
                },
              }}
              // variant="light"
              onClick={handleOnClickBackToAdmin}
            // disabled={isShowInfoDisabled()}
            >
              <SupervisorAccountIcon fontSize="inherit" />
            </IconButton>
          </Box>
        )}

        {rc.description_panel && (
          <Box
            sx={{
              // ml: 2,
              // mr: 2,
            }}
          >
            <IconButton
              aria-label="Show Info"
              color="primary"
              size="small"
              sx={{
                "&.MuiIconButton-sizeSmall": {
                  fontSize: "16px",
                },
              }}
              // variant="light"
              onClick={handleOnClickShowDescriptionPanel}
              disabled={isShowInfoDisabled()}
            >
              <DescriptionIcon fontSize="inherit" />
            </IconButton>
          </Box>
        )}

        <Box
          sx={
            {
              // ml: 2,
            }
          }
        >
          <IconButton
            aria-label="Oauth"
            color="primary"
            size="small"
            sx={{
              "&.MuiIconButton-sizeSmall": {
                fontSize: "16px",
              },
            }}
            // variant="light"
            onClick={handleOnClickOauth}
            disabled={isShowOauthDisabled()}
          >
            <VpnKeyIcon fontSize="inherit" />
          </IconButton>
        </Box>

        <Box name="customersettings">
          <IconButton
            aria-label="customersettingsIcon"
            color="primary"
            size="small"
            sx={{
              "&.MuiIconButton-sizeSmall": {
                fontSize: "16px",
              },
            }}
            // variant="light"
            onClick={handleShowCustomerDialog}
            disabled={isShowCustomerDialogDisabled()}
          >
            <SettingsIcon fontSize="inherit" />
          </IconButton>
        </Box>


        <Box name="conversationslive">
          <IconButton
            aria-label="conversationslive"
            color={aiAgentShowLiveConversations.active === true ? "success" : "primary"}
            size="small"
            sx={{
              "&.MuiIconButton-sizeSmall": {
                fontSize: "16px",
              },
            }}
            // variant="light"
            onClick={handleShowAiAgentLiveConversations}
            disabled={isShowAiAgentLiveConversationsDisabled()}
          >
            <ConversationsIcon fontSize="inherit" />
          </IconButton>
        </Box>

        <Box
          flexGrow={1}
          sx={
            {
              // ml: 2
            }
          }
        ></Box>

        {showAiAgentAddEditDialog()}
        {showAiAgentRemoveDialog()}

        {showAiAgentsEditingControls()}

        {
          //   <Box
          //     sx={{
          //       ml: 1,
          //       mr: 1,
          //     }}
          //   >
          //     <IconButton
          //       aria-label="Link"
          //       color="primary"
          //       size="small"
          //       // variant="light"
          //       onClick={handleOnClickAutomationLink}
          //       // disabled
          //       disabled={isAutomationLinkDisabled()}
          //       sx={{
          //         "&.MuiIconButton-sizeSmall": {
          //           fontSize: "16px",
          //         },
          //       }}
          //     >
          //       <LinkIcon fontSize="10px" />
          //     </IconButton>
          //   </Box>
        }

        {
          // showAiSettingsAddEditDialog()
        }
        {
          // showAiSettingsRemoveDialog()
        }
        {
          // showAiSettingsEditingControls()
        }

        <Box
          sx={{
            ml: 3,
            mr: 1,
          }}
        >
          <Button
            aria-label="Cancel"
            // color="success"
            // variant="contained"
            variant="outlined"
            sx={{
              // minWidth: "70px",
              "&.Mui-disabled": {
                borderColor: theme.palette.grey[200],
                // borderColor: '#cc0000'
              },
            }}
            onClick={handleOnClickCancel}
            disabled={isSaveDisabled()}
          >
            CANCEL
          </Button>
        </Box>
        <Box
          sx={{
            ml: 1,
            mr: 1,
          }}
        >
          {aiSettingsSaving ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "70px",
                height: "30px",
              }}
            >
              <CircularProgress
                size={16}
                sx={{
                  p: 0,
                  m: 0,
                }}
              // variant="determinate"
              />
            </Box>
          ) : (
            <Button
              aria-label="Save"
              color="success"
              variant="contained"
              sx={{
                minWidth: "70px",
              }}
              onClick={handleOnClickSave}
              disabled={isSaveDisabled()}
            >
              SAVE
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(AiSettingsPanel);
