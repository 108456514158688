import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import PropTypes from "prop-types";

import {
  getFirestore,
  collection,
  query,
  where,
  // orderBy
} from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { firebase } from "../code/firebase";
import { processKeywordsUserData } from "../code/ecomacy";

// third-party
import { FixedSizeList } from "react-window";

import { RemoteConfigAuthContext } from "../helpers/RemoteConfigAuthProvider";
import { CustomerAuthContext } from "../helpers/CustomerAuthProvider";

import { AiAuthContext } from "../helpers/AiAuthProvider";
import {setd, debug, setc, clog} from "../helpers/HelperFunctions";

import _ from "lodash";

import {
  useTheme,
  // styled
} from "@mui/material/styles";

import {
  Box,
  //Tab,
  //Tabs,
  Typography,
  IconButton,
  Grid,
  Paper,
  Slider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  CircularProgress,
} from "@mui/material";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import LinkIcon from "@mui/icons-material/LinkOutlined";
import AddIcon from "@mui/icons-material/AddOutlined";
import RemoveIcon from "@mui/icons-material/RemoveOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/EditOutlined";
import SaveIcon from "@mui/icons-material/SaveOutlined";
import ClearIcon from "@mui/icons-material/ClearOutlined";
import DoneIcon from "@mui/icons-material/DoneOutlined";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";

import Container from "@mui/material/Container";
import GridKeywordTimelinePane from "./GridKeywordTimelinePane";
import LoadingPane from "./LoadingPane";
import LoadingSubPane from "./LoadingSubPane";
import { set } from "date-fns";
import { i, re } from "mathjs";

// import MainCard from './MainCard';
// import { getDfsLocationData } from '../code/ecomacy'

// import './GridKeywordsPane.css'
// const auth = getAuth(firebase)

const AiHistoryLivePanel = (props) => {
  const theme = useTheme();


  const { rc } = useContext(RemoteConfigAuthContext);

  const { userData } = props;
  const { adminStatus, setAdminStatus } = useContext(CustomerAuthContext);


  const { aiAgentShowLiveConversations, setAiAgentShowLiveConversations } = useContext(AiAuthContext);
  const { aiLiveLinkSettingsDocs, setAiLiveLinkSettingsDocs } = useContext(AiAuthContext);
  const { getAiLiveLinkSettingsDocs } = useContext(AiAuthContext);
  const { aiLiveLinkSettingsSelected, setAiLiveLinkSettingsSelected } = useContext(AiAuthContext);

  const { aiLiveConversationsDocs, setAiLiveConversationsDocs } = useContext(AiAuthContext);
  const { getAiLiveConversationsDocs } = useContext(AiAuthContext);
  const { aiLiveConversationsSelected, setAiLiveConversationsSelected } = useContext(AiAuthContext);

  const { aiLiveConversation, setAiLiveConversation } = useContext(AiAuthContext);

  const { refreshAiLiveConversationSettingsData, setRefreshAiLiveConversationSettingsData } = useContext(AiAuthContext);



  const { aiAgentsSelected, setAiAgentsSelected } = useContext(AiAuthContext);

  const { aiSettingsData, setAiSettingsData } = useContext(AiAuthContext);
  const { aiSettingsDataUpdated, setAiSettingsDataUpdated } = useContext(AiAuthContext);

  const { aiSettingsDataConversationIdRef } = useContext(AiAuthContext);

  const { aiModelSelected, setAiModelSelected } = useContext(AiAuthContext);

  const { aiSettingsDataRef } = useContext(AiAuthContext);
  const { aiModelSelectedRefresh, setAiModelSelectedRefresh } = useContext(AiAuthContext);
  const { aiStrategySelectedRefresh, setAiStrategySelectedRefresh } = useContext(AiAuthContext);

  const { aiSettingsSelected, setAiSettingsSelected } = useContext(AiAuthContext);

  const { aiConversationsDocs, setAiConversationsDocs } = useContext(AiAuthContext);
  const { aiConversationsDocsRefresh, setAiConversationsDocsRefresh } = useContext(AiAuthContext);

  const { aiConversationsSelected, setAiConversationsSelected } = useContext(AiAuthContext);
  const { getAiConversationsDocs } = useContext(AiAuthContext);
  const { aiConversationDuplicatedRefresh, setAiConversationDuplicatedRefresh } = useContext(AiAuthContext);

  const { aiConversation, setAiConversation } = useContext(AiAuthContext);

  const { aiAgentsIsEditing, setAiAgentsIsEditing } = useContext(AiAuthContext);
  const { aiSettingsIsEditing, setAiSettingsIsEditing } = useContext(AiAuthContext);
  const { aiConversationsIsEditing, setAiConversationsIsEditing } = useContext(AiAuthContext);
  const { aiConversationsIsRemoving, setAiConversationsIsRemoving } = useContext(AiAuthContext);

  const { refreshAiConversationSettingsData, setRefreshAiConversationSettingsData } = useContext(AiAuthContext);

  const [aiConversationsName, setAiConversationsName] = useState("");
  const { saveAiConversationsName } = useContext(AiAuthContext);
  const [aiConversationsNameSaving, setAiConversationsNameSaving] = useState(false);

  const { aiConversationFetchData, setAiConversationFetchData } = useContext(AiAuthContext);

  const { duplicateAiConversation } = useContext(AiAuthContext);
  const { removeAiConversation } = useContext(AiAuthContext);

  const { saveAiSettingsAutomationLink } = useContext(AiAuthContext);
  const [automationLinkSaving, setAutomationLinkSaving] = useState(false);

  const { saveAiSettingsTemplate } = useContext(AiAuthContext);
  const [savePresetSaving, setSavePresetSaving] = useState(false);
  const { showSavePresetAlert, setShowSavePresetAlert } = useContext(AiAuthContext);
  const { saveAiPreset, setsaveAiPreset } = useContext(AiAuthContext);

  const [init, setInit] = useState(false);

  const [model, setModel] = useState({});

  // if (clog()) console.log('AiModelPanel aiSettingsData', aiSettingsData);

  useEffect(() => {
    setInit(false);
  }, [init]);



  // aiAgentShowLiveConversations is activated
  // or...
  // aiagent is changed while aiAgentShowLiveConversations currently activated
  // new
  useEffect(() => {
    if (
      !init &&
      aiLiveLinkSettingsDocs.aiagentid !== aiAgentShowLiveConversations.aiagentid
      // need extra tests for ailinksettingid
    ) {
      // reset aiLiveConversationsDocs
      setAiLiveLinkSettingsDocs({
        aigentid: "",
        // ailinksettingid: "all",
        data: [],
      });
      // aiAgentShowLiveConversations is active and
      // aiagentid is different than aiLiveConversationsDocs.aiagentid
      fetchAiLiveLinkSettings()
        // make sure to catch any error
        .catch(console.error);
    }
  }, [aiAgentShowLiveConversations]);

  const fetchAiLiveLinkSettings = useCallback(async () => {
    const returnData = await getAiLiveLinkSettingsDocs(userData, aiAgentShowLiveConversations);
    if (clog()) console.log("fetchAiLiveConversations debugger");
    if (
      returnData?.data?.aiagentid !== "" &&
      returnData?.data?.aiagentid !== aiLiveLinkSettingsSelected.aiagentid
    ) {
      // set in getAiLiveLinkSettingsDocs function > setAiLiveLinkSettingsDocs(returnData.data)
      setAiLiveLinkSettingsSelected({
        name: "",
        aiagentid: returnData.data.aiagentid,
        ailinksettingid: "all",
      });

    }
  }, [getAiLiveLinkSettingsDocs]);


  useEffect(() => {

    if (
      !init &&
      aiAgentShowLiveConversations.active === true &&
      aiLiveLinkSettingsDocs.aiagentid !== ""
    ) {

      if (
        // aiagent is different
        aiLiveLinkSettingsSelected.aiagentid !== aiLiveConversationsDocs.aiagentid
        ||
        // aiagent is same but ailinksettingid is different
        aiLiveLinkSettingsSelected.aiagentid === aiLiveConversationsDocs.aiagentid &&
        aiLiveLinkSettingsSelected.ailinksettingid !== aiLiveConversationsDocs.ailinksettingid
      ) {
        // reset aiLiveConversationsDocs
        setAiLiveConversationsDocs({
          aigentid: "",
          ailinksettingid: "",
          data: [],
        });
        // aiAgentShowLiveConversations is active and
        // aiagentid is different than aiLiveConversationsDocs.aiagentid
        fetchAiLiveConversations()
          // make sure to catch any error
          .catch(console.error);
      }
    }
  }, [aiLiveLinkSettingsSelected]);
  // }, [aiLiveLinkSettingsDocs]);

  const fetchAiLiveConversations = useCallback(async () => {

    const returnData = await getAiLiveConversationsDocs(userData, aiLiveLinkSettingsSelected);
    if (clog()) console.log("fetchAiLiveConversations debugger");
    if (returnData?.data?.data) {
      // set in getAiLiveConversationsDocs function > setAiLiveConversationsDocs(returnData.data)
      // if aiLiveConversationsDocs exist, set to live one
      if (returnData.data.data.length > 0) {
        setAiLiveConversationsSelected(returnData.data.data[0]);
      } else {
        // no conversations exist
        setAiLiveConversationsSelected(null);
      }
    }
  }, [getAiLiveConversationsDocs]);

  // needs to be written
  // useEffect(() => {
  //   if (
  //     !init &&
  //     aiAgentShowLiveConversations.active === true &&
  //     aiLiveLinkSettingsDocs.aiagentid !== ""
  //   ) {
  //     if (
  //       // aiagent is different
  //       aiLiveLinkSettingsSelected.aiagentid !== aiLiveConversationsDocs.aiagentid
  //       ||
  //       // aiagent is same but ailinksettingid is different
  //       aiLiveLinkSettingsSelected.aiagentid === aiLiveConversationsDocs.aiagentid &&
  //       aiLiveLinkSettingsSelected.ailinksettingid !== aiLiveConversationsDocs.ailinksettingid
  //     ) {
  //       // reset aiLiveConversationsDocs
  //       setAiLiveConversationsDocs({
  //         aigentid: "",
  //         ailinksettingid: "",
  //         data: [],
  //       });
  //       // aiAgentShowLiveConversations is active and
  //       // aiagentid is different than aiLiveConversationsDocs.aiagentid
  //       fetchAiLiveConversations()
  //         // make sure to catch any error
  //         .catch(console.error);
  //     }
  //   }
  //  }, [aiLiveLinkSettingsDocs]);


  // aiAgentShowLiveConversations is activated
  // or...
  // aiagent is changed while aiAgentShowLiveConversations currently activated
  // old
  // useEffect(() => {
  //   if (
  //     !init &&
  //     aiAgentShowLiveConversations.active === true &&
  //     aiAgentShowLiveConversations.aiagentid !== aiLiveConversationsDocs.aiagentid
  //   ) {
  //     // reset aiLiveConversationsDocs
  //     setAiLiveConversationsDocs({
  //       aigentid: "",
  //       data: [],
  //     });
  //     // aiAgentShowLiveConversations is active and
  //     // aiagentid is different than aiLiveConversationsDocs.aiagentid
  //     fetchAiLiveConversations()
  //       // make sure to catch any error
  //       .catch(console.error);
  //   }
  // }, [aiAgentShowLiveConversations]);

  // const fetchAiLiveConversations = useCallback(async () => {
  //   const returnData = await getAiLiveConversationsDocs(userData, aiAgentShowLiveConversations);
  //   if (clog()) console.log("fetchAiLiveConversations debugger");
  //   if (returnData?.data?.data) {
  //     // set in getAiLiveConversationsDocs function > setAiLiveConversationsDocs(returnData.data)
  //     // if aiLiveConversationsDocs exist, set to live one
  //     if (returnData.data.data.length > 0) {
  //       setAiLiveConversationsSelected(returnData.data.data[0]);
  //     } else {
  //       // no conversations exist
  //       setAiLiveConversationsSelected(null);
  //     }
  //   }
  // }, [getAiLiveConversationsDocs]);


  // aiagent is changed
  useEffect(() => {
    if (
      !init &&
      aiAgentShowLiveConversations.active === true &&
      aiAgentShowLiveConversations.aiagentid !== aiAgentsSelected.aiagentid
    ) {

      // aiAgentShowLiveConversations is active and
      // aiagentid is different than aiAgentsSelected.aiagentid
      setAiAgentShowLiveConversations({
        active: true,
        aiagentid: aiAgentsSelected.aiagentid,
      });
    }
  }, [aiAgentsSelected]);

  useEffect(() => {
    if (refreshAiLiveConversationSettingsData && aiLiveConversation) {
      let settingsData = null;

      // unpack aisettings data
      // if (aiConversation && aiConversation.aisettings && aiConversation.aisettings !== "") {
      //   settingsData = JSON.parse(aiConversation.aisettings);
      // }
      if (aiConversation && aiConversation.aisettings) {
        settingsData = aiConversation.aisettings;
      }
      if (settingsData) {
        // if (debug()) debugger
        setAiSettingsData(settingsData);
        setAiSettingsDataUpdated(settingsData);
        // set ref for comparisons after settings save
        aiSettingsDataRef.current = settingsData;

        //set ref for use in QuestionPanel to compare improve prompt data
        aiSettingsDataConversationIdRef.current = aiConversation.aiconversationid;
      } else {
        // BT ZZZ what to do here?
        // no aisettings data in aiconversation
        setAiSettingsData(null);
        setAiSettingsDataUpdated(null);
        // set ref for comparisons after settings save
        aiSettingsDataRef.current = null;

        //set ref for use in QuestionPanel to compare improve prompt data
        aiSettingsDataConversationIdRef.current = aiConversation.aiconversationid;
      }
      // trigger useEffect in AiModelPanel
      setAiModelSelectedRefresh(true);
      // trigger useEffect in AiQuestionsPanel
      setAiStrategySelectedRefresh(true);

      setRefreshAiConversationSettingsDataX(false);
    }
  }, [refreshAiLiveConversationSettingsData]);

  function renderRow(props) {
    const { index, style } = props;

    return (
      <ListItem style={style} key={index} component="div" disablePadding divider>
        <ListItemButton onClick={handleListItemButtonOnClick()}>
          <ListItemText primary={`Item ${index + 1}`} />
        </ListItemButton>
      </ListItem>
    );
  }

  const handleListItemButtonOnClick = (selectedConversationDoc) => {
    setAiLiveConversationsSelected(selectedConversationDoc);
  };

  const isIconButtonDisabled = (conversation) => {
    let disabled = false;

    if (aiAgentsIsEditing.active || aiConversationsIsEditing.active) {
      disabled = true;
    }

    if (aiSettingsIsEditing.active && aiSettingsIsEditing.type !== "improvePromptProcessing") {
      disabled = true;
    }
    // is prompt improve in progress
    // if (conversation && conversation.improveprocessing) {
    //   disabled = true;
    // }

    return disabled;
  };

  const linkIconActiveDisabled = () => {
    let linkColor = "primary.main";

    if (aiAgentsIsEditing.active || aiSettingsIsEditing.active || aiConversationsIsEditing.active) {
      linkColor = "grey.300";
    }

    return linkColor;
  };
  // const showAiLinkSettingsLink = (conversation) => {
  //   if (conversation && conversation.ailinksettingid && conversation.ailinksettingid !== "") {
  //     //
  //     return (
  //       <LinkIcon
  //         // color="primary"
  //         sx={{
  //           // color: "primary.main",
  //           color: linkIconActiveDisabled(),
  //           fontSize: 16,
  //         }}
  //       />
  //     );
  //   } else {
  //     return <></>;
  //   }
  // };

  // generic for controls
  const isAiHistoryEditDisabled = () => {
    let disabled = false;

    if (aiAgentsIsEditing.active || aiSettingsIsEditing.active || aiConversationsIsEditing.active) {
      disabled = true;
    }

    return disabled;
  };

  const isAiHistoryRemoveDisabled = () => {
    let disabled = false;

    if (aiAgentsIsEditing.active || aiSettingsIsEditing.active || aiConversationsIsEditing.active) {
      disabled = true;
    }

    // one conversation must exist
    if (aiConversationsDocs && aiConversationsDocs.length < 2) {
      disabled = true;
    }

    // selected conversation has ailinksettingid string
    if (aiConversationsSelected && aiConversationsSelected.ailinksettingid && aiConversationsSelected.ailinksettingid !== "") {
      disabled = true;
    }

    return disabled;
  };

  const handleOnChangeAiConversationsName = (event) => {
    const val = event.target.value;
    if (clog()) console.log("event:", event.target.value);
    setAiConversationsName(val);
  };

  const handleOnClickAiConversationsEdit = () => {
    //
    if (aiConversationsIsEditing.active === false) {
      let name = "";
      if (aiConversationsSelected && aiConversationsSelected.name) {
        name = aiConversationsSelected.name;
      }
      setAiConversationsName(name);

      // activate edit mode
      setAiConversationsIsEditing({
        active: true,
        type: "edit",
      });
    } else {
      // deactivate edit mode
      setAiConversationsIsEditing({
        active: false,
        type: "",
      });
    }
  };

  const handleOnClickAiConversationsNameSave = async () => {
    //
    // setAiSettingsName(name)
    setAiConversationsNameSaving(true);

    // set a state so that aiChat knows to not refetch conversation
    // will be set back to true in AiChatPanel useEffect [aiConversationsSelected]
    setAiConversationFetchData(false);

    const result = await saveAiConversationsName(aiConversationsSelected.aiconversationid, aiConversationsName);

    if (result && result.data && result.data.aiconversationid && result.data.aiconversationid !== "") {
      // update aiConversationsSelected with new name
      let updatedAiConversationsSelected = { ...aiConversationsSelected };
      updatedAiConversationsSelected.name = result.data.name;
      setAiConversationsSelected(updatedAiConversationsSelected);
    }

    setAiConversationsNameSaving(false);

    // deactivate edit mode
    setAiConversationsIsEditing({
      active: false,
      type: "",
    });
  };

  const isAiConversationsNameSaveDisabled = () => {
    let disabled = false;
    if (aiConversationsName === "") {
      disabled = true;
    }

    if (aiConversationsName === aiConversationsSelected.name) {
      disabled = true;
    }

    return disabled;
  };

  const isSavePresetDisabled = () => {
    let disabled = false;

    if (aiAgentsSelected === null) {
      disabled = true;
    }

    if (aiSettingsSelected === null) {
      disabled = true;
    }

    if (aiConversation === null) {
      disabled = true;
    }

    if (aiAgentsIsEditing.active === true) {
      disabled = true;
    }

    if (aiSettingsIsEditing.active === true) {
      disabled = true;
    }

    if (aiConversationsIsEditing.active === true) {
      disabled = true;
    }

    // make sure that module and strategy exist and are not empty
    // should aiSettingsDataUpdated or aiSettingsData
    if (aiSettingsData && aiSettingsData.data) {
      if (
        (aiSettingsData.data.module && aiSettingsData.data.module === "") ||
        (aiSettingsData.data.strategy && aiSettingsData.data.strategy === "")
      ) {
        disabled = true;
      }
    } else {
      disabled = true;
    }

    return disabled;
  };

  const handleOnClickSavePresetConfirm = async () => {
    if (clog()) console.log("handleOnClickSavePreset");
    // setAiSettingsName(name)
    setSavePresetSaving(true);

    const result = await saveAiPreset(userData);

    if (result && result.data) {
      // what to do here?
      if (clog()) console.log("handleOnClickSavePreset result", result.data);
    } else {
      if (clog()) console.log("handleOnClickSavePreset result", result.error);
    }

    // show alert
    setShowSavePresetAlert(true);
    setSavePresetSaving(false);
    //
    // deactivate edit mode
    setAiConversationsIsEditing({
      active: false,
      type: "",
    });
  };

  const handleOnClickSavePreset = async () => {
    //
    if (aiConversationsIsEditing.active === false) {
      // activate edit mode
      setAiConversationsIsEditing({
        active: true,
        type: "save",
      });
    } else {
      // deactivate edit mode
      setAiConversationsIsEditing({
        active: false,
        type: "",
      });
    }
  };

  const isAutomationLinkDisabled = () => {
    let disabled = false;

    if (aiAgentsSelected === null) {
      disabled = true;
    }

    if (aiSettingsSelected === null) {
      disabled = true;
    }

    if (aiConversation === null) {
      disabled = true;
    }

    if (
      aiAgentsSelected !== null &&
      aiSettingsSelected !== null &&
      aiConversation !== null &&
      // aiConversation !== undefined &&
      aiConversation.ailinksettingid &&
      aiConversation.ailinksettingid !== ""
    ) {
      disabled = true;
    }

    if (aiAgentsIsEditing.active === true) {
      disabled = true;
    }

    if (aiSettingsIsEditing.active === true) {
      disabled = true;
    }

    if (aiConversationsIsEditing.active === true) {
      disabled = true;
    }

    return disabled;
  };

  const handleOnClickAutomationLinkConfirm = async () => {
    if (clog()) console.log("handleOnClickAutomationLink");
    // setAiSettingsName(name)
    setAutomationLinkSaving(true);

    const result = await saveAiSettingsAutomationLink(userData);

    if (result && result.data) {
      // what to do here?
      if (clog()) console.log("handleOnClickAutomationLink result", result.data);
    } else {
      if (clog()) console.log("handleOnClickAutomationLink result", result.error);
    }
    setAutomationLinkSaving(false);

    // deactivate edit mode
    setAiConversationsIsEditing({
      active: false,
      type: "",
    });
  };

  const handleOnClickAutomationLink = async () => {
    //
    if (aiConversationsIsEditing.active === false) {
      // activate edit mode
      setAiConversationsIsEditing({
        active: true,
        type: "link",
      });
    } else {
      // deactivate edit mode
      setAiConversationsIsEditing({
        active: false,
        type: "",
      });
    }
  };

  const handleOnClickAiConversationsAdd = async () => {
    const result = await duplicateAiConversation(userData, aiConversation);

    if (result && result.data && result.data.aiconversationid && result.data.aiconversationid !== "") {
      // const val = result.data.aiconversationid

      // trigger useEffect in AiHistoryPanel to activate latest conversation
      setAiConversationDuplicatedRefresh(true);
    }

    // deactivate edit mode
    setAiConversationsIsEditing({
      active: false,
      type: "",
    });
  };

  const handleOnClickRemoveConfirm = async () => {
    setAiConversationsIsRemoving(true);

    const result = await removeAiConversation(userData, aiConversationsSelected);

    if (result && result.data && result.data.aiconversations && result.data.aiconversations.length > 0) {
      // trigger refresh of aiConversationsDocs
      setAiConversationsDocsRefresh(true);
    }
    setAiConversationsIsRemoving(false);

    // const result = await removeAiConversation(userData, aiConversationsSelected.aiconversationid);

    // if (result && result.data && result.data.aiconversationid && result.data.aiconversationid !== "") {
    //   // trigger refresh of aiConversationsDocs
    //   setAiConversationsDocsRefresh(true);
    // }


    // deactivate edit mode
    setAiConversationsIsEditing({
      active: false,
      type: "",
    });
  };

  const handleOnClickAiConversationsRemove = () => {
    //
    if (aiConversationsIsEditing.active === false) {
      // activate edit mode
      setAiConversationsIsEditing({
        active: true,
        type: "remove",
      });
    } else {
      // deactivate edit mode
      setAiConversationsIsEditing({
        active: false,
        type: "",
      });
    }
  };

  const handleOnChangeLinkSetting = (event) => {
    const val = event.target.value;

    if (val === "all") {
      setAiLiveLinkSettingsSelected({
        name: "",
        aiagentid: aiAgentShowLiveConversations.aiagentid,
        ailinksettingid: "all",
      });
    } else {
      // find val in aiLiveLinkSettingsDocs
      const selectedSetting = aiLiveLinkSettingsDocs.data.find((s) => s.ailinksettingid === val);
      if (selectedSetting) {
        setAiLiveLinkSettingsSelected({
          name: selectedSetting.name,
          aiagentid: selectedSetting.aiagentid,
          ailinksettingid: selectedSetting.ailinksettingid,
        });
      }
    }
    return;
  };

  const showAiLiveConversationsFilterControls = () => {

    // setAiLiveLinkSettingsDocs
    return (
      <Box
        name={"historyControls"}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          // justifyContent: "flex-end",
          justifyContent: "space-between",
          px: 2,
          py: 2,
          // pb: 1,
          borderStyle: "solid",
          borderWidth: 1,
          borderColor: theme.palette.border.normal,
          borderBottom: 0,
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
          backgroundColor: theme.palette.background.paper,
        }}
      >

        <FormControl
          sx={{
            // m: 1,
            // minWidth: 120
            width: "100%",
            "& .MuiInputLabel-root.MuiInputLabel-shrink": {
              // backgroundColor: theme.palette.background.bg,
              backgroundColor: theme.palette.background.paper,
            },
          }}
        >
          <InputLabel>Settings</InputLabel>
          <Select
            // id="demo-simple-select"
            value={aiLiveLinkSettingsSelected.ailinksettingid}
            label="Settings"
            // disabled={true}
            // readOnly={isStrategyReadOnly()}
            onChange={(event) => handleOnChangeLinkSetting(event)}
          >
            <MenuItem key={'all'} value={'all'}>
              All Settings
            </MenuItem>

            {aiLiveLinkSettingsDocs?.data?.map((s, index) => {
              return (
                <MenuItem key={s.ailinksettingid} value={s.ailinksettingid}>
                  {s.name}
                </MenuItem>
              );
            })}

          </Select>
        </FormControl>


      </Box>
    );
  };

  if (init || aiLiveConversationsDocs.aigentid === "") {
    return <LoadingSubPane spin={true} />;
  }

  if (aiLiveConversationsDocs.data && aiLiveConversationsDocs.data.length > 0) {
    const conversationsCount = aiLiveConversationsDocs.length;

    //    <Box
    //    sx={{
    //      width: '100%',
    //      // height={400}
    //      height: '100%',
    //      bgcolor: 'background.paper',
    //      overflow: 'hidden'
    //    }}
    //  >

    return (
      <Box
        name={"historyPanel"}
        height={"100%"}
        // bgcolor="#cc0000"
        sx={{
          display: "flex",
          flexDirection: "column",
          // maxWidth: "260px",
          // overflow: 'hidden',
        }}
      >
        {showAiLiveConversationsFilterControls()}

        <Box
          name={"historyList"}
          sx={{
            flexGrow: 1,
            overflow: "hidden",
            height: "100%",
          }}
        >
          {
            // <Typography>Ai Chat Panel</Typography>
          }

          <Box
            // component={wrapper}
            // ref={wrapper} // Option 1: use ref to scroll to bottom
            id={"historyScrollWrapper"}
            sx={{
              backgroundColor: theme.palette.background.paper,
              maxHeight: "100%",
              // flexGrow: 1,
              overflow: "auto",
              // display: 'flex', // Option 2: reverse column so that the last message is at the top
              // flexDirection: "column-reverse" // Option 2: would need to reverse the order of the messages
            }}
          >
            <List
              sx={{
                p: 0,
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: theme.palette.border.normal,
                borderBottom: 0,
                borderBottomLeftRadius: "4px",
                borderBottomRightRadius: "4px",
              }}
            >
              {aiLiveConversationsDocs.data.map((c, index) => {
                // const dateTest = c.createdon.toDate()
                let dateString = "";
                if (c.createdon && c.createdon.seconds) {
                  const date = new Date(c.createdon.seconds * 1000);
                  dateString = date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear();
                }

                if (1 === 2) {

                }
                else {
                  return (
                    <ListItem
                      key={c.ailiveconversationid}
                      disablePadding
                      // divider
                      sx={{
                        // backgroundColor: aiConversationsSelected && aiConversationsSelected.aiconversationid === c.aiconversationid ? "grey.100" : "transparent",
                        // color: aiConversationsSelected && aiConversationsSelected.aiconversationid === c.aiconversationid ? theme.palette.primary.contrastText : 'inherit'
                        borderBottomColor: theme.palette.border.normal,
                        borderBottomStyle: "solid",
                        borderBottomWidth: "1px",
                        // pr: 0,
                      }}
                    >
                      <ListItemButton
                        onClick={() => handleListItemButtonOnClick(c)}
                        disabled={isIconButtonDisabled(c)}
                        sx={{
                          pr: "8px",
                          minHeight: "46px",
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            // justifyContent: 'space-between',
                          }}
                        >
                          <Box
                            sx={{
                              flexGrow: 1,
                              // backgroundColor: '#cc0000',
                              overflow: "hidden",
                            }}
                          >
                            <Typography
                              // nowrap
                              sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                fontWeight: aiLiveConversationsSelected && aiLiveConversationsSelected.ailiveconversationid === c.ailiveconversationid ? "bold" : "normal",
                                color: aiLiveConversationsSelected && aiLiveConversationsSelected.ailiveconversationid === c.ailiveconversationid ? theme.palette.primary.main : "inherit",
                              }}
                            >
                              {c.name && c.name !== "" ? c.name : c.ailiveconversationid}
                            </Typography>


                            {
                              rc.history_show_conversation_id && (
                                <ListItemText
                                  // BT temp to show conversation id
                                  // secondary={c.aiconversationid}
                                  primary={c.name && c.name === "" ? c.name : c.ailiveconversationid}
                                  // sx={{
                                  //   '&.MuiListItemText-secondary': {}
                                  // }}
                                  sx={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                />
                              )
                            }
                          </Box>
                          <Box
                            sx={{
                              width: "16px",
                              maxWidth: "16px",
                              pt: "6px",
                              ml: 1,
                              // backgroundColor: 'green',
                            }}
                          >
                            {
                              // showAiLinkSettingsLink(c)
                            }
                          </Box>

                        </Box>
                      </ListItemButton>
                    </ListItem>
                  );
                }
              })}

              {
                //  <ListItem disablePadding divider><ListItemButton><ListItemText primary='hello' /></ListItemButton></ListItem>
              }
            </List>
          </Box>
        </Box>
      </Box>
    );
    // return (
    //   <Box
    //     sx={{
    //       width: '100%',
    //       // height={400}
    //       height: '100%',
    //       bgcolor: 'background.paper'
    //     }}
    //   >
    //     <FixedSizeList
    //       // height={400}
    //       height='100%'
    //       width="100%"
    //       itemSize={46}
    //       itemCount={200}
    //       overscanCount={5}
    //     >
    //       {renderRow}
    //     </FixedSizeList>
    //   </Box>
    // );
  }

  if (aiLiveConversationsDocs.data && aiLiveConversationsDocs.data.length === 0) {
    // return (
    //   <Box>
    //     <Typography>No conversations found</Typography>
    //   </Box>
    // );
    return (
      <Box
        name={"historyPanel"}
        height={"100%"}
        // bgcolor="#cc0000"
        sx={{
          display: "flex",
          flexDirection: "column",
          // maxWidth: "260px",
          // overflow: 'hidden',
        }}
      >
        {showAiLiveConversationsFilterControls()}

        <Box
          name={"historyList"}
          sx={{
            flexGrow: 1,
            overflow: "hidden",
            height: "100%",
          }}
        >
          <Box
            id={"historyScrollWrapper"}
            sx={{
              backgroundColor: theme.palette.background.paper,
              maxHeight: "100%",
              // flexGrow: 1,
              overflow: "auto",
            }}
          >
            <List
              sx={{
                p: 0,
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: theme.palette.border.normal,
                borderBottom: 0,
                borderBottomLeftRadius: "4px",
                borderBottomRightRadius: "4px",
              }}
            >

              <ListItem
                disablePadding
                // divider
                sx={{
                  // backgroundColor: aiConversationsSelected && aiConversationsSelected.aiconversationid === c.aiconversationid ? "grey.100" : "transparent",
                  // color: aiConversationsSelected && aiConversationsSelected.aiconversationid === c.aiconversationid ? theme.palette.primary.contrastText : 'inherit'
                  borderBottomColor: theme.palette.border.normal,
                  borderBottomStyle: "solid",
                  borderBottomWidth: "1px",
                  // pr: 0,
                  pr: "8px",
                  minHeight: "46px",

                }}
              >

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    // justifyContent: 'space-between',
                  }}
                >
                  <Box
                    sx={{
                      flexGrow: 1,
                      // backgroundColor: '#cc0000',
                      overflow: "hidden",
                      pl: 2,

                    }}
                  >
                    <Typography
                      // nowrap
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        fontWeight: "normal",
                        color: aiLiveConversationsSelected && aiLiveConversationsSelected.ailiveconversationid === c.ailiveconversationid ? theme.palette.primary.main : "inherit",
                      }}
                    >
                      No Conversations
                    </Typography>


                  </Box>
                  <Box
                    sx={{
                      width: "16px",
                      maxWidth: "16px",
                      pt: "6px",
                      ml: 1,
                      // backgroundColor: 'green',
                    }}
                  >
                  </Box>

                </Box>
              </ListItem>
            </List>
          </Box>
        </Box>
      </Box>
    );
  }
};

export default React.memo(AiHistoryLivePanel);
