import React, { useState, useEffect, useContext } from "react";
// import PropTypes from 'prop-types';
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebase } from "../code/firebase";
import { getUrlParams } from "../code/ecomacy";

import {setd, debug, setc, clog} from "../helpers/HelperFunctions";

// import { useTheme } from '@mui/material/styles';

// import Container from '@mui/material/Container';
import AiPane from "./AiPane";
import LoadingPane from "./LoadingPane";

// import MainCard from './MainCard';
// import './GridKeywordsPane.css'
import { CustomerAuthContext } from "../helpers/CustomerAuthProvider";

const AiContainer = (routerData) => {
  // const theme = useTheme();

  const auth = getAuth(firebase);

  const [user, loadingUser, errorUser] = useAuthState(auth);

  const { adminStatus, setAdminStatus } = useContext(CustomerAuthContext);
  const { getSetAdminStatus } = useContext(CustomerAuthContext);

  const { customerUserData, setCustomerUserData } = useContext(CustomerAuthContext);
  const { getCustomerUserData } = useContext(CustomerAuthContext);

    const { urlDataParams } = useContext(CustomerAuthContext);


  // BT: this should come from url
  // const tempUserId = "MbD0GzwDZONMouvApsbE3lgQEqy2"
  // const tempLocationId = "NG8AEc8nheLEMNYfo3dn"
  /*
  let userId = ''
  if (routerData.props && routerData.props.userId) {
    userId = routerData.props.userId
  }

  let locationId = ''
  if (routerData.props && routerData.props.locationId) {
    locationId = routerData.props.locationId
  }

  let lat = ''
  if (routerData.props && routerData.props.lat) {
    lat = routerData.props.lat
  }

  let lng = ''
  if (routerData.props && routerData.props.lng) {
    lng = routerData.props.lng
  }

  let website = ''
  if (routerData.props && routerData.props.website) {
    website = routerData.props.website
  }

  let postcode = ''
  if (routerData.props && routerData.props.postcode) {
    postcode = routerData.props.postcode
  }
  */
  let loaded = false;
  let userDataObj = {
    userId: "",
    locationId: "",
    email: "",
    type: "",
    // lat: null,
    // lng: null,
    // website: '',
    // postcode: '',
  };
  const [userData, setUserData] = useState({
    userId: "",
    locationId: "",
    email: "",
    type: "",
    // lat: null,
    // lng: null,
    // website: '',
    // postcode: '',
  });

  const [userLoaded, setUserLoaded] = useState(false);

  useEffect(() => {
    if (
      userDataObj.userId !== "" &&
      userDataObj.locationId !== "" &&
      // && userDataObj.email !== ''
      userLoaded === false
    ) {
      setUserData(userDataObj);
      setUserLoaded(true);
    } else if (loaded === true && userLoaded === false) {
      setUserLoaded(true);
    }

    //setUser({ pending: true, value: undefined })
    // fetchUser().then((fetchedUser) => {
    //  setUser({ pending: true, value: fetchedUser })
    //})
  }, [userDataObj]);

  // NEW WAY
  useEffect(() => {
    const getUserStatus = async () => {
      await getSetAdminStatus();
    };

    if (userLoaded) {
      getUserStatus()
        // make sure to catch any error
        .catch(console.error);
    }
  }, [userLoaded]);

  useEffect(() => {
    const requestCustomerDoc = async () => {
      const res = await getCustomerUserData();
      const test = res.data.oath

      // customer exists so check for oauth
      if (res?.data && !res.data.oath) {
        // missing oauth data
        redirectOauth();
      }
      //      let regid = '';
      //      if (rid) {
      //        regid = rid;
      //      }
      //      if (clog()) console.log({regid});
    };

    if (adminStatus.loaded === true) {
      requestCustomerDoc();
    }

    return () => {
      // if (clog()) console.log("component unmount");
      // this now gets called when the component unmounts
    };
  }, [adminStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  const redirectOauth = (event) => {
    if (clog()) console.log("redirectOauth event for oauth redirect");
    const redirect = import.meta.env.VITE_REACT_APP_OOATH_REDIRECT;
    const clientid = import.meta.env.VITE_REACT_APP_OOATH_CLIENTID;
    const scopes = [
      "contacts.write",
      "contacts.readonly",
      "conversations/message.readonly",
      "conversations/message.write",
      "conversations.write",
      "conversations.readonly",
    ];
    const scope = scopes.join(" ");

    if (clog()) console.log("redirect", redirect);
    if (clog()) console.log("clientid", clientid);

    const url = `https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=${redirect}/oauth/callback&client_id=${clientid}&scope=${scope}`;
    window.location.href = url;
  };
  // OLD WAY
  //   useEffect(() => {
  //     const requestCustomerDoc = async () => {
  //       const res = await getCustomerUserData()
  //       //      let regid = '';
  //       //      if (rid) {
  //       //        regid = rid;
  //       //      }
  //       //      if (clog()) console.log({regid});
  //
  //     };
  //
  //     if (userLoaded) {
  //       requestCustomerDoc();
  //     }
  //
  //     return () => {
  //       if (clog()) console.log('component unmount');
  //       // this now gets called when the component unmounts
  //     };
  //   }, [userLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  if (errorUser) {
    return <LoadingPane text={"Error User: " + JSON.stringify(errorUser)} data={errorUser} showSpinner={false} />;
  }

  if (loadingUser) {
    return <LoadingPane text={"Loading User..."} showSpinner={true} progress={25} />;
  }

  if (user) {
    // get locationId from url
    // const urlDataParams = getUrlParamsX();
    // userDataObj = {
    //   userId: userId,
    //   locationId: locationId,
    //   lat: lat,
    //   lng: lng,
    //   website: website,
    //   postcode: postcode,
    // }

    // trigger state change
    userDataObj = {
      userId: user.uid,
      email: urlDataParams.email,
      locationId: urlDataParams.locationId,
      type: urlDataParams.type,
    };

    loaded = true;

    if (customerUserData.loaded === false) {
      return <LoadingPane text={"Loading User Data..."} showSpinner={true} progress={50} />;
    }

    if (customerUserData.loaded === true && customerUserData.error) {
      if (clog()) console.log("Error Customer doc: " + JSON.stringify(customerUserData.error));
      // BT temp fix
      // return (
      //   <AiPane
      //     userData={userData}
      //   />
      // )
      let message = ''
      if (urlDataParams.locationId === '') {
        message = 'Error: Location ID is missing'
      }
      else if (customerUserData.error && customerUserData.error.val) {
        message = 'Error: ' + customerUserData.error.val
      }
      else {
        message = 'Error loading User Data'
      }

      return (
        <LoadingPane
          // text={'Error User doc: ' + JSON.stringify(customerUserData.error)}
          text={message}
          data={customerUserData.error}
          showSpinner={false}
        />
      );
    }

    if (
      userData.userId !== "" &&
      userData.locationId !== "" &&
      userLoaded === true &&
      customerUserData.loaded === true &&
      customerUserData.data &&
      customerUserData.data.aienabled === false
       ||
       // BT temp fix aienabled
       userData.userId !== '' &&
       userData.locationId !== '' &&
       userLoaded === true &&
       customerUserData.loaded === true &&
       customerUserData.data &&
       customerUserData.data.aienabled === undefined
    ) {
      return (
        <LoadingPane
          // text={'Error User doc: ' + JSON.stringify(customerUserData.error)}
          text={"AI Agent Tools is not enabled for this location"}
          data={customerUserData.error}
          showSpinner={false}
        />
      );
    } else if (
      userData.userId !== "" &&
      userData.locationId !== "" &&
      userLoaded === true &&
      customerUserData.loaded === true &&
      customerUserData.data &&
      customerUserData.data.aienabled === true
    ) {
      return <AiPane userData={userData} />;
    } else if (userData.userId === "" && userData.locationId === "" && userLoaded === true) {
      if (!userData.userId || userData.userId === "") {
        console.warn("Missing User ID");
      }

      if (!userData.locationId || userData.locationId === "") {
        console.warn("Missing Location ID");
      }

      return <LoadingPane text={"Missing user data"} showSpinner={false} />;
    }
  } else {
    // not able to log in contact support
    return <LoadingPane text={"Error loading data. Please contact support"} noProgress={true} showSpinner={true} />;
  }
};

export default React.memo(AiContainer);
