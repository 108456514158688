import React, {
  // useState,
  // useEffect
} from 'react'
// import PropTypes from 'prop-types';

// import { useTheme } from '@mui/material/styles';
import {
  Container,
  Box,
  CircularProgress
  // Tab,
  // Tabs,
  // Grid
} from '@mui/material';
// import Container from '@mui/material/Container';


const LoadingPane = (props) => {

  let text = ''
  if (props.text) {
    text = props.text
  }

  let noProgress = false
  if (props.noProgress) {
    noProgress = props.noProgress
  }
  let showSpinner = true
  if (props.showSpinner === true || props.showSpinner === false) {
    showSpinner = props.showSpinner
  }

  let progress = 100
  if (props.progress) {
    progress = props.progress
  }

  // const theme = useTheme();

  return (
    <Container maxWidth="lg"
      sx={{
        minHeight: `calc(100vh - 53px)`,
        height: `calc(100vh - 53px)`,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >

        {showSpinner && noProgress === false ?
          <CircularProgress variant="determinate" value={progress} />
          :
          <></>
        }

        {showSpinner && noProgress === true ?
          <CircularProgress />
          :
          <></>
        }

        <Box
          sx={{
            mt: 1,
            fontSize: '20px',
            fontWeight: 700
          }}
        >
          {text}
        </Box>
      </Box>

    </Container>
  )

};


export default React.memo(LoadingPane)