import React, { useContext } from "react";
// import { Link as RouterLink } from "react-router-dom";

// material-ui
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";

// third party
import * as Yup from "yup";
import { Formik } from "formik";

import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebase } from "../../../code/firebase";

import {setd, debug, setc, clog} from "../../../helpers/HelperFunctions";
import { AdminAuthContext } from "../../../helpers/AdminAuthProvider";

// project import
// import useAuth from 'hooks/useAuth';
import useScriptRef from "../../../hooks/useScriptRef";
// import FirebaseSocial from './FirebaseSocial';
import IconButton from "../../../components/@extended/IconButton";
// import AnimateButton from '../../../components/@extended/AnimateButton';

// assets
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

// ============================|| FIREBASE - LOGIN ||============================ //

const auth = getAuth(firebase);

const AuthLogin = () => {
  const theme = useTheme();



  const { login } = useContext(AdminAuthContext);

  const [checked, setChecked] = React.useState(false);
  const [capsWarning, setCapsWarning] = React.useState(false);

  // const { isLoggedIn, firebaseEmailPasswordSignIn } = useAuth();
  // isLoggedIn = user
  const [user, loadingUser, errorUser] = useAuthState(auth);

  const { adminShowForgotPassword, setAdminShowForgotPassword } = useContext(AdminAuthContext);
  const { adminShowCheckEmail, setAdminShowCheckEmail } = useContext(AdminAuthContext);

  const scriptedRef = useScriptRef();

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onKeyDown = (keyEvent) => {
    if (keyEvent.getModifierState("CapsLock")) {
      setCapsWarning(true);
    } else {
      setCapsWarning(false);
    }
  };

  const handleOnClickForgotPassword = () => {
    setAdminShowForgotPassword(true);
    setAdminShowCheckEmail(false);
  };

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          password: "",
          // email: 'stuart@stuartfenton.com',
          // password: 'VFH4eja!hzq4mya4atf',
          // email: 'stuart@hamletkitchenfitters.com',
          // password: 'yEz3dNwupKApQykDTKPU',
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email("Must be a valid email").max(255).required("Email is required"),
          password: Yup.string().max(255).required("Password is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            // await firebaseEmailPasswordSignIn(values.email, values.password).then(
            await login(values.email, values.password).then(
              () => {
                // WARNING: do not set any formik state here as formik might be already destroyed here. You may get following error by doing so.
                // Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application.
                // To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
                // github issue: https://github.com/formium/formik/issues/2430
              },
              (err) => {
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            );
          } catch (err) {
            console.error(err);
            if (scriptedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="email-login">Email Address</InputLabel>
                  <OutlinedInput
                    id="email-login"
                    type="email"
                    value={values.email}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="Enter email address"
                    fullWidth
                    error={Boolean(touched.email && errors.email)}
                  />
                  {touched.email && errors.email && (
                    <FormHelperText error id="standard-weight-helper-text-email-login">
                      {errors.email}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="password-login">Password</InputLabel>
                  <OutlinedInput
                    fullWidth
                    color={capsWarning ? "warning" : "primary"}
                    error={Boolean(touched.password && errors.password)}
                    id="-password-login"
                    type={showPassword ? "text" : "password"}
                    value={values.password}
                    name="password"
                    onBlur={(event) => {
                      setCapsWarning(false);
                      handleBlur(event);
                    }}
                    onKeyDown={onKeyDown}
                    onChange={handleChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          color="secondary"
                        >
                          {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder="Enter password"
                  />
                  {capsWarning && (
                    <Typography variant="caption" sx={{ color: "warning.main" }} id="warning-helper-text-password-login">
                      Caps lock on!
                    </Typography>
                  )}
                  {touched.password && errors.password && (
                    <FormHelperText error id="standard-weight-helper-text-password-login">
                      {errors.password}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>

              <Grid item xs={12} sx={{ mt: -1 }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                  {
                    // <FormControlLabel
                    //   control={
                    //     <Checkbox
                    //       checked={checked}
                    //       onChange={(event) => setChecked(event.target.checked)}
                    //       name="checked"
                    //       color="primary"
                    //       size="small"
                    //     />
                    //   }
                    //   label={<Typography variant="h6">Keep me sign in</Typography>}
                    // />
                  }
                  <Link
                    variant="h6"
                    // component={RouterLink}
                    // to={user ? '/forgotpassword' : '/forgotpassword'}
                    color="text.primary"
                    onClick={() => handleOnClickForgotPassword()}
                    sx={{
                      cursor: "pointer",
                      color: theme.palette.primary.main,
                    }}
                  >
                    Forgot Password?
                  </Link>
                </Stack>
              </Grid>
              {errors.submit && (
                <Grid item xs={12}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Grid>
              )}
              <Grid item xs={12}>
                {
                  // <AnimateButton>
                }
                <Button disableElevation disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained" color="primary">
                  Login
                </Button>
                {
                  // </AnimateButton>
                }
              </Grid>

              {
                //              <Grid item xs={12}>
                //              <Divider>
                //                <Typography variant="caption"> Login with</Typography>
                //              </Divider>
                //            </Grid>
                //            <Grid item xs={12}>
                //              <FirebaseSocial />
                //            </Grid>
              }
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

export default AuthLogin;
