import React, { useEffect, useRef, useContext, useState } from "react";
// import * as React from 'react';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { CustomerAuthContext } from "../helpers/CustomerAuthProvider";
import { AiAuthContext } from "../helpers/AiAuthProvider";
import { RemoteConfigAuthContext } from "../helpers/RemoteConfigAuthProvider";
import {setd, debug, setc, clog} from "../helpers/HelperFunctions";

import {
  Box,
  Typography,
  Link,
  // Button,
} from "@mui/material";

// import CheckCircleIcon from "@mui/icons-material/CheckCircleOutlined";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from "@mui/icons-material/ErrorOutlined";
import CircleIcon from "@mui/icons-material/CircleOutlined";

export default function AiCustomerSettingsDialog() {
  const { rc } = useContext(RemoteConfigAuthContext);


  const { customerUserData, setCustomerUserData } = useContext(CustomerAuthContext);
  const { getCustomerUserData } = useContext(CustomerAuthContext);
  //const { adminStatus, setAdminStatus } = useContext(AdminAuthContext);

  // const { showSettingsDialog, setShowSettingsDialog } = useContext(AiAuthContext);
  const { showAiCustomerSettingsDialog, setShowAiCustomerSettingsDialog } = useContext(AiAuthContext);

  const { saveCustomerApiKeys } = useContext(AiAuthContext);

  const { aiCustomerApiKeyStatus, setAiCustomerApiKeyStatus } = useContext(AiAuthContext);

  const [customerApiKeysSaving, setCustomerApiKeysSaving] = useState(false);
  const [eapiKey, setEapiKey] = React.useState("");
  const eapiKeyRef = useRef("");

  const [openaiKey, setOpenaiKey] = React.useState("");
  const openaiKeyRef = useRef("");

  const [anthropicKey, setAnthropicKey] = React.useState("");
  const anthropicKeyRef = useRef("");

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (showAiCustomerSettingsDialog.show === true) {

      let customerData = null
      if (customerUserData && customerUserData.data) {
        customerData = customerUserData.data
      }

      let eapiKeyValue = "";
      if (customerData && customerData.data && customerData.data.eapi && customerData.data.eapi !== "") {
        eapiKeyValue = customerData.data.eapi;
      }
      setEapiKey(eapiKeyValue);
      eapiKeyRef.current = eapiKeyValue;

      let openaiKeyValue = "";
      if (customerData && customerData.data && customerData.data.openai_key && customerData.data.openai_key !== "") {
        openaiKeyValue = customerData.data.openai_key;
      }
      setOpenaiKey(openaiKeyValue);
      openaiKeyRef.current = openaiKeyValue;


      let anthropicKeyValue = "";
      if (customerData && customerData.data && customerData.data.anthropic_key && customerData.data.anthropic_key !== "") {
        anthropicKeyValue = customerData.data.anthropic_key;
      }
      setAnthropicKey(anthropicKeyValue);
      anthropicKeyRef.current = anthropicKeyValue;

      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [showAiCustomerSettingsDialog]);



  const handleClose = () => {
    setShowAiCustomerSettingsDialog({
      show: false,
      type: [],
      message: "",
    });
  };

  const handleChange = (event, type) => {
    const val = event.target.value;

    if (type === "eapiKey") {
      setEapiKey(val);
    } else if (type === "openaiApiKey") {
      setOpenaiKey(val);
    } else if (type === "anthropicApiKey") {
      setAnthropicKey(val);
    }
  };

  const handleOnClickSave = async (event) => {
    let result;
    if (clog()) console.log("handleOnClickSave");
    setCustomerApiKeysSaving(true);

    const apiKeys = {
      // eapiKey: eapiKey,
      openaiKey: openaiKey,
      anthropicKey: anthropicKey,
    };

    result = await saveCustomerApiKeys(customerUserData, apiKeys);
    if (result && result.data) {
      const { data } = result;

      // trigger useEffect in AiSettingsPanel to load new settings
      // setAiSettingsDocsRefresh(val)
      setCustomerApiKeysSaving(false);
      setShowAiCustomerSettingsDialog({
        show: false,
        type: [],
        message: "",
      });
      // reload customer data
      // getCustomerUserData();
      getCustomerUserData();
    }
  };

  const isSaveDisabled = () => {
    let disabled = false;

    // if (eapiKey === "") {
    //   disabled = true;
    // }

    // if (eapiKey !== "" && eapiKey === eapiKeyRef.current) {
    //   disabled = true;
    // }

    if (openaiKey === openaiKeyRef.current && anthropicKey === anthropicKeyRef.current) {
      disabled = true;
    }


    return disabled;
  };

  const showApiStatusIcon = (type) => {
    // const apiKeyStatus = aiCustomerApiKeyStatus[type];
    // if (apiKeyStatus === "valid") {
    //   return <CheckCircleIcon color="success" />;
    // } else if (apiKeyStatus === "invalid") {
    //   return <ErrorIcon color="error" />;
    // } else {
    //   return <CircleIcon />;
    // }

    let hasValue = false
    let customerData = null
    if (customerUserData && customerUserData.data) {
      customerData = customerUserData.data
    }

    if (customerData && customerData.data && customerData.data[type] && customerData.data[type] !== '') {
      hasValue = true
    }
    if (hasValue) {
      return <CheckCircleIcon color="success" />;
    } else {
      return <CircleIcon
        // color="primary"
        sx={{
          color: "grey.400",
        }}
      />;
    }
  };


  const showMessage = () => {

    let message = "Add your AI API Keys";

    // if showAiCustomerSettingsDialog type array contains "openai" then show message "Add your OpenAI API Key."
    if (showAiCustomerSettingsDialog.type.includes("openai")) {
      message = "Add your ChatGPT API Key";
    }
    // if showAiCustomerSettingsDialog type array contains "anthropic" then show message "Add your OpenAI API Key."
    if (showAiCustomerSettingsDialog.type.includes("anthropic")) {
      message = "Add your Claude API Key";
    }

    if (showAiCustomerSettingsDialog.message === "engineer") {
      message = message + " to use the Engineer.";
    }
    else if (showAiCustomerSettingsDialog.message === "chat") {
      message = message + " to use Chat.";
    }

    return message;
  }
  return (
    <div>
      <Dialog
        fullWidth
        sx={{
          minWidth: "500px",
          minHeight: "300px",
        }}
        open={open}
        onClose={handleClose}
      >
        {
          // <DialogTitle>Settings</DialogTitle>
        }
        <DialogTitle>{showMessage()}</DialogTitle>

        <DialogContent
          dividers={true}
        >

          {
            //  <DialogContentText>{showMessage()}</DialogContentText>
          }


          {
            // <Box
            //   name="agencyApiKeyContainer"
            //   sx={{
            //     mt: 3,
            //   }}
            // >
            //   <Box>
            //     <Typography
            //       sx={{
            //         fontWeight: "bold",
            //       }}
            //     >
            //       Agency API Key
            //     </Typography>
            //   </Box>

            //   <Box
            //     sx={{
            //       mt: 1,
            //       display: "flex",
            //       flexDirection: "row",
            //     }}
            //   >
            //     <Box
            //       sx={{
            //         flexGrow: 1,
            //       }}
            //     >
            //       <TextField
            //         autoFocus
            //         // margin="dense"
            //         id="agencyApiKey"
            //         // label="Agency API Key"
            //         type="agencyApiKey"
            //         fullWidth
            //         variant="outlined"
            //         value={eapiKey}
            //         // variant="standard"
            //         onChange={(event) => handleChange(event, "eapiKey")}
            //       />
            //     </Box>
            //     <Box
            //       sx={{
            //         display: "flex",
            //         alignItems: "center",
            //         justifyContent: "center",
            //         // backgroundColor: "#cc0000",
            //         pl: 1,
            //       }}
            //     >
            //       {showApiStatusIcon("eapiKey")}
            //     </Box>
            //   </Box>

            //   <Box
            //     sx={{
            //       mt: 1,
            //     }}
            //   >
            //     <Typography
            //       sx={
            //         {
            //           // fontWeight: "bold",
            //         }
            //       }
            //     >
            //       To find your Agency API Key{" "}
            //       <Link target="_blank" rel="noopener" href="https://app.gohighlevel.com/settings/api_key">
            //         click here
            //       </Link>
            //       .
            //     </Typography>
            //   </Box>
            // </Box>
          }


          {
            // rc.ai_api_keys && (
            <Box
              name="openaiApiKeyContainer"
              sx={{
                mt: 3,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  ChatGPT API Key
                </Typography>
              </Box>
              <Box
                sx={{
                  mt: 1,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                  }}
                >
                  <TextField
                    autoFocus={showAiCustomerSettingsDialog.type.includes("openai")}
                    // margin="dense"
                    id="openaiApiKey"
                    // label="ChatGPT API Key"
                    type="openaiApiKey"
                    fullWidth
                    variant="outlined"
                    // placeholder="Optional"
                    value={openaiKey}
                    // variant="standard"
                    onChange={(event) => handleChange(event, "openaiApiKey")}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // backgroundColor: "#cc0000",
                    pl: 1,
                  }}
                >
                  {showApiStatusIcon("openai_key")}
                </Box>
              </Box>

              <Box
                sx={{
                  mt: 1,
                }}
              >
                <Typography
                  sx={
                    {
                      // fontWeight: "bold",
                    }
                  }
                >
                  To find your ChatGPT API Key{" "}
                  <Link target="_blank" rel="noopener" href="https://platform.openai.com/account/api-keys">
                    click here
                  </Link>
                  .
                </Typography>
              </Box>
            </Box>
            // )
          }

          {
            // rc.ai_api_keys && (
            <Box
              name="anthropicApiKeyContainer"
              sx={{
                mt: 3,
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Claude API Key
                </Typography>
              </Box>

              <Box
                sx={{
                  mt: 1,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                  }}
                >
                  <TextField
                    autoFocus={showAiCustomerSettingsDialog.type.includes("anthropic")}
                    // margin="dense"
                    id="anthropicApiKey"
                    // label="Claude API Key"
                    type="anthropicApiKey"
                    fullWidth
                    variant="outlined"
                    value={anthropicKey}
                    // variant="standard"
                    onChange={(event) => handleChange(event, "anthropicApiKey")}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // backgroundColor: "#cc0000",
                    pl: 1,
                  }}
                >
                  {showApiStatusIcon("anthropic_key")}
                </Box>
              </Box>

              <Box
                sx={{
                  mt: 1,
                }}
              >
                <Typography
                  sx={
                    {
                      // fontWeight: "bold",
                    }
                  }
                >
                  To find your Claude API Key{" "}
                  <Link target="_blank" rel="noopener" href="https://console.anthropic.com/account/keys">
                    click here
                  </Link>
                  .
                </Typography>
              </Box>
            </Box>
            // )
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={handleOnClickSave} disabled={isSaveDisabled()}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
