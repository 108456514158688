import React, { createContext, useState, useRef, useContext } from "react";
// import auth from '@react-native-firebase/auth';
import { getUserToken } from "../code/firebase";

import { getAuth } from "firebase/auth";

import {
  // collection,
  doc,
  setDoc,
  onSnapshot,
  getDoc,
  getDocs,
  getFirestore,
  collection,
  query,
  where,
  orderBy,
  limit,
  addDoc,
  updateDoc,
  deleteDoc,
  writeBatch,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";

import _ from "lodash";
import { diff, addedDiff, deletedDiff, updatedDiff, detailedDiff } from "deep-object-diff";

import { getFunctions, httpsCallable } from "firebase/functions";
import { useAuthState } from "react-firebase-hooks/auth";

import { getUrlParams } from "../code/ecomacy";
import { firebase } from "../code/firebase";
import { e, re, sort } from "mathjs";
import { ar, de, tr } from "date-fns/locale";
import { settings } from "firebase/analytics";
// import { Timestamp } from '@google-cloud/firestore';

import {setd, debug, setc, clog} from "./HelperFunctions";
import { RemoteConfigAuthContext } from "./RemoteConfigAuthProvider";
import axios from 'axios';

import {
  getCustomerAiAgentsDocs,
  processGetCustomerAiPresetAgentRefDocs,
  processSaveAdminAiPresetAgentToCustomer,
} from "./AdminAiAuthProviderFunctions";

// const {
//   processGetCustomerAiPresetAgentRefDocs,
//   processSaveAdminAiPresetAgentToCustomer,
// } = require("./AdminAiAuthProviderFunctions");

// const auth = getAuth(firebase);
// const functions = getFunctions(firebase, "europe-west1");
// const db = getFirestore(firebase);

/**
 * This provider is created
 * to access user in admin area
 */

const globaldeleteme = -1;

const initialState = {};

export const AdminAiAuthContext = createContext(initialState);

export const AdminAiAuthProvider = ({ children }) => {

  let props = {}
  if (children && children.props) {
    props = { ...children.props };
  }

  let userData = {};
  if (props.userData) {
    userData = props.urlDataParams;
  }
  let locationId = "";
  if (userData.locationId !== "") {
    locationId = userData.locationId;
  }
  let email = "";
  if (userData.email !== "") {
    email = userData.email;
  }

  const urlDataParams = props.urlDataParams;

  // const [user, setUser] = useState(null);
  const { rc } = useContext(RemoteConfigAuthContext);

  const [testVal, setTestVal] = useState("");
  const [showAdminAiPresetAgentsDialog, setShowAdminAiPresetAgentsDialog] = useState({
    show: false,
    locationid: "",
    userid: "",
  })

  const [adminCustomerAiPresetAgentRefDocsFound, setAdminCustomerAiPresetAgentRefDocsFound] = useState(null);

  const [adminIsSavingPresetAgent, setAdminIsSavingPresetAgent] = useState({
    saving: false,
    locationid: "",
    presetref: "",
  });

  const generateFirestoreDate = () => {
    // generate firestore timestamp
    // firebase.firestore.FieldValue.serverTimestamp()
    // firebase.firestore.Timestamp.now()
    // const timestamp = 1676475521;
    // const timestampDate = new Date(timestamp*1000);
    // const timestampFirestore = admin.firestore.Timestamp.fromDate(timestampDate)
    // const timestampFirestore = admin.firestore.Timestamp.now()
    // const timestampFirestore = firebase.firestore.Timestamp.now()
    const timestampFirestore = serverTimestamp();

    return timestampFirestore;
  };
  const delay = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds));

  const getDiff = (oldData, newData) => {
    //diff, addedDiff, deletedDiff, updatedDiff, detailedDiff
    const difference = diff(oldData, newData);
    return difference;
  };


  const getAiWebCodeTokenFromApi = async (payload) => {
    let returnData = {
      status: 0,
      // data: {},
      // error: {},
    };
    try {
      let URL = import.meta.env.VITE_REACT_APP_AI_API_URL;
      /*
      let URL = 'https://api.ecomacy.com'
      if (import.meta.env.VITE_REACT_APP_ENV === 'dev') {
        URL = 'http://localhost:8080'
      }
      */
      //STUAUTH
      if (URL) {
        const userToken = await getUserToken();
        const jwt = "Bearer " + userToken;
        const apiUrlPath = URL.concat("/gettoken");
        // const apiUrlPath = URL.concat("/gettoken/", payload.locationid, "/", payload.link, "/", payload.url);
        // const apiUrlPath = URL.concat("/gettoken/", jsonData);

        if (clog()) console.log("apiUrlPath", apiUrlPath);
        const response = await axios.get(apiUrlPath, {
          // const response = await axios.post(apiUrlPath, payload, {
          headers: {
            "Content-Type": "application/json",
            Authorization: jwt,
          },
          params: payload,
        });
        // cLog(response.data)

        if (response.status === 200) {
          returnData.status = response.status;
          returnData.data = response.data;
        } else {
          returnData.status = response.status;
          returnData.error = response.error;
        }
        // if (clog()) console.log('getAiWebCodeTokenFromApi returnData:', returnData);
        return returnData;
      } else {
        returnData.status = 490;
        returnData.error = "REACT_APP_API_URL url missing from env";
        return returnData;
      }
    } catch (err) {
      console.error("response error", err);
      returnData.status = 491;
      returnData.error = err;
      return returnData;
    }
  };

  const getAiWebCodeToken = async (userData, automationlink) => {
    let returnData = {
      status: 200,
      data: {},
      error: {},
    };
    try {
      let locationid = "";
      if (userData && userData.locationId) {
        locationid = userData.locationId;
      }

      // let domains = [];
      // if (agentDomains && agentDomains.length > 0) {
      //   domains = agentDomains;
      // }

      if (locationid !== "" && automationlink !== "") {
        const data = {
          locationid: locationid,
          link: automationlink,
          // url: url,
          // domains: domains,
        };

        let result = await getAiWebCodeTokenFromApi(data);
        returnData.status = result.status;

        if (result.status === 200 && result.data) {
          returnData.status = result.status;
          returnData.data = result.data;
        } else {
          returnData.status = result.status;
          returnData.error = result.error.code;
        }
        // let result = {status: 'success'};
        return returnData;
      }
    } catch (err) {
      console.error(err);
      returnData.status = 501;
      returnData.error = {
        val: "Web Code Token data fetch failed",
      };
      // return returnData;
    }
  };



  // // Function to get aiagent documents that match presetref in location
  // const processGetCustomerAiPresetAgentRefDocs = async (uData, locationId, categoryAgentsSelected) => {
  //   let returnData = {};
  //   try {
  //     // const test0 = aiAgentsSelected;
  //     // const test1 = aiSettingsSelected;
  //     // const test2 = aiConversation;
  //     // const test3 = aiSettingsData;
  //     // const test4 = aiSettingsDataUpdated;
  //     // const test5 = aiConversationsDocs;

  //     let locationid = "";
  //     if (locationId) {
  //       locationid = locationId;
  //     }

  //     let aicategoryagentid = "";
  //     if (categoryAgentsSelected && categoryAgentsSelected.aicategoryagentid) {
  //       aicategoryagentid = categoryAgentsSelected.aicategoryagentid;
  //     }

  //     let aiagentPromptRefs = [];
  //     // loop over categoryAgentsSelected.aipresetagents and get presetref
  //     if (categoryAgentsSelected && categoryAgentsSelected.aipresetagents) {
  //       categoryAgentsSelected.aipresetagents.forEach((presetagent) => {
  //         const aiagentid = presetagent.aiagentid;
  //         const presetref = presetagent.presetref;
  //         const promptRefData = {
  //           aiagentid: aiagentid,
  //           presetref: presetref,
  //         }
  //         aiagentPromptRefs.push(promptRefData);
  //       });
  //     }
  //     const test = aiagentPromptRefs;

  //     let customerAiAgentPromptRefFound = {};

  //     if (locationid && aiagentPromptRefs) {


  //       // loop over aiagentPromptRefs
  //       for (let i = 0; i < aiagentPromptRefs.length; i++) {
  //         const presetref = aiagentPromptRefs[i].presetref;
  //         const presetrefAgentId = aiagentPromptRefs[i].aiagentid;

  //         // get aiagents doc connected to presetref
  //         const q = query(
  //           collection(db, "aiagents"),
  //           where("locationid", "==", locationid),
  //           where("presetref", "==", presetref),
  //           orderBy("createdon", "desc")
  //         );

  //         const snapshot = await getDocs(q);

  //         snapshot.forEach((doc) => {
  //           // doc.data() is never undefined for query doc snapshots
  //           // if (clog()) console.log(doc.id, " => ", doc.data());
  //           // docExists = true;
  //           const docData = doc.data();
  //           const aiagentid = docData.aiagentid;

  //           // const smallData = {
  //           //   aiagentid: aiagentid,
  //           // }

  //           // check that aiagentid does not match presetrefAgentId
  //           // otherwise the agent the preset is based on could be overwritten
  //           // will only happen in account that created the preset
  //           // if (aiagentid !== presetrefAgentId) {
  //           // if presetref is not in customerAiAgentPromptRefFound then add it
  //           if (!customerAiAgentPromptRefFound[presetref]) {
  //             customerAiAgentPromptRefFound[presetref] = [];
  //           }
  //           // add aiagentid to customerAiAgentPromptRefFound
  //           customerAiAgentPromptRefFound[presetref].push(aiagentid);
  //           // }
  //         });
  //       }

  //       returnData.data = {
  //         customerDocs: customerAiAgentPromptRefFound,
  //         aicategoryagentid: aicategoryagentid,
  //       };

  //       // returnData.data = {
  //       //   customerAiAgentPromptRefFound: customerAiAgentPromptRefFound,
  //       //   // presetref: presetref,
  //       //   // aipresetagentsDoc: presetagentsDoc,
  //       //   // docExists: docExists,
  //       // };

  //     } else {
  //       returnData.error = {
  //         val: "Location id or presetref is missing",
  //       };
  //     }

  //     return returnData;

  //   } catch (err) {
  //     console.error(err);
  //     returnData.status = 501;
  //     returnData.error = {
  //       val: "get Customer AiPresetAgent Ref Docs failed",
  //     };
  //     // return returnData;
  //   }
  // }

  // // Function to delete documents from a collection based on aiconversationId
  // const deleteDocsByConversationId = async (collection, aiConversationId) => {
  //   const returnData = {};
  //   try {
  //     let processedDataIds = [];
  //     const ref = db.collection(collection)
  //       .where('aiconversationid', '==', aiConversationId);
  //     const snap = await ref.get();
  //     for (const doc of snap.docs) {
  //       await doc.ref.delete();
  //       await delay(100);
  //       processedDataIds.push(doc.id);
  //     }

  //     returnData.data = processedDataIds;
  //   } catch (error) {
  //     console.error(`Error deleting documents from ${collection} for aiconversationid ${aiConversationId}:`, error);
  //     returnData.error = `Error deleting documents from ${collection} for aiconversationid ${aiConversationId}: ${error.message}`;
  //   }

  //   return returnData;
  // }

  // // Main function to delete documents
  // const deleteAiAgentAndRelatedData = async (locationId, aiAgentId) => {
  //   let returnData = {};

  //   try {
  //     // aiagent
  //     //// aiagentid

  //     // aiconversations
  //     //// aisettings.aiagentid
  //     // aimessages
  //     //// aimessages/${aiconversationid}/messages

  //     // aipromptversions
  //     //// aiconversationid

  //     // aiengineerconversations
  //     //// aiconversationid
  //     //// locationid

  //     // ailinksettings
  //     //// aiagentid
  //     //// aiconversationid
  //     let processedData = {
  //       aiagentidremove: "",
  //       aiconversationsremove: {},
  //     }

  //     // Find and delete aiagents doc
  //     const aiAgentsRef = db.collection('aiagents')
  //       .where('locationId', '==', locationId)
  //       .where('aiAgentId', '==', aiAgentId);

  //     const aiAgentsSnap = await aiAgentsRef.get();
  //     for (const doc of aiAgentsSnap.docs) {
  //       // add aiagentid to processedData
  //       processedData.aiagentidremove = doc.id;

  //       await doc.ref.delete();
  //       await delay(100);


  //     }

  //     // Find aiconversations docs, save aiconversationId, and delete
  //     let aiConversationIds = [];
  //     const aiConversationsRef = db.collection('aiconversations')
  //       .where('aisettings.aiagentid', '==', aiAgentId);

  //     const aiConversationsSnap = await aiConversationsRef.get();
  //     for (const doc of aiConversationsSnap.docs) {
  //       aiConversationIds.push(doc.id);
  //       await doc.ref.delete();
  //       await delay(100);

  //       // add aiconversationid to processedData
  //       processedData.aiconversationsremove[doc.id] = {
  //         aiconversationid: doc.id
  //       };
  //     }

  //     // Use aiConversationId to delete related documents in other collections
  //     for (const aiConversationId of aiConversationIds) {
  //       // Delete aimessages
  //       const aiMessagesRef = db.collection(`aimessages/${aiConversationId}/messages`);
  //       const aiMessagesSnap = await aiMessagesRef.get();

  //       let processedDataMessages = []
  //       for (const doc of aiMessagesSnap.docs) {
  //         await doc.ref.delete();
  //         await delay(100);
  //         processedDataMessages.push(doc.id);
  //       }

  //       // add messages to processedData
  //       processedData.aiconversationsremove[aiConversationId] = {
  //         ...processedData.aiconversationsremove[aiConversationId],
  //         aimessageids: processedDataMessages
  //       };

  //       // delete documents in aipromptversions, aiengineerconversations, ailinksettings
  //       const aipromptversionsResult = await deleteDocsByConversationId('aipromptversions', aiConversationId);
  //       if (aipromptversionsResult?.data) {
  //         // add aipromptversions to processedData
  //         processedData.aiconversationsremove[aiConversationId] = {
  //           ...processedData.aiconversationsremove[aiConversationId],
  //           aipromptversions: aipromptversionsResult.data
  //         };
  //       }

  //       // delete documents in aipromptversions, aiengineerconversations, ailinksettings
  //       const aiengineerconversationsResult = await deleteDocsByConversationId('aiengineerconversations', aiConversationId);
  //       if (aiengineerconversationsResult?.data) {
  //         // add aiengineerconversations to processedData
  //         processedData.aiconversationsremove[aiConversationId] = {
  //           ...processedData.aiconversationsremove[aiConversationId],
  //           aiengineerconversations: aiengineerconversationsResult.data
  //         };
  //       }

  //       const ailinksettingsResult = await deleteDocsByConversationId('ailinksettings', aiConversationId);
  //       if (ailinksettingsResult?.data) {
  //         // add ailinksettings to processedData
  //         processedData.aiconversationsremove[aiConversationId] = {
  //           ...processedData.aiconversationsremove[aiConversationId],
  //           ailinksettings: ailinksettingsResult.data
  //         };
  //       }

  //     }

  //     returnData.data = processedData;

  //     return returnData;

  //   } catch (error) {
  //     console.error("Error in deleting documents:", error);
  //     returnData.error = error;

  //     return returnData;
  //   }
  // }

  // // process aiagent data for location
  // const processAiAgentPresetDataForLocation = (locationId, userId, aiAgentId, aiagentPresetData, date, webtoken) => {

  //   // clone aiagentPresetData
  //   let aiagentProcessedData = _.cloneDeep(aiagentPresetData);

  //   // add locationid
  //   aiagentProcessedData.locationid = locationId;

  //   // add userid
  //   // aiagentProcessedData.userid = userId;

  //   // add aiagentid
  //   aiagentProcessedData.aiagentid = aiAgentId;

  //   // add createdon
  //   aiagentProcessedData.createdon = date;

  //   // add updatedon
  //   aiagentProcessedData.updatedon = date;

  //   // add locationid
  //   aiagentProcessedData.locationid = locationId;


  //   // generate aisettingid
  //   const agentCollectionRef = collection(db, "aiagents");
  //   const docRefForAiSettings = doc(agentCollectionRef);
  //   // Get the new document Id
  //   const aiSettingId = docRefForAiSettings.id;

  //   // add new aisettingid to aisettings array
  //   aiagentProcessedData.aisettings[0].aisettingid = aiSettingId;

  //   // let newAiSetting = aiagentProcessedData.aisettings[0]
  //   // newAiSetting.aisettingid = aiSettingId;

  //   // let aiSettingsArr = [];

  //   // // add new aisetting to aisettings array
  //   // aiSettingsArr.push(newAiSetting);

  //   // aiagentProcessedData.aisettings = aiSettingsArr;

  //   // update domains
  //   aiagentProcessedData.domains = [];

  //   // update webtoken
  //   aiagentProcessedData.webtoken = webtoken;

  //   return aiagentProcessedData;
  // }

  // // process aiconversation data for location
  // const processAiConversationPresetDataForLocation = (
  //   locationId,
  //   userId,
  //   aiAgentId,
  //   settingId,
  //   aiConversationId,
  //   aiLinkSettingId,
  //   aiconversationPresetData,
  //   date
  // ) => {

  //   // clone aiagentPresetData
  //   let aiconversationProcessedData = _.cloneDeep(aiconversationPresetData);

  //   // add locationid
  //   aiconversationProcessedData.locationid = locationId;

  //   // add userid
  //   // aiconversationProcessedData.userid = userId;

  //   // add aiconversationid
  //   aiconversationProcessedData.aiconversationid = aiConversationId;

  //   // add aisettingid
  //   aiconversationProcessedData.aisettingid = settingId;

  //   // add ailinksettingid
  //   aiconversationProcessedData.ailinksettingid = aiLinkSettingId;

  //   // add createdon
  //   aiconversationProcessedData.createdon = date;

  //   // add updatedon
  //   aiconversationProcessedData.updatedon = date;

  //   // clean localstorage
  //   aiconversationProcessedData.localstorage = {};

  //   // update promptversion
  //   aiconversationProcessedData.promptversion = 1;

  //   // update promptversioncount
  //   aiconversationProcessedData.promptversioncount = 1;

  //   // clean messages
  //   aiconversationProcessedData.messages = [];

  //   //// update aisettings object
  //   // aiagentid
  //   aiconversationProcessedData.aisettings.aiagentid = aiAgentId;
  //   // aisettingid
  //   aiconversationProcessedData.aisettings.aisettingid = settingId;
  //   // locationid
  //   aiconversationProcessedData.aisettings.locationid = locationId;
  //   // userid
  //   aiconversationProcessedData.aisettings.userid = userId;
  //   // promptversion
  //   aiconversationProcessedData.aisettings.promptversion = 1;
  //   // remove version
  //   delete aiconversationProcessedData.aisettings.version;
  //   // remove versionbasedon
  //   delete aiconversationProcessedData.aisettings.versionbasedon;

  //   return aiconversationProcessedData;
  // }

  // // process aiconversation data for location
  // const processAiLinkSettingsPresetDataForLocation = (
  //   locationId,
  //   userId,
  //   aiAgentId,
  //   // settingId,
  //   aiConversationId,
  //   aiLinkSettingId,
  //   aiconversationProcessedDataSettings,
  //   date
  // ) => {

  //   // clone aiagentPresetData
  //   let ailinksettingsProcessedData = _.cloneDeep(aiconversationProcessedDataSettings);

  //   // add locationid
  //   ailinksettingsProcessedData.locationid = locationId;

  //   // add userid
  //   // ailinksettingsProcessedData.userid = userId;

  //   // add aiagentid
  //   ailinksettingsProcessedData.aiagentid = aiAgentId;

  //   // add aiconversationid
  //   ailinksettingsProcessedData.aiconversationid = aiConversationId;

  //   // remove aisettingid
  //   delete ailinksettingsProcessedData.aisettingid;
  //   // ailinksettingsProcessedData.aisettingid = settingId;

  //   // add ailinksettingid
  //   ailinksettingsProcessedData.ailinksettingid = aiLinkSettingId;

  //   // add createdon
  //   ailinksettingsProcessedData.createdon = date;

  //   // add updatedon
  //   ailinksettingsProcessedData.updatedon = date;

  //   return ailinksettingsProcessedData;
  // }

  // // process and save preset agent for location
  // const processSaveAdminAiPresetAgentToCustomer = async (uData, presetagent, replaceAgent) => {
  //   let res = {};

  //   // with code from saveAiSettingsNewFromPreset
  //   let returnData = {};
  //   try {

  //     let processedData = {
  //       aiagentremove: {},
  //       aiagentid: "",
  //       aiconversations: [],
  //       //   aiagentid: "",
  //       //   aiconversations: [
  //       //     {
  //       //       aiconversationid: "",
  //       //       aipromptversionid: "",
  //       //       ailinksettingid: "",

  //       // ],
  //     };

  //     // add locationid
  //     let locationId = "";
  //     if (uData.locationId && uData.locationId !== "") {
  //       locationId = uData.locationId;
  //     }

  //     // add userId
  //     let userId = "";
  //     if (uData.userId && uData.userId !== "") {
  //       userId = uData.userId;
  //     }

  //     let presetRefId = "";
  //     if (presetagent && presetagent.presetref) {
  //       presetRefId = presetagent.presetref;
  //     }

  //     let aiPresetAgentId = "";
  //     if (presetagent && presetagent.aipresetagentid) {
  //       aiPresetAgentId = presetagent.aipresetagentid;
  //     }

  //     if (locationId !== "" && userId !== "" && presetRefId !== "" && aiPresetAgentId !== "") {

  //       // get aipresetagent doc using aiPresetAgentId
  //       const aipresetagentRef = doc(db, "aipresetagents", aiPresetAgentId);
  //       const aipresetagentDoc = await getDoc(aipresetagentRef);
  //       const aipresetagentDocData = aipresetagentDoc.data();

  //       // get aiagent from aipresetagentDocData
  //       let aiagentPresetData = null;
  //       if (aipresetagentDocData && aipresetagentDocData.aiagent) {
  //         aiagentPresetData = aipresetagentDocData.aiagent;
  //       }

  //       // get aiconversations from aipresetagentDocData
  //       let aiconversationsPresetData = null;
  //       if (aipresetagentDocData && aipresetagentDocData.aiconversations) {
  //         aiconversationsPresetData = aipresetagentDocData.aiconversations;
  //       }

  //       if (aiagentPresetData && aiconversationsPresetData) {
  //         // const name = aiagentPresetData.name;
  //         // const automationLink = aiagentPresetData.automationlink;

  //         /* //////////////// START: delete aiagent and related documents //////////////// */
  //         let replaceAgentId = "";
  //         if (replaceAgent.length > 0) {
  //           replaceAgentId = replaceAgent[0].aiagentid;
  //         }

  //         // if replace agent has an aiagentid then remove all aiagent data
  //         if (replaceAgentId) {
  //           const deleteAgentRes = await deleteAiAgentAndRelatedData(locationId, replaceAgentId);
  //           if (deleteAgentRes?.data) {
  //             processedData.aiagentremove = deleteAgentRes.data;
  //           }
  //         }
  //         /* ///////////////// END: delete aiagent and related documents ///////////////// */


  //         // generate webtoken
  //         const webtokenRes = {
  //           status: 200,
  //           data: "xxxxxxxxxxxxx",
  //         }
  //         // const webtokenRes = await getAiWebCodeToken(uData, aiagentPresetData.automationlink);

  //         // if webtoken is generated
  //         if (webtokenRes && webtokenRes.status === 200 && webtokenRes.data) {
  //           const webtoken = webtokenRes.data;

  //           // Get a new write batch
  //           const batch = writeBatch(db);

  //           // save aiagents doc
  //           // Get the collection reference
  //           const agentCollectionRef = collection(db, "aiagents");

  //           // generate aiagentid
  //           // askstu - use replaceAgentId?
  //           const aRef = doc(agentCollectionRef);
  //           // Get the new document Id
  //           const aiAgentId = aRef.id;


  //           const date = generateFirestoreDate();

  //           // clean aiagentPresetData for location
  //           let aiagentProcessedData = processAiAgentPresetDataForLocation(locationId, userId, aiAgentId, aiagentPresetData, date, webtoken);

  //           // save settingsid to use in aiconversations
  //           const settingId = aiagentProcessedData.aisettings[0].aisettingid;

  //           // add to processedData
  //           processedData.aiagentid = aiAgentId;

  //           // update this once ailinksettingid in aiagentProcessedData.aisettings[0] is updated
  //           // batch.set(aRef, aiagentProcessedData);

  //           // START: loop over aiconversationsPresetData
  //           for (let i = 0; i < aiconversationsPresetData.length; i++) {

  //             let processedDataConversation = {
  //               aiconversationid: "",
  //               aipromptversionid: "",
  //               ailinksettingid: "",
  //             };

  //             // Get the aiconversations collection reference
  //             const conversationCollectionRef = collection(db, "aiconversations");
  //             // generate aiconversationid
  //             const cRef = doc(conversationCollectionRef);
  //             // Get the new document Id
  //             const aiConversationId = cRef.id;

  //             // add to processedDataConversation
  //             processedDataConversation.aiconversationid = aiConversationId;


  //             // Get the ailinksettings collection reference
  //             const linksettingCollectionRef = collection(db, "ailinksettings");
  //             // generate ailinksettingid
  //             const lRef = doc(linksettingCollectionRef);
  //             // Get the new document Id
  //             const aiLinkSettingId = lRef.id;

  //             // add to processedDataConversation
  //             processedDataConversation.ailinksettingid = aiLinkSettingId;


  //             // Get the aipromptversions collection reference
  //             const promptversionCollectionRef = collection(db, "aipromptversions");
  //             // generate aipromptversionid
  //             const pRef = doc(promptversionCollectionRef);
  //             // Get the new document Id
  //             const aiPromptVersionId = pRef.id;

  //             // add to processedDataConversation
  //             processedDataConversation.aipromptversionid = aiPromptVersionId;


  //             //// aiconversation data
  //             const aiconversationPresetData = aiconversationsPresetData[i];
  //             // clean aiconversationPresetData for location
  //             const aiconversationProcessedData = processAiConversationPresetDataForLocation(
  //               locationId,
  //               userId,
  //               aiAgentId,
  //               settingId,
  //               aiConversationId,
  //               aiLinkSettingId,
  //               aiconversationPresetData,
  //               date
  //             );


  //             // if automationlink is not empty then add ailinksettingid to aiagentProcessedData.aisettings[0]
  //             if (aiconversationProcessedData?.automationlink !== "") {
  //               // update ailinksettingid in aiagentProcessedData.aisettings[0]
  //               aiagentProcessedData.aisettings[0].ailinksettingid = aiLinkSettingId;
  //             }
  //             // set aisettings data to use later
  //             const aiconversationProcessedDataSettings = aiconversationProcessedData.aisettings;

  //             // save aiconversations doc
  //             const csRef = doc(db, "aiconversations", aiConversationId);
  //             batch.set(csRef, aiconversationProcessedData);


  //             //// ailinksettings data
  //             // process ailinksettings for location
  //             const ailinksettingsProcessedData = processAiLinkSettingsPresetDataForLocation(
  //               locationId,
  //               userId,
  //               aiAgentId,
  //               // settingId,
  //               aiConversationId,
  //               aiLinkSettingId,
  //               aiconversationProcessedDataSettings,
  //               date
  //             );

  //             // save ailinksettings doc
  //             const lsRef = doc(db, "ailinksettings", aiLinkSettingId);
  //             batch.set(lsRef, ailinksettingsProcessedData);



  //             //// aipromptversion data
  //             const processedPromptVersionDocData = {
  //               aiconversationid: aiConversationId,
  //               aipromptversionid: aiPromptVersionId,
  //               aisettings: aiconversationProcessedDataSettings,
  //               locationid: locationId,
  //               name: "1",
  //               promptversion: 1,
  //               updatedon: date,
  //               createdon: date,
  //             };

  //             // save aipromptversions doc
  //             const psRef = doc(db, "aipromptversions", aiPromptVersionId);
  //             batch.set(psRef, processedPromptVersionDocData);


  //             // add to processedData
  //             processedData.aiconversations.push(processedDataConversation);

  //           }
  //           // END: loop over aiconversationsPresetData

  //           // askstu - update this once linksettings in aiagentProcessedData.aisettings[0] is updated
  //           batch.set(aRef, aiagentProcessedData);

  //           // commit the batch
  //           // res = await batch.commit();

  //           if (clog()) console.log("saveAdminAiPresetAgentToCustomer:", processedData);
  //           returnData.data = processedData
  //           debugger

  //           // old code
  //           /*
  //           // use presetRefId from presetagent.presetref
  //           // // generate a presetref
  //           // const presetRef = doc(agentCollectionRef);
  //           // // Get the new document Id
  //           // const presetRefId = presetRef.id;

  //           // const date = generateFirestoreDate();

  //           // let newAgentData = {};

  //           // // if (globaldeleteme && globaldeleteme === 1) {
  //           // //   newAgentData.deleteme = globaldeleteme;
  //           // // }

  //           // // add aiagentid
  //           // newAgentData.aiagentid = aiAgentId;

  //           // // add presetref
  //           // newAgentData.presetref = presetRefId;

  //           // // add name
  //           // newAgentData.name = name;

  //           // // add automationlink
  //           // newAgentData.automationlink = automationLink;

  //           // // add createdon
  //           // newAgentData.createdon = date;

  //           // // add updatedon
  //           // newAgentData.updatedon = date;

  //           // // add locationid
  //           // newAgentData.locationid = locationId;

  //           // // generate aisettingid
  //           // const docRefForAiSettings = doc(agentCollectionRef);
  //           // // Get the new document Id
  //           // const aiSettingId = docRefForAiSettings.id;

  //           // // add aisettings
  //           // let newAiSetting = {
  //           //   aisettingid: aiSettingId,
  //           //   name: settingsName,
  //           //   automationlink: automationLink,
  //           //   archive: false,
  //           // };

  //           // let aiSettingsArr = [];

  //           // // add new aisetting to aisettings array
  //           // aiSettingsArr.push(newAiSetting);

  //           // newAgentData.aisettings = aiSettingsArr;

  //           // // add domains
  //           // newAgentData.domains = [];

  //           // // add webtoken
  //           // newAgentData.webtoken = webtoken;

  //           // // add archive
  //           // newAgentData.archive = false;

  //           // // Get a new write batch
  //           // const batch = writeBatch(db);

  //           // batch.set(aRef, newAgentData);

  //           // // generate id for new aiLinkSettings doc
  //           // const aiLinkSettingsRef = collection(db, "ailinksettings"); // collectionRef
  //           // const linkSettingsRef = doc(aiLinkSettingsRef); // docRef
  //           // // update to new id
  //           // const aiLinkSettingId = linkSettingsRef.id;


  //           // // build new conversation
  //           // const conversationRef = collection(db, "aiconversations");

  //           // // Generate "locally" a new document for the given collection reference
  //           // const conversationDocRef = doc(conversationRef);

  //           // // Get the new document Id
  //           // const aiConversationId = conversationDocRef.id;

  //           // let newConversationData = {};

  //           // // if (globaldeleteme && globaldeleteme === 1) {
  //           // //   newConversationData.deleteme = globaldeleteme;
  //           // // }

  //           // // add aiconversationid
  //           // newConversationData.aiconversationid = aiConversationId;

  //           // // add aisettingid
  //           // newConversationData.aisettingid = aiSettingId;

  //           // // add ailinksettingid
  //           // newConversationData.ailinksettingid = aiLinkSettingId;

  //           // // add automationlink
  //           // newConversationData.automationlink = automationLink;

  //           // // add name
  //           // newConversationData.name = settingsName;

  //           // // set live to false
  //           // newConversationData.live = false;

  //           // // add archive
  //           // newConversationData.archive = false;

  //           // // add updatedon
  //           // newConversationData.updatedon = date;

  //           // // add createdon
  //           // newConversationData.createdon = date;

  //           // // add locationid
  //           // newConversationData.locationid = locationId;

  //           // // process settings data
  //           // let newSettingsData = { ...settingsData };

  //           // // add aiagentid
  //           // newSettingsData.aiagentid = aiAgentId;

  //           // // add aisettingid
  //           // newSettingsData.aisettingid = aiSettingId;

  //           // // add userid
  //           // newSettingsData.userid = uData.userId;

  //           // // add locationid
  //           // newSettingsData.locationid = locationId;

  //           // // remove createdon
  //           // delete newSettingsData.createdon;

  //           // // remove updatedon
  //           // delete newSettingsData.updatedon;

  //           // // add automationlink to .data
  //           // newSettingsData.data.automationlink = automationLink;

  //           // // START: promptVersion

  //           // // const test = promptVersionSelected;

  //           // let promptVersion = 1;

  //           // let promptVersionName = "1";

  //           // let promptVersionId = "";

  //           // let promptVersionCount = 1;

  //           // let isNewPromptVersion = false;
  //           // // if promptVersionId is empty then generate an id
  //           // if (promptVersionId === "") {
  //           //   isNewPromptVersion = true;
  //           //   // Get the collection reference
  //           //   const aipromptversionsRef = collection(db, "aipromptversions");

  //           //   // Generate "locally" a new document for the given collection reference
  //           //   const aipromptversionsDocRef = doc(aipromptversionsRef);

  //           //   // Get the new document Id
  //           //   promptVersionId = aipromptversionsDocRef.id;
  //           // }

  //           // // process aisettings for aipromptversion
  //           // const processedPromptVersionSettingsData = processSettingsDataForPromptVersion(newSettingsData);

  //           // const processedPromptVersionDocData = {
  //           //   aiconversationid: aiConversationId,
  //           //   aipromptversionid: promptVersionId,
  //           //   aisettings: processedPromptVersionSettingsData,
  //           //   locationid: locationId,
  //           //   name: promptVersionName,
  //           //   promptversion: promptVersion,
  //           //   updatedon: date,
  //           // };

  //           // if (isNewPromptVersion) {
  //           //   // add createdon
  //           //   processedPromptVersionDocData.createdon = date;

  //           //   // add archive
  //           //   processedPromptVersionDocData.archive = false;
  //           // }

  //           // // if (globaldeleteme && globaldeleteme === 1) {
  //           // //   processedPromptVersionDocData.deleteme = globaldeleteme;
  //           // // }

  //           // // update linksettings
  //           // const pRef = doc(db, "aipromptversions", promptVersionId);
  //           // /// batch.set(lRef, processedDocData);
  //           // if (isNewPromptVersion) {
  //           //   batch.set(pRef, processedPromptVersionDocData);
  //           // } else {
  //           //   batch.update(pRef, processedPromptVersionDocData);
  //           // }

  //           // // END: promptVersion



  //           // // add version and versionbasedon
  //           // newConversationData.version = 1;
  //           // newConversationData.versionbasedon = 0;
  //           // newConversationData.promptversion = promptVersion;
  //           // newConversationData.promptversioncount = promptVersionCount;

  //           // // stringify settings data
  //           // // const settingsDataJson = JSON.stringify(newSettingsData);

  //           // // add settingsDataJson
  //           // // newConversationData.aisettings = settingsDataJson;
  //           // newConversationData.aisettings = newSettingsData;

  //           // // add settingsDataJson to messages
  //           // newConversationData.messages = [];

  //           // const message = {
  //           //   aisettings: settingsDataJson,
  //           //   content: "",
  //           //   role: "aisettings",
  //           //   // extra data without processing json
  //           //   data: {
  //           //     version: 1,
  //           //     versionbasedon: 0,
  //           //     // versionPrev: versionCurrent,
  //           //     promptversion: promptVersion,
  //           //     promptversionid: promptVersionId,
  //           //   },
  //           // };
  //           // newConversationData.messages.push(message);

  //           // const cRef = doc(db, "aiconversations", aiConversationId);
  //           // batch.set(cRef, newConversationData);

  //           // // build new ailinksettings
  //           // let newAiLinkSettingsData = { ...newSettingsData };

  //           // // add aiagentid
  //           // newAiLinkSettingsData.aiagentid = aiAgentId;

  //           // // add ailinksettingid
  //           // newAiLinkSettingsData.ailinksettingid = aiLinkSettingId;

  //           // // add aiconversationid
  //           // newAiLinkSettingsData.aiconversationid = aiConversationId;

  //           // // remove aisettingid
  //           // delete newAiLinkSettingsData.aisettingid;

  //           // // add locationid
  //           // newAiLinkSettingsData.locationid = locationId;

  //           // // add updatedon
  //           // newAiLinkSettingsData.updatedon = date;

  //           // // add createdon
  //           // newAiLinkSettingsData.createdon = date;

  //           // // add inuse
  //           // newAiLinkSettingsData.inuse = false;

  //           // // add archive
  //           // newAiLinkSettingsData.archive = false;

  //           // // if (globaldeleteme && globaldeleteme === 1) {
  //           // //   newAiLinkSettingsData.deleteme = globaldeleteme;
  //           // // }
  //           // // const test = newAgentData
  //           // // const test1 = newSettingsData
  //           // // const test2 = newAiLinkSettingsData

  //           // const lsRef = doc(db, "ailinksettings", aiLinkSettingId);
  //           // batch.set(lsRef, newAiLinkSettingsData);

  //           // if (clog()) console.log("aiAgentId", aiAgentId);
  //           // if (clog()) console.log("aiSettingId", aiSettingId);
  //           // if (clog()) console.log("aiConversationId", aiConversationId);
  //           // if (clog()) console.log("aiLinkSettingId", aiLinkSettingId);

  //           // // commit the batch
  //           // // res = await batch.commit();

  //           // returnData.data = {
  //           //   aiagentid: aiAgentId,
  //           //   aisettingid: aiSettingId,
  //           //   aiconversationid: aiConversationId,
  //           //   aipromptversionid: promptVersionId,
  //           //   ailinksettingid: aiLinkSettingId,
  //           // };

  //           // returnData.data = { aiagentid: id };
  //           */
  //         } else {
  //           returnData.error = webtokenRes.error;
  //         }

  //       }
  //       else {
  //         returnData.error = {
  //           val: "aiagentPresetData or aiconversationsPresetData is missing",
  //         };
  //       }



  //     }
  //     else {
  //       returnData.error = {
  //         val: "Location id or presetref is missing",
  //       };
  //     }

  //     return returnData;
  //   } catch (err) {
  //     console.error(err);
  //     returnData.error = err;
  //     return returnData;
  //   }
  // };

  return (
    <AdminAiAuthContext.Provider
      value={{
        urlDataParams,

        showAdminAiPresetAgentsDialog,
        setShowAdminAiPresetAgentsDialog,

        adminIsSavingPresetAgent,
        setAdminIsSavingPresetAgent,

        adminCustomerAiPresetAgentRefDocsFound,
        setAdminCustomerAiPresetAgentRefDocsFound,
        getCustomerAiPresetAgentRefDocs: async (uData, locationId, categoryAgentsSelected) => {
          let returnData = {};
          try {
            // const test0 = aiAgentsSelected;
            // const test1 = aiSettingsSelected;
            // const test2 = aiConversation;
            // const test3 = aiSettingsData;
            // const test4 = aiSettingsDataUpdated;
            // const test5 = aiConversationsDocs;

            const result = await processGetCustomerAiPresetAgentRefDocs(uData, locationId, categoryAgentsSelected)
            if (result?.data) {
              returnData.data = result.data;
            } else {
              returnData.error = result.error;
            }

            // let locationid = "";
            // if (locationId) {
            //   locationid = locationId;
            // }

            // let aicategoryagentid = "";
            // if (categoryAgentsSelected && categoryAgentsSelected.aicategoryagentid) {
            //   aicategoryagentid = categoryAgentsSelected.aicategoryagentid;
            // }

            // let aiagentPromptRefs = [];
            // // loop over categoryAgentsSelected.aipresetagents and get presetref
            // if (categoryAgentsSelected && categoryAgentsSelected.aipresetagents) {
            //   categoryAgentsSelected.aipresetagents.forEach((presetagent) => {
            //     const aiagentid = presetagent.aiagentid;
            //     const presetref = presetagent.presetref;
            //     const promptRefData = {
            //       aiagentid: aiagentid,
            //       presetref: presetref,
            //     }
            //     aiagentPromptRefs.push(promptRefData);
            //   });
            // }
            // const test = aiagentPromptRefs;

            // let customerAiAgentPromptRefFound = {};

            // if (locationid && aiagentPromptRefs) {


            //   // loop over aiagentPromptRefs
            //   for (let i = 0; i < aiagentPromptRefs.length; i++) {
            //     const presetref = aiagentPromptRefs[i].presetref;
            //     const presetrefAgentId = aiagentPromptRefs[i].aiagentid;

            //     // get aiagents doc connected to presetref
            //     const q = query(
            //       collection(db, "aiagents"),
            //       where("locationid", "==", locationid),
            //       where("presetref", "==", presetref),
            //       orderBy("createdon", "desc")
            //     );

            //     const snapshot = await getDocs(q);

            //     snapshot.forEach((doc) => {
            //       // doc.data() is never undefined for query doc snapshots
            //       // if (clog()) console.log(doc.id, " => ", doc.data());
            //       // docExists = true;
            //       const docData = doc.data();
            //       const aiagentid = docData.aiagentid;

            //       // const smallData = {
            //       //   aiagentid: aiagentid,
            //       // }

            //       // check that aiagentid does not match presetrefAgentId
            //       // otherwise the agent the preset is based on could be overwritten
            //       // will only happen in account that created the preset
            //       // if (aiagentid !== presetrefAgentId) {
            //       // if presetref is not in customerAiAgentPromptRefFound then add it
            //       if (!customerAiAgentPromptRefFound[presetref]) {
            //         customerAiAgentPromptRefFound[presetref] = [];
            //       }
            //       // add aiagentid to customerAiAgentPromptRefFound
            //       customerAiAgentPromptRefFound[presetref].push(aiagentid);
            //       // }
            //     });
            //   }

            //   returnData.data = {
            //     customerDocs: customerAiAgentPromptRefFound,
            //     aicategoryagentid: aicategoryagentid,
            //   };

            //   // returnData.data = {
            //   //   customerAiAgentPromptRefFound: customerAiAgentPromptRefFound,
            //   //   // presetref: presetref,
            //   //   // aipresetagentsDoc: presetagentsDoc,
            //   //   // docExists: docExists,
            //   // };

            // } else {
            //   returnData.error = {
            //     val: "Location id or presetref is missing",
            //   };
            // }

            return returnData;

          } catch (err) {
            console.error(err);
            returnData.status = 501;
            returnData.error = {
              val: "get Customer AiPresetAgent Ref Docs failed",
            };
            // return returnData;
          }
        },
        saveAdminAiPresetAgentToCustomer: async (uData, presetagent, replaceAgent) => {

          let returnData = {};
          try {

            // deleteme for testing
            if (globaldeleteme > 0) {
              presetagent.deleteme = globaldeleteme;
            }
            let result
            result = await processSaveAdminAiPresetAgentToCustomer(uData, presetagent, replaceAgent);
            if (result?.data) {
              returnData.data = result.data;
            } else {
              returnData.error = result.error;
            }

            return returnData;
          } catch (err) {
            console.error(err);
            returnData.error = err;
            return returnData;
          }
        },
      }}
    >
      {children}
    </AdminAiAuthContext.Provider>
  );
};

// export default AdminAiAuthProvider;
