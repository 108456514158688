import React, { useEffect, useRef, useContext, useState } from "react";
// import * as React from 'react';
import _ from "lodash";
import Highlight from "react-highlight";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { CustomerAuthContext } from "../helpers/CustomerAuthProvider";
import { AiAuthContext } from "../helpers/AiAuthProvider";
import {setd, debug, setc, clog} from "../helpers/HelperFunctions";

import {
  Grid,
  Box,
  Typography,
  Link,
  Snackbar,
  Alert,
  // Button,
  CircularProgress,
  Chip,
  IconButton,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import {
  useTheme,
  // styled
} from "@mui/material/styles";

import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import AddIcon from "@mui/icons-material/Add";

import { set } from "date-fns";
import { setIn } from "formik";
import { is } from "date-fns/locale";

// const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9_-]+$/;
const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9_]+$/;

export default function AiAgentPresetAgentsDialog(props) {
  const theme = useTheme();

  const { userData } = props;


  // const { adminStatus, setAdminStatus } = useContext(CustomerAuthContext);
  const { aiAgentsSelected, setAiAgentsSelected } = useContext(AiAuthContext);
  // const { aiSettingsSelected, setAiSettingsSelected } = useContext(AiAuthContext);
  // const { aiAgentsIsEditing, setAiAgentsIsEditing } = useContext(AiAuthContext);
  const { getAiPresetAgentRefDoc } = useContext(AiAuthContext);
  const [aiPresetAgentRefDoc, setAiPresetAgentRefDoc] = useState(null);
  const [aiPresetAgentRefDocName, setAiPresetAgentRefDocName] = useState("");

  const { aiAgentsIsSavingPresetAgent, setAiAgentsIsSavingPresetAgent } = useContext(AiAuthContext);

  const { saveAiPresetAgent } = useContext(AiAuthContext);
  const [aiPresetAgentName, setAiPresetAgentName] = useState("");
  const [showSavePresetAgentAlert, setShowSavePresetAgentAlert] = useState(false);

  const { showAiPresetAgentsDialog, setShowAiPresetAgentsDialog } = useContext(AiAuthContext);
  // const { showAiAgentVariablesDialog, setShowAiAgentVariablesDialog } = useContext(AiAuthContext);
  const [overwriteAiPresetAgentData, setOverwriteAiPresetAgentData] = useState(false);


  const [init, setInit] = useState(false);

  // triggered when category is selected
  useEffect(() => {
    const getData = async () => {
      if (aiAgentsSelected !== null) {
        let name = "";
        if (aiAgentsSelected?.name) {
          name = aiAgentsSelected.name;
        }
        setAiPresetAgentName(name);
        // check if
        const result = await getAiPresetAgentRefDoc(userData, aiAgentsSelected);
        if (result?.data) {
          setAiPresetAgentRefDoc(result.data);
          if (result.data.docExists) {
            setAiPresetAgentRefDocName(result.data.aipresetagentsDoc.name);
          }
        }
        setInit(true);
      }
    };

    if (showAiPresetAgentsDialog) {
      getData()
        // make sure to catch any error
        .catch(console.error);
    }
  }, [showAiPresetAgentsDialog]);


  const handleOnClickAiAgentsSavePresetAgentCancel = () => {
    // // reset checkbox
    // setAiAgentFullDelete(false);

    // // reset removing
    // setAiAgentsIsRemoving(false);

    // reset name
    // if (aiPresetAgentName !== "") {
    //   setAiPresetAgentName("");
    // }

    setShowAiPresetAgentsDialog(false);

    // deactivate edit mode
    // setAiAgentsIsEditing({
    //   active: false,
    //   type: "",
    // });
  };

  const handleOnChangeAiAgentPresetName = (event) => {
    setAiPresetAgentName(event.target.value);
  };

  const isAiPresetAgentSaveDisabled = () => {
    let disabled = false;
    if (aiPresetAgentName === "") {
      disabled = true;
    }

    return disabled;
  }


  const handleOnClickSavePresetAgentConfirm = async () => {
    if (clog()) console.log("handleOnClickSavePresetAgentConfirm");
    // // setAiSettingsName(name)
    setAiAgentsIsSavingPresetAgent(true);

    let type = "new";
    if (aiPresetAgentRefDoc.docExists === true && overwriteAiPresetAgentData === true) {
      type = "overwrite";
    }
    else if (aiPresetAgentRefDoc.docExists === true && overwriteAiPresetAgentData === false) {
      type = "duplicate";
    }

    let presetAgentName = aiPresetAgentName;
    if (aiPresetAgentRefDoc.docExists === true && overwriteAiPresetAgentData === true) {
      presetAgentName = aiPresetAgentRefDocName;
    }
    const result = await saveAiPresetAgent(userData, presetAgentName, type, aiPresetAgentRefDoc);

    if (result && result.data) {
      // what to do here?
      if (clog()) console.log("handleOnClickSavePresetAgent result", result.data);
    } else {
      if (clog()) console.log("handleOnClickSavePresetAgent result", result.error);
    }


    setAiAgentsIsSavingPresetAgent(false);

    // reset name
    setAiPresetAgentName("");

    setShowAiPresetAgentsDialog(false);

    // show alert
    setShowSavePresetAgentAlert(true);
  };

  const handleAiAgentDialogClose = () => {
    setShowAiPresetAgentsDialog(false);
  };

  const handleAiPresetAgentOverwriteData = () => {
    setOverwriteAiPresetAgentData(!overwriteAiPresetAgentData);
  }

  let open = false;
  if (showAiPresetAgentsDialog) {
    open = true;
  }



  let title = "Save AI Agent Preset";

  return (
    <Dialog
      name="aiagentsaveagentpresetdialog"
      open={open}
      onClose={handleAiAgentDialogClose}
      maxWidth="sm"
      sx={{
        "& .MuiDialog-paper": {
          position: "absolute",
          top: "10px",
          // left: "50%",
          // transform: "translate(-50%, -50%)",
        },
        // position: "absolute",
        // top: "0px",
        // alignItems: "flex-start",
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent
        dividers={true}
      >

        {init === true ? (
          <>
            <DialogContentText>Save AI Agent Preset</DialogContentText>

            <Box
              sx={{
                mt: 2,
                width: 300,
              }}
            >
              <TextField
                required
                fullWidth
                label="Agent Preset Name"
                name="agentpresetname"
                autoComplete="agentpresetname"
                placeholder="Add Agent Preset Name..."
                // disabled={true}
                // InputProps={{
                //   readOnly: aiAgentsIsEditing.active === true && aiAgentsIsEditing.type === "add" ? false : true,
                // }}
                InputLabelProps={{ shrink: true }}
                // autoFocus
                // value={aiSettingsDataUpdated.data.name}
                value={aiPresetAgentRefDoc.docExists === true && overwriteAiPresetAgentData ? aiPresetAgentRefDocName : aiPresetAgentName}
                onChange={(e) => handleOnChangeAiAgentPresetName(e)}
              // onKeyDown={(event) => {
              //   if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
              //     event.preventDefault();
              //   }
              // }}
              // error={aiAgentsAutomationLinkHasError() === true ? true : false}
              // helperText={aiAgentsAutomationLinkErrorText()}
              disabled={aiPresetAgentRefDoc.docExists === true && overwriteAiPresetAgentData ? true : false}
              />
            </Box>

            {aiPresetAgentRefDoc.docExists === true ? (
              <Box
                sx={{
                  mt: 2,
                  // width: 300,
                }}
              >
                <FormControlLabel
                  label="Overwrite Preset"
                  control={
                    <Checkbox
                      checked={overwriteAiPresetAgentData}
                      onChange={handleAiPresetAgentOverwriteData}
                      disabled={aiPresetAgentRefDoc.docExists === false ? true : false}
                    />
                  }
                />
              </Box>

            ) : (
              <></>
            )}
          </>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: 300,
            }}
          >
            <CircularProgress />
          </Box>
        )
        }
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",

            mr: 1,
          }}
        >
          <Button
            aria-label="Cancel"
            color="primary"
            variant="outlined"
            // size="small"
            // variant="light"
            sx={{
              mr: 1,
            }}
            onClick={handleOnClickAiAgentsSavePresetAgentCancel}
            disabled={aiAgentsIsSavingPresetAgent}
          >
            Cancel
          </Button>

          {aiAgentsIsSavingPresetAgent ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "64px",
                height: "36.5px",
              }}
            >
              <CircularProgress
                size={12}
                sx={{
                  p: 0,
                  m: 0,
                }}
              // variant="determinate"
              />
            </Box>
          ) : (
            <Button
              aria-label="Save"
              color="primary"
              variant="contained"
              // size="small"
              // variant="light"
              onClick={handleOnClickSavePresetAgentConfirm}
              disabled={isAiPresetAgentSaveDisabled()}
            >
              Save
            </Button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
}
