import React, { useState, useEffect, useContext, useLayoutEffect, useRef, useCallback } from "react";

import { RemoteConfigAuthContext } from "../helpers/RemoteConfigAuthProvider";
import { CustomerAuthContext } from "../helpers/CustomerAuthProvider";
import { AiAuthContext } from "../helpers/AiAuthProvider";
import {setd, debug, setc, clog} from "../helpers/HelperFunctions";

import _ from "lodash";

import {
  useTheme,
  // styled
} from "@mui/material/styles";

import {
  Box,
  // Tab,
  // Tabs,
  Typography,
  Button,
  Grid,
  Paper,
  // Slider,
  TextField,
  TextareaAutosize,
  IconButton,
  CircularProgress,
  LinearProgress,
} from "@mui/material";

import { styled } from "@mui/system";

import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import AddIcon from "@mui/icons-material/Add";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import Container from "@mui/material/Container";
import GridKeywordTimelinePane from "./GridKeywordTimelinePane";
import LoadingSubPane from "./LoadingSubPane";
import { set } from "date-fns";

import SupportAgentIcon from "@mui/icons-material/SupportAgent";


const AiQuestionsLivePanel = (props) => {
  const theme = useTheme();


  const { rc } = useContext(RemoteConfigAuthContext);
  const { userData } = props;

  const { adminStatus, setAdminStatus } = useContext(CustomerAuthContext);

  const { testVal, setTestVal } = useContext(AiAuthContext);

  const { aiLiveConversationsSelected, setAiLiveConversationsSelected } = useContext(AiAuthContext);
  const { aiLiveConversationFetchData, setAiLiveConversationFetchData } = useContext(AiAuthContext);
  const { getAiLiveConversationLinkSettingsData } = useContext(AiAuthContext);


  const { aiLiveSettingsData, setAiLiveSettingsData } = useContext(AiAuthContext);
  const { aiLiveSettingsDataUpdated, setAiLiveSettingsDataUpdated } = useContext(AiAuthContext);
  const { aiLiveConversation, setAiLiveConversation } = useContext(AiAuthContext);
  const { aiLiveMessages, setAiLiveMessages } = useContext(AiAuthContext);

  const { aiConversationsSelected, setAiConversationsSelected } = useContext(AiAuthContext);
  const { aiConversationFetchData, setAiConversationFetchData } = useContext(AiAuthContext);

  const { aiSettingsData, setAiSettingsData } = useContext(AiAuthContext);
  const { aiSettingsDataUpdated, setAiSettingsDataUpdated } = useContext(AiAuthContext);
  // const { aiSettingsDataRef } = useContext(AiAuthContext);
  // const { aiSettingsDataConversationIdRef } = useContext(AiAuthContext);

  const { getAiPromptVersionsDocs } = useContext(AiAuthContext);
  // const { aiPromptVersionsDocs, setAiPromptVersionDocs } = useContext(AiAuthContext);
  // // const [aiPromptVersionsTabs, setAiPromptVersionsTabs] = useState(null);
  // const { getSelectedIndexFromAiPromptVersionsDocs } = useContext(AiAuthContext);
  // const { getSelectedNewIndexFromAiPromptVersionsDocs } = useContext(AiAuthContext);

  // const { aiPromptVersionSelected, setAiPromptVersionSelected } = useContext(AiAuthContext);
  // const { getAiPromptVersionData } = useContext(AiAuthContext);
  // const aiPromptVersionSelectedinit = useRef(true);
  // const { cleanAiPromptVersionDataForAiSettings } = useContext(AiAuthContext);
  // const { mergeAiPromptVersionAiSettings } = useContext(AiAuthContext);
  // const { aiPromptVersionReset, setAiPromptVersionReset } = useContext(AiAuthContext);
  // const { getDefaultAiPreset } = useContext(AiAuthContext);
  // const { processAddPromptVersionAiSettingsData } = useContext(AiAuthContext);
  // const [aiPromptVersionAdded, setAiPromptVersionAdded] = useState(null);
  // const [aiPromptVersionNewSaving, setAiPromptVersionNewSaving] = useState("");

  // const { aiEngineersPanelShow, setAiEngineersPanelShow } = useContext(AiAuthContext);

  const { aiModelSelected, setAiModelSelected } = useContext(AiAuthContext);
  const { aiModelSelectedRefresh, setAiModelSelectedRefresh } = useContext(AiAuthContext);
  const { aiStrategySelected, setAiStrategySelected } = useContext(AiAuthContext);
  const { aiStrategySelectedRefresh, setAiStrategySelectedRefresh } = useContext(AiAuthContext);

  // const [improvePromptIterations, setImprovePromptIterations] = useState(1);
  // const { updateAiConversationImproveProcessing } = useContext(AiAuthContext);
  // const { getAiQuestionsImprovePrompt } = useContext(AiAuthContext);
  // const [improvePromptSaving, setImprovePromptSaving] = useState(false);
  // const { aiConversation, setAiConversation } = useContext(AiAuthContext);
  // const { saveAiSettingsX } = useContext(AiAuthContext);

  // const { aiAgentsIsEditing, setAiAgentsIsEditing } = useContext(AiAuthContext);
  // const { aiAgentsIsRemoving, setAiAgentsIsRemoving } = useContext(AiAuthContext);
  // const { aiSettingsIsEditing, setAiSettingsIsEditing } = useContext(AiAuthContext);
  // const { aiConversationsIsEditing, setAiConversationsIsEditing } = useContext(AiAuthContext);
  // const { aiConversationsIsRemoving, setAiConversationsIsRemoving } = useContext(AiAuthContext);

  // const { aiEngineersPanelHeightResizing, setAiEngineersPanelHeightResizing } = useContext(AiAuthContext);

  const [init, setInit] = useState(true);

  const [strategy, setStrategy] = useState(null);

  // const [questionsHeight, setQuestionsHeight] = useState(0);
  // const elementRef = useRef(null);

  const [improvePromptProgress, setImprovePromptProgress] = React.useState(0);

  // initial load - based on AiChatPanel: fetchAiConversationData
  useEffect(() => {
    if (init && aiLiveConversationsSelected) {
      fetchAiLiveConversationLinkSettingsData()
        // make sure to catch any error
        .catch(console.error);

      setInit(false);
    }
    else if (init && aiLiveConversationsSelected === null) {
      // reset everything
      // no conversation exists, reset selected aisettings data
      setAiLiveSettingsData(null);
      setAiLiveSettingsDataUpdated(null);
      setAiLiveConversation(null);
      setAiLiveMessages([]);

      setInit(false);
    }

  }, [init, aiLiveConversationsSelected]);

  // aiConversationsSelected is changed - based on AiChatPanel: fetchAiConversationData
  useEffect(() => {
    if (!init && aiLiveConversationFetchData === false) {
      // aiConversationSelected data has been updated but is still the same conversation
      // happens when editing the name of a selected conversation in HistoryPanel
      setAiLiveConversationFetchData(true);
    } else if (!init && aiLiveConversationsSelected) {
      ;

      fetchAiLiveConversationLinkSettingsData()
        // make sure to catch any error
        .catch(console.error);
      // setInit(false)
    } else if (!init && aiLiveConversationsSelected === null) {
      // reset everything
      // no conversation exists, reset selected aisettings data
      setAiLiveSettingsData(null);
      setAiLiveSettingsDataUpdated(null);
      setAiLiveConversation(null);
      setAiLiveMessages([]);

      // aiSettingsDataRef.current = null;
      // // trigger useEffect in AiModelPanel
      setAiModelSelectedRefresh(true);
      // // trigger useEffect in AiQuestionsLivePanel
      setAiStrategySelectedRefresh(true);
    }
  }, [aiLiveConversationsSelected]);

  const fetchAiLiveConversationLinkSettingsData = useCallback(async () => {
    // BT ZZZ
    const result = await getAiLiveConversationLinkSettingsData(userData, aiLiveConversationsSelected);
    if (result && result.data) {

      setAiLiveSettingsDataUpdated(result.data);

      // trigger useEffect in AiModelPanel
      setAiModelSelectedRefresh(true);
      // trigger useEffect in AiQuestionsPanel
      setAiStrategySelectedRefresh(true);

    }
  }, [getAiPromptVersionsDocs]);


  const isTextFieldDisabled = () => {
    let disabled = false;
    return disabled;
  };

  // read only allows text to be read during settings add
  const isTextFieldReadOnly = () => {
    let readOnly = true;
    return readOnly;
  };

  const showNoConverationMessages = () => {

    return (
      <Box
        sx={{
          // mt: 2,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          // height: "100%",
          alignItems: 'center',
          justifyContent: "center",
        }}
      >
        <Box
          name={"roleIcon"}
          sx={{
            mt: -4,
            width: "100px",
            pt: 2,
            // backgroundColor: '#cc0000',
            display: "flex",
            // alignItems: 'center',
            justifyContent: "center",
          }}
        >
          <SupportAgentIcon
            sx={{
              fontSize: "100px",
              color: "grey.300",
            }}
          // color='primary'
          />
        </Box>
        <Typography
          align="center"
          sx={{
            fontSize: "18px",
            color: "grey.600",
          }}
        >
          Select A Conversation
        </Typography>
      </Box>
    );

  };

  if (
    aiLiveSettingsDataUpdated &&
    aiStrategySelected &&
    aiStrategySelected.strategy &&
    aiStrategySelected.strategy !== ""
    // aiLiveSettingsDataUpdated[aiStrategySelected.strategy]
  ) {
    if (aiStrategySelected.strategy === "question_chat") {
      let objectivesVal = "";
      if (
        aiLiveSettingsDataUpdated &&
        aiLiveSettingsDataUpdated[aiStrategySelected.strategy] &&
        aiLiveSettingsDataUpdated[aiStrategySelected.strategy].objectives
      ) {
        objectivesVal = aiLiveSettingsDataUpdated[aiStrategySelected.strategy].objectives;
      }

      let whatVal = "";
      if (aiLiveSettingsDataUpdated && aiLiveSettingsDataUpdated[aiStrategySelected.strategy] && aiLiveSettingsDataUpdated[aiStrategySelected.strategy].what) {
        whatVal = aiLiveSettingsDataUpdated[aiStrategySelected.strategy].what;
      }

      let whoVal = "";
      if (aiLiveSettingsDataUpdated && aiLiveSettingsDataUpdated[aiStrategySelected.strategy] && aiLiveSettingsDataUpdated[aiStrategySelected.strategy].who) {
        whoVal = aiLiveSettingsDataUpdated[aiStrategySelected.strategy].who;
      }

      return (
        <Box
          sx={{
            maxHeight: "100%",
            // overflow: 'auto',
          }}
        >
          {
            // showPromptVersionControls()
          }

          <TextField
            id="objectives"
            sx={{
              backgroundColor: theme.palette.background.paper,
              // mt: 2,
              "& .MuiOutlinedInput-input": {
                fontSize: "15px",
              },
            }}
            fullWidth
            label="Objectives"
            multiline
            // rows={5}
            value={objectivesVal}
            // onChange={(event) => handleQuestionChange(event, "objectives")}
            disabled={isTextFieldDisabled()}
            InputProps={{
              readOnly: isTextFieldReadOnly(),
            }}
          // defaultValue="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text"
          />

          <TextField
            id="what"
            sx={{
              mt: 4,
              backgroundColor: theme.palette.background.paper,
              "& .MuiOutlinedInput-input": {
                fontSize: "15px",
              },
            }}
            fullWidth
            label="What"
            multiline
            // rows={10}
            value={whatVal}
            // onChange={(event) => handleQuestionChange(event, "what")}
            disabled={isTextFieldDisabled()}
            InputProps={{
              readOnly: isTextFieldReadOnly(),
            }}
          // defaultValue="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text"
          />

          <TextField
            id="who"
            sx={{
              mt: 4,
              backgroundColor: theme.palette.background.paper,
              "& .MuiOutlinedInput-input": {
                fontSize: "15px",
              },
            }}
            fullWidth
            label="Who"
            multiline
            // rows={10}
            value={whoVal}
            // onChange={(event) => handleQuestionChange(event, "who")}
            disabled={isTextFieldDisabled()}
            InputProps={{
              readOnly: isTextFieldReadOnly(),
            }}
          // defaultValue="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text"
          />
        </Box>
      );
    } else if (aiStrategySelected.strategy === "chat" || aiStrategySelected.strategy === "chatwithknowledge") {
      let promptVal = "";
      if (aiLiveSettingsDataUpdated && aiLiveSettingsDataUpdated[aiStrategySelected.strategy] && aiLiveSettingsDataUpdated[aiStrategySelected.strategy].prompt) {
        promptVal = aiLiveSettingsDataUpdated[aiStrategySelected.strategy].prompt;
      }


      return (
        <Box
          name={"chatPanel"}
          sx={{
            // backgroundColor: "#cc0000",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {
            // showPromptVersionControls()
          }


          <TextField
            id="prompt"
            sx={{
              flexGrow: 1,
              // height : '100%',
              backgroundColor: theme.palette.background.paper,
              // mt: 2,
              "& .MuiInputBase-multiline": {
                pr: 0,
                flexGrow: 1,
                userSelect: "none",
                // lineHeight: 2,
              },
              "& .MuiOutlinedInput-input": {
                fontSize: "15px",
                height: "100% !important",
                overflow: "auto !important",
                // userSelect: "none !important",
                pr: 2,
              },
              "& .MuiOutlinedInput-input.Mui-disabled": {
                color: theme.palette.text.primary,
                WebkitTextFillColor: theme.palette.text.primary,
              },
            }}
            fullWidth
            label="Prompt"
            multiline
            placeholder="Enter your chat prompt"
            value={promptVal}
            // onChange={(event) => handleQuestionChange(event, "prompt")}
            disabled={isTextFieldDisabled()}
            InputProps={{
              readOnly: isTextFieldReadOnly(),
            }}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
      );

    }
  }

  if (init) {
    return <LoadingSubPane spin={true} />;
  }


  if (
    aiLiveSettingsDataUpdated === null &&
    aiStrategySelected === null
  ) {

    // copied from AiChatLivePanel
    return (
      <Box
        name={"chatPanel"}
        sx={{
          display: "flex",
          height: "100%",
          flexDirection: "column",
          // overflow: 'hidden',
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: theme.palette.border.normal,
          // backgroundColor: '#efefef',
          borderRadius: "4px",
          overflow: "hidden",
          // backgroundColor: theme.palette.background.bg,
          backgroundColor: "#cc0000",
        }}
      >
        <Box
          name={"chatMessages"}
          sx={{
            flexGrow: 1,
            overflow: "hidden",
            height: "100%",
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Box
            id={"chatMessagesScrollWrapper"}
            sx={{
              maxHeight: "100%",
              height: "100%",
              overflow: "auto",
            }}
          >
            <Grid
              container
              // component={wrapper}
              // ref={wrapper} // Option 1: use ref to scroll to bottom
              sx={{
                my: 0,
                height: "100%",
              }}
            >
              {
                showNoConverationMessages()
              }

            </Grid>
          </Box>
        </Box>



      </Box>
    );

  }

  return <></>;
};

export default React.memo(AiQuestionsLivePanel);
