import React, { createContext, useState, useRef, useContext } from "react";
// import auth from '@react-native-firebase/auth';
import { getUserToken } from "../code/firebase";

import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithCustomToken,
} from "firebase/auth";

import {
  // collection,
  doc,
  setDoc,
  onSnapshot,
  getDoc,
  getDocs,
  getFirestore,
  collection,
  query,
  where,
  orderBy,
  limit,
  addDoc,
  updateDoc,
  deleteDoc,
  writeBatch,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";

import _ from "lodash";
import { diff, addedDiff, deletedDiff, updatedDiff, detailedDiff } from "deep-object-diff";

import { getFunctions, httpsCallable } from "firebase/functions";
import { useAuthState } from "react-firebase-hooks/auth";

import { getUrlParams } from "../code/ecomacy";
import { firebase } from "../code/firebase";
import { e, re, sort } from "mathjs";
import { ar, de, tr } from "date-fns/locale";
import { settings } from "firebase/analytics";
// import { Timestamp } from '@google-cloud/firestore';

// import { RemoteConfigAuthContext } from "./RemoteConfigAuthProvider";
import axios from 'axios';

const auth = getAuth(firebase);
const functions = getFunctions(firebase, "europe-west1");
const db = getFirestore(firebase);
// const auth = getAuth();

// const [user, loadingUser, errorUser] = useAuthState(auth);

import { setd, debug, setc, clog } from "./HelperFunctions";

const generateFirestoreDate = () => {
  // generate firestore timestamp
  // firebase.firestore.FieldValue.serverTimestamp()
  // firebase.firestore.Timestamp.now()
  // const timestamp = 1676475521;
  // const timestampDate = new Date(timestamp*1000);
  // const timestampFirestore = admin.firestore.Timestamp.fromDate(timestampDate)
  // const timestampFirestore = admin.firestore.Timestamp.now()
  // const timestampFirestore = firebase.firestore.Timestamp.now()
  const timestampFirestore = serverTimestamp();

  return timestampFirestore;
};
const delay = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds));

const getDiff = (oldData, newData) => {
  //diff, addedDiff, deletedDiff, updatedDiff, detailedDiff
  const difference = diff(oldData, newData);
  return difference;
};


const getAiWebCodeTokenFromApi = async (payload) => {
  let returnData = {
    status: 0,
    // data: {},
    // error: {},
  };
  try {
    let URL = import.meta.env.VITE_REACT_APP_AI_API_URL;
    /*
    let URL = 'https://api.ecomacy.com'
    if (import.meta.env.VITE_REACT_APP_ENV === 'dev') {
      URL = 'http://localhost:8080'
    }
    */
    //STUAUTH
    if (URL) {
      const userToken = await getUserToken();
      const jwt = "Bearer " + userToken;
      const apiUrlPath = URL.concat("/gettoken");
      // const apiUrlPath = URL.concat("/gettoken/", payload.locationid, "/", payload.link, "/", payload.url);
      // const apiUrlPath = URL.concat("/gettoken/", jsonData);

      if (clog()) console.log("apiUrlPath", apiUrlPath);
      const response = await axios.get(apiUrlPath, {
        // const response = await axios.post(apiUrlPath, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: jwt,
        },
        params: payload,
      });
      // cLog(response.data)

      if (response.status === 200) {
        returnData.status = response.status;
        returnData.data = response.data;
      } else {
        returnData.status = response.status;
        returnData.error = response.error;
      }
      // if (clog()) console.log('getAiWebCodeTokenFromApi returnData:', returnData);
      return returnData;
    } else {
      returnData.status = 490;
      returnData.error = "REACT_APP_API_URL url missing from env";
      return returnData;
    }
  } catch (err) {
    console.error("response error", err);
    returnData.status = 491;
    returnData.error = err;
    return returnData;
  }
};

const getAiWebCodeToken = async (userData, automationlink) => {
  let returnData = {
    status: 200,
    data: {},
    error: {},
  };
  try {
    let locationid = "";
    if (userData && userData.locationId) {
      locationid = userData.locationId;
    }

    // let domains = [];
    // if (agentDomains && agentDomains.length > 0) {
    //   domains = agentDomains;
    // }

    if (locationid !== "" && automationlink !== "") {
      const data = {
        locationid: locationid,
        link: automationlink,
        // url: url,
        // domains: domains,
      };

      let result = await getAiWebCodeTokenFromApi(data);
      returnData.status = result.status;

      if (result.status === 200 && result.data) {
        returnData.status = result.status;
        returnData.data = result.data;
      } else {
        returnData.status = result.status;
        returnData.error = result.error.code;
      }
      // let result = {status: 'success'};
      return returnData;
    }
  } catch (err) {
    console.error(err);
    returnData.status = 501;
    returnData.error = {
      val: "Web Code Token data fetch failed",
    };
    // return returnData;
  }
};

// temp function to login
const adminLogin = async (email, password) => {

  const returnData = {};
  try {

    await signInWithEmailAndPassword(auth, email, password);

    const user = auth.currentUser;

    returnData.data = user;

    return returnData;

  } catch (error) {
    console.error(`Error deleting documents from ${collectionName} with deleteme ${deletemeVal}:`, error);
    returnData.error = `Error deleting documents from ${collectionName} with deleteme ${deletemeVal}: ${error.message}`;

    return returnData;
  }
}

// Function to get aiagent documents that match location
const getCustomerAiAgentsDocs = async (locationId) => {
  const returnData = {};
  try {

    let aiagentsDocs = [];
    const user = auth.currentUser;
    // Creating a reference to the collection with a query
    const q = query(
      collection(db, 'aiagents'),
      where('locationid', '==', locationId)
    );

    const querySnapshot = await getDocs(q);
    for (const doc of querySnapshot.docs) {
      aiagentsDocs.push(doc.data());
    }

    returnData.data = aiagentsDocs;
    return returnData;

  } catch (error) {
    console.error(`Error fetching documents from aiagents with locationid ${locationId}:`, error);
    returnData.error = `Error fetching documents from aiagents with locationid ${locationId}: ${error.message}`;

    return returnData;
  }
}

// Function to delete documents from a collection based on aiconversationId
const deleteDocsByDeleteMeId = async (collectionName, deletemeVal) => {

  const returnData = {};
  try {

    let processedDataIds = [];
    const user = auth.currentUser;
    // Creating a reference to the collection with a query
    const q = query(collection(db, collectionName), where('deleteme', '==', deletemeVal));

    const querySnapshot = await getDocs(q);
    for (const doc of querySnapshot.docs) {
      await deleteDoc(doc.ref);
      await delay(100);
      processedDataIds.push(doc.id);
    }

    returnData.data = processedDataIds;
    return returnData;

  } catch (error) {
    console.error(`Error deleting documents from ${collectionName} with deleteme ${deletemeVal}:`, error);
    returnData.error = `Error deleting documents from ${collectionName} with deleteme ${deletemeVal}: ${error.message}`;

    return returnData;
  }

}

// Function to get aiagent documents that match presetref in location
const processGetCustomerAiPresetAgentRefDocs = async (uData, locationId, categoryAgentsSelected) => {
  let returnData = {};
  try {
    // const test0 = aiAgentsSelected;
    // const test1 = aiSettingsSelected;
    // const test2 = aiConversation;
    // const test3 = aiSettingsData;
    // const test4 = aiSettingsDataUpdated;
    // const test5 = aiConversationsDocs;

    let locationid = "";
    if (locationId) {
      locationid = locationId;
    }

    let aicategoryagentid = "";
    if (categoryAgentsSelected && categoryAgentsSelected.aicategoryagentid) {
      aicategoryagentid = categoryAgentsSelected.aicategoryagentid;
    }

    let aiagentPromptRefs = [];
    // loop over categoryAgentsSelected.aipresetagents and get presetref
    if (categoryAgentsSelected && categoryAgentsSelected.aipresetagents) {
      categoryAgentsSelected.aipresetagents.forEach((presetagent) => {
        const aiagentid = presetagent.aiagentid;
        const presetref = presetagent.presetref;
        const promptRefData = {
          aiagentid: aiagentid,
          presetref: presetref,
        }
        aiagentPromptRefs.push(promptRefData);
      });
    }
    const test = aiagentPromptRefs;

    let customerAiAgentPromptRefFound = {};

    if (locationid && aiagentPromptRefs) {


      // loop over aiagentPromptRefs
      for (let i = 0; i < aiagentPromptRefs.length; i++) {
        const presetref = aiagentPromptRefs[i].presetref;
        const presetrefAgentId = aiagentPromptRefs[i].aiagentid;

        // get aiagents doc connected to presetref
        const q = query(
          collection(db, "aiagents"),
          where("locationid", "==", locationid),
          where("presetref", "==", presetref),
          orderBy("createdon", "desc")
        );

        const snapshot = await getDocs(q);

        snapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          // if (clog()) console.log(doc.id, " => ", doc.data());
          // docExists = true;
          const docData = doc.data();
          const aiagentid = docData.aiagentid;

          // const smallData = {
          //   aiagentid: aiagentid,
          // }

          // check that aiagentid does not match presetrefAgentId
          // otherwise the agent the preset is based on could be overwritten
          // will only happen in account that created the preset
          // if (aiagentid !== presetrefAgentId) {
          // if presetref is not in customerAiAgentPromptRefFound then add it
          if (!customerAiAgentPromptRefFound[presetref]) {
            customerAiAgentPromptRefFound[presetref] = [];
          }
          // add aiagentid to customerAiAgentPromptRefFound
          customerAiAgentPromptRefFound[presetref].push(aiagentid);
          // }
        });
      }

      returnData.data = {
        customerDocs: customerAiAgentPromptRefFound,
        aicategoryagentid: aicategoryagentid,
      };

      // returnData.data = {
      //   customerAiAgentPromptRefFound: customerAiAgentPromptRefFound,
      //   // presetref: presetref,
      //   // aipresetagentsDoc: presetagentsDoc,
      //   // docExists: docExists,
      // };

    } else {
      returnData.error = {
        val: "Location id or presetref is missing",
      };
    }

    // const user = auth.currentUser;


    return returnData;

  } catch (err) {
    console.error(err);
    returnData.status = 501;
    returnData.error = {
      val: "get Customer AiPresetAgent Ref Docs failed",
    };
    // return returnData;
  }
}

// Function to delete documents from a collection based on aiconversationId
const deleteDocsByConversationId = async (collectionName, aiConversationId) => {
  const returnData = {};
  try {
    let processedDataIds = [];
    let q;
    if (collectionName === "aimessages") {
      q = query(collection(db, collectionName), where("id", "==", aiConversationId));
    } else {
      q = query(collection(db, collectionName), where("aiconversationid", "==", aiConversationId));
    }

    const querySnapshot = await getDocs(q);
    for (const doc of querySnapshot.docs) {
      await deleteDoc(doc.ref);
      await delay(100);
      processedDataIds.push(doc.id);
    }

    returnData.data = processedDataIds;
  } catch (error) {
    console.error(`Error deleting documents from ${collection} for aiconversationid ${aiConversationId}:`, error);
    returnData.error = `Error deleting documents from ${collection} for aiconversationid ${aiConversationId}: ${error.message}`;
  }

  return returnData;
}

// Main function to delete documents
const deleteAiAgentAndRelatedData = async (locationId, aiAgentId) => {
  let returnData = {};

  try {
    // aiagent
    //// aiagentid

    // aiconversations
    //// aisettings.aiagentid
    // aimessages
    //// aimessages/${aiconversationid}/messages

    // aipromptversions
    //// aiconversationid

    // aiengineerconversations
    //// aiconversationid
    //// locationid

    // ailinksettings
    //// aiagentid
    //// aiconversationid
    let processedData = {
      aiagentidremove: "",
      aiconversationsremove: {},
    }

    // Find and delete aiagents doc
    // Create a query
    const qa = query(
      collection(db, 'aiagents'),
      where('locationid', '==', locationId),
      where('aiagentid', '==', aiAgentId)
    );

    // Execute the query
    const queryAgentSnapshot = await getDocs(qa);
    for (const doc of queryAgentSnapshot.docs) {
      // Add aiagentid to processedData
      processedData.aiagentidremove = doc.id;

      // Delete the document
      await deleteDoc(doc.ref);
      await delay(100);
    }

    // Find aiconversations docs, save aiconversationId, and delete
    let aiConversationIds = [];
    // Creating a query
    const qc = query(
      collection(db, 'aiconversations'),
      where('aisettings.aiagentid', '==', aiAgentId)
    );

    // Executing the query
    const queryConversationsSnapshot = await getDocs(qc);
    for (const doc of queryConversationsSnapshot.docs) {
      aiConversationIds.push(doc.id);
      await deleteDoc(doc.ref);
      await delay(100);

      // Add aiconversationid to processedData
      processedData.aiconversationsremove[doc.id] = {
        aiconversationid: doc.id
      };
    }

    // Use aiConversationId to delete related documents in other collections
    for (const aiConversationId of aiConversationIds) {
      // Delete aimessages message
      // Reference to the subcollection
      const messagesRef = collection(db, `aimessages/${aiConversationId}/messages`);

      let processedDataMessages = []
      // Executing the query
      const querySnapshot = await getDocs(query(messagesRef));
      for (const doc of querySnapshot.docs) {
        await deleteDoc(doc.ref);
        await delay(100);
        processedDataMessages.push(doc.id);
      }

      // add messages to processedData
      processedData.aiconversationsremove[aiConversationId] = {
        ...processedData.aiconversationsremove[aiConversationId],
        aimessageids: processedDataMessages
      };

      // delete documents in aimessages
      const aimessagesResult = await deleteDocsByConversationId('aimessages', aiConversationId);
      if (aimessagesResult?.data) {
        // add aimessages to processedData
        processedData.aiconversationsremove[aiConversationId] = {
          ...processedData.aiconversationsremove[aiConversationId],
          aimessages: aimessagesResult.data
        };
      }

      // delete documents in aipromptversions
      const aipromptversionsResult = await deleteDocsByConversationId('aipromptversions', aiConversationId);
      if (aipromptversionsResult?.data) {
        // add aipromptversions to processedData
        processedData.aiconversationsremove[aiConversationId] = {
          ...processedData.aiconversationsremove[aiConversationId],
          aipromptversions: aipromptversionsResult.data
        };
      }

      // delete documents in aiengineerconversations
      const aiengineerconversationsResult = await deleteDocsByConversationId('aiengineerconversations', aiConversationId);
      if (aiengineerconversationsResult?.data) {
        // add aiengineerconversations to processedData
        processedData.aiconversationsremove[aiConversationId] = {
          ...processedData.aiconversationsremove[aiConversationId],
          aiengineerconversations: aiengineerconversationsResult.data
        };
      }

      // delete documents in ailinksettings
      const ailinksettingsResult = await deleteDocsByConversationId('ailinksettings', aiConversationId);
      if (ailinksettingsResult?.data) {
        // add ailinksettings to processedData
        processedData.aiconversationsremove[aiConversationId] = {
          ...processedData.aiconversationsremove[aiConversationId],
          ailinksettings: ailinksettingsResult.data
        };
      }

    }

    returnData.data = processedData;

    return returnData;

  } catch (error) {
    console.error("Error in deleting documents:", error);
    returnData.error = error;

    return returnData;
  }
}

// process aiagent data for location
const processAiAgentPresetDataForLocation = (locationId, userId, aiAgentId, aiagentPresetData, date, webtoken, unassignAutomationLink) => {

  // clone aiagentPresetData
  let aiagentProcessedData = _.cloneDeep(aiagentPresetData);

  // add locationid
  aiagentProcessedData.locationid = locationId;

  // add userid
  // aiagentProcessedData.userid = userId;

  // add aiagentid
  aiagentProcessedData.aiagentid = aiAgentId;

  // add createdon
  aiagentProcessedData.createdon = date;

  // add updatedon
  aiagentProcessedData.updatedon = date;

  // add locationid
  aiagentProcessedData.locationid = locationId;


  // generate aisettingid
  const agentCollectionRef = collection(db, "aiagents");
  const docRefForAiSettings = doc(agentCollectionRef);
  // Get the new document Id
  const aiSettingId = docRefForAiSettings.id;

  // add new aisettingid to aisettings array
  aiagentProcessedData.aisettings[0].aisettingid = aiSettingId;

  // if automationlink already exists then replace with empty string
  if (unassignAutomationLink === true) {
    // remove automationlink
    aiagentProcessedData.automationlink = "";
    aiagentProcessedData.aisettings[0].automationlink = "";
  }

  // let newAiSetting = aiagentProcessedData.aisettings[0]
  // newAiSetting.aisettingid = aiSettingId;

  // let aiSettingsArr = [];

  // // add new aisetting to aisettings array
  // aiSettingsArr.push(newAiSetting);

  // aiagentProcessedData.aisettings = aiSettingsArr;

  // update domains
  aiagentProcessedData.domains = [];

  // update webtoken
  aiagentProcessedData.webtoken = webtoken;

  return aiagentProcessedData;
}

// process aiconversation data for location
const processAiConversationPresetDataForLocation = (
  locationId,
  userId,
  aiAgentId,
  settingId,
  aiConversationId,
  aiLinkSettingId,
  aiconversationPresetData,
  date,
) => {

  // clone aiagentPresetData
  let aiconversationProcessedData = _.cloneDeep(aiconversationPresetData);

  // add locationid
  aiconversationProcessedData.locationid = locationId;

  // add userid
  // aiconversationProcessedData.userid = userId;

  // add aiconversationid
  aiconversationProcessedData.aiconversationid = aiConversationId;

  // add aisettingid
  aiconversationProcessedData.aisettingid = settingId;

  // add ailinksettingid
  aiconversationProcessedData.ailinksettingid = aiLinkSettingId;

  // add createdon
  aiconversationProcessedData.createdon = date;

  // add updatedon
  aiconversationProcessedData.updatedon = date;

  // clean localstorage
  aiconversationProcessedData.localstorage = {};

  // update promptversion
  // aiconversationProcessedData.promptversion = 1;

  // update promptversioncount
  // aiconversationProcessedData.promptversioncount = 1;

  // clean messages
  // aiconversationProcessedData.messages = [];

  //// update aisettings object
  // aiagentid
  // aiconversationProcessedData.aisettings.aiagentid = aiAgentId;
  // aisettingid
  // aiconversationProcessedData.aisettings.aisettingid = settingId;
  // locationid
  // aiconversationProcessedData.aisettings.locationid = locationId;
  // userid
  // aiconversationProcessedData.aisettings.userid = userId;
  // promptversion
  // aiconversationProcessedData.aisettings.promptversion = 1;
  // remove version
  // delete aiconversationProcessedData.aisettings.version;
  // remove versionbasedon
  // delete aiconversationProcessedData.aisettings.versionbasedon;

  // process aisettings
  let aiSettingsData = []
  if (aiconversationProcessedData.aisettings) {
    aiSettingsData = aiconversationProcessedData.aisettings
  }

  // loop over aiSettingsData
  for (const aiSettingData of aiSettingsData) {
    const test = aiSettingData
    const conversationCollectionRef = collection(db, "aiconversations");
    // generate aisettingid
    const docRefForAiSettings = doc(conversationCollectionRef);
    // Get the new document Id
    const aiSettingsIndexId = docRefForAiSettings.id;

    // update aisettingsindexid
    aiSettingData.aisettingsindexid = aiSettingsIndexId

    // update promptversion
    aiSettingData.promptversion = 1

  }

  return aiconversationProcessedData;
}

// process ailinksettings data for location
const processAiLinkSettingsPresetDataForLocation = (
  locationId,
  userId,
  aiAgentId,
  // settingId,
  aiConversationId,
  aiLinkSettingId,
  aiconversationProcessedDataSettings,
  date,
  aiConversationName,
  // unassignAutomationLink,
  aiagentPresetDataAutomationLink,
  isAutomationLinkDoc,
) => {

  let ailinksettingsProcessedData = {};

  // clone aiconversationProcessedDataSettings
  let ailinksettingsSettingsProcessedData = _.cloneDeep(aiconversationProcessedDataSettings);
  // loop over ailinksettingsSettingsProcessedData
  for (const settingData of ailinksettingsSettingsProcessedData) {
    // remove name
    delete settingData.name
  }

  // add aisettings
  ailinksettingsProcessedData.aisettings = ailinksettingsSettingsProcessedData

  // add name
  ailinksettingsProcessedData.name = aiConversationName

  // add userid
  ailinksettingsProcessedData.userid = userId

  // add locationid
  ailinksettingsProcessedData.locationid = locationId;

  // add userid
  // ailinksettingsProcessedData.userid = userId;

  // add aiagentid
  ailinksettingsProcessedData.aiagentid = aiAgentId;

  // add aiconversationid
  ailinksettingsProcessedData.aiconversationid = aiConversationId;

  // remove aisettingid
  delete ailinksettingsProcessedData.aisettingid;
  // ailinksettingsProcessedData.aisettingid = settingId;

  // add ailinksettingid
  ailinksettingsProcessedData.ailinksettingid = aiLinkSettingId;

  // add createdon
  ailinksettingsProcessedData.createdon = date;

  // add updatedon
  ailinksettingsProcessedData.updatedon = date;

  // add archive
  ailinksettingsProcessedData.archive = false;

  // add inuse
  ailinksettingsProcessedData.inuse = false;


  // add automationlink
  ailinksettingsProcessedData.automationlink = ''
  ailinksettingsProcessedData.webchatlink = ''

  if (isAutomationLinkDoc) {
    ailinksettingsProcessedData.automationlink = aiagentPresetDataAutomationLink
    ailinksettingsProcessedData.webchatlink = aiAgentId
  }

  // if automationlink already exists then replace with empty string
  // if (unassignAutomationLink === true) {
  //   // remove automationlink
  //   ailinksettingsProcessedData.automationlink = "";
  // }

  return ailinksettingsProcessedData;
}

// process and save preset agent for location
const processSaveAdminAiPresetAgentToCustomer = async (uData, presetagent, replaceAgent) => {
  let res = {};

  // with code from saveAiSettingsNewFromPreset
  let returnData = {};
  try {

    let processedData = {
      aiagentremove: {},
      aiagentid: "",
      aiagent: [],
      aiconversations: {},
      aipromptversions: {},
      ailinksettings: {},
      //   aiagentid: "",
      //   aiconversations: [
      //     {
      //       aiconversationid: "",
      //       aipromptversionid: "",
      //       ailinksettingid: "",

      // ],
    };

    // add locationid
    let locationId = "";
    if (uData.locationId && uData.locationId !== "") {
      locationId = uData.locationId;
    }

    // add userId
    let userId = "";
    if (uData.userId && uData.userId !== "") {
      userId = uData.userId;
    }

    let presetRefId = "";
    if (presetagent && presetagent.presetref) {
      presetRefId = presetagent.presetref;
    }

    let aiPresetAgentId = "";
    if (presetagent && presetagent.aipresetagentid) {
      aiPresetAgentId = presetagent.aipresetagentid;
    }

    // for testing
    let deleteme = -1;
    if (presetagent && presetagent.deleteme) {
      deleteme = presetagent.deleteme;
    }

    if (locationId !== "" && userId !== "" && presetRefId !== "" && aiPresetAgentId !== "") {

      let aiAgentsDocs = {};
      const getCustomerAiAgentsDocsResult = await getCustomerAiAgentsDocs(locationId);
      if (getCustomerAiAgentsDocsResult?.data) {
        aiAgentsDocs = getCustomerAiAgentsDocsResult.data;
        // if (clog()) console.log('aiAgentsDocs', aiAgentsDocs);
      }

      // get aipresetagent doc using aiPresetAgentId
      const aipresetagentRef = doc(db, "aipresetagents", aiPresetAgentId);
      const aipresetagentDoc = await getDoc(aipresetagentRef);
      const aipresetagentDocData = aipresetagentDoc.data();

      // get aiagent from aipresetagentDocData
      let aiagentPresetData = null;
      if (aipresetagentDocData && aipresetagentDocData.aiagent) {
        aiagentPresetData = aipresetagentDocData.aiagent;
      }

      // get original ailinksettingid so we know what linksetting doc to attach to
      const aiLinkSettingIdOriginal = aiagentPresetData.aisettings[0].ailinksettingid

      // get aiconversations from aipresetagentDocData
      let aiconversationsPresetData = null;
      if (aipresetagentDocData && aipresetagentDocData.aiconversations) {
        aiconversationsPresetData = aipresetagentDocData.aiconversations;
      }

      if (aiagentPresetData && aiconversationsPresetData) {
        // const name = aiagentPresetData.name;
        // const automationLink = aiagentPresetData.automationlink;
        /* //////////////// START: delete aiagent and related documents //////////////// */
        let replaceAgentId = "";
        if (replaceAgent.length > 0) {
          // replaceAgentId = replaceAgent[0].aiagentid;
          replaceAgentId = replaceAgent[0];
        }

        // if replace agent has an aiagentid then remove all aiagent data
        if (replaceAgentId) {
          const deleteAgentRes = await deleteAiAgentAndRelatedData(locationId, replaceAgentId);
          if (deleteAgentRes?.data) {
            processedData.aiagentremove = deleteAgentRes.data;
          }
        }
        /* ///////////////// END: delete aiagent and related documents ///////////////// */


        // check if automationlink is already assigned to an aiagent
        let aiagentPresetDataAutomationLink = '';
        if (aiagentPresetData && aiagentPresetData.automationlink) {
          aiagentPresetDataAutomationLink = aiagentPresetData.automationlink;
        }
        let unassignAutomationLink = false;
        if (aiagentPresetDataAutomationLink !== '') {
          // loop through aiAgentsDocs and check if any have automationlink
          for (const aiagentDoc of aiAgentsDocs) {
            if (aiagentDoc.aisettings && aiagentDoc.aisettings.length > 0) {
              if (
                aiagentDoc.aisettings[0].automationlink !== '' &&
                aiagentDoc.aisettings[0].automationlink === aiagentPresetDataAutomationLink
              ) {
                unassignAutomationLink = true;
                break;
              }
            }
          }
        }

        // generate webtoken
        const webtokenRes = {
          status: 200,
          data: "xxxxxxxxxxxxx",
        }
        // const webtokenRes = await getAiWebCodeToken(uData, aiagentPresetData.automationlink);

        // if webtoken is generated
        if (webtokenRes && webtokenRes.status === 200 && webtokenRes.data) {
          const webtoken = webtokenRes.data;

          // Get a new write batch
          const batch = writeBatch(db);

          // save aiagents doc
          // Get the collection reference
          const agentCollectionRef = collection(db, "aiagents");

          // generate aiagentid
          // askstu - use replaceAgentId?
          const aRef = doc(agentCollectionRef);
          // Get the new document Id
          const aiAgentId = aRef.id;


          const date = generateFirestoreDate();

          // clean aiagentPresetData for location
          let aiagentProcessedData = processAiAgentPresetDataForLocation(
            locationId,
            userId,
            aiAgentId,
            aiagentPresetData,
            date,
            webtoken,
            unassignAutomationLink,
          );

          // save settingsid to use in aiconversations
          const settingId = aiagentProcessedData.aisettings[0].aisettingid;

          // add to processedData
          processedData.aiagentid = aiAgentId;

          // update this once ailinksettingid in aiagentProcessedData.aisettings[0] is updated
          // batch.set(aRef, aiagentProcessedData);

          //
          let aiLinkSettingIdAutomationLink = "";

          // START: loop over aiconversationsPresetData
          for (let i = 0; i < aiconversationsPresetData.length; i++) {

            let processedDataConversation = {
              aiconversationid: "",
              aipromptversionid: "",
              ailinksettingid: "",
            };

            const test = aiLinkSettingIdOriginal
            const aiconversationsPresetDataOriginal = aiconversationsPresetData[i]
            let aiconversationsPresetDataOriginalLinkSettingId = ''
            if (aiconversationsPresetDataOriginal.ailinksettingid && aiconversationsPresetDataOriginal.ailinksettingid !== '') {
              aiconversationsPresetDataOriginalLinkSettingId = aiconversationsPresetDataOriginal.ailinksettingid
            }
            let isAutomationLinkDoc = false
            if (aiLinkSettingIdOriginal !== '' && aiLinkSettingIdOriginal === aiconversationsPresetDataOriginalLinkSettingId) {
              isAutomationLinkDoc = true
            }
            // Get the aiconversations collection reference
            const conversationCollectionRef = collection(db, "aiconversations");
            // generate aiconversationid
            const cRef = doc(conversationCollectionRef);
            // Get the new document Id
            const aiConversationId = cRef.id;

            // add to processedDataConversation
            processedDataConversation.aiconversationid = aiConversationId;


            // Get the ailinksettings collection reference
            const linksettingCollectionRef = collection(db, "ailinksettings");
            // generate ailinksettingid
            const lRef = doc(linksettingCollectionRef);
            // Get the new document Id
            const aiLinkSettingId = lRef.id;

            // add to processedDataConversation
            processedDataConversation.ailinksettingid = aiLinkSettingId;


            // add to processedDataConversation
            // processedDataConversation.aipromptversionid = aiPromptVersionId;


            //// aiconversation data
            const aiconversationPresetData = aiconversationsPresetData[i];
            // clean aiconversationPresetData for location
            const aiconversationProcessedData = processAiConversationPresetDataForLocation(
              locationId,
              userId,
              aiAgentId,
              settingId,
              aiConversationId,
              aiLinkSettingId,
              aiconversationPresetData,
              date,
            );

            let aiConversationName = ''
            if (aiconversationProcessedData.name && aiconversationProcessedData.name !== '') {
              aiConversationName = aiconversationProcessedData.name
            }

            // automationlink not in conversation anymore
            // if (aiconversationProcessedData.automationlink !== "") {
            //   // update aiLinkSettingIdAutomationLink to use in aiagentProcessedData.aisettings[0]
            //   aiLinkSettingIdAutomationLink = aiLinkSettingId;
            //   // if automationlink already exists then replace with empty string
            //   if (unassignAutomationLink === true) {
            //     // remove automationlink
            //     aiconversationProcessedData.automationlink = "";
            //     aiconversationProcessedData.aisettings.data.automationlink = "";
            //   }
            // }

            // if automationlink is not empty then add ailinksettingid to aiagentProcessedData.aisettings[0]
            // if (aiconversationProcessedData?.automationlink !== "") {
            //   // update ailinksettingid in aiagentProcessedData.aisettings[0]
            //   aiagentProcessedData.aisettings[0].ailinksettingid = aiLinkSettingId;
            // }

            // if automationlink is not empty then add ailinksettingid to aiagentProcessedData.aisettings[0]
            if (isAutomationLinkDoc) {
              // update ailinksettingid in aiagentProcessedData.aisettings[0]
              aiagentProcessedData.aisettings[0].ailinksettingid = aiLinkSettingId;
            }

            // set aisettings data to use later
            const aiconversationProcessedDataSettings = aiconversationProcessedData.aisettings;

            // deleteme
            if (deleteme > -1) aiconversationProcessedData.deleteme = deleteme;

            // save aiconversations doc
            const csRef = doc(db, "aiconversations", aiConversationId);
            batch.set(csRef, aiconversationProcessedData);


            //// ailinksettings data
            // process ailinksettings for location
            const ailinksettingsProcessedData = processAiLinkSettingsPresetDataForLocation(
              locationId,
              userId,
              aiAgentId,
              // settingId,
              aiConversationId,
              aiLinkSettingId,
              aiconversationProcessedDataSettings,
              date,
              aiConversationName,
              // unassignAutomationLink,
              aiagentPresetDataAutomationLink,
              isAutomationLinkDoc,
            );

            // deleteme
            if (deleteme > -1) ailinksettingsProcessedData.deleteme = deleteme;

            if (ailinksettingsProcessedData.automationlink !== "") {
              // update aiLinkSettingIdAutomationLink to use in aiagentProcessedData.aisettings[0]
              aiLinkSettingIdAutomationLink = aiLinkSettingId;
              // if automationlink already exists then replace with empty string
              if (unassignAutomationLink === true) {
                // remove automationlink
                ailinksettingsProcessedData.automationlink = "";
              }
            }

            // save ailinksettings doc
            const lsRef = doc(db, "ailinksettings", aiLinkSettingId);
            batch.set(lsRef, ailinksettingsProcessedData);

            // add to processedData
            if (!processedData.ailinksettings[ailinksettingsProcessedData.aiconversationid]) {
              processedData.ailinksettings[ailinksettingsProcessedData.aiconversationid] = []
            }
            processedData.ailinksettings[ailinksettingsProcessedData.aiconversationid].push(ailinksettingsProcessedData);



            // loop over aisettings
            for (const aiconversationProcessedDataSetting of aiconversationProcessedDataSettings) {

              // Get the aipromptversions collection reference
              const promptversionCollectionRef = collection(db, "aipromptversions");
              // generate aipromptversionid
              const pRef = doc(promptversionCollectionRef);
              // Get the new document Id
              const aiPromptVersionId = pRef.id;

              // aiconversationProcessedDataSetting
              let aiPromptVersionProcessedDataSettings = _.cloneDeep(aiconversationProcessedDataSetting)

              // remove name
              delete aiPromptVersionProcessedDataSettings.name

              // remove aisettingsindexid
              delete aiPromptVersionProcessedDataSettings.aisettingsindexid

              //// aipromptversion data
              let processedPromptVersionDocData = {
                aiconversationid: aiConversationId,
                aipromptversionid: aiPromptVersionId,
                aisettings: aiPromptVersionProcessedDataSettings,
                aisettingsindexid: aiconversationProcessedDataSetting.aisettingsindexid,
                archive: false,
                createdon: date,
                locationid: locationId,
                name: "1",
                updatedon: date,
              };

              // deleteme
              if (deleteme > -1) processedPromptVersionDocData.deleteme = deleteme;

              // save aipromptversions doc
              const psRef = doc(db, "aipromptversions", aiPromptVersionId);
              batch.set(psRef, processedPromptVersionDocData);

              // add to processedData
              if (!processedData.aipromptversions[processedPromptVersionDocData.aiconversationid]) {
                processedData.aipromptversions[processedPromptVersionDocData.aiconversationid] = []
              }
              processedData.aipromptversions[processedPromptVersionDocData.aiconversationid].push(processedPromptVersionDocData);

            }




            // add to processedData
            if (!processedData.aiconversations[processedDataConversation.aiconversationid]) {
              processedData.aiconversations[processedDataConversation.aiconversationid] = []
            }
            processedData.aiconversations[processedDataConversation.aiconversationid].push(processedDataConversation);

          }
          // END: loop over aiconversationsPresetData

          // deleteme
          if (deleteme > -1) aiagentProcessedData.deleteme = deleteme;


          // add aiLinkSettingId to aiagentProcessedData.aisettings[0]
          aiagentProcessedData.aisettings[0].ailinksettingid = aiLinkSettingIdAutomationLink;

          // update this once linksettings in aiagentProcessedData.aisettings[0] is updated
          batch.set(aRef, aiagentProcessedData);
          processedData.aiagent.push(aiagentProcessedData)

          // commit the batch
          res = await batch.commit();

          if (clog()) console.log("saveAdminAiPresetAgentToCustomer:", processedData);
          returnData.data = processedData

        } else {
          returnData.error = webtokenRes.error;
        }

      }
      else {
        returnData.error = {
          val: "aiagentPresetData or aiconversationsPresetData is missing",
        };
      }



    }
    else {
      returnData.error = {
        val: "Location id or presetref is missing",
      };
    }

    return returnData;
  } catch (err) {
    console.error(err);
    returnData.error = err;
    return returnData;
  }
}

export {
  adminLogin,
  getCustomerAiAgentsDocs,
  processGetCustomerAiPresetAgentRefDocs,
  processSaveAdminAiPresetAgentToCustomer,
  processAiAgentPresetDataForLocation,
  processAiConversationPresetDataForLocation,
  processAiLinkSettingsPresetDataForLocation,
  deleteAiAgentAndRelatedData,
  deleteDocsByConversationId,
  deleteDocsByDeleteMeId,
}

// module.exports = {
//   processGetCustomerAiPresetAgentRefDocs,
//   processSaveAdminAiPresetAgentToCustomer,
//   processAiAgentPresetDataForLocation,
//   processAiConversationPresetDataForLocation,
//   processAiLinkSettingsPresetDataForLocation,
//   deleteAiAgentAndRelatedData,
//   deleteDocsByConversationId,
// }