import React, { useState, useEffect, useContext } from 'react'
// import PropTypes from 'prop-types';
import {
  getAuth,
} from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { firebase } from '../code/firebase';
import { getUrlParams } from '../code/ecomacy'

// import { useTheme } from '@mui/material/styles';

// import Container from '@mui/material/Container';
import GridKeywordsPane from './GridKeywordsPane'
import LoadingPane from './LoadingPane'

// import MainCard from './MainCard';
// import './GridKeywordsPane.css'
import { CustomerAuthContext } from '../helpers/CustomerAuthProvider';



const GridKeywordsContainer = (routerData) => {

  // const theme = useTheme();
  const auth = getAuth(firebase)

  const [user, loadingUser, errorUser] = useAuthState(auth);

  const {customerUserData, setCustomerUserData} = useContext(CustomerAuthContext);
  const { getCustomerUserData } = useContext(CustomerAuthContext);

  // BT: this should come from url
  // const tempUserId = "MbD0GzwDZONMouvApsbE3lgQEqy2"
  // const tempLocationId = "NG8AEc8nheLEMNYfo3dn"
  /*
  let userId = ''
  if (routerData.props && routerData.props.userId) {
    userId = routerData.props.userId
  }

  let locationId = ''
  if (routerData.props && routerData.props.locationId) {
    locationId = routerData.props.locationId
  }

  let lat = ''
  if (routerData.props && routerData.props.lat) {
    lat = routerData.props.lat
  }

  let lng = ''
  if (routerData.props && routerData.props.lng) {
    lng = routerData.props.lng
  }

  let website = ''
  if (routerData.props && routerData.props.website) {
    website = routerData.props.website
  }

  let postcode = ''
  if (routerData.props && routerData.props.postcode) {
    postcode = routerData.props.postcode
  }
  */
  let loaded = false
  let userDataObj = {
    userId: '',
    locationId: '',
    email: '',
    // lat: null,
    // lng: null,
    // website: '',
    // postcode: '',
  }
  const [userData, setUserData] = useState({
    userId: '',
    locationId: '',
    email: '',
    // lat: null,
    // lng: null,
    // website: '',
    // postcode: '',
  });

  const [userLoaded, setUserLoaded] = useState(false);


  useEffect(() => {
    if (
      userDataObj.userId !== ''
      && userDataObj.locationId !== ''
      // && userDataObj.email !== ''
      && userLoaded === false
    ) {
      setUserData(userDataObj)
      setUserLoaded(true)
    }
    else if (loaded === true && userLoaded === false) {
      setUserLoaded(true)
    }

    //setUser({ pending: true, value: undefined })
    // fetchUser().then((fetchedUser) => {
    //  setUser({ pending: true, value: fetchedUser })
    //})
  }, [userDataObj])




  useEffect(() => {
    const requestCustomerDoc = async () => {
      const res = await getCustomerUserData()
      //      let regid = '';
      //      if (rid) {
      //        regid = rid;
      //      }
      //      if (clog()) console.log({regid});

    };

    if (userLoaded) {
      requestCustomerDoc();
    }

    return () => {
      if (clog()) console.log('component unmount');
      // this now gets called when the component unmounts
    };
  }, [userLoaded]); // eslint-disable-line react-hooks/exhaustive-deps




  if (errorUser) {
    return (
      <LoadingPane
        text={'Error User: ' + JSON.stringify(errorUser)}
        data={errorUser}
        showSpinner={false}
      />
    )
  }

  if (loadingUser) {
    return (
      <LoadingPane
        text={'Loading User...'}
        showSpinner={true}
        progress={25}
      />
    )
  }

  if (user) {

    // get locationId from url
    const urlDataParams = getUrlParams()
    // userDataObj = {
    //   userId: userId,
    //   locationId: locationId,
    //   lat: lat,
    //   lng: lng,
    //   website: website,
    //   postcode: postcode,
    // }

    // trigger state change
    userDataObj = {
      userId: user.uid,
      email: urlDataParams.email,
      locationId: urlDataParams.locationId,
    }

    loaded = true




  if (customerUserData.loaded === false) {
    return (
      <LoadingPane
        text={'Loading User Data...'}
        showSpinner={true}
        progress={50}
      />
    )
  }

  if (customerUserData.loaded === true && customerUserData.error) {
    if (clog()) console.log('Error User doc: ' + JSON.stringify(customerUserData.error))
    return (
      <LoadingPane
        // text={'Error User doc: ' + JSON.stringify(customerUserData.error)}
        text={'Error loading User Data'}
        data={customerUserData.error}
        showSpinner={false}
      />
    )
  }


    if (userData.userId !== '' && userData.locationId !== '' && userLoaded === true) {
      return (
        <GridKeywordsPane
          userData={userData}
        />
      )
    }
    else if (userData.userId === '' && userData.locationId === '' && userLoaded === true) {

      if (!userData.userId || userData.userId === '') {
        console.warn('Missing User ID')
      }

      if (!userData.locationId || userData.locationId === '') {
        console.warn('Missing Location ID')
      }

      return (
        <LoadingPane
          text={'Missing user data, please contact support.'}
          showSpinner={false}
        />
      )
    }

  }
  else {
    // not able to log in contact support
    return (
      <LoadingPane
        text={'Not able to log in, please contact support'}
        showSpinner={false}
      />
    )

  }




};


export default React.memo(GridKeywordsContainer)