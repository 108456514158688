import PropTypes from "prop-types";
import React, { useContext } from "react";
import { Link } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  AppBar,
  Toolbar,
  useMediaQuery,
  Box,
  // Menu,
  // MenuItem,
  // Typography,
  Button,
} from "@mui/material";

// project import
import AppBarStyled from "./AppBarStyled";
// import HeaderContent from './HeaderContent';
import IconButton from "../../components/@extended/IconButton";

// assets
import { SettingOutlined } from "@ant-design/icons";

import {setd, debug, setc, clog} from "../../helpers/HelperFunctions";
import { AdminAuthContext } from "../../helpers/AdminAuthProvider";

import AdminSettingsDialog from "../../components/AdminSettingsDialog";

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const HeaderAdmin = ({ open, handleDrawerToggle }) => {
  // const [anchorElNav, setAnchorElNav] = React.useState(null);


  const { logout } = useContext(AdminAuthContext);

  const { adminUserData, setAdminUserData } = useContext(AdminAuthContext);
  const { showSettingsDialog, setShowSettingsDialog } = useContext(AdminAuthContext);

  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down("lg"));

  // header content
  // const headerContent = useMemo(() => <HeaderContent />, []);

  const iconBackColorOpen = theme.palette.mode === "dark" ? "grey.200" : "grey.300";
  const iconBackColor = theme.palette.mode === "dark" ? "background.default" : "grey.100";

  const handleShowSettingsDialog = () => {
    if (clog()) console.log("show dialog:", adminUserData);
    setShowSettingsDialog(true);
  };

  const handleLogout = async () => {
    await logout();
    // reload the page
    window.location.reload(true);
  };

  // common header
  const mainHeader = (
    <Toolbar
      id="HeaderToolbar"
      sx={{
        paddingTop: "0px",
        paddingBottom: "0px",
        minHeight: "50px",
        // width: 1
      }}
    >
      {adminUserData && adminUserData.isAdmin && adminUserData.isAdmin === true ? <AdminSettingsDialog /> : <></>}
      <Button key={"settings"} onClick={handleShowSettingsDialog} sx={{ my: 1, color: "black", display: "block", mr: 2 }}>
        Settings
      </Button>

      <Button key={"logout"} onClick={handleLogout} sx={{ my: 1, color: "black", display: "block", mr: 2 }}>
        Logout
      </Button>

      {
        // <Link to="/settings" style={{ textDecoration: "none" }}>
        //   <IconButton
        //     aria-label="open drawer"
        //     onClick={handleDrawerToggle}
        //     edge="start"
        //     color="secondary"
        //     variant="light"
        //     sx={{ color: "text.primary", bgcolor: open ? iconBackColorOpen : iconBackColor, ml: { xs: 0, lg: -2 } }}
        //   >
        //     <SettingOutlined />
        //   </IconButton>
        // </Link>
      }

      {
        // headerContent
      }
    </Toolbar>
  );

  // app-bar params
  const appBar = {
    position: "fixed",
    color: "inherit",
    elevation: 0,
    sx: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      paddingLeft: "20px",
      // boxShadow: theme.customShadows.z1
    },
  };

  return (
    <>
      {!matchDownMD ? (
        <AppBarStyled open={open} {...appBar}>
          <Box id="AppBarStyledBox" sx={{ flex: 1, display: { xs: "none", md: "flex" } }}>
            <Box sx={{ flexGrow: 1 }}>
              <></>
            </Box>

            {mainHeader}
          </Box>
        </AppBarStyled>
      ) : (
        <AppBar {...appBar}>{mainHeader}</AppBar>
      )}
    </>
  );
};

HeaderAdmin.propTypes = {
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
};

export default HeaderAdmin;
