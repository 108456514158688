import React, { useState, useContext, useEffect } from "react";
// import semverSatisfies from 'semver/functions/satisfies';
//import { firebase } from './firebase';
import { getAuth } from "firebase/auth";
import { setUserProperties } from "firebase/analytics";

import {
  activate,
  isSupported,
  // getRemoteConfig,
  fetchAndActivate,
  fetchConfig,
  getValue,
  getAll,
} from "firebase/remote-config";
import { useAuthState } from "react-firebase-hooks/auth";

import { firebase, analytics, remoteConfig } from "../code/firebase";

const auth = getAuth(firebase);

const initialState = {
  // login: () => {},
  // logout: () => {},
  // authenticate: () => {},
  // getUserToken: () => {},
};
export const RemoteConfigAuthContext = React.createContext(initialState);

// remote config fetch is 15 seconds instead of 12 hours
let testingRemoteConfig = false;

let defaults = {
  param: "",
  admin_aipresetagent_tab: "false",
  strategy_openai_chatwithknowledge: "false",
  strategy_openai_questionchat: "false",
  description_panel: "false",
  model_claude_2: "false",
  model_openai_gpt_3_5_turbo: "false",
  model_openai_gpt_3_5_turbo_0301: "false",
  model_openai_gpt_3_5_turbo_16k: "false",
  model_openai_gpt_3_5_turbo_0613: "false",
  model_openai_gpt_3_5_turbo_16k_0613: "false",
  model_openai_gpt_4: "false",
  model_openai_gpt_4_0613: "false",
  model_openai_gpt_4_32k: "false",
  model_openai_gpt_4_32k_0613: "false",
  model_openai_gpt_4_1106_preview: "false",
  questions_improve_prompt: "false",
  aiagent_delete: "false",
  aisetting_delete: "false",
  ai_api_keys: "false",
  history_show_conversation_id: "false",
  history_show_settings_selector: "false",
  chat_promptversion_controls: "false",
};

// model_claude_2
// model_openai_gpt_3_5_turbo
// model_openai_gpt_3_5_turbo_0301
// model_openai_gpt_4
// model_openai_gpt_4_0613
// model_openai_gpt_4_32k
// model_openai_gpt_4_32k_0613
// model_openai_gpt_4_1106_preview

const devIds = ["npHTlNidilPwoSkOA6bgq1k9sY72"];

export const RemoteConfigAuthProvider = ({ children }) => {
  const [init, setInit] = useState(true);

  const [user, loadingUser, errorUser] = useAuthState(auth);

  let admin_aipresetagent_tab = false;

  let strategy_openai_chatwithknowledge = false;
  let strategy_openai_questionchat = false;
  let description_panel = false;

  let model_claude_2 = false;
  let model_openai_gpt_3_5_turbo = false;
  let model_openai_gpt_3_5_turbo_0301 = false;
  let model_openai_gpt_3_5_turbo_16k = false;
  let model_openai_gpt_3_5_turbo_0613 = false;
  let model_openai_gpt_3_5_turbo_16k_0613 = false;
  let model_openai_gpt_4 = false;
  let model_openai_gpt_4_0613 = false;
  let model_openai_gpt_4_32k = false;
  let model_openai_gpt_4_32k_0613 = false;
  let model_openai_gpt_4_1106_preview = false;

  let questions_improve_prompt = false;

  let aiagent_delete = false;
  let aisetting_delete = false;

  let ai_api_keys = false;

  let history_show_conversation_id = false;
  let history_show_settings_selector = false;
  let chat_promptversion_controls = false;

  let engineer_releaseversion = 1;
  let engineer_autoengineer = false;

  const [rc, setRc] = useState({
    admin_aipresetagent_tab: admin_aipresetagent_tab,
    strategy_openai_chatwithknowledge: strategy_openai_chatwithknowledge,
    strategy_openai_questionchat: strategy_openai_questionchat,
    description_panel: description_panel,
    model_claude_2: model_claude_2,
    model_openai_gpt_3_5_turbo: model_openai_gpt_3_5_turbo,
    model_openai_gpt_3_5_turbo_0301: model_openai_gpt_3_5_turbo_0301,
    model_openai_gpt_3_5_turbo_16k: model_openai_gpt_3_5_turbo_16k,
    model_openai_gpt_3_5_turbo_0613: model_openai_gpt_3_5_turbo_0613,
    model_openai_gpt_3_5_turbo_16k_0613: model_openai_gpt_3_5_turbo_16k_0613,
    model_openai_gpt_4: model_openai_gpt_4,
    model_openai_gpt_4_0613: model_openai_gpt_4_0613,
    model_openai_gpt_4_32k: model_openai_gpt_4_32k,
    model_openai_gpt_4_32k_0613: model_openai_gpt_4_32k_0613,
    model_openai_gpt_4_1106_preview:  model_openai_gpt_4_1106_preview,

    questions_improve_prompt: questions_improve_prompt,
    aiagent_delete: aiagent_delete,
    aisetting_delete: aisetting_delete,
    ai_api_keys: ai_api_keys,
    history_show_conversation_id: history_show_conversation_id,
    history_show_settings_selector: history_show_settings_selector,
    chat_promptversion_controls: chat_promptversion_controls,
    engineer_releaseversion: engineer_releaseversion,
    engineer_autoengineer: engineer_autoengineer,
  });


  useEffect(() => {
    const setAnalyticsUserProperties = async () => {
      if (user) {
        const isDev = devIds.includes(user.uid);
        setUserProperties(await analytics(), { is_dev_user: isDev });
      }
    };

    setAnalyticsUserProperties()
      // make sure to catch any error
      .catch(console.error);
  }, [user]);

  //  useEffect(() => {
  //    const getRemoteConfig = async () => {
  //      // const remoteConfig = getRemoteConfig();
  //      const remoteConfig = await isSupported() && getRemoteConfig(firebase);
  //      // For development only
  //      remoteConfig.settings = {
  //        minimumFetchIntervalMillis: 30000,
  //      };
  //      // remoteConfig.settings.minimumFetchIntervalMillis = 15000;
  //      const isFetched = await fetchAndActivate(remoteConfig);
  //      //
  //      if (isFetched) {
  //        if (clog()) console.log("Remote config fetched");
  //        let strategyAllowed = getValue(remoteConfig, "strategy_openai_questionchat");
  //        debugger;
  //        setInit(false);
  //      } else {
  //        debugger;
  //        if (clog()) console.log("Remote config not fetched");
  //      }
  //
  //      return isFetched;
  //    };
  //
  //    if (init) {
  //      getRemoteConfig()
  //        // make sure to catch any error
  //        .catch(console.error);
  //    }
  //   }, []);

  //  useEffect(() => {
  //    remoteConfig.defaultConfig = defaults;
  //
  //        // remoteConfig
  //         //  .fetchAndActivate()
  //         fetchAndActivate(remoteConfig)
  //          .then(activated => {
  //            if (!activated) if (clog()) console.log('not activated');
  //            // return remoteConfig.getAll();
  //            return getAll(remoteConfig);
  //          })
  //          .then(remoteFlags => {
  //            let newRemoteConfigs = {
  //              ...remoteConfigs,
  //            };
  //            const test = remoteFlags
  //
  //            for (const [key, config] of Object.entries(remoteFlags)) {
  //              // const appVer = process.env.REACT_APP_VERSION;
  //              // newRemoteConfigs[key] = semverSatisfies(appVer, config.asString());
  //              newRemoteConfigs[key] = config._value;
  //            }
  //
  //            const test1 = newRemoteConfigs
  //            setRemoteConfigs(newRemoteConfigs);
  //          })
  //          .catch(error => console.error(error));
  //  }, []);

  const initRemoteConfig = async () => {
    const remConfig = await remoteConfig();

    if (init) {
      if (!!remConfig) {
        if (testingRemoteConfig) {
          remConfig.settings.minimumFetchIntervalMillis = 15000; // 15 seconds - normal is 12 hours = 43200000
          console.warn("Remote config is in test mode and fetch interval is every 15 seconds");
        }
        remConfig.defaultConfig = defaults;

        // fetchConfig() // values set on the backend are fetched and cached
        // activate(); // make fetched values available to the app

        await fetchAndActivate(remConfig);
        // if (clog()) console.log('initRemoteConfig - fetchAndActivate')
        admin_aipresetagent_tab = getValue(remConfig, "admin_aipresetagent_tab").asBoolean();

        strategy_openai_chatwithknowledge = getValue(remConfig, "strategy_openai_chatwithknowledge").asBoolean();
        strategy_openai_questionchat = getValue(remConfig, "strategy_openai_questionchat").asBoolean();
        description_panel = getValue(remConfig, "description_panel").asBoolean();

        model_claude_2 = getValue(remConfig, "model_claude_2").asBoolean();
        model_openai_gpt_3_5_turbo = getValue(remConfig, "model_openai_gpt_3_5_turbo").asBoolean();
        model_openai_gpt_3_5_turbo_0301 = getValue(remConfig, "model_openai_gpt_3_5_turbo_0301").asBoolean();
        model_openai_gpt_3_5_turbo_16k = getValue(remConfig, "model_openai_gpt_3_5_turbo_16k").asBoolean();
        model_openai_gpt_3_5_turbo_0613 = getValue(remConfig, "model_openai_gpt_3_5_turbo_0613").asBoolean();
        model_openai_gpt_3_5_turbo_16k_0613 = getValue(remConfig, "model_openai_gpt_3_5_turbo_16k_0613").asBoolean();
        model_openai_gpt_4 = getValue(remConfig, "model_openai_gpt_4").asBoolean();
        model_openai_gpt_4_0613 = getValue(remConfig, "model_openai_gpt_4_0613").asBoolean();
        model_openai_gpt_4_32k = getValue(remConfig, "model_openai_gpt_4_32k").asBoolean();
        model_openai_gpt_4_32k_0613 = getValue(remConfig, "model_openai_gpt_4_32k_0613").asBoolean();
        model_openai_gpt_4_1106_preview = getValue(remConfig, "model_openai_gpt_4_1106_preview").asBoolean();

        questions_improve_prompt = getValue(remConfig, "questions_improve_prompt").asBoolean();

        aiagent_delete = getValue(remConfig, "aiagent_delete").asBoolean();
        aisetting_delete = getValue(remConfig, "aisetting_delete").asBoolean();

        ai_api_keys = getValue(remConfig, "ai_api_keys").asBoolean();

        history_show_conversation_id = getValue(remConfig, "history_show_conversation_id").asBoolean();
        history_show_settings_selector = getValue(remConfig, "history_show_settings_selector").asBoolean();
        chat_promptversion_controls = getValue(remConfig, "chat_promptversion_controls").asBoolean();

        engineer_releaseversion = getValue(remConfig, "engineer_releaseversion").asNumber();
        engineer_autoengineer = getValue(remConfig, "engineer_autoengineer").asBoolean();

        setRc({
          admin_aipresetagent_tab: admin_aipresetagent_tab,
          strategy_openai_chatwithknowledge: strategy_openai_chatwithknowledge,
          strategy_openai_questionchat: strategy_openai_questionchat,
          description_panel: description_panel,
          model_claude_2: model_claude_2,
          model_openai_gpt_3_5_turbo: model_openai_gpt_3_5_turbo,
          model_openai_gpt_3_5_turbo_0301: model_openai_gpt_3_5_turbo_0301,
          model_openai_gpt_3_5_turbo_16k: model_openai_gpt_3_5_turbo_16k,
          model_openai_gpt_3_5_turbo_0613: model_openai_gpt_3_5_turbo_0613,
          model_openai_gpt_3_5_turbo_16k_0613: model_openai_gpt_3_5_turbo_16k_0613,
          model_openai_gpt_4: model_openai_gpt_4,
          model_openai_gpt_4_0613: model_openai_gpt_4_0613,
          model_openai_gpt_4_32k: model_openai_gpt_4_32k,
          model_openai_gpt_4_32k_0613: model_openai_gpt_4_32k_0613,
          model_openai_gpt_4_1106_preview: model_openai_gpt_4_1106_preview,
          questions_improve_prompt: questions_improve_prompt,
          aiagent_delete: aiagent_delete,
          aisetting_delete: aisetting_delete,
          ai_api_keys: ai_api_keys,
          history_show_conversation_id: history_show_conversation_id,
          history_show_settings_selector: history_show_settings_selector,
          chat_promptversion_controls: chat_promptversion_controls,
          engineer_releaseversion: engineer_releaseversion,
          engineer_autoengineer: engineer_autoengineer,
        });

        setInit(false);
      }
    }
  };

  initRemoteConfig();
  //  useEffect(() => {
  //    const getRemoteConfig = async () => {
  //      // const remoteConfig = getRemoteConfig();
  //      // remoteConfig.settings.minimumFetchIntervalMillis = 15000;
  //      const isFetched = await fetchAndActivate(remoteConfig);
  //      //
  //      if (isFetched) {
  //        if (clog()) console.log("Remote config fetched");
  //        let strategyAllowed = getValue(remoteConfig, "strategy_openai_questionchat");
  //        setInit(false);
  //      } else {
  //        if (clog()) console.log("Remote config not fetched");
  //      }
  //
  //      return isFetched;
  //    };
  //
  //    if (init) {
  //      getRemoteConfig()
  //        // make sure to catch any error
  //        .catch(console.error);
  //    }
  //  }, []);

  //  useEffect(() => {
  //    remoteConfig.defaultConfig = defaults;
  //
  //        // remoteConfig
  //         //  .fetchAndActivate()
  //         fetchAndActivate(remoteConfig)
  //          .then(activated => {
  //            if (!activated) if (clog()) console.log('not activated');
  //            // return remoteConfig.getAll();
  //            return getAll(remoteConfig);
  //          })
  //          .then(remoteFlags => {
  //            let newRemoteConfigs = {
  //              ...remoteConfigs,
  //            };
  //            const test = remoteFlags
  //
  //            for (const [key, config] of Object.entries(remoteFlags)) {
  //              // const appVer = process.env.REACT_APP_VERSION;
  //              // newRemoteConfigs[key] = semverSatisfies(appVer, config.asString());
  //              newRemoteConfigs[key] = config._value;
  //            }
  //
  //            const test1 = newRemoteConfigs
  //            setRemoteConfigs(newRemoteConfigs);
  //          })
  //          .catch(error => console.error(error));
  //  }, []);

  return (
    <RemoteConfigAuthContext.Provider
      value={{
        rc,
        setRc,
        // strategy_openai_questionchat,
        // description_panel
        // remoteConfigs,
        // initRemoteConfig: async () => {
        //   const rc = await remoteConfig();

        //   if (!!rc) {
        //     rc.defaultConfig = defaults;

        //     // fetchConfig() // values set on the backend are fetched and cached
        //     // activate(); // make fetched values available to the app

        //     await fetchAndActivate(rc);

        //     strategy_openai_questionchat = getValue(rc, "strategy_openai_questionchat").asBoolean();
        //   }
        // },
      }}
    >
      {children}
    </RemoteConfigAuthContext.Provider>
  );
};

// export default RemoteConfigAuthProvider;
