import React, {
  useState,
  useEffect,
  // useCallback
  useContext,
  useRef,
} from "react";
import PropTypes from "prop-types";

import { create, all } from "mathjs";

import {} from "firebase/auth";
// import { useAuthState } from 'react-firebase-hooks/auth';
import {setd, debug, setc, clog} from "../helpers/HelperFunctions";
import { AdminAuthContext } from "../helpers/AdminAuthProvider";

import { formatCurrencyNumber, getCurrencySymbol } from "../code/locale";

import {
  getFirestore,
  collection,
  query,
  where,
  // orderBy
} from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";

import { FixedSizeList } from "react-window";

import { firebase } from "../code/firebase";
import { processKeywordsUserData } from "../code/ecomacy";

import _ from "lodash";

import { useTheme, styled } from "@mui/material/styles";

import {
  Box,
  Button,
  // LoadingButton,
  CircularProgress,
  Typography,
  Grid,
  Stack,
  Divider,
  IconButton,
  InputLabel,
  ListItem,
  ListItemButton,
  ListItemText,
  // ListItemIcon,
  ListItemSecondaryAction,
  OutlinedInput,
} from "@mui/material";

import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/EditOutlined";

import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/ClearOutlined";
import DoneIcon from "@mui/icons-material/DoneOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircleOutlined";
import CircleIcon from "@mui/icons-material/CircleOutlined";
import CancelIcon from "@mui/icons-material/CancelOutlined";

import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { SettingOutlined, FileImageOutlined, UserOutlined, UsergroupAddOutlined } from "@ant-design/icons";

import MainCard from "./MainCard";

// import GoogleMapPane from './GoogleMapPane'
import LoadingPane from "./LoadingPane";
// import { isDisabled } from "@testing-library/user-event/dist/utils";

// create a mathjs instance
const math = create(all);

const AdminAiSuperSetupTab = (props) => {
  const theme = useTheme();


  const { adminUserData, setAdminUserData } = useContext(AdminAuthContext);
  // const { adminAiCategoriesDocs, setAdminAiCategoriesDocs } = useContext(AdminAuthContext);
  const { adminAiCategoriesSuperAdminDocs, setAdminAiCategoriesSuperAdminDocs } = useContext(AdminAuthContext);

  const { getAiPresetsDocs } = useContext(AdminAuthContext);
  const { adminAiCategorySelected, setAdminAiCategorySelected } = useContext(AdminAuthContext);
  const adminCategorySelectedIndex = useRef(-1);
  const { adminAiCategoriesIsEditing, setAdminAiCategoriesIsEditing } = useContext(AdminAuthContext);

  // const { saveAdminAiCategoryNew } = useContext(AdminAuthContext);
  const { saveAdminAiCategorySuperAdminNew } = useContext(AdminAuthContext);

  const { saveAdminAiCategoryName } = useContext(AdminAuthContext);
  const [aiCategoryName, setAiCategoryName] = useState("");
  const [aiCategoryNameSaving, setAiCategoryNameSaving] = useState(false);
  const { deleteAdminAiCategory } = useContext(AdminAuthContext);

  // const [adminAiSetupTabLoading, setAiSetupTabLoading] = useState();
  const { adminAiSetupTabLoading, setAiSetupTabLoading } = useContext(AdminAuthContext);
  const { adminAiPresetsDocs, setAdminAiPresetsDocs } = useContext(AdminAuthContext);
  const { adminCategoryAiPresets, setAdminCategoryAiPresets } = useContext(AdminAuthContext);
  const { adminCategoryAiPresetsUpdated, setAdminCategoryAiPresetsUpdated } = useContext(AdminAuthContext);
  const { adminCategoryAiPresetsIdsUpdated, setAdminCategoryAiPresetsIdsUpdated } = useContext(AdminAuthContext);

  const { saveAdminAiCategoryPresetsData } = useContext(AdminAuthContext);
  const { adminAiPresetsIsEditing, setAdminAiPresetsIsEditing } = useContext(AdminAuthContext);

  const { saveAdminAiPresetName } = useContext(AdminAuthContext);
  const [aiPresetName, setAiPresetName] = useState("");
  const [aiPresetNameSaving, setAiPresetNameSaving] = useState(false);

  const [init, setInit] = useState(true);
  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = React.useState(false);

  const [selectedRow, setSelectedRow] = useState({
    type: "",
    aipresetid: "",
    name: "",
  });

  useEffect(() => {
    // reset states when tab is changed
    setAdminAiCategorySelected(null);
    setAdminCategoryAiPresets(null);
    setAdminCategoryAiPresetsUpdated(null);
    setAdminCategoryAiPresetsIdsUpdated(null);
  }, []);

  useEffect(() => {
    const getData = async () => {
      const preResult = await getAiPresetsDocs();
      // user.uid
    };

    if (adminAiSetupTabLoading) {
      getData()
        // make sure to catch any error
        .catch(console.error);

      setAiSetupTabLoading(false);
    }
  }, [adminAiSetupTabLoading]);

  // triggered when category is selected
  useEffect(() => {
    if (adminAiCategorySelected) {
      // set adminCategoryAiPresetsUpdated
      let categoryAiPresets = [];
      if (adminAiCategorySelected && adminAiCategorySelected.aipresets) {
        categoryAiPresets = adminAiCategorySelected.aipresets;
      }
      setAdminCategoryAiPresets(categoryAiPresets);
      setAdminCategoryAiPresetsUpdated(categoryAiPresets);

      let categoryAiPresetsIds = [];
      if (adminAiCategorySelected && adminAiCategorySelected.aipresetsids) {
        categoryAiPresetsIds = adminAiCategorySelected.aipresetsids;
      }
      setAdminCategoryAiPresetsIdsUpdated(categoryAiPresetsIds);
    } else {
      // trigged when category is deleted
      if (adminCategoryAiPresets !== null || adminCategoryAiPresetsUpdated !== null) {
        setAdminCategoryAiPresets(null);
        setAdminCategoryAiPresetsUpdated(null);
        setAdminCategoryAiPresetsIdsUpdated(null);
      }
    }
  }, [adminAiCategorySelected]);

  const saveButtonSx = {
    // ...(success && {
    //   bgcolor: theme.palette.success.main,
    //   '&:hover': {
    //     // bgcolor: theme.palette.success.dark,
    //     bgcolor: theme.palette.success.main,
    //   },
    // }),
    // ml: 1,
    height: "40px",
    width: "100%",
    fontWeight: 700,
  };

  const isListButtonDisabled = () => {
    let disabled = false;

    if (adminAiPresetsIsEditing.active === true) {
      disabled = true;
    }

    if (adminAiCategoriesIsEditing.active === true) {
      disabled = true;
    }

    return disabled;
  };

  const isCategorySelectDisabled = () => {
    let disabled = false;

    if (adminAiPresetsIsEditing.active === true) {
      disabled = true;
    }

    if (adminAiCategoriesIsEditing.active === true) {
      disabled = true;
    }

    return disabled;
  };

  const isCategoryAddRemoveEditDisabled = (type) => {
    let disabled = false;

    if (adminAiPresetsIsEditing.active === true) {
      disabled = true;
    }

    if (adminAiCategorySelected === null || adminAiCategorySelected === undefined) {
      // only disable edit & remove button
      if (type === "edit" || type === "remove") {
        disabled = true;
      }
    }

    // don't allow editing of category if preset save is pending
    const saveDisabled = isSaveDisabled();
    if (!saveDisabled) {
      disabled = true;
    }

    return disabled;
  };

  const handleOnChangeAiCategory = (event) => {
    if (clog()) console.log("event:", event.target.value);
    // let fullData = customersData.data
    let categories = [];
    if (adminAiCategoriesSuperAdminDocs && adminAiCategoriesSuperAdminDocs.length > 0) {
      categories = adminAiCategoriesSuperAdminDocs;
    }

    // update item
    const val = event.target.value;

    // find category in adminAiCategoriesSuperAdminDocs array by key aicategoryid
    const selectedCategoryFound = adminAiCategoriesSuperAdminDocs.find((c) => c.aicategoryid === val);
    let categorySelected = null;
    if (selectedCategoryFound) {
      categorySelected = selectedCategoryFound;
    }

    setAdminAiCategorySelected(categorySelected);
    resetSelectedRow();
  };

  const handleOnChangeAiCategoryName = (event) => {
    const val = event.target.value;
    if (clog()) console.log("event:", event.target.value);
    setAiCategoryName(val);
  };

  const handleOnClickAiCategoryEdit = () => {
    // const test = adminAiCategorySelected
    //
    if (adminAiCategoriesIsEditing.active === false) {
      let name = "";
      if (adminAiCategorySelected && adminAiCategorySelected.name) {
        name = adminAiCategorySelected.name;
      }
      setAiCategoryName(name);

      // activate edit mode
      setAdminAiCategoriesIsEditing({
        active: true,
        type: "edit",
      });
    } else {
      // deactivate edit mode
      setAdminAiCategoriesIsEditing({
        active: false,
        type: "",
      });
    }
  };

  const handleOnClickAiCategoryAdd = (e) => {
    // const test = adminAiCategorySelected
    //
    if (adminAiCategoriesIsEditing.active === false) {
      let name = "";

      setAiCategoryName(name);

      // activate edit mode
      setAdminAiCategoriesIsEditing({
        active: true,
        type: "add",
      });
    } else {
      // deactivate edit mode
      setAdminAiCategoriesIsEditing({
        active: false,
        type: "",
      });
    }
  };

  const handleOnClickAiCategoryRemove = (e) => {
    // const test = adminAiCategorySelected
    //
    if (adminAiCategoriesIsEditing.active === false) {
      // activate edit mode
      setAdminAiCategoriesIsEditing({
        active: true,
        type: "remove",
      });
    } else {
      // deactivate edit mode
      setAdminAiCategoriesIsEditing({
        active: false,
        type: "",
      });
    }
  };

  const handleOnClickRemoveConfirm = async () => {
    const result = await deleteAdminAiCategory(adminAiCategorySelected.aicategoryid);
    if (result && result.data && result.data.aicategoryid && result.data.aicategoryid !== "") {
      // unselect category in menu
      setAdminAiCategorySelected(null);
      // BT any other cleanup?
    }

    // deactivate edit mode
    setAdminAiCategoriesIsEditing({
      active: false,
      type: "",
    });
  };

  const handleOnClickAiCategoryNewSave = async (event) => {
    if (clog()) console.log("handleOnClickAiCategoryNewSave");
    //
    // setAiSettingsName(name)
    setAiCategoryNameSaving(true);

    const result = await saveAdminAiCategorySuperAdminNew(aiCategoryName);
    //
    if (result && result.data && result.data.aicategoryid && result.data.aicategoryid !== "") {
      // set adminAiCategor
      setAdminAiCategorySelected(result.data);

      // update adminAiCategorySelected
      // setAdminAiCategorySelected({
      //   ...adminAiCategorySelected,
      //   name: result.data.name,
      // });
    }

    setAiCategoryNameSaving(false);

    // deactivate edit mode
    setAdminAiCategoriesIsEditing({
      active: false,
      type: "",
    });
  };

  const handleOnClickAiCategoryNameSave = async (event) => {
    if (clog()) console.log("handleOnClickAiCategoryNameSave");
    //
    // setAiSettingsName(name)
    setAiCategoryNameSaving(true);

    let aiCategoryId = "";
    if (adminAiCategorySelected && adminAiCategorySelected.aicategoryid && adminAiCategorySelected.aicategoryid !== "") {
      aiCategoryId = adminAiCategorySelected.aicategoryid;
    }

    if (aiCategoryId !== "") {
      const result = await saveAdminAiCategoryName(aiCategoryId, aiCategoryName);
      //
      if (result && result.data && result.data.aicategoryid && result.data.aicategoryid !== "") {
        // update adminAiCategorySelected
        setAdminAiCategorySelected({
          ...adminAiCategorySelected,
          name: result.data.name,
        });
      }
    } else {
      console.warn("no category id found");
    }

    setAiCategoryNameSaving(false);

    // deactivate edit mode
    setAdminAiCategoriesIsEditing({
      active: false,
      type: "",
    });
  };

  const isAiCategoryNameSaveDisabled = () => {
    let disabled = false;

    let aiCategorySelectedName = "";
    if (adminAiCategorySelected && adminAiCategorySelected.name && adminAiCategorySelected.name !== "") {
      aiCategorySelectedName = adminAiCategorySelected.name;
    }

    if (aiCategoryName === "") {
      disabled = true;
    }

    if (aiCategoryName === aiCategorySelectedName) {
      disabled = true;
    }

    return disabled;
  };

  const handleOnClickSave = async () => {
    const result = await saveAdminAiCategoryPresetsData(adminAiCategorySelected, adminCategoryAiPresetsUpdated, adminCategoryAiPresetsIdsUpdated);
    if (result && result.data) {
      // cleanup stuff
      // update adminCategoryAiPresets
      setAdminCategoryAiPresets(result.data);
    }
  };

  const isSaveDisabled = () => {
    // compare adminCategoryAiPresetsUpdated with adminCategoryAiPresets
    let isDisabled = true;

    if (adminCategoryAiPresetsUpdated === null || adminCategoryAiPresets === null) {
      isDisabled = true;
    } else if (adminCategoryAiPresetsUpdated.length !== adminCategoryAiPresets.length) {
      isDisabled = false;
    } else if (adminCategoryAiPresetsUpdated && adminCategoryAiPresetsUpdated.length > 0) {
      if (adminCategoryAiPresets && adminCategoryAiPresets.length > 0) {
        if (adminCategoryAiPresetsUpdated.length !== adminCategoryAiPresets.length) {
          isDisabled = false;
        } else {
          // compare each item
          for (let i = 0; i < adminCategoryAiPresetsUpdated.length; i++) {
            const item = adminCategoryAiPresetsUpdated[i];
            const itemCurrent = adminCategoryAiPresets[i];
            if (item.aipresetid !== itemCurrent.aipresetid) {
              isDisabled = false;
              break;
            }
          }
        }
      } else {
        isDisabled = false;
      }
    }

    if (adminAiPresetsIsEditing.active === true) {
      isDisabled = true;
    }

    return isDisabled;
  };

  const resetSelectedRow = () => {
    const data = {
      type: "",
      name: "",
      aipresetid: "",
    };

    setSelectedRow(data);
  };

  const isSelectedRow = (type, item) => {
    let isSelected = false;
    if ("categorypreset" === type && selectedRow.type === type) {
      let aipresetid = "";
      if (item.item) {
        const itemObj = item.item;
        aipresetid = itemObj.aipresetid;
      } else {
        aipresetid = item.aipresetid;
      }

      if (aipresetid === selectedRow.aipresetid) {
        isSelected = true;
      }
    } else if ("preset" === type && selectedRow.type === type) {
      let aipresetid = "";
      if (item.item) {
        const itemObj = item.item;
        aipresetid = itemObj.aipresetid;
      } else {
        aipresetid = item.aipresetid;
      }

      if (aipresetid === selectedRow.aipresetid) {
        isSelected = true;
      }
    }

    return isSelected;
  };

  const isDisabledRow = (type, item) => {
    let isDisabled = false;

    if ("preset" === type) {
      const test = adminCategoryAiPresetsUpdated;
      const test1 = item;

      // use lodash to find item in adminCategoryAiPresetsUpdated
      const foundItem = _.find(adminCategoryAiPresetsUpdated, { aipresetid: item.aipresetid });
      if (foundItem) {
        isDisabled = true;
      }
    }

    // if (!adminAiCategorySelected) {
    //   isDisabled = true;
    // }
    return isDisabled;
  };

  const handleCategoryAiPresetsRowOnClick = (e, index, item) => {
    let click = 0;
    switch (e.detail) {
      case 1:
        if (clog()) console.log("click");
        click = 1;
        break;
      case 2:
        if (clog()) console.log("double click");
        click = 2;
        break;
    }

    let data = {
      type: "",
      aipresetid: "",
      name: "",
    };

    if (item) {
      data.type = "categorypreset";
      data.aipresetid = item.aipresetid;
      data.name = item.name;
    }

    if (!_.isEqual(data, selectedRow)) {
      setSelectedRow(data);
      if (clog()) console.log("Category Preset Clicked:", data.id);
    } else {
      setSelectedRow({
        type: "",
        aipresetid: "",
        name: "",
      });
    }
  };

  const handleOnClickCategoryAiPresetRemove = (e, item) => {
    e.stopPropagation();
    e.preventDefault();

    if (clog()) console.log("handleOnClickCategoryAiPresetRemove:", item);

    // remove item from adminCategoryAiPresetsUpdated
    const newPresetArr = adminCategoryAiPresetsUpdated.filter((preset) => preset.aipresetid !== item.aipresetid);

    setAdminCategoryAiPresetsUpdated(newPresetArr);

    let newAiPresetsIds = [...adminCategoryAiPresetsIdsUpdated];

    // find aipresetid in newAiPresetsIds
    const index = newAiPresetsIds.indexOf(item.aipresetid);
    if (index > -1) {
      // remove aipresetid from newAiPresetsIds
      newAiPresetsIds.splice(index, 1);
    }

    setAdminCategoryAiPresetsIdsUpdated(newAiPresetsIds);

    // reset selected row
    resetSelectedRow();
    // const item = adminAiPresetsDocs[index];
  };

  const renderSelectedPresetsRows = (props) => {
    const { index, style } = props;
    // const item = googleCategoriesRows[index]
    let name = "";
    const item = adminCategoryAiPresetsUpdated[index];
    if (item) {
      name = item.name;
      // const searchItemObj = searchResultsArr[index]
      // const item = searchItemObj.item
      // const id = item.id
      // const t = item.t
      return (
        <ListItem style={style} key={index} component="div" disablePadding divider>
          <ListItemButton
            sx={{ pt: 0, pb: 0, height: "100%" }}
            onClick={(event) => handleCategoryAiPresetsRowOnClick(event, index, item)}
            // selected={index === 1 ? true : false}
            selected={isSelectedRow("categorypreset", item)}
            disabled={isListButtonDisabled()}
          >
            <ListItemText
              primary={name}
              sx={{
                color: item.active === 0 ? theme.palette.text.disabled : theme.palette.text.primary,
                ".Mui-selected &": {
                  color: theme.palette.primary.main,
                },
                textTransform: "capitalize !important",
              }}
            />

            {
              // isSelectedRow("categorypreset", item) && (
            }
            <ListItemSecondaryAction>
              <IconButton
                aria-label="Remove"
                color="primary"
                // size="small"
                // variant="light"
                onClick={(event) => handleOnClickCategoryAiPresetRemove(event, item)}
                // disabled={isAiAgentsEditDisabled()}
                // sx={{
                //   // ml: 0.5,
                //   "&.MuiIconButton-sizeSmall": {
                //     fontSize: "16px",
                //   },
                // }}
              >
                <CancelIcon fontSize="inherit" />
              </IconButton>
            </ListItemSecondaryAction>
            {
              // )
            }
          </ListItemButton>
        </ListItem>
      );
    }
  };

  const handleAiPresetsRowOnClick = (index, item) => {
    if (clog()) console.log("handleAiPresetsRowOnClick:", index, item);
    let data = {
      type: "preset",
      aipresetid: "",
      name: "",
    };

    if (item.item) {
      const itemObj = item.item;
      data.type = "preset";
      data.aipresetid = itemObj.aipresetid;
      data.name = itemObj.name;
    } else {
      data.type = "preset";
      data.aipresetid = item.aipresetid;
      data.name = item.name;
    }

    if (!_.isEqual(data, selectedRow)) {
      setSelectedRow(data);
      if (clog()) console.log("Preset Clicked:", data.aipresetid);
    } else {
      // same row has been clicked on
      setSelectedRow({
        type: "",
        aipresetid: "",
        name: "",
      });
    }
  };

  const handleOnChangeAiPresetName = (event) => {
    const val = event.target.value;
    if (clog()) console.log("event:", event.target.value);
    setAiPresetName(val);
  };

  const handleOnClickAiPresetEdit = (e, item) => {
    e.stopPropagation();
    e.preventDefault();
    //
    if (adminAiPresetsIsEditing.active === false) {
      let name = "";
      if (item && item.name) {
        name = item.name;
      }
      setAiPresetName(name);

      // activate edit mode
      setAdminAiPresetsIsEditing({
        active: true,
        type: "edit",
      });
    } else {
      // deactivate edit mode
      setAdminAiPresetsIsEditing({
        active: false,
        type: "",
      });
    }
  };

  const handleOnClickAiPresetNameSave = async (event, item) => {
    if (clog()) console.log("handleOnClickAiPresetNameSave:", item);
    //
    // setAiSettingsName(name)
    setAiPresetNameSaving(true);

    const result = await saveAdminAiPresetName(item.aipresetid, aiPresetName);
    //
    if (result && result.data && result.data.aipresetid && result.data.aipresetid !== "") {
      // check if item exists in adminCategoryAiPresets
      const test = adminCategoryAiPresets;
      const presetIdx = _.findIndex(adminCategoryAiPresets, { aipresetid: result.data.aipresetid });
      if (presetIdx !== -1) {
        // update item in adminCategoryAiPresets
        const newPresetArr = [...adminCategoryAiPresets];
        // only update state if name is different
        if (newPresetArr[presetIdx].name !== result.data.name) {
          newPresetArr[presetIdx].name = result.data.name;
          setAdminCategoryAiPresets(newPresetArr);
        }
      }

      const presetUpdatedIdx = _.findIndex(adminCategoryAiPresetsUpdated, { aipresetid: result.data.aipresetid });
      if (presetUpdatedIdx !== -1) {
        // update item in adminCategoryAiPresets
        const newPresetUpdatedArr = [...adminCategoryAiPresetsUpdated];
        // only update state if name is different
        if (newPresetUpdatedArr[presetUpdatedIdx].name !== result.data.name) {
          newPresetUpdatedArr[presetUpdatedIdx].name = result.data.name;
          setAdminCategoryAiPresetsUpdated(newPresetUpdatedArr);
        }
      }

      // const test1 = adminCategoryAiPresetsUpdated
    }
    //
    setAiPresetNameSaving(false);

    // deactivate edit mode
    setAdminAiPresetsIsEditing({
      active: false,
      type: "",
    });
  };

  const isAiPresetNameSaveDisabled = (item) => {
    let disabled = false;
    if (aiPresetName === "") {
      disabled = true;
    }

    if (aiPresetName === item.name) {
      disabled = true;
    }

    return disabled;
  };

  const handleOnClickAiPresetAdd = (e, item) => {
    e.stopPropagation();
    e.preventDefault();

    if (clog()) console.log("handleOnClickAiPresetAdd:", item);

    // add item to adminCategoryAiPresetsUpdated
    let newPresetArr = [...adminCategoryAiPresetsUpdated];
    newPresetArr.push(item);

    // sort by name
    newPresetArr = _.sortBy(newPresetArr, "name");

    setAdminCategoryAiPresetsUpdated(newPresetArr);

    // add item to adminCategoryAiPresetsUpdated
    let newPresetIdsArr = [...adminCategoryAiPresetsIdsUpdated];

    newPresetIdsArr.push(item.aipresetid);
    setAdminCategoryAiPresetsIdsUpdated(newPresetIdsArr);

    // reset selected row
    resetSelectedRow();
    // const item = adminAiPresetsDocs[index];
  };

  const isAiPresetAddDisabled = (item) => {
    // default disable funtionality
    let disabled = isDisabledRow("preset", item);

    // no category is selected so dont show add button
    if (adminAiCategorySelected === null) {
      disabled = true;
    }

    return disabled;
  };

  const isAiPresetEditDisabled = (item) => {
    // default disable funtionality
    let disabled = false;

    if (adminAiPresetsIsEditing.active === true) {
      disabled = true;
    }

    if (adminAiCategoriesIsEditing.active === true) {
      disabled = true;
    }

    return disabled;
  };

  const renderAiPresetsRows = (props) => {
    const { index, style } = props;
    // const item = googleCategoriesRows[index]
    // let id = ''
    let t = "";

    // const item = searchResultsArr[index]
    const item = adminAiPresetsDocs[index];

    if (item) {
      // if (item.item) {
      //   const itemObj = item.item;
      //   // id = itemObj.id
      //   t = itemObj.t;
      // } else {
      //   // id = item.id
      //   t = item.t;
      // }

      let name = "";
      if (item.name) {
        name = item.name;
      }

      const selectedRow = isSelectedRow("preset", item);

      // const searchItemObj = searchResultsArr[index]
      // const item = searchItemObj.item
      // const id = item.id
      // const t = item.t

      const temp = true;
      if (selectedRow && adminAiPresetsIsEditing.active === true) {
        return (
          <ListItem style={style} key={index} component="div" disablePadding divider>
            <Box
              sx={{
                pt: 0,
                pb: 0,
                height: "100%",
                display: "flex",
                // backgroundColor: "blue",
              }}
            >
              {
                // <ListItemText
                //   primary={name + " XXXX"}
                //   sx={{
                //     textTransform: "capitalize !important",
                //     color: isDisabledRow("preset", item) ? theme.palette.text.disabled : "inherit",
                //   }}
                // />
              }

              <Box
                name="aiPresetsNameInput"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  pl: 1,
                  // flexGrow: 1,
                  // backgroundColor: "#cc0000",
                  width: "100%",
                }}
              >
                <TextField
                  required
                  fullWidth
                  label="Preset Name"
                  name="Preset Name"
                  autoComplete="name"
                  autoFocus
                  sx={{
                    minWidth: "300px",
                    input: {
                      py: "7px",
                      px: "8px",
                      backgroundColor: "white",
                    },
                  }}
                  value={aiPresetName}
                  onChange={(e) => handleOnChangeAiPresetName(e)}
                />
              </Box>

              <ListItemSecondaryAction>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Box>
                    {aiPresetNameSaving ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "36px",
                          height: "36px",
                        }}
                      >
                        <CircularProgress
                          size={12}
                          sx={{
                            p: 0,
                            m: 0,
                          }}
                          // variant="determinate"
                        />
                      </Box>
                    ) : (
                      <IconButton
                        aria-label="Save"
                        color="primary"
                        // size="small"
                        // variant="light"
                        onClick={(event) => handleOnClickAiPresetNameSave(event, item)}
                        disabled={isAiPresetNameSaveDisabled(item)}
                        // sx={{
                        //   // ml: 0.5,
                        //   "&.MuiIconButton-sizeSmall": {
                        //     fontSize: "16px",
                        //   },
                        // }}
                      >
                        <DoneIcon fontSize="inherit" />
                      </IconButton>
                    )}
                  </Box>
                  <Box>
                    <IconButton
                      aria-label="Cancel"
                      color="primary"
                      // size="small"
                      // variant="light"
                      onClick={(event) => handleOnClickAiPresetEdit(event, item)}
                      // disabled={isDisabledRow("preset", item)}
                      // sx={{
                      //   // ml: 0.5,
                      //   "&.MuiIconButton-sizeSmall": {
                      //     fontSize: "16px",
                      //   },
                      // }}
                    >
                      <ClearIcon fontSize="inherit" />
                    </IconButton>
                  </Box>
                </Box>
              </ListItemSecondaryAction>
            </Box>
          </ListItem>
        );
      } else if (selectedRow) {
        return (
          <ListItem style={style} key={index} component="div" disablePadding divider>
            <ListItemButton
              sx={{ pt: 0, pb: 0, height: "100%" }}
              onClick={() => handleAiPresetsRowOnClick(index, item)}
              selected={selectedRow}
              // disabled={isDisabledRow("preset", item)}
              disabled={isListButtonDisabled()}
            >
              <ListItemText
                primary={name}
                sx={{
                  textTransform: "capitalize !important",
                  //  color: isDisabledRow("preset", item) ? theme.palette.text.disabled : "inherit",
                }}
              />

              <ListItemSecondaryAction>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Box>
                    <IconButton
                      aria-label="Edit"
                      color="primary"
                      // size="small"
                      // variant="light"
                      onClick={(event) => handleOnClickAiPresetEdit(event, item)}
                      disabled={isAiPresetEditDisabled(item)}
                      // sx={{
                      //   // ml: 0.5,
                      //   "&.MuiIconButton-sizeSmall": {
                      //     fontSize: "16px",
                      //   },
                      // }}
                    >
                      <EditIcon fontSize="inherit" />
                    </IconButton>
                  </Box>

                  {
                    // <Box>
                    //   <IconButton
                    //     aria-label="Add"
                    //     color="primary"
                    //     size="small"
                    //     // variant="light"
                    //     onClick={(event) => handleOnClickAiPresetAdd(event, item)}
                    //     disabled={isAiPresetAddDisabled(item)}
                    //     sx={{
                    //       // ml: 0.5,
                    //       "&.MuiIconButton-sizeSmall": {
                    //         fontSize: "16px",
                    //       },
                    //     }}
                    //   >
                    //     <AddIcon fontSize="inherit" />
                    //   </IconButton>
                    // </Box>
                  }

                  {isDisabledRow("preset", item) ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <IconButton
                        aria-label="Add"
                        color="primary"
                        // size="small"
                        // variant="light"
                        // onClick={(event) => handleOnClickAiPresetAdd(event, item)}
                        onClick={(event) => handleOnClickCategoryAiPresetRemove(event, item)}
                        // disabled={isAiPresetAddDisabled(item)}
                        // sx={{
                        //   // ml: 0.5,
                        //   "&.MuiIconButton-sizeSmall": {
                        //     fontSize: "16px",
                        //   },
                        // }}
                      >
                        <CheckCircleIcon fontSize="inherit" />
                      </IconButton>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <IconButton
                        aria-label="Add"
                        color="primary"
                        // size="small"
                        // variant="light"
                        onClick={(event) => handleOnClickAiPresetAdd(event, item)}
                        disabled={isAiPresetAddDisabled(item)}
                        // sx={{
                        //   // ml: 0.5,
                        //   "&.MuiIconButton-sizeSmall": {
                        //     fontSize: "16px",
                        //   },
                        // }}
                      >
                        <CircleIcon fontSize="inherit" />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              </ListItemSecondaryAction>
            </ListItemButton>
          </ListItem>
        );
      } else {
        return (
          <ListItem style={style} key={index} component="div" disablePadding divider>
            <ListItemButton
              sx={{ pt: 0, pb: 0, height: "100%" }}
              onClick={() => handleAiPresetsRowOnClick(index, item)}
              selected={selectedRow}
              // disabled={isDisabledRow("preset", item)}
              disabled={isListButtonDisabled()}
            >
              <ListItemText
                primary={name}
                sx={{
                  textTransform: "capitalize !important",
                  // color: isDisabledRow("preset", item) ? theme.palette.text.disabled : "inherit",
                }}
              />

              {isDisabledRow("preset", item) ? (
                <ListItemSecondaryAction>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <IconButton
                      aria-label="Add"
                      color="primary"
                      // size="small"
                      // variant="light"
                      // onClick={(event) => handleOnClickAiPresetAdd(event, item)}
                      onClick={(event) => handleOnClickCategoryAiPresetRemove(event, item)}

                      // disabled={isAiPresetAddDisabled(item)}
                      // sx={{
                      //   // ml: 0.5,
                      //   "&.MuiIconButton-sizeSmall": {
                      //     fontSize: "16px",
                      //   },
                      // }}
                    >
                      <CheckCircleIcon fontSize="inherit" />
                    </IconButton>
                  </Box>
                </ListItemSecondaryAction>
              ) : (
                <ListItemSecondaryAction>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <IconButton
                      aria-label="Add"
                      color="primary"
                      // size="small"
                      // variant="light"
                      onClick={(event) => handleOnClickAiPresetAdd(event, item)}
                      disabled={isAiPresetAddDisabled(item)}
                      // sx={{
                      //   // ml: 0.5,
                      //   "&.MuiIconButton-sizeSmall": {
                      //     fontSize: "16px",
                      //   },
                      // }}
                    >
                      <CircleIcon fontSize="inherit" />
                    </IconButton>
                  </Box>
                </ListItemSecondaryAction>
              )}
            </ListItemButton>
          </ListItem>
        );
      }
    }
  };

  const getSelectedCategoryId = () => {
    let selectedCategory = "none";
    if (adminAiCategorySelected && adminAiCategorySelected.aicategoryid && adminAiCategorySelected.aicategoryid !== "") {
      selectedCategory = adminAiCategorySelected.aicategoryid;
    }

    return selectedCategory;
  };

  const showCategoriesControls = () => {
    if (adminAiCategoriesIsEditing.active === true && adminAiCategoriesIsEditing.type === "edit") {
      return (
        // START Category Edit
        <Box
          name="aiCategoriesNameInput"
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            // pl: 1,

            // backgroundColor: "#cc0000",
          }}
        >
          <Box>
            <TextField
              required
              fullWidth
              label="Category Name"
              name="Category Name"
              autoComplete="name"
              autoFocus
              sx={{
                minWidth: "300px",
                input: {
                  // py: "7px",
                  // px: "8px",
                  backgroundColor: "white",
                },
              }}
              value={aiCategoryName}
              onChange={(e) => handleOnChangeAiCategoryName(e)}
            />
          </Box>

          <Box
            sx={{
              ml: 1,
            }}
          >
            {aiCategoryNameSaving ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "30px",
                  height: "30px",
                }}
              >
                <CircularProgress
                  size={12}
                  sx={{
                    p: 0,
                    m: 0,
                  }}
                  // variant="determinate"
                />
              </Box>
            ) : (
              <IconButton
                aria-label="Save"
                color="primary"
                size="small"
                // variant="light"
                onClick={(event) => handleOnClickAiCategoryNameSave(event)}
                disabled={isAiCategoryNameSaveDisabled()}
                sx={{
                  // ml: 0.5,
                  "&.MuiIconButton-sizeSmall": {
                    fontSize: "16px",
                  },
                }}
              >
                <DoneIcon fontSize="inherit" />
              </IconButton>
            )}
          </Box>

          <Box>
            <IconButton
              aria-label="Cancel"
              color="primary"
              size="small"
              // variant="light"
              onClick={() => handleOnClickAiCategoryEdit()}
              // disabled={isCategoryAddRemoveEditDisabled("add")}
              sx={{
                // ml: 0.5,
                "&.MuiIconButton-sizeSmall": {
                  fontSize: "16px",
                },
              }}
            >
              <ClearIcon fontSize="inherit" />
            </IconButton>
          </Box>
        </Box>
        // END Category Edit
      );
    } else if (adminAiCategoriesIsEditing.active === true && adminAiCategoriesIsEditing.type === "add") {
      return (
        // START Category Edit
        <Box
          name="aiCategoriesNameInput"
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            // pl: 1,

            // backgroundColor: "#cc0000",
          }}
        >
          <Box>
            <TextField
              required
              fullWidth
              label="Category Name"
              name="Category Name"
              autoComplete="name"
              autoFocus
              sx={{
                minWidth: "300px",
                input: {
                  // py: "7px",
                  // px: "8px",
                  backgroundColor: "white",
                },
              }}
              value={aiCategoryName}
              onChange={(e) => handleOnChangeAiCategoryName(e)}
            />
          </Box>

          <Box
            sx={{
              ml: 1,
            }}
          >
            {aiCategoryNameSaving ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "30px",
                  height: "30px",
                }}
              >
                <CircularProgress
                  size={12}
                  sx={{
                    p: 0,
                    m: 0,
                  }}
                  // variant="determinate"
                />
              </Box>
            ) : (
              <IconButton
                aria-label="Save"
                color="primary"
                size="small"
                // variant="light"
                onClick={(event) => handleOnClickAiCategoryNewSave(event)}
                disabled={isAiCategoryNameSaveDisabled()}
                sx={{
                  // ml: 0.5,
                  "&.MuiIconButton-sizeSmall": {
                    fontSize: "16px",
                  },
                }}
              >
                <DoneIcon fontSize="inherit" />
              </IconButton>
            )}
          </Box>

          <Box>
            <IconButton
              aria-label="Cancel"
              color="primary"
              size="small"
              // variant="light"
              onClick={() => handleOnClickAiCategoryEdit()}
              // disabled={isCategoryAddRemoveEditDisabled("add")}
              sx={{
                // ml: 0.5,
                "&.MuiIconButton-sizeSmall": {
                  fontSize: "16px",
                },
              }}
            >
              <ClearIcon fontSize="inherit" />
            </IconButton>
          </Box>
        </Box>
        // END Category Edit
      );
    } else {
      return (
        // START Category Select
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            // backgroundColor: "#cc0000",
          }}
        >
          <Box>
            <FormControl
              sx={{
                // m: 1,
                minWidth: 120,
              }}
            >
              <InputLabel>Category</InputLabel>
              <Select
                // id="demo-simple-select"
                // value={age}
                label="Category"
                // onChange={handleChange}
                // value={adminAiCategorySelected && adminAiCategorySelected.aicategoryid ? adminAiCategorySelected.aicategoryid : ""}
                value={getSelectedCategoryId()}
                onChange={(event) => handleOnChangeAiCategory(event)}
                disabled={isCategorySelectDisabled()}
                sx={{
                  minWidth: 300,
                }}
              >
                {adminAiCategorySelected === null && (
                  <MenuItem
                    value="none"
                    disabled
                    sx={{
                      display: "none",
                    }}
                  >
                    Select a Category
                  </MenuItem>
                )}
                {adminAiCategoriesSuperAdminDocs &&
                  adminAiCategoriesSuperAdminDocs.map((c, index) => {
                    return (
                      <MenuItem key={"aicategory" + index} value={c.aicategoryid}>
                        {c.name}
                      </MenuItem>
                    );
                  })}
                {
                  // adminUserData && adminUserData.userDoc && adminUserData.userDoc.memberships && adminUserData.userDoc.memberships.map((m, index) => {
                  //   return (
                  //     <MenuItem key={'membership' + index} value={index}>{m.name}</MenuItem>
                  //   )
                  // })
                }
              </Select>
            </FormControl>
          </Box>

          {adminAiCategoriesIsEditing.active === true && adminAiCategoriesIsEditing.type === "remove" ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                // backgroundColor: "#cc0000",
              }}
            >
              <Box
                sx={{
                  ml: 1,
                }}
              >
                <IconButton
                  aria-label="Save"
                  color="primary"
                  size="small"
                  // variant="light"
                  onClick={(event) => handleOnClickRemoveConfirm(event)}
                  // disabled={isAiCategoryNameSaveDisabled()}
                  sx={{
                    // ml: 0.5,
                    "&.MuiIconButton-sizeSmall": {
                      fontSize: "16px",
                    },
                  }}
                >
                  <DoneIcon fontSize="inherit" />
                </IconButton>
              </Box>

              <Box>
                <IconButton
                  aria-label="Cancel"
                  color="primary"
                  size="small"
                  // variant="light"
                  onClick={() => handleOnClickAiCategoryEdit()}
                  // disabled={isCategoryAddRemoveEditDisabled("add")}
                  sx={{
                    // ml: 0.5,
                    "&.MuiIconButton-sizeSmall": {
                      fontSize: "16px",
                    },
                  }}
                >
                  <ClearIcon fontSize="inherit" />
                </IconButton>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                // backgroundColor: "#cc0000",
              }}
            >
              <Box
                sx={{
                  ml: 1,
                }}
              >
                <IconButton
                  aria-label="Edit"
                  color="primary"
                  size="small"
                  // variant="light"
                  onClick={() => handleOnClickAiCategoryEdit()}
                  disabled={isCategoryAddRemoveEditDisabled("edit")}
                  sx={{
                    // ml: 0.5,
                    "&.MuiIconButton-sizeSmall": {
                      fontSize: "16px",
                    },
                  }}
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>
              </Box>
              <Box>
                <IconButton
                  aria-label="Add"
                  color="primary"
                  size="small"
                  // variant="light"
                  onClick={() => handleOnClickAiCategoryAdd()}
                  disabled={isCategoryAddRemoveEditDisabled("add")}
                  sx={{
                    // ml: 0.5,
                    "&.MuiIconButton-sizeSmall": {
                      fontSize: "16px",
                    },
                  }}
                >
                  <AddIcon fontSize="inherit" />
                </IconButton>
              </Box>
              <Box>
                <IconButton
                  aria-label="Remove"
                  color="primary"
                  size="small"
                  // variant="light"
                  onClick={() => handleOnClickAiCategoryRemove()}
                  disabled={isCategoryAddRemoveEditDisabled("remove")}
                  sx={{
                    // ml: 0.5,
                    "&.MuiIconButton-sizeSmall": {
                      fontSize: "16px",
                    },
                  }}
                >
                  <RemoveIcon fontSize="inherit" />
                </IconButton>
              </Box>
            </Box>
          )}
        </Box>
        // END Category Select
      );
    }
  };

  if (adminAiSetupTabLoading || !adminAiPresetsDocs) {
    return (
      <LoadingPane
      // text={'Error loading Ecomacy Data'}
      //showSpinner={false}
      />
    );
  }

  const test = adminUserData;
  const test1 = adminAiCategoriesSuperAdminDocs;
  const test2 = adminAiPresetsDocs;

  return (
    <Box width="1154px">
      <Grid container spacing={3} mt={0}>
        <Grid item xs={12} md={12}>
          <Box
            sx={{
              // backgroundColor: '#cc0000',
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {showCategoriesControls()}

            <Box>
              <Button
                aria-label="Save"
                color="success"
                variant="contained"
                sx={{
                  minWidth: "70px",
                }}
                onClick={handleOnClickSave}
                disabled={isSaveDisabled()}
              >
                SAVE
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            sx={
              {
                // backgroundColor: "#cc0000",
              }
            }
          >
            {
              <Stack spacing={0.5}>
                <Box
                  sx={{
                    bgcolor: "background.paper",
                    pb: "2px",
                  }}
                >
                  <InputLabel sx={{ fontWeight: 700 }}>Category Presets</InputLabel>
                </Box>
                <MainCard
                  content={false}
                  // codeString={scrollListCodeString}
                >
                  <Box sx={{ width: "100%", height: 390, bgcolor: "background.paper" }}>
                    <FixedSizeList
                      height={600}
                      width="100%"
                      itemSize={56}
                      // data={googleCategoriesRows}
                      // itemCount={googleCategoriesRows.length}
                      data={adminCategoryAiPresetsUpdated ? adminCategoryAiPresetsUpdated : []}
                      itemCount={adminCategoryAiPresetsUpdated ? adminCategoryAiPresetsUpdated.length : 0}
                      overscanCount={5}
                      scrollToItem={0}
                    >
                      {renderSelectedPresetsRows}
                    </FixedSizeList>
                  </Box>
                </MainCard>
              </Stack>
            }
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            sx={
              {
                // backgroundColor: "#cc0000",
              }
            }
          >
            <Stack spacing={0.5}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  // p: 1,
                  // m: 1,
                  bgcolor: "background.paper",
                }}
              >
                <InputLabel sx={{ fontWeight: 700 }}>Presets</InputLabel>
                <Typography variant="h6" align="right" sx={{ textDecoration: "none" }} color="textSecondary">
                  <b>Results:</b>{" "}
                  {
                    // searchResultsArr.length
                    adminAiPresetsDocs.length
                  }
                </Typography>
              </Box>

              <MainCard content={false}>
                <Box sx={{ width: "100%", height: 390, bgcolor: "background.paper" }}>
                  <FixedSizeList
                    height={800}
                    width="100%"
                    itemSize={56}
                    // data={googleCategoriesRows}
                    // itemCount={googleCategoriesRows.length}
                    data={adminAiPresetsDocs}
                    itemCount={adminAiPresetsDocs.length}
                    overscanCount={5}
                    scrollToItem={0}
                  >
                    {renderAiPresetsRows}
                  </FixedSizeList>
                </Box>
              </MainCard>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default React.memo(AdminAiSuperSetupTab);
