import React, { useState, useEffect } from 'react'
// import PropTypes from 'prop-types';

import {
  getFirestore,
  collection,
  query,
  where,
  // orderBy
} from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import { firebase } from '../code/firebase';
import { processKeywordsUserData } from '../code/ecomacy';

import _ from 'lodash';

// import { useTheme } from '@mui/material/styles';
// import Container from '@mui/material/Container';

import SettingsPane from './SettingsPane'
import SettingsPaneLoading from './SettingsPaneLoading'

// import MainCard from './MainCard';

const SettingsKeywordsWrapper = (props) => {
  // const theme = useTheme();

  // let userData = {}
  // if (props.userData) {
  //   userData = props.userData
  // }

  let userDataObj = {
    userId: '',
    locationId: '',
    email: '',
    website: '',
    address: '',
    city: '',
    state: '',
    country: '',
    postalCode: '',
    timezone: '',
    businessname: '',
    lat: null,
    lng: null,
    zoom: ''

  }

  if (props.userData) {
    userDataObj.userId = props.userData.userId
    userDataObj.locationId = props.userData.locationId
    userDataObj.email = props.userData.email
    // userDataObj = props.userData
  }

  const [userData, setUserData] = useState(userDataObj);

  const [keywordId, setKeywordId] = useState('');
  const [gridTemplateId, setGridTemplateId] = useState('');
  const [keywordsData, setKeywordsData] = useState([]);
  const [keywordsLoaded, setKeywordsLoaded] = useState(false);

  const [keywordsSchedule, setKeywordsSchedule] = useState({});
  const [keywordsDataParams, setKeywordsDataParams] = useState({});
  const [keywordsDocData, setKeywordsDocData] = useState({});

  let keywordsDataObj = {}

  useEffect(() => {
    if (!_.isEmpty(keywordsDataObj) && keywordsDataObj.exists === true && keywordsLoaded === false) {
      // initial firestore fetch
      setKeywordId(keywordsDataObj.id)
      setGridTemplateId(keywordsDataObj.gridTemplateId)
      setKeywordsData(keywordsDataObj.keywords)
      setKeywordsSchedule(keywordsDataObj.schedule)
      setKeywordsDataParams(keywordsDataObj.data)

      setKeywordsDocData(keywordsDataObj.docData)
      setKeywordsLoaded(true)

      // update user data
      if (keywordsDataObj.docData.data) {
        const keywordsDocDataData = keywordsDataObj.docData.data
        // HL data
        // "address": "17 St Mary’s Road",
        // "city": "Leatherhead",
        // "state": "Surrey",
        // "country": "GB",

        const newUserData = processKeywordsUserData(userData, keywordsDocDataData)
        setUserData(newUserData)
      }
    }
    else if (!_.isEqual(keywordsData, keywordsDataObj.keywords) && keywordsDataObj.exists === true && keywordsLoaded === true) {
      // firestore updated
      setGridTemplateId(keywordsDataObj.gridTemplateId)
      setKeywordsData(keywordsDataObj.keywords)
      setKeywordsSchedule(keywordsDataObj.schedule)
      setKeywordsDataParams(keywordsDataObj.data)

      setKeywordsDocData(keywordsDataObj.docData)

    }

    else if (!_.isEqual(keywordsSchedule, keywordsDataObj.schedule) && keywordsDataObj.exists === true && keywordsLoaded === true) {
      // keywords doc schedule updated
      // firestore updated
      // setGridTemplateId(keywordsDataObj.gridTemplateId)
      // setKeywordsData(keywordsDataObj.keywords)
      setKeywordsSchedule(keywordsDataObj.schedule)
      // setKeywordsDataParams(keywordsDataObj.data)

      setKeywordsDocData(keywordsDataObj.docData)

    }
    else if (!_.isEqual(keywordsDocData, keywordsDataObj.docData) && keywordsDataObj.exists === true && keywordsLoaded === true) {
      // keywords doc data updated
      // firestore updated
      // setGridTemplateId(keywordsDataObj.gridTemplateId)
      // setKeywordsData(keywordsDataObj.keywords)
      // setKeywordsSchedule(keywordsDataObj.schedule)
      // setKeywordsDataParams(keywordsDataObj.data)
      setKeywordsDocData(keywordsDataObj.docData)

    }
    else if (!_.isEmpty(keywordsDataObj) && keywordsDataObj.exists === false && keywordsLoaded === false) {
      setKeywordsLoaded(true)
    }
    //setUser({ pending: true, value: undefined })
    // fetchUser().then((fetchedUser) => {
    //  setUser({ pending: true, value: fetchedUser })
    //})
  }, [keywordsDataObj])


  const keywordsRef = collection(getFirestore(firebase), 'keywords')

  const q = query(
    keywordsRef,
    where('userid', '==', userData.userId),
    where('eclocationid', '==', userData.locationId),
  );
  const [value, loading, error] = useCollection(
    q,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );




  if (error) {
    // return (
    //   <Container maxWidth={false}>
    //     <strong>Keywords Error: {JSON.stringify(error)}</strong>
    //   </Container>
    // )

    return (
      <SettingsPaneLoading
        loading={''}
        error={'Keywords Error: : ' + JSON.stringify(error)}
      />
    )
  }

  if (loading) {
    // return (
    //   <Container maxWidth={false}>
    //     Loading Keywords...
    //   </Container>
    // )

    return (
      <SettingsPaneLoading
        loading={'Loading Keywords'}
        loadingProgress={25}
        error={''}
      />
    )
  }

  if (value) {
    // START: build data
    // let userId = ''
    // let locationId = ''
    let sortedKeywordsData = []
    let keywordDataFound = false

    value.docs.forEach((doc, index) => {

      const docData = doc.data()
      if (docData) {
        keywordDataFound = true
        // if (docData.userid) {
        //   userId = docData.userid
        // }
        // if (docData.eclocationid) {
        //   locationId = docData.eclocationid
        // }

        keywordsDataObj = {
          id: '',
          keywords: []
        }

        let docId = ''
        if (docData.id) {
          docId = docData.id
        }
        let keywords = []
        if (docData.keywords) {
          keywords = docData.keywords
          if (keywords.length > 0) {
            // sort keywords
            const alphaKeywords = _.orderBy(keywords, 'keyword', 'asc')

            // find primary index
            const primaryKeywordIdx = _.findIndex(alphaKeywords, { primary: 1 });

            // add primary keyword object first
            sortedKeywordsData.push(alphaKeywords[primaryKeywordIdx])

            //loop and add remaining keyword objects
            for (let i = 0; i < alphaKeywords.length; i++) {
              if (i !== primaryKeywordIdx) {
                sortedKeywordsData.push(alphaKeywords[i])
              }
            }
          }

          // trigger useEffect
          keywordsDataObj = {
            exists: true,
            // userId: userId,
            // locationId: locationId,
            id: docId,
            keywords: sortedKeywordsData,
            gridTemplateId: docData.gridtemplateid,
            schedule: docData.schedule,
            data: docData.data,
            docData: docData, // BT: might not need

            // keywords: keywords
          }

        }

      }


    })
    // no data found
    if (!keywordDataFound) {
      keywordsDataObj = {
        exists: false
      }
    }

    if (keywordsLoaded) {
      return (
        <SettingsPane
          userData={userData}
          keywordsData={keywordsData}
          keywordId={keywordId}
          gridTemplateId={gridTemplateId}
          keywordsDataParams={keywordsDataParams}
          keywordsSchedule={keywordsSchedule}
          keywordsDocData={keywordsDocData}
          keywordsLoaded={keywordsLoaded}
        />
      );
    }
    else {
      // return (
      //   <Container maxWidth={false}>
      //     Adding Keywords State...
      //   </Container>
      // )

      return (
        <SettingsPaneLoading
          loading={'Adding Keywords State'}
          loadingProgress={50}
          error={''}
        />
      )

    }

  }







};


export default React.memo(SettingsKeywordsWrapper)