// ==============================|| OVERRIDES - INPUT BASE ||============================== //

export default function InputBase() {
  return {
    MuiInputBase: {
      styleOverrides: {
        multiline: {
          lineHeight: 1.57,
        },
        sizeSmall: {
          fontSize: "0.75rem",
        },
      },
    },
  };
}
