import React, {
  useState,
  useEffect,
  useContext,
  // useCallback
} from "react";
import PropTypes from "prop-types";

import {
  getFirestore,
  collection,
  query,
  where,
  // orderBy
} from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { firebase } from "../code/firebase";
import { processKeywordsUserData } from "../code/ecomacy";

import { RemoteConfigAuthContext } from "../helpers/RemoteConfigAuthProvider";
import { CustomerAuthContext } from "../helpers/CustomerAuthProvider";

import { AiAuthContext } from "../helpers/AiAuthProvider";
import {setd, debug, setc, clog} from "../helpers/HelperFunctions";

import _ from "lodash";

import {
  useTheme,
  // styled
} from "@mui/material/styles";

import { Box, Tab, Tabs, Typography, Button, Grid, Paper, Slider, TextField } from "@mui/material";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import AddIcon from "@mui/icons-material/Add";

import Container from "@mui/material/Container";
import GridKeywordTimelinePane from "./GridKeywordTimelinePane";
import LoadingPane from "./LoadingPane";
import LoadingSubPane from "./LoadingSubPane";

// import MainCard from './MainCard';
// import { getDfsLocationData } from '../code/ecomacy'

// import './GridKeywordsPane.css'
// const auth = getAuth(firebase)
// SF

const handleOnClick = (event) => {
  if (clog()) console.log("handleOnClick UNUSED");
  // const redirect = 'https://a5648aadd45f17fc.p50.rt3.io'
  const redirect = "http://localhost:3000";
  // const redirect = 'https://127.0.0.1:443'
  const clientid = "641a0f0a5be946d51183564e-lfiowjim";
  const scopes = ["conversations/message.readonly", "conversations/message.write", "conversations.write", "conversations.readonly"];
  const scope = scopes.join(" ");
  const url = `https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=${redirect}/oauth/callback&client_id=${clientid}&scope=${scope}`;
  window.location.href = url;
};

const AiModelPanel = (props) => {
  const theme = useTheme();


  const { rc } = useContext(RemoteConfigAuthContext);

  const { adminStatus, setAdminStatus } = useContext(CustomerAuthContext);
  // const { debug, clog } = useContext(CustomerAuthContext);

  const { testVal, setTestVal } = useContext(AiAuthContext);
  const { aiSettingsData, setAiSettingsData } = useContext(AiAuthContext);
  const { aiSettingsDataUpdated, setAiSettingsDataUpdated } = useContext(AiAuthContext);
  const { aiStrategySelected, setAiStrategySelected } = useContext(AiAuthContext);
  const { aiStrategySelectedRefresh, setAiStrategySelectedRefresh } = useContext(AiAuthContext);
  const { aiModelSelected, setAiModelSelected } = useContext(AiAuthContext);
  const { aiModelSelectedRefresh, setAiModelSelectedRefresh } = useContext(AiAuthContext);

  const { aiAgentsIsEditing, setAiAgentsIsEditing } = useContext(AiAuthContext);
  const { aiSettingsIsEditing, setAiSettingsIsEditing } = useContext(AiAuthContext);
  const { aiConversationsIsEditing, setAiConversationsIsEditing } = useContext(AiAuthContext);

  const [init, setInit] = useState(true);

  const [aiModel, setAiModel] = useState({});
  const { aiModelReset, setAiModelReset } = useContext(AiAuthContext);

  // if (clog()) console.log('AiModelPanel aiSettingsData', aiSettingsData);

  const modelList = [];

  if (rc.model_claude_2) {
    modelList.push({
      key: "claude-2",
      model: "Claude 2",
      modeltype: "anthropic",
    });
  }

  if (rc.model_openai_gpt_3_5_turbo) {
    modelList.push({
      key: "gpt-3-5-turbo",
      model: "gpt 3.5 turbo",
      modeltype: "openai",
    });
  }

  if (rc.model_openai_gpt_3_5_turbo_0301) {
    modelList.push({
      key: "gpt-3-5-turbo-0301",
      model: "gpt 3.5 turbo 0301",
      modeltype: "openai",
    });
  }

  if (rc.model_openai_gpt_3_5_turbo_16k) {
    modelList.push({
      key: "gpt-3-5-turbo-16k",
      model: "gpt 3.5 turbo 16k",
      modeltype: "openai",
    });
  }

  if (rc.model_openai_gpt_3_5_turbo_0613) {
    modelList.push({
      key: "gpt-3-5-turbo-0613",
      model: "gpt 3.5 turbo 0613",
      modeltype: "openai",
    });
  }

  if (rc.model_openai_gpt_3_5_turbo_16k_0613) {
    modelList.push({
      key: "gpt-3-5-turbo-16k-0613",
      model: "gpt 3.5 turbo 16k 0613",
      modeltype: "openai",
    });
  }

  if (rc.model_openai_gpt_4) {
    modelList.push({
      key: "gpt-4",
      model: "gpt 4",
      modeltype: "openai",
    });
  }

  if (rc.model_openai_gpt_4_0613) {
    modelList.push({
      key: "gpt-4-0613",
      model: "gpt 4 0613",
      modeltype: "openai",
    });
  }

  if (rc.model_openai_gpt_4_1106_preview) {
    modelList.push({
      key: "gpt-4-1106-preview",
      model: "gpt 4 1106 preview",
      modeltype: "openai",
    });
  }

  if (rc.model_openai_gpt_4_32k) {
    modelList.push({
      key: "gpt-4-32k",
      model: "gpt 4 32k",
      modeltype: "openai",
    });
  }

  if (rc.model_openai_gpt_4_32k_0613) {
    modelList.push({
      key: "gpt-4-32k-0613",
      model: "gpt 4 32k 0613",
      modeltype: "openai",
    });
  }

  modelList.push({
    key: "gpt-4o",
    model: "gpt 4o",
    modeltype: "openai",
  });


  modelList.push({
    key: "gpt-4-turbo",
    model: "gpt 4 turbo",
    modeltype: "openai",
  });


  const defaultModelData = {};

  defaultModelData["gpt-4o"] = {
    temperature: 1,
    max_tokens: 256,
    top_p: 1,
    frequency_penalty: 0,
    presence_penalty: 0,
  };

  defaultModelData["gpt-4-turbo"] = {
    temperature: 1,
    max_tokens: 256,
    top_p: 1,
    frequency_penalty: 0,
    presence_penalty: 0,
  };

  if (rc.model_claude_2) {
    defaultModelData["claude-2"] = {
      max_tokens_to_sample: 256,
      temperature: 1,
      top_k: -1,
      top_p: -1,
    };
  }

  if (rc.model_openai_gpt_3_5_turbo) {
    defaultModelData["gpt-3-5-turbo"] = {
      temperature: 1,
      max_tokens: 256,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    };
  }

  if (rc.model_openai_gpt_3_5_turbo_0301) {
    defaultModelData["gpt-3-5-turbo-0301"] = {
      temperature: 1,
      max_tokens: 256,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    };
  }

  if (rc.model_openai_gpt_3_5_turbo_16k) {
    defaultModelData["gpt-3-5-turbo-16k"] = {
      temperature: 1,
      max_tokens: 256,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    };
  }

  if (rc.model_openai_gpt_3_5_turbo_0613) {
    defaultModelData["gpt-3-5-turbo-0613"] = {
      temperature: 1,
      max_tokens: 256,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    };
  }

  if (rc.model_openai_gpt_3_5_turbo_16k_0613) {
    defaultModelData["gpt-3-5-turbo-16k-0613"] = {
      temperature: 1,
      max_tokens: 256,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    };
  }

  if (rc.model_openai_gpt_4) {
    defaultModelData["gpt-4"] = {
      temperature: 1,
      max_tokens: 256,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    };
  }

  if (rc.model_openai_gpt_4_0613) {
    defaultModelData["gpt-4-0613"] = {
      temperature: 1,
      max_tokens: 256,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    };
  }

  if (rc.model_openai_gpt_4_32k) {
    defaultModelData["gpt-4-32k"] = {
      temperature: 1,
      max_tokens: 256,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    };
  }

  if (rc.model_openai_gpt_4_32k_0613) {
    defaultModelData["gpt-4-32k-0613"] = {
      temperature: 1,
      max_tokens: 256,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    };
  }


  if (rc.model_openai_gpt_4_1106_preview) {
    defaultModelData["gpt-4-1106-preview"] = {
      temperature: 1,
      max_tokens: 256,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    };
  }





  // model_openai_gpt_4
  // model_openai_gpt_4_0613
  // model_openai_gpt_4_32k
  // model_openai_gpt_4_32k_0613

  const strategyList = [
    {
      key: "chat",
      name: "Chat",
      strategy: "chat",
    },
  ];
  if (rc.strategy_openai_chatwithknowledge) {
    // add question chat if config is met
    const chatWithKnowledge = {
      key: "chatwithknowledge",
      name: "Chat with Knowledge Base",
      strategy: "chatwithknowledge",
    };
    strategyList.push(chatWithKnowledge);
  }

  if (rc.strategy_openai_questionchat) {
    // add question chat if config is met
    const questionChat = {
      key: "question_chat",
      name: "Question Chat",
      strategy: "question_chat",
    };
    strategyList.push(questionChat);
  }



  // temperature: 0.7 - Defaults to 1
  // max_tokens: 256
  // top_p: 1 - Defaults to 1
  // frequency_penalty: 0 - Defaults to 0
  // presence_penalty: 0 - Defaults to 0

  // max_tokens_to_sample
  // temperature
  // top_k
  // top_p

  useEffect(() => {
    if (init && aiSettingsDataUpdated) {
      // set menu
      // find aiSettingsDataUpdated
      // BT ZZZ
      let selectedModel = "";
      if (aiSettingsDataUpdated && aiSettingsDataUpdated.data && aiSettingsDataUpdated.data.model && aiSettingsDataUpdated.data.model !== "") {
        selectedModel = aiSettingsDataUpdated.data.model;

        // var foundModelIdx = _.findIndex(modelList, { model: aiSettingsDataUpdated.data.model });
        var foundModelIdx = _.findIndex(modelList, { key: aiSettingsDataUpdated.data.model });
        if (foundModelIdx > -1) {
          const foundModel = modelList[foundModelIdx];
          setAiModelSelected(foundModel);

          const defaultData = getDefaultModelData(foundModel);
          if (aiSettingsDataUpdated[selectedModel]) {
            // check for missing params
            const allParamsData = getMissingModelDataParams(aiSettingsDataUpdated[selectedModel], defaultData)

            // model data exists in aiSettingsDataUpdated
            setAiModel(allParamsData);
          } else {
            // if (debug()) debugger;
            if (clog()) console.log("getDefaultModelData", foundModel);
            // model data does not exist in aiSettingsDataUpdated, use default model data
            // const defaultData = getDefaultModelData(foundModel);
            setAiModel(defaultData);
          }
        }
      }
      // else {
      //   // no model found - new blank setting
      //   setAiModelSelected('')
      // }

      let selectedStrategy = "";
      if (aiSettingsDataUpdated && aiSettingsDataUpdated.data && aiSettingsDataUpdated.data.strategy && aiSettingsDataUpdated.data.strategy !== "") {
        selectedStrategy = aiSettingsDataUpdated.data.strategy;

        var foundStrategyIdx = _.findIndex(strategyList, { strategy: aiSettingsDataUpdated.data.strategy });
        if (foundStrategyIdx > -1) {
          const foundStrategy = strategyList[foundStrategyIdx];
          setAiStrategySelected(foundStrategy);
          // listen to aiStrategySelected in AiQuestionPanel useEffect
          // setAiStrategy(aiSettingsDataUpdated[selectedStrategy])
        }
      }
      // else {
      //   // no model found - new blank setting
      //   setAiStrategySelected('')
      // }

      setInit(false);
    }
    // if (map) {
    //   updateBounds(map)
    //   // handleRecenterMapReset()
    // }
    //   }, [init])
  }, [aiSettingsDataUpdated]);

  useEffect(() => {
    // triggered from AiSettingsPanel fetchAiSettingsData
    if (aiModelSelectedRefresh === true) {
      // BT ZZZ

      // set menu
      // find aiSettingsDataUpdated
      let selectedModel = "";
      if (aiSettingsDataUpdated && aiSettingsDataUpdated.data && aiSettingsDataUpdated.data.model && aiSettingsDataUpdated.data.model !== "") {
        selectedModel = aiSettingsDataUpdated.data.model;

        // var foundIdx = _.findIndex(modelList, { model: aiSettingsDataUpdated.data.model });
        var foundIdx = _.findIndex(modelList, { key: aiSettingsDataUpdated.data.model });
        if (foundIdx > -1) {
          const foundModel = modelList[foundIdx];
          setAiModelSelected(foundModel);

          const defaultData = getDefaultModelData(foundModel);

          if (aiSettingsDataUpdated[selectedModel]) {
            // check for missing params
            const allParamsData = getMissingModelDataParams(aiSettingsDataUpdated[selectedModel], defaultData)

            // model data exists in aiSettingsDataUpdated
            setAiModel(allParamsData);
          } else {
            //if (debug()) debugger;
            if (clog()) console.log("getDefaultModelData", foundModel);

            // model data does not exist in aiSettingsDataUpdated, use default model data
            // const defaultData = getDefaultModelData(foundModel);
            setAiModel(defaultData);
          }
        }
      } else {
        // setAiModelSelected(null);
        // show model and strategy controls
        setAiModelSelected("");
        setAiModel(null);
        if (clog()) console.log("AiModelPanel aiSettingsDataUpdated.data.model not found", aiSettingsDataUpdated);
      }

      let selectedStrategy = "";
      if (aiSettingsDataUpdated && aiSettingsDataUpdated.data && aiSettingsDataUpdated.data.strategy && aiSettingsDataUpdated.data.strategy !== "") {
        selectedStrategy = aiSettingsDataUpdated.data.strategy;

        var foundStrategyIdx = _.findIndex(strategyList, { strategy: aiSettingsDataUpdated.data.strategy });
        if (foundStrategyIdx > -1) {
          const foundStrategy = strategyList[foundStrategyIdx];
          setAiStrategySelected(foundStrategy);
          // listen to aiStrategySelected in AiQuestionPanel useEffect
          // setAiStrategy(aiSettingsDataUpdated[selectedStrategy])
        }
      } else {
        if (debug()) debugger;
        // setAiStrategySelected(null);
        // show model and strategy controls
        setAiStrategySelected("");
        if (clog()) console.log("AiModelPanel aiSettingsDataUpdated.data.strategy not found", aiSettingsDataUpdated);
      }
      setAiModelSelectedRefresh(false);
    }
  }, [aiModelSelectedRefresh]);

  useEffect(() => {
    // triggered from AiSettingsPanel SAVE handleOnClickCancel
    if (aiModelReset === true) {
      // // set menu
      // // find aiSettingsDataUpdated
      let selectedModel = "";
      if (aiSettingsData && aiSettingsData.data && aiSettingsData.data.model && aiSettingsData.data.model !== "") {
        selectedModel = aiSettingsData.data.model;

        // var foundIdx = _.findIndex(modelList, { model: aiSettingsData.data.model });
        var foundIdx = _.findIndex(modelList, { key: aiSettingsData.data.model });
        if (foundIdx > -1) {
          const foundModel = modelList[foundIdx];
          setAiModelSelected(foundModel);

          const defaultData = getDefaultModelData(foundModel);

          if (aiSettingsData[selectedModel]) {
            // check for missing params
            const allParamsData = getMissingModelDataParams(aiSettingsData[selectedModel], defaultData)

            // model data exists in aiSettingsData
            setAiModel(allParamsData);
          } else {
            //if (debug()) debugger;
            if (clog()) console.log("getDefaultModelData", foundModel);

            // model data does not exist in aiSettingsData, use default model data
            // const defaultData = getDefaultModelData(foundModel);
            setAiModel(defaultData);
          }
        }
      }

      let selectedStrategy = "";
      if (aiSettingsData && aiSettingsData.data && aiSettingsData.data.strategy && aiSettingsData.data.strategy !== "") {
        selectedStrategy = aiSettingsData.data.strategy;

        var foundStrategyIdx = _.findIndex(strategyList, { strategy: aiSettingsData.data.strategy });
        if (foundStrategyIdx > -1) {
          const foundStrategy = strategyList[foundStrategyIdx];
          setAiStrategySelected(foundStrategy);
          // listen to aiStrategySelected in AiQuestionPanel useEffect
          // setAiStrategy(aiSettingsData[selectedStrategy])
        }
      }
      // else {
      //   if (debug()) debugger;
      //   // setAiStrategySelected(null);
      //   // show model and strategy controls
      //   setAiStrategySelected("");
      //   if (clog()) console.log("AiModelPanel aiSettingsData.data.strategy not found", aiSettingsData);
      // }

      setAiModelReset(false);
    }
  }, [aiModelReset]);

  // NOT USED YET
  useEffect(() => {
    if (!init) {
      // const test1 = aiModelSelected
      // const test2 = aiModel
      // const test3 = aiSettingsDataUpdated
      // const test4 = aiSettingsData
      // compare aiSettingsDataUpdated to aiSettingsData
      // get selected models in xxx.data
      let aiSettingsDataModel = "";
      if (aiSettingsData && aiSettingsData.data && aiSettingsData.data.model && aiSettingsData.data.model !== "") {
        aiSettingsDataModel = aiSettingsData.data.model;
      }
      let aiSettingsDataUpdatedModel = "";
      if (aiSettingsDataUpdated && aiSettingsDataUpdated.data && aiSettingsDataUpdated.data.model && aiSettingsDataUpdated.data.model !== "") {
        aiSettingsDataUpdatedModel = aiSettingsDataUpdated.data.model;
      }

      // selected model matches aiSettingsData
      if (aiSettingsDataModel !== "" && aiSettingsDataUpdatedModel !== "" && aiSettingsDataUpdatedModel === aiSettingsDataModel) {
        // compare aiSettingsDataUpdated data to aiSettingsData data
      }
    }
  }, [aiSettingsDataUpdated]);

  const getDefaultModelData = (model) => {
    // defaultModelData
    let returnData = {};
    let key = "";
    if (model && model.key && model.key !== "") {
      key = model.key;
    }

    if (key !== "") {
      returnData = defaultModelData[key];
    }

    return returnData;
  };

  const getMissingModelDataParams = (data, defaultData) => {

    // if defaultData is empty, return data
    if (Object.keys(defaultData).length === 0) {
      return data
    }

    // if defaultData has params, check if data has params
    let returnData = {}
    // loop through defaultData
    Object.keys(defaultData).forEach((key) => {
      // if data has param, use data param
      if (data && data[key] !== undefined) {
        returnData[key] = data[key]
      }
      else {
        // if data does not have param, use defaultData param
        returnData[key] = defaultData[key]
      }
    })
    // if (clog()) console.log('getMissingModelDataParams returnData', returnData)

    return returnData

  }

  const cleanModelString = (val) => {
    // var regexPattern = /[^A-Za-z0-9]/g;
    const cleanedString = val.replace(/[^0-9a-z]/gi, "");
    return cleanedString;
  };

  const handleOnChangeStrategy = (event) => {
    const val = event.target.value;
    var foundIdx = _.findIndex(strategyList, { key: val });
    if (foundIdx > -1) {
      const foundStrategy = strategyList[foundIdx];
      setAiStrategySelected(foundStrategy);

      // add model to aiSettingsDataUpdated
      let dataUpdated = {};
      if (aiSettingsDataUpdated && aiSettingsDataUpdated.data) {
        dataUpdated = { ...aiSettingsDataUpdated.data };
      }

      let strategy = "";
      if (foundStrategy && foundStrategy.strategy && foundStrategy.strategy !== "") {
        strategy = foundStrategy.strategy;
      }

      dataUpdated.strategy = strategy;

      // update aiSettingsDataUpdated model
      setAiSettingsDataUpdated({
        ...aiSettingsDataUpdated,
        data: dataUpdated,
      });

      // get original data
      let dataOriginal = {};
      if (aiSettingsDataUpdated && aiSettingsDataUpdated.data) {
        dataOriginal = { ...aiSettingsDataUpdated.data };
      }

      let strategyOriginal = "";
      if (dataOriginal && dataOriginal.strategy && dataOriginal.strategy !== "") {
        strategyOriginal = dataOriginal.strategy;
      }

      // compare data to dataOriginal
      const isEqual = _.isEqual(strategy, strategyOriginal);

      if (isEqual === false) {
        // if different, set aiSettingsIsEditing
        setAiSettingsIsEditing({
          active: true,
          type: "strategy",
        });
      } else {
        // if same, set aiSettingsIsEditing
        setAiSettingsIsEditing({
          active: false,
          type: "",
        });
      }

      // listen to aiStrategySelected in AiQuestionPanel useEffect
      // let selected = {}
      // if (aiSettingsDataUpdated && aiSettingsDataUpdated[foundStrategy.strategy]) {
      //   selected = aiSettingsDataUpdated[foundStrategy.strategy]
      // }
      // setAiStrategy(selected)
    }
  };

  const getSelectedStrategyKey = () => {
    let selectedStrategy = "";
    if (aiStrategySelected && aiStrategySelected.key && aiStrategySelected.key !== "") {
      selectedStrategy = aiStrategySelected.key;
    }

    return selectedStrategy;
  };




  const handleOnChangeModel = (event) => {
    const val = event.target.value;

    var foundIdx = _.findIndex(modelList, { key: val });
    if (foundIdx > -1) {
      const foundModel = modelList[foundIdx];
      setAiModelSelected(foundModel);
      let selected = null;
      if (aiSettingsDataUpdated && aiSettingsDataUpdated[foundModel.key]) {
        selected = aiSettingsDataUpdated[foundModel.key];
      }

      const defaultData = getDefaultModelData(foundModel);
      if (selected === null) {
        // model data does not exist in aiSettingsDataUpdated, use default model data
        selected = defaultData;
      }
      else {
        // check for missing params
        const allParamsData = getMissingModelDataParams(selected, defaultData)
        selected = allParamsData;
      }
      setAiModel(selected);

      // add model to aiSettingsDataUpdated
      let dataUpdated = {};
      if (aiSettingsDataUpdated && aiSettingsDataUpdated.data) {
        dataUpdated = { ...aiSettingsDataUpdated.data };
      }

      let modelKey = "";
      if (foundModel && foundModel.key && foundModel.key !== "") {
        modelKey = foundModel.key;
      }
      dataUpdated.model = modelKey;

      // add model type
      let modelType = "";
      if (foundModel && foundModel.modeltype && foundModel.modeltype !== "") {
        modelType = foundModel.modeltype;
      }
      dataUpdated.modeltype = modelType;

      // check if modelKey exists as a holder
      let createModelHolder = true;
      if (aiSettingsDataUpdated && aiSettingsDataUpdated[modelKey]) {
        createModelHolder = false;
      }

      if (createModelHolder) {
        // update aiSettingsDataUpdated model
        setAiSettingsDataUpdated({
          ...aiSettingsDataUpdated,
          [modelKey]: selected,
          data: dataUpdated,
        });
      } else {
        // update aiSettingsDataUpdated model
        setAiSettingsDataUpdated({
          ...aiSettingsDataUpdated,
          data: dataUpdated,
        });
      }

      // get original data
      let dataOriginal = {};
      if (aiSettingsData && aiSettingsData.data) {
        dataOriginal = { ...aiSettingsData.data };
      }

      let modelKeyOriginal = "";
      if (dataOriginal && dataOriginal.model && dataOriginal.model !== "") {
        modelKeyOriginal = dataOriginal.model;
      }

      // compare data to dataOriginal
      const isEqual = _.isEqual(modelKey, modelKeyOriginal);
      if (isEqual === false) {
        // if different, set aiSettingsIsEditing
        setAiSettingsIsEditing({
          active: true,
          type: "model",
        });
      } else {
        // if same, set aiSettingsIsEditing
        setAiSettingsIsEditing({
          active: false,
          type: "",
        });
      }
    }
  };

  const getSelectedModelKey = () => {
    let selectedModel = "";
    if (aiModelSelected && aiModelSelected.key && aiModelSelected.key !== "") {
      selectedModel = aiModelSelected.key;
    }

    return selectedModel;
  };

  const valuetext = (value) => {
    // return `${value}°C`;
    return value;
  };

  const handleOnChangeCommitted = (modelType) => {
    // if (clog()) console.log('newValue:', newValue)
    // if (clog()) console.log('model:', model)
    // if (clog()) console.log('handleOnChangeCommitted:', aiSettingsDataUpdated)

    let key = "";
    if (modelType && modelType !== "") {
      key = modelType;
    }

    if (key !== "") {
      // update aiSettingsDataUpdated model
      setAiSettingsDataUpdated({
        ...aiSettingsDataUpdated,
        [key]: aiModel,
      });
    }
  };
  //  const handleOnChangeCommitted = (event, newValue, model, parameter) => {
  //    // if (clog()) console.log('newValue:', newValue)
  //    // if (clog()) console.log('model:', model)
  //    // if (clog()) console.log('parameter:', parameter)
  //
  //    let modelData = {}
  //    if (aiSettingsDataUpdated &&
  //      aiSettingsDataUpdated[model]
  //    ) {
  //      modelData = aiSettingsDataUpdated[model]
  //      // clone selected model data
  //      let modelDataClone = _.cloneDeep(modelData)
  //      // update clone model parameter
  //      modelDataClone[parameter] = newValue
  //      // update aiSettingsDataUpdated state
  //      setAiSettingsDataUpdated({
  //        ...aiSettingsDataUpdated,
  //        [model]: modelDataClone
  //      })
  //    }
  //  };

  const handleSliderChange = (event, newValue, parameter) => {
    // if (clog()) console.log('newValue:', newValue)
    // if (clog()) console.log('model:', model)
    // if (clog()) console.log('parameter:', parameter)
    const modelSelected = aiModelSelected

    // if (aiModel && aiModel[parameter] === undefined) {

    // }
    if (aiModel && aiModel[parameter] !== undefined) {
      const data = {
        ...aiModel,
        [parameter]: newValue,
      };
      setAiModel(data);

      let dataOriginal = {};
      if (aiModelSelected !== "" && aiModelSelected !== null && aiSettingsData && aiSettingsData[aiModelSelected.key]) {
        dataOriginal = aiSettingsData[aiModelSelected.key];
      }

      // compare data to dataOriginal
      const isEqual = _.isEqual(data, dataOriginal);
      if (isEqual === false) {
        // if different, set aiSettingsIsEditing
        setAiSettingsIsEditing({
          active: true,
          // type: "modelParams",
          type: "model",
        });
      } else {
        // if same, set aiSettingsIsEditing
        setAiSettingsIsEditing({
          active: false,
          type: "",
        });
      }
    }
  };

  const handleSliderChangeOld = (event, newValue, model, parameter) => {
    // if (clog()) console.log('newValue:', newValue)
    // if (clog()) console.log('model:', model)
    // if (clog()) console.log('parameter:', parameter)

    let modelData = {};
    if (aiSettingsDataUpdated && aiSettingsDataUpdated[model]) {
      modelData = aiSettingsDataUpdated[model];
      // clone selected model data
      let modelDataClone = _.cloneDeep(modelData);
      // update clone model parameter
      modelDataClone[parameter] = newValue;
      // update aiSettingsDataUpdated state
      setAiSettingsDataUpdated({
        ...aiSettingsDataUpdated,
        [model]: modelDataClone,
      });
    }
  };

  const getSliderValueOld = (model, parameter) => {
    // if (clog()) console.log('model:', model)
    // if (clog()) console.log('parameter:', parameter)
    // BT: might need to send default value as well in params
    let returnVal = 0;
    let modelData = {};

    if (aiSettingsDataUpdated && aiSettingsDataUpdated[model]) {
      modelData = aiSettingsDataUpdated[model];
      if (modelData && modelData[parameter]) {
        returnVal = modelData[parameter];
      }
    }
    return returnVal;
  };

  const getSliderValue = (parameter, model = null) => {
    // if (clog()) console.log('aiModel:', aiModel)
    // if (clog()) console.log('parameter:', parameter)
    // BT: might need to send default value as well in params
    let returnVal = 0;
    let modelData = {};
    if (aiModel && aiModel[parameter] !== undefined) {
      returnVal = aiModel[parameter];
    }

    return returnVal;
  };

  // const handleOnClickShowState = () => {
  //   //if (clog()) console.log('aiSettingsDataUpdated:', aiSettingsDataUpdated);
  //   if (clog()) console.log("aiModel:", aiModel);
  // };

  const isStrategyReadOnly = () => {
    let readonly = false;

    if (
      (aiSettingsIsEditing.active && aiSettingsIsEditing.type === "promptVersionChange")
    ) {
      readonly = true;
    }

    return readonly;
  };

  const isStrategyDisabled = () => {
    let disabled = false;
    if (aiAgentsIsEditing.active || aiConversationsIsEditing.active) {
      disabled = true;
    }

    if (
      (aiSettingsIsEditing.active && aiSettingsIsEditing.type !== "model") &&
      (aiSettingsIsEditing.active && aiSettingsIsEditing.type !== "promptVersionNew") &&
      (aiSettingsIsEditing.active && aiSettingsIsEditing.type !== "promptVersionChange")
    ) {
      disabled = true;
    }

    return disabled;
  };


  const isModelReadOnly = () => {
    let readonly = false;

    if (
      (aiSettingsIsEditing.active && aiSettingsIsEditing.type === "promptVersionChange")
    ) {
      readonly = true;
    }

    return readonly;
  };

  const isModelDisabled = () => {
    let disabled = false;
    if (aiAgentsIsEditing.active || aiConversationsIsEditing.active) {
      disabled = true;
    }

    if (
      (aiSettingsIsEditing.active && aiSettingsIsEditing.type !== "model") &&
      (aiSettingsIsEditing.active && aiSettingsIsEditing.type !== "promptVersionNew") &&
      (aiSettingsIsEditing.active && aiSettingsIsEditing.type !== "promptVersionChange")
    ) {
      disabled = true;
    }

    return disabled;
  };

  const isModelLabelsDisabled = () => {
    let disabled = false;
    if (aiAgentsIsEditing.active || aiConversationsIsEditing.active) {
      disabled = true;
    }

    // if (aiSettingsIsEditing.active && aiSettingsIsEditing.type !== "modelParams") {
    if (
      aiSettingsIsEditing.active &&
      aiSettingsIsEditing.type !== "model" &&
      aiSettingsIsEditing.active &&
      aiSettingsIsEditing.type !== "promptVersionChange" &&
      aiSettingsIsEditing.type !== "promptVersionNew"
    ) {
      disabled = true;
    }

    return disabled;
  };

  const isModelParamsDisabled = () => {
    let disabled = false;
    if (aiAgentsIsEditing.active || aiConversationsIsEditing.active) {
      disabled = true;
    }

    // if (aiSettingsIsEditing.active && aiSettingsIsEditing.type !== "modelParams") {
    if (
      (aiSettingsIsEditing.active && aiSettingsIsEditing.type !== "model") &&
      (aiSettingsIsEditing.active && aiSettingsIsEditing.type !== "promptVersionNew")
    ) {
      disabled = true;
    }

    return disabled;
  };

  const isModelParamsReadOnly = () => {
    let readyOnly = false;
    if (aiSettingsIsEditing.active && aiSettingsIsEditing.type === "promptVersionChange") {
      readyOnly = true;
    }

    return readyOnly;
  };

  const showModelOptions = () => {
    let selectedModel = "";
    if (aiModelSelected && aiModelSelected.key && aiModelSelected.key !== "") {
      selectedModel = aiModelSelected.key;
    }

    if (selectedModel === "claude-2") {
      // max_tokens_to_sample
      // temperature
      // top_k
      // top_p

      return (
        <Box sx={{ mt: 2 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              // p: 4
            }}
          >
            <Box flexGrow={1}>
              <Typography
                sx={{
                  color: isModelLabelsDisabled() ? "grey.400" : "grey.800",
                }}
              >
                Max Tokens To Sample
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  color: isModelLabelsDisabled() ? "grey.400" : "grey.800",
                }}
              >
                {getSliderValue("max_tokens_to_sample")}
              </Typography>
            </Box>
          </Box>

          <Slider
            aria-label="Always visible"
            getAriaValueText={valuetext}
            step={1}
            min={0}
            max={1000}
            disabled={isModelParamsDisabled()}
            // readOnly={isModelParamsReadOnly()}
            value={getSliderValue("max_tokens_to_sample")}
            onChange={(event, newValue) => handleSliderChange(event, newValue, "max_tokens_to_sample")}
            onChangeCommitted={() => handleOnChangeCommitted(selectedModel)}
            sx={{
              "&.MuiSlider-root.Mui-disabled .MuiSlider-track": {
                color: isModelParamsReadOnly() ? theme.palette.primary.main : "#f5f5f5",
              },
              "&.MuiSlider-root.Mui-disabled .MuiSlider-thumb": {
                borderColor: isModelParamsReadOnly() ? theme.palette.primary.main : "#f5f5f5",
              },
            }}
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              // p: 4
            }}
          >
            <Box flexGrow={1}>
              <Typography
                sx={{
                  color: isModelLabelsDisabled() ? "grey.400" : "grey.800",
                }}
              >
                Temperature
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  color: isModelLabelsDisabled() ? "grey.400" : "grey.800",
                }}
              >
                {getSliderValue("temperature")}
              </Typography>
            </Box>
          </Box>

          <Slider
            aria-label="Always visible"
            getAriaValueText={valuetext}
            step={0.1}
            min={0}
            max={1}
            disabled={isModelParamsDisabled()}
            // readOnly={isModelParamsReadOnly()}
            value={getSliderValue("temperature")}
            onChange={(event, newValue) => handleSliderChange(event, newValue, "temperature")}
            onChangeCommitted={() => handleOnChangeCommitted(selectedModel)}
            sx={{
              "&.MuiSlider-root.Mui-disabled .MuiSlider-track": {
                color: isModelParamsReadOnly() ? theme.palette.primary.main : "#f5f5f5",
              },
              "&.MuiSlider-root.Mui-disabled .MuiSlider-thumb": {
                borderColor: isModelParamsReadOnly() ? theme.palette.primary.main : "#f5f5f5",
              },
            }}
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              // p: 4
            }}
          >
            <Box flexGrow={1}>
              <Typography
                sx={{
                  color: isModelLabelsDisabled() ? "grey.400" : "grey.800",
                }}
              >
                Top P
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  color: isModelLabelsDisabled() ? "grey.400" : "grey.800",
                }}
              >
                {getSliderValue("top_p")}
              </Typography>
            </Box>
          </Box>

          <Slider
            aria-label="Always visible"
            getAriaValueText={valuetext}
            step={0.1}
            min={-0.9}
            max={2}
            disabled={isModelParamsDisabled()}
            // readOnly={isModelParamsReadOnly()}
            value={getSliderValue("top_p")}
            onChange={(event, newValue) => handleSliderChange(event, newValue, "top_p")}
            onChangeCommitted={() => handleOnChangeCommitted(selectedModel)}
            sx={{
              "&.MuiSlider-root.Mui-disabled .MuiSlider-track": {
                color: isModelParamsReadOnly() ? theme.palette.primary.main : "#f5f5f5",
              },
              "&.MuiSlider-root.Mui-disabled .MuiSlider-thumb": {
                borderColor: isModelParamsReadOnly() ? theme.palette.primary.main : "#f5f5f5",
              },
            }}
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              // p: 4
            }}
          >
            <Box flexGrow={1}>
              <Typography
                sx={{
                  color: isModelLabelsDisabled() ? "grey.400" : "grey.800",
                }}
              >
                Top K
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  color: isModelLabelsDisabled() ? "grey.400" : "grey.800",
                }}
              >
                {getSliderValue("top_k")}
              </Typography>
            </Box>
          </Box>

          <Slider
            aria-label="Always visible"
            getAriaValueText={valuetext}
            step={0.1}
            min={-1}
            max={2}
            disabled={isModelParamsDisabled()}
            // readOnly={isModelParamsReadOnly()}
            value={getSliderValue("top_k")}
            onChange={(event, newValue) => handleSliderChange(event, newValue, "top_k")}
            onChangeCommitted={() => handleOnChangeCommitted(selectedModel)}
            sx={{
              "&.MuiSlider-root.Mui-disabled .MuiSlider-track": {
                color: isModelParamsReadOnly() ? theme.palette.primary.main : "#f5f5f5",
              },
              "&.MuiSlider-root.Mui-disabled .MuiSlider-thumb": {
                borderColor: isModelParamsReadOnly() ? theme.palette.primary.main : "#f5f5f5",
              },
            }}
          />
        </Box>
      );
    }

    if (
      selectedModel === "gpt-3-5-turbo" ||
      selectedModel === "gpt-3-5-turbo-0301" ||
      selectedModel === "gpt-3-5-turbo-0613" ||
      selectedModel === "gpt-3-5-turbo-16k" ||
      selectedModel === "gpt-3-5-turbo-16k-0613" ||
      selectedModel === "gpt-4" ||
      selectedModel === "gpt-4o" ||
      selectedModel === "gpt-4-turbo" ||
      selectedModel === "gpt-4-0613" ||
      selectedModel === "gpt-4-1106-preview" ||
      selectedModel === "gpt-4-32k" ||
      selectedModel === "gpt-4-32k-0613"
    ) {
      // temperature: 0.7 - Defaults to 1
      // max_tokens: 256
      // top_p: 1 - Defaults to 1
      // frequency_penalty: 0 - Defaults to 0
      // presence_penalty: 0 - Defaults to 0

      return (
        <Box sx={{ mt: 2 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              // p: 4
            }}
          >
            <Box flexGrow={1}>
              <Typography
                sx={{
                  color: isModelLabelsDisabled() ? "grey.400" : "grey.800",
                }}
              >
                Temperature
              </Typography>
            </Box>
            <Box>
              {
                // <Typography>{getSliderValueOld('gpt-3-5-turbo', 'temperature')}</Typography>
              }
              <Typography
                sx={{
                  color: isModelLabelsDisabled() ? "grey.400" : "grey.800",
                }}
              >
                {getSliderValue("temperature")}
              </Typography>
            </Box>
          </Box>

          <Slider
            // sx={{ mt: 2.5 }}
            aria-label="Always visible"
            // defaultValue={1}
            // getAriaValueText={valuetext}
            getAriaValueText={valuetext}
            step={0.1}
            min={0}
            max={2}
            disabled={isModelParamsDisabled()}
            // readOnly={isModelParamsReadOnly()}
            // value={getSliderValueOld('gpt-3-5-turbo', 'temperature')}
            // onChange={(event, newValue) => handleSliderChangeOld(event, newValue, 'gpt-3-5-turbo', 'temperature')}

            value={getSliderValue("temperature")}
            onChange={(event, newValue) => handleSliderChange(event, newValue, "temperature")}
            onChangeCommitted={() => handleOnChangeCommitted(selectedModel)}
            // valueLabelDisplay="on"
            // valueLabelDisplay="auto"
            sx={{
              "&.MuiSlider-root.Mui-disabled .MuiSlider-track": {
                color: isModelParamsReadOnly() ? theme.palette.primary.main : "#f5f5f5",
              },
              "&.MuiSlider-root.Mui-disabled .MuiSlider-thumb": {
                borderColor: isModelParamsReadOnly() ? theme.palette.primary.main : "#f5f5f5",
              },
            }}
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              // p: 4
            }}
          >
            <Box flexGrow={1}>
              <Typography
                sx={{
                  color: isModelLabelsDisabled() ? "grey.400" : "grey.800",
                }}
              >
                Max Length
              </Typography>
            </Box>
            <Box>
              {
                // <Typography>{getSliderValueOld('gpt-3-5-turbo', 'max_tokens')}</Typography>
              }
              {
                <Typography
                  sx={{
                    color: isModelLabelsDisabled() ? "grey.400" : "grey.800",
                  }}
                >
                  {getSliderValue("max_tokens")}
                </Typography>
              }
            </Box>
          </Box>

          <Slider
            // sx={{ mt: 2.5 }}
            aria-label="Always visible"
            // defaultValue={256}
            getAriaValueText={valuetext}
            step={1}
            min={0}
            max={1000}
            disabled={isModelParamsDisabled()}
            // readOnly={isModelParamsReadOnly()}
            // value={getSliderValueOld('gpt-3-5-turbo', 'max_tokens')}
            // onChange={(event, newValue) => handleSliderChangeOld(event, newValue, 'gpt-3-5-turbo', 'max_tokens')}

            value={getSliderValue("max_tokens")}
            onChange={(event, newValue) => handleSliderChange(event, newValue, "max_tokens")}
            onChangeCommitted={() => handleOnChangeCommitted(selectedModel)}
            // onChangeCommitted={(event, newValue) => handleOnChangeCommitted(event, newValue, 'gpt-3-5-turbo', 'max_tokens')}
            // valueLabelDisplay="on"
            // valueLabelDisplay="auto"
            sx={{
              "&.MuiSlider-root.Mui-disabled .MuiSlider-track": {
                color: isModelParamsReadOnly() ? theme.palette.primary.main : "#f5f5f5",
              },
              "&.MuiSlider-root.Mui-disabled .MuiSlider-thumb": {
                borderColor: isModelParamsReadOnly() ? theme.palette.primary.main : "#f5f5f5",
              },
            }}
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              // p: 4
            }}
          >
            <Box flexGrow={1}>
              <Typography
                sx={{
                  color: isModelLabelsDisabled() ? "grey.400" : "grey.800",
                }}
              >
                Top P
              </Typography>
            </Box>
            <Box>
              {
                // <Typography>{getSliderValueOld('gpt-3-5-turbo', 'top_p')}</Typography>
              }
              <Typography
                sx={{
                  color: isModelLabelsDisabled() ? "grey.400" : "grey.800",
                }}
              >
                {getSliderValue("top_p")}
              </Typography>
            </Box>
          </Box>

          <Slider
            // sx={{ mt: 2.5 }}
            aria-label="Always visible"
            // defaultValue={1}
            // getAriaValueText={valuetext}
            getAriaValueText={valuetext}
            step={0.1}
            min={0}
            max={1}
            // value={getSliderValueOld('gpt-3-5-turbo', 'top_p')}
            // onChange={(event, newValue) => handleSliderChangeOld(event, newValue, 'gpt-3-5-turbo', 'top_p')}
            disabled={isModelParamsDisabled()}
            // readOnly={isModelParamsReadOnly()}
            value={getSliderValue("top_p")}
            onChange={(event, newValue) => handleSliderChange(event, newValue, "top_p")}
            onChangeCommitted={() => handleOnChangeCommitted(selectedModel)}
            // valueLabelDisplay="on"
            // valueLabelDisplay="auto"
            sx={{
              "&.MuiSlider-root.Mui-disabled .MuiSlider-track": {
                color: isModelParamsReadOnly() ? theme.palette.primary.main : "#f5f5f5",
              },
              "&.MuiSlider-root.Mui-disabled .MuiSlider-thumb": {
                borderColor: isModelParamsReadOnly() ? theme.palette.primary.main : "#f5f5f5",
              },
            }}
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              // p: 4
            }}
          >
            <Box flexGrow={1}>
              <Typography
                sx={{
                  color: isModelLabelsDisabled() ? "grey.400" : "grey.800",
                }}
              >
                Frequency Penalty
              </Typography>
            </Box>
            <Box>
              {
                // <Typography>{getSliderValueOld('gpt-3-5-turbo', 'frequency_penalty')}</Typography>
              }
              <Typography
                sx={{
                  color: isModelLabelsDisabled() ? "grey.400" : "grey.800",
                }}
              >
                {getSliderValue("frequency_penalty")}
              </Typography>
            </Box>
          </Box>

          <Slider
            // sx={{ mt: 2.5 }}
            aria-label="Always visible"
            // defaultValue={0}
            // getAriaValueText={valuetext}
            getAriaValueText={valuetext}
            step={0.1}
            min={-2}
            max={2}
            disabled={isModelParamsDisabled()}
            // readOnly={isModelParamsReadOnly()}
            // value={getSliderValueOld('gpt-3-5-turbo', 'frequency_penalty')}
            // onChange={(event, newValue) => handleSliderChangeOld(event, newValue, 'gpt-3-5-turbo', 'frequency_penalty')}

            value={getSliderValue("frequency_penalty")}
            onChange={(event, newValue) => handleSliderChange(event, newValue, "frequency_penalty")}
            onChangeCommitted={() => handleOnChangeCommitted(selectedModel)}
            // valueLabelDisplay="on"
            // valueLabelDisplay="auto"
            sx={{
              "&.MuiSlider-root.Mui-disabled .MuiSlider-track": {
                color: isModelParamsReadOnly() ? theme.palette.primary.main : "#f5f5f5",
              },
              "&.MuiSlider-root.Mui-disabled .MuiSlider-thumb": {
                borderColor: isModelParamsReadOnly() ? theme.palette.primary.main : "#f5f5f5",
              },
            }}
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              // p: 4
            }}
          >
            <Box flexGrow={1}>
              <Typography
                sx={{
                  color: isModelLabelsDisabled() ? "grey.400" : "grey.800",
                }}
              >
                Presence Penalty
              </Typography>
            </Box>
            <Box>
              {
                // <Typography>{getSliderValueOld('gpt-3-5-turbo', 'presence_penalty')}</Typography>
              }
              <Typography
                sx={{
                  color: isModelLabelsDisabled() ? "grey.400" : "grey.800",
                }}
              >
                {getSliderValue("presence_penalty")}
              </Typography>
            </Box>
          </Box>

          <Slider
            // sx={{ mt: 2.5 }}
            aria-label="Always visible"
            // defaultValue={0}
            // getAriaValueText={valuetext}
            getAriaValueText={valuetext}
            step={0.1}
            min={-2}
            max={2}
            disabled={isModelParamsDisabled()}
            // readOnly={isModelParamsReadOnly()}
            // value={getSliderValue('gpt-3-5-turbo', 'presence_penalty')}
            // onChange={(event, newValue) => handleSliderChange(event, newValue, 'gpt-3-5-turbo', 'presence_penalty')}

            value={getSliderValue("presence_penalty")}
            onChange={(event, newValue) => handleSliderChange(event, newValue, "presence_penalty")}
            onChangeCommitted={() => handleOnChangeCommitted(selectedModel)}
            // valueLabelDisplay="on"
            // valueLabelDisplay="auto"
            sx={{
              "&.MuiSlider-root.Mui-disabled .MuiSlider-track": {
                color: isModelParamsReadOnly() ? theme.palette.primary.main : "#f5f5f5",
              },
              "&.MuiSlider-root.Mui-disabled .MuiSlider-thumb": {
                borderColor: isModelParamsReadOnly() ? theme.palette.primary.main : "#f5f5f5",
              },
            }}
          />

          {
            // <Button
            //   variant="contained"
            //   sx={{ mt: "4px" }}
            //   // disabled={loading}
            //   size="small"
            //   onClick={handleOnClickShowState}
            //   disabled={isDisabled()}
            // >
            //   Check State
            // </Button>
          }
        </Box>
      );
    }

    return <></>;

    // return <Typography>Model Options</Typography>
    // return <Typography>{selectedModel}</Typography>
  };
  // const Item = styled(Paper)(({ theme }) => ({
  //   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  //   ...theme.typography.body2,
  //   padding: theme.spacing(1),
  //   textAlign: 'center',
  //   color: theme.palette.text.secondary,
  // }));

  const showVersionData = () => {
    let versionStr = "";
    if (aiSettingsDataUpdated && aiSettingsDataUpdated.version) {
      versionStr = parseInt(aiSettingsDataUpdated.version);
    }

    if (aiSettingsDataUpdated && aiSettingsDataUpdated.versionbasedon && aiSettingsDataUpdated.versionbasedon > 0) {
      versionStr = versionStr + " / " + parseInt(aiSettingsDataUpdated.versionbasedon);
    }

    return versionStr;
  };

  if (init) {
    return <LoadingSubPane spin={true} />;
  }

  debugger
  // if (aiModelSelected && aiStrategySelected) {
  if (aiModelSelected !== null && aiStrategySelected !== null) {
    return (
      <Box
        sx={{
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: theme.palette.border.normal,
          // backgroundColor: '#efefef',
          borderRadius: "4px",
          // overflow: "hidden",
          backgroundColor: theme.palette.background.paper,
          px: 2,
          pt: 2,

        }}
      >
        {
          //  aiSettingsDataUpdated && aiSettingsDataUpdated.version && (
          //    <Box
          //      sx={{
          //        mb: 2,
          //        // pl: "12px",
          //        pb: 1,
          //        // backgroundColor: "grey.100",
          //        // backgroundColor: 'primary.100',
          //        // borderRadius: "4px",
          //      }}
          //    >
          //      <TextField
          //        // margin="normal"
          //        // required
          //        fullWidth
          //        label="Version"
          //        name="Version"
          //        // disabled={true}
          //        InputProps={{
          //          readOnly: true,
          //        }}
          //        // value={aiSettingsDataUpdated.version}
          //        value={showVersionData()}
          //      />
          //    </Box>
          //  )
        }

        <FormControl
          sx={{
            // m: 1,
            // minWidth: 120
            width: "100%",
            "& .MuiInputLabel-root.MuiInputLabel-shrink": {
              // backgroundColor: theme.palette.background.bg,
              backgroundColor: theme.palette.background.paper,
            },
          }}
        >
          <InputLabel>Strategy</InputLabel>
          <Select
            // id="demo-simple-select"
            // value={age}
            label="Strategy"
            value={getSelectedStrategyKey()}
            disabled={isStrategyDisabled()}
            readOnly={isStrategyReadOnly()}
            // value={row.data && row.data.membership ? row.data.membership : ''}
            // value={getMembershipVal(row)}
            onChange={(event) => handleOnChangeStrategy(event)}
          >
            {strategyList.map((s, index) => {
              return (
                <MenuItem key={s.key} value={s.key}>
                  {s.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl
          sx={{
            mt: 3,
            // m: 1,
            // minWidth: 120
            width: "100%",
            "& .MuiInputLabel-root.MuiInputLabel-shrink": {
              // backgroundColor: theme.palette.background.bg,
              backgroundColor: theme.palette.background.paper,
            },
          }}
        >
          <InputLabel>Model</InputLabel>
          <Select
            // id="demo-simple-select"
            // value={age}
            label="Models"
            value={getSelectedModelKey()}
            disabled={isModelDisabled()}
            readOnly={isModelReadOnly()}
            // value={row.data && row.data.membership ? row.data.membership : ''}
            // value={getMembershipVal(row)}
            onChange={(event) => handleOnChangeModel(event)}
          >
            {modelList.map((m, index) => {
              return (
                <MenuItem key={m.key} value={m.key}>
                  {m.model}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        {showModelOptions()}
      </Box>
    );
  }

  return <></>;
};

export default React.memo(AiModelPanel);
