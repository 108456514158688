import React from 'react'

import { Outlet } from "react-router-dom";
import { useTheme } from '@mui/material/styles';

import {
  // AppBar,
  // useMediaQuery,
  Container,
  // Link,
  // Toolbar
} from '@mui/material';

import Header from './Header/Header'


export default function AiLayout() {

  const theme = useTheme();

  return (

    <Container
      id='AiLayout'
      name='AiLayout'
      maxWidth={false}
      disableGutters
      sx={{
        backgroundColor: theme.palette.background.bgdark,
        // paddingTop: '53px',
        paddingTop: '0px',
        // paddingLeft: '0 !important',
        // paddingRight: '0 !important',
        // bgcolor: 'background.paper',
        // boxShadow: 1,
        // borderRadius: 2,
        // p: 0,
        // minWidth: 300,
      }}
    >

    {
      // <Header />
    }

      <Outlet />

      {
        // Footer
      }
    </Container>

  )

};