import React, {
  useState,
  useEffect,
  // useCallback
} from 'react'
import PropTypes from 'prop-types';


import {
  getFirestore,
  collection,
  query,
  where,
  // orderBy
} from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import { firebase } from '../code/firebase';
import { processKeywordsUserData } from '../code/ecomacy';

import _ from 'lodash';

import {
  useTheme,
  // styled
} from '@mui/material/styles';

import {
  Box,
  Tab,
  Tabs,
  // Grid
} from '@mui/material';

import Container from '@mui/material/Container';
import GridKeywordTimelinePane from './GridKeywordTimelinePane'
// import GoogleMapPane from './GoogleMapPane'
import LoadingPane from './LoadingPane'
import GridKeywordsFinishSetupPane from './GridKeywordsFinishSetupPane'
import KeywordsSetup from './KeywordsSetup'
// import KeywordsUpdate from './KeywordsUpdate'

// import MainCard from './MainCard';
// import { getDfsLocationData } from '../code/ecomacy'

/*
import {
  getEcomacyLocationData,
  compareKeywordsEcomacyData
} from '../code/ecomacy'
*/

// import './GridKeywordsPane.css'
// const auth = getAuth(firebase)

// tab panel wrapper
function TabPanel({ children, value, index, ...other }) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`profile-tabpanel-${index}`} aria-labelledby={`profile-tab-${index}`} {...other}>
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `profile-tab-${index}`,
    'aria-controls': `profile-tabpanel-${index}`
  };
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};


const GridKeywordsPane = (props) => {
  const theme = useTheme();

  const [tabValue, setTabValue] = useState(0);

  let userDataObj = {
    userId: '',
    locationId: '',
    email: '',
    website: '',
    address: '',
    city: '',
    state: '',
    country: '',
    postalCode: '',
    googleplaceid: '',
    lat: null,
    lng: null,
    zoom: ''

  }
  if (props.userData) {
    userDataObj.userId = props.userData.userId
    userDataObj.locationId = props.userData.locationId
    userDataObj.email = props.userData.email
    // userDataObj = props.userData
  }

  const [userData, setUserData] = useState(userDataObj);
  const [timelineRange, setTimelineRange] = useState('alltime');
  const [timelineRangeTimestamp, setTimelineRangeTimestamp] = useState(0);

  /*
  let ecomacyUserDataObj = {
    data: {},
    status: 0,
    loaded: false,
  }
  const [ecomacyUserData, setEcomacyUserData] = useState(ecomacyUserDataObj);
  */
  /*
  let dataMatchObj = {
    data: {},
    match: false,
  }
  const [dataMatch, setDataMatch] = useState(dataMatchObj);
  const [dataMatchProcessed, setDataMatchProcessed] = useState(false);
  */
  // const [ecomacyLoaded, setEcomacyLoaded] = useState(false);

  const [keywordsDocData, setKeywordsDocData] = useState({});
  const [keywordsData, setKeywordsData] = useState([]);
  const [keywordsLoaded, setKeywordsLoaded] = useState(false);

  let keywordsDataObj = {}

  useEffect(() => {
    if (!_.isEmpty(keywordsDataObj) && keywordsLoaded === false) {
      // initial firestore fetch
      setKeywordsData(keywordsDataObj.keywords)
      setKeywordsLoaded(true)
      setKeywordsDocData(keywordsDataObj.docData)

      // update user data
      if (keywordsDataObj.docData.data) {
        const keywordsDocDataData = keywordsDataObj.docData.data
        // HL data
        // "address": "17 St Mary’s Road",
        // "city": "Leatherhead",
        // "state": "Surrey",
        // "country": "GB",

        const newUserData = processKeywordsUserData(userData, keywordsDocDataData)

        setUserData(newUserData)


      }
    }
    else if (!_.isEqual(keywordsData, keywordsDataObj.keywords) && keywordsLoaded === true) {
      // firestore updated
      setKeywordsData(keywordsDataObj.keywords)
      setKeywordsDocData(keywordsDataObj.docData)
    }
    //setUser({ pending: true, value: undefined })
    // fetchUser().then((fetchedUser) => {
    //  setUser({ pending: true, value: fetchedUser })
    //})
  }, [keywordsDataObj])

  /*
    useEffect(() => {
      if (!_.isEmpty(keywordsDocData)) {
        fetchLocationData()
          // make sure to catch any error
          .catch(console.error);
      }

    }, [keywordsDocData])


    const fetchLocationData = useCallback(async () => {

      if (ecomacyUserData.loaded === false) {
        // ecomacy API location data
        // const returnData = await fetch('https://yourapi.com');
        const result = await getEcomacyLocationData(userData)

        if (result.status === 200) {
          ecomacyUserDataObj = {
            data: result.data,
            error: {},
            status: result.status,
            loaded: true
          }
          setEcomacyUserData(ecomacyUserDataObj)
        }
        else {
          if (clog()) console.log('Error: ', result.error)
          ecomacyUserDataObj = {
            data: {},
            error: result.error,
            status: result.status,
            loaded: true
          }
          setEcomacyUserData(ecomacyUserDataObj)
        }

      }
    }, [])


    useEffect(() => {
      if (ecomacyUserData.loaded === true) {
        // compare keywordsDocData with ecomacyUserData to make sure address matches etc
        const result = compareKeywordsEcomacyData(ecomacyUserData, keywordsDocData)

        // temp data
        // result.match = false

        setDataMatch(result)
        setDataMatchProcessed(true)
      }

    }, [ecomacyUserData])
  */

  /*
  let dfsLocationDataObj = {
    data: {},
    status: 0,
    loaded: false,
  }
  const [dfsLocationData, setDfsLocationData] = useState(dfsLocationDataObj);
  const [dfsLoaded, setDfsLoaded] = useState(false);

  useEffect(() => {

    if (userData.googleplaceid !== '' && dfsLoaded === false) {
      fetchData(userData)
        // make sure to catch any error
        .catch(console.error);
    }


  }, [userData])

  const fetchData = useCallback(async (userDataObject) => {

    const test3 = userDataObject
    if (dfsLocationData.loaded === false) {
      // DataForSeo API location data
      const result = await getDfsLocationData(userDataObject)
      if (result.status === 200) {
        dfsLocationDataObj = {
          data: result.data,
          error: {},
          status: result.status,
          loaded: true
        }
        setDfsLocationData(dfsLocationDataObj)
      }
      else {
        if (clog()) console.log('Error: ', result.error)
        dfsLocationDataObj = {
          data: {},
          error: result.error,
          status: result.status,
          loaded: true
        }
        setDfsLocationData(dfsLocationDataObj)
      }

    }
  }, [])
  */



  useEffect(() => {
    if (keywordsLoaded) {
      const d = new Date();
      let timestamp = 0
      // const timestamp = Math.floor(Date.now() / 1000)
      if ('alltime' === timelineRange) {
        setTimelineRangeTimestamp(timestamp)
      }
      else {
        if ('12months' === timelineRange) {
          d.setMonth(d.getMonth() - 12);
        }
        if ('6months' === timelineRange) {
          d.setMonth(d.getMonth() - 6);
        }
        if ('1month' === timelineRange) {
          d.setMonth(d.getMonth() - 1);
        }

        if ('now' === timelineRange) {
          // d.setDay(d.getDay() - 1);
        }

        timestamp = Math.floor(d.getTime() / 1000)
        setTimelineRangeTimestamp(timestamp)
      }

    }

  }, [timelineRange])

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };


  const keywordsRef = collection(getFirestore(firebase), 'keywords')

  const q = query(
    keywordsRef,
    where('userid', '==', userData.userId),
    where('eclocationid', '==', userData.locationId),

    // where('eclocationid', '==', 'xxx'),
  );
  const [value, loading, error] = useCollection(
    q,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );




  if (error) {
    return (
      <LoadingPane
        text={'Error: ' + JSON.stringify(error)}
        showSpinner={false}
      />
    )
  }

  if (loading) {
    return (
      <LoadingPane
        text={'Loading Keywords...'}
        showSpinner={true}
        progress={75}

      />
    )

    /*
    return (
      <Container maxWidth="lg">
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs variant="fullWidth" value={tabValue} onChange={handleChange} aria-label="profile tabs">
              <Tab
                key={0}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textTransform: 'capitalize'
                }}
                label={'...'}
                {...a11yProps(0)}
              />

            </Tabs>
        </Box>

        <TabPanel
          key={0}
          value={tabValue}
          index={0}
          dir={theme.direction}
        >
          <GoogleMapPane
            idx={0}
          />
        </TabPanel>
      </Container>
    )
    */
  }

  if (value) {
    // START: build data

    let sortedKeywordsData = []

    // triggers useEffect even if no data exists
    keywordsDataObj = {
      docData: {},
      keywords: []
    }

    value.docs.forEach((doc, index) => {

      const docData = doc.data()
      if (docData) {
        // let userId = ''
        // let locationId = ''
        // if (docData.userid) {
        //   userId = docData.userid
        // }
        // if (docData.eclocationid) {
        //   locationId = docData.eclocationid
        // }

        let keywords = []

        if (docData.keywords) {
          keywords = docData.keywords
          if (keywords.length > 0) {
            // sort keywords
            const alphaKeywords = _.orderBy(keywords, 'keyword', 'asc')

            // find primary index
            const primaryKeywordIdx = _.findIndex(alphaKeywords, { primary: 1 });

            // add primary keyword object first
            sortedKeywordsData.push(alphaKeywords[primaryKeywordIdx])

            //loop and add remaining keyword objects
            for (let i = 0; i < alphaKeywords.length; i++) {
              if (i !== primaryKeywordIdx) {
                sortedKeywordsData.push(alphaKeywords[i])
              }
            }
          }


          // trigger useEffect
          keywordsDataObj = {
            docData: docData,
            // userId: userId,
            // locationId: locationId,
            keywords: sortedKeywordsData
            // keywords: keywords
          }

        }

      }
    })

    // END: build data

    if (
      !_.isEmpty(keywordsDocData)
      && keywordsLoaded === true
      // && dataMatch.match === true
      // && dataMatchProcessed === true
    ) {
      if (keywordsData.length > 0) {
        return (
          <Container maxWidth="lg">

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                // variant="fullWidth"
                variant="scrollable"
                scrollButtons="auto"
                value={tabValue}
                onChange={handleChange}
                aria-label="Keywords"
                sx={{
                  mb: '-3px',
                  // borderLeft: '1px solid #cc0000',
                  '& .MuiTabs-indicator': {
                    // display: 'flex',
                    // justifyContent: 'center',
                    // backgroundColor: 'transparent',
                    display: 'none',
                    backgroundColor: '#ffffff'

                  },
                }}
              >
                {keywordsData.map(function (k, i) {
                  // build Tabs
                  const keywordTitle = k.keyword
                  return (
                    <Tab
                      key={i}
                      sx={{
                        borderRadius: 0,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textTransform: 'capitalize',

                        borderLeft: '1px solid transparent',
                        borderRight: '1px solid transparent',
                        borderBottom: '1px solid transparent',
                        '&:not(:first-of-type)': {
                          ml: '-1px'
                        },
                        '&.Mui-selected': {
                          background: theme.palette.background.paper,
                          borderLeft: `1px solid ${theme.palette.divider}`,
                          borderRight: `1px solid ${theme.palette.divider}`,
                          borderBottom: theme.palette.background.paper,
                        },
                      }}
                      // icon={<UserOutlined style={{ marginBottom: 0, marginRight: '10px' }} />}
                      label={keywordTitle}
                      {...a11yProps(i)}
                    />
                  )
                })}
              </Tabs>



            </Box>

            {keywordsData.map(function (k, i) {
              // build Tab Panel
              const title = k.keyword
              const id = k.keyworditemid
              return (
                <TabPanel
                  key={i}
                  value={tabValue}
                  index={i}
                  dir={theme.direction}
                >

                  {/*
                    <Grid container spacing={2.5}>
                      <Grid item xs={12} md={6}>
                        Col 1
                      </Grid>
                      <Grid item xs={12} md={6}>
                        Col 2
                      </Grid>
                    </Grid>

                  UserId - {userId} <br />
                  LocationId - {locationId} <br />
                  Title - {title} <br />
                  ID - {id} <br />
              */}

                  <GridKeywordTimelinePane
                    title={title}
                    keywordId={id}
                    userData={userData}
                    keywordsDocData={keywordsDocData}
                    timelineRange={timelineRange}
                    setTimelineRange={setTimelineRange}
                    timelineRangeTimestamp={timelineRangeTimestamp}
                  // parentFuncSelectKeywordScan={handleSelectKeywordScan}
                  />

                </TabPanel>
              )
            })}

          </Container>
        );
      }
      else {
        return (
          <GridKeywordsFinishSetupPane
            type={'noKeywords'}
            // text={'No Keywords added. '}
            // data={}
            showSpinner={false}
          />
        )
      }
    }
    // else if (
    //   !_.isEmpty(keywordsDocData)
    //   && keywordsLoaded === true
    //   && dataMatchProcessed === false
    // ) {
    //   return (
    //     <LoadingPane
    //       text={'Checking Keywords...'}
    //       showSpinner={true}
    //       progress={75}
    //     />
    //   )
    // }
    // else if (
    //   !_.isEmpty(keywordsDocData)
    //   && keywordsLoaded === true
    //   && dataMatchProcessed === true
    //   && dataMatch.match === false
    // ) {
    //   return (
    //     <KeywordsUpdate
    //       keywordsDocData={keywordsDocData}
    //       dataMatch={dataMatch}
    //     />
    //   )
    // }
    else if (_.isEmpty(keywordsDocData) && keywordsLoaded) {
      // no keywords doc exists
      return (
        <KeywordsSetup
          userData={userData}
        />
      )
    }

  }







};


export default React.memo(GridKeywordsPane)