import React, { useEffect, useContext, useState } from "react";

// import ReactDOM from "react-dom/client";
import {
  // BrowserRouter,
  Router,
  Routes,
  Route,
  // useNavigate
} from "react-router-dom";

import {
  getAuth,
  // signOut
} from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebase, initialiseFirebase } from "../code/firebase";
// initialiseFirebase()

import MainAdminLayout from "../layout/MainAdminLayout";
import AdminContainer from "../components/AdminContainer";
import AdminLoginContainer from "../components/AdminLoginContainer";
import AdminLoginForgotPasswordContainer from "../components/AdminLoginForgotPasswordContainer";
import AdminLoginCheckMailContainer from "../components/AdminLoginCheckMailContainer";

import LoadingPane from "../components/LoadingPane";

import MainLayout from "../layout/MainLayout";
//import TestPane from '../components/TestPane'
import GridKeywordsContainer from "../components/GridKeywordsContainer";
import SettingsContainer from "../components/SettingsContainer";
// import GridKeywordsPane from '../components/GridKeywordsPane'

import {setd, debug, setc, clog} from "../helpers/HelperFunctions";
import { AdminAuthContext } from "../helpers/AdminAuthProvider";

// import AdminAuthProviderLayout from '../helpers/AdminAuthProviderLayout';
// import { AdminAuthProvider } from '../helpers/AdminAuthProvider';
// import { getUrlParams } from '../code/ecomacy'

const auth = getAuth(firebase);

// ==============================|| ROUTING RENDER ||============================== //

export default function AdminRoutes(props) {

  const [user, loadingUser, errorUser] = useAuthState(auth);


  const { adminStatus, setAdminStatus } = useContext(AdminAuthContext);
  const { getSetAdminStatus } = useContext(AdminAuthContext);

  useEffect(() => {
    // if (clog()) console.log('Routes urlDataParams:', urlDataParams);
    // if (
    //   userDataObj.userId !== ''
    //   && userDataObj.locationId !== ''
    //   // && userDataObj.email !== ''
    //   && userLoaded === false
    // ) {
    //   setUserData(userDataObj)
    //   setUserLoaded(true)
    // }
    // else if (loaded === true && userLoaded === false) {
    //   setUserLoaded(true)
    // }
  }, []);

  useEffect(() => {
    const getUserStatus = async () => {
      const idTokenResult = await getSetAdminStatus();
      // let isAdmin = false;
      // const adminClaim = idTokenResult.claims.admin;
      // if (adminClaim) {
      //   isAdmin = true;
      // }
      // setAdminStatus({
      //   loaded: true,
      //   isAdmin: isAdmin,
      // });
    };

    if (user) {

      getUserStatus()
        // make sure to catch any error
        .catch(console.error);
    }
  }, [user]);

  // const userData = props.userData
  // const userData = {}

  // When location is logged in
  if (user && adminStatus.loaded) {
    if (adminStatus.isAdmin) {
      if (clog()) console.log("user logged in and is admin");
      return (
        <Routes>
          <Route path="/" element={<MainAdminLayout />}>
            <Route
              index
              element={<AdminContainer />}
              // element={<SettingsContainer props={userData} />}
            />
          </Route>
        </Routes>
      );
    } else {
      // when url is /admin
      if (clog()) console.log("user logged in and is not admin");
      return (
        <Routes>
          <Route path="/" element={<MainAdminLayout hideHeader />}>
            <Route
              index
              element={<AdminLoginContainer />}
              // element={<SettingsContainer props={userData} />}
            />

            {/* <Route
            path="forgotpassword"
            element={<AdminLoginForgotPasswordContainer />}
            // element={<SettingsContainer props={userData} />}
          />
          <Route
            path="checkmail"
            element={<AdminLoginCheckMailContainer />}
            // element={<SettingsContainer props={userData} />}
          /> */}
          </Route>
        </Routes>
      );
    }
  }

  if (user && adminStatus.loaded === false) {
    return <LoadingPane />;
  }

  if (loadingUser) {
    return <LoadingPane />;
  } else {
    // user is not logged in
    if (clog()) console.log("user not logged in");
    return (
      <Routes>
        <Route path="/" element={<MainAdminLayout hideHeader />}>
          <Route
            index
            element={<AdminLoginContainer />}
            // element={<SettingsContainer props={userData} />}
          />
          {
            //  <Route
            //   path="/forgotpassword"
            //   element={<AdminLoginForgotPasswordContainer />}
            //   // element={<SettingsContainer props={userData} />}
            // />
            // <Route
            //   path="checkmail"
            //   element={<AdminLoginCheckMailContainer />}
            //   // element={<SettingsContainer props={userData} />}
            // />
          }
        </Route>
      </Routes>
    );
  }

  // return (
  //     <Routes>
  //       <Route path="/" element={<MainAdminLayout />}>
  //         <Route element={<AdminAuthProviderLayout />}>
  //           <Route
  //             index
  //             element={<AdminLoginContainer />}
  //           // element={<SettingsContainer props={userData} />}
  //           />
  //         </Route>
  //       </Route>
  //     </Routes>
  // )

  // return (
  //   <Router>
  //   <Routes>
  //     <Route path="/" element={<MainAdminLayout />}>
  //       <Route element={<AdminAuthProviderLayout />}>
  //         <Route
  //           index
  //           element={<AdminLoginContainer />}
  //         // element={<SettingsContainer props={userData} />}
  //         />
  //       </Route>
  //     </Route>
  //   </Routes>
  //   </Router>
  // )
}

// return (
//   <Routes>
//     <Route path="/" element={<MainLayout />}>
//       <Route
//         index
//         element={<GridKeywordsContainer />}
//       // element={<SettingsContainer props={userData} />}
//       />
//       <Route
//         path="settings"
//         // element={<GridKeywordsContainer props={userData} />}
//         element={<SettingsContainer />}
//       />
//     </Route>
//   </Routes>
// )

// return (
//   <Routes>
//     <Route path="/" element={<App />}>
//       <Route index element={<Home />} />
//       <Route path="teams" element={<Teams />}>
//         <Route path=":teamId" element={<Team />} />
//         <Route path="new" element={<NewTeamForm />} />
//         <Route index element={<LeagueStandings />} />
//       </Route>
//     </Route>
//   </Routes>
// )
