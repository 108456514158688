import React, {
  useState,
  useEffect,
  useContext,
  // useCallback
} from "react";
import PropTypes from "prop-types";

import {
  getFirestore,
  collection,
  query,
  where,
  // orderBy
} from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { firebase } from "../code/firebase";
import { processKeywordsUserData } from "../code/ecomacy";

import { CustomerAuthContext } from "../helpers/CustomerAuthProvider";
import { AiAuthContext } from "../helpers/AiAuthProvider";
import {setd, debug, setc, clog} from "../helpers/HelperFunctions";

import _ from "lodash";

import {
  useTheme,
  // styled
} from "@mui/material/styles";

import { Box, Tab, Tabs, Typography, Button, Grid, Paper } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import { BorderRight } from "@mui/icons-material";

import Container from "@mui/material/Container";
import GridKeywordTimelinePane from "./GridKeywordTimelinePane";
import LoadingPane from "./LoadingPane";
import AiSettingsPanel from "./AiSettingsPanel";
import AiDescriptionPanel from "./AiDescriptionPanel";
import AiHistoryPanel from "./AiHistoryPanel";
import AiQuestionsPanel from "./AiQuestionsPanel";
import AiChatPanel from "./AiChatPanel";
import AiEngineerPanel from "./AiEngineerPanel";
import AiModelPanel from "./AiModelPanel";

import AiHistoryLivePanel from "./AiHistoryLivePanel";
import AiQuestionsLivePanel from "./AiQuestionsLivePanel";
import AiChatLivePanel from "./AiChatLivePanel";
import AiModelLivePanel from "./AiModelLivePanel";

// import { debug } from 'firebase-functions/logger';

// import MainCard from './MainCard';
// import { getDfsLocationData } from '../code/ecomacy'

// import './GridKeywordsPane.css'
// const auth = getAuth(firebase)

// SF event for oauth redirect
// const handleOnClick = (event) => {
//   if (clog()) console.log('handleOnClick SF event for oauth redirect')
//   const redirect = process.env.REACT_APP_OOATH_REDIRECT
//   const clientid = process.env.REACT_APP_OOATH_CLIENTID
//   const scopes = [
//     'contacts.write',
//     'contacts.readonly',
//     'conversations/message.readonly',
//     'conversations/message.write',
//     'conversations.write',
//     'conversations.readonly',
//   ]
//   const scope = scopes.join(' ')
//
//   if (clog()) console.log('redirect', redirect)
//   if (clog()) console.log('clientid', clientid)
//
//   const url = `https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=${redirect}/oauth/callback&client_id=${clientid}&scope=${scope}`
//   window.location.href = url;
// }

const AiPane = (props) => {
  const theme = useTheme();

  const { userData } = props;


  const { customerUserData, setCustomerUserData } = useContext(CustomerAuthContext);

  const { aiShowDescriptionPanel, setAiShowDescriptionPanel } = useContext(AiAuthContext);

  const { testVal, setTestVal } = useContext(AiAuthContext);

  const { aiAgentsDocs, setAiAgentsDocs } = useContext(AiAuthContext);
  const { getAiAgentsDocs } = useContext(AiAuthContext);

  const { aiAgentsSelected, setAiAgentsSelected } = useContext(AiAuthContext);
  const { aiAgentsSelectedRef } = useContext(AiAuthContext);

  const { aiAgentShowLiveConversations, setAiAgentShowLiveConversations } = useContext(AiAuthContext);

  const { aiSettingsDocs, setAiSettingsDocs } = useContext(AiAuthContext);
  // const { getAiSettingsDocs } = useContext(AiAuthContext);

  const { aiSettingsSelected, setAiSettingsSelected } = useContext(AiAuthContext);
  const { aiSettingsData, setAiSettingsData } = useContext(AiAuthContext);
  const { aiSettingsDataRef } = useContext(AiAuthContext);
  const { aiSettingsDataUpdated, setAiSettingsDataUpdated } = useContext(AiAuthContext);

  const { getAiSettings } = useContext(AiAuthContext);

  const { getAiCategoriesDoc } = useContext(AiAuthContext);
  const { aiCategoriesDoc, setAiCategoriesDoc } = useContext(AiAuthContext);
  const [initCategoriesDoc, setInitCategoriesDoc] = useState(true);

  const [init, setInit] = useState(true);
  const [initSettingsDocs, setInitSettingsDocs] = useState(true);
  const [loading, setLoading] = useState(false);

  const [initSettings, setInitSettings] = useState(true);

  const { aiQuestionsEngineerPanelRef } = useContext(AiAuthContext);

  // fetches aisettings Docs on init
  useEffect(() => {
    // declare the data fetching function
    const fetchAiAgentDocs = async () => {
      const returnData = await getAiAgentsDocs(userData);
      if (returnData && returnData.data) {
        // set in getAiAgentsDocs function
        // setAiAgentsData(returnData.data)
        // if aisettings exist, set the first one as selected
        if (returnData.data.length > 0) {
          // setAiAgentsSelected(returnData.data[0])
        }

        setInit(false);
      } else {
        setInit(false);
      }
    };

    if (init) {
      // call the function
      fetchAiAgentDocs()
        // make sure to catch any error
        .catch(console.error);
    }
  }, [init]);

  //   fetches aipresets Docs once aiagents docs are fetched
  useEffect(() => {
    // declare the data fetching function
    const fetchAiCategoriesDoc = async () => {
      // const test = customerUserData;
      if (
        customerUserData &&
        customerUserData.data &&
        customerUserData.data.aidata &&
        customerUserData.data.aidata.aicategoryid &&
        customerUserData.data.aidata.aicategoryid !== ""
      ) {
        const aiCategoryId = customerUserData.data.aidata.aicategoryid;

        const returnData = await getAiCategoriesDoc(userData, aiCategoryId);

        if (returnData && returnData.data) {
          // set in getAiCategoriesDoc function
          // setAiCategoriesDoc(returnData.data)

          setInitCategoriesDoc(false);
        } else {
          setInitCategoriesDoc(false);
        }
      } else {
        // no customerUserData aidata aicategoryid
        setInitCategoriesDoc(false);
      }
    };

    if (!init && initCategoriesDoc && aiCategoriesDoc === null) {
      // call the function
      fetchAiCategoriesDoc()
        // make sure to catch any error
        .catch(console.error);
    }
  }, [init]);

  // fetches aiSettings Doc
  // triggered by aiSettingsSelected change from null during init
  // useEffect(() => {
  //   // declare the data fetching function
  //   const fetchAiSettingsData = async () => {
  //     // const returnData = await getAiSettings(userData, aiSettingsSelected);
  //     const returnData = {};
  //     if (returnData && returnData.data) {

  //       setInitSettings(false);
  //     } else {
  //       setInitSettings(false);
  //     }
  //   };

  //   if (!init && initSettings && aiSettingsSelected !== null) {
  //     debugger
  //     // call the function
  //     fetchAiSettingsData()
  //       // make sure to catch any error
  //       .catch(console.error);
  //   }
  // }, [aiSettingsSelected, initSettings]);

  const showMainGrid = () => {

    // aiAgentShowLiveConversations is active, show live conversations
    if (aiAgentShowLiveConversations && aiAgentShowLiveConversations.active === true) {
      return (
        <Grid
          container
          spacing={3}
          sx={{
            //   pb: 3,
            px: 3,
            minHeight: "500px",
          }}
        >

          <Grid item xs>
            {/* START: Questions Panel / Chat Panel / Models Panel - was 160px */}
            <Grid container spacing={3} height="calc(100vh - 70px)">
              <Grid
                item
                // xs
                xs={2}
                maxHeight={"100%"}
                sx={{
                  minHeight: "500px",
                }}
              >
                <AiHistoryLivePanel userData={userData} />
              </Grid>
              <Grid
                // ref={aiQuestionsEngineerPanelRef}
                item
                xs={4}
                maxHeight={"100%"}
                sx={{
                  pb: 4,
                  display: "flex",
                  flexDirection: "column",
                  minHeight: "500px",
                }}
              // sx={{backgroundColor: 'primary.main'}}
              >
                <AiQuestionsLivePanel userData={userData} />
              </Grid>

              <Grid
                item
                xs={4}
                maxHeight={"100%"}
                sx={{
                  pb: 4,
                  minHeight: "500px",
                }}
              >
                <AiChatLivePanel userData={userData} />
              </Grid>
              <Grid
                item
                // xs
                xs={2}
                maxHeight={"100%"}
                // minWidth="200px"
                // maxWidth="200px"
                sx={{
                  minHeight: "500px",
                  // minWidth: "200px !important",
                }}
              >
                <AiModelLivePanel userData={userData} />
              </Grid>
            </Grid>
            {/* END: Questions Panel / Chat Panel / Models Panel */}
          </Grid>
        </Grid>
      );
    }
    else {
      // show instructions
      if (aiAgentsSelected === null && aiSettingsSelected === null && aiCategoriesDoc !== null) {
        return (
          <Grid
            container
            spacing={3}
            sx={{
              //   pb: 3,
              px: 3,
            }}
          >
            <Grid item xs>
              {/* START: Instructions Panel  - was 160px */}
              <Grid container spacing={3} height="calc(100vh - 70px)">
                <Grid
                  item
                  xs
                  //xs={1}
                  maxHeight={"100%"}
                // sx={{backgroundColor: 'primary.main'}}
                >
                  <Box
                    sx={{
                      height: "100%",
                      display: "flex",
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center",
                      // backgroundColor: 'primary.main',
                    }}
                  >
                    <Typography variant="h4" component="h1">
                      Select an AI Agent to get started.
                    </Typography>
                  </Box>
                </Grid>
                {/* END: Instructions Panel */}
              </Grid>
            </Grid>
          </Grid>
        );
      } else if (aiAgentsSelected !== null && aiSettingsSelected === null) {
        return (
          <Grid
            container
            spacing={3}
            sx={{
              //   pb: 3,
              px: 3,
            }}
          >
            <Grid item xs>
              {/* START: Instructions Panel  - was 160px */}
              <Grid container spacing={3} height="calc(100vh - 70px)">
                <Grid
                  item
                  xs
                  //xs={1}
                  maxHeight={"100%"}
                // sx={{backgroundColor: 'primary.main'}}
                >
                  <Box
                    sx={{
                      height: "100%",
                      display: "flex",
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center",
                      // backgroundColor: 'primary.main',
                    }}
                  >
                    <Typography variant="h4" component="h1">
                      Add or select a Setting.
                    </Typography>
                  </Box>
                </Grid>
                {/* END: Instructions Panel */}
              </Grid>
            </Grid>
          </Grid>
        );
      }
      // show controls
      else if (aiAgentsSelected !== null && aiSettingsSelected !== null) {
        return (
          <Grid
            container
            spacing={3}
            sx={{
              //   pb: 3,
              px: 3,
              minHeight: "500px",
            }}
          >
            {/* START: Description Panel */}

            {aiShowDescriptionPanel && (
              <Grid
                item
                xs={3}
                sx={{
                  // backgroundColor: 'primary.main',
                  borderRightWidth: "1px",
                  borderRightStyle: "solid",
                  borderRightColor: theme.palette.border.normal,
                }}
              >
                <AiDescriptionPanel userData={userData} />
              </Grid>
            )}

            {/* START: Description */}

            <Grid item xs>
              {/* START: Questions Panel / Chat Panel / Models Panel - was 160px */}
              <Grid container spacing={3} height="calc(100vh - 70px)">
                <Grid
                  item
                  // xs
                  xs={2}
                  maxHeight={"100%"}
                  sx={{
                    minHeight: "500px",
                  }}
                >
                  <AiHistoryPanel userData={userData} />
                </Grid>
                <Grid
                  ref={aiQuestionsEngineerPanelRef}
                  item
                  xs={4}
                  maxHeight={"100%"}
                  sx={{
                    pb: 4,
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "500px",
                  }}
                // sx={{backgroundColor: 'primary.main'}}
                >
                  <AiQuestionsPanel userData={userData} />
                  <AiEngineerPanel userData={userData} />
                </Grid>

                <Grid
                  item
                  xs={4}
                  maxHeight={"100%"}
                  sx={{
                    pb: 4,
                    minHeight: "500px",
                  }}
                >
                  <AiChatPanel userData={userData} />
                </Grid>
                <Grid
                  item
                  // xs
                  xs={2}
                  maxHeight={"100%"}
                  // minWidth="200px"
                  // maxWidth="200px"
                  sx={{
                    minHeight: "500px",
                    // minWidth: "200px !important",
                  }}
                >
                  <AiModelPanel userData={userData} />
                </Grid>
              </Grid>
              {/* END: Questions Panel / Chat Panel / Models Panel */}
            </Grid>
          </Grid>
        );
      } else {
        return <Grid></Grid>;
      }
    }
  };

  // if (aiSettingsData) {
  // if (aiAgentsDocs && aiSettingsDocs) {
  if (aiAgentsDocs) {
    return (
      <Container
        // maxWidth="lg"
        maxWidth={false}
        disableGutters
        sx={{
          minWidth: "1100px !important",
          // mt: 4,
          // backgroundColor: "#fff",
        }}
      >
        {/* START: Top Toolbar */}
        <Grid
          container
          spacing={0}
          sx={{
            pb: 3,
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              // backgroundColor: 'primary.main',
              borderBottomWidth: "1px",
              borderBottomStyle: "solid",
              borderBottomColor: theme.palette.border.normal,
            }}
          >
            <AiSettingsPanel userData={userData} />
          </Grid>
        </Grid>
        {/* END: Top Toolbar */}

        {showMainGrid()}

        {
          //   <Button
          //     sx={{
          //       mr: 2
          //     }}
          //     variant="contained"
          //     // sx={buttonSx}
          //     // disabled={loading}
          //     // size="small"
          //     onClick={handleOnClick}
          //     // disabled={isDisabled()}
          //     startIcon={<AddIcon />}
          //   >
          //     Click Me
          //   </Button>
        }
      </Container>
    );
  }

  return (
    <LoadingPane
    // userData={userData}
    />
  );
};

export default React.memo(AiPane);
