import React, { createContext, useState, useRef, useContext } from "react";



const setd = (val) => {
  localStorage.setItem("debug", val);
  if (clog()) console.log("debug set to: ", val);
  return;
};

const setc = (val) => {
  localStorage.setItem("clog", val);
  if (clog()) console.log("clog set to: ", val);
  return;
};

const debug = () => {
  let debug = false;
  const localStorageDebug = localStorage.getItem("debug");
  if (localStorageDebug && localStorageDebug === "1") {
    debug = true;
  }

  return debug;
};

const clog = () => {
  let clog = false;
  const localStorageClog = localStorage.getItem("clog");
  if (localStorageClog && localStorageClog === "1") {
    clog = true;
  }
  return clog;
};

const clogOld = (text, val, type = "normal") => {
  let showConsoleLog = false;

  const localStorageClog = localStorage.getItem("clog");
  if (localStorageClog && localStorageClog === "1") {
    showConsoleLog = true;
  }

  // if (window.clog && window.clog === '1') {
  //   showConsoleLog = true;
  // }

  if (showConsoleLog) {
    let t = "";
    if (text && text !== "") {
      t = text + ": ";
    }

    let v = "";
    if (val && val !== "") {
      v = val;
    }

    if (type === "normal") {
      if (v !== "") {
        if (clog()) console.log(t, val);
      } else {
        if (clog()) console.log(t);
      }
    } else if (type === "warn") {
      if (v !== "") {
        console.warn(t, val);
      } else {
        console.warn(t);
      }
    } else if (type === "error") {
      if (v !== "") {
        console.error(t, val);
      } else {
        console.error(t);
      }
    }
  }
};
// attach to window
// window.setd = setd;
// window.setc = setc;
// window.debug = debug;
// window.clog = clog;


export {
  setd,
  setc,
  debug,
  clog,
  clogOld
}

