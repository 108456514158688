import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import PropTypes from "prop-types";

import {
  getFirestore,
  collection,
  query,
  where,
  // orderBy
} from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { firebase } from "../code/firebase";
import { processKeywordsUserData } from "../code/ecomacy";

import { RemoteConfigAuthContext } from "../helpers/RemoteConfigAuthProvider";
import { CustomerAuthContext } from "../helpers/CustomerAuthProvider";
import { AiAuthContext } from "../helpers/AiAuthProvider";
import { setd, debug, setc, clog } from "../helpers/HelperFunctions";

import _ from "lodash";

import { useTheme, styled } from "@mui/material/styles";

import {
  Box,
  Tab,
  Tabs,
  Typography,
  Button,
  IconButton,
  Grid,
  Paper,
  Menu,
  MenuItem,
  ListSubheader,
  InputAdornment,
  Divider,
  // Tooltip,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import TextField from "@mui/material/TextField";

import SendIcon from "@mui/icons-material/Send";
import RefreshIcon from "@mui/icons-material/Refresh";
import SyncIcon from "@mui/icons-material/Sync";
import SettingsIcon from "@mui/icons-material/Settings";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CircleIcon from "@mui/icons-material/Circle";

// import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';

import Container from "@mui/material/Container";
import GridKeywordTimelinePane from "./GridKeywordTimelinePane";
import LoadingSubPane from "./LoadingSubPane";
import { RunCircle } from "@mui/icons-material";

// import MainCard from './MainCard';
// import { getDfsLocationData } from '../code/ecomacy'

// import './GridKeywordsPane.css'
// const auth = getAuth(firebase)

/*
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    // color: theme.palette.common.black,
    color: theme.palette.primary.main,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: theme.palette.primary.main,
  },
}));

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    // fontSize: 11,
  },
}));
*/
const BorderTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    // boxShadow: theme.shadows[1],
  },
  [`& .${tooltipClasses.arrow}::before`]: {
    color: theme.palette.common.white,
    // boxShadow: theme.shadows[1],

    border: `1px solid ${theme.palette.primary.main}`,
    boxSizing: "border-box",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    // boxShadow: theme.shadows[1],
    fontSize: "14px",
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

const ApplySettingsMenu = (props) => {
  const { userData } = props;


  const { aiSettingsData, setAiSettingsData } = useContext(AiAuthContext);
  const { aiSettingsDataUpdated, setAiSettingsDataUpdated } = useContext(AiAuthContext);
  const { aiSettingsDataRef } = useContext(AiAuthContext);
  const { aiSettingsIndexIdSelected, setAiSettingsIndexIdSelected } = useContext(AiAuthContext);

  const { aiConversation, setAiConversation } = useContext(AiAuthContext);
  const { aiConversationDuplicatedRefresh, setAiConversationDuplicatedRefresh } = useContext(AiAuthContext);
  const { duplicateAiConversationWithPrevSettings } = useContext(AiAuthContext);

  const { aiMessages, setAiMessages } = useContext(AiAuthContext);

  const { saveAiSettings } = useContext(AiAuthContext);

  const {
    conversation,
    // version,
    settingsMessage,
    settingsData,
    disabled,
    menuDisabled,
  } = props;

  let isCurrentDisabled = false;
  let isNewDisabled = false;
  if (disabled !== undefined) {
    isCurrentDisabled = disabled;
    isNewDisabled = disabled;
  }

  let isMenuDisabled = false;
  if (menuDisabled !== undefined) {
    isMenuDisabled = menuDisabled;
  }

  let version = null;
  if (settingsMessage && settingsMessage.data && settingsMessage.data.version) {
    version = settingsMessage.data.version;
  }

  let versionBasedOn = null;
  if (settingsMessage && settingsMessage.data && settingsMessage.data.versionbasedon) {
    versionBasedOn = settingsMessage.data.versionbasedon;
  }

  let currentVersion = null;
  if (settingsData && settingsData.version) {
    currentVersion = settingsData.version;
  }

  if (currentVersion !== null && version !== null && currentVersion === version) {
    isCurrentDisabled = true;
  }

  const handleIsCurrentDisabled = () => {
    let disabled = false;
    if (currentVersion !== null && version !== null && currentVersion === version) {
      disabled = true;
    }

    return disabled;
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // based on handleOnClickSave in AiSettingsPanel
  const handleApplyToCurrentConversation = async (event) => {
    let result;
    if (clog()) console.log("handleOnClickSave");
    const test = aiConversation;

    // let settingsJson = null;
    // if (settingsMessage && settingsMessage.aisettings && settingsMessage.aisettings !== "") {
    //   settingsJson = settingsMessage.aisettings;
    // }

    let settings = null;
    if (settingsMessage && settingsMessage.aisettings) {
      settings = settingsMessage.aisettings;
    }

    // if (settingsJson) {
    if (settings) {
      //
      // const aiSettingsDataPrev = JSON.parse(settingsJson);
      const aiSettingsDataPrev = settings;

      // BT aisettings
      // same trigger as Save in AiSettingsPanel except aiSettingsDataPrev is used instead of aiSettingsDataUpdated
      // this is old and missing aiAgentsSelected & promptVersionSelected??
      result = await saveAiSettings(userData, aiConversation, aiSettingsDataPrev);
      if (result && result.data) {
        const { data } = result;

        // BT aisettings
        // setAiConversation <- always up to date in getAiConversation subscription
        setAiSettingsData(data);
        setAiSettingsDataUpdated(data);
        // set ref for comparisons after settings save
        aiSettingsDataRef.current = data;

        // trigger useEffect in AiSettingsPanel to load new settings
        // setAiSettingsDocsRefresh(val)
      }
    }

    setAnchorEl(null);
  };

  // From AiHistoryPanel
  //  const handleOnClickAiConversationsAdd = async () => {
  //    const result = await duplicateAiConversation();
  //
  //    if (result && result.data && result.data.aiconversationid && result.data.aiconversationid !== "") {
  //      // const val = result.data.aiconversationid
  //
  //      // trigger useEffect in AiHistoryPanel to activate latest conversation
  //      setAiConversationDuplicatedRefresh(true);
  //    }
  //
  //    // deactivate edit mode
  //    setAiConversationsIsEditing({
  //      active: false,
  //      type: "",
  //    });
  //  };

  const handleApplyToNewConversation = async () => {
    const test1 = conversation;
    const test2 = settingsMessage;
    const test3 = settingsData;

    if (clog()) console.log("handleApplyToNewConversation");

    // like duplicateAiConversation in AiHistoryPanel but using settings from selected aisettings json
    const result = await duplicateAiConversationWithPrevSettings(settingsMessage);

    if (result && result.data && result.data.aiconversationid && result.data.aiconversationid !== "") {
      // const val = result.data.aiconversationid
      // trigger useEffect in AiHistoryPanel to activate latest conversation
      setAiConversationDuplicatedRefresh(true);
    }

    // const result = await savePrevAiSettingsToCurrent(conversation, settingsMessage)
    setAnchorEl(null);
  };

  let helpString = "";
  if (version) {
    helpString = `${version}`;
  }

  if (version && versionBasedOn && versionBasedOn > 0) {
    helpString = helpString + ` / ${versionBasedOn}`;
  }

  return (
    <Box>
      <BorderTooltip title={helpString} placement="left" arrow leaveDelay={200}>
        <IconButton
          id="apply-settings-menu"
          color="primary"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          disabled={isMenuDisabled}
        >
          <CircleIcon
            sx={{
              fontSize: "10px",
              color: "grey.500",
            }}
          />
        </IconButton>
      </BorderTooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      // anchorOrigin={{
      //   vertical: 'top',
      //   horizontal: 'left',
      // }}
      // transformOrigin={{
      //   vertical: 'top',
      //   horizontal: 'left',
      // }}
      >
        <ListSubheader
          color="primary"
          sx={{
            "&.MuiListSubheader-colorPrimary": {
              lineHeight: "38px",
              // backgroundColor: '#efefef',
              // textTransform: 'uppercase',
            },
          }}
        >
          {version}
        </ListSubheader>
        <MenuItem
          onClick={handleApplyToCurrentConversation}
          // disabled={isCurrentDisabled}
          disabled={handleIsCurrentDisabled()}
        >
          Apply To Current
        </MenuItem>
        <MenuItem onClick={handleApplyToNewConversation} disabled={isNewDisabled}>
          Create New
        </MenuItem>
      </Menu>
    </Box>
  );
};

const AiChatPanel = (props) => {
  const { userData } = props;
  const theme = useTheme();


  const { rc } = useContext(RemoteConfigAuthContext);
  const { customerUserData, setCustomerUserData } = useContext(CustomerAuthContext);

  const { testVal, setTestVal } = useContext(AiAuthContext);


  const { aiAgentsSelected, setAiAgentsSelected } = useContext(AiAuthContext);
  const { aiAgentsIsRemoving, setAiAgentsIsRemoving } = useContext(AiAuthContext);

  const { aiSettingsData, setAiSettingsData } = useContext(AiAuthContext);
  const { aiSettingsDataUpdated, setAiSettingsDataUpdated } = useContext(AiAuthContext);
  const { aiSettingsDataRef } = useContext(AiAuthContext);
  const { aiSettingsIndexIdSelected, setAiSettingsIndexIdSelected } = useContext(AiAuthContext);

  const { aiStrategySelectedRefresh, setAiStrategySelectedRefresh } = useContext(AiAuthContext);
  const { aiModelSelectedRefresh, setAiModelSelectedRefresh } = useContext(AiAuthContext);

  const { aiChatInput, setAiChatInput } = useContext(AiAuthContext);

  const { postAiMessage } = useContext(AiAuthContext);
  const { resetAiMessages } = useContext(AiAuthContext);
  const { aiConversationsIsSaving, setAiConversationsIsSaving } = useContext(AiAuthContext);
  const { aiConversationsIsRemoving, setAiConversationsIsRemoving } = useContext(AiAuthContext);

  // const { aiChatMessagesData, setAiChatMessagesData } = useContext(AiAuthContext);

  const { aiSettingsSelected, setAiSettingsSelected } = useContext(AiAuthContext);
  const { aiConversationsSelected, setAiConversationsSelected } = useContext(AiAuthContext);
  const { aiConversation, setAiConversation } = useContext(AiAuthContext);
  const { aiMessages, setAiMessages } = useContext(AiAuthContext);
  const [aiMessagesUserSending, setAiMessagesUserSending] = useState(false);

  const { aiSettingsItemsSwitchPromptsSelected, setAiSettingsItemsSwitchPromptsSelected, } = useContext(AiAuthContext);


  const { aiConversationMessageCount } = useContext(AiAuthContext);
  // const { getAiConversation } = useContext(AiAuthContext);
  const { getAiConversationAndMessages } = useContext(AiAuthContext);

  const { refreshAiConversationSettingsData, setRefreshAiConversationSettingsData } = useContext(AiAuthContext);
  const { duplicateAiConversation } = useContext(AiAuthContext);
  const { aiConversationDuplicatedRefresh, setAiConversationDuplicatedRefresh } = useContext(AiAuthContext);

  const { aiAgentsIsEditing, setAiAgentsIsEditing } = useContext(AiAuthContext);
  const { aiSettingsIsEditing, setAiSettingsIsEditing } = useContext(AiAuthContext);
  const { aiConversationsIsEditing, setAiConversationsIsEditing } = useContext(AiAuthContext);
  const { aiConversationFetchData, setAiConversationFetchData } = useContext(AiAuthContext);

  const { checkCustomerApiKeys } = useContext(AiAuthContext);
  const { showAiCustomerSettingsDialog, setShowAiCustomerSettingsDialog } = useContext(AiAuthContext);

  const [init, setInit] = useState(true);

  const messageCount = useRef(0);
  const scrollRef = useRef();

  // if (clog()) console.log('AiChatPanel aiSettingsData', aiSettingsData);

  // initial load
  useEffect(() => {
    if (init && aiConversationsSelected) {
      fetchAiConversationData()
        // make sure to catch any error
        .catch(console.error);
      setInit(false);
    }
  }, [init, aiConversationsSelected]);

  // aiConversationsSelected is changed
  useEffect(() => {
    if (!init && aiConversationFetchData === false) {
      // aiConversationSelected data has been updated but is still the same conversation
      // happens when editing the name of a selected conversation in HistoryPanel
      setAiConversationFetchData(true);
    } else if (!init && aiConversationsSelected) {
      fetchAiConversationData()
        // make sure to catch any error
        .catch(console.error);
      // setInit(false)
    } else if (!init && aiConversationsSelected === null) {
      // no conversation exists, reset selected aisettings data
      setAiSettingsData(null);
      setAiSettingsDataUpdated(null);
      aiSettingsDataRef.current = null;
      // trigger useEffect in AiModelPanel
      setAiModelSelectedRefresh(true);
      // trigger useEffect in AiQuestionsPanel
      setAiStrategySelectedRefresh(true);


    }
  }, [aiConversationsSelected]);

  const fetchAiConversationData = useCallback(async () => {
    // BT ZZZ
    const result = await getAiConversationAndMessages(userData, aiConversationsSelected);
    // BT debugger

    // trigger useEffect in AiHistoryPanel
    setRefreshAiConversationSettingsData(true);
    if (result && result.aiconversation && result.aiconversation.data) {
      //   setAiConversation(result.data)
      if (result.aimessages.data && result.aimessages.data.length > 0) {
        // setAiChatMessagesData(result.data.messages)
        messageCount.current = result.aimessages.data.length;
      }
      else {
        messageCount.current = 0;
      }
    }
  }, [getAiConversationAndMessages]);



  // aiSettingsIndexIdSelected is changed
  useEffect(() => {

    if (!init) {

      const test1 = aiConversationsSelected
      const test2 = aiSettingsIndexIdSelected
      const test3 = aiSettingsData

      let aiSettingsDataSettingsIndexId = "";
      if (aiSettingsData && aiSettingsData.aisettingsindexid) {
        aiSettingsDataSettingsIndexId = aiSettingsData.aisettingsindexid;
      }

      let aiSettingsIndexIdSelectedSettingsIndexId = "";
      if (aiSettingsIndexIdSelected.aisettingsindexid) {
        aiSettingsIndexIdSelectedSettingsIndexId = aiSettingsIndexIdSelected.aisettingsindexid;
      }

      // if aisettingsindexid is different then load new aisettings
      if (
        aiSettingsIndexIdSelectedSettingsIndexId !== '' &&
        aiSettingsIndexIdSelectedSettingsIndexId !== aiSettingsDataSettingsIndexId
      ) {
        const conversationSelectedAiSettings = aiConversationsSelected.aisettings;
        // find aisettings in aiConversationsSelected
        const settingsData = _.find(conversationSelectedAiSettings, { aisettingsindexid: aiSettingsIndexIdSelectedSettingsIndexId });

        if (settingsData) {
          setAiSettingsData(settingsData);
          setAiSettingsDataUpdated(settingsData);
          aiSettingsDataRef.current = settingsData;
          // trigger useEffect in AiModelPanel
          setAiModelSelectedRefresh(true);
          // trigger useEffect in AiQuestionsPanel
          setAiStrategySelectedRefresh(true);
        }
        else {
          // no conversation exists, reset selected aisettings data
          setAiSettingsData(null);
          setAiSettingsDataUpdated(null);
          aiSettingsDataRef.current = null;
          // trigger useEffect in AiModelPanel
          setAiModelSelectedRefresh(true);
          // trigger useEffect in AiQuestionsPanel
          setAiStrategySelectedRefresh(true);
        }
      }

    }

  }, [aiSettingsIndexIdSelected]);




  //// START: Scroll Into View
  // const scrollRef = useRef(); <- defined with states etc

  // run after every render
  useEffect(() => {
    scrollRefIntoView()
  });

  const scrollRefIntoView = () => {
    // if element exists then scroll into view
    if (scrollRef.current) {
      // param: defaultVal
      //  ...options
      // behavior:"auto",
      //   auto | smooth
      // block: "start",
      //   start | center | end | nearest
      // inline:"nearest"
      //   start | center | end | nearest
      const opts = {
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      }
      scrollRef.current.scrollIntoView(opts)
    }
  };

  const isScollRef = (type, index = -1) => {
    let isSelected = false

    // aiMessagesUserSending <- human sent message, waiting for it to appear
    // isChatWaitingForAiResponse <- human message appeared, waiting for AI response

    const isWaiting = isChatWaitingForAiResponse()
    if (isWaiting === false && aiMessagesUserSending === false) {
      if ("message" === type) {
        if (index === aiMessages.length - 1) {
          isSelected = true
        }
      }
    }

    return isSelected

  }
  //// END: Scroll Into View

  useEffect(() => {
    if (aiMessages && aiMessages.length !== messageCount.current) {
      // update message count ref
      messageCount.current = aiMessages.length;
      if (aiMessagesUserSending) {
        setAiMessagesUserSending(false)
      }
    }
  }, [aiMessages]);


  const handleOnChangeChatInput = (e) => {
    // if (clog()) console.log('handleOnChangeChatInput:', e.target.value);
    setAiChatInput(e.target.value);
  };

  const handleOnSubmitSend = async () => {

    // check if chat api keys are set
    const checkApiKeys = checkCustomerApiKeys('chat', customerUserData, aiSettingsDataUpdated)
    if (checkApiKeys.show) {
      // open settings dialog
      setShowAiCustomerSettingsDialog({
        show: checkApiKeys.show,
        type: checkApiKeys.type,
        message: "chat",
      })
      return
    }

    // call api
    setAiChatInput("");
    setAiMessagesUserSending(true)

    // set freeze state
    // setAiChatInputFreeze(true)
    // "message": {
    //   "type": "ai",
    //   "content": "\n\nHello there, how may I assist you today?"
    // },
    let userMessageData = [
      {
        type: "human",
        content: aiChatInput,
      },
    ];




    // SF
    const { data, error } = await postAiMessage(userData, aiSettingsSelected, aiConversationsSelected, userMessageData, aiSettingsItemsSwitchPromptsSelected);

    // set freeze state
    // setAiChatInputFreeze(false)

    // SF: messages stuff
    if (clog()) console.log("handleOnSubmitSend:", userMessageData);
  };

  const handleOnClickReset = async () => {

    // check if chat api keys are set
    const checkApiKeys = checkCustomerApiKeys('chat', customerUserData, aiSettingsDataUpdated)
    if (checkApiKeys.show) {
      // open settings dialog
      setShowAiCustomerSettingsDialog({
        show: checkApiKeys.show,
        type: checkApiKeys.type,
        message: "chat",
      })
      return
    }


    setAiConversationsIsSaving(true);
    if (clog()) console.log("handleOnClickReset");
    // const result = await duplicateAiSettings()
    const resetMessageData = {
      type: "aireset",
      content: "",
    };

    const result = await resetAiMessages(userData, aiConversationsSelected, resetMessageData);

    if (result && result.data && result.data.aiconversationid && result.data.aiconversationid !== "") {
      // const val = result.data.aiconversationid;
    }
    setAiConversationsIsSaving(false);
  };

  const isChatInputDisabled = () => {
    let disabled = false;

    if (aiAgentsIsEditing.active || aiConversationsIsEditing.active || aiConversationsIsSaving) {
      disabled = true;
    }

    if (aiSettingsIsEditing.active && aiSettingsIsEditing.type !== "improvePromptProcessing") {
      disabled = true;
    }

    if (aiConversation && aiConversation.improveprocessing) {
      disabled = true;
    }

    // check variables
    let agentInputs = []
    if (aiAgentsSelected && aiAgentsSelected.inputs && aiAgentsSelected.inputs.length > 0) {
      agentInputs = aiAgentsSelected.inputs
    }

    let missingInputValue = false
    if (agentInputs.length > 0) {
      agentInputs.forEach((input) => {
        if (input.value === "") {
          missingInputValue = true
        }
      })
    }

    if (missingInputValue) {
      disabled = true
    }

    return disabled;
  };

  const isSendDisabled = () => {
    let disabled = true;
    if (aiChatInput && aiChatInput !== "") {
      disabled = false;
    }

    if (aiAgentsIsEditing.active || aiConversationsIsEditing.active || aiConversationsIsSaving) {
      disabled = true;
    }

    if (aiSettingsIsEditing.active && aiSettingsIsEditing.type !== "improvePromptProcessing") {
      disabled = true;
    }

    if (aiConversation && aiConversation.improveprocessing) {
      disabled = true;
    }

    // check variables
    let agentInputs = []
    if (aiAgentsSelected && aiAgentsSelected.inputs && aiAgentsSelected.inputs.length > 0) {
      agentInputs = aiAgentsSelected.inputs
    }

    let missingInputValue = false
    if (agentInputs.length > 0) {
      agentInputs.forEach((input) => {
        if (input.value === "") {
          missingInputValue = true
        }
      })
    }

    if (missingInputValue) {
      disabled = true
    }

    return disabled;
  };

  const isResetDisabled = () => {
    let disabled = false;

    if (aiAgentsIsEditing.active || aiSettingsIsEditing.active || aiConversationsIsEditing.active || aiConversationsIsSaving) {
      disabled = true;
    }

    // check for no messages
    let mesCount = 0;
    if (aiMessages && aiMessages.length > 0) {
      mesCount = aiMessages.length;
    }

    if (mesCount === 0) {
      disabled = true;
    }

    // check if rest is last message
    if (aiMessages && aiMessages.length > 0) {
      const lastMessage = aiMessages[aiMessages.length - 1];
      if (lastMessage.type === "aireset") {
        disabled = true;
      }
    }

    // check variables
    let agentInputs = []
    if (aiAgentsSelected && aiAgentsSelected.inputs && aiAgentsSelected.inputs.length > 0) {
      agentInputs = aiAgentsSelected.inputs
    }

    let missingInputValue = false
    if (agentInputs.length > 0) {
      agentInputs.forEach((input) => {
        if (input.value === "") {
          missingInputValue = true
        }
      })
    }

    if (missingInputValue) {
      disabled = true
    }

    return disabled;
  };

  const isSettingsMenuDisabled = () => {
    let disabled = false;

    if (aiAgentsIsEditing.active || aiSettingsIsEditing.active || aiConversationsIsEditing.active) {
      disabled = true;
    }

    return disabled;
  };

  const showNoMessages = () => {
    let messageCount = 0;
    if (aiMessages && aiMessages.length > 0) {
      messageCount = aiMessages.length;
    }


    if (aiMessagesUserSending === false) {

      if (messageCount === 0 || (messageCount === 1 && aiMessages[0].type === "aisettings")) {
        return (
          <Box
            sx={{
              // mt: 2,
              display: "flex",
              flexDirection: "column",
              width: "100%",
              // height: "100%",
              alignItems: 'center',
              justifyContent: "center",
            }}
          >
            <Box
              name={"roleIcon"}
              sx={{
                mt: -4,
                width: "100px",
                pt: 2,
                // backgroundColor: '#cc0000',
                display: "flex",
                // alignItems: 'center',
                justifyContent: "center",
              }}
            >
              <SupportAgentIcon
                sx={{
                  fontSize: "100px",
                  color: "grey.300",
                }}
              // color='primary'
              />
            </Box>
            <Typography
              align="center"
              sx={{
                fontSize: "18px",
                color: "grey.600",
              }}
            >
              Send a message to start conversation
            </Typography>
          </Box>
        );
      } else {
        return <></>;
      }
    }
    else {
      return <></>;
    }
  };

  // const Item = styled(Paper)(({ theme }) => ({
  //   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  //   ...theme.typography.body2,
  //   padding: theme.spacing(1),
  //   textAlign: 'center',
  //   color: theme.palette.text.secondary,
  // }));

  // if (clog()) console.log('AiChatPanel aiChatMessagesData', aiChatMessagesData);

  const isChatWaitingForAiResponse = () => {

    let waiting = false;
    // last message is human so waiting for ai response
    if (aiMessages && aiMessages.length > 0 && aiMessages[aiMessages.length - 1].type === "human") {
      waiting = true
    }

    return waiting
  }
  const showChatWaitingResponse = () => {
    // const m = aiEngineersSelected;
    // if (aiEngineerMessages && aiEngineerMessages.length > 0) {
    //   return <></>;
    // }
    const isWaiting = isChatWaitingForAiResponse()
    // if last message is user then waiting for agent response
    if (isWaiting) {
      return (
        <Grid
          item
          xs={12}
          key={'chatawaitingresponse'}
          name={'chatawaitingresponse'}
          sx={{
            px: 2,
            py: 1,
          }}
          ref={scrollRef}
        >
          <Box name={"background"}
            sx={{
              // backgroundColor: m.role === "assistant" ? "#efefef" : "#f9f9f9",
              backgroundColor: theme.palette.background.paper,
              borderRadius: "5px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: theme.palette.border.normal,
              boxShadow: theme.palette.boxshadow.normal,
              display: "flex",
              flexDirection: "row",
              minHeight: "56px",
              // backgroundColor: m.role === 'assistant' ? '#efefef' : '#fff',
            }}
          >
            <Box name={"icon"}>
              <Box
                name={"roleIcon"}
                sx={{
                  width: "56px",
                  minWidth: "56px",
                  pt: 2,
                  // backgroundColor: '#cc0000',
                  display: "flex",
                  // alignItems: 'center',
                  justifyContent: "center",
                }}
              >
                <SupportAgentIcon
                  sx={{
                    color: "grey.500",
                  }}
                // color='primary'
                />
              </Box>
            </Box>

            <Box name={"message"}
              sx={{
                // backgroundColor: m.role === "assistant" ? "#efefef" : "#f9f9f9",
                flexGrow: 1,
                // px: 2,
                px: 0,
                py: 2,
                borderRadius: "5px",
              }}
            >
              <Box>
                <div className="dot-holder">
                  <div
                    className="dot-pulse"
                    style={{
                      "--ca-agentmessageloading": theme.palette.primary.main,
                    }}
                  ></div>
                </div>
              </Box>
            </Box>
            <Box name={"settings"}
              sx={{
                width: "56px",
                minWidth: "56px",
                pt: "10px",
                px: "5px",
                display: "flex",
                // alignItems: 'center',
                justifyContent: "center",
              }}
            ></Box>
          </Box>
        </Grid>
      );
    }
    else if (aiMessagesUserSending) {
      // if user sent a message and waiting for it to appear in aimessages
      return (
        <Grid
          item
          xs={12}
          key={'chatawaitingresponse'}
          name={'chatawaitingresponse'}
          sx={{
            px: 2,
            py: 1,
          }}
          ref={scrollRef}
        >
          <Box name={"background"}
            sx={{
              // backgroundColor: m.role === "assistant" ? "#efefef" : "#f9f9f9",
              backgroundColor: theme.palette.background.paper,
              borderRadius: "5px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: theme.palette.border.normal,
              boxShadow: theme.palette.boxshadow.normal,
              display: "flex",
              flexDirection: "row",
              minHeight: "56px",
              // backgroundColor: m.role === 'assistant' ? '#efefef' : '#fff',
            }}
          >
            <Box name={"settings"}
              sx={{
                width: "56px",
                minWidth: "56px",
                pt: "10px",
                px: "5px",
                display: "flex",
                // alignItems: 'center',
                justifyContent: "center",
              }}
            ></Box>
            <Box name={"message"}
              sx={{
                // backgroundColor: m.role === "assistant" ? "#efefef" : "#f9f9f9",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                flexGrow: 1,
                // px: 2,
                px: 0,
                py: 2,
                borderRadius: "5px",
              }}
            >
              <Box>
                <div className="dot-holder">
                  <div
                    className="dot-pulse"
                    style={{
                      "--ca-agentmessageloading": theme.palette.primary.main,
                    }}
                  ></div>
                </div>
              </Box>
            </Box>
            <Box name={"icon"}>
              <Box name={"roleIcon"}
                sx={{
                  width: "56px",
                  minWidth: "56px",
                  pt: 2,
                  // backgroundColor: '#cc0000',
                  display: "flex",
                  // alignItems: 'center',
                  justifyContent: "center",
                }}
              >
                <AccountCircleIcon
                  sx={{
                    color: "grey.500",
                  }}
                // color='primary'
                />
              </Box>
            </Box>
          </Box>
        </Grid>
      );
    }
  };

  if (aiAgentsIsRemoving || aiConversationsIsRemoving) {
    return <LoadingSubPane spin={true} />;
  }

  if (aiConversation) {
    // if (clog()) console.log('AiChatPanel aiConversation', aiConversation);

    return (
      <Box
        name={"chatPanel"}
        height={"100%"}
        // bgcolor="#cc0000"
        sx={{
          display: "flex",
          flexDirection: "column",
          // overflow: 'hidden',
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: theme.palette.border.normal,
          // backgroundColor: '#efefef',
          borderRadius: "4px",
          overflow: "hidden",
          backgroundColor: theme.palette.background.bg,
        }}
      >
        <Box
          name={"chatMessages"}
          sx={{
            flexGrow: 1,
            overflow: "hidden",
            height: "100%",
            backgroundColor: messageCount === 0 || (messageCount === 1 && aiMessages[0].type === "aisettings") ? "transparent" : theme.palette.background.paper,
          }}
        >
          {
            // <Typography>Ai Chat Panel</Typography>
          }

          <Box
            // component={wrapper}
            // ref={wrapper} // Option 1: use ref to scroll to bottom
            id={"chatMessagesScrollWrapper"}
            sx={{
              maxHeight: "100%",
              height: (aiMessages && aiMessages.length > 0) || (aiMessages && aiMessages.length === 0 && aiMessagesUserSending) ? "auto" : "100%",
              // flexGrow: 1,
              overflow: "auto",
              // display: 'flex', // Option 2: reverse column so that the last message is at the top
              // flexDirection: "column-reverse" // Option 2: would need to reverse the order of the messages
            }}
          >
            <Grid
              container
              // component={wrapper}
              // ref={wrapper} // Option 1: use ref to scroll to bottom
              sx={{
                my: (aiMessages && aiMessages.length > 0) || (aiMessages && aiMessages.length === 0 && aiMessagesUserSending) ? 1 : 0,
                height: (aiMessages && aiMessages.length > 0) || (aiMessages && aiMessages.length === 0 && aiMessagesUserSending) ? "auto" : "100%",

                // maxHeight: '100%',
                // flexGrow: 1,
                //  overflow: 'auto',
                // display: 'flex', // Option 2: reverse column so that the last message is at the top
                // flexDirection: "column-reverse" // Option 2: would need to reverse the order of the messages
              }}
            >
              {
                showNoMessages()
              }
              {aiMessages &&
                aiMessages.map((m, i) => {
                  // const test = m
                  // check to see if next message exists
                  let settingsMessage = null;
                  // if (i + 1 < aiMessages.length) {
                  //   const prevMessage = aiMessages[i - 1];
                  //   // has the type of 'aisettings'
                  //   if (prevMessage.type === 'aisettings') {
                  //     settingsMessage = prevMessage
                  //   }
                  // }
                  const prevMessageIndex = i - 1;
                  if (prevMessageIndex > -1) {
                    const prevMessage = aiMessages[prevMessageIndex];
                    // has the type of 'aisettings'
                    if (prevMessage.type === "aisettings") {
                      settingsMessage = prevMessage;
                    }
                  }

                  let isLastMessage = false;
                  if (i === aiMessages.length - 1) {
                    isLastMessage = true;
                  }
                  if (isLastMessage && m.type === "aisettingsX") {
                    settingsMessage = m;

                    return (
                      <Grid item xs={12} key={i} name={m.type}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            minHeight: "56px",
                          }}
                        >
                          <Box
                            sx={{
                              width: "56px",
                              minWidth: "56px",
                              pt: 2,
                              // backgroundColor: '#cc0000',
                              display: "flex",
                              // alignItems: 'center',
                              justifyContent: "center",
                            }}
                          ></Box>

                          <Box
                            sx={{
                              flexGrow: 1,
                              pr: 2,
                              py: 2,
                              // backgroundColor: 'blue',
                              // backgroundColor: 'primary.100',
                            }}
                          >
                            {
                              // <Typography
                              //   sx={{ fontSize: '15px' }}
                              //   key={"m" + i}
                              //   value={i}
                              // >
                              //   Settings Update: Version {m.data.version}
                              // </Typography>
                            }
                          </Box>

                          <Box
                            sx={{
                              width: "56px",
                              minWidth: "56px",
                              pt: "10px",
                              px: "5px",
                              display: "flex",
                              // alignItems: 'center',
                              justifyContent: "center",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                // justifyContent: 'center',
                              }}
                            >
                              {rc.chat_promptversion_controls && (
                                <ApplySettingsMenu
                                  userData={userData}
                                  conversation={aiConversation}
                                  // version={settingsMessage.data.version}
                                  settingsMessage={settingsMessage}
                                  settingsData={aiSettingsDataUpdated}
                                  // disabled={true}
                                  menuDisabled={isSettingsMenuDisabled()}
                                />
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    );
                  } else if (m.type === "ai") {
                    return (
                      <Grid
                        item
                        xs={12}
                        key={i}
                        name={m.type}
                        sx={{
                          px: 2,
                          py: 1,
                        }}
                        ref={
                          isScollRef('message', i) ? scrollRef : null
                        }
                      >
                        <Box name={"background"}
                          sx={{
                            // backgroundColor: m.type === "ai" ? "#efefef" : "#f9f9f9",
                            backgroundColor: theme.palette.background.paper,
                            borderRadius: "5px",
                            borderWidth: "1px",
                            borderStyle: "solid",
                            borderColor: theme.palette.border.normal,
                            boxShadow: theme.palette.boxshadow.normal,
                            display: "flex",
                            flexDirection: "row",
                            minHeight: "56px",
                            // backgroundColor: m.type === 'ai' ? '#efefef' : '#fff',
                          }}
                        >
                          <Box name={"icon"}
                            sx={{
                              width: "56px",
                              minWidth: "56px",
                              pt: 2,
                              // backgroundColor: '#cc0000',
                              display: "flex",
                              // alignItems: 'center',
                              justifyContent: "center",
                            }}
                          >
                            {m.type === "ai" && (
                              <SupportAgentIcon
                                sx={{
                                  color: "grey.500",
                                }}
                              // color='primary'
                              />
                            )}

                            {m.type === "human" && (
                              <AccountCircleIcon
                                sx={{
                                  color: "grey.500",
                                }}
                              // color='primary'
                              />
                            )}
                          </Box>

                          <Box name={"message"}
                            sx={{
                              // backgroundColor: m.type === "ai" ? "#efefef" : "#f9f9f9",
                              flexGrow: 1,
                              // px: 2,
                              px: 0,
                              py: 2,
                              // borderRadius: "5px",
                            }}
                          >
                            {
                              <Typography
                                sx={{
                                  fontSize: "15px",
                                  whiteSpace: "pre-wrap",
                                }}
                                key={"m" + i}
                                value={i}
                              >
                                {m.data && m.data.content}
                              </Typography>
                            }
                          </Box>
                          <Box name={"settings"}
                            sx={{
                              width: "56px",
                              minWidth: "56px",
                              pt: "10px",
                              px: "5px",
                              display: "flex",
                              // alignItems: 'center',
                              justifyContent: "center",
                            }}
                          >
                            {settingsMessage && (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  // justifyContent: 'center',
                                }}
                              >
                                {
                                  //  <Typography
                                  //    sx={{
                                  //      fontWeight: 'bold',
                                  //    }}
                                  //  >{settingsMessage.data.version}
                                  //  </Typography>
                                }

                                {rc.chat_promptversion_controls && (
                                  <ApplySettingsMenu
                                    userData={userData}
                                    conversation={aiConversation}
                                    // version={settingsMessage.data.version}
                                    settingsMessage={settingsMessage}
                                    settingsData={aiSettingsDataUpdated}
                                    menuDisabled={isSettingsMenuDisabled()}
                                  />
                                )}
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Grid>
                    );
                  } else if (m.type === "human") {
                    return (
                      <Grid
                        item
                        xs={12}
                        key={i}
                        name={m.type}
                        sx={{
                          px: 2,
                          py: 1,
                        }}
                        ref={
                          isScollRef('message', i) ? scrollRef : null
                        }
                      >
                        <Box name={"background"}
                          sx={{
                            // backgroundColor: m.type === "ai" ? "#efefef" : "#f9f9f9",
                            backgroundColor: theme.palette.background.paper,
                            borderRadius: "5px",
                            borderWidth: "1px",
                            borderStyle: "solid",
                            borderColor: theme.palette.border.normal,
                            boxShadow: theme.palette.boxshadow.normal,
                            display: "flex",
                            flexDirection: "row",
                            minHeight: "56px",
                            // backgroundColor: m.type === 'ai' ? '#efefef' : '#fff',
                          }}
                        >
                          <Box name={"settings"}
                            sx={{
                              width: "56px",
                              minWidth: "56px",
                              pt: "10px",
                              px: "5px",
                              display: "flex",
                              // alignItems: 'center',
                              justifyContent: "center",
                            }}
                          >
                            {settingsMessage && (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  // justifyContent: 'center',
                                }}
                              >
                                {
                                  //  <Typography
                                  //    sx={{
                                  //      fontWeight: 'bold',
                                  //    }}
                                  //  >{settingsMessage.data.version}
                                  //  </Typography>
                                }

                                {rc.chat_promptversion_controls && (
                                  <ApplySettingsMenu
                                    userData={userData}
                                    conversation={aiConversation}
                                    // version={settingsMessage.data.version}
                                    settingsMessage={settingsMessage}
                                    settingsData={aiSettingsDataUpdated}
                                    menuDisabled={isSettingsMenuDisabled()}
                                  />
                                )}
                              </Box>
                            )}
                          </Box>

                          <Box name={"message"}
                            sx={{
                              // backgroundColor: m.type === "ai" ? "#efefef" : "#f9f9f9",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-end",
                              flexGrow: 1,
                              // px: 2,
                              px: 0,
                              py: 2,
                              // borderRadius: "5px",
                            }}
                          >
                            {
                              <Typography
                                sx={{
                                  fontSize: "15px",
                                  whiteSpace: "pre-wrap",
                                }}
                                key={"m" + i}
                                value={i}
                              >
                                {m.data && m.data.content}
                              </Typography>
                            }
                          </Box>

                          <Box name={"icon"}
                            sx={{
                              width: "56px",
                              minWidth: "56px",
                              pt: 2,
                              // backgroundColor: '#cc0000',
                              display: "flex",
                              // alignItems: 'center',
                              justifyContent: "center",
                            }}
                          >
                            {m.type === "ai" && (
                              <SupportAgentIcon
                                sx={{
                                  color: "grey.500",
                                }}
                              // color='primary'
                              />
                            )}

                            {m.type === "human" && (
                              <AccountCircleIcon
                                sx={{
                                  color: "grey.500",
                                }}
                              // color='primary'
                              />
                            )}
                          </Box>



                        </Box>
                      </Grid>
                    );
                  } else if (m.type === "aireset") {
                    return (
                      <Grid
                        item
                        xs={12}
                        key={i}
                        name={m.type}
                        sx={
                          {
                            // my: 1,
                          }
                        }
                        ref={
                          isScollRef('message', i) ? scrollRef : null
                        }
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            // minHeight: "56px",
                            // backgroundColor: m.type === 'ai' ? '#efefef' : '#fff',
                          }}
                        >
                          <Box
                            sx={{
                              width: "56px",
                              minWidth: "56px",
                              // pt: 2,
                              // backgroundColor: '#cc0000',
                              display: "flex",
                              // alignItems: 'center',
                              justifyContent: "center",
                            }}
                          >
                            {m.type === "ai" && (
                              <SupportAgentIcon
                                sx={{
                                  color: "grey.500",
                                }}
                              // color='primary'
                              />
                            )}

                            {m.type === "human" && (
                              <AccountCircleIcon
                                sx={{
                                  color: "grey.500",
                                }}
                              // color='primary'
                              />
                            )}
                          </Box>

                          <Box
                            sx={{
                              // backgroundColor: m.type === "ai" ? "#efefef" : "#f9f9f9",
                              flexGrow: 1,
                              px: 0,
                              py: 0,
                              // borderRadius: "5px",
                            }}
                          >
                            {
                              <Divider
                                sx={{
                                  // backgroundColor: "grey.400",
                                  borderColor: "grey.400",
                                  color: "grey.900",
                                  "&.MuiDivider-root:before": {
                                    borderColor: theme.palette.border.normal,
                                  },
                                  "&.MuiDivider-root:after": {
                                    borderColor: theme.palette.border.normal,
                                  },
                                }}
                              >
                                <SyncIcon
                                  // color="primary"
                                  sx={{
                                    mt: "8px",
                                    // color: "primary.main",
                                    // color: linkIconActiveDisabled(),
                                    color: "grey.400",
                                    fontSize: 16,
                                  }}
                                />
                              </Divider>
                            }
                          </Box>
                          <Box
                            sx={{
                              width: "56px",
                              minWidth: "56px",
                              // pt: "10px",
                              px: "5px",
                              display: "flex",
                              // alignItems: 'center',
                              justifyContent: "center",
                            }}
                          ></Box>
                        </Box>
                      </Grid>
                    );
                  }
                })}

              {showChatWaitingResponse()}
            </Grid>
          </Box>
        </Box>

        <Box
          name={"chatInput"}
          sx={{
            display: "flex",
            flexDirection: "row",
            py: 2,
            pl: 2,
            pr: "5px",
            justifyContent: "center",
            alignItems: "flex-end",
            borderTopWidth: "1px",
            borderTopStyle: "solid",
            borderTopColor: theme.palette.border.normal,
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              // display: 'flex',
              // flexDirection: 'column',
              // p: 4
              // pr: 2,
            }}
          >
            <TextField
              fullWidth
              name="chatinput"
              value={aiChatInput}
              onChange={handleOnChangeChatInput}
              onKeyDown={(event) => {
                if (event.key === "Enter" && (event.ctrlKey || event.metaKey)) {
                  if (aiChatInput !== "") {
                    // if (clog()) console.log('Enter Pressed')
                    handleOnSubmitSend();
                  }
                }
              }}
              disabled={isChatInputDisabled()}
              multiline
              maxRows={25}
              autoFocus
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Send"
                      color="primary"
                      variant="light"
                      onClick={handleOnSubmitSend}
                      disabled={isSendDisabled()}
                      sx={{
                        mb: "19px",
                        "&.MuiIconButton-sizeSmall": {
                          fontSize: "16px",
                        },
                      }}
                      size="small"
                    >
                      <SendIcon fontSize="inherit" />
                    </IconButton>
                  </InputAdornment>
                ),
                sx: {
                  alignItems: "flex-end",
                  "&.MuiOutlinedInput-root": {
                    backgroundColor: theme.palette.background.paper,
                  },
                },
                //     <Box
                //     sx={{
                //       display: "flex",
                //       flexDirection: "column",
                //       alignItems: "flex-end",
                //       backgroundColor: "red",
                //     }}
                //   >
              }}
            />
          </Box>

          <Box
            sx={{
              ml: "5px",
              pb: "8px",
              // flex: 1,
            }}
          >
            <IconButton
              aria-label="Reset"
              color="primary"
              variant="light"
              onClick={handleOnClickReset}
              disabled={isResetDisabled()}
            >
              <SyncIcon fontSize="10px" />
            </IconButton>
          </Box>
        </Box>
      </Box>
    );
  }

  if (init) {
    return <LoadingSubPane spin={true} />;
  }

  // if (!init) {
  //   return (
  //     <Box>
  //       <Typography>Select a conversation</Typography>
  //     </Box>
  //   )
  // }
};

export default React.memo(AiChatPanel);
