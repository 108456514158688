import React, {
  // useEffect,
  useState
} from 'react'
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  // MarkerF
} from '@react-google-maps/api';

import moveArrows from '../assets/moveArrows3.svg';

const mapsApiKey = import.meta.env.VITE_REACT_APP_MAPS_API


function KeywordsSetupGoogleMapPane(props) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: mapsApiKey
  })

  const containerStyle = {
    // width: '720px',
    width: '100%',
    height: '500px',
    display: props.showMap === true ? 'block' : 'none'
  };

  const markerDataProps = props.markerData
  const ecomacyLoaded = props.ecomacyLoaded

  const [markerData, setMarkerData] = useState(markerDataProps ? markerDataProps : []);

  // const center = {
  //   lat: 51.291727,
  //   lng: -0.3233870000000252,
  // };

  //  useEffect(() => {
  //    if (props.markerData !== undefined) {
  //      const test = props.markerData
  //      if (map) {
  //      }
  //    }
  //
  //  }, [props.markerData])
  //
  //  useEffect(() => {
  //    if (activeMarker > -1) {
  //      if (map) {
  //      }
  //    }
  //
  //  }, [activeMarker])

  // london
  const centerObj = {
    lat: 51.509865,
    lng: -0.118092
  };

  const [center, setCenter] = useState(centerObj);


  const [activeMarker, setActiveMarker] = useState(-1);
  const [userMarker, setUserMarker] = useState(null);

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds();
    if (ecomacyLoaded) {
      const markerDataLength = markerData.length

      if (0 === markerDataLength) {
        // what to do if
        // map.setCenter(center);
        // map.setZoom(16);
      }

      if (1 === markerDataLength) {
        const centerLatLng = {
          lat: markerData[0].lat,
          lng: markerData[0].lng,
        }

        map.setCenter(centerLatLng);
        map.setZoom(16);
        setMap(map)

        setCenter(centerLatLng)

        // set active marker
        setActiveMarker(0)

        // set userMarker
        setUserMarker(centerLatLng)
      }

      if (markerDataLength > 1) {
        // bounds.extend(center);
        // map.fitBounds(bounds);
        const bounds = new window.google.maps.LatLngBounds();
        for (var i = 0; i < markerDataLength; i++) {
          // if (markers[i].getVisible()) {
          const item = markerData[i]
          const pos = {
            lat: item.lat,
            lng: item.lng
          }
          bounds.extend(pos);
          // }
        }
        map.fitBounds(bounds);

        setMap(map)

        // set map center
        const mapCenter = map.getCenter()
        setCenter(mapCenter)

      }
    }





    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])






  const handleMarkerOnClick = (idx) => {
    const mapCenter = map.getCenter();

    map.setCenter(mapCenter);

    setActiveMarker(idx)

    setUserMarker(markerData[idx])
  }


  const handleOnDragStart = (t) => {
    // if (clog()) console.log('latLng:', t.latLng.lat())
    // hide grid
    // setShowMarker(false)
  }

  const handleOnDrag = (t) => {
    // if (clog()) console.log('latLng:', t.latLng.lat())
  }

  const handleOnDragEnd = (t) => {
    // if (clog()) console.log('latLng:', t.latLng.lat())
    const newCenter = {
      lat: t.latLng.lat(),
      lng: t.latLng.lng()
    };
    // setDragMarker(newCenter)
    setUserMarker(newCenter)

    // update lat lng in parent
    // updateGridLatLng(newCenter)
  }

  const isDraggable = (idx) => {

    let draggable = false
    if (activeMarker === idx) {
      draggable = true
    }

    return draggable
  }


  const mapOnClick = (e) => {

    if (markerDataProps.length === 0) {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();

      let newMarkerArr = []

      const newMarkerItem = {
        lat: lat,
        lng: lng
      }

      newMarkerArr.push(newMarkerItem)
      setMarkerData(newMarkerArr)


      setActiveMarker(0)
      setUserMarker(newMarkerItem)


      // map.setCenter(newMarkerItem);
      // setMap(map)
      // setCenter(newMarkerItem)

    }
  }

  const mapOptions = {
    // disableDefaultUI: true
    mapTypeControl: false,
    scaleControl: true,
    zoomControl: true,
    fullscreenControl: true,
    streetViewControl: false,
    keyboardShortcutsControl: false,
    clickableIcons: false
  }


  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={13}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onClick={mapOnClick}
      options={mapOptions}
      mapTypeIds={'ROADMAP'}
    >



      {markerData && markerData.map((item, idx) => {
        // const id = item.id

        const itemId = idx + 1
        const key = 'm' + itemId

        const labelParams = {
          text: '' + itemId,
          color: 'white',
          fontSize: '18px',
          fontWeight: "bold"
        }

        const pos = {
          lat: item.lat,
          lng: item.lng
        }
        //let fillColor = 'rgba(140, 140, 140, 0.7)'
        // let fillColor = 'rgba(	51, 102, 255, 0.8)'
        let fillColor = 'rgba(	24, 144, 255, 0.8)'
        // green 2FA626
        if (activeMarker === idx || markerData.length === 1) {
          fillColor = 'rgba(47, 166, 38, 0.8)'
        }

        // orange FF8C00
        // if (ratingPos >= 4 && ratingPos <= 10) {
        //   fillColor = 'rgba(255, 140, 0, 0.7)'
        // }

        return (
          <Marker
            key={key}
            id={idx}
            position={activeMarker === idx ? userMarker : pos}
            label={labelParams}
            onClick={() => handleMarkerOnClick(idx)}
            draggable={isDraggable(idx)}
            onDragStart={(t) => handleOnDragStart(t)}
            onDrag={(t) => handleOnDrag(t)}
            onDragEnd={(t) => handleOnDragEnd(t)}
            icon={
              activeMarker === idx ?
              {
                url: moveArrows,
                anchor: new window.google.maps.Point(20, 20),
                scaledSize: new window.google.maps.Size(40, 40),
                strokeWeight: 0,
                zIndex: 99999999
              }
              :
              {
              // path: "M10, 35 a 25, 25 0 1,0 50,0 a 25,25 0 1,0 -50,0",
              path: "M-20,0 a 20,20 0 1,0 40,0 a 20,20 0 1,0 -40,0",
              fillColor: fillColor,
              // fillColor: '#cc0000',
              fillOpacity: 1,
              scale: 1,
              strokeWeight: 0,
              zIndex: 99999999
            }

          }
          ></Marker>
        )

      })}




      {/*
      <MarkerF
      key={'a1'}
      id={'a1'}
      position={center}
      label={labelParams}
      // onMouseOver={() => handleActiveMarker(id, scanId)}
      // onMouseOut={() => setActiveMarker(null)}
      // icon={image}
//        icon={{
//          // path: "M10, 35 a 25, 25 0 1,0 50,0 a 25,25 0 1,0 -50,0",
//          path: "M-20,0 a 20,20 0 1,0 40,0 a 20,20 0 1,0 -40,0",
//          // fillColor: fillColor,
//          fillColor: '#cc0000',
//          fillOpacity: 1,
//          scale: 1,
//          strokeWeight: 0,
//          zIndex: 99999999
//        }}
    ></MarkerF>
*/}



    </GoogleMap>
  ) : <></>
}

export default React.memo(KeywordsSetupGoogleMapPane)