import React, { createContext, useState, useRef, useContext } from "react";
// import auth from '@react-native-firebase/auth';
import { getUserToken } from "../code/firebase";
import { getAuth, signOut } from "firebase/auth";

import {
  // collection,
  doc,
  setDoc,
  onSnapshot,
  // getDoc,
  getDocs,
  getFirestore,
  collection,
  query,
  where,
  orderBy,
  limit,
} from "firebase/firestore";

import _ from "lodash";

import { getFunctions, httpsCallable } from "firebase/functions";
import { useAuthState } from "react-firebase-hooks/auth";

import { getUrlParams } from "../code/ecomacy";
import { firebase } from "../code/firebase";
import { re } from "mathjs";

import {setd, debug, setc, clog} from "./HelperFunctions";

// const userData = getUrlParamsX();

// let locationId = "";
// if (userData.locationId !== "") {
//   locationId = userData.locationId;
// }
// let email = "";
// if (userData.email !== "") {
//   email = userData.email;
// }

const auth = getAuth(firebase);
const functions = getFunctions(firebase, "europe-west1");
const db = getFirestore(firebase);


const initialState = {
  // login: () => {},
  // logout: () => {},
  // authenticate: () => {},
  // getUserToken: () => {},
};

export const CustomerAuthContext = createContext(initialState);
// export const AuthContext = createContext({} as ContextState);
// const Context = createContext({} as ContextState);

export const CustomerAuthProvider = ({ children }) => {



  let props = {}
  if (children && children.props) {
    props = { ...children.props };
  }

  let userData = {};
  if (props.urlDataParams) {
    userData = props.urlDataParams;
  }
  let locationId = "";
  if (userData.locationId !== "") {
    locationId = userData.locationId;
  }
  let email = "";
  if (userData.email !== "") {
    email = userData.email;
  }

  const urlDataParams = props.urlDataParams;


  // const [user, setUser] = useState(null);
  const [testVal, setTestVal] = useState("");

  const [remoteConfigStatus, setRemoteConfigStatus] = useState({
    loaded: false,
    questionChat: false,
  });

  const [adminStatus, setAdminStatus] = useState({
    loaded: false,
    isSuperAdmin: false,
    isAdmin: false,
  });

  const [customerUserData, setCustomerUserData] = useState({
    data: null,
    error: null,
    loaded: false,
  });

  const [customersData, setCustomersData] = useState({
    data: null,
    // dataOrig: null,
    error: null,
    loaded: false,
    // synced: false
  });

  const [user, loadingUser, errorUser] = useAuthState(auth);

  const [rawData, setRawData] = useState({
    customerData: null,
    accountStartTs: null,
    billingStartTs: null,
    tokensIncluded: null,
    tokensExtra: null,
    monthly: null,
    multiple: null,
    tokenPrice: null,
    runningTotal: null,
    tokensLeftThisMonth: null,
    allCustomerData: false,

    saveKeywords: null,
    keywordsCount: null,
    schedule: null,
    scheduleDay: null,
    scheduleHour: null,
    scheduleInterval: null,
    allKeywordsData: false,

    // saveGridTemplates: null,
    gridCount: null,
    allGridData: false,
  });

  const rawDataRef = useRef({});

  const [tokenData, setTokenData] = useState({
    iterationsMonthly: null,
    tokensLeftThisMonth: null,
    tokensNeededSingle: null,
    totalSingle: null,
    tokensNeededMonthly: null,
    totalMonthly: null,
    tokensLeftAfterDeduction: null,
  });

  const [gridTemplatesDocs, setGridTemplatesDocs] = useState({
    loading: true,
  });
  const [gridTemplatesDocsInit, setGridTemplatesDocsInit] = useState(true);

  const getCustomerUserDoc = async (uid) => {
    try {
      let customerDoc = null;
      let customerLogSearchDoc = null;
      const test = uid;
      const test1 = userData;
      const test2 = adminStatus;
      let qc = null;

      if (adminStatus.isAdmin) {
        qc = query(collection(db, "customers"), where("locationid", "==", userData.locationId), where("userid", "==", uid));
      } else {
        qc = query(collection(db, "customers"), where("locationid", "==", uid));
      }

      const snapshotCustomer = await getDocs(qc);

      if (snapshotCustomer.empty) {
        const err = {
          val: "No Location Data found",
        };
        return {
          error: err,
        };
      }

      snapshotCustomer.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        // if (clog()) console.log(doc.id, " => ", doc.data());
        customerDoc = doc.data();
      });

      if (customerDoc) {
        if (!customerDoc.data) {
          customerDoc.data = {};
        }
        // const qls = query(collection(db, "logsearches"), where("eclocationid", "==", uid), orderBy("created", "desc"), limit(1));

        // const snapshotLogSearch = await getDocs(qls);

        // // if (snapshotLogSearch.empty) {
        // // }

        // snapshotLogSearch.forEach((doc) => {
        //   // doc.data() is never undefined for query doc snapshots
        //   // if (clog()) console.log(doc.id, " => ", doc.data());
        //   customerLogSearchDoc = doc.data();
        // });
      }

      // if (customerLogSearchDoc && customerLogSearchDoc.runningtotal) {
      //   customerDoc.data.runningtotal = customerLogSearchDoc.runningtotal;
      // }

      return customerDoc;
    } catch (err) {
      console.error(err);
      return {
        error: err,
      };
    }
  };

  /*
  const getAdminCustomerDocs = async (uid) => {
    return new Promise((resolve, reject) => {

      try {

        let customersDataOrigObject = {}

        const q = query(collection(db, "customers"), where("userid", "==", uid));
        // let customerDocs = []
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          let customerDocs = []
          // const cities = [];
          querySnapshot.forEach((doc) => {
            // customerDocs.push(doc.data().name);
            const docData = doc.data()
            customerDocs.push(doc.data());

            // let customerDocClone = _.cloneDeep(docData)

            // customersDataOrigObject[docData.id] = docData
            // if (docData.id === '0PmBJQrhaR4GHno2mR5e') {
            // }
            // customersDataOrigObject[docData.id] = customerDocClone
          });
          // if (clog()) console.log("Current cities in CA: ", cities.join(", "));

          setCustomersData({
            ...customersData,
            data: customerDocs,
            // dataOrig: customerDocs,
            loaded: true,
          })

          // create customersDataOrig
          //// setCustomersDataOrig(customersDataOrigObject)

          // ref for comparison
          // let customerDocsClone = _.cloneDeep(customerDocs)

          resolve({
            data: customerDocs
          })
        });

        // return unsubscribe

      } catch (err) {
        console.error(err);

        setCustomersData({
          ...customersData,
          error: err,
          loaded: true,
        })

        reject({
          error: err
        })

      }
    })
  }
  */

  const delay = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds));

  return (
    <CustomerAuthContext.Provider
      value={{
        urlDataParams,

        testVal,
        setTestVal,
        adminStatus,
        setAdminStatus,
        getSetAdminStatus: async () => {
          let isSuperAdmin = false;
          let isAdmin = false;
          let isDemoAdmin = false;

          if (user) {
            const idTokenResult = await user.getIdTokenResult();
            // check if superadmin
            const superAdminClaim = idTokenResult.claims.superadmin;
            if (superAdminClaim) {
              isSuperAdmin = true;
              isAdmin = true;
            }
            // check if admin
            const adminClaim = idTokenResult.claims.admin;
            if (adminClaim) {
              isAdmin = true;
            }

            // check if demoadmin
            const demoAdminClaim = idTokenResult.claims.demoadmin;
            if (demoAdminClaim) {
              isAdmin = true;
              isDemoAdmin = true;
            }

            // set state for use on admin login screen etc
            setAdminStatus({
              loaded: true,
              isSuperAdmin: isSuperAdmin,
              isAdmin: isAdmin,
              isDemoAdmin: isDemoAdmin,
            });
          }

          return isAdmin;
        },
        getAdminStatus: async () => {
          let isSuperAdmin = false;
          let isAdmin = false;
          let isDemoAdmin = false;

          if (user) {
            const idTokenResult = await user.getIdTokenResult();

            // check if superadmin
            const superAdminClaim = idTokenResult.claims.superadmin;
            if (superAdminClaim) {
              isSuperAdmin = true;
              isAdmin = true;
            }
            // check if admin
            const adminClaim = idTokenResult.claims.admin;
            if (adminClaim) {
              isAdmin = true;
            }

            // check if demoadmin
            const demoAdminClaim = idTokenResult.claims.demoadmin;
            if (demoAdminClaim) {
              isAdmin = true;
              isDemoAdmin = true;
            }
          }

          const returnData = {
            isSuperAdmin: isSuperAdmin,
            isAdmin: isAdmin,
            isDemoAdmin: isDemoAdmin,
          };

          return returnData;
        },
        resetAdminStatus: async () => {
          // reset admin status
          setAdminStatus({
            loaded: false,
            isSuperAdmin: false,
            isAdmin: false,
            isDemoAdmin: false,
          });

          return;
        },
        customerUserData,
        setCustomerUserData,
        getCustomerUserData: async () => {
          try {
            // let userDoc = {};
            if (user && user.uid) {
              // 1: Get UserDoc
              const userDoc = await getCustomerUserDoc(user.uid);
              if (userDoc && userDoc.error === undefined) {
                // user doc found
                setCustomerUserData({
                  ...customerUserData,
                  data: userDoc,
                  loaded: true,
                });

                return {
                  data: userDoc,
                }
              } else if (userDoc && userDoc.error) {
                //
                setCustomerUserData({ ...customerUserData, loaded: true, error: userDoc.error });
                return {
                  error: userDoc.error,
                }
              } else {
                // no doc found
                setCustomerUserData({
                  ...customerUserData,
                  loaded: true,
                  error: {
                    val: "no doc found",
                  },
                });
                return {
                  error: {
                    val: "no doc found",
                  },
                }
              }
            }
          } catch (err) {
            console.error(err);
            // setAdminUserData({ ...adminUserData, loaded: true, error: err })
          }
        },
        rawData,
        setRawData,
        rawDataRef,
        tokenData,
        setTokenData,

        gridTemplatesDocs,
        setGridTemplatesDocs,
        gridTemplatesDocsInit,
        setGridTemplatesDocsInit,
        getGridTemplateDocs: async (userData) => {
          return new Promise((resolve, reject) => {
            try {
              let gridTemplateDocs = [];
              let found = false;
              const q = query(
                collection(db, "gridtemplates"),
                where("userid", "==", userData.userId),
                where("eclocationid", "==", userData.locationId)
              );
              // let customerDocs = []
              const unsubscribe = onSnapshot(q, (querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  // customerDocs.push(doc.data().name);
                  gridTemplateDocs.push(doc.data());
                });
                // if (clog()) console.log("Current cities in CA: ", cities.join(", "));
                if (gridTemplateDocs) {
                  // user doc found
                  setGridTemplatesDocs({
                    data: gridTemplateDocs,
                  });
                  // if initial load, set initGridTemplatesDocs to false
                  // if (initGridTemplatesDocs) {
                  //   setGridTemplatesDocsInit(false)
                  // }
                }

                resolve(gridTemplateDocs);

                // resolve({
                //   data: customerDocs
                // })

                // resolve(userDoc)
              });
            } catch (err) {
              console.error(err);
              setGridTemplatesDocs({
                error: err,
              });
              // setAdminUserData({
              //   ...adminUserData,
              //   loaded: true,
              //   error: err
              // })

              resolve({
                error: err,
              });
            }
          });
        },
        logout: async () => {
          try {
            await signOut(auth);
          } catch (e) {
            console.error(e);
          }
        },
      }}
    >
      {children}
    </CustomerAuthContext.Provider>
  );
};

// export default AdminAuthProvider;
