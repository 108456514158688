import React, { useEffect, useRef, useContext, useState } from "react";
// import * as React from 'react';
import _ from "lodash";
import Highlight from "react-highlight";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { CustomerAuthContext } from "../helpers/CustomerAuthProvider";
import { AiAuthContext } from "../helpers/AiAuthProvider";
import {setd, debug, setc, clog} from "../helpers/HelperFunctions";

import {
  Grid,
  Box,
  Typography,
  Link,
  Snackbar,
  Alert,
  // Button,
  CircularProgress,
  Chip,
  IconButton,
} from "@mui/material";

import {
  useTheme,
  // styled
} from "@mui/material/styles";

import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import AddIcon from "@mui/icons-material/Add";

import { set } from "date-fns";
import { setIn } from "formik";
import { is } from "date-fns/locale";

// const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9_-]+$/;
const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9_]+$/;

export default function AiAgentVariablesDialog(props) {
  const theme = useTheme();


  // const { userData } = props;
  // const { adminStatus, setAdminStatus } = useContext(CustomerAuthContext);
  const { aiAgentsSelected, setAiAgentsSelected } = useContext(AiAuthContext);
  // const { aiSettingsSelected, setAiSettingsSelected } = useContext(AiAuthContext);

  const { showAiAgentVariablesDialog, setShowAiAgentVariablesDialog } = useContext(AiAuthContext);

  const [aiAgentInputs, setAiAgentInputs] = React.useState([]);
  // const [inputs, setInputs] = React.useState([]);
  const aiAgentInputsRef = useRef([]);
  const [aiAgentInputsErrors, setAiAgentInputsErrors] = React.useState({});

  // const inputRef = useRef(null);

  const [savingInputs, setSavingInputs] = React.useState(false);

  const { saveAiAgentsInputs } = useContext(AiAuthContext);

  const [open, setOpen] = React.useState(false);

  const [openSnackbar, setOpenSnackbar] = React.useState({
    open: false,
    message: "",
  });

  const scrollRef = useRef();
  const [addScrollRef, setAddScrollRef] = React.useState({
    index: -1,
  });

  // const inputTextFieldRef = React.useRef(null);

  useEffect(() => {
    if (showAiAgentVariablesDialog === true) {
      let agentInputs = [];
      if (aiAgentsSelected && aiAgentsSelected.inputs) {
        agentInputs = aiAgentsSelected.inputs;
      }

      setAiAgentInputs(agentInputs);
      aiAgentInputsRef.current = agentInputs;
      // setAiAgentInputs(agentInputs);

      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [showAiAgentVariablesDialog]);


  // run after every render
  useEffect(() => {
    scrollRefIntoView()
  });

  const scrollRefIntoView = () => {
    // if element exists then scroll into view
    if (scrollRef.current) {
      // param: defaultVal
      //  ...options
      // behavior:"auto",
      //   auto | smooth
      // block: "start",
      //   start | center | end | nearest
      // inline:"nearest"
      //   start | center | end | nearest
      const opts = {
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      }
      scrollRef.current.scrollIntoView(opts)
    }
  };



  const handleClose = () => {
    setShowAiAgentVariablesDialog(false);
  };

  // add new input row
  const handleAddNewRow = () => {

    // clone inputs
    const newInputs = [...aiAgentInputs];

    const newRow = {
      name: "",
      shortname: "",
      value: "",
    };

    // add input to array
    newInputs.push(newRow);

    setAiAgentInputs(newInputs);

    // scroll to new row
    setAddScrollRef({
      index: newInputs.length - 1,
    });
  };


  // edit input
  const handleChangeEditInput = (event, index, key) => {

    const val = event.target.value;

    let newInputs = [...aiAgentInputs];

    let newInput = { ...newInputs[index] };

    if (key === 'shortname') {

      // check if val is valid
      const regexTest = ALPHA_NUMERIC_DASH_REGEX.test(val);
      if (regexTest) {

        if (val === '') {
          newInput.shortname = val;
          newInput.name = val;

          // remove item in errors
          if (aiAgentInputsErrors[index]) {
            let newErrors = { ...aiAgentInputsErrors };
            delete newErrors[index];
            setAiAgentInputsErrors(newErrors);
          }

          // reset shortname error check
          // setAiAgentInputsErrors({
          //   ...aiAgentInputsErrors,
          //   [index]: 0,
          // });
        }
        else {
          // check if shortname already exists in inputs
          const shortnameExists = aiAgentInputs.filter((input, i) => {
            return input.shortname === val && i !== index;
          });
          // add item in errors
          if (shortnameExists.length > 0) {
            setAiAgentInputsErrors({
              ...aiAgentInputsErrors,
              [index]: 1,
            });
          }
          else {
            // remove item in errors
            if (aiAgentInputsErrors[index]) {
              let newErrors = { ...aiAgentInputsErrors };
              delete newErrors[index];
              setAiAgentInputsErrors(newErrors);
            }
          }

          newInput.shortname = val;
          newInput.name = 'input.' + val;
        }

      }

    }
    else {
      newInput[key] = val;
    }

    newInputs[index] = newInput;

    setAiAgentInputs(newInputs);
  };

  // reset inputs back to original
  const handleCancel = () => {
    setAiAgentInputs(aiAgentInputsRef.current);

    // scroll to top
    setAddScrollRef({
      index: 0,
    });
  };


  const isSaveDisabled = () => {
    let disabled = false;

    // check for empty inputs values
    let emptyInputs = aiAgentInputs.filter((input) => {
      return input.shortname === "" || input.value === "";
    });
    if (emptyInputs.length > 0) {
      disabled = true;
    }

    // compare aiAgentInputs to aiAgentInputsRef
    if (_.isEqual(aiAgentInputs, aiAgentInputsRef.current)) {
      disabled = true;
    }

    // check if aiAgentInputsErrors is empty
    if (Object.keys(aiAgentInputsErrors).length > 0) {
      disabled = true;
    }

    return disabled;
  }

  const isCancelDisabled = () => {
    let disabled = false;

    // check for empty inputs values
    // let emptyInputs = aiAgentInputs.filter((input) => {
    //   return input.shortname === "" || input.value === "";
    // });
    // if (emptyInputs.length > 0) {
    //   disabled = true;
    // }

    // compare aiAgentInputs to aiAgentInputsRef
    if (_.isEqual(aiAgentInputs, aiAgentInputsRef.current)) {
      disabled = true;
    }

    // check if aiAgentInputsErrors is empty
    // if (Object.keys(aiAgentInputsErrors).length > 0) {
    //   disabled = true;
    // }

    return disabled;
  }


  const areButtonsDisabled = () => {
    let disabled = false;

    if (savingInputs === true) {
      disabled = true;
    }

    return disabled;
  }


  // save aiAgentInputs
  const handleSave = async (index) => {

    setSavingInputs(true);

    // save domains to customer doc
    const agentRes = await saveAiAgentsInputs(aiAgentsSelected.aiagentid, aiAgentInputs);
    if (agentRes && agentRes.data) {
      // setAiAgentInputs(newInputs);
      // set ref equal to disable add button
      aiAgentInputsRef.current = aiAgentInputs;

      // update aiAgentsSelected with new aiAgentInputs
      const newAiAgentsSelected = _.cloneDeep(aiAgentsSelected);
      newAiAgentsSelected.inputs = aiAgentInputs;
      setAiAgentsSelected(newAiAgentsSelected);

    }
    setSavingInputs(false);

  };

  // disable delete input button
  const isDeleteInputDisabled = () => {
    let disabled = false;

    if (savingInputs === true) {
      disabled = true;
    }

    return disabled;
  }

  // delete input row
  const handleDeleteInput = (index) => {

    // clone aiAgentInputs
    const newInputs = [...aiAgentInputs];

    // remove item at index
    newInputs.splice(index, 1);

    // check for item in errors and remove
    if (aiAgentInputsErrors[index]) {
      let newErrors = { ...aiAgentInputsErrors };
      delete newErrors[index];
      setAiAgentInputsErrors(newErrors);
    }

    // set state
    setAiAgentInputs(newInputs);

  };

  // copy automation link to clipboard
  const handleCopyAgentAutomationLink = () => {
    setOpenSnackbar({
      open: true,
      message: "Copied Automation Link to Clipboard",
    });

    let agentAutomationLink = aiAgentsSelected?.automationlink || "";

    navigator.clipboard.writeText(agentAutomationLink);
  };


  // copy automation link to clipboard
  const handleCopyAgentInputName = (index, input) => {
    let name = input?.name || "";

    // setOpenSnackbar({
    //   open: false,
    //   message: "",
    // });

    // check for item error
    let error = false
    if (aiAgentInputsErrors[index] && aiAgentInputsErrors[index] === 1) {
      error = true;
    }

    if (name !== "" && !error) {
      setOpenSnackbar({
        open: true,
        message: "Copied Input Name to Clipboard",
      });

      navigator.clipboard.writeText(name);
    }
  };


  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar({
      open: false,
      message: "",
    });
  };


  // const aiAgentInputHasError = (index, val) => {
  //   let error = -1;

  //   // check if shortname already exists in inputs
  //   const shortnameExists = aiAgentInputs.filter((input, i) => {
  //     return input.shortname === val && i !== index;
  //   });

  //   if (shortnameExists.length > 0) {
  //     error = index;
  //   }
  //   // if (aiAgentsAutomationLinkInUse || aiAgentsAutomationLinkIllegalChars) {
  //   //   error = true;
  //   // }

  //   return error;
  // };

  const aiAgentInputErrorText = (index) => {
    let errorText = "";
    // if (aiAgentsAutomationLinkInUse) {
    //   errorText = "Automation Link already in use.";
    // }

    // if (aiAgentsAutomationLinkIllegalChars) {
    //   // errorText = 'Only A-Z, a-z, 0-9, _ and - are allowed.';
    //   errorText = "Only letters (A-Z, a-z), numbers (0-9) and underscores (_) are allowed.";
    // }

    if (aiAgentInputsErrors[index] && aiAgentInputsErrors[index] === 1) {
      errorText = "There is already an input with this short name.";
    }

    return errorText;
  };



  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={openSnackbar.open}
        onClose={() => handleSnackbarClose()}
        autoHideDuration={4000}
      // message="Copied to clipboard"
      // disabled
      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: "100%" }} variant="filled">
          {openSnackbar.message}
        </Alert>
      </Snackbar>
      <Dialog
        fullWidth
        sx={{
          minWidth: "500px",
          // minHeight: "300px",
          // minHeight: "80vh",
          // height: '80vh',
          // maxHeight: '80vh',

          "& .MuiDialog-paper": {
            minHeight: '50vh !important',
            maxHeight: '80vh !important',
          }
        }}
        open={open}
        onClose={handleClose}
      // scroll={'paper'}
      >
        <DialogTitle>Agent Variables</DialogTitle>
        <DialogContent
          dividers={true}
        >
          <DialogContentText
            sx={{
              textAlign: "center",
            }}
          >
            Agent Automation Link & Automation Inputs
          </DialogContentText>

          <Box
            name="domain"
            sx={{
              mt: 3,
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontWeight: "bold",
                }}
              >
                Agent Automation Link (Click To Copy)
              </Typography>
            </Box>




            <Grid container spacing={2}>
              <Grid item xs={5}>

                <Box
                  sx={{
                    mt: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      flexGrow: 1,
                    }}
                  >
                    <TextField
                      // autoFocus
                      //autoFocus={domainInputAutoFocus()}
                      // inputRef={domainTextFieldRef}
                      id="Agency Automation Link"
                      // label="Agency API Key"
                      type="websiteUrl"
                      fullWidth
                      variant="outlined"
                      value={aiAgentsSelected?.automationlink ? aiAgentsSelected.automationlink : ""}
                      // disabled={true}
                      InputProps={{
                        readOnly: true,
                      }}
                      inputProps={{
                        onClick: () => handleCopyAgentAutomationLink()
                      }}
                      sx={{
                        "MuiInputLabel-root.Mui-disabled": {
                          color: theme.palette.text.primary,
                        },
                        "& .MuiOutlinedInput-input.Mui-disabled": {
                          color: theme.palette.text.primary,
                          WebkitTextFillColor: theme.palette.text.primary,
                        },
                      }}
                    />
                  </Box>

                </Box>


              </Grid>
              <Grid item xs={7}>

              </Grid>
            </Grid>


          </Box>

          <Box
            name="agentInputs"
            sx={{
              mt: 3,
            }}
          >

            <Box>
              <Typography
                sx={{
                  fontWeight: "bold",
                }}
              >
                Agent Inputs
              </Typography>
            </Box>


            {
              /* START: scrollable box */
              <Box
                sx={{
                  overflowY: 'auto',
                  display: "flex",
                  flexGrow: 1,
                  flexDirection: "column",
                  // maxHeight:"200px"
                }}
              >

                {aiAgentInputs && aiAgentInputs.length === 0 && (
                  <Box
                    sx={{
                      mt: 2,
                      mb: 4,
                    }}
                  >
                     <Typography
                      sx={{
                        // fontSize: "16px",
                        // fontWeight: "bold",
                      }}
                    >
                      No inputs have been added yet.
                    </Typography>

                    <Typography
                      sx={{
                        mt: 1,
                        // fontSize: "16px",
                        // fontWeight: "bold",
                      }}
                    >
                      To add a new input, click the <Typography component='span' sx={{fontWeight: "bold"}}>"+ New Row"</Typography> button below.
                    </Typography>
                  </Box>
                )}


                {aiAgentInputs && aiAgentInputs.length > 0 && (
                  // loop over inputs
                  aiAgentInputs.map((input, index) => {

                    return (
                      <Box
                        key={"input-" + index}
                        sx={{
                          mt: 2,
                          mb: 4,
                        }}
                        ref={addScrollRef.index === index ? scrollRef : null}
                      >

                        <Grid container spacing={2}>
                          <Grid item xs={5}>

                            <TextField
                              // autoFocus
                              //autoFocus={domainInputAutoFocus()}
                              // inputRef={domainTextFieldRef}
                              id={"inputShortName" + index}
                              // label="Agency API Key"
                              type="inputShortName"
                              label="Short Name"
                              fullWidth
                              variant="outlined"
                              value={input.shortname}
                              InputLabelProps={{ shrink: true }}
                              onChange={(event) => handleChangeEditInput(event, index, 'shortname')}
                              error={aiAgentInputsErrors[index] === 1 ? true : false}
                              helperText={aiAgentInputErrorText(index)}
                            />

                            <TextField
                              id={"Input Name" + index}
                              type="inputName"
                              label="Name (Click To Copy)"
                              fullWidth
                              variant="outlined"
                              value={input.name}
                              InputLabelProps={{ shrink: true }}
                              InputProps={{
                                readOnly: true,
                              }}
                              inputProps={{
                                onClick: () => handleCopyAgentInputName(index, input),
                              }}
                              sx={{
                                mt: "21px",
                                input: {
                                  cursor: 'pointer',
                                },
                              }}
                            />
                          </Grid>
                          <Grid item xs={7}>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                              }}
                            >


                              <Box sx={{
                                flexGrow: 1,
                              }}>
                                <TextField
                                  id={"Input Value" + index}
                                  type="inputValue"
                                  label="Value"
                                  fullWidth
                                  multiline
                                  rows={3}
                                  variant="outlined"
                                  value={input.value}
                                  // variant="standard"
                                  InputLabelProps={{ shrink: true }}
                                  onChange={(event) => handleChangeEditInput(event, index, 'value')}
                                  sx={{
                                    backgroundColor: theme.palette.background.paper,
                                    "& .MuiInputBase-multiline": {
                                      pr: 0,
                                      flexGrow: 1,
                                      userSelect: "none",
                                    },
                                    "& .MuiOutlinedInput-input": {
                                      fontSize: "15px",
                                      height: "100% !important",
                                      overflow: "auto !important",
                                      pr: 2,
                                    },
                                    "& .MuiOutlinedInput-input.Mui-disabled": {
                                      color: theme.palette.text.primary,
                                      WebkitTextFillColor: theme.palette.text.primary,
                                    },
                                  }}
                                />

                              </Box>

                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",

                                }}
                              >
                                <Box
                                  sx={{
                                    ml: 1,
                                  }}
                                >
                                  <IconButton
                                    aria-label="Delete Input"
                                    color="primary"
                                    size="small"
                                    sx={{
                                      "&.MuiIconButton-sizeSmall": {
                                        fontSize: "16px",
                                      },
                                    }}
                                    // variant="light"
                                    onClick={() => handleDeleteInput(index)}
                                    disabled={isDeleteInputDisabled(index)}
                                  >
                                    <DeleteIcon fontSize="inherit" />
                                  </IconButton>
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    );
                  })
                )}

              </Box>
              /* END: scrollable box */
            }


          </Box>


        </DialogContent>
        <DialogActions>
          <Box
            sx={{
              // ml: 1,
              mr: 3,
            }}
          >
            <Button
              variant="outlined"
              onClick={handleAddNewRow}
              disabled={areButtonsDisabled()}
              startIcon={<AddIcon />}
            >
              New Row
            </Button>
          </Box>
          <Button
            variant="outlined"
            onClick={handleCancel}
            // disabled={areButtonsDisabled()}
            disabled={isCancelDisabled()}
          >
            Cancel
          </Button>


          {
            savingInputs === true ? (
              <Box
                sx={{
                  width: "64px",
                  ml: 1,
                  mr: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",

                }}
              >
                <CircularProgress size={20} />
              </Box>
            ) : (
              <Box
                sx={{
                  ml: 1,
                  mr: 2,
                }}
              >
                <Button
                  variant="contained"
                  onClick={handleSave}
                  color="success"
                  disabled={isSaveDisabled()}
                >
                  Save
                </Button>
              </Box>
            )}
          <Button
            variant="outlined"
            onClick={handleClose}
            disabled={areButtonsDisabled()}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
