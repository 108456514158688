import React, { useEffect, useRef, useContext, useState } from "react";
// import * as React from 'react';
import _ from "lodash";
import Highlight from "react-highlight";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import {setd, debug, setc, clog} from "../helpers/HelperFunctions";
import { AdminAuthContext } from "../helpers/AdminAuthProvider";
import { CustomerAuthContext } from "../helpers/CustomerAuthProvider";
import { AdminAiAuthContext } from "../helpers/AdminAiAuthProvider";

import {
  Grid,
  Box,
  Typography,
  Link,
  Snackbar,
  Alert,
  // Button,
  CircularProgress,
  Chip,
  IconButton,
  FormControlLabel,
  FormControl,
  InputLabel,
  Checkbox,
  Select,
  MenuItem,
  List,
  ListItem,
} from "@mui/material";

import {
  useTheme,
  // styled
} from "@mui/material/styles";

import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import AddIcon from "@mui/icons-material/Add";

import { set } from "date-fns";
import { setIn } from "formik";
import { is } from "date-fns/locale";
import { re } from "mathjs";

// const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9_-]+$/;
const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9_]+$/;

export default function AiAgentPresetAgentsDialog(props) {
  const theme = useTheme();

  const { userData } = props;


  const { showAdminAiPresetAgentsDialog, setShowAdminAiPresetAgentsDialog } = useContext(AdminAiAuthContext);
  // const [overwriteAiPresetAgentData, setOverwriteAiPresetAgentData] = useState(false);

  const { adminAiCategoryAgentsDocs, setAdminAiCategoryAgentsDocs } = useContext(AdminAuthContext);

  const { adminIsSavingPresetAgent, setAdminIsSavingPresetAgent } = useContext(AdminAiAuthContext);

  const { getCustomerAiPresetAgentRefDocs } = useContext(AdminAiAuthContext);

  const [aiCategoryAgentSelected, setAiCategoryAgentSelected] = useState(null);

  const [allowPresetAgentsOverwrite, setAllowPresetAgentsOverwrite] = useState(null);

  const { adminCustomerAiPresetAgentRefDocsFound, setAdminCustomerAiPresetAgentRefDocsFound } = useContext(AdminAiAuthContext);

  const { saveAdminAiPresetAgentToCustomer } = useContext(AdminAiAuthContext);

  const [refreshPresetAgents, setRefreshPresetAgents] = useState(false);

  // const [init, setInit] = useState(false);


  // triggered when category is selected
  useEffect(() => {
    if (aiCategoryAgentSelected) {
      getData()
        // make sure to catch any error
        .catch(console.error);
    }
  }, [aiCategoryAgentSelected]);

    // triggered after saving aiprestagent to Customer
    useEffect(() => {
      if (refreshPresetAgents) {
        getData()
          // make sure to catch any error
          .catch(console.error);

          setRefreshPresetAgents(false)
      }
    }, [refreshPresetAgents]);


  const getData = async () => {
    if (aiCategoryAgentSelected !== null) {

      let locationId = "";
      if (showAdminAiPresetAgentsDialog.locationid) {
        locationId = showAdminAiPresetAgentsDialog.locationid;
      }

      const result = await getCustomerAiPresetAgentRefDocs(userData, locationId, aiCategoryAgentSelected);
      if (result?.data) {
        setAdminCustomerAiPresetAgentRefDocsFound(result.data);
        let allowedPresentAgentsSave = {};
        if (aiCategoryAgentSelected.aipresetagents && aiCategoryAgentSelected.aipresetagents.length > 0) {
          // loop over aiCategoryAgentSelected.aipresetagents
          for (let i = 0; i < aiCategoryAgentSelected.aipresetagents.length; i++) {
            const presetagent = aiCategoryAgentSelected.aipresetagents[i];

            let allowAdd = true;
            if (result.data.customerDocs[presetagent.presetref]) {
              allowAdd = false;
            }
            allowedPresentAgentsSave[presetagent.presetref] = allowAdd;

          }

        }
        // save allowedPresentAgentsSave for checkboxes if needed later
        setAllowPresetAgentsOverwrite(allowedPresentAgentsSave)

      }
      else {
        setAdminCustomerAiPresetAgentRefDocsFound(null);
        setAllowPresetAgentsOverwrite(null)
      }
      // let name = "";
      // if (aiAgentsSelected?.name) {
      //   name = aiAgentsSelected.name;
      // }
      // setAiPresetAgentName(name);
      // // check if
      // const result = await getAiPresetAgentRefDoc(userData, aiAgentsSelected);
      // if (result?.data) {
      //   setAiPresetAgentRefDoc(result.data);
      //   if (result.data.docExists) {
      //     setAiPresetAgentRefDocName(result.data.aipresetagentsDoc.name);
      //   }
      // }
      // setInit(true);
    }
  };

  // useEffect(() => {
  //   const getData = async () => {
  //     if (aiAgentsSelected !== null) {
  //       let name = "";
  //       if (aiAgentsSelected?.name) {
  //         name = aiAgentsSelected.name;
  //       }
  //       setAiPresetAgentName(name);
  //       // check if
  //       const result = await getAiPresetAgentRefDoc(userData, aiAgentsSelected);
  //       if (result?.data) {
  //         setAiPresetAgentRefDoc(result.data);
  //         if (result.data.docExists) {
  //           setAiPresetAgentRefDocName(result.data.aipresetagentsDoc.name);
  //         }
  //       }
  //       setInit(true);
  //     }
  //   };

  //   if (showAiPresetAgentsDialog) {
  //     getData()
  //       // make sure to catch any error
  //       .catch(console.error);
  //   }
  // }, [showAiPresetAgentsDialog]);


  const handleAdminAiPresetAgentsDialogClose = () => {
    setShowAdminAiPresetAgentsDialog({
      show: false,
      locationid: "",
      userid: "",
    });
    setAiCategoryAgentSelected(null);
    setAdminCustomerAiPresetAgentRefDocsFound(null);
    setAllowPresetAgentsOverwrite(null);
  };


  // const handleOnChangeAiAgentPresetName = (event) => {
  //   setAiPresetAgentName(event.target.value);
  // };

  // const isAiPresetAgentSaveDisabled = () => {
  //   let disabled = false;
  //   if (aiPresetAgentName === "") {
  //     disabled = true;
  //   }

  //   return disabled;
  // }


  // const handleOnClickSavePresetAgentConfirm = async () => {
  //   if (clog()) console.log("handleOnClickSavePresetAgentConfirm");
  //   // // setAiSettingsName(name)
  //   setAiAgentsIsSavingPresetAgent(true);

  //   let type = "new";
  //   if (aiPresetAgentRefDoc.docExists === true && overwriteAiPresetAgentData === true) {
  //     type = "overwrite";
  //   }
  //   else if (aiPresetAgentRefDoc.docExists === true && overwriteAiPresetAgentData === false) {
  //     type = "duplicate";
  //   }

  //   let presetAgentName = aiPresetAgentName;
  //   if (aiPresetAgentRefDoc.docExists === true && overwriteAiPresetAgentData === true) {
  //     presetAgentName = aiPresetAgentRefDocName;
  //   }
  //   const result = await saveAiPresetAgent(userData, presetAgentName, type, aiPresetAgentRefDoc);

  //   if (result && result.data) {
  //     // what to do here?
  //     if (clog()) console.log("handleOnClickSavePresetAgent result", result.data);
  //   } else {
  //     if (clog()) console.log("handleOnClickSavePresetAgent result", result.error);
  //   }


  //   setAiAgentsIsSavingPresetAgent(false);

  //   // reset name
  //   setAiPresetAgentName("");

  //   setShowAdminAiPresetAgentsDialog(false);

  //   // show alert
  //   setShowSavePresetAgentAlert(true);
  // };



  const getAiPresetAgentOverwriteData = (presetagent) => {
    let returnVal = true;
    const presetref = presetagent.presetref
    if (allowPresetAgentsOverwrite && allowPresetAgentsOverwrite[presetref] === false) {
      returnVal = false;
    }
    else if (allowPresetAgentsOverwrite && allowPresetAgentsOverwrite[presetref] === true) {
      returnVal = true;
    }
    return returnVal;

  };

  const handleAiPresetAgentOverwriteData = (presetagent) => {
    // setOverwriteAiPresetAgentData(!overwriteAiPresetAgentData);

    setAllowPresetAgentsOverwrite({
      ...allowPresetAgentsOverwrite,
      [presetagent.presetref]: !allowPresetAgentsOverwrite[presetagent.presetref],
    })


    // save allowedPresentAgentsSave for checkboxes if needed later
    // setAllowPresetAgentsOverwrite(allowedPresentAgentsSave)

  }


  const handleOnClickPresetAgentAdd = async (presetagent) => {

    if (clog()) console.log("handleOnClickPresetAgentAdd", presetagent);

    let locationId = "";
    if (showAdminAiPresetAgentsDialog.locationid) {
      locationId = showAdminAiPresetAgentsDialog.locationid;
    }

    let userId = "";
    if (showAdminAiPresetAgentsDialog.userid) {
      userId = showAdminAiPresetAgentsDialog.userid;
    }

    let presetref = ''
    if (presetagent.presetref) {
      presetref = presetagent.presetref;
    }

    if (presetref !== '') {
      setAdminIsSavingPresetAgent({
        saving: true,
        locationid: showAdminAiPresetAgentsDialog.locationid,
        presetref: presetref,

      })

      // check if aiagent needs to be replaced
      let replaceAgent = []
      if (adminCustomerAiPresetAgentRefDocsFound?.customerDocs && adminCustomerAiPresetAgentRefDocsFound.customerDocs[presetref]) {
        replaceAgent = adminCustomerAiPresetAgentRefDocsFound.customerDocs[presetref]
      }

      // build uData that function expects
      const uData = {
        locationId: locationId,
        userId: userId,
      }

      const result = await saveAdminAiPresetAgentToCustomer(uData, presetagent, replaceAgent);

      if (result && result.data) {
        setAdminIsSavingPresetAgent({
          saving: false,
          locationid: '',
          presetref: '',
        })
        // trigger fetch of customer presetagents
        setRefreshPresetAgents(true)
      }



      // setAdminIsSavingPresetAgent({
      //   saving: false,
      //   locationid: '',
      //   presetref: '',
      // })

    }
  }


  const isAiPresetAgentAddDisabled = (presetagent) => {
    let disabled = false;

    const presetref = presetagent.presetref
    if (allowPresetAgentsOverwrite && allowPresetAgentsOverwrite[presetref] === false) {
      disabled = true;
    }
    else if (allowPresetAgentsOverwrite && allowPresetAgentsOverwrite[presetref] === true) {
      disabled = false;
    }

    if (adminIsSavingPresetAgent.saving === true && adminIsSavingPresetAgent.presetref !== presetref) {
      disabled = true;
    }
    return disabled;

  }

  const isAiPresetAgentOverwriteDisabled = (presetagent) => {
    let disabled = false;

    if (adminIsSavingPresetAgent.saving === true) {
      disabled = true;
    }
    return disabled;

  }



  const getAiCategoryAgentVal = () => {
    let returnVal = -1;
    let aiCategoryAgentId = "";

    if (aiCategoryAgentSelected?.aicategoryagentid) {
      aiCategoryAgentId = aiCategoryAgentSelected.aicategoryagentid;
    }

    let categoryagents = [];
    if (adminAiCategoryAgentsDocs && adminAiCategoryAgentsDocs.length > 0) {
      categoryagents = adminAiCategoryAgentsDocs;
    }

    if (categoryagents.length > 0 && aiCategoryAgentId !== "") {
      var found = _.findIndex(categoryagents, { aicategoryagentid: aiCategoryAgentId });
      if (found > -1) {
        returnVal = found;
      }
    }

    return returnVal;
  };

  const handleOnChangeAiCategoryAgent = (event) => {
    if (clog()) console.log("event:", event.target.value);

    // let fullData = customersData.data

    let categoryagents = [];
    if (adminAiCategoryAgentsDocs && adminAiCategoryAgentsDocs.length > 0) {
      categoryagents = adminAiCategoryAgentsDocs;
    }

    // update item
    const val = event.target.value;

    let selectedCategory = null;
    if (categoryagents && categoryagents[val]) {
      selectedCategory = categoryagents[val];
    }

    setAiCategoryAgentSelected(selectedCategory);

    // // update item
    // // let dataItem = _.cloneDeep(customersDataOrig[itemId])
    // let dataItem = { ...customersDataOrig[itemId] };

    // // init data if it doesn't exist
    // if (!dataItem.aidata) {
    //   dataItem.aidata = {};
    // }

    // // set val
    // dataItem.aidata = {
    //   ...dataItem.aidata,
    //   aicategoryagentid: selectedCategory.aicategoryagentid,
    // };

    // setCustomersDataOrig({
    //   ...customersDataOrig,
    //   [itemId]: dataItem,
    // });
  };



  const showPresetAgentsRows = () => {

    const test = aiCategoryAgentSelected
    const test2 = adminCustomerAiPresetAgentRefDocsFound

    // load rows
    if (
      aiCategoryAgentSelected?.aicategoryagentid &&
      adminCustomerAiPresetAgentRefDocsFound?.aicategoryagentid &&
      adminCustomerAiPresetAgentRefDocsFound.aicategoryagentid === aiCategoryAgentSelected.aicategoryagentid
    ) {

      // loop over aiCategoryAgentSelected.aipresetagents
      if (aiCategoryAgentSelected.aipresetagents && aiCategoryAgentSelected.aipresetagents.length > 0) {
        return (
          <Box
            sx={{
              mt: 2,
              width: 500,
            }}
          >
            <Typography
              variant="h5" component="div">
              Preset Agents
            </Typography>

            <List sx={{ width: '100%', maxWidth: 500, bgcolor: 'background.paper' }}>

              {aiCategoryAgentSelected.aipresetagents.map((presetAgent, index) => {


                let showOverwrite = false;
                const presetref = presetAgent.presetref

                // check if adminCustomerAiPresetAgentRefDocsFound[presetref] exists
                if (adminCustomerAiPresetAgentRefDocsFound?.customerDocs[presetref]) {
                  showOverwrite = true;
                }

                return (
                  <ListItem
                    key={index}
                    sx={{
                      py: 2,
                      backgroundColor: index % 2 === 0 ? 'grey.100' : 'background.paper',
                    }}
                    secondaryAction={
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          // height: '100%',
                          // width: 300,
                          // mt: 2,
                        }}
                      >

                        {showOverwrite && (
                          <FormControlLabel
                            label="Overwrite"
                            control={
                              <Checkbox
                                checked={getAiPresetAgentOverwriteData(presetAgent)}
                                onChange={() => handleAiPresetAgentOverwriteData(presetAgent)}
                                disabled={isAiPresetAgentOverwriteDisabled(presetAgent)}
                              />
                            }
                          />
                        )}



                        {
                          adminIsSavingPresetAgent.saving === true &&
                            adminIsSavingPresetAgent.presetref === presetref ? (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "64px",
                                height: "30px",
                                mr: '9px',
                              }}
                            >
                              <CircularProgress
                                size={20}
                                sx={{
                                  p: 0,
                                  m: 0,
                                }}
                              // variant="determinate"
                              />
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                width: "73px",
                              }}
                            >
                              <Button
                                aria-label="Add"
                                color="primary"
                                variant="outlined"
                                sx={{
                                  mr: 1,
                                }}
                                onClick={() => handleOnClickPresetAgentAdd(presetAgent)}
                                disabled={isAiPresetAgentAddDisabled(presetAgent)}
                              >
                                Add
                              </Button>
                            </Box>
                          )}

                      </Box>
                    }
                    disablePadding
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        pl: 2,
                      }}
                    >
                      <Typography>
                        {
                          presetAgent.name
                          // presetAgent.presetref
                        }
                      </Typography>
                    </Box>

                  </ListItem>
                );
              })}

            </List>
          </Box>
        )
      }
      else if (aiCategoryAgentSelected.aipresetagents && aiCategoryAgentSelected.aipresetagents.length === 0) {
        return (
          <Box
            sx={{
              width: 500,
              height: 100,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="h5" component="div">
              No Preset Agents Found
            </Typography>
          </Box>
        )
      }
    }

    // aiCategoryAgentSelected but adminCustomerAiPresetAgentRefDocsFound is null
    // aiCategoryAgentSelected but adminCustomerAiPresetAgentRefDocsFound categoryagentid is different
    if (
      aiCategoryAgentSelected?.aicategoryagentid &&
      adminCustomerAiPresetAgentRefDocsFound === null
      ||
      aiCategoryAgentSelected?.aicategoryagentid &&
      adminCustomerAiPresetAgentRefDocsFound?.aicategoryagentid &&
      adminCustomerAiPresetAgentRefDocsFound.aicategoryagentid !== aiCategoryAgentSelected.aicategoryagentid
    ) {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: 100,
            width: 500,
            mt: 2,
          }}
        >
          <CircularProgress />
        </Box>
      )
    }

  }

  let open = false;
  if (showAdminAiPresetAgentsDialog.show === true) {
    open = true;
  }



  let title = "Add Agents To Location";

  return (
    <Dialog
      name="aiagentsaveagentpresetdialog"
      open={open}
      onClose={handleAdminAiPresetAgentsDialogClose}
      maxWidth="sm"
      sx={{
        "& .MuiDialog-paper": {
          position: "absolute",
          top: "10px",
          // left: "50%",
          // transform: "translate(-50%, -50%)",
        },
        // position: "absolute",
        // top: "0px",
        // alignItems: "flex-start",
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent
        dividers={true}
      >

        {
          <Box
            sx={{
              width: 500,
            }}
          >
            <FormControl
              sx={{
                mt: 2,
                width: '100%',
              }}
            >
              <InputLabel>Agent Collection</InputLabel>
              <Select
                // id="demo-simple-select"
                // value={age}
                // placeholder="Select Agent Collection"
                label="Agents"
                // onChange={handleChange}
                // value={5}
                // value={row.data && row.data.membership ? row.data.membership : ''}
                value={getAiCategoryAgentVal()}
                onChange={(event) => handleOnChangeAiCategoryAgent(event)}
              // sx={{
              //   width: 300,
              // }}
              >
                <MenuItem
                  value={-1}
                  disabled={true}
                >
                  Select Agent Collection
                </MenuItem>
                {adminAiCategoryAgentsDocs &&
                  adminAiCategoryAgentsDocs.map((c, index) => {
                    return (
                      <MenuItem key={"aicategoryagent" + index} value={index}>
                        {c.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Box>
        }


        {showPresetAgentsRows()}


        {
          // init === true ? (
          //   <>
          //     <DialogContentText>Save AI Agent Preset</DialogContentText>

          //     <Box
          //       sx={{
          //         mt: 2,
          //         width: 300,
          //       }}
          //     >
          //       <TextField
          //         required
          //         fullWidth
          //         label="Agent Preset Name"
          //         name="agentpresetname"
          //         autoComplete="agentpresetname"
          //         placeholder="Add Agent Preset Name..."
          //         // disabled={true}
          //         // InputProps={{
          //         //   readOnly: aiAgentsIsEditing.active === true && aiAgentsIsEditing.type === "add" ? false : true,
          //         // }}
          //         InputLabelProps={{ shrink: true }}
          //         // autoFocus
          //         // value={aiSettingsDataUpdated.data.name}
          //         value={aiPresetAgentRefDoc.docExists === true && overwriteAiPresetAgentData ? aiPresetAgentRefDocName : aiPresetAgentName}
          //         onChange={(e) => handleOnChangeAiAgentPresetName(e)}
          //       // onKeyDown={(event) => {
          //       //   if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
          //       //     event.preventDefault();
          //       //   }
          //       // }}
          //       // error={aiAgentsAutomationLinkHasError() === true ? true : false}
          //       // helperText={aiAgentsAutomationLinkErrorText()}
          //       disabled={aiPresetAgentRefDoc.docExists === true && overwriteAiPresetAgentData ? true : false}
          //       />
          //     </Box>

          //     {aiPresetAgentRefDoc.docExists === true ? (
          //       <Box
          //         sx={{
          //           mt: 2,
          //           // width: 300,
          //         }}
          //       >
          //         <FormControlLabel
          //           label="Overwrite Preset"
          //           control={
          //             <Checkbox
          //               checked={overwriteAiPresetAgentData}
          //               onChange={handleAiPresetAgentOverwriteData}
          //               disabled={aiPresetAgentRefDoc.docExists === false ? true : false}
          //             />
          //           }
          //         />
          //       </Box>

          //     ) : (
          //       <></>
          //     )}
          //   </>
          // ) : (
          //   <Box
          //     sx={{
          //       display: 'flex',
          //       flexDirection: 'column',
          //       justifyContent: 'center',
          //       alignItems: 'center',
          //       height: '100%',
          //       width: 300,
          //     }}
          //   >
          //     <CircularProgress />
          //   </Box>
          // )
        }
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",

            mr: 1,
          }}
        >
          <Button
            aria-label="Close"
            color="primary"
            variant="contained"
            // variant="outlined"
            sx={{
              mr: 1,
            }}
            onClick={handleAdminAiPresetAgentsDialogClose}
          // disabled={adminIsSavingPresetAgent}
          >
            Close
          </Button>

          {
            // adminIsSavingPresetAgent ? (
            //   <Box
            //     sx={{
            //       display: "flex",
            //       alignItems: "center",
            //       justifyContent: "center",
            //       width: "64px",
            //       height: "36.5px",
            //     }}
            //   >
            //     <CircularProgress
            //       size={12}
            //       sx={{
            //         p: 0,
            //         m: 0,
            //       }}
            //     // variant="determinate"
            //     />
            //   </Box>
            // ) : (
            //   <Button
            //     aria-label="Save"
            //     color="primary"
            //     variant="contained"
            //   // onClick={handleOnClickSavePresetAgentConfirm}
            //   // disabled={isAiPresetAgentSaveDisabled()}
            //   >
            //     Save
            //   </Button>
            // )
          }
        </Box>
      </DialogActions>
    </Dialog>
  );
}
