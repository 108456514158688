import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import PropTypes from "prop-types";

import {
  getFirestore,
  collection,
  query,
  where,
  // orderBy
} from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { firebase } from "../code/firebase";
import { processKeywordsUserData } from "../code/ecomacy";

import { RemoteConfigAuthContext } from "../helpers/RemoteConfigAuthProvider";
import { AiAuthContext } from "../helpers/AiAuthProvider";
import {setd, debug, setc, clog} from "../helpers/HelperFunctions";

import _ from "lodash";

import { useTheme, styled } from "@mui/material/styles";

import {
  Box,
  Tab,
  Tabs,
  Typography,
  Button,
  IconButton,
  Grid,
  Paper,
  Menu,
  MenuItem,
  ListSubheader,
  InputAdornment,
  Divider,
  // Tooltip,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import TextField from "@mui/material/TextField";

import SendIcon from "@mui/icons-material/Send";
import RefreshIcon from "@mui/icons-material/Refresh";
import SyncIcon from "@mui/icons-material/Sync";
import SettingsIcon from "@mui/icons-material/Settings";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CircleIcon from "@mui/icons-material/Circle";

// import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';

import Container from "@mui/material/Container";
import GridKeywordTimelinePane from "./GridKeywordTimelinePane";
import LoadingSubPane from "./LoadingSubPane";
import { RunCircle } from "@mui/icons-material";

// import MainCard from './MainCard';
// import { getDfsLocationData } from '../code/ecomacy'

// import './GridKeywordsPane.css'
// const auth = getAuth(firebase)

/*
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    // color: theme.palette.common.black,
    color: theme.palette.primary.main,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: theme.palette.primary.main,
  },
}));

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    // fontSize: 11,
  },
}));
*/
const BorderTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    // boxShadow: theme.shadows[1],
  },
  [`& .${tooltipClasses.arrow}::before`]: {
    color: theme.palette.common.white,
    // boxShadow: theme.shadows[1],

    border: `1px solid ${theme.palette.primary.main}`,
    boxSizing: "border-box",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    // boxShadow: theme.shadows[1],
    fontSize: "14px",
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

const AiChatLivePanel = (props) => {
  const { userData } = props;
  const theme = useTheme();

  // const { rc } = useContext(RemoteConfigAuthContext);


  const { testVal, setTestVal } = useContext(AiAuthContext);

  const { aiAgentsIsRemoving, setAiAgentsIsRemoving } = useContext(AiAuthContext);

  const { aiSettingsData, setAiSettingsData } = useContext(AiAuthContext);
  const { aiSettingsDataUpdated, setAiSettingsDataUpdated } = useContext(AiAuthContext);
  const { aiSettingsDataRef } = useContext(AiAuthContext);

  const { aiStrategySelectedRefresh, setAiStrategySelectedRefresh } = useContext(AiAuthContext);
  const { aiModelSelectedRefresh, setAiModelSelectedRefresh } = useContext(AiAuthContext);

  const { aiChatInput, setAiChatInput } = useContext(AiAuthContext);

  const { postAiMessage } = useContext(AiAuthContext);
  const { resetAiMessages } = useContext(AiAuthContext);
  const { aiConversationsIsSaving, setAiConversationsIsSaving } = useContext(AiAuthContext);
  const { aiConversationsIsRemoving, setAiConversationsIsRemoving } = useContext(AiAuthContext);

  // const { aiChatMessagesData, setAiChatMessagesData } = useContext(AiAuthContext);

  const { aiLiveConversationsSelected, setAiLiveConversationsSelected } = useContext(AiAuthContext);
  const { aiLiveConversationFetchData, setAiLiveConversationFetchData } = useContext(AiAuthContext);
  const { getAiLiveConversationAndMessages } = useContext(AiAuthContext);
  const { aiLiveConversation, setAiLiveConversation } = useContext(AiAuthContext);
  const { aiLiveMessages, setAiLiveMessages } = useContext(AiAuthContext);


  const { aiSettingsSelected, setAiSettingsSelected } = useContext(AiAuthContext);
  const { aiConversationsSelected, setAiConversationsSelected } = useContext(AiAuthContext);
  const { aiConversation, setAiConversation } = useContext(AiAuthContext);
  const { aiMessages, setAiMessages } = useContext(AiAuthContext);
  const [aiMessagesUserSending, setAiMessagesUserSending] = useState(false);

  const { aiConversationMessageCount } = useContext(AiAuthContext);
  // const { getAiConversation } = useContext(AiAuthContext);
  const { getAiConversationAndMessages } = useContext(AiAuthContext);

  const { refreshAiConversationSettingsData, setRefreshAiConversationSettingsData } = useContext(AiAuthContext);
  const { duplicateAiConversation } = useContext(AiAuthContext);
  const { aiConversationDuplicatedRefresh, setAiConversationDuplicatedRefresh } = useContext(AiAuthContext);

  const { aiAgentsIsEditing, setAiAgentsIsEditing } = useContext(AiAuthContext);
  const { aiSettingsIsEditing, setAiSettingsIsEditing } = useContext(AiAuthContext);
  const { aiConversationsIsEditing, setAiConversationsIsEditing } = useContext(AiAuthContext);
  const { aiConversationFetchData, setAiConversationFetchData } = useContext(AiAuthContext);

  const [init, setInit] = useState(true);

  const messageCount = useRef(0);
  const scrollRef = useRef();

  // if (clog()) console.log('AiChatPanel aiSettingsData', aiSettingsData);

  // initial load
  useEffect(() => {
    if (init && aiLiveConversationsSelected) {
      fetchAiLiveConversationData()
        // make sure to catch any error
        .catch(console.error);
      setInit(false);
    }

    else if (init && aiLiveConversationsSelected === null) {

      setInit(false);
    }
  }, [init, aiLiveConversationsSelected]);

  // useEffect(() => {
  //   if (!init) {
  //     fetchAiLiveConversationData()
  //       // make sure to catch any error
  //       .catch(console.error);
  //   }
  // }, [aiSettingsData])

  // aiLiveConversationsSelected is changed
  useEffect(() => {
    if (!init && aiConversationFetchData === false) {
      // aiConversationSelected data has been updated but is still the same conversation
      // happens when editing the name of a selected conversation in HistoryPanel
      setAiConversationFetchData(true);
    } else if (!init && aiLiveConversationsSelected) {
      fetchAiLiveConversationData()
        // make sure to catch any error
        .catch(console.error);
      // setInit(false)
    } else if (!init && aiLiveConversationsSelected === null) {
      // no conversation exists, reset selected aisettings data
      setAiSettingsData(null);
      setAiSettingsDataUpdated(null);
      aiSettingsDataRef.current = null;
      // trigger useEffect in AiModelPanel
      setAiModelSelectedRefresh(true);
      // trigger useEffect in AiQuestionsPanel
      setAiStrategySelectedRefresh(true);
    }
  }, [aiLiveConversationsSelected]);

  // const fetchAiLiveConversationData = useCallback(async () => {
  //   // BT ZZZ

  //   const result = await getAiConversation(userData, aiLiveConversationsSelected);
  //   // trigger useEffect in AiHistoryPanel
  //   setRefreshAiConversationSettingsDataX(true);
  //   if (result && result.data) {
  //     //   setAiConversation(result.data)
  //     if (result.data.messages && result.data.messages.length > 0) {
  //       // setAiChatMessagesData(result.data.messages)
  //       messageCount.current = result.data.messages.length;
  //     }
  //   }
  // }, [getAiConversation]);

  const fetchAiLiveConversationData = useCallback(async () => {
    // BT ZZZ
    const result = await getAiLiveConversationAndMessages(userData, aiLiveConversationsSelected);
    // trigger useEffect in AiHistoryPanel
    setRefreshAiConversationSettingsData(true);
    if (result && result.aiconversation && result.aiconversation.data) {
      //   setAiConversation(result.data)
      if (result.aimessages.data && result.aimessages.data.length > 0) {
        // setAiChatMessagesData(result.data.messages)
        messageCount.current = result.aimessages.data.length;
      }
      else {
        messageCount.current = 0;
      }
    }
  }, [getAiLiveConversationAndMessages]);


  //// START: Scroll Into View
  // const scrollRef = useRef(); <- defined with states etc

  // run after every render
  useEffect(() => {
    scrollRefIntoView()
  });

  const scrollRefIntoView = () => {
    // if element exists then scroll into view
    if (scrollRef.current) {
      // param: defaultVal
      //  ...options
      // behavior:"auto",
      //   auto | smooth
      // block: "start",
      //   start | center | end | nearest
      // inline:"nearest"
      //   start | center | end | nearest
      const opts = {
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      }
      scrollRef.current.scrollIntoView(opts)
    }
  };

  const isScollRef = (type, index = -1) => {
    let isSelected = false

    // aiMessagesUserSending <- human sent message, waiting for it to appear
    // isChatWaitingForAiResponse <- human message appeared, waiting for AI response

    const isWaiting = isChatWaitingForAiResponse()
    if (isWaiting === false && aiMessagesUserSending === false) {
      if ("message" === type) {
        if (index === aiLiveMessages.length - 1) {
          isSelected = true
        }
      }
    }

    return isSelected

  }
  //// END: Scroll Into View

  useEffect(() => {
    if (aiLiveMessages && aiLiveMessages.length !== messageCount.current) {
      // update message count ref
      messageCount.current = aiLiveMessages.length;
      if (aiMessagesUserSending) {
        setAiMessagesUserSending(false)
      }
    }
  }, [aiLiveMessages]);


  const showNoMessages = () => {
    let messageCount = 0;
    if (aiLiveMessages && aiLiveMessages.length > 0) {
      messageCount = aiLiveMessages.length;
    }


    if (aiMessagesUserSending === false) {

      if (messageCount === 0 || (messageCount === 1 && aiLiveMessages[0].type === "aisettings")) {
        return (
          <Box
            sx={{
              // mt: 2,
              display: "flex",
              flexDirection: "column",
              width: "100%",
              // height: "100%",
              alignItems: 'center',
              justifyContent: "center",
            }}
          >
            <Box
              name={"roleIcon"}
              sx={{
                mt: -4,
                width: "100px",
                pt: 2,
                // backgroundColor: '#cc0000',
                display: "flex",
                // alignItems: 'center',
                justifyContent: "center",
              }}
            >
              <SupportAgentIcon
                sx={{
                  fontSize: "100px",
                  color: "grey.300",
                }}
              // color='primary'
              />
            </Box>
            <Typography
              align="center"
              sx={{
                fontSize: "18px",
                color: "grey.600",
              }}
            >
              No messages yet
            </Typography>
          </Box>
        );
      } else {
        return <></>;
      }
    }
    else {
      return <></>;
    }
  };

  const showNoConverationMessages = () => {

    return (
      <Box
        sx={{
          // mt: 2,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          // height: "100%",
          alignItems: 'center',
          justifyContent: "center",
        }}
      >
        <Box
          name={"roleIcon"}
          sx={{
            mt: -4,
            width: "100px",
            pt: 2,
            // backgroundColor: '#cc0000',
            display: "flex",
            // alignItems: 'center',
            justifyContent: "center",
          }}
        >
          <SupportAgentIcon
            sx={{
              fontSize: "100px",
              color: "grey.300",
            }}
          // color='primary'
          />
        </Box>
        <Typography
          align="center"
          sx={{
            fontSize: "18px",
            color: "grey.600",
          }}
        >
          Select A Conversation
        </Typography>
      </Box>
    );

  };

  const isChatWaitingForAiResponse = () => {

    let waiting = false;
    // last message is human so waiting for ai response
    if (aiLiveMessages && aiLiveMessages.length > 0 && aiLiveMessages[aiLiveMessages.length - 1].type === "human") {
      waiting = true
    }

    return waiting
  }
  const showChatWaitingResponse = () => {
    // const m = aiEngineersSelected;
    // if (aiEngineerMessages && aiEngineerMessages.length > 0) {
    //   return <></>;
    // }
    const isWaiting = isChatWaitingForAiResponse()
    // if last message is user then waiting for agent response
    if (isWaiting) {
      return (
        <Grid
          item
          xs={12}
          key={'chatawaitingresponse'}
          name={'chatawaitingresponse'}
          sx={{
            px: 2,
            py: 1,
          }}
          ref={scrollRef}
        >
          <Box name={"background"}
            sx={{
              // backgroundColor: m.role === "assistant" ? "#efefef" : "#f9f9f9",
              backgroundColor: theme.palette.background.paper,
              borderRadius: "5px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: theme.palette.border.normal,
              boxShadow: theme.palette.boxshadow.normal,
              display: "flex",
              flexDirection: "row",
              minHeight: "56px",
              // backgroundColor: m.role === 'assistant' ? '#efefef' : '#fff',
            }}
          >
            <Box name={"icon"}>
              <Box
                name={"roleIcon"}
                sx={{
                  width: "56px",
                  minWidth: "56px",
                  pt: 2,
                  // backgroundColor: '#cc0000',
                  display: "flex",
                  // alignItems: 'center',
                  justifyContent: "center",
                }}
              >
                <SupportAgentIcon
                  sx={{
                    color: "grey.500",
                  }}
                // color='primary'
                />
              </Box>
            </Box>

            <Box name={"message"}
              sx={{
                // backgroundColor: m.role === "assistant" ? "#efefef" : "#f9f9f9",
                flexGrow: 1,
                // px: 2,
                px: 0,
                py: 2,
                borderRadius: "5px",
              }}
            >
              <Box>
                <div className="dot-holder">
                  <div
                    className="dot-pulse"
                    style={{
                      "--ca-agentmessageloading": theme.palette.primary.main,
                    }}
                  ></div>
                </div>
              </Box>
            </Box>
            <Box name={"settings"}
              sx={{
                width: "56px",
                minWidth: "56px",
                pt: "10px",
                px: "5px",
                display: "flex",
                // alignItems: 'center',
                justifyContent: "center",
              }}
            ></Box>
          </Box>
        </Grid>
      );
    }
    else if (aiMessagesUserSending) {
      // if user sent a message and waiting for it to appear in aimessages
      return (
        <Grid
          item
          xs={12}
          key={'chatawaitingresponse'}
          name={'chatawaitingresponse'}
          sx={{
            px: 2,
            py: 1,
          }}
          ref={scrollRef}
        >
          <Box name={"background"}
            sx={{
              // backgroundColor: m.role === "assistant" ? "#efefef" : "#f9f9f9",
              backgroundColor: theme.palette.background.paper,
              borderRadius: "5px",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: theme.palette.border.normal,
              boxShadow: theme.palette.boxshadow.normal,
              display: "flex",
              flexDirection: "row",
              minHeight: "56px",
              // backgroundColor: m.role === 'assistant' ? '#efefef' : '#fff',
            }}
          >
            <Box name={"settings"}
              sx={{
                width: "56px",
                minWidth: "56px",
                pt: "10px",
                px: "5px",
                display: "flex",
                // alignItems: 'center',
                justifyContent: "center",
              }}
            ></Box>
            <Box name={"message"}
              sx={{
                // backgroundColor: m.role === "assistant" ? "#efefef" : "#f9f9f9",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                flexGrow: 1,
                // px: 2,
                px: 0,
                py: 2,
                borderRadius: "5px",
              }}
            >
              <Box>
                <div className="dot-holder">
                  <div
                    className="dot-pulse"
                    style={{
                      "--ca-agentmessageloading": theme.palette.primary.main,
                    }}
                  ></div>
                </div>
              </Box>
            </Box>
            <Box name={"icon"}>
              <Box name={"roleIcon"}
                sx={{
                  width: "56px",
                  minWidth: "56px",
                  pt: 2,
                  // backgroundColor: '#cc0000',
                  display: "flex",
                  // alignItems: 'center',
                  justifyContent: "center",
                }}
              >
                <AccountCircleIcon
                  sx={{
                    color: "grey.500",
                  }}
                // color='primary'
                />
              </Box>
            </Box>
          </Box>
        </Grid>
      );
    }
  };

  if (aiAgentsIsRemoving || aiConversationsIsRemoving) {
    return <LoadingSubPane spin={true} />;
  }

  if (clog()) console.log('AiChatLivePanel aiLiveConversation', aiLiveConversation);
  if (aiLiveConversation) {
    // if (clog()) console.log('AiChatPanel aiConversation', aiConversation);
    return (
      <Box
        name={"chatPanel"}
        height={"100%"}
        // bgcolor="#cc0000"
        sx={{
          display: "flex",
          flexDirection: "column",
          // overflow: 'hidden',
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: theme.palette.border.normal,
          // backgroundColor: '#efefef',
          borderRadius: "4px",
          overflow: "hidden",
          backgroundColor: theme.palette.background.bg,
        }}
      >
        <Box
          name={"chatMessages"}
          sx={{
            flexGrow: 1,
            overflow: "hidden",
            height: "100%",
            backgroundColor: messageCount === 0 || (messageCount === 1 && aiLiveMessages[0].type === "aisettings") ? "transparent" : theme.palette.background.paper,
          }}
        >
          <Box
            id={"chatMessagesScrollWrapper"}
            sx={{
              maxHeight: "100%",
              height: (aiLiveMessages && aiLiveMessages.length > 0) || (aiLiveMessages && aiLiveMessages.length === 0 && aiMessagesUserSending) ? "auto" : "100%",
              overflow: "auto",
            }}
          >
            <Grid
              container
              // component={wrapper}
              // ref={wrapper} // Option 1: use ref to scroll to bottom
              sx={{
                my: (aiLiveMessages && aiLiveMessages.length > 0) || (aiLiveMessages && aiLiveMessages.length === 0 && aiMessagesUserSending) ? 1 : 0,
                height: (aiLiveMessages && aiLiveMessages.length > 0) || (aiLiveMessages && aiLiveMessages.length === 0 && aiMessagesUserSending) ? "auto" : "100%",
              }}
            >
              {
                showNoMessages()
              }
              {aiLiveMessages?.map((m, i) => {
                // const test = m
                // check to see if next message exists
                let settingsMessage = null;
                // if (i + 1 < aiLiveMessages.length) {
                //   const prevMessage = aiLiveMessages[i - 1];
                //   // has the type of 'aisettings'
                //   if (prevMessage.type === 'aisettings') {
                //     settingsMessage = prevMessage
                //   }
                // }
                const prevMessageIndex = i - 1;
                if (prevMessageIndex > -1) {
                  const prevMessage = aiLiveMessages[prevMessageIndex];
                  // has the type of 'aisettings'
                  if (prevMessage.type === "aisettings") {
                    settingsMessage = prevMessage;
                  }
                }

                let isLastMessage = false;
                if (i === aiLiveMessages.length - 1) {
                  isLastMessage = true;
                }
                if (isLastMessage && m.type === "aisettingsX") {
                  settingsMessage = m;

                  return (
                    <Grid item xs={12} key={i} name={m.type}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          minHeight: "56px",
                        }}
                      >
                        <Box
                          sx={{
                            width: "56px",
                            minWidth: "56px",
                            pt: 2,
                            // backgroundColor: '#cc0000',
                            display: "flex",
                            // alignItems: 'center',
                            justifyContent: "center",
                          }}
                        ></Box>

                        <Box
                          sx={{
                            flexGrow: 1,
                            pr: 2,
                            py: 2,
                            // backgroundColor: 'blue',
                            // backgroundColor: 'primary.100',
                          }}
                        >
                          {
                            // <Typography
                            //   sx={{ fontSize: '15px' }}
                            //   key={"m" + i}
                            //   value={i}
                            // >
                            //   Settings Update: Version {m.data.version}
                            // </Typography>
                          }
                        </Box>

                        <Box
                          sx={{
                            width: "56px",
                            minWidth: "56px",
                            pt: "10px",
                            px: "5px",
                            display: "flex",
                            // alignItems: 'center',
                            justifyContent: "center",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              // justifyContent: 'center',
                            }}
                          >

                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  );
                } else if (m.type === "ai" ) {
                  return (
                    <Grid
                      item
                      xs={12}
                      key={i}
                      name={m.type}
                      sx={{
                        px: 2,
                        py: 1,
                      }}
                      ref={
                        isScollRef('message', i) ? scrollRef : null
                      }
                    >
                      <Box name={"background"}
                        sx={{
                          // backgroundColor: m.type === "ai" ? "#efefef" : "#f9f9f9",
                          backgroundColor: theme.palette.background.paper,
                          borderRadius: "5px",
                          borderWidth: "1px",
                          borderStyle: "solid",
                          borderColor: theme.palette.border.normal,
                          boxShadow: theme.palette.boxshadow.normal,
                          display: "flex",
                          flexDirection: "row",
                          minHeight: "56px",
                          // backgroundColor: m.type === 'ai' ? '#efefef' : '#fff',
                        }}
                      >
                        <Box name={"icon"}
                          sx={{
                            width: "56px",
                            minWidth: "56px",
                            pt: 2,
                            // backgroundColor: '#cc0000',
                            display: "flex",
                            // alignItems: 'center',
                            justifyContent: "center",
                          }}
                        >
                          {m.type === "ai" && (
                            <SupportAgentIcon
                              sx={{
                                color: "grey.500",
                              }}
                            // color='primary'
                            />
                          )}

                          {m.type === "human" && (
                            <AccountCircleIcon
                              sx={{
                                color: "grey.500",
                              }}
                            // color='primary'
                            />
                          )}
                        </Box>

                        <Box name={"message"}
                          sx={{
                            // backgroundColor: m.type === "ai" ? "#efefef" : "#f9f9f9",
                            flexGrow: 1,
                            // px: 2,
                            px: 0,
                            py: 2,
                            // borderRadius: "5px",
                          }}
                        >
                          {
                            <Typography
                              sx={{
                                fontSize: "15px",
                                whiteSpace: "pre-wrap",
                              }}
                              key={"m" + i}
                              value={i}
                            >
                              {m.data && m.data.content}
                            </Typography>
                          }
                        </Box>

                        <Box name={"controls"}
                          sx={{
                            width: "56px",
                            minWidth: "56px",
                            pt: "10px",
                            px: "5px",
                            display: "flex",
                            // alignItems: 'center',
                            justifyContent: "center",
                          }}
                        >

                        </Box>
                      </Box>
                    </Grid>
                  );
                } else if (m.type === "human" ) {
                  return (
                    <Grid
                      item
                      xs={12}
                      key={i}
                      name={m.type}
                      sx={{
                        px: 2,
                        py: 1,
                      }}
                      ref={
                        isScollRef('message', i) ? scrollRef : null
                      }
                    >
                      <Box name={"background"}
                        sx={{
                          // backgroundColor: m.type === "ai" ? "#efefef" : "#f9f9f9",
                          backgroundColor: theme.palette.background.paper,
                          borderRadius: "5px",
                          borderWidth: "1px",
                          borderStyle: "solid",
                          borderColor: theme.palette.border.normal,
                          boxShadow: theme.palette.boxshadow.normal,
                          display: "flex",
                          flexDirection: "row",
                          minHeight: "56px",
                          // backgroundColor: m.type === 'ai' ? '#efefef' : '#fff',
                        }}
                      >

                        <Box name={"controls"}
                          sx={{
                            width: "56px",
                            minWidth: "56px",
                            pt: "10px",
                            px: "5px",
                            display: "flex",
                            // alignItems: 'center',
                            justifyContent: "center",
                          }}
                        >

                        </Box>

                        <Box name={"message"}
                          sx={{
                            // backgroundColor: m.type === "ai" ? "#efefef" : "#f9f9f9",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            flexGrow: 1,
                            // px: 2,
                            px: 0,
                            py: 2,
                            // borderRadius: "5px",
                          }}
                        >
                          {
                            <Typography
                              sx={{
                                fontSize: "15px",
                                whiteSpace: "pre-wrap",
                              }}
                              key={"m" + i}
                              value={i}
                            >
                              {m.data && m.data.content}
                            </Typography>
                          }
                        </Box>

                        <Box name={"icon"}
                          sx={{
                            width: "56px",
                            minWidth: "56px",
                            pt: 2,
                            // backgroundColor: '#cc0000',
                            display: "flex",
                            // alignItems: 'center',
                            justifyContent: "center",
                          }}
                        >
                          {m.type === "ai" && (
                            <SupportAgentIcon
                              sx={{
                                color: "grey.500",
                              }}
                            // color='primary'
                            />
                          )}

                          {m.type === "human" && (
                            <AccountCircleIcon
                              sx={{
                                color: "grey.500",
                              }}
                            // color='primary'
                            />
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  );
                } else if (m.type === "aireset") {
                  return (
                    <Grid
                      item
                      xs={12}
                      key={i}
                      name={m.type}
                      sx={
                        {
                          // my: 1,
                        }
                      }
                      ref={
                        isScollRef('message', i) ? scrollRef : null
                      }
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          // minHeight: "56px",
                          // backgroundColor: m.type === 'ai' ? '#efefef' : '#fff',
                        }}
                      >
                        <Box
                          sx={{
                            width: "56px",
                            minWidth: "56px",
                            // pt: 2,
                            // backgroundColor: '#cc0000',
                            display: "flex",
                            // alignItems: 'center',
                            justifyContent: "center",
                          }}
                        >
                          {m.type === "ai" && (
                            <SupportAgentIcon
                              sx={{
                                color: "grey.500",
                              }}
                            // color='primary'
                            />
                          )}

                          {m.type === "human" && (
                            <AccountCircleIcon
                              sx={{
                                color: "grey.500",
                              }}
                            // color='primary'
                            />
                          )}
                        </Box>

                        <Box
                          sx={{
                            // backgroundColor: m.type === "ai" ? "#efefef" : "#f9f9f9",
                            flexGrow: 1,
                            px: 0,
                            py: 0,
                            // borderRadius: "5px",
                          }}
                        >
                          {
                            <Divider
                              sx={{
                                // backgroundColor: "grey.400",
                                borderColor: "grey.400",
                                color: "grey.900",
                                "&.MuiDivider-root:before": {
                                  borderColor: theme.palette.border.normal,
                                },
                                "&.MuiDivider-root:after": {
                                  borderColor: theme.palette.border.normal,
                                },
                              }}
                            >
                              <SyncIcon
                                // color="primary"
                                sx={{
                                  mt: "8px",
                                  // color: "primary.main",
                                  // color: linkIconActiveDisabled(),
                                  color: "grey.400",
                                  fontSize: 16,
                                }}
                              />
                            </Divider>
                          }
                        </Box>
                        <Box
                          sx={{
                            width: "56px",
                            minWidth: "56px",
                            // pt: "10px",
                            px: "5px",
                            display: "flex",
                            // alignItems: 'center',
                            justifyContent: "center",
                          }}
                        ></Box>
                      </Box>
                    </Grid>
                  );
                }

              })}

              {showChatWaitingResponse()}
            </Grid>
          </Box>
        </Box>
      </Box>
    );
  }

  if (init) {
    return <LoadingSubPane spin={true} />;
  }

  if (aiLiveConversation === null) {
    // if (clog()) console.log('AiChatPanel aiConversation', aiConversation);
    return (
      <Box
        name={"chatPanel"}
        height={"100%"}
        // bgcolor="#cc0000"
        sx={{
          display: "flex",
          flexDirection: "column",
          // overflow: 'hidden',
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: theme.palette.border.normal,
          // backgroundColor: '#efefef',
          borderRadius: "4px",
          overflow: "hidden",
          backgroundColor: theme.palette.background.bg,
        }}
      >
        <Box
          name={"chatMessages"}
          sx={{
            flexGrow: 1,
            overflow: "hidden",
            height: "100%",
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Box
            id={"chatMessagesScrollWrapper"}
            sx={{
              maxHeight: "100%",
              height: "100%",
              overflow: "auto",
            }}
          >
            <Grid
              container
              // component={wrapper}
              // ref={wrapper} // Option 1: use ref to scroll to bottom
              sx={{
                my: 0,
                height: "100%",
              }}
            >
              {
                showNoConverationMessages()
              }

            </Grid>
          </Box>
        </Box>
      </Box>
    );
  }
  // if (!init) {
  //   return (
  //     <Box>
  //       <Typography>Select a conversation</Typography>
  //     </Box>
  //   )
  // }
};

export default React.memo(AiChatLivePanel);
