import React, {
  useState,
  useEffect,
  // useCallback
  useContext
} from 'react'
import PropTypes from 'prop-types';

import { create, all } from 'mathjs'

import {
} from 'firebase/auth';
// import { useAuthState } from 'react-firebase-hooks/auth';
import {setd, debug, setc, clog} from "../helpers/HelperFunctions";
import { AdminAuthContext } from '../helpers/AdminAuthProvider';

import {
  formatCurrencyNumber,
  getCurrencySymbol
} from '../code/locale';

import {
  getFirestore,
  collection,
  query,
  where,
  // orderBy
} from 'firebase/firestore';
import { useCollection } from 'react-firebase-hooks/firestore';
import { firebase } from '../code/firebase';
import { processKeywordsUserData } from '../code/ecomacy';

import _ from 'lodash';

import {
  useTheme,
  styled
} from '@mui/material/styles';

import {
  Box,
  Button,
  // LoadingButton,
  CircularProgress,
  Typography,
  Grid,
  Stack,
  Divider,
} from '@mui/material';

import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { SettingOutlined, FileImageOutlined, UserOutlined, UsergroupAddOutlined } from '@ant-design/icons';

import MainCard from './MainCard';


// import GoogleMapPane from './GoogleMapPane'
import LoadingPane from './LoadingPane'


// create a mathjs instance
const math = create(all)

const AdminPlansSetupTab = (props) => {
  const theme = useTheme();



  const { adminUserData, setAdminUserData } = useContext(AdminAuthContext);
  const { adminPlanData, setAdminPlanData } = useContext(AdminAuthContext);
  const { adminMembershipsData, setAdminMembershipsData } = useContext(AdminAuthContext);
  const { customersData, setCustomersData } = useContext(AdminAuthContext);
  // const { customersEcData, setCustomersEcData } = useContext(AdminAuthContext);
  // const { customersDataSynced, setCustomersDataSynced } = useContext(AdminAuthContext);

  // const { customersDataOrigObj } = useContext(AdminAuthContext);
  const { customersDataOrig, setCustomersDataOrig } = useContext(AdminAuthContext);
  const { saveAdminCustomer } = useContext(AdminAuthContext);
  const { saveAdminUserMemberships } = useContext(AdminAuthContext);
  const [adminMembershipsDataUpdated, setAdminMembershipsDataUpdated] = useState([]);
  const { createMembershipId } = useContext(AdminAuthContext);

  // const tokenPrice = 0.012;
  // const monthlyPriceMin = 20;

  const { tokenPrice } = useContext(AdminAuthContext);
  const { monthlyPriceMin } = useContext(AdminAuthContext);


  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [success, setSuccess] = React.useState(false);

  useEffect(() => {
    setAdminMembershipsDataUpdated(adminMembershipsData);
    setLoading(false);
  }, [])

  if (clog()) console.log('adminUserData.userDoc:', adminUserData.userDoc);
  if (clog()) console.log('adminPlanData.data:', adminPlanData.data);
  if (clog()) console.log('adminMembershipsData:', adminMembershipsData);

  const saveButtonSx = {
    // ...(success && {
    //   bgcolor: theme.palette.success.main,
    //   '&:hover': {
    //     // bgcolor: theme.palette.success.dark,
    //     bgcolor: theme.palette.success.main,
    //   },
    // }),
    // ml: 1,
    height: '40px',
    width: '100%',
    fontWeight: 700
  };

  const handleAdd = () => {

    // clone adminPlanData.data
    let membership = null;
    if (adminPlanData && adminPlanData.data && adminPlanData.data.memberships && adminPlanData.data.memberships[0]) {
      membership = adminPlanData.data.memberships[0];

      // clone & update membership
      let newMembership = _.cloneDeep(membership)
      newMembership.editable = true
      newMembership.name = 'Untitled ' + adminMembershipsDataUpdated.length

      // generate id
      const id = createMembershipId(adminMembershipsDataUpdated)
      newMembership.membershipid = id

      let newArr = [...adminMembershipsDataUpdated]
      newArr.push(newMembership)

      setAdminMembershipsDataUpdated(newArr);

    }
  }

  const handleRemove = (index) => {

    const test = adminMembershipsDataUpdated
    // let updatedMemberships = _.cloneDeep(adminMembershipsDataUpdated)
    let updatedMemberships = [...adminMembershipsDataUpdated]

    // remove item from updatedMemberships using index
    updatedMemberships.splice(index, 1)

    setAdminMembershipsDataUpdated(updatedMemberships);

  }

  const handleSave = async () => {
    if (clog()) console.log('handleSave:', adminMembershipsDataUpdated[0].plans);

    setSaving(true);
    setSuccess(false);
    // setLoading(true);

    let docId = ''
    // if adminUserData and adminUserData.userDoc exists
    if (adminUserData && adminUserData.userDoc) {
      const userDoc = adminUserData.userDoc
      // if userDoc id exists
      if (userDoc.id) {
        docId = userDoc.id
      }
    }

    let result = {}
    if (docId !== '' && adminMembershipsDataUpdated.length > 0) {
      result = await saveAdminUserMemberships(docId, adminMembershipsDataUpdated)
    }

    if (result && result.data) {
      // update adminMembershipsData to match adminMembershipsDataUpdated
      setAdminMembershipsData(adminMembershipsDataUpdated)
      // const test = adminUserData.userDoc
      // const test1 = adminMembershipsDataUpdated
      // const test2 = adminMembershipsData
    }

    setSaving(false);
    setSuccess(true);
    // setLoading(false);
  }

  const isSaveDisabled = () => {
    let disabled = true;

    if (saving) {

    }
    else {
      if (adminMembershipsDataUpdated && adminMembershipsData) {
        // deep compare adminMembershipsDataUpdated to adminMembershipsData
        if (!_.isEqual(adminMembershipsDataUpdated, adminMembershipsData)) {
          disabled = false;
          // check for empty values
          adminMembershipsDataUpdated.forEach((membership) => {
            if (membership.name === '') {
              disabled = true;
            }

            if (!disabled) {
              // loop over plans
              if (membership.plans && membership.plans.length > 0) {
                membership.plans.forEach((plan) => {
                  if (plan.name === '') {
                    disabled = true;
                  }

                  if (plan.extratokens === '') {
                    disabled = true;
                  }

                  if (plan.extratokenscost === '') {
                    disabled = true;
                  }

                  if (plan.monthly === '') {
                    disabled = true;
                  }

                  if (plan.monthlycost === '') {
                    disabled = true;
                  }

                  if (plan.monthlyincluded === '') {
                    disabled = true;
                  }
                })
              }
            }
          })
        }
      }
    }

    return disabled;
  }

  const calcMonthlyCost = (val) => {
    let prices = null;
    if (adminPlanData && adminPlanData.data && adminPlanData.data.prices) {
      prices = adminPlanData.data.prices
    }

    // let monthlyCostInt = monthlyPriceMin;
    let monthlyCostInt = monthlyPriceMin.current;
    // let monthlyCost = monthlyCostInt.toFixed(2);
    if (prices && prices.monthlypercentage) {
      const monthlyCostCalc = (val / 100) * prices.monthlypercentage;
      if (monthlyCostCalc > monthlyCostInt) {
        monthlyCostInt = math.ceil(monthlyCostCalc, 2);
        // monthlyCost = (Math.round(monthlyCostCalc * 100) / 100).toFixed(2);
        // monthlyCostInt = (math.ceil(monthlyCostCalc * 100) / 100, 2);
        // monthlyCost = monthlyCostInt.toFixed(2);
      }
    }

    // return monthlyCost
    return monthlyCostInt
  }

  const processMonthlyCost = (val) => {

    let processedVal = '';
    if (val !== '') {
      //
      // processedVal = val.toFixed(2);
      processedVal = formatCurrencyNumber(val)
    }

    return processedVal

  }

  const calcTokensIncluded = (val) => {

    let value = 0
    if (val !== '') {
      value = val
    }

    let prices = null;
    if (adminPlanData && adminPlanData.data && adminPlanData.data.prices) {
      prices = adminPlanData.data.prices
    }

    // const tokensPrice = (value * tokenPrice) /100;
    // const tokensPrice = value * tokenPrice;
    const tokensPrice = value * tokenPrice.current;

    // const tokensPriceRoundedOld = (Math.round(tokensPrice * 100) / 100).toFixed(2)
    const tokensPriceRoundedInt = math.ceil((tokensPrice * 100) / 100, 2)
    // const tokensPriceRounded = tokensPriceRoundedInt.toFixed(2)
    const tokensPriceRounded = formatCurrencyNumber(tokensPriceRoundedInt)
    // let monthlyCost = 20;
    // if (prices && prices.monthlypercentage) {
    //   const monthlyCostCalc = (val / 100) * prices.monthlypercentage;
    //   if (monthlyCostCalc > monthlyCost) {
    //     monthlyCost = monthlyCostCalc
    //   }
    // }

    return tokensPriceRounded
  }

  const getTokenBlock = () => {

    let prices = null;
    if (adminPlanData && adminPlanData.data && adminPlanData.data.prices) {
      prices = adminPlanData.data.prices
    }

    let block = 0
    if (prices && prices.tokenblock) {
      block = prices.tokenblock
    }
    return block
  }


  const getTokenBlockCost = (val) => {

    let value = 0
    if (val !== '') {
      value = val
    }
    let prices = null;
    if (adminPlanData && adminPlanData.data && adminPlanData.data.prices) {
      prices = adminPlanData.data.prices
    }

    let block = 0
    if (prices && prices.tokenblock) {
      block = prices.tokenblock
    }


    // const tokensPrice = (value * tokenPrice) /100;
    // const tokensPrice = (value * block) * tokenPrice;
    const tokensPrice = (value * block) * tokenPrice.current;

    // const tokensPriceRoundedOld = (Math.round(tokensPrice * 100) / 100).toFixed(2)
    const tokensPriceRoundedInt = math.ceil((tokensPrice * 100) / 100, 2)
    // const tokensPriceRounded = tokensPriceRoundedInt.toFixed(2)
    const tokensPriceRounded = formatCurrencyNumber(tokensPriceRoundedInt)
    // let monthlyCost = 20;
    // if (prices && prices.monthlypercentage) {
    //   const monthlyCostCalc = (val / 100) * prices.monthlypercentage;
    //   if (monthlyCostCalc > monthlyCost) {
    //     monthlyCost = monthlyCostCalc
    //   }
    // }

    return tokensPriceRounded
  }


  const handleChangeName = (event, membershipGroupIndex) => {
    const val = event.target.value;

    // clone membership group
    let updatedMembershipGroup = _.cloneDeep(adminMembershipsDataUpdated[membershipGroupIndex])

    //  membership group plan
    updatedMembershipGroup.name = val

    let newArr = [...adminMembershipsDataUpdated];

    newArr[membershipGroupIndex] = updatedMembershipGroup;

    setAdminMembershipsDataUpdated(newArr);

  }


  const handleChange = (event, type, membershipGroupIndex, planIndex, plan) => {
    const val = event.target.value;
    let valInt = 0
    let updatedPlan = _.cloneDeep(plan)

    if (type === 'monthly') {
      if (val !== '') {
        valInt = parseInt(val);
        updatedPlan.monthly = valInt
        updatedPlan.monthlycost = calcMonthlyCost(valInt)
      }
      else {
        updatedPlan.monthly = ''
        updatedPlan.monthlycost = calcMonthlyCost(valInt)
      }

      // calculate monthlycost

    }

    if (type === 'monthlyincluded') {
      if (val !== '') {
        valInt = parseInt(val);
        updatedPlan.monthlyincluded = valInt
        // updatedPlan.monthlyincludedcost = calcTokensIncluded(valInt)

      }
      else {
        updatedPlan.monthlyincluded = ''
      }
    }

    if (type === 'extratokenscost') {
      if (val !== '') {
        valInt = parseInt(val);
        updatedPlan.extratokenscost = valInt
        // updatedPlan.monthlyincludedcost = calcTokensIncluded(valInt)

      }
      else {
        updatedPlan.extratokenscost = ''
      }
    }

    if (type === 'extratokens') {
      if (val !== '') {
        valInt = parseInt(val);
        updatedPlan.extratokens = valInt
        // updatedPlan.monthlyincludedcost = calcTokensIncluded(valInt)

      }
      else {
        updatedPlan.extratokens = ''
      }
    }

    // clone membership group
    let updatedMembershipGroup = _.cloneDeep(adminMembershipsDataUpdated[membershipGroupIndex])

    //  membership group plan
    updatedMembershipGroup.plans[planIndex] = updatedPlan

    let newArr = [...adminMembershipsDataUpdated];

    newArr[membershipGroupIndex] = updatedMembershipGroup;

    setAdminMembershipsDataUpdated(newArr);

  }

  if (loading) {
    return (
      <LoadingPane
      // text={'Error loading Ecomacy Data'}
      //showSpinner={false}
      />
    )
  }
  return (
    <Box width="1154px">
      {Object.keys(adminMembershipsDataUpdated).map((key, index) => {

        const membershipGroup = adminMembershipsDataUpdated[key];
        const membershipGroupIndex = index;
        return (
          <Box key={index}
            mb={4}
          // width="1000px"
          >

            {index === 0 ? (
              <Grid container spacing={3} mt={0}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h3" >
                    {membershipGroup.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} justifyContent="flex-end">

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      // pr: 2,
                      // height: '100%'
                    }}
                  >

                    <Button
                      sx={{
                        mr: 2
                      }}
                      variant="contained"
                      // sx={buttonSx}
                      // disabled={loading}
                      // size="small"
                      onClick={handleAdd}
                      // disabled={isDisabled()}
                      startIcon={<AddIcon />}
                    >
                      Add
                    </Button>


                    <Box id='saveButtonBox' sx={{
                      position: 'relative',
                      // width: '100%'
                    }}>
                      <Button
                        variant="contained"
                        // type="submit"
                        sx={saveButtonSx}
                        disabled={isSaveDisabled()}
                        // disabled={loading}
                        startIcon={<SaveIcon />}
                        onClick={handleSave}

                      >
                        Save
                      </Button>
                      {saving && (
                        <CircularProgress
                          size={24}
                          sx={{
                            color: theme.palette.success.main,
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                          }}
                        />
                      )}
                    </Box>
                  </Box>

                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={3} mt={0}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    // size="large"
                    sx={{

                      input: {
                        fontSize: '24px',
                        fontWeight: 700
                      },

                    }}
                    value={membershipGroup.name}
                    name="lastname"
                    //onBlur={handleBlur}
                    onChange={(event) => handleChangeName(event, membershipGroupIndex)}
                    placeholder="Name"
                  />
                </Grid>

                <Grid item xs={12} sm={8}>

                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <Box>
                      <Button
                        variant="contained"
                        // sx={buttonSx}
                        // disabled={loading}
                        // size="small"
                        onClick={() => handleRemove(index)}
                      // disabled={isSaveDisabled()}
                      startIcon={<DeleteIcon />}
                      >
                        Remove
                      </Button>
                    </Box>
                  </Stack>

                </Grid>
              </Grid>
            )}



            <Grid container spacing={3} mt={0}>
              {membershipGroup.plans && (membershipGroup.plans.map((m, i) => {
                const plan = membershipGroup.plans[i];
                const planIndex = i;

                return (
                  <Grid
                    key={'m' + m.level}
                    item xs={12}
                    lg={4}
                    sm={6}
                  >
                    <MainCard>
                      <Grid
                        container
                        // spacing={2}
                        justifyContent="center"
                        alignItems="center"
                        direction="row"
                      >


                        <Grid item xs={12} sm={12} pl={0}>
                          <Typography sx={{ fontWeight: '700' }}>Plan {m.level}</Typography>
                        </Grid>


                        <Grid item xs={12} sm={6} pl={0}>
                          <Typography>&nbsp;</Typography>
                        </Grid>

                        <Grid item xs={12} sm={3} pl={2}>
                          <Typography sx={{ fontWeight: '700' }}>Unit</Typography>
                        </Grid>

                        <Grid item xs={12} sm={3} pl={2}>
                          <Typography sx={{ fontWeight: '700' }}>Cost</Typography>
                        </Grid>




                        <Grid name={'monthlycost-l'} item xs={12} sm={6} pl={0} pt={1}
                        // bgcolor={'#cc0000'}
                        >
                          <Typography
                          // </Grid>sx={{ mt: '12px' }}
                          >
                            Monthly Price
                          </Typography>
                        </Grid>

                        <Grid name={'monthlycost-m'} item xs={12} sm={3} pl={2} pt={1}
                        // bgcolor={'#cccccc'}
                        >
                          <Stack spacing={1.25}>
                            <TextField
                              fullWidth
                              id={i + "monthlyb" + m.level}
                              value={m.monthly}
                              name="lastname"
                              //onBlur={handleBlur}
                              onChange={(event) => handleChange(event, 'monthly', membershipGroupIndex, planIndex, plan)}
                              placeholder="Monthly Price"
                            />
                            {
                              // touched.lastname && errors.lastname && (
                              //   <FormHelperText error id="personal-last-name-helper">
                              //     {errors.lastname}
                              //   </FormHelperText>
                              // )
                            }
                          </Stack>
                        </Grid>
                        <Grid name={'monthlycost-r'} item xs={12} sm={3} pl={2} pt={1}
                        // bgcolor={'#cc0000'}
                        >
                          <Stack spacing={1.25}>
                            <Typography>{processMonthlyCost(m.monthlycost)}</Typography>
                          </Stack>
                        </Grid>







                        <Grid name={'tokensincluded-l'} item xs={12} sm={6} pl={0} pt={1}
                        // bgcolor={'#cc0000'}
                        >
                          <Typography
                          // </Grid>sx={{ mt: '12px' }}
                          >
                            Tokens Included
                          </Typography>
                        </Grid>

                        <Grid name={'tokensincluded-m'} item xs={12} sm={3} pl={2} pt={1}
                        // bgcolor={'#cccccc'}
                        >
                          <Stack spacing={1.25}>
                            <TextField
                              fullWidth
                              id={i + "tokensincluded" + m.level}
                              value={m.monthlyincluded}
                              name="lastname"
                              //onBlur={handleBlur}
                              onChange={(event) => handleChange(event, 'monthlyincluded', membershipGroupIndex, planIndex, plan)}
                              placeholder="Tokens Included"
                            />
                            {
                              // touched.lastname && errors.lastname && (
                              //   <FormHelperText error id="personal-last-name-helper">
                              //     {errors.lastname}
                              //   </FormHelperText>
                              // )
                            }
                          </Stack>
                        </Grid>
                        <Grid name={'tokensincluded-r'} item xs={12} sm={3} pl={2} pt={1}
                        // bgcolor={'#cc0000'}
                        >
                          <Stack spacing={1.25}>
                            <Typography>{calcTokensIncluded(m.monthlyincluded)}</Typography>
                          </Stack>
                        </Grid>





                        <Grid item xs={12} sm={12} pt={2}>
                          <Divider variant="middle" />
                        </Grid>







                        <Grid name={'tokensextra-l'} item xs={12} sm={6} pl={0} pt={2}
                        // bgcolor={'#cc0000'}
                        >
                          <Typography
                          // </Grid>sx={{ mt: '12px' }}
                          >
                            Extra Tokens (per {getTokenBlock()})
                          </Typography>
                        </Grid>

                        <Grid name={'tokensextra-m'} item xs={12} sm={3} pl={2} pt={2}
                        // bgcolor={'#cccccc'}
                        >
                          <Stack spacing={1.25}>
                            <TextField
                              fullWidth
                              id={i + "monthlyb" + m.level}
                              value={m.extratokenscost}
                              name="lastname"
                              //onBlur={handleBlur}
                              // onChange={handleChange}
                              onChange={(event) => handleChange(event, 'extratokenscost', membershipGroupIndex, planIndex, plan)}

                              placeholder="Extra Tokens"
                            />
                            {
                              // touched.lastname && errors.lastname && (
                              //   <FormHelperText error id="personal-last-name-helper">
                              //     {errors.lastname}
                              //   </FormHelperText>
                              // )
                            }
                          </Stack>
                        </Grid>
                        <Grid name={'tokensextra-r'} item xs={12} sm={3} pl={2} pt={2}
                        // bgcolor={'#cc0000'}
                        >
                          <Stack spacing={1.25}>
                            <Typography>{getTokenBlockCost(m.extratokens)}</Typography>
                          </Stack>
                        </Grid>

                        <Grid item xs={12} sm={12} pt={2}>
                          <Divider variant="middle" />
                        </Grid>







                        <Grid item xs={12} sm={12} pt={2}>

                          <Box
                            name={'planTitle'}
                            mt={1}
                            mb={2}
                          >
                            <Typography
                              color="primary"
                              sx={{
                                fontSize: '20px',
                                lineHeight: '20px',
                                fontWeight: '700'
                              }}
                            >
                              Plan {m.level}
                            </Typography>
                          </Box>


                          <Box
                            name={'planMonthly'}
                            sx={{
                              display: 'flex',
                              alignItems: 'stretch',
                              justifyContent: 'flex-start',
                              height: 70,

                            }}
                          >
                            <Box
                              sx={{
                                pt: '10px',
                                mr: '10px'
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: '24px',
                                  lineHeight: '24px',
                                  fontWeight: 700
                                }}
                              >
                                {getCurrencySymbol('')}
                              </Typography>
                            </Box>

                            <Box
                              sx={{
                                mr: '10px'
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: '70px',
                                  lineHeight: '70px',
                                  fontWeight: 700
                                }}
                              >
                                {m.monthly}
                              </Typography>
                            </Box>

                            <Box sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'flex-end',
                              pb: '8px'
                            }}>
                              <Typography
                                // color="textSecondary"
                                sx={{
                                  fontSize: '24px',
                                  lineHeight: '24px',
                                  fontWeight: 700
                                }}
                              >
                                monthly
                              </Typography>
                            </Box>
                          </Box>





                          <Box
                            name={'planTokensIncluded'}
                            sx={{
                              display: 'flex',
                              alignItems: 'stretch',
                              justifyContent: 'flex-start',
                              height: 40,
                              mt: 2
                            }}
                          >
                            <Box
                              sx={{
                                mr: '10px'
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: '40px',
                                  lineHeight: '40px',
                                  fontWeight: 700
                                }}
                              >
                                {m.monthlyincluded}
                              </Typography>
                            </Box>

                            <Box sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'flex-end',
                              pb: '4px'
                            }}>
                              <Typography
                                // color="textSecondary"
                                sx={{
                                  fontSize: '20px',
                                  lineHeight: '20px',
                                  fontWeight: 700
                                }}
                              >
                                tokens included
                              </Typography>
                            </Box>
                          </Box>




                          <Box
                            name={'planTokensIncluded'}
                            sx={{
                              display: 'flex',
                              alignItems: 'stretch',
                              justifyContent: 'flex-start',
                              height: 40,
                              mt: 2
                            }}
                          >

                            <Box
                              sx={{
                                pt: '6px',
                                mr: '6px'
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: '20px',
                                  lineHeight: '20px',
                                  fontWeight: 700
                                }}
                              >
                                {getCurrencySymbol('')}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                mr: '10px'
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: '40px',
                                  lineHeight: '40px',
                                  fontWeight: 700
                                }}
                              >
                                {m.extratokenscost}
                              </Typography>
                            </Box>

                            <Box sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'flex-end',
                              pb: '4px'
                            }}>
                              <Typography
                                // color="textSecondary"
                                sx={{
                                  fontSize: '20px',
                                  lineHeight: '20px',
                                  fontWeight: 700
                                }}
                              >
                                per {getTokenBlock()} extra tokens
                              </Typography>
                            </Box>
                          </Box>


                        </Grid>




                      </Grid>

                    </MainCard>
                  </Grid>
                )
              }
              ))}
            </Grid>

          </Box>
        );

      })}

    </Box>
  );
};


export default React.memo(AdminPlansSetupTab)