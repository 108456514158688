import React, { useEffect, useState } from 'react';

import { } from 'firebase/auth';
// import { useAuthState } from 'react-firebase-hooks/auth';

import {
  collection, getFirestore,
  // doc,
  query,
  where
} from 'firebase/firestore';
import {
  useCollection
  //useDocument
} from 'react-firebase-hooks/firestore';

import { firebase } from '../code/firebase';

import Fuse from 'fuse.js';
import _ from 'lodash';

import {
  Box, Button, Grid, IconButton, InputLabel, ListItem,
  ListItemButton,
  ListItemText,
  // ListItemIcon,
  ListItemSecondaryAction,
  OutlinedInput, Stack, Typography, useTheme
} from '@mui/material';

import { Done, DoDisturb, Star, ArrowLeft, ArrowRight } from '@mui/icons-material';
// import { useTheme } from '@mui/material/styles';

// third-party
import {
  SearchOutlined,
  EyeOutlined,
  FacebookOutlined
} from '@ant-design/icons';
import { FixedSizeList } from 'react-window';

import MainCard from './MainCard';


import SettingsCalcPane from './SettingsCalcPane';



import { processUserKeywordData } from '../code/settings';


const SettingsKeywordsPane = (props) => {
  const theme = useTheme();
  const buttonWidth = 94

  // let userData = {}
  // if (props.userData) {
  //   userData = props.userData
  // }

  let keywordsData = []
  if (props.keywordsData) {
    keywordsData = props.keywordsData
  }

  let saveKeywords = {}
  if (props.saveKeywords) {
    saveKeywords = props.saveKeywords
  }

  let saveGridTemplates = {}
  if (props.saveGridTemplates) {
    saveGridTemplates = props.saveGridTemplates
  }

  const [selectedRow, setSelectedRow] = useState({
    type: '',
    id: '',
    t: ''
  });

  // rows array from user keywords - use for comparison
  // eslint-disable-next-line no-unused-vars
  const [savedUserKeywordData, setSavedUserKeywordData] = useState(keywordsData);
  // rows array from user keywords - updated from row funcs
  // const [userKeywordData, setUserKeywordData] = useState(keywordsData);
  const userKeywordData = props.userKeywordData
  const setUserKeywordData = props.setUserKeywordData

  // const [userKeywordRows, setUserKeywordRows] = useState([]);

  // data from google
  // eslint-disable-next-line no-unused-vars
  const [googleCategoriesData, setGoogleCategoriesData] = useState({});
  // rows array from google data
  const [googleCategoriesRows, setGoogleCategoriesRows] = useState([]);
  const [googleCategoriesLoaded, setGoogleCategoriesLoaded] = useState(false);
  let googleCategoriesDataObj = {}

  useEffect(() => {
    if (!_.isEmpty(googleCategoriesDataObj) && googleCategoriesDataObj.exists === true && googleCategoriesLoaded === false) {
      // initial firestore fetch
      setGoogleCategoriesData(googleCategoriesDataObj) // only time this is used so far
      setGoogleCategoriesLoaded(true)

      // process data for table
      const rowData = buildGoogleCategoryRows(googleCategoriesDataObj)

      setGoogleCategoriesRows(rowData)
    }
  }, [googleCategoriesDataObj])

  const [searchValue, setSearchValue] = useState('');
  const handleSearchInput = (event) => {
    const value = event.target.value
    setSearchValue(value);
  };
  let searchResultsArr = []


  const resetSelectedRow = () => {
    const data = {
      type: '',
      id: '',
      t: ''
    }

    setSelectedRow(data)
  }

  const isSelectedRow = (type, item) => {
    let isSelected = false
    if ('user' === type && selectedRow.type === type) {
      if (item.categoryid === selectedRow.id) {
        isSelected = true
      }
    }
    else if ('google' === type && selectedRow.type === type) {
      let id = ''
      if (item.item) {
        const itemObj = item.item
        id = itemObj.id
      }
      else {
        id = item.id
      }

      if (id === selectedRow.id) {
        isSelected = true
      }
    }

    return isSelected
  }

  const renderUserKeywordRows = (props) => {
    const { index, style } = props;
    // const item = googleCategoriesRows[index]
    let k = ''
    const item = userKeywordData[index]
    if (item) {
      k = item.keyword
      // const searchItemObj = searchResultsArr[index]
      // const item = searchItemObj.item
      // const id = item.id
      // const t = item.t
      return (

        <ListItem style={style} key={index} component="div" disablePadding divider>
          <ListItemButton
            sx={{ pt: 0, pb: 0, height: '100%' }}
            onClick={(event) => handleUserCategoryRowOnClick(event, index, item)}
            // selected={index === 1 ? true : false}
            selected={isSelectedRow('user', item)}
          >
            <ListItemText
              primary={k}
              sx={{
                color: item.active === 0 ? theme.palette.text.disabled : theme.palette.text.primary,
                ".Mui-selected &": {
                  color: theme.palette.primary.main
                },
                textTransform: 'capitalize !important'
              }}
            />

            {item.primary === 1 &&
              <ListItemSecondaryAction
              >
                <Star
                  fontSize="small"
                  sx={{
                    // color: theme.palette.primary[200],
                    color: theme.palette.text.secondary,
                    ".Mui-selected &": {
                      color: theme.palette.primary.main
                    },
                    // color: theme.palette.primary[200],
                    mt: '5px'
                  }}
                />
              </ListItemSecondaryAction>
            }

            {item.active === 0 &&
              <ListItemSecondaryAction
              >
                <DoDisturb
                  fontSize="small"
                  sx={{
                    color: item.active === 0 ? theme.palette.text.disabled : theme.palette.text.primary,
                    ".Mui-selected &": {
                      color: theme.palette.primary.main
                    },
                    mt: '5px'
                  }}
                />
              </ListItemSecondaryAction>
            }


          </ListItemButton>
        </ListItem>
      );
    }

  }

  const handleUserCategoryRowOnClick = (e, index, item) => {

    let click = 0
    switch (e.detail) {
      case 1:
        if (clog()) console.log("click");
        click = 1
        break;
      case 2:
        if (clog()) console.log("double click");
        click = 2
        break;
    }

    let data = {
      type: '',
      id: '',
      t: ''
    }

    if (item) {
      data.type = 'user'
      data.id = item.categoryid
      data.t = item.keyword
    }

    if (!_.isEqual(data, selectedRow)) {
      setSelectedRow(data)
      if (clog()) console.log('User Category Clicked:', data.id)
    }
    else {
      setSelectedRow({
        type: '',
        id: '',
        t: ''
      })
    }

  }

  const buildGoogleCategoryRows = (data) => {
    let rows = []
    if (data && data.categories) {
      const categories = data.categories
      Object.keys(categories).map((itemKey, index) => {
        const category = categories[itemKey]
        const rowData = {
          id: itemKey,
          t: category.t
        }
        rows.push(rowData)
        // rows[index] = rowData
        return null
      })

    }

    // sort array
    rows = _.sortBy(rows, 'id')

    return rows

  }

  const renderGoogleSearchRows = (props) => {
    const { index, style } = props;
    // const item = googleCategoriesRows[index]
    // let id = ''
    let t = ''
    const item = searchResultsArr[index]
    if (item) {
      if (item.item) {
        const itemObj = item.item
        // id = itemObj.id
        t = itemObj.t
      }
      else {
        // id = item.id
        t = item.t
      }

      // const searchItemObj = searchResultsArr[index]
      // const item = searchItemObj.item
      // const id = item.id
      // const t = item.t
      return (
        <ListItem style={style} key={index} component="div" disablePadding divider>
          <ListItemButton
            sx={{ pt: 0, pb: 0, height: '100%' }}
            onClick={() => handleGoogleCategoryRowOnClick(index, item)}
            selected={isSelectedRow('google', item)}
          >
            <ListItemText
              primary={t}
              sx={{ textTransform: 'capitalize !important' }}
            />
          </ListItemButton>
        </ListItem>
      );
    }

  }

  const handleGoogleCategoryRowOnClick = (index, item) => {
    let data = {
      type: '',
      id: '',
      t: ''
    }

    if (item.item) {
      const itemObj = item.item
      data.type = 'google'
      data.id = itemObj.id
      data.t = itemObj.t
    }
    else {
      data.type = 'google'
      data.id = item.id
      data.t = item.t
    }

    if (!_.isEqual(data, selectedRow)) {
      setSelectedRow(data)
      if (clog()) console.log('Google Category Clicked:', data.id)
    }
    else {
      setSelectedRow({
        type: '',
        id: '',
        t: ''
      })
    }

  }

  // START: Button Logic

  const handleEnableDisable = () => {
    // add to userKeywordData
    const result = processUserKeywordData('enableDisable', selectedRow, userKeywordData)
    if (result.processed === true) {
      setUserKeywordData(result.data)
      // resetSelectedRow()
    }
  }

  const enableDisableText = () => {
    let text = 'Enable'
    // selected row id google type
    if (selectedRow.type === 'user') {
      // does item exist in savedUserKeywordData = categoryid
      const idx = _.findIndex(savedUserKeywordData, { categoryid: selectedRow.id })
      if (idx !== -1) {
        const userIdx = _.findIndex(userKeywordData, { categoryid: selectedRow.id })
        const item = userKeywordData[userIdx]
        let active = false
        if (item.active === 1) {
          active = true
        }
        if (active) {
          text = 'Disable'
        }
      }
    }
    return text
  }

  const enableDisableIcon = () => {
    let icon = <Done />
    // selected row id google type
    if (selectedRow.type === 'user') {
      // does item exist in savedUserKeywordData = categoryid
      const idx = _.findIndex(savedUserKeywordData, { categoryid: selectedRow.id })
      if (idx !== -1) {
        const userIdx = _.findIndex(userKeywordData, { categoryid: selectedRow.id })
        const item = userKeywordData[userIdx]
        let active = false
        if (item.active === 1) {
          active = true
        }
        if (active) {
          icon = <DoDisturb />
        }
      }
    }
    return icon
  }

  const enableDisableDisabled = () => {
    let disabled = true
    // selected row id google type
    if (selectedRow.type === 'user') {
      // does item already exist in userKeywordData = categoryid
      const idx = _.findIndex(savedUserKeywordData, { categoryid: selectedRow.id })
      if (idx !== -1) {
        const item = savedUserKeywordData[idx]
        if (item.primary !== 1) {
          disabled = false
        }
      }
    }
    return disabled
  }

  const makePrimaryDisabled = () => {
    let disabled = true
    // selected row id google type
    if (selectedRow.type === 'user') {
      // does item already exist in userKeywordData = categoryid
      const idx = _.findIndex(userKeywordData, { categoryid: selectedRow.id })
      if (idx !== -1) {
        const item = userKeywordData[idx]
        if (item.primary !== 1 && item.active === 1) {
          disabled = false
        }
      }
    }
    return disabled
  }

  const handleMakePrimary = () => {
    // add to userKeywordData
    const result = processUserKeywordData('makePrimary', selectedRow, userKeywordData)
    if (result.processed === true) {
      setUserKeywordData(result.data)
      // resetSelectedRow()
    }
  }


  const addGoogleCategoryDisabled = () => {
    let disabled = true
    // selected row id google type
    if (selectedRow.type === 'google') {
      // does item already exist in userKeywordData = categoryid
      const idx = _.findIndex(userKeywordData, { categoryid: selectedRow.id })
      if (idx === -1) {
        disabled = false
      }
    }
    return disabled
  }

  const handleAddGoogleCategory = () => {
    // add to userKeywordData
    const result = processUserKeywordData('add', selectedRow, userKeywordData)
    if (result.processed === true) {
      setUserKeywordData(result.data)
      resetSelectedRow()
    }
  }



  const removeGoogleCategoryDisabled = () => {
    let disabled = true
    // selected row id google type
    if (selectedRow.type === 'user') {
      // does item exist in savedUserKeywordData = categoryid
      const idx = _.findIndex(savedUserKeywordData, { categoryid: selectedRow.id })
      if (idx === -1) {
        const userIdx = _.findIndex(userKeywordData, { categoryid: selectedRow.id })
        const item = userKeywordData[userIdx]
        if (item.primary !== 1) {
          disabled = false
        }
        // disabled = false

      }
    }
    return disabled
  }

  const handleRemoveGoogleCategory = () => {
    // remove from userKeywordData
    const result = processUserKeywordData('remove', selectedRow, userKeywordData)
    if (result.processed === true) {
      setUserKeywordData(result.data)
      resetSelectedRow()
    }
  }

  // END: Button Logic

  const googleCategoriesRef = collection(getFirestore(firebase), 'googlecategories')
  // BT: GB hardwired for now
  const q = query(
    googleCategoriesRef,
    where('regioncode', '==', 'GB'),
    // where('eclocationid', '==', userData.locationId),
    // where('eclocationid', '==', 'xxx'),
  );
  const [value, loading, error] = useCollection(
    q,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );


  if (error) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Stack spacing={0.5}>
            <InputLabel sx={{ fontWeight: 700 }}>Selected Keywords</InputLabel>
            <MainCard
              content={false}
            // codeString={scrollListCodeString}
            >
              <Box sx={{ width: '100%', height: 390, bgcolor: 'background.paper' }}>
                <strong>Error: {JSON.stringify(error)}</strong>
              </Box>
            </MainCard>
          </Stack>
        </Grid>
      </Grid>
    )
  }

  if (loading) {
    return (
      <Grid container spacing={3}>

        {/* START: Buttons & Search */}

        <Grid item xs={12} sm={6}>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              // justifyContent: 'center'
              mt: '2px'
            }}
            justifyContent='space-between'

          >

            <Button
              // id={enableDisableText()}
              variant="contained"
              // sx={{ my: 3, ml: 1 }}
              sx={{
                minWidth: buttonWidth
              }}
              disabled={true}
            >
              Enable

            </Button>


            <Button
              variant="contained"
              startIcon={<Star />}
              // sx={{ my: 3, ml: 1 }}
              sx={{
                minWidth: buttonWidth
              }}
              disabled={true}
            >
              Primary
            </Button>

            <IconButton
              aria-label="Remove Google Category"
              // edge="end"
              color="primary"
              variant="light"
              sx={{
                color: theme.palette.primary.contrastText,
                // bgcolor: open ? iconBackColorOpen : iconBackColor,
                bgcolor: theme.palette.primary.main,
                "&:hover": {
                  color: theme.palette.primary.contrastText,
                  bgcolor: theme.palette.primary.dark,
                },
                ml: {
                  xs: 0,
                  // lg: -2
                }
              }}
              disabled={true}
            >
              <ArrowRight />
            </IconButton>

          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              // justifyContent: 'center'
            }}
            justifyContent='space-between'

          >
            <IconButton
              aria-label="Add Google Category"
              // edge="end"
              color="primary"
              variant="dark"
              sx={{
                color: theme.palette.primary.contrastText,
                bgcolor: theme.palette.primary.main,
                "&:hover": {
                  color: theme.palette.primary.contrastText,
                  bgcolor: theme.palette.primary.dark,
                },
                ml: {
                  xs: 0,
                  // lg: -2
                }
              }}
              disabled={true}
            >
              <ArrowLeft />
            </IconButton>

            <OutlinedInput
              id="SearchKeywords"
              placeholder="Search Keywords"
              startAdornment={<SearchOutlined />}
              value={searchValue}
              sx={{
                minWidth: 211
              }}
            />
          </Box>
        </Grid>
        {/* END: Buttons & Search */}

        {/* START: User Keywords */}
        <Grid item xs={12} sm={6}>
          <Stack spacing={0.5}>
            <Box
              sx={{
                bgcolor: 'background.paper',
                pb: '2px'
              }}
            >
              <InputLabel sx={{ fontWeight: 700 }}>Selected Keywords</InputLabel>
            </Box>
            <MainCard
              content={false}
            // codeString={scrollListCodeString}
            >
              <Box sx={{ width: '100%', height: 390, bgcolor: 'background.paper' }}>

              </Box>
            </MainCard>
          </Stack>
        </Grid>
        {/* END: User Keywords */}

        {/* START: Google Keywords */}
        <Grid item xs={12} sm={6}>
          <Stack spacing={0.5}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                // p: 1,
                // m: 1,
                bgcolor: 'background.paper',
              }}
            >
              <InputLabel sx={{ fontWeight: 700 }}>Keywords List</InputLabel>
              <Typography
                variant='h6'
                align='right'
                sx={{ textDecoration: 'none' }}
                color="textSecondary">
                <b>Results:</b> {searchResultsArr.length}
              </Typography>
            </Box>

            <MainCard
              content={false}
            >
              <Box sx={{ width: '100%', height: 390, bgcolor: 'background.paper' }}>

              </Box>
            </MainCard>

          </Stack>
        </Grid>
        {/* END: Google Keywords */}

      </Grid>

    )
  }

  if (value) {

    //let googleCategoriesDataFound = false

    value.docs.forEach((doc, index) => {
      const docData = doc.data()
      if (docData) {
        // googleCategoriesDataFound = true
        googleCategoriesDataObj = docData
        googleCategoriesDataObj.exists = true
        // add count
        // let count = 0
        // if (docData.categories) {
        // count = _.size(docData.categories)
        // }
        // googleCategoriesDataObj.count = count
      }

    })

    if (googleCategoriesLoaded) {


      // START: Create the Fuse index

      const searchOptions = {
        // shouldSort: true,
        // includeScore: true,
        threshold: 0.1,
        //     threshold: 0.5,
        //     // location: 0,
        //     // distance: 100,
        //     maxPatternLength: 32,
        //     minMatchCharLength: 1,
        keys: [

          {
            name: "id",
            // weight: 2
          },
          {
            name: "t",
            // weight: 1
          }
        ]

      };

      const googleCategoriesIndex = Fuse.createIndex(searchOptions.keys, googleCategoriesRows)
      const fuse = new Fuse(googleCategoriesRows, searchOptions, googleCategoriesIndex)
      if (searchValue === '') {
        searchResultsArr = googleCategoriesRows
      }
      else {
        searchResultsArr = fuse.search(searchValue)
      }
      // END: Create the Fuse index


      if (clog()) console.log('---------------------');
      if (clog()) console.log('saveKeywords:', saveKeywords);
      if (clog()) console.log('saveGridTemplates:', saveGridTemplates);


      return (
        <Grid container spacing={3}>

          {/* START: Buttons & Search */}

          <SettingsCalcPane
            saveKeywords={saveKeywords}
            saveGridTemplates={saveGridTemplates}
          />


          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                // justifyContent: 'center'
                mt: '2px'
              }}
              justifyContent='space-between'

            >

              <Button
                // id={enableDisableText()}
                // startIcon={<Done />}
                startIcon={enableDisableIcon()}

                variant="contained"
                // sx={{ my: 3, ml: 1 }}
                sx={{
                  minWidth: buttonWidth,
                  pl: 0,
                  pr: 0
                }}
                // onClick={() => handleEnableDisable(enableDisableText())}
                onClick={handleEnableDisable}
                disabled={enableDisableDisabled()}
              >
                {enableDisableText()}

              </Button>


              <Button
                variant="contained"
                startIcon={<Star />}
                // sx={{ my: 3, ml: 1 }}
                sx={{
                  minWidth: buttonWidth
                }}
                onClick={handleMakePrimary}
                disabled={makePrimaryDisabled()}
              >
                Primary
              </Button>

              <IconButton
                aria-label="Remove Google Category"
                onClick={handleRemoveGoogleCategory}
                // edge="end"
                color="primary"
                variant="light"
                sx={{
                  color: theme.palette.primary.contrastText,
                  // bgcolor: open ? iconBackColorOpen : iconBackColor,
                  bgcolor: theme.palette.primary.main,
                  "&:hover": {
                    color: theme.palette.primary.contrastText,
                    bgcolor: theme.palette.primary.dark,
                  },
                  ml: {
                    xs: 0,
                    // lg: -2
                  }
                }}
                disabled={removeGoogleCategoryDisabled()}
              >
                <ArrowRight />
              </IconButton>

            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                // justifyContent: 'center'
              }}
              justifyContent='space-between'

            >
              <IconButton
                aria-label="Add Google Category"
                onClick={handleAddGoogleCategory}
                // edge="end"
                color="primary"
                variant="dark"
                sx={{
                  color: theme.palette.primary.contrastText,
                  bgcolor: theme.palette.primary.main,
                  "&:hover": {
                    color: theme.palette.primary.contrastText,
                    bgcolor: theme.palette.primary.dark,
                  },
                  ml: {
                    xs: 0,
                    // lg: -2
                  }
                }}
                disabled={addGoogleCategoryDisabled()}
              >
                <ArrowLeft />
              </IconButton>

              <OutlinedInput
                id="SearchKeywords"
                placeholder="Search Keywords"
                startAdornment={<SearchOutlined />}
                value={searchValue}
                onChange={handleSearchInput}
                sx={{
                  minWidth: 211
                }}
              />
            </Box>
          </Grid>
          {/* END: Buttons & Search */}

          {/* START: User Keywords */}
          <Grid item xs={12} sm={6}>
            <Stack spacing={0.5}>
              <Box
                sx={{
                  bgcolor: 'background.paper',
                  pb: '2px'
                }}
              >
                <InputLabel sx={{ fontWeight: 700 }}>Selected Keywords</InputLabel>
              </Box>
              <MainCard
                content={false}
              // codeString={scrollListCodeString}
              >
                <Box sx={{ width: '100%', height: 390, bgcolor: 'background.paper' }}>

                  <FixedSizeList
                    height={600}
                    width="100%" itemSize={56}
                    // data={googleCategoriesRows}
                    // itemCount={googleCategoriesRows.length}
                    data={userKeywordData}
                    itemCount={userKeywordData.length}
                    overscanCount={5}
                    scrollToItem={0}
                  >
                    {renderUserKeywordRows}
                  </FixedSizeList>
                </Box>
              </MainCard>
            </Stack>
          </Grid>
          {/* END: User Keywords */}

          {/* START: Google Keywords */}
          <Grid item xs={12} sm={6}>
            <Stack spacing={0.5}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  // p: 1,
                  // m: 1,
                  bgcolor: 'background.paper',
                }}
              >
                <InputLabel sx={{ fontWeight: 700 }}>Keywords List</InputLabel>
                <Typography
                  variant='h6'
                  align='right'
                  sx={{ textDecoration: 'none' }}
                  color="textSecondary">
                  <b>Results:</b> {searchResultsArr.length}
                </Typography>
              </Box>

              <MainCard
                content={false}
              >
                <Box sx={{ width: '100%', height: 390, bgcolor: 'background.paper' }}>


                  <FixedSizeList
                    height={600}
                    width="100%" itemSize={56}
                    // data={googleCategoriesRows}
                    // itemCount={googleCategoriesRows.length}
                    data={searchResultsArr}
                    itemCount={searchResultsArr.length}
                    overscanCount={5}
                    scrollToItem={0}
                  >
                    {renderGoogleSearchRows}
                  </FixedSizeList>

                </Box>
              </MainCard>

            </Stack>
          </Grid>
          {/* END: Google Keywords */}

        </Grid>
      )
    }
    else {
      return (
        <Grid container spacing={3}>

          {/* START: Buttons & Search */}
          <Grid item xs={12} sm={6}>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                // justifyContent: 'center'
                mt: '2px'
              }}
              justifyContent='space-between'

            >

              <Button
                // id={enableDisableText()}
                variant="contained"
                // sx={{ my: 3, ml: 1 }}
                sx={{
                  minWidth: buttonWidth
                }}
                disabled={true}
              >
                Enable

              </Button>


              <Button
                variant="contained"
                startIcon={<Star />}
                // sx={{ my: 3, ml: 1 }}
                sx={{
                  minWidth: buttonWidth
                }}
                disabled={true}
              >
                Primary
              </Button>

              <IconButton
                aria-label="Remove Google Category"
                // edge="end"
                color="primary"
                variant="light"
                sx={{
                  color: theme.palette.primary.contrastText,
                  // bgcolor: open ? iconBackColorOpen : iconBackColor,
                  bgcolor: theme.palette.primary.main,
                  "&:hover": {
                    color: theme.palette.primary.contrastText,
                    bgcolor: theme.palette.primary.dark,
                  },
                  ml: {
                    xs: 0,
                    // lg: -2
                  }
                }}
                disabled={true}
              >
                <ArrowRight />
              </IconButton>

            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                // justifyContent: 'center'
              }}
              justifyContent='space-between'

            >
              <IconButton
                aria-label="Add Google Category"
                // edge="end"
                color="primary"
                variant="dark"
                sx={{
                  color: theme.palette.primary.contrastText,
                  bgcolor: theme.palette.primary.main,
                  "&:hover": {
                    color: theme.palette.primary.contrastText,
                    bgcolor: theme.palette.primary.dark,
                  },
                  ml: {
                    xs: 0,
                    // lg: -2
                  }
                }}
                disabled={true}
              >
                <ArrowLeft />
              </IconButton>

              <OutlinedInput
                id="SearchKeywords"
                placeholder="Search Keywords"
                startAdornment={<SearchOutlined />}
                value={searchValue}
                sx={{
                  minWidth: 211
                }}
              />
            </Box>
          </Grid>
          {/* END: Buttons & Search */}

          {/* START: User Keywords */}
          <Grid item xs={12} sm={6}>
            <Stack spacing={0.5}>
              <Box
                sx={{
                  bgcolor: 'background.paper',
                  pb: '2px'
                }}
              >
                <InputLabel sx={{ fontWeight: 700 }}>Selected Keywords</InputLabel>
              </Box>
              <MainCard
                content={false}
              >
                <Box sx={{ width: '100%', height: 390, bgcolor: 'background.paper' }}>

                </Box>
              </MainCard>
            </Stack>
          </Grid>
          {/* END: User Keywords */}

          {/* START: Google Keywords */}
          <Grid item xs={12} sm={6}>
            <Stack spacing={0.5}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  // p: 1,
                  // m: 1,
                  bgcolor: 'background.paper',
                }}
              >
                <InputLabel sx={{ fontWeight: 700 }}>Keywords List</InputLabel>
                <Typography
                  variant='h6'
                  align='right'
                  sx={{ textDecoration: 'none' }}
                  color="textSecondary">
                  <b>Results:</b> {searchResultsArr.length}
                </Typography>
              </Box>

              <MainCard
                content={false}
              >
                <Box sx={{ width: '100%', height: 390, bgcolor: 'background.paper' }}>

                </Box>
              </MainCard>

            </Stack>
          </Grid>
          {/* END: Google Keywords */}

        </Grid>
      )

    }
  }

};


export default React.memo(SettingsKeywordsPane)