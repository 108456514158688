import React, { createContext, useState, useRef, useContext } from "react";

import { Link } from "react-router-dom";

import { useTheme, styled } from "@mui/material/styles";

// material-ui
import { Grid, Stack, Typography, Box, Container, Button } from "@mui/material";

// project import
// import useAuth from 'hooks/useAuth';
import AuthWrapper from "../sections/auth/AuthWrapper";

import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebase } from "../code/firebase";

import AuthForgotPassword from "../sections/auth/auth-forms/AuthForgotPassword";
import LoadingPane from "./LoadingPane";

import {setd, debug, setc, clog} from "../helpers/HelperFunctions";
import { AdminAuthContext } from "../helpers/AdminAuthProvider";

// ================================|| LOGIN ||================================ //

const auth = getAuth(firebase);

const AdminLoginForgotPasswordContainer = () => {
  if (clog()) console.log("AdminLoginForgotPasswordContainer")

  // const { isLoggedIn } = useAuth();


  const { logout } = useContext(AdminAuthContext);

  const theme = useTheme();

  const [user, loadingUser, errorUser] = useAuthState(auth);

  // const handleLogout = async () => {
  //   await logout();
  // };

  // const logoutButton = () => {
  //   if (user) {
  //     if (clog()) console.log("UID:", user.uid);
  //     return (
  //       <Grid item xs={12}>
  //         {
  //           // <Typography variant="p">Email: {user.email}</Typography>
  //           // <Typography variant="p">UID: {user.uid}</Typography>
  //         }
  //         {
  //           // <Button
  //           //   disableElevation
  //           //   // disabled={isSubmitting}
  //           //   fullWidth
  //           //   size="large"
  //           //   type="submit"
  //           //   variant="contained"
  //           //   color="primary"
  //           //   onClick={() => handleLogout()}
  //           // >
  //           //   Logout
  //           // </Button>
  //         }
  //       </Grid>
  //     );
  //   }
  // };

  // if (clog()) console.log("AdminLoginForgotPasswordContainer");
  if (loadingUser) {
    return (
      <Container component="main" maxWidth="lg">
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#fff",
            border: `1px solid ${theme.palette.divider}`,
            padding: 4,
          }}
        >
          <LoadingPane
            text={"Loading..."}
            showSpinner={true}
            // progress={50}
          />
        </Box>
      </Container>
    );
  } else {
    return (
      <AuthWrapper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
            <Typography variant="h3">Forgot Password</Typography>
            <Typography
              component={Link}
              // to={isLoggedIn ? '/auth/login' : '/login'}
              variant="body1"
              sx={{ textDecoration: 'none' }}
              color="primary"
            >
              Back to Login
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <AuthForgotPassword />
        </Grid>
      </Grid>
    </AuthWrapper>
    );
  }
};

export default AdminLoginForgotPasswordContainer;
